import { Typography, Grid } from '@material-ui/core'
import React from 'react'
import Button from 'components/Button'
import { useHistory } from 'react-router'
import { ROUTE_URL } from 'constants.js'
import { useIntl } from 'react-intl'

const InProgress = ({ data }) => {
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  return (
    <>
      <Typography variant='h3' gutterBottom>
        {formatMessage({ id: 'tlpay.your-application-is-in-progress' })}
      </Typography>
      <Typography paragraph>
      {formatMessage({ id: 'tlpay.your-application-is-currently-under-review-by-our-payment-provider-compliance-team-if-any-additional-information-or-documents-are-required-we-will-provide-you-with-further-details' })}
      </Typography>
      {data.customerHashId && <Typography paragraph>
        <b>{formatMessage({ id: 'tlpay.nium-customer-hash-id' })}</b>: {data.customerHashId}
      </Typography>}
      <Grid container justifyContent='flex-end'>
        <Button color="primary" onClick={() => {
          push(ROUTE_URL.home)
        }}>
          {formatMessage({ id: 'tlpay.i-understand' })}
        </Button>
      </Grid>
      
    </>
  )
}

export default InProgress
