import { person, contact, documentDetailsCorporateStakeholders } from './common'
import { TYPE } from '../../formTypes'
import {
  annualTurnoverEu,
  businessTypesEu,
  corporateDocumentTypeEu,
  industrySector,
  intendedUseOfAccount,
  personDocumentTypeEu,
  positionEu,
  totalEmployees,
  yesOrNo,
  formatValueMap,
  stakeholderTypes,
  stakeholderDetailsPersonDocumentTypeEu,
  monthlyTransactionVolume,
  monthlyTransactions,
  averageTransactionValue,
  countryOfOperationEu,
} from '../values'
import { NIUM_STAKEHOLDER_TYPE } from 'constants.js'

export const address = formatMessage => ({
  addressLine1: {
    label: formatMessage({ id: 'kyb-form.address-line-1' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  addressLine2: {
    label: formatMessage({ id: 'kyb-form.address-line-2' }),
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  city: {
    label: formatMessage({ id: 'kyb-form.city' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  country: {
    label: formatMessage({ id: 'kyb-form.country' }),
    type: TYPE.COUNTRY,
    required: true,
  },
  postcode: {
    label: formatMessage({ id: 'kyb-form.postcode' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
      'string.pattern.base',
    ],
  },
  state: {
    label: formatMessage({ id: 'kyb-form.state' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
})

export const documentDetailsCorporate = formatMessage => ({
  documentType: {
    label: formatMessage({ id: 'kyb-form.document-type' }),
    type: TYPE.SELECT,
    options: formatValueMap(corporateDocumentTypeEu, formatMessage),
    required: true,
  },
  "document.0.document": {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE,
    info: formatMessage({ id: 'kyb-form.file-size-limit-1-5-mb-accepted-formats-pdf-jpg-png' }),
    required: true,
  },
})

export const documentDetailsPerson = formatMessage => ({
  documentType: {
    label: formatMessage({ id: 'kyb-form.document-type' }),
    type: TYPE.SELECT,
    info: formatMessage({ id: 'kyb-form.passport-is-required' }),
    options: formatValueMap(personDocumentTypeEu, formatMessage),
    required: true
  },
  "document.0.document": {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE,
    info: formatMessage({ id: 'kyb-form.file-size-limit-1-5-mb-accepted-formats-pdf-jpg-png' }),
    required: true
  },
  documentNumber: {
    label: formatMessage({ id: 'kyb-form.document-number' }),
    type: TYPE.STRING,
    required: true,
    info: formatMessage({ id: 'kyb-form.e-g-incorporation-number-or-passport-etc' }),
  },
  documentIssuanceCountry: {
    label: formatMessage({ id: 'kyb-form.document-issuance-country' }),
    type: TYPE.COUNTRY,
    required: true
  },
  documentExpiryDate: {
    label: formatMessage({ id: 'kyb-form.document-expiry-date' }),
    info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
    type: TYPE.DATE,
    future: true,
  },
  documentIssuedDate: {
    label: formatMessage({ id: 'kyb-form.document-issued-date' }),
    info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
    type: TYPE.DATE,
    past: true,
  },
})

export const stakeholderDetailsDocumentDetailsPerson = formatMessage => ({
  ...documentDetailsPerson(formatMessage),
  documentType: {
    label: formatMessage({ id: 'kyb-form.document-type' }),
    type: TYPE.SELECT,
    info: formatMessage({ id: 'kyb-form.passport-is-required' }),
    options: {
      ...formatValueMap(stakeholderDetailsPersonDocumentTypeEu, formatMessage),
      POWER_OF_ATTORNEY: formatMessage({ id: 'kyb-form.apostilled-letter-of-authority' }),
    },
    required: true
  },
  "document.0.document": {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE,
    info: '<i>' + formatMessage({ id: 'kyb-form.accepted-file-formats-pdf-jpg-png' }) + '</i><br>' +
      formatMessage({ id: 'kyb-form.photocopies-or-scanned-documents-in' }) + ' <i>' +
      formatMessage({ id: 'kyb-form.black-and-white' }) + '</i> ' +
      formatMessage({ id: 'kyb-form.are-not-accepted' }),
    required: true
  },
  documentIssuedDate: {
    label: formatMessage({ id: 'kyb-form.document-issued-date' }),
    info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
    type: TYPE.DATE,
    past: true,
    required: true
  },
})

export const stakeHolderProfessionDetails = formatMessage => ({
  position: {
    label: formatMessage({ id: 'kyb-form.position' }),
    type: TYPE.SELECT,
    options: formatValueMap(positionEu, formatMessage)
  },
  positionStartDate: {
    label: formatMessage({ id: 'kyb-form.position-start-date' }),
    type: TYPE.DATE,
    past: true,
  },
  sharePercentage: {
    label: formatMessage({ id: 'kyb-form.share-percentage' }),
    type: TYPE.STRING,
    decimalDigits: 2,
    info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
  },
})

export const stakeholderDetailsTaxDetails = formatMessage => ({
  title: formatMessage({ id: 'kyb-form.tax-details' }),
  type: TYPE.GROUP,
  fields: {
    country: {
      label: formatMessage({ id: 'kyb-form.country' }),
      type: TYPE.COUNTRY,
      required: true,
    },
    taxNumber: {
      label: formatMessage({ id: 'kyb-form.tax-number' }),
      type: TYPE.STRING,
      required: true,
      customErrorMessageTypes: [
        'string.max',
      ],
    },
  },
})

export const stakeholdersFields = formatMessage => ({
  stakeholderType: {
    type: TYPE.RADIO,
    options: formatValueMap(stakeholderTypes, formatMessage),
    required: true
  },
  businessPartner: {
    title: formatMessage({ id: 'kyb-form.stakeholder-details' }),
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.CORPORATE
    },
    fields: {
      businessName: {
        label: formatMessage({ id: 'kyb-form.business-name' }),
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: formatMessage({ id: 'kyb-form.business-registration-number' }),
        type: TYPE.STRING,
        required: true
      },
      businessEntityType: {
        label: formatMessage({ id: 'kyb-form.stakeholder-type' }),
        type: TYPE.SELECT,
        options: formatValueMap(positionEu, formatMessage),
        required: true
      },
      sharePercentage: {
        label: formatMessage({ id: 'kyb-form.share-percentage' }),
        type: TYPE.STRING,
        decimalDigits: 2,
        info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
      },
      "legalDetails.registeredCountry": {
        label: formatMessage({ id: 'kyb-form.registered-country' }),
        type: TYPE.COUNTRY,
        required: true
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporateStakeholders(formatMessage)]
      },
    },
  },
  stakeholderDetails: {
    title: formatMessage({ id: 'kyb-form.stakeholder-details' }),
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.INDIVIDUAL
    },
    fields: {
      ...person(formatMessage),
      birthCountry: {
        label: formatMessage({ id: 'kyb-form.birth-country' }),
        type: TYPE.COUNTRY,
        required: true
      },
      isPep: {
        label: formatMessage({ id: 'kyb-form.is-politically-exposed-person' }),
        type: TYPE.RADIO,
        options: formatValueMap(yesOrNo, formatMessage),
        required: true
      },
      address: {
        title: formatMessage({ id: 'kyb-form.address-details' }),
        type: TYPE.GROUP,
        fields: address(formatMessage),
      },
      contactDetails: {
        title: formatMessage({ id: 'kyb-form.contact-details' }),
        type: TYPE.GROUP,
        fields: {
          ...contact(formatMessage),
        }
      },
      professionalDetails: {
        title: formatMessage({ id: 'kyb-form.professional-details' }),
        type: TYPE.GROUP,
        fields: [stakeHolderProfessionDetails(formatMessage)],
        multiple: true,
      },
      "taxDetails.0": stakeholderDetailsTaxDetails(formatMessage),
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        type: TYPE.GROUP,
        info: formatMessage({ id: 'kyb-form.if-the-stakeholder-position-is-not-director-it-is-mandatory-to-upload-a-power-of-attorney-certified-with-apostille' }),
        multiple: true,
        fields: [stakeholderDetailsDocumentDetailsPerson(formatMessage)]
      },
    },
  },
})

export const topBeneficiariesDebit = formatMessage => ({
  beneficiary: {
    label: formatMessage({ id: 'kyb-form.top-beneficiaries-debit' }),
    type: TYPE.STRING,
    required: true,
  },
})

export const topRemittersCredit = formatMessage => ({
  remitter: {
    label: formatMessage({ id: 'kyb-form.top-remitters-credit' }),
    type: TYPE.STRING,
    required: true,
  },
})

export const applicantProfessionalDetails = formatMessage => ({
  position: {
    label: formatMessage({ id: 'kyb-form.position' }),
    type: TYPE.SELECT,
    options: formatValueMap(positionEu, formatMessage),
    required: true
  },
  positionStartDate: {
    label: formatMessage({ id: 'kyb-form.position-start-date' }),
    type: TYPE.DATE,
    past: true,
    required: true
  },
  sharePercentage: {
    label: formatMessage({ id: 'kyb-form.share-percentage' }),
    type: TYPE.STRING,
    decimalDigits: 2,
    info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
  },
})

const expectedAccountUsageDebit = formatMessage => ({
  title: formatMessage({ id: 'kyb-form.expected-debit-transactions' }),
  type: TYPE.GROUP,
  fields: {
    monthlyTransactionVolume: {
      label: formatMessage({ id: 'kyb-form.monthly-transaction-volume-debit' }),
      type: TYPE.SELECT,
      options: formatValueMap(monthlyTransactionVolume, formatMessage),
      info: formatMessage({ id: 'kyb-form.please-select-the-estimated-monthly-volume-of-payout-transactions' }),
      required: true,
    },
    monthlyTransactions: {
      label: formatMessage({ id: 'kyb-form.monthly-transactions-debit' }),
      type: TYPE.SELECT,
      options: formatValueMap(monthlyTransactions, formatMessage),
      info: formatMessage({ id: 'kyb-form.please-select-the-estimated-monthly-count-of-payout-transactions' }),
      required: true,
    },
    averageTransactionValue: {
      label: formatMessage({ id: 'kyb-form.average-transaction-value-debit' }),
      type: TYPE.SELECT,
      options: formatValueMap(averageTransactionValue, formatMessage),
      info: formatMessage({ id: 'kyb-form.please-select-the-estimated-average-payout-transaction-value' }),
      required: true,
    },
    topTransactionCountries: {
      label: formatMessage({ id: 'kyb-form.top-transaction-countries-debit' }),
      info: formatMessage({ id: 'kyb-form.please-select-your-anticipated-top-3-payout-countries' }),
      type: TYPE.COUNTRY,
      multiple: true,
      required: true,
    },
    topBeneficiaries: {
      label: formatMessage({ id: 'kyb-form.top-beneficiaries-debit' }),
      info: formatMessage({ id: 'kyb-form.expected-top-beneficiaries-can-be-specific-company-names-or-type-of-entities-i-e-corporate' }),
      type: TYPE.STRING,
      multiple: true,
      required: true,
    },
  },
})

const expectedAccountUsageCredit = formatMessage => ({
  title: formatMessage({ id: 'kyb-form.expected-credit-transactions' }),
  type: TYPE.GROUP,
  fields: {
    monthlyTransactionVolume: {
      label: formatMessage({ id: 'kyb-form.monthly-transaction-volume-credit' }),
      type: TYPE.SELECT,
      options: formatValueMap(monthlyTransactionVolume, formatMessage),
      info: formatMessage({ id: 'kyb-form.please-select-the-estimated-monthly-volume-of-payin-transactions' }),
      required: true,
    },
    monthlyTransactions: {
      label: formatMessage({ id: 'kyb-form.monthly-transactions-credit' }),
      type: TYPE.SELECT,
      options: formatValueMap(monthlyTransactions, formatMessage),
      info: formatMessage({ id: 'kyb-form.please-select-the-estimated-monthly-count-of-payin-transactions' }),
      required: true,
    },
    averageTransactionValue: {
      label: formatMessage({ id: 'kyb-form.average-transaction-value-credit' }),
      type: TYPE.SELECT,
      options: formatValueMap(averageTransactionValue, formatMessage),
      info: formatMessage({ id: 'kyb-form.please-selct-the-estimated-average-payin-transaction-value' }),
      required: true,
    },
    topTransactionCountries: {
      label: formatMessage({ id: 'kyb-form.top-transaction-countries-credit' }),
      info: formatMessage({ id: 'kyb-form.please-select-your-anticipated-top-3-payin-countries' }),
      type: TYPE.COUNTRY,
      multiple: true,
      required: true,
    },
    topRemitters: {
      label: formatMessage({ id: 'kyb-form.top-remitters-credit' }),
      info: formatMessage({ id: 'kyb-form.expected-top-remitters-can-be-specific-company-names-or-type-of-entities-i-e-corporate' }),
      type: TYPE.STRING,
      multiple: true,
      required: true,
    },
  },
})

export const applicantDetailsFields = formatMessage => ({
  ...person(formatMessage),
  birthCountry: {
    label: formatMessage({ id: 'kyb-form.birth-country' }),
    type: TYPE.COUNTRY,
    required: true
  },
  isPep: {
    label: formatMessage({ id: 'kyb-form.is-politically-exposed-person' }),
    type: TYPE.RADIO,
    options: formatValueMap(yesOrNo, formatMessage),
    required: true
  },
  contactDetails: {
    title: formatMessage({ id: 'kyb-form.applicants-contact-details' }),
    type: TYPE.GROUP,
    fields: contact(formatMessage),
  },
  professionalDetails: {
    title: formatMessage({ id: 'kyb-form.applicants-professional-details' }),
    type: TYPE.GROUP,
    fields: [applicantProfessionalDetails(formatMessage)],
    multiple: true,
  },
  address: {
    title: formatMessage({ id: 'kyb-form.applicants-address-details' }),
    type: TYPE.GROUP,
    info: formatMessage({ id: 'kyb-form.the-applicant-s-address-details-is-where-you-provide-your-current-and-personal-place-of-residence-you-will-need-to-enter-your-complete-address-including-the-house-number-street-name-town-or-city-county-or-state-postal-code' }),
    fields: address(formatMessage),
  },
  documentDetails: {
    title: formatMessage({ id: 'kyb-form.applicants-document-details' }),
    info: '<i>' + formatMessage({ id: 'kyb-form.the-applicant-who-completes-this-form-must-be-a-director-or-an-authorized-signatory-that-has-been-appointed-by-a-director-or-by-power-of-attorney-to-sign-and-execute-transactions-on-behalf-of-the-company' }) + '</i><br>' +
      '<i>' + formatMessage({ id: 'kyb-form.documents-should-include-a-form-of-photo-identification-and-for-those-acting-by-power-of-attorney-an-additional-document-power-of-attorney-certified-with-apostille-must-also-be-provided' }) + '</i>',
    type: TYPE.GROUP,
    multiple: true,
    fields: [documentDetailsPerson(formatMessage)],
    lockedFieldIndexes: [],
  },
})

export const formStructure = formatMessage => ({
  region: {
    title: formatMessage({ id: 'kyb-form.region' }),
    type: TYPE.GROUP,
    fields: {
      region: {
        label: formatMessage({ id: 'kyb-form.region' }),
        type: TYPE.SELECT,
        options: { EU: 'EU' },
        required: true,
      }
    }
  },
  businessDetails: {
    title: formatMessage({ id: 'kyb-form.business-details' }),
    type: TYPE.GROUP,
    fields: {
      businessName: {
        label: formatMessage({ id: 'kyb-form.business-name' }),
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: formatMessage({ id: 'kyb-form.business-registration-number' }),
        type: TYPE.STRING,
        required: true
      },
      tradeName: {
        label: formatMessage({ id: 'kyb-form.trade-name' }),
        type: TYPE.STRING,
        required: true
      },
      website: {
        label: formatMessage({ id: 'kyb-form.website' }),
        type: TYPE.STRING
      },
      businessType: {
        label: formatMessage({ id: 'kyb-form.business-type' }),
        type: TYPE.SELECT,
        options: formatValueMap(businessTypesEu, formatMessage),
        required: true
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        info: '<p>' + formatMessage({ id: 'kyb-form.for-nium-to-perform-electronic-verification-of-company-data-the-following-information-is-required-where-applicable' }) + ' <b>' + 
          formatMessage({ id: 'kyb-form.note' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.documents-must-be-uploaded-in-pdf-jpg-or-png-format' }) + '</p>' +
          '<p>' + formatMessage({ id: 'kyb-form.if-the-corporate-entity-has-multiple-ownership-layers-please-provide-an-organization-chart-showing-shareholding-structure-and-ub-os-with-shareholding-percentages-the-organization-chart-must-be-signed-and-dated-by-one-authorized-representative' }) + '</p>' +
          '<p>' + formatMessage({ id: 'kyb-form.for-private-companies-a-register-of-directors-and-register-of-shareholders-are-required-when-details-of-the-provided-shareholders-or-directors-are-not-available-in-the-business-registration-document' }) + '</p>',
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporate(formatMessage)]
      },
      legalDetails: {
        title: formatMessage({ id: 'kyb-form.legal-registration-details' }),
        type: TYPE.GROUP,
        fields: {
          registeredDate: {
            label: formatMessage({ id: 'kyb-form.registration-date' }),
            type: TYPE.DATE,
            past: true,
            info: formatMessage({ id: 'kyb-form.the-date-the-business-was-originally-registered' }),
            required: true
          },
          registeredCountry: {
            label: formatMessage({ id: 'kyb-form.registered-country' }),
            type: TYPE.COUNTRY,
            required: true
          },
        }
      },
      "taxDetails.0": {
        title: formatMessage({ id: 'kyb-form.tax-details' }),
        type: TYPE.GROUP,
        info: formatMessage({ id: 'kyb-form.if-your-company-is-a-subsidiary-or-a-part-of-a-group-of-companies-provide-the-country-where-taxes-are-paid-for-your-own-legal-entity-and-not-for-the-entire-group-of-companies' }),
        fields: {
          country: {
            label: formatMessage({ id: 'kyb-form.country' }),
            type: TYPE.COUNTRY,
            options: ['AT', 'BE', 'HR', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'],
            required: true
          },
          taxNumber: {
            label: formatMessage({ id: 'kyb-form.tax-number' }),
            type: TYPE.STRING,
            required: true
          },
        },
      },
      "addresses": {
        title: formatMessage({ id: 'kyb-form.addresses' }),
        type: TYPE.GROUP,
        fields: {
          registeredAddress: {
            title: formatMessage({ id: 'kyb-form.registered-address' }),
            noExpand: true,
            type: TYPE.GROUP,
            fields: address(formatMessage),
          },
          isSameAddress: {
            label: formatMessage({ id: 'kyb-form.is-your-business-address-same-as-your-registered-address' }),
            type: TYPE.RADIO,
            options: formatValueMap(yesOrNo, formatMessage),
            required: true,
          },
          businessAddress: {
            title: formatMessage({ id: 'kyb-form.business-address' }),
            noExpand: true,
            type: TYPE.GROUP,
            fields: address(formatMessage),
            visibleIfFalse: 'businessDetails.addresses.isSameAddress',
          },
        },
      },
    }
  },
  stakeholders: {
    title: formatMessage({ id: 'kyb-form.stakeholders' }),
    info:
      formatMessage({ id: 'kyb-form.stakeholders-include-the-following-roles-and' }) +
      ' <b>' + formatMessage({ id: 'kyb-form.all' }) + '</b> ' +
      formatMessage({ id: 'kyb-form.applicable-persons-must-be-added-and-completed' }) + '<br><b> ' +
      formatMessage({ id: 'kyb-form.note' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.documents-must-be-uploaded-in-pdf-jpg-or-png-format-with-a-max-size-of-1-5-mb' }) + '<br /><ul><li><b>' +
      formatMessage({ id: 'kyb-form.directors-of-a-company' }) + '</b>: ' +
      formatMessage({  id: 'kyb-form.are-either-officers-or-agents-appointed-by-the-shareholder-beneficial-owners-to-manage-the-company-s-day-to-day-affairs-please-include-at-least-two-directors-of-the-company-in-this-section' }) + '</li><li><b>' +
      formatMessage({ id: 'kyb-form.control-person' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.a-control-person-is-an-individual-with-significant-responsibility-for-managing-or-directing-the-entity-including-an-executive-officer-or-senior-manager-e-g-chief-executive-officer-chief-financial-officer-chief-operating-officer-managing-member-general-partner-president-treasurer' }) + '<br>' +
      formatMessage({ id: 'kyb-form.please-include-all-individuals-with-significant-control-over-the-company' }) + '</li><li><b>' +
      formatMessage({ id: 'kyb-form.individual-shareholders-ub-os' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.ub-os-must-be-natural-persons-individuals-except-for-publicly-traded-businesses-list-ub-os-with-25-ownership-or-more-or-10-or-more-for-pe-ps' }) + '<br><b>' +
      formatMessage({ id: 'kyb-form.note' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.if-the-stakeholder-s-position-is-ubo-then-the-share-percentage-is-required' }) + '</li><li><b>' +
      formatMessage({ id: 'kyb-form.corporate-stakeholder' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.required-if-the-company-has-corporate-stakeholders' }) + '</li></ul>',
    type: TYPE.GROUP,
    multiple: true,
    fields: [stakeholdersFields(formatMessage)]
  },
  applicantDetails: {
    title: formatMessage({ id: 'kyb-form.applicant-details' }),
    info: formatMessage({ id: 'kyb-form.all-clients-must-complete-this-section-the-applicant-must-be-a-director-or-an-authorized-signatory-that-has-been-appointed-by-a-director-or-by-apostilled-power-of-attorney-to-sign-and-execute-transactions-on-behalf-of-the-company' }),
    type: TYPE.GROUP,
    extraControls: [
      { type: 'COPY_FROM_STAKEHOLDER' },
    ],
    fields: applicantDetailsFields(formatMessage),
  },
  riskAssessmentInfo: {
    title: formatMessage({ id: 'kyb-form.additional-business-information' }),
    type: TYPE.GROUP,
    fields: {
      totalEmployees: {
        label: formatMessage({ id: 'kyb-form.total-employees' }),
        type: TYPE.SELECT,
        options: formatValueMap(totalEmployees, formatMessage),
        required: true
      },
      annualTurnover: {
        label: formatMessage({ id: 'kyb-form.annual-turnover' }),
        type: TYPE.SELECT,
        options: formatValueMap(annualTurnoverEu, formatMessage),
        required: true
      },
      industrySector: {
        label: formatMessage({ id: 'kyb-form.industry-sector' }),
        type: TYPE.SELECT,
        options: formatValueMap(industrySector, formatMessage),
        required: true
      },
      countryOfOperation: {
        label: formatMessage({ id: 'kyb-form.country-of-operation' }),
        type: TYPE.SELECT,
        options: formatValueMap(countryOfOperationEu, formatMessage, 'kyb-form.country-'),
        multiple: true,
        required: true,
        info: formatMessage({ id: 'kyb-form.please-select-countries-where-you-have-offices-or-operations' }),
      },
    }
  },
  expectedAccountUsage: {
    title: formatMessage({ id: 'kyb-form.expected-account-usage' }),
    type: TYPE.GROUP,
    fields: {
      debit: expectedAccountUsageDebit(formatMessage),
      credit: expectedAccountUsageCredit(formatMessage),
      intendedUses: {
        label: formatMessage({ id: 'kyb-form.intended-use-of-account' }),
        type: TYPE.SELECT,
        options: formatValueMap(intendedUseOfAccount, formatMessage),
        multiple: true,
        required: true,
      },
    }
  },
})
