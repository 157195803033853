import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { API_URL, ROUTE_URL } from 'constants.js'
import { ACTIONS } from 'store/paymentProfiles'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { get, post, patch } from 'utils/api'
import { dismissErrorSnackBar, dismissSnackBar } from 'utils/generators'

function* fetchPaymentProfiles() {
  const url = `${API_URL}/payment-profiles`

  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const result = yield call(get, url)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({ type: ACTIONS.PAYMENT_PROFILES_SUCCESS, payload: result })
  } catch (e) {
    console.error(e)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
  }
}

function* fetchCurrPaymentProfile({ payload }) {
  const url = `${API_URL}/payment-profiles/${payload.id}`

  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const result = yield call(get, url)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({ type: ACTIONS.FETCH_CURR_PAYMENT_PROFILE_SUCCESS, payload: result })
  } catch (e) {
    console.error(e)
    yield put({
      type: ACTIONS.FETCH_CURR_PAYMENT_PROFILE_ERROR,
      payload: `${e.data.errors[0].status} ${e.data.errors[0].title}`
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* patchPaymentProfile({ payload }) {
  const url = `${API_URL}/payment-profiles/${payload.id}`

  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const result = yield call(patch, url, payload.dataPayment)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    if (result.errors) {
      throw result.response
    }
    yield put({
      type: ACTIONS.PATCH_PAYMENT_PROFILE_SUCCESS,
      payload: {
        ...result,
        snackbar: 'Payment Profile updated.'
      }
    })
    yield fork(dismissSnackBar, ACTIONS.CLOSE_SNACKBAR)
    yield put(push(ROUTE_URL.paymentProfileList))
  } catch (e) {
    console.error(e)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({
      type: ACTIONS.PATCH_PAYMENT_PROFILE_ERROR,
      payload: `${e.data.errors[0].status} ${e.data.errors[0].title}`
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* postPaymentProfiles({ payload }) {
  const url = `${API_URL}/payment-profiles`

  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const result = yield call(post, url, payload)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    if (result.errors) {
      throw result.response
    }
    yield put({
      type: ACTIONS.POST_PAYMENT_PROFILE_SUCCESS,
      payload: {
        ...result,
        snackbar: 'Payment Profile added.'
      }
    })
    yield fork(dismissSnackBar, ACTIONS.CLOSE_SNACKBAR)
    yield put(push(ROUTE_URL.paymentProfileList))
  } catch (e) {
    console.error(e)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({
      type: ACTIONS.PAYMENT_PROFILES_ERROR,
      payload: `${e.data.errors[0].status} ${e.data.errors[0].title}`
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* watchPostPaymentProfiles() {
  const { POST_PAYMENT_PROFILE } = ACTIONS
  yield takeLatest(POST_PAYMENT_PROFILE, postPaymentProfiles)
}

function* watchPatchPaymentProfile() {
  yield takeLatest(ACTIONS.PATCH_PAYMENT_PROFILE, patchPaymentProfile)
}

function* watchFetchCurrPaymentProfile() {
  yield takeLatest(ACTIONS.FETCH_CURR_PAYMENT_PROFILE, fetchCurrPaymentProfile)
}

function* watchFetchPaymentProfiles() {
  const { FETCH_PAYMENT_PROFILES } = ACTIONS
  yield takeLatest(FETCH_PAYMENT_PROFILES, fetchPaymentProfiles)
}

export default function* rootSaga() {
  yield all([
    watchPostPaymentProfiles(),
    watchPatchPaymentProfile(),
    watchFetchPaymentProfiles(),
    watchFetchCurrPaymentProfile()
  ])
}
