import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment, TableCell, TextField } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

const emptyEvent = { target: { value: '' } }

const NumberCell = ({ value, onChange }) => {
  const [ localValue, setLocalValue ] = useState(value || '')

  useEffect(() => {
    if (value === '') {
      setLocalValue('')
    }
  }, [ value ])

  const handleChange = (e) => {
    setLocalValue(e.target.value)
    onChange(e)
  }

  const clearInput = () => {
    setLocalValue('')
    onChange(emptyEvent)
  }

  return (
    <TableCell align="center">
      <TextField
        fullWidth
        type='number'
        InputProps={{
          endAdornment: localValue ? (
            <InputAdornment position="end">
              <IconButton size="small" disableRipple onClick={clearInput}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        onChange={handleChange}
        value={localValue}
      />
    </TableCell>
  )
}

NumberCell.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func
}

export default NumberCell
