import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useStyles } from './styles'

const SimulationDetailLabel = ({ value }) => {
  const classes = useStyles()
  return (
    <Typography variant="body2" className={classes.stepLabel}>
      {value}
    </Typography>
  )
}

SimulationDetailLabel.propTypes = {
  value: PropTypes.string
}

export default memo(SimulationDetailLabel)
