import {
  BSD_CALENDAR_EVENTS,
  ERROR_SNACKBAR_TIMEOUT,
  BSD_CALENDAR_EVENTS_ACTIONS as EVENTS_ACTIONS,
  SUCCESS_SNACKBAR_TIMEOUT,
} from 'constants.js'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { getPaymentProfile, getPaymentProfiles } from 'services/paymentProfilesApi'
import {
  deleteSettlementScheduleDates,
  getSettlementSchedule,
  patchSettlementSchedule,
  patchSettlementScheduleDates,
  postSettlementSchedule,
  postSettlementScheduleDates
} from 'services/settlementSchedulesApi'
import { sleep } from 'utils/functions'

const buildSettlementScheduleCollectionOps = (
  settlementScheduleId,
  existingEventsMap,
  unsavedEventsMap,
) => {
  const eventsToCreate = []
  const eventsToUpdate = []
  const eventsToDelete = []

  for (const dateKey in unsavedEventsMap) {
    const unsavedDayEvents = unsavedEventsMap[dateKey]

    for (const unsavedEvent of unsavedDayEvents) {
      const date = unsavedEvent.date.format('YYYY-MM-DD')
      const time = unsavedEvent.date.format('HH:mm')

      switch (unsavedEvent.type) {
        case EVENTS_ACTIONS.FORCE_CYCLE_START:
        case EVENTS_ACTIONS.FORCE_COLLECTION:
          eventsToCreate.push({
            date, time,
            settlementScheduleId,
            action: unsavedEvent.type,
          })
          break
        case EVENTS_ACTIONS.SKIP_CYCLE_START:
        case EVENTS_ACTIONS.SKIP_COLLECTION: {
          if (!unsavedEvent.details) {
            console.warn('missing details of existing event to skip; unknown operation to take, ignoring', { unsavedEvent })
            return
          }

          const existingEvent = existingEventsMap[date]?.find(event =>
            [ // events types that can be skipped
              BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START,
              BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION,
              BSD_CALENDAR_EVENTS.FORCE_CYCLE_START,
              BSD_CALENDAR_EVENTS.FORCE_COLLECTION,
            ].includes(event.type) &&
            unsavedEvent.date.isSame(event.date, 'minute')
          )

          if (!existingEvent) {
            console.warn('could not find existing event to skipped; unknown action to take, ignoring', { unsavedEvent })
            return
          }

          switch (existingEvent.type) {
            case BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START:
            case BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION:
              // skipping a scheduled event, create override
              eventsToCreate.push({
                date, time,
                settlementScheduleId,
                action: unsavedEvent.type,
              })
              break
            case BSD_CALENDAR_EVENTS.FORCE_CYCLE_START:
            case BSD_CALENDAR_EVENTS.FORCE_COLLECTION:
              // skipping a forced event, delete the override
              eventsToDelete.push(unsavedEvent.details.id)
              break
            default:
          }
          break
        }
        case EVENTS_ACTIONS.UN_SKIP_CYCLE_START:
        case EVENTS_ACTIONS.UN_SKIP_COLLECTION:
          if (!unsavedEvent.details?.id) {
            console.warn('un-skip event is missing details.id; don\'t know what to un-skip, ignoring', { unsavedEvent })
            break
          }

          eventsToDelete.push(unsavedEvent.details.id)
          break
        case EVENTS_ACTIONS.RESCHEDULE_FORCE_CYCLE_START:
        case EVENTS_ACTIONS.RESCHEDULE_FORCE_COLLECTION:
          eventsToUpdate.push({
            type: 'settlement-schedule-overrides',
            id: unsavedEvent.details.id,
            attributes: {
              date, time,
              settlementScheduleId,
              action: unsavedEvent.details.action,
            },
          })
          break
        default:
          console.warn('unknown how to save event', { unsavedEvent })
      }
    }
  }

  return { eventsToCreate, eventsToUpdate, eventsToDelete }
}

/** @param {string | undefined} id */
export const useGetPaymentProfile = (id) => useQuery(['paymentProfile', id], () => (
  id ? getPaymentProfile(id) : null
))
export const useGetPaymentProfiles = () => useQuery('paymentProfiles', () => getPaymentProfiles())
export const useGetSettlementSchedule = (settlementScheduleId) =>
  useQuery(['settlementSchedule', settlementScheduleId], ({ queryKey }) => getSettlementSchedule(queryKey[1]), {
    enabled: !!settlementScheduleId,
  })

export const useSaveSettlementSchedule = (
  settlementScheduleId,
  existingEventsMap,
  unsavedEventsMap,
  onSuccess = () => { },
) => {
  const [snackbar, setSnackbar] = useState({
    isOpened: false,
    isError: false,
    successOrErrorText: '',
  })
  const settlementScheduleMutation = useMutation(
    async (body) => {
      let settlementSchedule
      if (settlementScheduleId) {
        settlementSchedule = await patchSettlementSchedule(settlementScheduleId, body)
      } else {
        settlementSchedule = await postSettlementSchedule(body)
      }
      if (settlementScheduleId || settlementSchedule?.data?.id) {
        const {
          eventsToCreate, eventsToUpdate, eventsToDelete,
        } = buildSettlementScheduleCollectionOps(
          settlementScheduleId, existingEventsMap, unsavedEventsMap,
        )

        const operations = []
        if (eventsToCreate.length) {
          operations.push(postSettlementScheduleDates(eventsToCreate))
        }
        if (eventsToUpdate.length) {
          operations.push(patchSettlementScheduleDates(eventsToUpdate))
        }
        if (eventsToDelete.length) {
          operations.push(deleteSettlementScheduleDates(eventsToDelete))
        }
        await Promise.allSettled(operations)
      }
      return settlementSchedule
    },
    {
      onError: async (error) => {
        setSnackbar({
          isOpened: true,
          isError: true,
          successOrErrorText: error.toString(),
        })
        await sleep(ERROR_SNACKBAR_TIMEOUT)
        setSnackbar({
          isOpened: false,
          isError: false,
          successOrErrorText: '',
        })
      },
      onSuccess: async (settlementSchedule) => {
        setSnackbar({
          isOpened: true,
          isError: false,
          successOrErrorText: 'Settlement Schedule saved.',
        })
        await sleep(SUCCESS_SNACKBAR_TIMEOUT)
        setSnackbar({
          isOpened: false,
          isError: false,
          successOrErrorText: '',
        })
        onSuccess(settlementSchedule?.data)
      },
    },
  )
  return {
    snackbar,
    ...settlementScheduleMutation,
  }
}
