import { API_URL } from 'constants.js';
import httpFetch from 'utils/httpFetch'

const formatAmount = (amount) => {
    return amount == null || amount === ''
        ? '' : (amount / 100).toFixed(2)
}

const formatDate = (date) => {
    try {
        return date == null || date === ''
            ? '' : `"${new Date(date).toLocaleString()}"`
    } catch (e) {
        return formatTextField(date)
    }
}

const formatTextField = (date) => {
    return `"${date ?? ''}"`
}

const downloadAsCsv = (data, fileName) => {
    if (!data || !data.length) {
        return;
    }
    const csvHeader = Object.keys(data[0]).join(',');
    const csvRows = data.map((row) => Object.values(row).join(','));
    const csvString = csvHeader + '\n' + csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
}

export const downloadRemittances = async (summaryId) => {
    const response = await httpFetch(
        `${API_URL}/payments-statement/transactions-summary/${summaryId}/remittances`
    );
    downloadAsCsv(response?.data?.map((r) => ({
        'Payer': formatTextField(r.source?.name),
        'Payee': formatTextField(r.target?.name),
        'Currency': formatTextField(r.currency),
        'Amount': formatAmount(r.amount),
        'Payment Ref': formatTextField(r.paymentRef),
        'Cycle Phase': formatTextField(r.cyclePhase),
        'Payment Date': formatDate(r.paymentDate),
        'Payment For': formatTextField(r.paymentFor),
        'Payment Method': formatTextField(r.paymentMethod),
        'Reason Description': formatTextField(r.reasonDescription),
        'Status': formatTextField(r.status),
        'Updated At': formatDate(r.updatedAt),
        'Created At': formatDate(r.createdAt),
        // ...
    })), 'remittances.csv');
}

export const downloadInvoices = async (summaryId) => {
    const response = await httpFetch(
        `${API_URL}/payments-statement/transactions-summary/${summaryId}/invoices`
    );
    downloadAsCsv(response?.data?.map((trx) => ({
        'Booking Ref': formatTextField(trx.bookingRef),
        'Lead Name': formatTextField(trx.leadName),
        'Payee': formatTextField(trx.target?.name),
        'Departure Date': formatDate(trx.departureDate),
        'Currency': formatTextField(trx.currency),
        'Description': formatTextField(trx.description),
        'Status': formatTextField(trx.status),
        'Reason Code': formatTextField(trx.reasonCode),
        'ReasonDescription': formatTextField(trx.reasonDescription),
        'Amended Amount': formatAmount(trx.amendedAmount),
        'Created At': formatDate(trx.createdAt),
        'Updated At': formatDate(trx.updatedAt),
        'Imported At': formatDate(trx.importedAt),
        'Is Committed': formatTextField(trx.isCommitted),
        'Imported Target Id': formatTextField(trx.importedTargetId),
        'Earliest Due Date': formatDate(trx.earliestDueDate),
        'Commit Date': formatDate(trx.commitDate),
        'Collection At': formatDate(trx.collectionAt),
        'Buyer Review Period Start At': formatDate(trx.buyerReviewPeriodStartAt),
        'BRP Start Notification Sent': formatTextField(trx.brpStartNotificationSent),
        'BRP 24 Hours Notification Sent': formatTextField(trx.brp24HoursNotificationSent),
        'Collection PaymentAt': formatDate(trx.collectionPaymentAt),
        'Collection ClearedAt': formatDate(trx.collectionClearedAt),
        'Disbursement At': formatDate(trx.disbursementAt),
        'Disbursement Payment At': formatDate(trx.disbursementPaymentAt),
        'Cycle End At': formatDate(trx.cycleEndAt),
        'Buyer Notes': formatTextField(trx.buyerNotes),
        'Cycle Start At': formatDate(trx.cycleStartAt),
        'Trans Type': formatTextField(trx.transType),
        'Booking Nett Amount': formatAmount(trx.bookingNettAmount),
        'BookingComAmount': formatAmount(trx.bookingComAmount),
        'Booking Sell Amount': formatAmount(trx.bookingSellAmount),
        'Booking Nett Tax Amount': formatAmount(trx.bookingNettTaxAmount),
        'Booking Com Tax Amount': formatAmount(trx.bookingComTaxAmount),
        'Booking Sell Tax Amount': formatAmount(trx.bookingSellTaxAmount),
        'Tkt Voucher Number': formatTextField(trx.tktVoucherNumber),
        'Conf Number': formatTextField(trx.confNumber),
        'Trans Pax No': formatTextField(trx.transPaxNo),
        'Trans Product Type': formatTextField(trx.transProductType),
        'Destination': formatTextField(trx.destination),
        'Dest Country': formatTextField(trx.destCountry),
        'Optional 1': formatTextField(trx.optional_1),
        'Optional 2': formatTextField(trx.optional_2),
        'Optional 3': formatTextField(trx.optional_3),
        'Seller Notes': formatTextField(trx.sellerNotes),
        // ...
    })), 'invoices.csv');
}

