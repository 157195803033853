import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'

const HeaderRow = ({ children }) => (
  <TableHeaderRow.Row>
    {children}
  </TableHeaderRow.Row>
)

HeaderRow.propTypes = {
  children: PropTypes.node
}

export default HeaderRow
