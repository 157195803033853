import { connect } from 'react-redux'
import { push } from 'connected-react-router'

const mapState = ({ auth, companies }) => ({
  currUserCompany: companies.currUserCompany,
  currUserCompanyId: auth.companyId
})

const mapDispatch = dispatch => ({
  redirectTo: params => dispatch(push(params))
})

export const withConnect = connect(mapState, mapDispatch)
