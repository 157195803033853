import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  Typography
} from '@material-ui/core'
import Modal from 'components/Modal'
import { useIntl } from 'react-intl'

const ModalCreationSelector = ({ isProcessingEntity, handleClose }) => {
  const { formatMessage } = useIntl()
  const handleListItemClick = value => {
    handleClose(value)
  }

  useEffect(() => {
    if (!isProcessingEntity) {
      handleListItemClick('SELLER')
    }
  }, [ isProcessingEntity ])

  return (
    <Modal defaultOpened onClose={handleClose}>
      <Box mb={3}>
        <Typography variant="h6">
          {formatMessage({ id: 'commercial-link-entity.for-this-commercial-link-are-you-going-to-be' })}:
        </Typography>
      </Box>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item>
          <Button variant="outlined" color="primary" onClick={() => handleListItemClick('SELLER')}>{formatMessage({ id: 'commercial-link-entity.seller' })}</Button>
        </Grid>
        {
          isProcessingEntity &&
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => handleListItemClick('PROCESSING_ENTITY')}>{formatMessage({ id: 'commercial-link-entity.processing-entity' })}</Button>
          </Grid>
        }
      </Grid>
    </Modal>
  )
}

ModalCreationSelector.propTypes = {
  isProcessingEntity: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
}

ModalCreationSelector.displayName = 'ModalCreationSelector'

export default ModalCreationSelector
