import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@devexpress/dx-react-grid-material-ui'

const Cell = memo(({ tabIndex, column, row, ...restProps }) => {
  const hasEditComponent = column?.editComponent && !column?.disableEditing?.({ row })
  return <Table.Cell
    {...restProps}
    { ...(hasEditComponent || tabIndex !== undefined ? { tabIndex: tabIndex ?? 0 } : {}) }
  />
})

Cell.propTypes = {
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  row: PropTypes.object,
  column: PropTypes.object
}

Cell.displayName = 'Cell'

export default Cell
