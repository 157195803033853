import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Controller } from 'react-hook-form'
import { useStyles } from '../styles'
import Grid from '@material-ui/core/Grid'
import HelpPopup from 'components/HelpPopup'

const CompanyIdsForm = ({
  form: {
    control,
    formState: { errors },
  },
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid item container xs={12} justifyContent='center'>
        <Grid item md={6} sm={8} xs={10}>
          <Typography component="h1" variant="subtitle2">
            Company trading IDs
          </Typography>

          <Typography component="p" variant="subtitle1">
            Please select your trading IDs. These will appear on your Company Profile visible to other Travel Ledger companies.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent='center'>
        <Grid item container justifyContent='space-between' md={6} sm={8} xs={10}>
          <Controller
            control={control}
            name="emailID"
            render={({ field }) => (
              <TextField
                {...field}
                label="Email ID"
                variant="outlined"
                className={classes.inputWithTooltip}
                required
                error={!!errors.emailID}
                helperText={errors.emailID?.message}
              />
            )}
          />
          <div className={classes.tooltip}>
            <HelpPopup iconSize='large' id="registration-emailid" />
          </div>
        </Grid>
      </Grid>

      <Grid item container xs={12} justifyContent='center'>
        <Grid item md={6} sm={8} xs={10}>
          <Controller
            control={control}
            name="abtaID"
            render={({ field }) => (
              <TextField
                {...field}
                label="ABTA"
                variant="outlined"
                className={classes.inputWithTooltip}
                error={!!errors.abtaID}
                helperText={errors.abtaID?.message}
              />
            )}
          />
        </Grid>
      </Grid>
        
        <Grid item container xs={12} justifyContent='center'>
        <Grid item md={6} sm={8} xs={10}>
          <Controller
            control={control}
            name="iataID"
            render={({ field }) => (
              <TextField
                {...field}
                label="IATA"
                variant="outlined"
                className={classes.inputWithTooltip}
                error={!!errors.iataID}
                helperText={errors.iataID?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} justifyContent='center'>
        <Grid item md={6} sm={8} xs={10}>
          <Controller
            control={control}
            name="tID"
            render={({ field }) => (
              <TextField
                {...field}
                label="TIDS"
                variant="outlined"
                className={classes.inputWithTooltip}
                error={!!errors.tID}
                helperText={errors.tID?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

CompanyIdsForm.propTypes = {
  form: PropTypes.object,
}

export default CompanyIdsForm
