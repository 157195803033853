import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import { styled } from '@material-ui/core/styles'

const BackOffCountDown = ({ tryAgainAt, setIsBackOffBlocked }) => {

  const [counter, setCounter] = useState(0)
  const [time, setTime] = useState(null)

  useEffect(() => {
    if (tryAgainAt) {
      const diff = tryAgainAt - Date.now()
      setCounter(diff)
      setIsBackOffBlocked(true)
    }
  }, [tryAgainAt, setIsBackOffBlocked])

  useEffect(() => {
    const timer =
      counter > 0 ? setInterval(() => {
        setCounter(counter - 1000)
        setTime(toTime(counter))
      }, 1000) : setIsBackOffBlocked(false)
    return () => clearInterval(timer)
  }, [counter, setIsBackOffBlocked])

  const toTime = _time => {
    let seconds = Math.floor((_time / 1000) % 60),
      minutes = Math.floor(_time / (1000 * 60))

    minutes = minutes < 10 ? "0" + minutes : minutes
    seconds = seconds < 10 ? "0" + seconds : seconds

    return {
      seconds,
      minutes
    }
  }

  const BackOffAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(-1),
  }))

  const isBackOffBlocked = counter > 0
  return (
    <>
      {isBackOffBlocked && time &&
        <BackOffAlert severity="warning">
          <b>Blocked</b> Try again in {`${time.minutes}:${time.seconds}`}
        </BackOffAlert>
      }
    </>
  )
}

BackOffCountDown.propTypes = {
  tryAgainAt: PropTypes.number,
  setIsBackOffBlocked: PropTypes.func
}

export default BackOffCountDown
