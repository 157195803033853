import { all, call, put, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import _invert from 'lodash/invert'
import { takeFirst } from 'utils/generators'
import httpFetch from 'utils/httpFetch'
import { toFloat } from 'utils/functions'
import { CURRENCY_CODE, API_URL, TRANSACTION_TYPE_TO_NAME } from 'constants.js'
import { ACTIONS } from 'store/transactions'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'

// Functions
export const getTransactions = async () => {
  const url = `${API_URL}/transactions`
  return httpFetch(url)
}

export const performPostTransaction = async postData => {
  const currentData = postData
  const url = `${API_URL}/transaction`
  const type = parseInt(_invert(TRANSACTION_TYPE_TO_NAME)[postData.transactionType])
  const source = postData.source
  const target = postData.target.address
  const amount = toFloat(postData.totalAmount)
  const currency = CURRENCY_CODE[postData.transactionCurrency]
  currentData.paymentSchedule = currentData.paymentSchedule.map(schedule => ({
    amount: schedule.amount,
    dueDate: schedule.dueDate
  }))
  delete currentData.lineCount
  delete currentData.paymentScheduleCount
  delete currentData.type
  delete currentData.source
  delete currentData.target
  delete currentData.totalAmount
  delete currentData.customSource
  delete currentData.customTarget
  delete currentData.transactionType
  delete currentData.allocateTransaction
  delete currentData.transactionAllocations
  delete currentData.transactionCurrency
  const transaction = {
    type,
    source,
    target,
    amount,
    currency,
    infos: postData
  }
  const data = {
    method: 'POST',
    body: JSON.stringify(transaction)
  }

  return httpFetch(url, data)
}

export const performPatchTransaction = async patchData => {
  try {
    const url = `${API_URL}/transaction/${patchData.id}/${patchData.target}`
    const body = { [patchData.target]: patchData.value }
    const data = {
      method: 'PATCH',
      body: JSON.stringify(body)
    }
    return httpFetch(url, data)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const performChangeTransactionStatus = changeData => {
  try {
    const url = `${API_URL}/transaction/${changeData.id}/${changeData.status ? 'unreject' : 'reject'}`
    const body = { rejected_amount: 0, rejected_text: 'Rejected' } // ToDo: Feed those here from a form
    const data = {
      method: 'PATCH',
      body: JSON.stringify(body)
    }
    return httpFetch(url, data)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const performPostAllocation = allocationData => {
  const url = `${API_URL}/allocation`
  const data = {
    method: 'POST',
    body: JSON.stringify({ ...allocationData, target: allocationData.target.id })
  }
  return httpFetch(url, data)
}

// Saga helpers
const frontendTransaction = transaction => {
  const transactionData = Object.assign({}, transaction)
  transactionData.id = transactionData.ref
  delete transactionData.ref
  return transactionData
}

export function* putError(error) {
  yield put({
    type: AUTH_ACTIONS.SET_ERROR,
    payload: error
  })
  yield delay(1000)
  yield put({
    type: AUTH_ACTIONS.SET_ERROR,
    payload: false
  })
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false
  })
}

// Sagas
/*
export saga* fetchSomething(action) {
  1. put SET_FETCHING to true // to make spinner appear
  2. call performer to perform the task needed
  3. check result, if bad, throw error
  4. put ACTION_SUCCESS if necessary
  5. In catch, log error and putError
  6. put SET_FETCHING to false // to make spinner dissapear
}
*/
// ToDo: Write a fetchingSaga wrapper

export function* fetchTransactions() {
  const { FETCH_TRANSACTIONS_SUCCESS } = ACTIONS
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: true
  })
  try {
    const transactions = yield call(getTransactions)
    if (!transactions) {
      throw Error('Received empty or null transactions')
    } else if (transactions.error) {
      yield call(putError, `${transactions.message} ${transactions.extraMessage || ''}`)
      return
    } else {
      yield put({
        type: FETCH_TRANSACTIONS_SUCCESS,
        payload: transactions.map(transaction => frontendTransaction(transaction))
      })
    }
  } catch (error) {
    console.error(error)
    yield call(putError, error)
  }
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false
  })
}

export function* postTransaction(action) {
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: true
  })
  try {
    let allocations = null
    if (action.payload.transactionAllocations && action.payload.transactionAllocations.length) {
      allocations = action.payload.transactionAllocations && action.payload.transactionAllocations
    }
    const result = yield call(performPostTransaction, action.payload)

    if (!result) {
      throw Error('Unable to post transaction')
    } else if (result.error) {
      yield call(putError, `${result.message} ${result.extraMessage || ''}`)
      return
    }

    if (allocations) {
      const source = result.ref
      yield allocations.map(function* (allocation) {
        yield put({
          type: ACTIONS.POST_TRANSACTION_ALLOCATION,
          payload: {
            source,
            target: allocation.allocationTarget,
            amount: allocation.amount
          }
        })
      })
    }

    if (result.success) {
      yield call(fetchTransactions)
    } else {
      throw Error('Unable to post transaction - Unknown response')
    }
  } catch (error) {
    console.error(error)
    yield call(putError, error)
  }
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false
  })
}

export function* patchTransaction(action) {
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: true
  })
  try {
    const result = yield call(performPatchTransaction, action.payload)
    if (!result) {
      throw Error('Unable to patch transaction')
    } else if (result.error) {
      yield call(putError, `${result.message} ${result.extraMessage || ''}`)
      return
    } else if (result.success) {
      yield call(fetchTransactions)
    } else {
      throw Error('Unable to patch transaction - Unknown response')
    }
  } catch (error) {
    console.error(error)
    yield call(putError, error)
  }
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false
  })
}

// ToDo: Reduce boilerplate
export function* rejectTransaction(action) {
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: true
  })
  try {
    const result = yield call(performChangeTransactionStatus, { id: action.payload, status: false })
    if (!result) {
      throw Error('Unable to reject transaction')
    } else if (result.error) {
      yield call(putError, `${result.message} ${result.extraMessage || ''}`)
      return
    } else if (result.success) {
      yield call(fetchTransactions)
    } else {
      throw Error('Unable to reject transaction - Unknown response')
    }
  } catch (error) {
    console.error(error)
    yield call(putError, error)
  }
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false
  })
}

export function* unRejectTransaction(action) {
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: true
  })
  try {
    const result = yield call(performChangeTransactionStatus, { id: action.payload, status: true })
    if (!result) {
      throw Error('Unable to unreject transaction')
    } else if (result.error) {
      yield call(putError, `${result.message} ${result.extraMessage || ''}`)
      return
    } else if (result.success) {
      yield call(fetchTransactions)
    } else {
      throw Error('Unable to unreject transaction - Unknown response')
    }
  } catch (error) {
    console.error(error)
    yield call(putError, error)
  }
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false
  })
}

export function* postTransactionAllocation(action) {
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: true
  })
  try {
    const result = yield call(performPostAllocation, action.payload)
    if (!result) {
      throw Error('Unable to post allocation')
    } else if (result.error) {
      yield call(putError, `${result.message} ${result.extraMessage || ''}`)
      return
    } else if (result.success) {
      yield call(fetchTransactions)
    } else {
      throw Error('Unable to post allocation - Unknown response')
    }
  } catch (error) {
    console.error(error)
    yield call(putError, error)
  }
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false
  })
}

// Watchers
function* watchFetchTransactions() {
  const { FETCH_TRANSACTIONS } = ACTIONS
  yield takeFirst(FETCH_TRANSACTIONS, fetchTransactions)
}

function* watchPostTransaction() {
  const { POST_TRANSACTION } = ACTIONS
  yield takeFirst(POST_TRANSACTION, postTransaction)
}

function* watchPatchTransaction() {
  const { PATCH_TRANSACTION } = ACTIONS
  yield takeLatest(PATCH_TRANSACTION, patchTransaction)
}

function* watchRejectTransaction() {
  const { REJECT_TRANSACTION } = ACTIONS
  yield takeLatest(REJECT_TRANSACTION, rejectTransaction)
}

function* watchUnjrejectTransaction() {
  const { UNREJECT_TRANSACTION } = ACTIONS
  yield takeLatest(UNREJECT_TRANSACTION, unRejectTransaction)
}

function* watchPostTransactionAllocation() {
  const { POST_TRANSACTION_ALLOCATION } = ACTIONS
  yield takeLatest(POST_TRANSACTION_ALLOCATION, postTransactionAllocation)
}

export default function* rootSaga() {
  yield all([
    watchFetchTransactions(),
    watchPostTransaction(),
    watchPatchTransaction(),
    watchRejectTransaction(),
    watchUnjrejectTransaction(),
    watchPostTransactionAllocation()
  ])
}
