import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Spinner from './components/Spinner'
import GlobalSpinnerContextProvider from './components/GlobalSpinnerContextProvider'
import { theme } from './theme'
import { ToastContainer, toast } from 'react-toastify'
import { ThemeProvider } from '@material-ui/core/styles'
import 'react-toastify/dist/ReactToastify.css'
import NavigationDrawer from 'components/NavigationDrawer'
import FormattedError from 'components/FormattedError'
import { QueryClient, QueryClientProvider, useIsFetching } from 'react-query'
import { useStyles } from './styles'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import WarningIcon from '@material-ui/icons/Warning'
import { API_URL, isProduction } from 'constants.js'
import SessionTimeout from './components/SessionTimeout'
import { getLanguage, updateLanguage } from 'services/usersApi'
import { getDefaultLanguage } from './utils'
import LanguageConfirmationDialog from './components/LanguageConfirmationDialog'
import { BREADCRUMB_CONTEXT_DEFAULT_VALUES, BreadcrumbContextProvider } from './components/BreadcrumbContext'
import { get } from 'utils/api'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const App = ({
  auth,
  logout,
  refreshToken,
  company,
  children,
  push,
  fetchCurrUserCompany,
  getTokenExpiry,
  onLanguageChange,
  language,
}) => {

  const classes = useStyles()
  const [isConfirmLanguageDialogOpen, setIsConfirmLanguageDialogOpen] = React.useState(false)
  const targetLanguageRef = React.useRef(null)
  const [tabVisibility, setTabVisibility] = React.useState(BREADCRUMB_CONTEXT_DEFAULT_VALUES.visibility)
  const [isFetchingInstantPaymentsTabVisibility, setIsFetchingInstantPaymentsTabVisibility] = React.useState(false)

  useEffect(() => {
    if (auth && auth.companyId && !company.id) {
      fetchCurrUserCompany([])
    }
  }, [ fetchCurrUserCompany, auth ])

  useEffect(() => {
    if (auth?.id) {
      const MAX_RETRY_COUNT = 3
      queryClient.fetchQuery(
        `get-language-settings--${auth.id}`,
        () => getLanguage(),
        { retry: MAX_RETRY_COUNT },
      )
        .then(res => {
          onLanguageChange(res?.data?.language || getDefaultLanguage())
        })

      setIsFetchingInstantPaymentsTabVisibility(true)
      queryClient.fetchQuery(
        'instant-payments-tab-visibility',
        () => get(`${API_URL}/instant-payments/visibility`),
        { retry: MAX_RETRY_COUNT },
      )
        .then(res => {
          setTabVisibility(prev => ({
            ...prev,
            instantPayments: res?.visible,
          }))
        })
        .catch(console.error)
        .then(() => setIsFetchingInstantPaymentsTabVisibility(false))
    }
  }, [auth?.id])

  const isFetching = useIsFetching()

  useEffect(() => {
    if (auth.encounteredError) {
      toast.error(<FormattedError error={auth.encounteredError} />)
    }
  }, [ auth.encounteredError ])

  const handleLanguageConfirmationDialogOpen = (lang) => {
    Object.assign(targetLanguageRef, { current: lang })
    setIsConfirmLanguageDialogOpen(true)
  }

  const handleLanguageSettingsUpdate = async () => {
    const lang = targetLanguageRef.current

    if (lang) {
      await updateLanguage(lang)
      onLanguageChange(lang)
    }

    setIsConfirmLanguageDialogOpen(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <SessionTimeout auth={auth} logout={logout} refreshToken={refreshToken} getTokenExpiry={getTokenExpiry}>
        <QueryClientProvider client={queryClient}>
          <BreadcrumbContextProvider value={{
            ...BREADCRUMB_CONTEXT_DEFAULT_VALUES,
            visibility: tabVisibility,
            isLoading: isFetchingInstantPaymentsTabVisibility,
          }}>
            <ToastContainer
              position="top-right"
              closeOnClick
              draggable
              autoClose={false}
              className={classes.toastify}
            />
            <div>
              <NavigationDrawer
                language={language}
                onLanguageSettingsChange={handleLanguageConfirmationDialogOpen}
                invisible={!auth.token}
                push={push}
                isProcessingEntity={company.isProcessingEntity}
                auth={auth}
                logout={logout}
                company={company}
              >
                {() => (
                  <GlobalSpinnerContextProvider>
                    {<Spinner open={auth.fetching || !!isFetching} />}
                    {children}
                  </GlobalSpinnerContextProvider>
                )}
              </NavigationDrawer>

              <LanguageConfirmationDialog
                open={isConfirmLanguageDialogOpen && !!targetLanguageRef.current}
                onClose={() => setIsConfirmLanguageDialogOpen(false)}
                onConfirm={handleLanguageSettingsUpdate}
                language={targetLanguageRef.current}
              />

              {!isProduction && <Box className={classes.alertWrapper}>
                <Alert
                  severity="error"
                  variant="filled"
                  icon={<WarningIcon size="24" className={classes.alertIcon} />}
                  className={classes.alert}
                >
                  <Typography>This is a Test environment - Transactions will not collect any payments.</Typography>
                </Alert>
              </Box>}
            </div>
          </BreadcrumbContextProvider>
        </QueryClientProvider>
      </SessionTimeout>
    </ThemeProvider>
  )
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  fetchCurrUserCompany: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]),
  push: PropTypes.func.isRequired
}

const withQueryClientProvider = (Component) => (props) => <QueryClientProvider client={queryClient}><Component {...props} /></QueryClientProvider>

export default withQueryClientProvider(App)
