import { fullName } from 'utils/functions'
const FETCH_COMMERCIAL_LINKS = fullName('fetch-commercial-links', 'FETCH_COMMERCIAL_LINKS')
const FETCH_COMMERCIAL_LINKS_SUCCESS = fullName('fetch-commercial-links', 'FETCH_COMMERCIAL_LINKS_SUCCESS')
const CREATE_COMMERCIAL_LINK = fullName('create-link', 'CREATE_COMMERCIAL_LINK')
const ADD_COMMERCIAL_LINKS = fullName('add-link', 'ADD_COMMERCIAL_LINKS')
const ADD_COMMERCIAL_LINKS_SUCCESS = fullName('add-link', 'ADD_COMMERCIAL_LINKS_SUCCESS')
const ADD_COMMERCIAL_LINKS_ERROR = fullName('add-link', 'ADD_COMMERCIAL_LINKS_ERROR')
const PATCH_COMMERCIAL_LINK = fullName('patch-link', 'PATCH_COMMERCIAL_LINK')
const PATCH_COMMERCIAL_LINK_SUCCESS = fullName('patch-link', 'PATCH_COMMERCIAL_LINK_SUCCESS')
const PATCH_COMMERCIAL_LINK_ERROR = fullName('patch-link', 'PATCH_COMMERCIAL_LINK_ERROR')
const SEARCH_COMMERCIAL_LINK = fullName('search-link', 'SEARCH_COMMERCIAL_LINK')
const SEARCH_COMMERCIAL_LINK_SUCCESS = fullName('search-link-success', 'SEARCH_COMMERCIAL_LINK_SUCCESS')
const CLOSE_SNACKBAR = fullName('add-link', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_COMMERCIAL_LINKS,
  FETCH_COMMERCIAL_LINKS_SUCCESS,
  CREATE_COMMERCIAL_LINK,
  ADD_COMMERCIAL_LINKS,
  ADD_COMMERCIAL_LINKS_SUCCESS,
  ADD_COMMERCIAL_LINKS_ERROR,
  PATCH_COMMERCIAL_LINK,
  PATCH_COMMERCIAL_LINK_SUCCESS,
  PATCH_COMMERCIAL_LINK_ERROR,
  SEARCH_COMMERCIAL_LINK,
  SEARCH_COMMERCIAL_LINK_SUCCESS,
  CLOSE_SNACKBAR
}

export const fetchCommercialLinks = payload => ({
  type: FETCH_COMMERCIAL_LINKS,
  payload
})

export const postCommercialLinks = payload => ({
  type: ADD_COMMERCIAL_LINKS,
  payload
})

export const patchCommercialLink = payload => ({
  type: PATCH_COMMERCIAL_LINK,
  payload
})

export const searchCommercialLinks = payload => ({
  type: SEARCH_COMMERCIAL_LINK,
  payload
})

const initialState = {
  links: [],
  searchLinks: [],
  snackbars: false,
  error: false,
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

export const commercialLinks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMERCIAL_LINKS_SUCCESS:
      return {
        ...state,
        links: action.payload.data,
        snackbars: false,
        error: false
      }
    case ADD_COMMERCIAL_LINKS_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: 'Commercial Link added.'
        },
        error: false
      }
    case ADD_COMMERCIAL_LINKS_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        },
        error: true
      }
    case PATCH_COMMERCIAL_LINK_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: 'Commercial Link updated.'
        }
      }
    case PATCH_COMMERCIAL_LINK_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: 'Cannot update commercial link'
        }
      }
    case SEARCH_COMMERCIAL_LINK_SUCCESS:
      return {
        ...state,
        searchLinks: action.payload.data
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbars: false,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    default:
      return state
  }
}
