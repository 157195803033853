import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  fieldSet: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  topTransactionPaper: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2)
  },
  gridTransactionsHeader: {
    justifyContent: 'space-between'
  },
  form: {
    width: '100%'
  },
  topPaper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  textField: {
    marginRight: theme.spacing(1)
  },
  fabMargin: {
    marginLeft: theme.spacing(3)
  },
  fabExtendedIcon: {
    marginRight: theme.spacing(1)
  },
  buttonSpacing: {
    marginTop: theme.spacing(4)
  },
  formInput: {
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px'
  },
  fieldsMargin: {
    paddingRight: '15px',
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px'
  },
  dialog: {
    minWidth: 600
  },
  dialogTitle: {
    flex: 'inherit',
    textAlign: 'center'
  },
  dialogContent: {
    margin: '-10px 20px 10px 20px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogItem: {
    textAlign: 'center',
    padding: '15px',
    flex: '1',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  errorContainer: {
    borderRadius: '5px'
  },
  errorText: {
    color: '#8b0000',
    margin: '5px 10px'
  },
  summaryTableContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto'
  },
  summaryTableHeaderRow: {
    position: 'sticky',
    top: 0,
    '&>th': {
      backgroundColor: '#fff'
    }
  }
}))
