import React, { memo, useMemo } from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'clsx'
import TableCell from '@material-ui/core/TableCell'
import {
  HORIZONTAL_GROUP_ORIENTATION,
  VERTICAL_GROUP_ORIENTATION,
} from '@devexpress/dx-scheduler-core'
import { BSD_CALENDAR_EVENTS } from 'constants.js'
import { useStyles } from './styles'
import { Cached, AttachMoney } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'

const COLORS = {
  GREEN: 'GREEN',
  RED: 'RED',
  ORANGE: 'ORANGE',
  TRANSPARENT: 'TRANSPARENT',
}

const Cell = ({
  className,
  today,
  otherMonth,
  isShaded,
  dayDate,
  dayEvents,
  dayType,
  endOfGroup,
  groupOrientation,
  hasRightBorder,
  onClick,
}) => {
  const isFirstMonthDay = dayDate.date() === 1
  const formatOptions = isFirstMonthDay && !today ? 'MMM D' : 'D'
  const classes = useStyles()

  const collectionStatusColor = useMemo(() => {
    const hasCollectionEvents = dayEvents.some((ev) =>
      [
        BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION,
        BSD_CALENDAR_EVENTS.FORCE_COLLECTION,
        BSD_CALENDAR_EVENTS.SKIP_COLLECTION,
      ].includes(ev.type),
    )

    if (!hasCollectionEvents) {
      return COLORS.TRANSPARENT
    }

    if (!dayEvents.some((ev) => ev.type === BSD_CALENDAR_EVENTS.SKIP_COLLECTION)) {
      // no skips, so all active
      return COLORS.GREEN
    }

    if (
      dayEvents.some(ev => ev.type === BSD_CALENDAR_EVENTS.SKIP_COLLECTION) &&
      !dayEvents.some(ev =>
        ev.type === BSD_CALENDAR_EVENTS.FORCE_COLLECTION ||
        ev.type === BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION
      )
    ) {
      // there are skips and no active
      return COLORS.RED
    }

    // there are skips and active ones
    return COLORS.ORANGE
  }, [dayEvents])

  const cycleStatusColor = useMemo(() => {
    const hasCycleStartEvents = dayEvents.some((ev) =>
      [
        BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START,
        BSD_CALENDAR_EVENTS.FORCE_CYCLE_START,
        BSD_CALENDAR_EVENTS.SKIP_CYCLE_START,
      ].includes(ev.type),
    )

    if (!hasCycleStartEvents) {
      return COLORS.TRANSPARENT
    }

    if (!dayEvents.some((ev) => ev.type === BSD_CALENDAR_EVENTS.SKIP_CYCLE_START)) {
      // no skips, all active
      return COLORS.GREEN
    }

    if (
      dayEvents.some(ev => ev.type === BSD_CALENDAR_EVENTS.SKIP_CYCLE_START) &&
      !dayEvents.some(ev =>
        ev.type === BSD_CALENDAR_EVENTS.FORCE_CYCLE_START ||
        ev.type === BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START
      )
    ) {
      // there are skips and no active
      return COLORS.RED
    }

    // there are skips and active ones
    return COLORS.ORANGE
  }, [dayEvents])

  if (otherMonth) {
    return <TableCell className={classes.cell} onClick={onClick} />
  }

  return (
    <TableCell
      key={dayDate.format('YYYY-MM-DD')}
      tabIndex={0}
      className={classNames(
        {
          [classes.cell]: true,
          [classes.shadedCell]: isShaded,
          [classes.brightRightBorder]:
            (endOfGroup || hasRightBorder) && groupOrientation === HORIZONTAL_GROUP_ORIENTATION,
          [classes.brightBorderBottom]: endOfGroup && groupOrientation === VERTICAL_GROUP_ORIENTATION,
        },
        className,
      )}
      onClick={onClick}
    >
      <div
        className={classNames({
          [classes[dayType]]: dayType !== undefined,
          [classes.text]: !today,
          [classes.otherMonth]: otherMonth && !today,
        })}
      >
        <Grid container justify="space-between">
          <Grid item>
            <Cached
              fontSize="small"
              className={classes[cycleStatusColor]}
            />
          </Grid>
          <Grid item className={classNames({
            [classes.today]: today,
          })}>
            {dayDate.format(formatOptions)}
          </Grid>
          <Grid item>
            <AttachMoney
              fontSize="small"
              className={classes[collectionStatusColor]}
            />
          </Grid>
        </Grid>
      </div>
    </TableCell>
  )
}

Cell.propTypes = {
  dayDate: PropTypes.instanceOf(moment).isRequired,
  dayEvents: PropTypes.array.isRequired,
  dayType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  today: PropTypes.bool,
  otherMonth: PropTypes.bool,
  isShaded: PropTypes.bool,
  endOfGroup: PropTypes.bool,
  hasRightBorder: PropTypes.bool,
  groupOrientation: PropTypes.oneOf([HORIZONTAL_GROUP_ORIENTATION, VERTICAL_GROUP_ORIENTATION]),
}

Cell.defaultProps = {
  endDate: undefined,
  className: undefined,
  today: false,
  otherMonth: false,
  isShaded: false,
  event: undefined,
  endOfGroup: false,
  hasRightBorder: false,
  groupingInfo: undefined,
  groupOrientation: HORIZONTAL_GROUP_ORIENTATION,
}

Cell.displayName = 'Cell'

export default memo(Cell)
