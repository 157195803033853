import * as React from 'react'
import PropTypes from 'prop-types'
import { Getter, Plugin } from '@devexpress/dx-react-core'
import { sortedRows, getColumnExtension } from '@devexpress/dx-grid-core'

const pluginDependencies = [
  { name: 'SortingState' }
]

const CustomSorting = ({ columnExtensions }) => {
  const getColumnCompare = columnName => (getColumnExtension(columnExtensions, columnName)).compare

  const rowsComputed = (
    { rows, sorting, isGroupRow, getRowLevelKey }
  ) => {
    const getCellValue = (row, columnName) => {
      return row[columnName]
    }
    return sortedRows(rows, sorting, getCellValue, getColumnCompare, isGroupRow, getRowLevelKey)
  }

  return (
    <Plugin
      name="CustomSorting"
      dependencies={pluginDependencies}
    >
      <Getter name="rows" computed={rowsComputed} />
    </Plugin>
  )
}

CustomSorting.propTypes = {
  columnExtensions: PropTypes.array
}

export default CustomSorting
