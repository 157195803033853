import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

const EditButton = ({ onExecute, disabled }) => (
  <IconButton onClick={onExecute} title="Cancel" disabled={disabled}>
    <EditIcon />
  </IconButton>
)

EditButton.propTypes = {
  onExecute: PropTypes.func,
  disabled: PropTypes.bool
}

export default EditButton
