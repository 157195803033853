import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save'

const SaveButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel">
    <SaveIcon />
  </IconButton>
)

SaveButton.propTypes = {
  onExecute: PropTypes.func
}

export default SaveButton
