import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1),
    marginTop: theme.spacing(1)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(2),
      width: '550px'
    }
  },
  childForm: {
    padding: theme.spacing(1, 1),
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(2)
    }
  },
  fab: {
    width: '180px',
    marginTop: '30px'
  },
  fabIcon: {
    marginRight: theme.spacing(1)
  },
  dropZone: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(0, 2),
    borderColor: theme.palette.error.main
  }
}))
