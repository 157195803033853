import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import SimulationDetailsContent from './SimulationDetailsContent'
import { useGetCommercialLinkCollectionEvents } from '../../hooks'

const getCheckedCommercialLinks = commercialLinks => commercialLinks.filter(commercialLink => commercialLink.checked === true)

const SimulationDetails = ({ commercialLinks, simulationDate }) => {
  const checkedCommercialLinks = useMemo(() => getCheckedCommercialLinks(commercialLinks), [ commercialLinks ])
  const [ activeTab, setActiveTab ] = useState(0)
  const collectionEvent = useGetCommercialLinkCollectionEvents(checkedCommercialLinks[activeTab]?.id, simulationDate)

  const handleChange = useCallback((_, newValue) => {
    if (checkedCommercialLinks.length) {
      setActiveTab(newValue)
    }
  }, [ checkedCommercialLinks, simulationDate ])

  useEffect(() => {
    handleChange(null, 0)
  }, [ commercialLinks, handleChange ])

  if (!simulationDate || !checkedCommercialLinks.length) {
    return null
  }
  return (
    <Card>
      <CardContent>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {checkedCommercialLinks.map((commercialLink, index) => (
            <Tab
              key={commercialLink.id}
              component="a"
              onClick={(event) => {
                event.preventDefault()
              }}
              label={`${index + 1}. ${commercialLink.displayName}`}
            />
          ))}
        </Tabs>
        { collectionEvent.data ? (
          <SimulationDetailsContent collectionEvent={collectionEvent} />
        ) : null }
      </CardContent>
    </Card>
  )
}

SimulationDetails.propTypes = {
  commercialLinks: PropTypes.array,
  simulationDate: PropTypes.string
}

export default SimulationDetails
