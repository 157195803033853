import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { get, patch } from '../../utils/api'
import AuthView from '../AuthView'
import UserForm from './components/UserForm'
import { ROUTE_URL } from '../../constants'
import { useStyles } from './styles'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import { kebabCase } from 'lodash'
import { useIntl } from 'react-intl'

const roles = {
  ADMIN: 'ADMIN',
  NORMAL: 'NORMAL',
}

const _user = {
  name: { value: '', err: '' },
  email: { value: '', err: '' },
  role: { value: '', err: '' },
}

function getValues(user) {
  return Object.entries(user).reduce((accm, [key, { value }]) => {
    const v = key === 'email' && value === '' ? null : value

    return { ...accm, [key]: v }
  }, {})
}

async function getUser(id, { setUser }) {
  const { data } = await get(`/users/${id}`)
  const { attributes } = data
  const user = Object.entries(attributes).reduce((accm, [key, value]) => {
    if (accm[key]) {
      return { ...accm, [key]: { value, err: '' } }
    }
    return accm
  }, _user)

  setUser(user)
}

async function handleSubmit(user, { url, method, history }) {
  const usr = getValues(user, method === patch)
  try {
    await method(url, { ...usr })
    history.push(ROUTE_URL.entityProfile)
  } catch (error) {
    toast.error(getReadableErrorMessage(error))
  }
}

function User({ location, history }) {
  const [user, setUser] = useState(_user)
  const { id } = useParams()
  const opt = { method: patch, url: `/users/${id}` }
  const classes = useStyles()
  const { formatMessage } = useIntl()

  useEffect(() => {
    id && getUser(id, { setUser })
  }, [id])

  return (
    <AuthView location={location}>
      <Paper className={classes.root}>
        <UserForm
          user={user}
          id={id}
          roles={Object.fromEntries(
            Object.entries(roles)
              .map(([k, v]) => [k, formatMessage({ id: `user-roles.${kebabCase(v)}` })]),
          )}
          setUserProp={(key, { value, err }) => setUser({ ...user, [key]: { value, err } })}
          submit={() => handleSubmit(user, { ...opt, setUser, history })}
        />
      </Paper>
    </AuthView>
  )
}

User.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}

export default User
