import { fullName } from 'utils/functions'

// Action Names
const UPLOAD_CSV = fullName('csv', 'UPLOAD_CSV')
const DOWNLOAD_CSV = fullName('csv', 'DOWNLOAD_CSV')
const SET_UPLOAD_CSV_ERROR = fullName('csv', 'SET_UPLOAD_CSV_ERROR')
const SET_UPLOAD_CSV_SUCCESS = fullName('csv', 'SET_UPLOAD_CSV_SUCCESS')

export const ACTIONS = {
  UPLOAD_CSV,
  DOWNLOAD_CSV,
  SET_UPLOAD_CSV_ERROR,
  SET_UPLOAD_CSV_SUCCESS
}

export const uploadCSV = uploadData => ({
  type: UPLOAD_CSV,
  payload: uploadData
})

export const downloadCSV = downloadData => ({
  type: DOWNLOAD_CSV,
  payload: downloadData
})

export const setUploadCSVError = error => ({
  type: SET_UPLOAD_CSV_ERROR,
  payload: error
})

const initialState = {
  error: null,
  success: false,
  fetching: false,
}

const csv = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPLOAD_CSV_ERROR:
      return {
        ...state,
        error: action.payload,
        success: false,
        fetching: false
      }
    case UPLOAD_CSV:
      return {
        ...state,
        success: false,
        fetching: true
      }
    case SET_UPLOAD_CSV_SUCCESS:
      return {
        ...state,
        success: true,
        fetching: false
      }
    default:
      return state
  }
}

export default csv
