import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(() => ({
  // TODO: Check with client if clickable label is acceptable
  root: {
    // pointerEvents: 'none'
  },
  switch: {
    pointerEvents: 'auto'
  }
}))
