import { connect } from 'react-redux'
import { push, goBack } from 'connected-react-router'
import { createRemittance } from 'store/remittances'
import { fetchInvoices, createInvoice } from 'store/invoices'
import { searchCommercialLinks } from 'store/commercialLinks'
import { searchCompanies, clearSearchCompaniesData } from 'store/companies'

const mapState = ({ auth, companies, commercialLinks, invoices, remittances }) => ({
  invoicesSnackbar: invoices.snackbar,
  selectedInvoices: invoices.selected,
  remittancesSnackbar: remittances.snackbar,
  currUserCompanyId: auth.companyId,
  invoices: invoices.committed.list,
  companies: companies.searchCompany.data,
  links: commercialLinks.searchLinks
})

const mapDispatch = dispatch => ({
  redirectTo: data => dispatch(push(data)),
  goBack: () => dispatch(goBack()),
  createInvoice: data => dispatch(createInvoice(data)),
  fetchInvoices: data => dispatch(fetchInvoices(data)),
  createRemittance: data => dispatch(createRemittance(data)),
  searchCompanies: data => dispatch(searchCompanies(data)),
  clearSearchCompaniesData: () => dispatch(clearSearchCompaniesData()),
  searchCommercialLinks: data => dispatch(searchCommercialLinks(data))
})

export const withConnect = connect(mapState, mapDispatch)
