import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Template, TemplatePlaceholder, Plugin, TemplateConnector
} from '@devexpress/dx-react-core'

const ExtraToolbar = ({
  content
}) => (
  <Plugin
    name="ExtraToolbar"
    dependencies={[
      { name: 'Toolbar' }
    ]}
  >
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <TemplateConnector>
        {() => content}
      </TemplateConnector>
    </Template>
  </Plugin>
)

ExtraToolbar.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default ExtraToolbar
