import React from 'react'
import PropTypes from 'prop-types'
import { columnsInvite } from './columns'
import Grid from '@material-ui/core/Grid'
import HelpPopup from 'components/HelpPopup'
import DataGrid from 'components/DataGrid'
import InvitesErrorAlert from './components/InvitesErrorAlert'
import { useQuery } from 'react-query'
import { getInvites } from 'services/invitesApi'
import { Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'

const InvitesTableTitle = () => <HelpPopup id="users.invites.title">Pending invites</HelpPopup>

const UsersInvites = ({ failedInvites = [], company }) => {
  const { formatMessage } = useIntl()

  if (!company) {
    return <></>
  }
  const { data: invitesData } = useQuery(`invites-${company.id}`, () => getInvites(company.id))

  const invites = (invitesData?.data || []).map((invite) => ({
    ...invite,
    ...invite.inviteDetails,
  }))

  return (
    <>
      <Grid container spacing={3}>
        <Typography variant="h1">User invites</Typography>
        {failedInvites?.length ? <InvitesErrorAlert failedInvites={failedInvites} /> : null}
        <Grid item xs={12} sm={12}>
          <DataGrid title={InvitesTableTitle} name="invites" columns={columnsInvite(formatMessage)} data={invites} />
        </Grid>
      </Grid>
    </>
  )
}

UsersInvites.propTypes = {
  auth: PropTypes.object,
  failedInvites: PropTypes.array,
  myCompany: PropTypes.object,
  company: PropTypes.object,
}

export default UsersInvites
