import { SUBSCRIPTION_STATUS, COMPANY_STATUS } from 'constants.js'

export const CL_FILTERS_VALUES = {
  ALL: 'ALL',
  YES: 'YES',
  NO: 'NO',
}

export const CL_FILTERS_VALUES_TO_NAMES = {
  ALL: '(all)',
  YES: 'Yes',
  NO: 'No',
}

export const SUBSCRIPTION_STATUS_OPTIONS = {
  ALL: '(all)',  
  [SUBSCRIPTION_STATUS.ACTIVE]: SUBSCRIPTION_STATUS.ACTIVE,
  [SUBSCRIPTION_STATUS.CANCELLED]: SUBSCRIPTION_STATUS.CANCELLED
}

export const COMPANY_STATUS_OPTIONS = {
  ALL: '(all)',  
  [COMPANY_STATUS.LIVE]: COMPANY_STATUS.LIVE,
  [COMPANY_STATUS.SUSPENDED]: COMPANY_STATUS.SUSPENDED
}

export const initialFilterValues = {
  status: 'ALL',
  subscription: 'ALL',
  role: 'ALL',
  isCurrentCompanySeller: 'ALL',
  isCurrentCompanyBuyer: 'ALL',
  isCurrentCompanyPe: 'ALL'
}

export const CL_ROLE_FILTERS_VALUES = {
  ALL: '(all)',  
  SELLER: 'Seller',
  BUYER: 'Buyer',
  SELLER_BUYER: 'Seller & Buyer',
}

export const DEFAULT_HIDDEN_COLUMNS = [
  'email_id'
]
