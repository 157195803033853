import { connect } from 'react-redux'
import Component from './BookingsReview.js'
import { push } from 'connected-react-router'
import { fetchLatestCycles, fetchInvoices, updateInvoice, updateInvoices, clearInvoices, selectInvoiceForAddRemittance } from 'store/invoices'
import { searchCompanies, clearSearchCompaniesData } from 'store/companies'

export const mapState = ({ auth, invoices, companies }) => ({
  currUserCompanyId: auth.companyId,
  invoices: invoices.committed.list,
  snackbar: invoices.snackbar,
  companies: companies.searchCompany.data,
  currUserCompany: companies.currUserCompany,
  latestCycleStartDates: invoices.latestCycleStartDates,
  latestCollectionDates: invoices.latestCollectionDates,
  latestCollectionPayments: invoices.latestCollectionPayments,
  latestDisbursementDates: invoices.latestDisbursementDates,
  latestDisbursementPayments: invoices.latestDisbursementPayments,
  lastUpdate: invoices.committed.lastUpdate,
  currUserRole: auth.role
})

export const mapDispatch = (dispatch) => ({
  redirectTo: (data) => dispatch(push(data)),
  selectInvoiceForAddRemittance: (data) => dispatch(selectInvoiceForAddRemittance(data)),
  clearInvoices: () => dispatch(clearInvoices()),
  fetchInvoices: (data) => dispatch(fetchInvoices(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  updateInvoices: (data) => dispatch(updateInvoices(data, false)),
  searchCompanies: (data) => dispatch(searchCompanies(data)),
  clearSearchCompaniesData: () => dispatch(clearSearchCompaniesData()),
  fetchLatestCycles: (isPastView) => dispatch(fetchLatestCycles(isPastView))
})

export default connect(mapState, mapDispatch)(Component)
