import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { CURRENCY_CODE, CURRENCY_CODE_TO_NAME, REMITTANCE_STATUSES, ROUTE_URL } from 'constants.js'
import AuthView from 'containers/AuthView/connect'
import { BreadcrumbMenuTransactions } from 'containers/BreadcrumbMenu'
import Button from 'components/Button'
import { FILTER_TYPES, DEFAULT_COLUMN_WIDTH_VALUES } from 'components/DataGrid/utils'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import TransactionsTable from 'components/TransactionsTable'
import InnerTransactionDetailsTable from 'components/TransactionsTable/InnerTransactionDetailsTable'
import {
  PayerNameCell,
  PayeeNameCell,
  RemittanceStatusCell
} from 'components/TransactionsTable/components'
import { dateToReadableDateTime, utcToTimezone } from 'utils/date'

import { initialFilterValues } from './constants'
import {
  getPayerIdType,
  getPayerId,
  getPayeeIdType,
  getPayeeId,
  filterLocalRemittances,
  filterValuesToQueryParams,
  parseRemittances
} from './utils'
import BookingsListComments from './components/BookingsListComments'
import styles from './styles.module.css'
import { useGridTitleWithHelpPopup } from 'components/HelpPopup/HelpPopup'
import { useQuery } from 'react-query'
import { getCompanies } from 'services/companiesApi'
import { useIntl } from 'react-intl'
import kebabCase from 'lodash/kebabCase'

const RemittancesReview = ({
  redirectTo,
  location,
  remittances,
  fetchRemittances,
  clearRemittances,
  currUserCompany,
  currUserCompanyId,
  fetchLatestCycles,
  latestCollectionDates,
  latestCollectionPayments,
  latestDisbursementDates,
  latestDisbursementPayments,
  isCurrentCompanyProcessingEntity,
  lastUpdate,
}) => {
  const [ filters, setFilters ] = useState(initialFilterValues)
  const { formatMessage } = useIntl()
  
  const { data: processingEntities } = useQuery('processing-entities-filter', () => getCompanies({'isProcessingEntity[eq]':true}), {
    enabled: !!currUserCompany && !!currUserCompany.id && !currUserCompany.isProcessingEntity,
    retry: false
  })

  const queryParams = filterValuesToQueryParams({ ...filters }, currUserCompanyId)
  const filteredRemittances = filterLocalRemittances(parseRemittances(remittances), filters)
  const totalNumberOfRemittances = filteredRemittances.length

  const remittancesCurrency = filteredRemittances?.[0]?.currency
  const isAllRemittancesSameCurrency = filteredRemittances?.every(ele => ele.currency === remittancesCurrency)

  const columns = [
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payer' }),
      name: 'sourceId',
      getCellValue: PayerNameCell,
      filter: true
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payer-id-type' }),
      name: 'sourceCompany.codes.type',
      getCellValue: getPayerIdType,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payer-id' }),
      name: 'sourceCompany.codes',
      getCellValue: getPayerId,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payee' }),
      name: 'targetId',
      getCellValue: PayeeNameCell,
      filter: true
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payee-id-type' }),
      name: 'PayeeIdType',
      getCellValue: getPayeeIdType,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payee-id' }),
      name: 'PayeeId',
      getCellValue: getPayeeId,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      title: formatMessage({ id: 'remittances.processing-entity-company-name' }),
      name: 'processingEntityCompanyName',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: !isCurrentCompanyProcessingEntity ? processingEntities?.reduce((r, i) => {
            return Object.assign(r, {[i.name]: i.name})
          }, {'Direct': 'Direct'}) || {'Direct': 'Direct'} : { [currUserCompany.name]: currUserCompany.name }
        }
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      title: formatMessage({ id: 'remittances.currency' }),
      name: 'currency',
      getCellValue: row => row?.currency ?? '',
      getCellValueToExport: row => row?.currency ?? '',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: {
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP],
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR],
          }
        }
      },
      titleToExport: 'Currency',
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.amount-paid' }),
      name: 'amountPaidInCents',
      type: 'currency',
      summary: isAllRemittancesSameCurrency ? (total, row) => total + parseInt(row?.amountPaidInCents, 10) ?? 0 : null,
      summaryCurrency: remittancesCurrency,
      filter: { type: FILTER_TYPES.CURRENCY },
      align: 'right',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payment-ref' }),
      name: 'paymentRef',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.collection-submission' }),
      name: 'collectionAt',
      filter: {
        type: FILTER_TYPES.DATE,
        predefinedRanges: latestCollectionDates,
        disableClearButton: true
      },
      getCellValue: row => utcToTimezone(row.collectionAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.collectionAt, currUserCompany.timezone)),
      width: DEFAULT_COLUMN_WIDTH_VALUES.XXL,
      type: 'datetime',
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.collection-date' }),
      name: 'collectionPaymentAt',
      filter: { type: FILTER_TYPES.DATE, predefinedRanges: latestCollectionPayments }, // TODO
      getCellValue: row => utcToTimezone(row.collectionPaymentAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.collectionPaymentAt, currUserCompany.timezone)),
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      type: 'datetime',
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payment-submission' }),
      name: 'disbursementAt',
      // TODO predefined ranges does not take into account that it could be a collection/first-phase date (direct payment)
      filter: { type: FILTER_TYPES.DATE, predefinedRanges: latestDisbursementDates },
      // NOTE when there is no second phase, the payment submission is the collection/first-phase too (direct payment)
      getCellValue: row => utcToTimezone(row.disbursementAt || row.collectionAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(
        // NOTE when there is no second phase, the payment submission is the collection/first-phase too (direct payment)
        utcToTimezone(row.disbursementAt || row.collectionAt, currUserCompany.timezone)
      ),
      width: DEFAULT_COLUMN_WIDTH_VALUES.XXL,
      type: 'datetime',
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payment-date' }),
      name: 'disbursementPaymentAt',
      // TODO predefined ranges does not take into account that it could be a collection/first-phase date (direct payment)
      filter: { type: FILTER_TYPES.DATE, predefinedRanges: latestDisbursementPayments },
      // NOTE when there is no second-phase, the payment is the collection/first-phase payment too (direct payment)
      getCellValue: row => utcToTimezone(row.disbursementPaymentAt || row.collectionPaymentAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(
        // NOTE when there is no second-phase, the payment is the collection/first-phase payment too (direct payment)
        utcToTimezone(row.disbursementPaymentAt || row.collectionPaymentAt, currUserCompany.timezone)
      ),
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      type: 'datetime',
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.business-terms-name' }),
      name: 'businessTermsName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.settlement-schedule-name' }),
      name: 'settlementScheduleName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XXL
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.mandate-id' }),
      name: 'paymentConfiguration.mandateId',
      getCellValue: ({ paymentConfiguration }) => paymentConfiguration.mandateId,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.remittance-status' }),
      name: 'status',
      getCellValue: RemittanceStatusCell,
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(REMITTANCE_STATUSES)
              .map(([k, v]) => [k, formatMessage({ id: `remittance-status.${kebabCase(v)}` })]),
          ),
        }
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.payment-profile-name' }),
      name: 'paymentProfile',
      getCellValue: row => row?.invoices?.[0]?.commercialLink?.paymentProfile?.name,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.cl-description' }),
      name: 'commercialLinkDescription',
      getCellValue: row => row?.invoices?.[0]?.commercialLink?.displayName ?? '',
      filter: true
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.updated-by' }),
      name: 'updatedBy',
      getCellValue: row => row?.updatedBy?.name,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.updated-at' }),
      name: 'updatedAt',
      getCellValue: row => utcToTimezone(row?.updatedAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.updatedAt, currUserCompany.timezone)),
      filter: { type: FILTER_TYPES.DATE },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      type: 'datetime',
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'remittances.created-at' }),
      name: 'createdAt',
      filter: { type: FILTER_TYPES.DATE },
      getCellValue: row => utcToTimezone(row.createdAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.createdAt, currUserCompany.timezone)),
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      type: 'datetime',
    },
  ]

  useEffect(() => {
    if (currUserCompanyId) {
      fetchRemittances(queryParams)
      if (!latestDisbursementDates || !latestCollectionDates) {
        fetchLatestCycles()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currUserCompanyId ])

  useEffect(() => {
    if (latestCollectionDates && latestCollectionDates.length) {
      setFilters({
        ...filters,
        collectionAt: latestCollectionDates[0]
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ latestCollectionDates?.length ])

  useEffect(() => {
    return () => clearRemittances()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRedirectToCreate = () => {
    redirectTo(ROUTE_URL.createRemittanceTransaction)
  }

  return (
    <AuthView location={location} title="Transactions">
      <BreadcrumbMenuTransactions />
      <Grid container spacing={1}>
        <Grid item xs={12} className={styles.tableBlock}>
          <TransactionsTable
            lastUpdate={lastUpdate}
            name="review_remittances"
            columns={columns}
            propMach={filters}
            data={filteredRemittances}
            onPageChanged={() => {}}
            detailPanel={rowData => (
              <InnerTransactionDetailsTable
                rowData={rowData}
                title={`${formatMessage({ id: 'remittances.related-bookings' })} *`}
                exportTitle={`Remittance_${rowData.paymentRef}-${rowData.paymentConfiguration?.mandateId}_bookings`}
                detailsType="invoices"
                isCurrentCompanyProcessingEntity={isCurrentCompanyProcessingEntity}
                commentSection={<BookingsListComments />}
              />
            )}
            filtering={{
              defaultFilters: initialFilterValues,
              filters,
              onChange: setFilters,
              clearFilters: () => {
                latestCollectionDates && latestCollectionDates.length
                ?
                  setFilters(Object.assign(initialFilterValues, {
                    collectionAt: latestCollectionDates[0],
                  }))
                :
                  setFilters(initialFilterValues)
              }
            }}
            extra={
              <>
                { isCurrentCompanyProcessingEntity &&
                  <Button
                    color="primary"
                    icon="add"
                    onClick={handleRedirectToCreate}
                    style={{ marginRight: 8 }}
                    size="small"
                  >
                    {formatMessage({ id: 'remittances.create' })}
                  </Button>
                }
                <RefreshButton onClick={() => fetchRemittances(queryParams)} />
              </>
            }
            totalNumberOfItems={totalNumberOfRemittances}
            isRowExpandable={row => !!row?.invoiceIds?.length}
          />
        </Grid>
      </Grid>
    </AuthView>
  )
}

RemittancesReview.propTypes = {
  location: PropTypes.object,
  currUserCompanyId: PropTypes.string,
  fetchRemittances: PropTypes.func,
  clearRemittances: PropTypes.func,
  redirectTo: PropTypes.func,
  remittances: PropTypes.array,
  fetchLatestCycles: PropTypes.func,
  latestCollectionDates: PropTypes.array,
  latestDisbursementDates: PropTypes.array,
  isCurrentCompanyProcessingEntity: PropTypes.bool,
  lastUpdate: PropTypes.number,
  currUserCompany: PropTypes.object
}

export default RemittancesReview
