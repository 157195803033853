import Joi from 'joi'
import { uniq } from 'lodash'

const dataDeliveryEmailsWhitelistValidator = (value) => {
  const emails = uniq(value.split(';').map(email => email.trim().toLowerCase()).filter(email => !!email))
  emails.forEach((email) => {
    const { error } = Joi.string().email({ tlds: { allow: false } }).validate(email)
    if (error) {
      throw new Error('Invalid')
    }
  })
  return emails
}

export const formSchema = Joi.object({
  dataDeliveryEmailsWhitelist: Joi.any().custom(dataDeliveryEmailsWhitelistValidator).message('Must be valid email addresses separated by semi-colons (;)')
})
