import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment, MenuItem, Select, TableCell, TextField } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

const defaultValue = {
  type: 'gte',
  value: ''
}

const InputCell = ({ value = defaultValue, onChange }) => {
  const handleTypeChange = useCallback((e) => {
    onChange({
      ...value,
      type: e.target.value
    })
  }, [ onChange, value ])

  const handleValueChange = useCallback((e) => {
    onChange({
      ...value,
      value: e.target.value
    })
  }, [ onChange, value ])

  const handleClear = useCallback(() => {
    onChange({
      ...value,
      value: ''
    })
  }, [ onChange, value ])

  return (
    <TableCell align="right">
      <TextField
        fullWidth
        InputProps={{
          endAdornment: (
            value.value ? (
              <IconButton size="small" disableRipple onClick={handleClear}>
                <Clear />
              </IconButton>
            ) : null
          ),
          startAdornment: (
            <InputAdornment position="start">
              <Select
                value={value.type}
                onChange={handleTypeChange}
                MenuProps={{variant: "menu"}}
              >
                <MenuItem value="gte">&gt;=</MenuItem>
                <MenuItem value="eq">=</MenuItem>
                <MenuItem value="lte">&lt;=</MenuItem>
              </Select>
            </InputAdornment>
          )
        }}
        onChange={handleValueChange}
        value={value.value}
      />
    </TableCell>
  )
}

InputCell.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func
}

export default InputCell
