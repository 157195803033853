import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const postForgotPassword = async (body) => {
  const url = `${API_URL}/users/password/forgot-password`
  const data = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return httpFetch(url, data)
}

export const postLogin = async (body) => {
  const url = `${API_URL}/sessions`
  const data = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return httpFetch(url, data)
}

export const postMfaVerify = async (body) => {
  const url = `${API_URL}/sessions/verify`
  const data = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return httpFetch(url, data)
}
