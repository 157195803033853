import { createMuiTheme } from '@material-ui/core/styles'

export const themeDefault = createMuiTheme({
  palette: {
    common: {
      black: '#000A1E',
      white: '#FFFFFF'
    },
    primary: {
      main: '#043943',
      light: '#065B6A',
      dark: '#021E23',
      contrastText: '#FFFFFF',
      background: '#04394330',
    },
    secondary: {
      main: '#EEE0CB',
      light: '#F4EBDD',
      dark: '#E0C9A6',
      contrastText: '#043943'
    },
    error: {
      main: '#F44336',
      light: '#F88078',
      dark: '#D0190B',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#FF9800',
      light: '#FFBF61',
      dark: '#C77700',
      contrastText: '#043943'
    },
    info: {
      main: '#7CC1F8',
      light: '#2196F3',
      dark: '#0B74C7',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#4CAF50',
      light: '#82C985',
      dark: '#3B873E',
      contrastText: '#FFFFFF'
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121'
    },
    text: {
      primary: '#043943',
      secondary: 'rgba(4, 57, 67, 0.72)',
      disabled: 'rgba(4, 57, 67, 0.40)',
      hint: 'rgba(4, 57, 6, 0.36)'
    },
    divider: 'rgba(4, 57, 6, 0.12)',
    background: {
      paper: '#FAFAFA',
      default: '#FFFFFF'
    },
    action: {
      active: '#E0E0E0',
      disabled: 'rgba(4, 57, 67, 0.24)',
      disabledBackground: 'rgba(4, 57, 67, 0.08)'
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: '900',
      fontSize: '1.75rem',
      lineHeight: '1',
      textTransform: 'uppercase'
    },
    h2: {
      fontWeight: '900',
      fontSize: '1.625rem',
      lineHeight: '1',
      textTransform: 'uppercase'
    },
    h3: {
      fontWeight: '900',
      fontSize: '1.375rem',
      lineHeight: '1',
      textTransform: 'uppercase'
    },
    h4: {
      fontWeight: '900',
      fontSize: '1.25rem',
      lineHeight: '1',
      textTransform: 'uppercase'
    },
    h5: {
      fontWeight: '900',
      fontSize: '1.125rem',
      lineHeight: '1.5'
    },
    h6: {
      fontWeight: '900',
      fontSize: '1rem',
      lineHeight: '1.25'
    },
    subtitle1: {
      fontWeight: 'normal',
      fontSize: '1.125rem',
      lineHeight: '1.5'
    },
    subtitle2: {
      fontWeight: 'bold',
      fontSize: '1.125rem',
      lineHeight: '1.5'
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '1rem',
      lineHeight: '1.5'
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '1rem',
      lineHeight: '1.5'
    },
    caption: {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      lineHeight: '1.5',
      letterSpacing: '0.01rem'
    },
    overline: {
      fontWeight: 'normal',
      fontSize: '0.75rem',
      lineHeight: '1.5',
      textTransform: 'none',
      letterSpacing: 0
    },
    button: {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      lineHeight: 1,
      textTransform: 'uppercase',
      letterSpacing: '0.06rem'
    }
  },
  shape: {
    borderRadius: 0
  },
  props: {
    MuiButton: {
      disableElevation: true,
    }
  }
})

export const theme = createMuiTheme({
  palette: themeDefault.palette,
  typography: themeDefault.typography,
  spacing: themeDefault.spacing,
  props: themeDefault.props,
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: themeDefault.palette.primary.main,
        color: themeDefault.palette.primary.contrastText
      }
    },
    MuiTabs: {
      root: {
        backgroundColor: themeDefault.palette.secondary.light,
        marginBottom: themeDefault.spacing(1),
        minHeight: 'unset',
      },
      indicator: {
        display: 'none'
      }
    },
    MuiTab: {
      root: {
        minHeight: 'unset',
        padding: themeDefault.spacing(1.5, 2),
        fontSize: '0.875rem',
        '&$selected': {
          backgroundColor: themeDefault.palette.secondary.dark
        }
      }
    },
    MuiPaper: {
      root: {
        borderRadius: 0
      },
      rounded: {
        borderRadius: 0
      }
    },
    MuiFormControl: {
      marginDense: {
        marginTop: themeDefault.spacing(1),
        marginBottom: themeDefault.spacing(2)
      },
      marginNormal: {
        marginTop: 0,
        marginBottom: themeDefault.spacing(1)
      }
    },
    MuiInputAdornment: {
      root: {
        '& .MuiInputBase-root:before': {
          display: 'none'
        }
      }
    },
    MuiInputLabel: {
      shrink: {
        color: themeDefault.palette.primary.main
      },
      '&$focused': {
        color: themeDefault.palette.primary.main
      }
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: themeDefault.palette.text.secondary
        }
      }
    },
    MuiDrawer: {
      root: {
        '& svg': {
          marginLeft: themeDefault.spacing(1),
          color: themeDefault.palette.error.main
        },
        '& hr': {
          backgroundColor: themeDefault.palette.secondary.dark
        }
      },
      paper: {
        backgroundColor: themeDefault.palette.secondary.main
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        height: themeDefault.spacing(4),
        lineHeight: 0,
        fontSize: '0.875rem',
      },
      sizeSmall: {
        height: themeDefault.spacing(3),
        fontSize: '0.75rem',
      },
      sizeLarge: {
        height: themeDefault.spacing(5),
        fontSize: '1rem',
      },
      contained: {
        '&$disabled': {
          backgroundColor: themeDefault.palette.action.disabledBackground,
          color: themeDefault.palette.action.disabled,
        }
      }
    },
    MuiIconButton: {
      root: {
        color: themeDefault.palette.primary.main
      }
    },
    MuiSelect: {
      icon: {
        color: themeDefault.palette.primary.main
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '0.75rem',
        [themeDefault.breakpoints.up('lg')]: {
          fontSize: '0.8rem',
        },
        [themeDefault.breakpoints.up('xl')]: {
          fontSize: '0.9rem',
        },
        '& .MuiSvgIcon-root': {
          width: '1.25rem'
        },
        '& .MuiSvgIcon-fontSizeInherit': {
          width: '0.75rem'
        },
        '& .MuiInputBase-input': {
          fontSize: '0.75rem'
        },
        '& .MuiCheckbox-root': {
          padding: 0
        },
        '& .MuiIconButton-root': {
          padding: 0
        }
      },
      head: {
        padding: '8px 8px !important'
      },
      stickyHeader: {
        position: 'inherit'
      },
      paddingCheckbox: {
        padding: '0 !important'
      },
      body: {
        padding: '6px 8px !important',
        fontWeight: 'normal'
      },
      footer: {
        padding: '6px 8px !important'
      },
      alignRight: {
        '& input': {
          textAlign: 'right'
        }
      }
    },
    MuiTablePagination: {
      root: {
        minHeight: themeDefault.spacing(6.5)
      },
      toolbar: {
        backgroundColor: themeDefault.palette.common.white,
        color: themeDefault.palette.primary.main
      }
    },
    MuiTableRow: {
      root: {
        height: themeDefault.spacing(4)
      }
    },
    MuiToolbar: {
      regular: {
        minHeight:'56px !important'
      },
      dense: {
        minHeight: themeDefault.spacing(5),
        paddingLeft: 0,
        paddingRight: 0,
        '& .MuiTypography-root': {
          fontSize: '0.85rem'
        },
        '& .MuiInputBase-root': {
          fontSize: '0.85rem'
        }
      }
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked + $track': {
          opacity: 1,
          backgroundColor: themeDefault.palette.primary.main
        }
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: themeDefault.palette.primary.main,
        color: themeDefault.palette.primary.contrastText
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.875rem'
      }
    },
    MuiTypography: {
      h3: {
        '&$gutterBottom': {
          marginBottom: themeDefault.spacing(2)
        }
      }
    }
  }
})
