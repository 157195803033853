import React from 'react'
import PropTypes from 'prop-types'

const OneItemRow = props => (
  <div className={props.center ? 'columns has-text-centered' : 'columns'}>
    <div className="column is-1" />
    <div className="column is-10">
      { props.children }
    </div>
    <div className="column is-1" />
  </div>
)

OneItemRow.defaultProps = {
  center: false
}

OneItemRow.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ])
}

export default OneItemRow
