import { connect } from 'react-redux'
import Component from './Login.js'
import { fetchAuth } from 'store/auth'
import { replace } from 'connected-react-router'

export const mapState = ({
  auth
}) => ({
  auth
})

export const mapDispatch = (dispatch) => ({
  fetchAuth: data => dispatch(fetchAuth(data)),
  replace: data => dispatch(replace(data))
})

export default connect(mapState, mapDispatch)(Component)
