import React from 'react'
import PropTypes from 'prop-types'
import { SETTLEMENT_STATUS_VALUES_TO_NAME } from 'constants.js'

const SettlementStatusCell = React.memo(
  ({ settlementStatus }) =>
    SETTLEMENT_STATUS_VALUES_TO_NAME[settlementStatus] ?? ''
)

SettlementStatusCell.displayName = 'SettlementStatusCell'

SettlementStatusCell.propTypes = {
  settlementStatus: PropTypes.string
}

export default SettlementStatusCell
