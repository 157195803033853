import { NOTIFICATION_MODULES } from 'constants.js'
import { _betweenDates, _contains, _equalsDate } from 'utils/filters'

export const filterValuesToQueryParams = (filterValues) => {
  let filtersToSet = {}

  if (filterValues.since) {
    filtersToSet['createdAt[gt]'] = filterValues.since
  }
  if (filterValues.until) {
    filtersToSet['createdAt[lt]'] = filterValues.until
  }
  if (filterValues.types && filterValues.types.length) {
    filtersToSet['type[in]'] = filterValues.types
  }
  if (filterValues.searchText) {
    filtersToSet['message[search]'] = filterValues.searchText
  }
  if (!filterValues.showDismissed) {
    filtersToSet['isDismissed[eq]'] = false
  }
  if (filterValues.limit) {
    filtersToSet.limit = filterValues.limit
  }
  if (filterValues.sorting) {
    filtersToSet = { ...filtersToSet, ...filterValues.sorting }
  }
  if (filterValues.paginationQuery) {
    filtersToSet = { ...filtersToSet, ...filterValues.paginationQuery }
  }
  return {
    ...filtersToSet,
    orderBy: 'createdAt',
    order: 'DESC'
  }
}

export const filterLocalNotifications = (notifications, filters) => {
  return notifications.filter(notification => {
    if (filters.message && !_contains(filters.message, notification.message)) {
      return false
    }
    if (filters.module && !filters.module.find(module => _contains(NOTIFICATION_MODULES[module], notification.module))) {
      return false
    }
    if (filters.type && !filters.type.find(filtered => _contains(filtered, notification.type))) {
      return false
    }
    if (filters.createdAt && filters.createdAt?.startDate) {
      const { startDate, endDate } = filters.createdAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, notification.createdAt)) {
        return false
      } else if (!_betweenDates(notification.createdAt, startDate, endDate)) {
        return false
      }
    }

    return true
  })
}
