import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { columnsUser } from './columns'
import Box from '@material-ui/core/Box'
import { ROUTE_URL } from '../../../../constants'
import HelpPopup from 'components/HelpPopup'
import DataGrid from 'components/DataGrid'
import { useQuery, useMutation } from 'react-query'
import { deactivateUser, getUsers, reactivateUser, resetMFAUser } from 'services/usersApi'
import { getReadableErrorMessage } from 'utils/errors'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { filterUsers } from './utils'
import { useIntl } from 'react-intl'

const ManageUsersTable = ({ canEdit, company }) => {
  const history = useHistory()
  const [filters, setFilters] = useState({})
  const onError = (error) => {
    toast.error(getReadableErrorMessage(error))
  }
  const { data: usersData, refetch: refetchUsers } = useQuery(`users-${company.id}`, () => getUsers(company.id))
  const { mutate: deactivateUserMutation } = useMutation(deactivateUser, {
    onSuccess: () => {
      toast.success('User deactivated')
      refetchUsers()
    },
    onError,
  })
  const { mutate: reactivateUserMutation } = useMutation(reactivateUser, {
    onSuccess: () => {
      toast.success('User reactivated')
      refetchUsers()
    },
    onError,
  })
  const { mutate: resetMFAUserMutation } = useMutation(resetMFAUser, {
    onSuccess: () => {
      toast.success('User 2FA was reset')
      refetchUsers()
    },
    onError,
  })
  const { formatMessage } = useIntl()

  const users = usersData?.data || []
  const filteredUsers = filterUsers(users, filters)

  return (
    <Box width="min-content">
      <DataGrid
        title={<HelpPopup id="users.manage-users.title">{formatMessage({ id: 'manage-users.manage-users' })}</HelpPopup>}
        name="manage-users"
        columns={columnsUser(formatMessage)}
        data={filteredUsers}
        actions={[
          {
            icon: 'edit',
            tooltip: formatMessage({ id: 'manage-users.edit-user' }),
            onClick: (_, { id }) => history.push(ROUTE_URL.userById.replace(':id', id)),
            disabled: (rowData) => rowData.isDeleted,
          },
          {
            icon: 'visibility_off',
            tooltip: formatMessage({ id: 'manage-users.Deactivate User' }),
            onClick: (e, rowData) => deactivateUserMutation(rowData?.id),
            hidden: (rowData) => rowData.isDeleted,
          },
          {
            icon: 'visibility',
            tooltip: formatMessage({ id: 'manage-users.reactivate-user' }),
            onClick: (e, rowData) => reactivateUserMutation(rowData?.id),
            hidden: (rowData) => !rowData.isDeleted,
          },
          {
            icon: 'sync',
            tooltip: formatMessage({ id: 'manage-users.reset-two-factor-authentication' }),
            onClick: (e, rowData) => resetMFAUserMutation(rowData?.email),
            disabled: (rowData) => !rowData.isMfaSetup || rowData.isDeleted,
          },
        ]}
        toolbarActions={
          canEdit
            ? [
                {
                  icon: 'add',
                  tooltip: formatMessage({ id: 'manage-users.add-user' }),
                  label: formatMessage({ id: 'manage-users.add-user' }),
                  onClick: () => {
                    history.push(ROUTE_URL.invite, { company })
                  },
                },
              ]
            : []
        }
        pagination={{ pageSizes: [5, 10, 25], pageSize: 5}}
        filtering={{
          defaultFilters: {},
          filters,
          onChange: (newValue) => {
            setFilters(newValue)
          },
        }}
      />
    </Box>
  )
}

ManageUsersTable.propTypes = {
  company: PropTypes.object,
  canEdit: PropTypes.bool,
}

export default ManageUsersTable
