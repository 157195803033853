import moment from 'moment'

export const DEFAULT_PAGE_SIZES = [ 10, 25, 50 ]
export const DEFAULT_VIRTUAL_PAGE_SIZES = [ 25, 50, 100, 200, 500 ]
export const INITIAL_PAGE_SIZE = 10
export const INITIAL_VIRTUAL_PAGE_SIZE = 500
export const DATE_RANGES = {
  THIS_WEEK: {
    label: 'This week',
    startDate: moment().startOf('isoWeek'),
    endDate: moment().endOf('isoWeek')
  },
  NEXT_WEEK: {
    label: 'Next week',
    startDate: moment().startOf('isoWeek').add(7, 'days'),
    endDate: moment().endOf('isoWeek').add(7, 'days')
  },
  THIS_MONTH: {
    label: 'This month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  },
  NEXT_MONTH: {
    label: 'Next month',
    startDate: moment().startOf('month').add(1, 'months'),
    endDate: moment().endOf('month').add(1, 'months')
  },
  THIS_YEAR: {
    label: 'This year',
    startDate: moment().startOf('year'),
    endDate: moment().endOf('year')
  },
  NEXT_YEAR: {
    label: 'Next year',
    startDate: moment().startOf('year').add(1, 'years'),
    endDate: moment().endOf('year').add(1, 'years')
  },
  LAST_21_DAYS: {
    label: 'Last 21 days',
    startDate: moment().subtract(21, 'days'),
    endDate: moment()
  }
}
