import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'

const RowDetail = ({ row }) => {
  const classes = useStyles()
  return (
    <p className={classes.rowDetail}>{ row.message }</p>
  )
}

RowDetail.propTypes = {
  row: PropTypes.object
}

export default RowDetail
