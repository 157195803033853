import React, { memo } from 'react'
import PropTypes from 'prop-types'
import MTableCell from '@material-ui/core/TableCell'
import MCheckbox from '@material-ui/core/Checkbox'

const Checkbox = ({ row, selected, onToggle, isChecked, isDisabled }) => {
  const disabled = isDisabled ? isDisabled({ row }) : false
  const checked = isChecked ? isChecked({ selected, row }) : selected
  return (
    <MTableCell align="center">
      <MCheckbox
        disableRipple={true} onChange={onToggle} tabIndex={-1} checked={checked} color="primary" disabled={disabled} />
    </MTableCell>
  )
}

Checkbox.propTypes = {
  isChecked: PropTypes.func,
  isDisabled: PropTypes.func,
  onToggle: PropTypes.func,
  selected: PropTypes.bool,
  row: PropTypes.shape({
    status: PropTypes.string
  })
}

const MemoizedCheckbox = memo(Checkbox)

export default MemoizedCheckbox

export const enhancedCheckbox = (isChecked, isDisabled) =>
  (props) => <MemoizedCheckbox isChecked={isChecked} isDisabled={isDisabled} {...props} />
