import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  expandRow: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: theme.spacing(1)
      }
    }
  },
  expand: {
    fontSize: '1.5rem',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))
