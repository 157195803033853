import { COMPANY_ID_TYPES, READ_ONLY_COMPANY_ID_TYPES } from 'constants.js'
import { CL_FILTERS_VALUES } from './constants'

export const filterOutReadOnlyCompanyCodes = (codes) =>
  Object.entries(codes || {})
    .filter(([type]) => !Object.values(READ_ONLY_COMPANY_ID_TYPES).includes(type))
    .reduce((acc, [type, code]) => {
      // eslint-disable-next-line no-param-reassign
      acc[type] = code
      return acc
    }, {})

const buildProcEntString = (items) => {
  return items
    .map((item, i, row) => {
      let str = item
      if (i === 0) {
        str = ' (' + str
      }
      if (i + 1 === row.length) {
        str = str + ')'
      }
      return str
    })
    .join(',')
}

const buildHeadCompanyDisplayName = (headCompanies) => {
  if (headCompanies.length === 0) {
    return '-'
  }
  return headCompanies
    .map((headCompany) => {
      const codes =
        headCompany.codes &&
        Object.entries(headCompany.codes)
          .filter(([key]) => key !== 'ABTACRM')
          .map(([key, value]) => `${key} ${value}`)
          .join(' | ')
      let displayName = `${headCompany.name}`
      if (codes) {
        displayName = `${displayName} (${codes})`
      }
      return displayName
    })
    .join(', ')
}

export const parseCompanies = (companies, currUserCompanyName) => {
  return companies.map((company) => {
    const allCodeTypes = Object.values({ ...COMPANY_ID_TYPES, ...READ_ONLY_COMPANY_ID_TYPES })
    const companyCodes = {}
    allCodeTypes.forEach((codeType) => {
      if (!!company.codes && !!company.codes[codeType]) {
        companyCodes[codeType.toLowerCase()] = company.codes[codeType]
      }
    })

    return {
      ...company,
      ...companyCodes,
      companyName: company.name,
      countryName: company.countryName,
      headOffice: buildHeadCompanyDisplayName(company.headCompanies),
      role:
        company.isSeller && company.isBuyer
          ? 'Seller & Buyer'
          : company.isSeller
          ? 'Seller'
          : company.isBuyer && 'Buyer',
      isCurrentCompanySeller: company.isCurrentCompanySeller
        ? company.currentCompanySellerClStatus.map((ele) => ele).join(', ') +
          buildProcEntString(company.currentCompanySellerPe)
        : 'No',
      isCurrentCompanyBuyer: company.isCurrentCompanyBuyer
        ? company.currentCompanyBuyerClStatus.map((ele) => ele).join(', ') +
          buildProcEntString(company.currentCompanyBuyerPe)
        : 'No',
      isCurrentCompanyPe: company.isCurrentCompanyPe
        ? company.currentCompanyPeClStatus.map((ele) => ele).join(', ') +
          (currUserCompanyName && ` (${currUserCompanyName})`)
        : 'No',
      subscription: company.subscription.status,
    }
  })
}

export const filterValuesToQueryParams = (filterValues, viewAsCompanyId) => {
  const filtersToSet = {}
  filtersToSet['acceptedTos'] = true
  if (viewAsCompanyId) {
    filtersToSet['viewAsCompanyId[eq]'] = viewAsCompanyId
  }
  if (filterValues.companyName) {
    filtersToSet['name[contains]'] = filterValues.companyName
  }
  if (filterValues.abta) {
    filtersToSet['type[eq]'] = 'ABTA'
    filtersToSet['code[contains]'] = filterValues.abta
  }
  if (filterValues.tids) {
    filtersToSet['type[eq]'] = 'TIDS'
    filtersToSet['code[contains]'] = filterValues.tids
  }
  if (filterValues.iata) {
    filtersToSet['type[eq]'] = 'IATA'
    filtersToSet['code[contains]'] = filterValues.iata
  }
  if (filterValues.email_id) {
    filtersToSet['type[eq]'] = 'EMAIL_ID'
    filtersToSet['code[contains]'] = filterValues.email_id
  }
  if (filterValues.countryName) {
    filtersToSet['countryName[contains]'] = filterValues.countryName
  }
  if (filterValues.headOffice) {
    filtersToSet['headOffice[contains]'] = filterValues.headOffice
  }
  if (filterValues.status && filterValues.status !== CL_FILTERS_VALUES.ALL) {
    filtersToSet['status[eq]'] = filterValues.status
  }
  if (filterValues.subscription && filterValues.subscription !== CL_FILTERS_VALUES.ALL) {
    filtersToSet['subscriptionStatus[eq]'] = filterValues.subscription
  }
  if (filterValues.role && filterValues.role !== CL_FILTERS_VALUES.ALL) {
    filtersToSet['role[eq]'] = filterValues.role
  }
  if (filterValues.isCurrentCompanySeller && filterValues.isCurrentCompanySeller !== CL_FILTERS_VALUES.ALL) {
    filtersToSet['isCurrentCompanySeller[eq]'] = filterValues.isCurrentCompanySeller
  }
  if (filterValues.isCurrentCompanyBuyer && filterValues.isCurrentCompanyBuyer !== CL_FILTERS_VALUES.ALL) {
    filtersToSet['isCurrentCompanyBuyer[eq]'] = filterValues.isCurrentCompanyBuyer
  }
  if (filterValues.isCurrentCompanyPe && filterValues.isCurrentCompanyPe !== CL_FILTERS_VALUES.ALL) {
    filtersToSet['isCurrentCompanyPe[eq]'] = filterValues.isCurrentCompanyPe
  }

  return filtersToSet
}
