import moment from 'moment-timezone'

const zones = new Set()
moment.tz.countries().map(country =>
  moment.tz.zonesForCountry(country)
    .reduce((set, name) => {
      const tz = moment.tz(name)
      const utcOffset = tz.utcOffset()
      const Z = tz.format('Z')
      const zoneSplitted = name.split('/')
      const title = `(${Z}) ${zoneSplitted[1]}`.concat(zoneSplitted[2] ? `/${zoneSplitted[2]}` : '')
      return set.add({ name, title, utcOffset })
    }, zones)
)

const checkDuplicates = new Set()
const momentTimeZoneItems = [...zones]
  .filter(obj => !checkDuplicates.has(obj['name']) && checkDuplicates.add(obj['name']))
  .sort((a, b) => a.utcOffset - b.utcOffset)

export default momentTimeZoneItems
