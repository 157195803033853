import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { yellow, red } from '@material-ui/core/colors'
import Switch from '@material-ui/core/Switch'

export const CommitSwitch = withStyles((theme) => ({
  root: {
    width: 34,
    height: 18,
    padding: 0,
    margin: theme.spacing(1.5),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: red[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const useStyles = makeStyles((theme) => ({
  dropzone: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'dashed',
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 20,
    padding: 50,
    flexGrow: 1
  },
  listItem: {
    background: theme.palette.grey[200],
    borderRadius: 10,
    boxShadow: `3px 3px 3px rgba(0,0,0,0.2)`
  },
  buttonWrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  gridFlexGrow: {
    flexGrow: 1
  },
  hiddenInput: {
    position: 'fixed',
    top: -1000,
    left: -1000
  },
  commitToolbar: {
    height: 64,
    padding: '0 24px',
    backgroundColor: 'rgb(232, 232, 232)'
  },
  invoiceError: {
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.error.main,
    borderBottomStyle: 'solid'
  },
  invoiceWarning: {
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.warning.main,
    borderBottomStyle: 'solid'
  },
  currencyCellContent: {
    textAlign: 'right'
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  warningIcon: {
    color: yellow[800]
  },
  errorIcon: {
    color: red[500]
  },
  label: {
    marginTop: theme.spacing(1)
  },
  errorList:{
    fontWeight: 'normal',
    margin: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0.5)
  },
  errorListSecondary:{
    fontSize: 13,
    marginLeft: theme.spacing(0.5),
    opacity: 0.7
  }
}))
