/* eslint-disable no-param-reassign */
import { INVOICE_TAGS } from 'constants.js'

const RED = 'FFFF0000'
const LIGHT_RED = 'FFFFCCCB'

const customAlertTextCell = cell => {
  cell.alignment = { vertical: 'middle', horizontal: 'center' }
  cell.font = {
    name: 'Arial Black',
    color: { argb: RED },
    size: 14,
    bold: true
  }
}

const customAlertCell = cell => {
  cell.border = {
    top: { style: 'thin', color: { argb: RED } },
    left: { style: 'thin', color: { argb: RED } },
    bottom: { style: 'thin', color: { argb: RED } },
    right: { style: 'thin', color: { argb: RED } }
  }
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: LIGHT_RED }
  }
}

export const customTags = (cell, row, column) => {
  if (row?.tags?.length && column.name === 'tags') {
    customAlertTextCell(cell)
  }
}

export const customBookingRef = (cell, row, column) => {
  if ((row?.tags?.includes(INVOICE_TAGS.POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT)
    || row?.tags?.includes(INVOICE_TAGS.POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT)) && column.name === 'bookingRef') {
    customAlertCell(cell)
  }
}

export const customOriginalAmount = (cell, row, column) => {
  if (row?.tags?.includes(INVOICE_TAGS.AMOUNT_ABOVE_THRESHOLD) && column.name === 'originalAmountInCents') {
    customAlertCell(cell)
  }
}
