import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import Icon from '@material-ui/core/Icon'
import Link from '@material-ui/core/Link'
import { Typography } from '@material-ui/core'

// eslint-disable-next-line react/display-name
const LinkButton = ({ children, icon, iconPosition = 'start', color, label, href, target, ...props }) => {
  const classes = useStyles()

  return <Link
    {...props}
    color={color}
    href={href}
    target={target}
    className={classes.linkButton}
  >
    {icon && iconPosition === 'start' && <Icon>{icon}</Icon>}
    {label && <Typography>{ label }</Typography>}
    {children}
    {icon && iconPosition === 'end' && <Icon>{icon}</Icon>}
  </Link>

}

LinkButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  icon: PropTypes.string,
  color: PropTypes.oneOf([
    'default', 'inherit', 'primary', 'secondary', 'success', 'error', 'abort'
  ]),
  iconPosition: PropTypes.oneOf([
    'start', 'end'
  ]),
  label: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf([
    '_blank', '_parent', '_self', '_top', ''
  ]),
}

export default LinkButton
