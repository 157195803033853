import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'

const ActionButton = ({ rowData, icon, tooltip, onClick, disabled, hidden, label }) => {
  const classes = useStyles()
  if (hidden && hidden(rowData)) {
    return null
  }
  if (label) {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={onClick}
        startIcon={<Icon>{icon}</Icon>}
        className={classes.root}
      >
        {label}
      </Button>
    )
  }
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton aria-label={tooltip} disabled={disabled && disabled(rowData)} onClick={(event) => onClick(event, rowData)}>
          <Icon>{icon}</Icon>
        </IconButton>
      </span>
    </Tooltip>
  )
}

ActionButton.propTypes = {
  rowData: PropTypes.object,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.func,
  hidden: PropTypes.func,
  label: PropTypes.string
}

export default memo(ActionButton)
