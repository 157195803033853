import Button from 'components/Button'
import { TextField } from '@material-ui/core'
import { useStyles } from '../styles'
import React, { useState, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { cloneDeep } from 'lodash'

function getInitValue(x) {
  if (Array.isArray(x)) {
    return x.map(y => y === null || y === undefined ? '' : `${y}`)
  }
  return ['']
}

export function MultiTextField({
  value,
  onChange: _onChange,
  label,
  required,
  info,
  errorMessage,
}) {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const valuesRef = useRef(getInitValue(value))
  const inputRefs = useRef(valuesRef.current.map(() => React.createRef()))
  const [numFields, setNumFields] = useState(valuesRef.current.length)

  useEffect(() => {
    const newVal = getInitValue(value)
    if (newVal.length !== valuesRef.current.length) {
      valuesRef.current = newVal
      while (inputRefs.current.length < newVal.length) {
        inputRefs.current.push(React.createRef())
      }
      if (inputRefs.current.length > newVal.length) {
        inputRefs.current = inputRefs.current.slice(0, newVal.length)
      }
      for (let i = 0; i < newVal.length; i++) {
        if (inputRefs.current[i].current) {
          inputRefs.current[i].current.value = newVal[i]
        }
      }
      setNumFields(newVal.length)
    } else {
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i] !== valuesRef.current[i]) {
          inputRefs.current[i].current.value = newVal[i]
        }
      }
    }
  }, [value])

  const onChange = useDebouncedCallback(v => _onChange(cloneDeep(v)), 400)

  const handleChange = idx => e => {
    valuesRef.current[idx] = e.target.value
    onChange(valuesRef.current)
  }

  const handleAdd = () => {
    valuesRef.current.push('')
    inputRefs.current.push(React.createRef())
    setNumFields(v => v + 1)
    onChange(valuesRef.current)
  }

  const handleRemove = idx => () => {
    valuesRef.current.splice(idx, 1)
    for (let i = idx; i < valuesRef.current.length; i++) {
      inputRefs.current[i].current.value = valuesRef.current[i]
    }
    inputRefs.current.pop()
    setNumFields(v => v - 1)
    onChange(valuesRef.current)
  }

  return (
    <fieldset className={`${classes.multiTextField} ${errorMessage ? 'error' : ''}`}>
      <legend>{label}{required ? ' *' : ''}</legend>
      {!!info && (<div className="info">{info}</div>)}
      {new Array(numFields).fill(null).map((_v, idx) => {
        return (
          <div className="text-input-container" key={`text-${idx}`}>
            <TextField
              inputRef={inputRefs.current[idx]}
              onChange={handleChange(idx)}
              variant="outlined"
              fullWidth
              className={`${classes.field} text-input`}
              size='small'
              placeholder={label}
              defaultValue={valuesRef.current[idx]}
            />

            <Button
              className="remove-btn"
              size="small"
              color="primary"
              onClick={handleRemove(idx)}
              disabled={numFields <= 1}
            >
              {formatMessage({ id: 'tlpay.remove' })}
            </Button>
          </div>
        )
      })}
      {!!errorMessage && (<div className="error-msg-container">{errorMessage}</div>)}
      <div className="add-btn-container">
        <Button
          className="add-btn"
          size="small"
          color="primary"
          onClick={handleAdd}
        >
          {formatMessage({ id: 'tlpay.add-more' })}
        </Button>
      </div>
    </fieldset>
  )
}
