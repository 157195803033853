import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Grid,
  FormControlLabel
} from '@material-ui/core'
import AuthView from 'containers/AuthView/connect'
import NotificationsTable from './components/NotificationsTable'
import SnackbarComponent from 'components/Snackbar'
import { filterLocalNotifications, filterValuesToQueryParams } from './utils'
import { NOTIFICATION_TYPES } from 'constants.js'
import { utcToTimezone } from 'utils/date'
import { useIntl } from 'react-intl'

const NotificationCentre = ({
  snackbar,
  location,
  notifications = [],
  searchNotifications,
  dismissNotifications,
  currUserCompany
}) => {
  const [ filters, setFilters ] = useState({
    type: Object.keys(NOTIFICATION_TYPES)
  })
  const [ showDismissed, setShowDismissed ] = useState(false)
  const { formatMessage } = useIntl()

  useEffect(() => {
    const queryParams = filterValuesToQueryParams({ showDismissed })
    searchNotifications(queryParams)
  }, [ showDismissed ])

  const filteredNotifications = filterLocalNotifications(notifications, filters)
  const data = filteredNotifications.map(notification => {
    return {
      ...notification,
      createdAt: utcToTimezone(notification.createdAt, currUserCompany.timezone)
    }
  })
  const totalNumberOfNotifications = filteredNotifications.length

  return (
    <AuthView location={location} title="Notifications">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <NotificationsTable
            data={data}
            onPageChanged={() => {}}
            totalNumberOfItems={totalNumberOfNotifications}
            filtering={{
              defaultFilters: {},
              filters,
              onChange: (newValue) => {
                setFilters(newValue)
              }
            }}
            selectable={{
              actions: [
                {
                  title: formatMessage({ id: 'notification-center.dismiss-selected-notifications' }),
                  icon: 'archive',
                  onClick: selection => dismissNotifications(selection),
                  validate: (selection) => {
                    if (!selection.length) {
                      throw new Error(formatMessage({ id: 'notification-center.please-select-at-least-1-notification' }))
                    }
                    return true
                  }
                }
              ]
            }}
            title={
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={showDismissed}
                    onChange={() => setShowDismissed(!showDismissed)}
                    name="showDismissed"
                  />
                }
                label={formatMessage({ id: 'notification-center.show-dismissed' })}
              />
            }
          />
        </Grid>
      </Grid>
      <SnackbarComponent
        status={snackbar?.isOpened}
        error={snackbar?.isError}
        text={snackbar?.successOrErrorText}
      />
    </AuthView>
  )
}

NotificationCentre.propTypes = {
  notifications: PropTypes.array,
  searchNotifications: PropTypes.func,
  dismissNotifications: PropTypes.func,
  location: PropTypes.object,
  snackbar: PropTypes.object,
  currUserCompany: PropTypes.object
}

export default NotificationCentre
