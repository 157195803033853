import { LANGUAGE_CULTURE_NAMES } from '@travel-ledger/type-constants';

export function getDefaultLanguage() {
  for (const lang of window.navigator.languages) {
    if (LANGUAGE_CULTURE_NAMES[lang]) {
      return lang;
    }
  }
  return 'en-GB'
}
