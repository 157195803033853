import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Component from './SettlementSchedulesList.js'

const mapState = ({ auth }) => ({
  auth,
  push
})

const mapDispatch = dispatch => ({
  push: (data) => dispatch(push(data))
})

export default connect(mapState, mapDispatch)(Component)
