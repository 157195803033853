import { fullName } from 'utils/functions'

// Action Names
const FETCH_TRANSACTIONS = fullName('transactions', 'FETCH_TRANSACTIONS')
const FETCH_TRANSACTIONS_SUCCESS = fullName('transactions', 'FETCH_TRANSACTIONS_SUCCESS')
const FETCH_TRANSACTIONS_FAILURE = fullName('transactions', 'FETCH_TRANSACTIONS_FAILURE')
const POST_TRANSACTION = fullName('transactions', 'POST_TRANSACTION') // ToDo: Add Success and Fail for loading?
const PATCH_TRANSACTION = fullName('transactions', 'PATCH_TRANSACTION') // ToDo: Add Success and Fail for loading?
const REJECT_TRANSACTION = fullName('transactions', 'REJECT_TRANSACTION')
const UNREJECT_TRANSACTION = fullName('transactions', 'UNREJECT_TRANSACTION')
const POST_TRANSACTION_ALLOCATION = fullName('allocations', 'POST_TRANSACTION_ALLOCATION')

export const ACTIONS = {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  POST_TRANSACTION,
  PATCH_TRANSACTION,
  REJECT_TRANSACTION,
  UNREJECT_TRANSACTION,
  POST_TRANSACTION_ALLOCATION
}

export const fetchTransactions = () => ({
  type: FETCH_TRANSACTIONS
})

export const acceptTransaction = id => ({
  type: UNREJECT_TRANSACTION,
  payload: id
})

export const declineTransaction = id => ({
  type: REJECT_TRANSACTION,
  payload: id
})

export const createTransaction = transactionData => {
  return {
    type: POST_TRANSACTION,
    payload: transactionData
  }
}

export const createAllocation = allocationData => ({
  type: POST_TRANSACTION_ALLOCATION,
  payload: allocationData
})

const initialState = []

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default transactions
