import { all, call, put, select, fork } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { takeFirst, getRouterQueryParam, dismissErrorSnackBar } from 'utils/generators'
import { ROUTE_URL } from 'constants.js'
import { ACTIONS } from 'store/registration'
import { replace } from 'connected-react-router'
import {
  performRegistration,
  performFetchRegistrationInvite
} from '../requests'

// Functions
export function* putError(error) {
  yield put({
    type: ACTIONS.SET_ERROR,
    payload: error
  })
  yield delay(1000)
  yield put({
    type: ACTIONS.SET_ERROR,
    payload: false
  })
  yield put({
    type: ACTIONS.SET_FETCHING,
    payload: false
  })
}

// Sagas
export function* fetchRegistrationInviteData() {
  const { FETCH_INVITE_DATA_SUCCESS, FETCH_INVITE_DATA_FAILURE, CLOSE_SNACKBAR } = ACTIONS
  try {
    const token = yield getRouterQueryParam('token')

    const result = yield call(performFetchRegistrationInvite, token)
    const { inviteDetails, to, inviteType } = result.data.attributes
    yield put({
      type: FETCH_INVITE_DATA_SUCCESS,
      payload: {
        inviteDetails: {
          email: to,
          inviteType,
          ...inviteDetails
        },
        token
      }
    })
  } catch (error) {
    yield put({ type: FETCH_INVITE_DATA_FAILURE, payload: error ? error.message : 'Error fetching invite' })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

const getRegistrationState = state => {
  return state.registration
}

export function* register(action) {
  const { REGISTER_SUCCESS, REGISTER_FAILURE, CLOSE_SNACKBAR } = ACTIONS
  try {
    const { token } = yield select(getRegistrationState)
    yield call(performRegistration, { ...action.payload, token })
    yield put({
      type: REGISTER_SUCCESS
    })
    yield put(replace(ROUTE_URL.login))
  } catch (error) {
    yield put({ type: REGISTER_FAILURE, payload: error ? error.message : 'Error registering' })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export default function* rootSaga() {
  const { FETCH_INVITE_DATA, REGISTER } = ACTIONS
  yield all([
    takeFirst(FETCH_INVITE_DATA, fetchRegistrationInviteData),
    takeFirst(REGISTER, register)
  ])
}
