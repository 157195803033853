import { all, call, put, takeLatest, throttle } from 'redux-saga/effects'
import { ACTIONS } from 'store/ptxContacts'
import { performFetchPtxContacts, performFetchPtxContactsMandates } from '../requests'

export function* fetchPtxContacts(action) {
  const { FETCH_PTX_CONTACTS_SUCCESS, FETCH_PTX_CONTACTS_ERROR } = ACTIONS
  try {
    const name = action.payload
    const { data } = yield call(performFetchPtxContacts, name)
    yield put({ type: FETCH_PTX_CONTACTS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FETCH_PTX_CONTACTS_ERROR })
  }
}

export function* fetchPtxContactsMandates(action) {
  const { FETCH_PTX_CONTACTS_MANDATES_SUCCESS, FETCH_PTX_CONTACTS_MANDATES_ERROR } = ACTIONS
  try {
    const contactId = action.payload
    const { data } = yield call(performFetchPtxContactsMandates, contactId)
    yield put({ type: FETCH_PTX_CONTACTS_MANDATES_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FETCH_PTX_CONTACTS_MANDATES_ERROR })
  }
}

export default function* rootSaga() {
  const { FETCH_PTX_CONTACTS, FETCH_PTX_CONTACTS_MANDATES } = ACTIONS
  yield all([
    throttle(300, FETCH_PTX_CONTACTS, fetchPtxContacts),
    takeLatest(FETCH_PTX_CONTACTS_MANDATES, fetchPtxContactsMandates)
  ])
}
