/* eslint-disable no-param-reassign */
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import PropTypes from 'prop-types'

const SelectOnTabMenuItem = React.forwardRef(({ 'data-value': valueForEvent, name, value, onChange, ...props }, ref) => {
  const handleKeyDown = event => {
    if (event.key === 'Tab') {
      event.persist()
      event.target = { value: valueForEvent, name }
      onChange(event)
    }
  }
  return (
    <MenuItem ref={ref} value={value} onKeyDown={handleKeyDown} {...props} />
  )
})

SelectOnTabMenuItem.propTypes = {
  'data-value': PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

SelectOnTabMenuItem.displayName = 'SelectOnTabMenuItem'

export default SelectOnTabMenuItem
