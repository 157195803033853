import { SCHEDULE_DAY_KEY_TITLE } from 'constants.js'
import moment from 'moment'

// HH:mm
export const timePattern = /(?:[01]\d|2[0-3]):(?:[0-5]\d)/

export const getHourAndMinutesFromTime = (time) => {
  const [hour, minute] = time.split(':')
  return { hour: parseInt(hour), minute: parseInt(minute) }
}

/**
 * @param {string} cronValue cron-string
 * @returns {{
 *  month: string
 *  day: string  // monthDate
 *  week: string // weekDay
 *  time: string // format HH:mm
 *  hour: string
 *  minute: string
 *  second: string
 * }}
 */
export const cronExpressionToScheduleFields = (cronValue) => {
  const cronSettings = cronValue?.split(' ')
  let second, minute, hour, day, month, week
  if ((cronSettings?.length ?? 0) < 5) {
    return { second, minute, hour, day, month, week }
  }
  if (cronSettings.length === 5) {
    ;[minute, hour, day, month, week] = cronSettings
    second = 0
  } else {
    ;[second, minute, hour, day, month, week] = cronSettings
  }
  if (/^[0-9]$/.test(minute)) {
    minute = parseInt(minute) < 10 ? `0${minute}` : minute
  }
  if (/^[0-9]$/.test(hour)) {
    hour = parseInt(hour) < 10 ? `0${hour}` : hour
  }
  const time = `${hour}:${minute}`
  return { second, minute, hour, time, day, month, week }
}

export const scheduleFieldsToCronExpression = ({
  second = '0',
  minute = '0',
  hour = '0',
  day = '*',
  month = '*',
  week = '*',
  time = undefined,
}) => {
  let castTime
  if (timePattern.test(time)) {
    castTime = getHourAndMinutesFromTime(time)
  }
  return `${second} ${castTime?.minute ?? minute} ${castTime?.hour ?? hour} ${day} ${month} ${week}`
}

export const getReadableDay = (day) => {
  switch (day) {
    case 'L':
      return 'last'
    case '1':
      return '1st'
    case '2':
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${day}th`
  }
}

export const cronExpressionToReadableString = (cronValue) => {
  const { minute, hour, week, day } = cronExpressionToScheduleFields(cronValue)
  if (!minute || !hour || !week) {
    return ''
  }
  const time = moment(`${hour}${minute}`, 'HHmm').format('HH:mm')
  if (week !== '*') {
    return `Weekly on ${week
      .split(',')
      .map((d) => SCHEDULE_DAY_KEY_TITLE[d])
      .join(',')} at ${time}`
  }
  const readableDay = getReadableDay(day)
  return `Every ${readableDay} day of the month at ${time}`
}
