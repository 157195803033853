import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(3)
  },
  formInput: {
    minWidth: 200
  },
  fabMargin: {
    margin: theme.spacing(1)
  },
  fabExtendedIcon: {
    marginRight: theme.spacing(1)
  },
  createButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchFormColumn: {
    display: 'flex'
  },
  labelBlock: {
    display: 'flex'
  },
  textLabel: {
    display: 'inline-block',
    paddingRight: '12px',
    paddingTop: '12px',
    textAlign: 'right'
  }
}))
