import React from 'react'
import { FINANCIAL_FLOAT_OPTIONS, PAYMENT_PLATFORMS, STATUSES } from 'constants.js'

const PROFILE_UPDATE = 'PROFILE_UPDATE'
const ERROR_EMPTY_FIELD = 'This field is required.'

const SHOULD_BE_NUMBER = [ 'deposit', 'balance' ]

const initialState = {
  deposit: 72,
  depositPeriod: 'AFTER_BOOKING',
  balance: 72,
  balancePeriod: 'BEFORE_DEPARTURE',
  inclTxStatusSubmission: [ STATUSES.ACCEPTED ],
  procRules: [],
  platform: PAYMENT_PLATFORMS.DIRECT_DEBIT_UK,
  name: '',
  nameError: null,
  voidUnacceptedInvoices: false,
  restrictRefundAmendments: true,
  financialFloatOption: Object.keys(FINANCIAL_FLOAT_OPTIONS)[0],
  manualEntryForwardsFunds: false,
  voidNonLiveInvoices: true,
  allowBuyerImport: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case PROFILE_UPDATE:
      return {
        ...state,
        name: action.payload.name,
        deposit: action.payload.depositTerms.numHours,
        depositPeriod: action.payload.depositTerms.when,
        balance: action.payload.balanceTerms.numHours,
        balancePeriod: action.payload.balanceTerms.when,
        platform: action.payload.platform,
        inclTxStatusSubmission: action.payload.sellerRules.validStatusesToInclude,
        restrictRefundAmendments: action.payload.sellerRules.restrictRefundAmendments,
        voidUnacceptedInvoices: action.payload.sellerRules.voidUnacceptedInvoices,
        financialFloatOption: action.payload.processorRules.financialFloatOption,
        manualEntryForwardsFunds: action.payload.processorRules.manualEntryForwardsFunds,
        voidNonLiveInvoices: action.payload.sellerRules.voidNonLiveInvoices ?? false,
        allowBuyerImport: action.payload.sellerRules.allowBuyerImport ?? false,
      }
    case 'name':
      return {
        ...state,
        name: action.payload,
        nameError: action.payload ? null : ERROR_EMPTY_FIELD
      }
    case SHOULD_BE_NUMBER.find(k => k === action.type):
      return {
        ...state,
        [action.type]: action.payload === '' ? '' : Number(action.payload)
      }
    case action.type:
      return {
        ...state,
        [action.type]: action.payload
      }
    default:
      return state
  }
}

export const useBusinessTermsState = (id, existingData) => {
  const [ state, dispatch ] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    if (id && Object.keys(existingData).length) {
      dispatch({ type: PROFILE_UPDATE, payload: existingData })
    }
  }, [ existingData ])

  const handleDispatchValue = React.useCallback(({ target }) => {
    dispatch({ type: target.name, payload: target.value })
  }, [])

  const dispatchExclusiveVoidOrMatchedInvoice = (name, value) => {
    if (name === 'voidUnacceptedInvoices' && value === true && state.inclTxStatusSubmission.includes(STATUSES.MATCHED)) {
      dispatch({ type: 'inclTxStatusSubmission', payload: state.inclTxStatusSubmission.filter(status => status !== STATUSES.MATCHED) })
    }
    if (name === 'inclTxStatusSubmission' && value.includes(STATUSES.MATCHED)) {
      dispatch({ type: 'voidUnacceptedInvoices', payload: false })
    }
  }

  const handleDispatchCheckbox = React.useCallback(name => value => {
    dispatch({ type: name, payload: value })
    dispatchExclusiveVoidOrMatchedInvoice(name, value)
  }, [])

  const handleDispatchSwitch = React.useCallback(({ target }) => {
    dispatch({ type: target.value, payload: target.checked })
  }, [])

  return {
    state,
    handleDispatchValue,
    handleDispatchCheckbox,
    handleDispatchSwitch
  }
}
