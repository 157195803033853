import { connect } from 'react-redux'
import Component from './CompanyInvite'
import { createCompanyInvite, validateCompanyInvite } from 'store/users'

export const mapState = ({ companies, users }) => ({
  ...users,
  currUserCompany: companies?.currUserCompany
})

export const mapDispatch = dispatch => ({
  validateInvites: (file) => dispatch(validateCompanyInvite(file)),
  sendInvites: (data) => dispatch(createCompanyInvite(data))
})

export default connect(mapState, mapDispatch)(Component)
