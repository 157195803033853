
import moment from "moment"
import { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { getSettlementScheduleDates } from "services/settlementSchedulesApi"
import { unionEventMaps } from "../utils"
import { BSD_CALENDAR_EVENTS } from "constants.js"

const useQuerySettlementScheduleDates = (
  settlementScheduleId, from, to,
) => useQuery(
  ['settlementScheduleDates', settlementScheduleId, from, to],
  () => getSettlementScheduleDates(settlementScheduleId, {
    from: from.format('YYYY-MM-DD'),
    to: to.format('YYYY-MM-DD'),
  }),
  { enabled: !!settlementScheduleId },
)

const useMemoExistingEventsMap = (
  settlementScheduleDates, timezone,
) => useMemo(() => {
  if (!settlementScheduleDates) {
    return {}
  }

  const existingEvents = {}

  for (const dateKey in settlementScheduleDates) {
    const dateEvents = settlementScheduleDates[dateKey].map(
      event => {
        const time = event.details?.time ?? '00:00:00'
        const isWeekendOrHoliday = (
          BSD_CALENDAR_EVENTS.WEEKEND === event.type ||
          BSD_CALENDAR_EVENTS.HOLIDAY === event.type
        )

        return {
          ...event,
          id: event.details.id ?? `${event.type}@${dateKey}:${time}`,
          // weekend and holidays do not carry time (always 00:00:00)
          // converting with timezone would cause the dates to shift
          date: isWeekendOrHoliday
            ? moment.tz(event.details.date, timezone)
            : moment.utc(`${dateKey} ${time}`).tz(timezone),
        }
      }
    )

    // after timezone change, the day can be different, so
    // we have to create a new date key to store against
    for (const event of dateEvents) {
      const timezoneDateKey = event.date?.format('YYYY-MM-DD')

      existingEvents[timezoneDateKey] = existingEvents[timezoneDateKey] ?? []
      existingEvents[timezoneDateKey].push(event)
    }
  }

  return existingEvents
}, [settlementScheduleDates, timezone])

export const useSettlementCalendarNavigation = (settlementScheduleId, timezone) => {
  const [monthsToDisplay, setMonthsToDisplay] = useState(3)
  const [startFrom, setStartFrom] = useState(moment().add(-1, 'days'))
  const latestDay = useMemo(
    () => startFrom.clone().add(monthsToDisplay, 'months').endOf('month'),
    [startFrom, monthsToDisplay],
  )
  const { data: queryData } = useQuerySettlementScheduleDates(
    settlementScheduleId, startFrom, latestDay,
  )
  const existingEventsMap = useMemoExistingEventsMap(
    queryData?.data?.attributes, timezone,
  )
  const [unsavedEventsMap, setUnsavedEventsMap] = useState({})
  const currentEventsMap = useMemo(
    () => unionEventMaps(existingEventsMap, unsavedEventsMap),
    [existingEventsMap, unsavedEventsMap],
  )

  const onMonthsToDisplayChange = (event) => setMonthsToDisplay(event.target.value)
  const onNavigate = (direction) => () => {
    const addSub = direction < 0 ? 'subtract' : 'add'

    setStartFrom(startFrom
      .clone()
/*  */[addSub](monthsToDisplay, 'months')
      .startOf('month')
    )
  }

  return {
    existingEventsMap,
    currentEventsMap,
    unsavedEventsMap,
    setUnsavedEventsMap,
    startFrom,
    onNavigate,
    onMonthsToDisplayChange,
    monthsToDisplay,
  }
}
