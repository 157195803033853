import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'

const UnderlinedCell = forwardRef(({ children, error = false, warning = true, ...props }, ref) => {
  const classes = useStyles({ error, warning })
  return (
    <span
      {...props}
      ref={ref}
      className={classes.underline}
    >
      {children}
    </span>
  )
})

UnderlinedCell.displayName = 'UnderlinedCell'

UnderlinedCell.propTypes = {
  children: PropTypes.element,
  error: PropTypes.bool,
  warning: PropTypes.bool
}

export default memo(UnderlinedCell)
