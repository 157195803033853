import React from 'react'
import PropTypes from 'prop-types'
import { button } from 'react-validation'

const FormButton = ({ hasErrors, className, onClick, validation, disable, children }) => {
  return (
    <button className={className} onClick={onClick} disabled={disable || (validation ? hasErrors : false)}>
      { children }
    </button>
  )
}

FormButton.defaultProps = {
  className: 'button is-info',
  onClick: () => {},
  validation: true,
  disable: false
}

FormButton.propTypes = {
  hasErrors: PropTypes.bool,
  validation: PropTypes.bool,
  disable: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ])
}

export default button(FormButton)
