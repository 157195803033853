/* eslint-disable react/display-name */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import data from './data'
import SelectOnTabMenuItem from 'components/SelectOnTabMenuItem'

const TimeZoneInput = memo(({ defaultValue, value, onChange, label = 'Time Zone', ...props }) => {
  const handleChange = e => {
    onChange(e.target.value)
  }

  return (
    <FormControl {...props}>
      <InputLabel htmlFor="time-zone">{label}</InputLabel>
      <Select
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        label={label}
        MenuProps={{variant: "menu"}}
        inputProps={{
          name: label,
          id: 'time-zone'
        }}
      >
        {data.map((item, key) => (
          <SelectOnTabMenuItem key={key} value={item.name} onChange={handleChange}>{item.title}</SelectOnTabMenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

TimeZoneInput.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
}

export default TimeZoneInput
