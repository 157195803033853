import React from 'react'
import PropTypes from 'prop-types'
import { QUICK_VIEWS } from 'constants.js'
import { useStyles } from './styles'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useIntl } from 'react-intl'
import kebabCase from 'lodash/kebabCase'

const QuickViewsDropdown = ({ isProcessingEntity, onQuickViewChange, quickView }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <FormControl size="small" className={classes.form}>
      <FormControlLabel
        control={
          <Select
            className={classes.quickViews}
            variant="outlined"
            value={quickView}
            onChange={onQuickViewChange}
            MenuProps={{variant: "menu"}}
          >
            {Object.entries(QUICK_VIEWS)
              .filter(([key]) => {
                if (!isProcessingEntity) {
                  return !key.includes('PE')
                } else {
                  return key.includes('PE')
                }
              })
              .map(([key, _value]) =>
                <MenuItem key={key} value={_value}>
                  {formatMessage({ id: `quick-views.${kebabCase(_value)}` })}
                </MenuItem>
              )}
          </Select>
        }
        label={formatMessage({ id: 'quick-views.quick-view' }) + ':'}
        labelPlacement="start"
      />
    </FormControl>
  )
}

QuickViewsDropdown.propTypes = {
  onQuickViewChange: PropTypes.func,
  isProcessingEntity: PropTypes.bool,
  quickView: PropTypes.string,
}

export default QuickViewsDropdown