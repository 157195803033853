import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from 'components/Button'
import CardContent from '@material-ui/core/CardContent'
import NotificationListCheckbox from './components/NotificationListCheckbox'
import AuthView from '../AuthView/connect'
import PropTypes from 'prop-types'
import { useGetNotificationsConfig, usePatchNotificationsConfig } from './hooks'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './styles'
import { Box } from '@material-ui/core'
import { DEFAULT_EMAIL_NOTIFY_FOR, DEFAULT_PLATFORM_NOTIFY_FOR, NOTIFICATION_MODULES } from 'constants.js'
import { useIntl } from 'react-intl'

const NotificationCentreConfiguration = ({ location, auth }) => {
  const classes = useStyles()
  const { data: notificationsConfigData, loading } = useGetNotificationsConfig(auth.id)
  const { mutate: notificationSettingsMutate } = usePatchNotificationsConfig(auth.id)
  const [notifyEmailFor, setNotifyEmailFor] = useState()
  const [notifyPlatformFor, setNotifyPlatformFor] = useState()
  const { formatMessage } = useIntl()

  useEffect(() => {
    const user = notificationsConfigData?.data?.attributes
    setNotifyEmailFor(user?.notifyEmailFor || DEFAULT_EMAIL_NOTIFY_FOR)
    setNotifyPlatformFor(user?.notifyPlatformFor || DEFAULT_PLATFORM_NOTIFY_FOR)
  }, [notificationsConfigData])

  const handleSaveConfig = async () => {
    await notificationSettingsMutate({ notifyEmailFor, notifyPlatformFor })
  }

  if (loading || !notifyEmailFor || !notifyPlatformFor) {
    return (
      <AuthView location={location} title="Notifications Configuration">
        <Typography>Loading...</Typography>
      </AuthView>
    )
  }

  return (
    <AuthView location={location} title="Notifications Configuration">
      <>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5">{formatMessage({ id: 'notifications-configuration.receive-notifications' })}:</Typography>
              </Grid>
              <Grid item xs={6}>
                {Object.values(NOTIFICATION_MODULES).map((module) => (
                  <Accordion key={module}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>{formatMessage({
                        id: `notification-modules.${module.replace(/\s+/g, '-').toLowerCase()}`,
                      })}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={6}>
                        <NotificationListCheckbox
                          title={formatMessage({ id: 'notifications-configuration.on-my-email-address' })}
                          value={notifyEmailFor[module] || DEFAULT_EMAIL_NOTIFY_FOR[module] }
                          onChange={(newValue) => {
                            setNotifyEmailFor((previous) => ({ ...previous, [module]: newValue }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NotificationListCheckbox
                          title={formatMessage({ id: 'notifications-configuration.on-travel-ledger-platform' })}
                          value={notifyPlatformFor[module] || DEFAULT_PLATFORM_NOTIFY_FOR[module] }
                          onChange={(newValue) => {
                            setNotifyPlatformFor((previous) => ({ ...previous, [module]: newValue }))
                          }}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant="outlined" color="success" icon="save" onClick={handleSaveConfig}>
                    {formatMessage({ id: 'notifications-configuration.save-configuration' })}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    icon="reset"
                    onClick={async () => {
                      await handleSaveConfig({
                        nofitfyEmailFor: DEFAULT_EMAIL_NOTIFY_FOR,
                        notifyPlatformFor: DEFAULT_PLATFORM_NOTIFY_FOR,
                      })
                      setNotifyEmailFor(DEFAULT_EMAIL_NOTIFY_FOR)
                      setNotifyPlatformFor(DEFAULT_PLATFORM_NOTIFY_FOR)
                    }}
                  >
                    {formatMessage({ id: 'notifications-configuration.reset-configuration' })}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </>
    </AuthView>
  )
}

NotificationCentreConfiguration.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
}

export default NotificationCentreConfiguration
