import React, { memo } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import PropTypes from 'prop-types'
import { NOTIFICATION_TYPES } from 'constants.js'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const NotificationListCheckbox = ({ title = 'All', value = {}, onChange }) => {
  const allChecked = Object.values(value).every((type) => type === true)
  const checkedCount = Object.values(value).filter((type) => type === true).length
  const isIndeterminate = checkedCount > 0 && checkedCount < Object.keys(NOTIFICATION_TYPES).length
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const handleCheckboxChange = (ev) => {
    const {
      target: { name, checked },
    } = ev
    if (name === 'all') {
      if (checked) {
        return onChange(
          Object.values(NOTIFICATION_TYPES).reduce((acc, cur) => {
            acc[cur] = true
            return acc
          }, {}),
        )
      }
      return onChange(
        Object.values(NOTIFICATION_TYPES).reduce((acc, cur) => {
          acc[cur] = false
          return acc
        }, {}),
      )
    }
    if (checked) {
      return onChange({ ...value, [name]: true })
    }
    return onChange({ ...value, [name]: false })
  }

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecked}
              indeterminate={isIndeterminate}
              onChange={handleCheckboxChange}
              name="all"
              color="primary"
            />
          }
          label={title}
        />
      </FormLabel>
      <FormGroup className={classes.formGroup}>
        {Object.values(NOTIFICATION_TYPES).map((type) => (
          <FormControlLabel
            key={type}
            control={<Checkbox checked={value[type]} onChange={handleCheckboxChange} name={type} color="primary" />}
            label={formatMessage({ id: `notification-types.${type}` })}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

NotificationListCheckbox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default memo(NotificationListCheckbox)
