import { TRANS_TYPES } from './constants'
import moment from 'moment-timezone'
import { get as _get } from 'lodash'
import { _contains } from 'utils/filters'

export const parseTransactions = (transactions, companyTimezone) => {
  const timezone = companyTimezone || 'Europe/London'
  return transactions.map((transaction) => {
    return {
      ...transaction,
      id: `${transaction.authCode}_${transaction.createdAt}`,
      transactionType: TRANS_TYPES[transaction.transactionType],
      amount: transaction.cardTransactionAmount.toLocaleString('en-UK', { style: 'currency', currency: transaction.transactionCurrencyCode }),
      remittanceId: transaction?.remittance?.id || 'NA',
      commercialLinkDisplayName: transaction?.commercialLink?.displayName || 'NA',
      date: moment.utc(transaction.createdAt).tz(timezone),
      reference: transaction.labels?.customerComments || transaction.labels?.iccNarrative || transaction.tags?.reference || transaction.authCode || transaction.retrievalReferenceNumber || '',
      payer: transaction.labels?.remitterName || transaction.labels?.senderName || transaction.labels?.senderFirstName && transaction.labels.senderFirstName + ' ' + transaction.labels?.senderLastName || '',
      method: transaction.labels?.iccPayMode || transaction.labels?.payoutMethod || TRANS_TYPES[transaction.transactionType] || '',
      currency: transaction.transactionCurrencyCode,
    }
  })
}

export const filterValuesToQueryParams = (filterValues, accountSelected) => {
  const filtersToSet = {
    order: 'DESC'
  }
  if (accountSelected?.curSymbol) {
    filtersToSet.currency = accountSelected.curSymbol
  }
  if (filterValues?.currentPage) {
    filtersToSet.page = filterValues.currentPage
  }
  if (filterValues?.itemsPerPage) {
    filtersToSet.size = filterValues.itemsPerPage
  }
  if (filterValues.transactionType && filterValues.transactionType !== 'ALL') {
    filtersToSet.transactionType = filterValues.transactionType
  }
  if (filterValues.status && filterValues.status !== 'ALL') {
    filtersToSet.status = filterValues.status
  }
  if (filterValues.date) {
    filtersToSet.startDate = moment(filterValues.date.startDate).format('YYYY-MM-DD')
    filtersToSet.endDate = moment(filterValues.date.endDate).format('YYYY-MM-DD')
  }
  if (filterValues.retrievalReferenceNumber) {
    filtersToSet.authCode = filterValues.retrievalReferenceNumber
  }

  return filtersToSet
}

export const filterLocalTransactions = (transactions, filters) => {
  return transactions.filter((transaction) => {
    if (_get(filters, 'amount.value')) {
      if (
        (filters.amount.type === 'eq' &&
          !(
            parseFloat(filters.amount.value) === transaction.cardTransactionAmount
          )) ||
        (filters.amount.type === 'gte' &&
          !(parseFloat(filters.amount.value) <= transaction.cardTransactionAmount)) ||
        (filters.amount.type === 'lte' &&
          !(parseFloat(filters.amount.value) >= transaction.cardTransactionAmount))
      ) {
        return false
      }
    }
    if (_get(filters, 'currentBalance.value')) {
      if (
        (filters.currentBalance.type === 'eq' &&
          !(parseFloat(filters.currentBalance.value) === transaction.currentBalance)) ||
        (filters.currentBalance.type === 'gte' &&
          !(parseFloat(filters.currentBalance.value) <= transaction.currentBalance)) ||
        (filters.currentBalance.type === 'lte' &&
          !(parseFloat(filters.currentBalance.value) >= transaction.currentBalance))
      ) {
        return false
      }
    }
    if (filters.remittanceId && !_contains(filters.remittanceId, transaction.remittanceId)) {
      return false
    }
    if (filters.commercialLinkDisplayName && !_contains(filters.commercialLinkDisplayName, transaction.commercialLinkDisplayName)) {
      return false
    }
    if (filters.payer && !_contains(filters.payer, transaction.payer)) {
      return false
    }
    if (filters.method && !_contains(filters.method, transaction.method)) {
      return false
    }
    if (filters.reference && !_contains(filters.reference, transaction.reference)) {
      return false
    }
    return true
  })
}
