import { fullName } from 'utils/functions'

// Action Names
const FETCH_PTX_CONTACTS = fullName('ptx-contacts', 'FETCH_PTX_CONTACTS')
const FETCH_PTX_CONTACTS_SUCCESS = fullName('ptx-contacts', 'FETCH_PTX_CONTACTS_SUCCESS')
const FETCH_PTX_CONTACTS_ERROR = fullName('ptx-contacts', 'FETCH_PTX_CONTACTS_ERROR')
const FETCH_PTX_CONTACTS_MANDATES = fullName('ptx-contacts', 'FETCH_PTX_CONTACTS_MANDATES')
const FETCH_PTX_CONTACTS_MANDATES_SUCCESS = fullName('ptx-contacts', 'FETCH_PTX_CONTACTS_MANDATES_SUCCESS')
const FETCH_PTX_CONTACTS_MANDATES_ERROR = fullName('ptx-contacts', 'FETCH_PTX_CONTACTS_MANDATES_ERROR')

export const ACTIONS = {
  FETCH_PTX_CONTACTS,
  FETCH_PTX_CONTACTS_SUCCESS,
  FETCH_PTX_CONTACTS_ERROR,
  FETCH_PTX_CONTACTS_MANDATES,
  FETCH_PTX_CONTACTS_MANDATES_SUCCESS,
  FETCH_PTX_CONTACTS_MANDATES_ERROR
}

export const fetchPtxContacts = (name) => ({
  type: FETCH_PTX_CONTACTS,
  payload: name
})

export const fetchPtxContactsMandates = (contactId) => ({
  type: FETCH_PTX_CONTACTS_MANDATES,
  payload: contactId
})

const initialState = {
  ptxContacts: [],
  mandates: []
}

const ptxContacts = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PTX_CONTACTS_SUCCESS:
      return {
        ...state,
        ptxContacts: action.payload
      }
    case FETCH_PTX_CONTACTS_MANDATES_SUCCESS:
      return {
        ...state,
        mandates: action.payload
      }
    default:
      return state
  }
}
export default ptxContacts
