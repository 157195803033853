import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { MalformedFileError } from 'utils/errors'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ClearIcon from '@material-ui/icons/Clear'
import { useStyles } from './styles'

const FormattedError = ({ error }) => {
  const boxRef = useRef()
  const handleCopyText = (ev) => {
    ev.stopPropagation()
    const formattedErrorMessage = boxRef?.current?.innerText
    if (formattedErrorMessage) {
      if (navigator.clipboard !== undefined) {
        navigator.clipboard.writeText(formattedErrorMessage)
      }
    }
  }

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Grid container spacing={1} justify="flex-end">
          <Grid item className={classes.headerContent}>
            <Typography variant="h6">Error</Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.headerButton} aria-label="copy" size="small" onClick={handleCopyText}>
              <FileCopyIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton className={classes.headerButton} aria-label="close" size="small">
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.body} ref={boxRef}>
        { error instanceof MalformedFileError ?
          Object.entries(error.getErrorByFileName()).map(([ file, errors ], index) => (
            <Box key={file}>
              <Typography variant="h6">File {file}</Typography>
              {
                errors.map((line, errorIndex) => (
                  <p key={`${index}_${errorIndex}`}>{`${line.lineNo ? `Line ${line.lineNo}:`: ''}  ${line.message}`}</p>
                ))
              }
            </Box>
          )) : (
            <p>{error.toString()}</p>
          )
        }
      </Box>
    </Box>
  )
}

FormattedError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.instanceOf(MalformedFileError)
  ])
}

export default FormattedError
