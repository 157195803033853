import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      height: '100vh',
      background: theme.palette.grey[100]
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '90vh', // only 90 to respect bottom env message
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    background: theme.palette.background.default,
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))
