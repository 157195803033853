import PropTypes from 'prop-types'
import React, { memo, useMemo, useState } from 'react'
import {
  DataTypeProvider,
  EditingState,
  IntegratedPaging,
  IntegratedSelection,
  PagingState,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid as DEGrid,
  PagingPanel,
  Table as DETable,
  VirtualTable,
  Toolbar as DEToolbar,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui'

import { memoizingComparison } from 'utils/functions'

import {
  getCurrencyColumnsName,
  getDateColumnsName,
  getDatetimeColumnsName,
  getEnabledSortingColumns,
} from '../../utils'
import { TableActionColumn } from '../../plugins/TableActionColumn'
import Command from '../../plugins/CommandComponent'
import CustomSorting from '../../plugins/CustomSorting'
import EnhancedEditingState from '../../plugins/EnhancedEditingState'
import ExtraToolbar from '../../plugins/ExtraToolbar'
import FilteringState from '../../plugins/FilteringState'
import ResetFilterPanel from '../../plugins/ResetFilterPanel'
import TableActionFooter from '../../plugins/TableActionFooter'
import TableFilterRow from '../../plugins/TableFilterRow'
import TableInlineCellEditing from '../../plugins/TableInlineCellEditing'
import TableSummaryRow from '../../plugins/TableSummaryRow'
import TableTopSummary from '../../plugins/TableTopSummary'
import ToolbarAction from '../../plugins/ToolbarAction'
import ToolbarTitle from '../../plugins/ToolbarTitle'

import { CurrencyCell, DateCell } from '../DataTypeCells'
import { enhancedCheckbox } from '../Checkbox'
import { enhancedRootGrid } from '../RootGrid'
import { makeRow } from '../Row'
import Cell from '../Cell'
import ColumnChooserButton from '../ActionButtons/ColumnChooserButton'
import EditCell from '../EditCell'
import ExportButton from '../ActionButtons/ExportButton'
import Footer from '../Footer'
import Pagination from '../Pagination'
import RowDetailToggleButton from '../RowDetailToggleButton'
import StickyTable from '../StickyTable'
import Toolbar from '../Toolbar'
import HeaderRow from '../HeaderRow'
import { makeHeaderRowCell } from '../HeaderRowCell'
import { makeHeaderSortLabel } from '../HeaderSortLabel'
import { useIntl } from 'react-intl'

const DataGrid = ({
  actions = [],
  columnOrder,
  columns,
  columnWidths,
  columnChooserContainerComponent,
  currentPage,
  data,
  defaultExpandedRowIds,
  disableColumnVisibility,
  disableExport,
  disableReordering,
  disableResizing,
  disableRow,
  extra,
  filtering,
  fullRemainingWindowHeight,
  height,
  hiddenColumnNames,
  isRowExpandable,
  lastUpdate,
  onChangePage,
  onColumnWidthsChange,
  onCurrentPageChange,
  onHiddenColumnNamesChange,
  onOrderChange,
  onPageSizeChange,
  onRowChangesChange,
  onSelectionChange,
  onSortingChange,
  onStartExport,
  pageSize,
  pageSizes,
  pagination,
  RowDetail,
  selectable,
  sort,
  sorting,
  summaryToolbar,
  tableColumnExtensions,
  tableMessages,
  title,
  toolbarActions = [],
  expandableComponentTootltip = '',
  virtualTable = false,
}) => {
  const currencyColumns = useMemo(() => getCurrencyColumnsName(columns), [columns])
  const dateColumns = useMemo(() => getDateColumnsName(columns), [columns])
  const datetimeColumns = useMemo(() => getDatetimeColumnsName(columns), [columns])
  const disabledSortingColumns = useMemo(() => getEnabledSortingColumns(columns), [columns])
  const memoizedEnhancedRootGrid = useMemo(
    () => enhancedRootGrid(height, fullRemainingWindowHeight),
    [height, fullRemainingWindowHeight],
  )
  const [hideSortTooltip,setHideSortTooltip] = useState(false)
  const { formatMessage } = useIntl()

  const CurrencyTypeProvider = (props) => <DataTypeProvider formatterComponent={CurrencyCell} {...props} />

  // eslint-disable-next-line react/prop-types
  const DateTypeProvider = ({ hasTime, ...props }) => (
    <DataTypeProvider formatterComponent={(_props) => <DateCell hasTime={hasTime} {..._props} />} {...props} />
  )

  const totalNumberOfItems = pagination?.totalNumberOfItems

  const handleFilterChange = (val) => {
    // We have to check if filters are changed to avoid unnecessary rerenders
    if (JSON.stringify(val) === JSON.stringify(filtering?.filters)) {
      return
    }
    // If we are changing filters, we have to set page to 0 in order to avoid weird edge cases
    if (currentPage > 0) {
      onChangePage(0)
    }
    if (filtering?.onChange) {
      filtering.onChange(val)
    }
  }

  return (
    <DEGrid
      rows={data}
      columns={columns.filter((column) => !column.disabled)}
      rootComponent={memoizedEnhancedRootGrid}
      key={lastUpdate}
      getRowId={(row) => row.id}
    >
      <RowDetailState defaultExpandedRowIds={defaultExpandedRowIds || []} />
      <EditingState />
      <EnhancedEditingState onRowChangesChange={onRowChangesChange} />
      {currencyColumns?.length ? <CurrencyTypeProvider for={currencyColumns} /> : null}
      {dateColumns?.length ? <DateTypeProvider for={dateColumns} /> : null}
      {datetimeColumns?.length ? <DateTypeProvider for={datetimeColumns} hasTime={true} /> : null}
      <SortingState
        sorting={[sorting].map((column) => ({ columnName: column.orderBy, direction: column.order?.toLowerCase() }))}
        onSortingChange={onSortingChange}
        columnSortingEnabled={!sort?.disabled}
        columnExtensions={disabledSortingColumns}
      />
      <CustomSorting />
      <DragDropProvider />
      <FilteringState
        filters={filtering?.filters}
        defaultFilters={filtering?.defaultFilters}
        onFiltersChange={handleFilterChange}
      />
      <SelectionState
        onSelectionChange={onSelectionChange}
        {...(selectable?.checked ? { selection: selectable?.checked } : {})}
      />
      <PagingState
        currentPage={currentPage}
        pageSize={pageSize}
        {...(!pagination?.onPageChanged ? { onCurrentPageChange: onCurrentPageChange } : {})}
        onPageSizeChange={onPageSizeChange}
      />
      <IntegratedPaging />
      <IntegratedSelection />
      {virtualTable ? (
        <VirtualTable
          height="auto"
          tableComponent={StickyTable}
          rowComponent={makeRow(disableRow)}
          cellComponent={Cell}
          columnExtensions={tableColumnExtensions}
          footerComponent={Footer}
          estimatedRowHeight={50}
          messages={tableMessages}
        />
      ) : (
        <DETable
          tableComponent={StickyTable}
          rowComponent={makeRow(disableRow)}
          cellComponent={Cell}
          columnExtensions={tableColumnExtensions}
          footerComponent={Footer}
          messages={tableMessages}
        />
      )}
      {filtering ? <TableFilterRow /> : null}
      <TableInlineCellEditing selectTextOnEditStart cellComponent={(cellProps) => <EditCell {...cellProps} />} />
      {actions.length ? <TableActionColumn actions={actions} commandComponent={Command} /> : null}
      {selectable ? (
        <TableSelection
          showSelectAll
          selectionColumnWidth={28}
          cellComponent={enhancedCheckbox(selectable.isChecked, selectable.isDisabled)}
        />
      ) : null}
      <TableSummaryRow />
      {RowDetail ? (
        <TableRowDetail
          contentComponent={RowDetail}
          toggleColumnWidth={18}
          toggleCellComponent={(props) => (
            <RowDetailToggleButton tooltip={expandableComponentTootltip} isExpandable={isRowExpandable} {...props} />
          )}
        />
      ) : null}
      {!disableResizing ? (
        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={onColumnWidthsChange} />
      ) : null}
      {!disableReordering ? <TableColumnReordering order={columnOrder} onOrderChange={onOrderChange} /> : null}
      {!disableColumnVisibility ? (
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={onHiddenColumnNamesChange}
        />
      ) : null}
      {disableExport && disableColumnVisibility && !title ? null : <DEToolbar rootComponent={Toolbar} />}
      {toolbarActions.map((action) => (
        <ToolbarAction key={action.tooltip} {...action} />
      ))}
      <ToolbarTitle>{title}</ToolbarTitle>
      {filtering?.clearFilters ? <ResetFilterPanel onClick={filtering.clearFilters} /> : null}
      {disableColumnVisibility ? null : <ColumnChooser toggleButtonComponent={ColumnChooserButton} containerComponent={columnChooserContainerComponent} />}
      {disableExport ? null : <ExportPanel messages={{ exportAll: formatMessage({ id: 'table.exportAllData' }) }} startExport={onStartExport} toggleButtonComponent={ExportButton} />}
      {extra ? <ExtraToolbar content={extra} /> : null}
      <TableHeaderRow
        showSortingControls={!sort?.disabled}
        rowComponent={HeaderRow}
        cellComponent={makeHeaderRowCell(filtering?.filters)}
        sortLabelComponent={makeHeaderSortLabel(setHideSortTooltip)}
        messages={{
          sortingHint: formatMessage({ id: 'table.sort' }),
          ...hideSortTooltip && { 'sortingHint': '' }
        }}
      />
      {summaryToolbar ? <TableTopSummary>{summaryToolbar}</TableTopSummary> : null}
      {!pagination?.disabled && pageSize ? (
        <PagingPanel
          pageSizes={pageSizes}
          {...(pagination?.onPageChanged
            ? {
                // eslint-disable-next-line react/display-name, react/prop-types
                containerComponent: (props) => (
                  <Pagination
                    count={totalNumberOfItems}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageSizes={pageSizes}
                    onChangePage={onChangePage}
                    onChangePageSize={props.onPageSizeChange}
                  />
                ),
              }
            : {})}
          messages={{
            rowsPerPage: formatMessage({ id: 'table-pagination.rows-per-page' }),
            ...tableMessages,
          }}
        />
      ) : null}
      {selectable?.actions ? (
        <TableActionFooter actions={selectable?.actions}>{summaryToolbar}</TableActionFooter>
      ) : null}
    </DEGrid>
  )
}

DataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullRemainingWindowHeight: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  name: PropTypes.string,
  actions: PropTypes.array,
  sorting: PropTypes.object,
  RowDetail: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  toolbarActions: PropTypes.array,
  defaultExpandedRowIds: PropTypes.array,
  disableExport: PropTypes.bool,
  disableResizing: PropTypes.bool,
  disableReordering: PropTypes.bool,
  disableColumnVisibility: PropTypes.bool,
  disableRow: PropTypes.func,
  sort: PropTypes.shape({
    disabled: PropTypes.bool,
    defaultColumn: PropTypes.shape({
      orderBy: PropTypes.string,
      order: PropTypes.oneOf(['ASC', 'DESC']),
    }),
  }),
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    onPageChanged: PropTypes.func,
    disabled: PropTypes.bool,
    totalNumberOfItems: PropTypes.number,
  }),
  summaryToolbar: PropTypes.func,
  selectable: PropTypes.shape({
    onChange: PropTypes.func,
    actions: PropTypes.array,
    isChecked: PropTypes.func,
    isDisabled: PropTypes.func,
    checked: PropTypes.array,
  }),
  filtering: PropTypes.shape({
    defaultFilters: PropTypes.object,
    filters: PropTypes.object,
    onChange: PropTypes.func,
    clearFilters: PropTypes.func,
  }),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onRowChangesChange: PropTypes.func,
  onSortingChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.array,
  columnWidths: PropTypes.array,
  onColumnWidthsChange: PropTypes.func,
  columnOrder: PropTypes.array,
  onOrderChange: PropTypes.func,
  hiddenColumnNames: PropTypes.array,
  onHiddenColumnNamesChange: PropTypes.func,
  tableColumnExtensions: PropTypes.array,
  onChangePage: PropTypes.func,
  onCurrentPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onStartExport: PropTypes.func,
  lastUpdate: PropTypes.number,
  isRowExpandable: PropTypes.func,
  virtualTable: PropTypes.bool,
  expandableComponentTootltip: PropTypes.string,
}

export default memo(DataGrid, memoizingComparison)
