import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

const Footer = ({ children }) => {
  return (
    <Grid justify="flex-start" alignItems="center" container>
      { children }
    </Grid>
  )
}

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

Footer.displayName = 'Footer'

export default Footer
