import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@devexpress/dx-react-grid-material-ui'
import { isProduction } from 'constants.js'

const BOTTOM_PADDING = !isProduction ? 40 : 20

const RootGrid = ({ height, fullRemainingWindowHeight, ...otherProps }) => {
  const gridRef = useRef()
  const [ gridHeight, setGridHeight ] = useState(height || 'auto')

  const setGridHeightComputed = useCallback(
    () => {
      if (fullRemainingWindowHeight && gridRef?.current) {
        const { innerHeight } = window
        const { top } = gridRef?.current?.getBoundingClientRect()
        setGridHeight(innerHeight - top - BOTTOM_PADDING)
      }
    },
    [ gridRef, fullRemainingWindowHeight ],
  )

  useEffect(() => {
    setGridHeightComputed()
  }, [ setGridHeightComputed ])

  return (
    <div ref={gridRef}>
      <Grid.Root {...otherProps} style={{ height: gridHeight }} />
    </div>
  )
}

RootGrid.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fullRemainingWindowHeight: PropTypes.bool
}

export const enhancedRootGrid = (height = 'auto', fullRemainingWindowHeight) => (props) => <RootGrid { ...props } height={height} fullRemainingWindowHeight={fullRemainingWindowHeight} />

export default RootGrid
