import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(() => ({
  rowDetail: {
    whiteSpace: 'pre'
  },
  messageText: {
    height: 'auto',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line'
  },
  messageTextNew: {
    height: 'auto',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  }
}))
