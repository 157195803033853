import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL } from 'constants.js'
import { Grid, Typography, Card, CardContent } from '@material-ui/core'
import AuthView from 'containers/AuthView/connect'
import connect from './connect'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import { useStyles } from './styles'
import { useForm, Controller } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { formSchema, defaultValues } from './billingDetailsValidationSchema'
import { updateCompany } from 'services/companiesApi'
import CountrySelectInput from 'containers/CountrySelectInput'
import { InputText } from 'components/ui/Form/InputText'
import { ROUTE_URL } from 'constants.js'
import { Redirect } from 'react-router'
import { mapFormKeys } from 'utils/functions';
import { useIntl } from 'react-intl'

const BillingDetails = ({ location, auth, currUserCompany }) => {
  const {
    handleSubmit,
    control,
    reset,

    formState: {
      errors,
    },
  } = useForm({ resolver: joiResolver(formSchema), defaultValues })
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { isLoading, data, refetch } = useQuery(
    `get-company-billing-details`,
    () => get(`${API_URL}/companies/${auth.companyId}`),
    {
      retry: false,
    },
  )
  const { mutate: updateBillingDetails, isLoading: isUpdating } = useMutation(updateCompany, {
    onSuccess: () => {
      toast.success(formatMessage({ id: 'billing-details.billing-details-updated' }))
      refetch()
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  const onSubmit = handleSubmit((formData) => {
    const body = { id: auth.companyId, ...formData }
    updateBillingDetails(body)
  })

  useEffect(() => {
    if (data) {
      reset(mapFormKeys(data.data.attributes, defaultValues));
    } else {
      reset(defaultValues)
    }
  }, [data, reset])

  if (!currUserCompany?.isHeadOffice) {
    return <Redirect to={ROUTE_URL.home} />
  }

  if (isLoading) {
    return (
      <AuthView location={location} title="Billing details">
        <Typography>Loading...</Typography>
      </AuthView>
    )
  }

  return (
    <AuthView location={location} auth={auth} title="Billing details">
      <Card className={classes.topPaper}>
        <CardContent>
          <Grid container spacing={1} xs={12} lg={6}>
            <Typography paragraph>
              {formatMessage({ id: 'billing-details.please-fill-in-your-company-details-to-facilitate-the-subscription-billing-process-on-travel-ledger' })}
            </Typography>
            <InputText
              errors={errors}
              name="taxIdentificationNumber"
              control={control}
              label={formatMessage({ id: 'billing-details.vat-sales-tax-id' })}
            />
            <InputText errors={errors} name="billingAddress" control={control} label={formatMessage({ id: 'billing-details.address' })} />
            <InputText errors={errors} name="billingCity" control={control} label={formatMessage({ id: 'billing-details.city' })} />
            <Controller
              name="billingCountryId"
              control={control}
              // eslint-disable-next-line no-unused-vars
              render={({ field: { ref, ...field } }) => (
                <CountrySelectInput
                  {...field}
                  defaultValue=""
                  label={formatMessage({ id: 'billing-details.country' })}
                  fullWidth
                  required
                  variant="outlined"
                  className={classes.input}
                  error={!!errors?.countryId}
                  helperText={errors?.countryId?.message}
                />
              )}
            />
          </Grid>
          <Button
            disabled={isUpdating}
            variant="extended"
            type="submit"
            size="large"
            color="success"
            aria-label="add"
            icon="save"
            onClick={onSubmit}
            className={classes.buttonSpacing}
          >
            {formatMessage({ id: 'billing-details.save' })}
          </Button>
          <Button
            variant="extended"
            size="large"
            color="primary"
            onClick={() => reset(mapFormKeys(data.data.attributes, defaultValues))}
            className={classes.buttonSpacing}
          >
            {formatMessage({ id: 'billing-details.cancel' })}
          </Button>
        </CardContent>
      </Card>
    </AuthView>
  )
}

BillingDetails.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(BillingDetails)
