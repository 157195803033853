import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import { List, ListItem, ListItemText, Checkbox, Divider, Typography } from '@material-ui/core'

const columnChooserContainer =
  ({ groups, handleToggle, hiddenColumns }) =>
  ({ children }) => {
    const classes = useStyles()
    return (
      <>
        <div>
          {groups.map(({ groupLabel, options }, index) => {
            return (
              <div key={index}>
                <Typography className={classes.groupLabel}>{groupLabel}</Typography>
                <List className={classes.list}>
                  {options.map(({ label, value, columns }) => {
                    const labelId = `column-chooser-group-${value}`
                    const checked = columns.every((c) => !hiddenColumns.includes(c))
                    return (
                      <ListItem
                        key={labelId}
                        role={undefined}
                        dense
                        button
                        onClick={() => {
                          const hide = checked
                          const toggleColumnsVisibility = hide
                            ? [...new Set([...hiddenColumns, ...columns])]
                            : hiddenColumns.filter((c) => !columns.includes(c))
                          handleToggle(toggleColumnsVisibility)
                        }}
                      >

                          <Checkbox
                            edge="start"
                            checked={checked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />

                        <ListItemText id={labelId} primary={label} />
                      </ListItem>
                    )
                  })}
                </List>
              </div>
            )
          })}
        </div>
        <Divider />
        {children}
      </>
    )
  }

columnChooserContainer.propTypes = {
  children: PropTypes.element,
  handleToggle: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
}

export default columnChooserContainer
