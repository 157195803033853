import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import Modal from 'components/Modal'

export const useDeleteModal = (onSuccess) => {
  const [ selection, setSelection ] = useState([])

  const openModal = (ids) => {
    setSelection(ids)
  }

  const onCancel = () => {
    setSelection([])
  }

  const onConfirm = () => {
    onSuccess(selection)
    onCancel()
  }

  return {
    selection,
    openModal,
    onCancel,
    onConfirm
  }
}

const DeleteConfirmation = ({ selection, onCancel, onConfirm }) => {
  const modalRef = useRef()

  useEffect(() => {
    if (selection?.length) {
      modalRef.current.open()
    } else {
      modalRef.current.close()
    }
  }, [ selection ])

  const handleCloseClick = (success = false) => () => {
    if (!success) {
      return onCancel()
    }
    modalRef.current.close()
    onConfirm()
  }

  const classes = useStyles()
  return (
    <Modal defaultOpened={true} ref={modalRef} onClose={handleCloseClick(false)}>
      <Grid container alignItems="center" justify="space-between" className={classes.content}>
        <Grid item>
          <Typography variant="h5">Do you want to delete {selection.length} selected records?</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleCloseClick(false)}>Cancel</Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleCloseClick(true)}>Ok</Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

DeleteConfirmation.propTypes = {
  selection: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default DeleteConfirmation
