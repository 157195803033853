import React ,{ useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL, EWALLET_STATUS } from 'constants.js'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import AuthView from 'containers/AuthView/connect'
import Signup from './Signup'
import TLPay from './TLPay'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'

const getCompanyText = wallet => {
  let label = `${wallet.name}`
  // add hq / branch
  label += ` ${wallet.isHQ ? '(HQ)' : '(Branch)'}`
  // add tl id
  label += ` - ${wallet.tlId}`
  // add progress indication
  if (wallet.wallet) {
    label += ` - Active`
  } else if (wallet.signup) {
    label += ` - In Progress (${wallet.signup.providerStatus})`
  }
  return label
}

const isWalletActive = wallet => wallet?.wallet?.status === EWALLET_STATUS.ACTIVE

const TLMain = ({ location, auth, currUserCompany }) => {
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const { data: wallets, isLoading, refetch } = useQuery('getAllWallets', () => get(`${API_URL}/e-wallet/wallets`), {
    retry: false
  })
  useEffect(() => {
    setGlobalSpinner(isLoading)
  }, [isLoading, setGlobalSpinner])
  
  const [companyWallet, setCompanyWallet] = useState()
  const [companyOptions, setCompanyOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const { formatMessage } = useIntl()

  // delete old kyb entry if wallet is already active
  useEffect(() => {
    if (!wallets) {
      return
    }
    for (const w of wallets) {
      if (isWalletActive(w)) {
        localStorage.removeItem(`@tldg:ewallet_signup_${w.companyId}`)
      }
    }
  }, [wallets])

  // build options & manage selected
  useEffect(() => {
    if (!wallets) {
      return
    }
    // build options
    const options = []
    for (const w of wallets) {
      options.push({
        id: w.companyId,
        label: getCompanyText(w)
      })
    }
    setCompanyOptions(options)

    // auto select
    const preselected = wallets?.find(w => w.companyId === (companyWallet?.companyId || currUserCompany?.id))
    if (preselected) {
      setCompanyWallet(preselected)
    }
  }, [wallets, currUserCompany, companyWallet])

  // manage autocomplete value
  useEffect(() => {
    const optionToSelect = companyOptions.find(co => co.id === companyWallet?.companyId)
    optionToSelect && setSelectedOption(optionToSelect)
  }, [companyOptions, companyWallet])

  const hasActiveWallet = isWalletActive(companyWallet)
  const showSelect = !isLoading && companyOptions.length > 1
  const showPage = !isLoading && !!companyWallet
  return <AuthView location={location} auth={auth} title="TL Pay">
    <Grid style={{ marginBottom: '10px' }} container xs={12} alignItems='center'>
      <Typography variant='h1' gutterBottom>
        {formatMessage({ id: 'tlpay.tl-pay' })}
      </Typography>
      
      {showSelect && <Autocomplete
          options={companyOptions}
          variant="outlined"
          fullWidth
          dense
          name="select-company"
          value={selectedOption}
          getOptionLabel={(option) => option.label}
          onChange={(e, val) => {
            if(val && val.id) {
              setCompanyWallet(
                wallets?.find(w => w.companyId === val.id)
              )
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={formatMessage({ id: 'tlpay.my-tl-pay-accounts' })} variant="outlined" />
          )}
        ></Autocomplete>}
    </Grid>
    
    {showPage && !hasActiveWallet && <Signup companyWallet={companyWallet} refetchWallets={refetch} />}
    {showPage && hasActiveWallet && <TLPay companyWallet={companyWallet} />}
  </AuthView>
}

TLMain.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(TLMain) 