import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: `${theme.spacing(7)}px !important`,
    color: theme.palette.success.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  contentContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  }
}))
