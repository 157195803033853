import React from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'
import { Link, Typography, Box, LinearProgress } from '@material-ui/core'
import { download } from 'utils/api'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { useStyles } from './styles'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import { DATA_DELIVERY_STATUSES, DATA_DELIVERY_STATUSES_TO_PERCENTAGES } from 'constants.js'
import { useIntl } from 'react-intl'

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const DataDeliveryStatusCell = ({ status }) => {
  const classes = useStyles()
  if (status === DATA_DELIVERY_STATUSES.LOADED) {
    return <CheckCircleIcon className={classes.successIcon} />
  }
  if (status === DATA_DELIVERY_STATUSES.REJECTED) {
    return <ErrorIcon color="error" />
  }
  return <LinearProgressWithLabel value={DATA_DELIVERY_STATUSES_TO_PERCENTAGES[status]} />
}

const columns = formatMessage => [
  { name: 'uploadType', title: formatMessage({ id: 'data-delivery.upload-method' }) },
  { name: 'fileType', title: formatMessage({ id: 'data-delivery.file-type' }) },
  { name: 'createdAt', title: formatMessage({ id: 'data-delivery.date-time' }), type: 'datetime' },
  { name: 'filename', title: formatMessage({ id: 'data-delivery.file-name' }) },
  { name: 'sender', title: formatMessage({ id: 'data-delivery.account-from' }) },
  {
    name: 'importedInvoices',
    title: formatMessage({ id: 'data-delivery.number-of-records' }),
    getCellValue: (row) => row.importedInvoices ?? parseInt(row.importedInvoices, 10),
  },
  { name: 'totalAmount', title: formatMessage({ id: 'data-delivery.total-value' }), type: 'currency', align: 'right' },
  {
    name: 'dataDeliveryStatus',
    title: formatMessage({ id: 'data-delivery.status' }),
    getCellValue: (row) => <DataDeliveryStatusCell status={row.dataDeliveryStatus} />
  },
  { name: 'sameAmountDupes', title: formatMessage({ id: 'data-delivery.duplicates-with-same-amount' }) },
  { name: 'differentAmountDupes', title: formatMessage({ id: 'data-delivery.duplicates-with-different-amount' }) },
  {
    name: 'url',
    title: formatMessage({ id: 'data-delivery.download' }),
    getCellValue: (row) =>
      !!row.url && (
        <Link component="button" onClick={() => download(row.url)} >
          Download
        </Link>
      ),
    export: false,
  },
]

const DataDeliveryLogTable = ({ data, fetchDataDelivery, ...props }) => {
  const { formatMessage } = useIntl()

  return (
    <DataGrid
      columns={columns(formatMessage)}
      data={data ?? []}
      extra={<RefreshButton onClick={() => fetchDataDelivery()} />}
      {...props}
      sort={{
        keySorting: {
          order: 'DESC',
          orderBy: 'createdAt',
        },
      }}
    />
  )
}

DataDeliveryLogTable.propTypes = {
  data: PropTypes.array.isRequired,
}

export default DataDeliveryLogTable
