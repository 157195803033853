import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

const BASE = '/commercial-links'

export const getCommercialLinks = async (urlParams) => {
  const url = `${API_URL}${BASE}`
  const { data } = await httpFetch(url, {
    urlParams,
    shouldThrowError: true
  })
  return data
}

export const postImportCSV = async (files = []) => {
  const url = `${API_URL}${BASE}`
  const body = new FormData()
  files.forEach((file, index) => {
    body.append(`file_${index}`, file)
  })
  const result = await httpFetch(url, {
    body,
    method: 'POST',
    shouldThrowError: true
  })
  return result
}
