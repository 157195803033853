import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { dateToReadableDate, dateToReadableDateTime } from 'utils/date'

const DateCellContent = memo(({ hasTime, value }) => {
  if (!value) {
    return ''
  }

  return (
    <>
      { hasTime ? dateToReadableDateTime(value) : dateToReadableDate(value) }
    </>
  )
})

DateCellContent.propTypes = {
  hasTime: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

DateCellContent.displayName = 'DateCellContent'

export default DateCellContent
