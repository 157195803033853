import React, { memo } from 'react'
import PropTypes from 'prop-types'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core'
import { INVOICE_TAGS } from 'constants.js'

const ERROR_TAGS = [
  INVOICE_TAGS.ARCHIVED_COMMERCIAL_LINK,
  INVOICE_TAGS.POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT,
  INVOICE_TAGS.POSSIBLE_ENHANCED_DUPLICATE,
]

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const TagCell = memo(({ tags = [] }) => {
  const classes = useStyles()

  if (!tags.length) {
    return null
  }

  const errorTags = ERROR_TAGS.some((tag) => tags.includes(tag))
  const type = errorTags ? 'error' : 'warning'

  return <WarningIcon className={classes[type]} />
})

TagCell.displayName = 'TagCell'

TagCell.propTypes = {
  tags: PropTypes.array,
}

export default TagCell
