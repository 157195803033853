import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  dialogContent: {
    margin: '10px 20px 20px 20px',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    color: theme.palette.grey[500]
  }
}))
