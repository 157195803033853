import { ScheduledWithdrawalDayOfMonth, ScheduledWithdrawalFrequencyType } from '@travel-ledger/type-constants'
import Joi from 'joi'

export const MAX_REFERENCE_MSG_LEN = 512

export const formSchema = (availableBalance = 0, currency) => {
  return Joi.object({
    amount: Joi.number().precision(2).greater(0).max(availableBalance).required().messages({ "number.max": `You don’t have enough funds available. Amount must be ${availableBalance.toLocaleString('en-UK', { style: 'currency', currency })} or less.` }),
    account: Joi.string().required(),
    reference: Joi.string().max(MAX_REFERENCE_MSG_LEN).regex(/^[0-9a-zA-Z ]*$/).allow('').message({
      'string.pattern.base': 'Special characters are not allowed in this field.',
      'string.max': `Reference message must be less than or equal to ${MAX_REFERENCE_MSG_LEN} characters.`,
    }),
  })
}

export const FREQUENCY_TYPE_KEY_TITLE = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
}

export const DAYS_OF_MONTH_KEY_TITLE = {
  LAST: 'Last day',
}

export const defaultValues = {
  amount: '',
  account: '',
  reference: '',
}

export const scheduledWithdrawalFormSchema = () => {
  return Joi.object({
    id: Joi.string().uuid().optional(),
    account: Joi.string().required(),
    amount: Joi.number().precision(2).greater(0).when('withdrawAllAvailableFunds', {
      is: false,
      then: Joi.when('minAmountLeftEnabled', {
        is: false,
        then: Joi.required(),
        otherwise: Joi.allow('').optional(),
      }),
      otherwise: Joi.allow('').optional(),
    }),
    reference: Joi.string().max(MAX_REFERENCE_MSG_LEN).regex(/^[0-9a-zA-Z ]*$/).allow('').message({
      'string.pattern.base': 'Special characters are not allowed in this field.',
      'string.max': `Reference message must be less than or equal to ${MAX_REFERENCE_MSG_LEN} characters.`,
    }),
    frequencyType: Joi.string()
      .valid(...Object.keys(FREQUENCY_TYPE_KEY_TITLE))
      .default(FREQUENCY_TYPE_KEY_TITLE.DAILY)
      .required(),
    daysOfWeek: Joi.array().items(Joi.number().min(0).max(6)).when('frequencyType', {
      is: ScheduledWithdrawalFrequencyType.WEEKLY,
      then: Joi.array().min(1).required(),
    }),
    daysOfMonth: Joi.array().items(
      Joi.any().valid(ScheduledWithdrawalDayOfMonth.LAST, ...Array.from({ length: 31 }, (_, i) => i + 1))
    ).when('frequencyType', {
      is: ScheduledWithdrawalFrequencyType.MONTHLY,
      then: Joi.array().min(1).required(),
    }),
    timeOfDay: Joi.string().required(),
    minAvailableFunds: Joi.number().precision(2).greater(0).when('minAvailableFundsEnabled', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow('').optional(),
    }),
    minAmountLeft: Joi.number().precision(2).greater(0).when('minAmountLeftEnabled', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow('').optional(),
    }),
    minAvailableFundsEnabled: Joi.boolean().default(false).required(),
    minAmountLeftEnabled: Joi.boolean().default(false).required(),
    withdrawAllAvailableFunds: Joi.boolean().default(false).required(),
  })
}

export const scheduledWithdrawalDefaultValues = {
  id: undefined,
  amount: '',
  account: '',
  reference: '',
  frequencyType: ScheduledWithdrawalFrequencyType.DAILY,
  daysOfWeek: [],
  daysOfMonth: [],
  timeOfDay: '',
  minAvailableFunds: '',
  minAmountLeft: '',
  withdrawAllAvailableFunds: false,
  minAvailableFundsEnabled: false,
  minAmountLeftEnabled: false,
}
