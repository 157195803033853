import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  alertWrapper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 10000
  },
  alert: {
    padding: theme.spacing(0.15) + 'px !important',
    borderRadius: 0,
    left: 0,
    width: '100vw',
    justifyContent: 'center',
  },
  alertIcon: {
    paddingTop: theme.spacing(0.25),
  },
  toastify: {
    '& .Toastify__toast--error': {
      backgroundColor: theme.palette.error.main,
      maxHeight: '45vh',
    },
    '& .Toastify__toast-body': {
      overflow: 'hidden',
      maxHeight: '45vh',
    },
    '& .Toastify__close-button--error': {
      display: 'none'
    }
  },
}))
