import { connect } from 'react-redux'
import Component from './ResetPassword.js'
import { resetPasswordToken } from 'store/auth'
import { replace } from 'connected-react-router'

export const mapState = ({
  auth
}) => {
  return {
    ...auth
  }
}

export const mapDispatch = (dispatch) => ({
  postResetPassword: data => dispatch(resetPasswordToken(data)),
  replace: data => dispatch(replace(data))
})

export default connect(mapState, mapDispatch)(Component)
