import React from 'react'
import PropTypes from 'prop-types'
import CompanyIds from './CompanyIds'
import { Controller } from 'react-hook-form'

const ReactHookFormCompanyIds = ({ control, name, label, error, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line no-unused-vars
      render={({ field: { ref, ...field } }) => (
        <CompanyIds
          label={label}
          {...field}
          error={Array.isArray(error) ? error : error?.message}
        />
      )}
      { ...props }
    />
  )
}

ReactHookFormCompanyIds.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.array,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  checkboxProps: PropTypes.object
}

export default ReactHookFormCompanyIds
