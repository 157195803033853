
import { Checkbox, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Controller } from 'react-hook-form'

/**
 * @typedef WeekdaySelectProps
 * @property {import('react-hook-form').Control} control
 * @property {import('react-hook-form').FieldPath} name
 * @property {Array<{ value: any; label: string }>} options
 * @property {string} [label]
 * @property {string} [placeholder]
 * @property {boolean} [multiple]
 * @property {boolean} [error]
 * @property {boolean} [disabled]
 */

/** @param {WeekdaySelectProps} param0 */
export const ControlledWeekdaySelect = ({
  options,
  control,
  name,
  label,
  placeholder,
  multiple,
  error,
  disabled,
}) => {
  return <Controller
    render={({ field: { onChange, ...field } }) => <Autocomplete
      {...field}
      disabled={disabled}
      multiple={true}
      options={options}
      getOptionLabel={
        option => option?.label
      }
      getOptionSelected={
        (option, selectedOption) => option.value === selectedOption?.value
      }
      onChange={
        (_, data) => {
          const value = multiple ? data : (data && data.length && data.splice(data.length - 1, 1)) || undefined
          onChange(value)
        }
      }
      renderOption={
        (option, { selected }) => <>
          <Checkbox key={option.value} checked={selected} />
          {option.label}
        </>
      }
      renderInput={
        params => <TextField
          {...params}
          error={error}
          label={label}
          placeholder={placeholder}
        />
      }
      disableCloseOnSelect
    />}
    control={control}
    name={name}
    onChange={([, data]) => data}
  />
}

ControlledWeekdaySelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default memo(ControlledWeekdaySelect)
