import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  paymentDDLabel: {
    marginRight: theme.spacing(3),
    textAlign: 'end'
  },
  paymentRoleCell: {
    height: 50,
    minWidth: 240,
    marginLeft: 20
  },
  paymentText: {
    display: 'inline-block',
    height: 'auto',
    minWidth: 240,
    paddingRight: 20
  },
  paymentTextInput: {
    height: 60,
    minWidth: 240,
    marginLeft: 20
  },
  paymentRoleRow: {
    alignItems: 'center'
  },
  half: {
    minWidth: 240
  },
  formControl: {
    marginLeft: theme.spacing(3)
  },
  formInput: {
    height: 60,
    width: '100%',
    margin: theme.spacing(1),
    minWidth: 200
  },
  fabMargin: {
    margin: theme.spacing(1)
  },
  fabExtendedIcon: {
    marginRight: theme.spacing(1)
  },
  btLabel: {
    marginLeft: theme.spacing(1)
  },
  section: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    '& $btLabel': {
      marginLeft: 0
    }
  }
}))
