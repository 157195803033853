import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Divider,
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import logo from 'assets/logo_brand.png'
import { useStyles } from './styles'
import { dateToReadableDate } from 'utils/date'

const FormatAmount = ({ amount }) => {
  return `£ ${(amount / 100).toFixed(2)}`
}

const InvoiceReceipt = ({ invoice }) => {
  const classes = useStyles()

  return (
    <Grid>
      <Grid container>
        <Grid container direction="row" alignItems="center" xs={10}>
          <Grid item>
            <img src={logo} className={classes.logo} alt="logo" />
          </Grid>
          <Grid>
            <Box marginLeft={1}>
              <Typography>{invoice.sourceCompany.name}</Typography>
              <Typography>{invoice.sourceCompany?.billingAddress}</Typography>
              <Typography>{invoice.sourceCompany?.billingCity}</Typography>
              <Typography>{invoice.sourceCompany?.country?.countryName}</Typography>
              <Typography>Tax number: {invoice.sourceCompany?.taxIdentificationNumber}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Typography component="div">
            <Typography>Invoice: {invoice.bookingRef}</Typography>
            <Typography>Date: {dateToReadableDate(invoice.commitDate)}</Typography>
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.upperDivider} />
      <Grid container>
        <Grid item xs={6}>
          <Typography><strong>Bill to:</strong></Typography>
          <Typography>{invoice.targetCompany.name}</Typography>
          <Typography>{invoice.extra?.billing?.address}</Typography>
          <Typography>{invoice.extra?.billing?.city}</Typography>
          <Typography>{invoice.extra?.billing?.countryName}</Typography>
          <Typography>Tax number: {invoice.extra?.billing?.taxIdentificationNumber}</Typography>
        </Grid>
      </Grid>
      <Divider className={classes.bottomDivider} />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="Invoice items">
          <TableHead>
            <TableRow>
              <TableCell align="left" width="40%">
                Description
              </TableCell>
              <TableCell align="right" width="20%">
                Service period
              </TableCell>
              <TableCell align="right" width="10%">
                Quantity
              </TableCell>
              <TableCell align="right" width="10%">
                Unit price
              </TableCell>
              <TableCell align="right" width="10%">
                VAT
              </TableCell>
              <TableCell align="right" width="10%">
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.invoiceScheduleEntries.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell align="left">{row.extra?.description}</TableCell>
                  <TableCell align="right">{row.extra?.servicePeriod}</TableCell>
                  <TableCell align="right">{row.extra?.quantity}</TableCell>
                  <TableCell align="right">
                    <FormatAmount amount={row.extra?.unitPrice} />
                  </TableCell>
                  <TableCell align="right">20%</TableCell>
                  <TableCell align="right">
                    <FormatAmount amount={row.extra.lineAmount} />
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell colspan={6} className={classes.spacedRow} />
            </TableRow>
            <TableRow>
              <TableCell colspan={5} align="right">
                <Typography variant="h6">Subtotal</Typography>
              </TableCell>
              <TableCell align="right">
                <FormatAmount amount={invoice.subTotal} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colspan={5} align="right">
                <Typography variant="h6">VAT (20%)</Typography>
              </TableCell>
              <TableCell align="right">
                <FormatAmount amount={invoice.taxAmount} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colspan={5} align="right">
                <Typography variant="h6">Total</Typography>
              </TableCell>
              <TableCell align="right">
                <FormatAmount amount={invoice.total} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box marginBottom={5} />
    </Grid>
  )
}

InvoiceReceipt.propTypes = {
  invoice: PropTypes.object,
}

export default InvoiceReceipt
