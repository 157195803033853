import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  linkButton: {
    display: 'flex',
    gap: theme.spacing(1),
    width: 'fit-content',
    alignItems: 'center',
  },
}))
