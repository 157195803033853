import { FILTER_TYPES } from 'components/DataGrid/utils'

export const columnsUser = formatMessage => [
  {
    name: 'name',
    title: formatMessage({ id: 'manage-users.name' }),
    filter: true,
  },
  {
    name: 'email',
    title: formatMessage({ id: 'manage-users.email' }),
    filter: true,
  },
  {
    name: 'role',
    title: formatMessage({ id: 'manage-users.type' }),
    filter: {
      type: FILTER_TYPES.MULTIPLE_SELECT,
      inputProps: {
        options: { ADMIN: 'Admin', NORMAL: 'Normal' },
      },
    },
  },
  {
    name: 'isDisabled',
    title: formatMessage({ id: 'manage-users.disabled' }),
    getCellValue: (row) => (row?.isDeleted ? 'Yes' : 'No'),
    filter: {
      type: FILTER_TYPES.MULTIPLE_SELECT,
      inputProps: {
        options: { true: 'Yes', false: 'No' },
      },
    },
  },
]

export const columnsInvite = formatMessage => [
  {
    name: 'name',
    title: formatMessage({ id: 'manage-users.name' }),
  },
  {
    name: 'to',
    title: formatMessage({ id: 'manage-users.email' }),
  },
  {
    name: 'inviterName',
    title: formatMessage({ id: 'manage-users.inviter' }),
    getCellValue: (row) => `${row?.inviterName} (${row?.inviterEmail})`,
  },
  {
    name: 'inviteType',
    title: formatMessage({ id: 'manage-users.invite-type' }),
  },
  {
    name: 'inviteStatus',
    title: formatMessage({ id: 'manage-users.status' }),
  },
]
