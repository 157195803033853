import { useMemo } from 'react'
import { useIntl } from 'react-intl'

/**
 * Gives back a dictionary of translations for enum values, where the
 * enum values will be the keys, and the translated text the values.
 * 
 * The translation id is automatically inferred from the arguments,
 * and follows the pattern _**`enum.${enumTag}.${enumValue}`**_. Multiple
 * enums can be provided, getting combined in the final result; enums
 * that share the same values will be overwritten (undefined behaviour).
 * 
 * - `enumTag` - This is derived from the enum name, supporting only
 *  `UPPER_SNAKE_CASE` and `camelCase` enum names. The resulting tag is
 * `dashed-lower-case` (which is what should be used on the translation id).
 * - `enumValue` - Will straight up be each of the enum values, supporting
 * both strings and numbers.
 * 
 * Example usage:
 * ```typescript
 *  const items = useMemoEnumItems({ MyEnum, AnotherEnum })
 * ````
 * 
 * - `suffix` - Appends a suffix to the translation id, if provided,
 * such that the resulting translation id will be _**`enum.${enumTag}.${enumValue}.${suffix}`**_.
 *
 * @type {<
 *  Enum extends Record<string | number, string | number>
 * >(
 *  arg: Record<string, Enum>
 *  options?: { suffix?: string }
 * ) => Record<Enum[keyof Enum], string>}
 */
export const useMemoEnumItems = (enums, options = {}) => {
  const { formatMessage } = useIntl()
  const { suffix } = options

  return useMemo(() => {
    const result = {}

    for (const enumName in enums) {
      const enumValues = Object.values(enums[enumName])
      const enumTag = enumName
        .replace(/_/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase()

      for (const value of enumValues) {
        const id = `enum.${enumTag}.${value}` + (suffix ? `.${suffix}` : '')

        result[value] = formatMessage({ id })
      }
    }

    return result
  }, [enums, formatMessage, suffix])
}
