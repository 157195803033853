import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SimulationDetailValue from './SimulationDetailValue'
import SimulationDetailLabel from './SimulationDetailLabel'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

const SimulationDetailsContent = ({ collectionEvent }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container direction="row" justify="space-between">
        <Typography variant="h6">
          Invoices with following characteristics will be included:
        </Typography>
      </Grid>
      <Grid container direction="row" justify="flex-start" spacing={2} className={classes.stepperGrid}>
        <Stepper alternativeLabel activeStep={-1}>
          {
            (collectionEvent?.data?.dates ?? []).map((date, index) => (
              <Step key={`${index}:${date.date}`}>
                <StepLabel optional={<SimulationDetailLabel value={date.name} />} >
                  <SimulationDetailValue {...date} />
                </StepLabel>
              </Step>
            ))
          }
        </Stepper>
      </Grid>
      <Grid className={classes.note} container direction="row" justify="center" spacing={2}>
        <Typography variant="caption">
          NOTE: Dates are all calculated automatically based on inputted collection date
        </Typography>
      </Grid>
    </>
  )
}

SimulationDetailsContent.propTypes = {
  collectionEvent: PropTypes.object
}

export default SimulationDetailsContent
