import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  content: {
    marginBottom: theme.spacing(2),
  }
}))
