import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import AuthView from 'containers/AuthView';
import SnackbarComponent from 'components/Snackbar';
import PaymentProfileConfigForm from 'components/PaymentProfileConfigForm';
import { useForm } from "react-hook-form";
import { getValidationSchema } from './formValidationSchema';
import { joiResolver } from '@hookform/resolvers/joi';
import { PAYMENT_PLATFORMS } from 'constants.js';
import { useQuery } from 'react-query';
import { getPaymentProfileDefaultConfig } from 'services/paymentProfilesApi';

const defaultValues = {
  platform: PAYMENT_PLATFORMS.DIRECT_DEBIT_UK,
  profileName: '',
  bottomlinePtxEmail: '',
  bottomlinePtxPassword: '',
  paymentId: '',
  fundsClearingPeriod: 72,
}

const PaymentProfileConfiguration = ({
  location,
  currPaymentProfile,
  createPaymentProfile,
  patchPaymentProfile,
  fetchCurrPaymentProfile,
  snackbar
}) => {
  const profileId = get(location, 'state.id', null);

  const [schema, setSchema] = useState(getValidationSchema(profileId));
  const [platform, setPlatform] = useState(PAYMENT_PLATFORMS.DIRECT_DEBIT_UK);
  const { handleSubmit, formState: { errors }, control, getValues, setValue, reset } = useForm({
    resolver: joiResolver(schema),
    defaultValues,
  });

  const {
    isLoading,
    data: paymentProfileDefaultConfig,
  } = useQuery('paymentProfileDefaultConfig', getPaymentProfileDefaultConfig)

  useEffect(() => {
    if (!!profileId) {
      fetchCurrPaymentProfile({ id: profileId })
    }
  }, [profileId, fetchCurrPaymentProfile])

  useEffect(() => {
    setSchema(getValidationSchema(profileId, platform))
  }, [platform, profileId])

  useEffect(() => {
    if (!!currPaymentProfile && !!profileId) {
      reset({
        platform: currPaymentProfile.platform,
        profileName: currPaymentProfile.name,
        bottomlinePtxEmail: currPaymentProfile.platformConfiguration?.bottomlinePtxEmail,
        bottomlinePtxPassword: currPaymentProfile.platformConfiguration?.bottomlinePtxPassword,
        paymentId: currPaymentProfile.platformConfiguration?.sun,
        fundsClearingPeriod: currPaymentProfile.platformConfiguration?.fundsClearingPeriodInHours,
      });
      setPlatform(currPaymentProfile.platform);
    }

  }, [currPaymentProfile, profileId, reset])

  const submitPayment = () => {
    const state = getValues();
    const dataPayment = {
      name: state.profileName,
      platform: state.platform,
      platformConfiguration: {
        fundsClearingPeriodInHours: parseInt(state.fundsClearingPeriod, 10)
      }
    }

    if (state.platform === PAYMENT_PLATFORMS.DIRECT_DEBIT_UK) {
      dataPayment.platformConfiguration.bottomlinePtxEmail = state.bottomlinePtxEmail
      dataPayment.platformConfiguration.sun = state.paymentId

      if (state.bottomlinePtxPassword) {
        dataPayment.platformConfiguration.bottomlinePtxPassword = state.bottomlinePtxPassword
      }
    }

    if (!!profileId) {
      patchPaymentProfile({ id: profileId, dataPayment })
    } else {
      createPaymentProfile(dataPayment)
    }
  }

  return (
    <AuthView location={location} title="Payment Profile">
      {!isLoading && (
        <PaymentProfileConfigForm
          formValues={getValues()}
          setFormValue={setValue}
          platform={platform}
          changePlatform={setPlatform}
          control={control}
          submitPayment={handleSubmit(submitPayment)}
          errors={errors}
          paymentProfileDefaultConfig={paymentProfileDefaultConfig}
        />
      )}
      <SnackbarComponent
        status={snackbar.isOpened}
        error={snackbar.isError}
        text={snackbar.successOrErrorText}
      />
    </AuthView>
  )
}

PaymentProfileConfiguration.propTypes = {
  location: PropTypes.object,
  createPaymentProfile: PropTypes.func,
  patchPaymentProfile: PropTypes.func,
  fetchCurrPaymentProfile: PropTypes.func,
  currPaymentProfile: PropTypes.object,
  snackbar: PropTypes.object
}

export default PaymentProfileConfiguration
