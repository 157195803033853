import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const getNotificationsConfig = async (id) => {
  const url = `${API_URL}/users/${id}`
  return httpFetch(url)
}

export const patchNotificationsConfig = async (id, body) => {
  const url = `${API_URL}/users/${id}`
  const data = {
    method: 'PATCH',
    body: JSON.stringify(body)
  }
  return httpFetch(url, data)
}
