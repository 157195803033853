import React, { useState, useEffect, useRef, useCallback, useMemo, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import useLocalStorage from 'utils/useLocalStorage'
import {
  areHiddenColumnsFilteredBy,
  customizeCell,
  getExportCellValue,
  getTableName,
  getColumnsName,
  onSave,
  useManualPaginatingAndSorting,
  useColumnsWidth,
} from './utils'
import {
  DEFAULT_VIRTUAL_PAGE_SIZES,
  DEFAULT_PAGE_SIZES,
  INITIAL_PAGE_SIZE,
  INITIAL_VIRTUAL_PAGE_SIZE,
} from './constants'
import Grid from './components/Grid'
import { columnChooserContainer, columnChooserContainerEmpty } from './components/ColumnChooserContainer'
import { useIntl } from 'react-intl'

const DataGrid = forwardRef(
  (
    {
      title,
      name,
      height,
      columns,
      columnChooserGroups,
      data,
      pagination,
      editable,
      selectable,
      sort,
      defaultHiddenColumns = [],
      exportTitle,
      ...props
    },
    gridRef,
  ) => {
    const tableName = getTableName(title, name)
    const exporterRef = useRef(null)
    const [settingsStorage, setSettingsStorage] = useLocalStorage(`@tldg:${tableName}`, {})
    const [columnOrder, setColumnOrder] = useState(getColumnsName(columns))
    const [hiddenColumnNames, setHiddenColumnNames] = useState(defaultHiddenColumns)
    const [tableColumnExtensions, setTableColumnExtensions] = useState([])
    const [selection, setSelection] = useState([])
    const initialPageSize = pagination?.pageSize || (props.virtualTable ? INITIAL_VIRTUAL_PAGE_SIZE : INITIAL_PAGE_SIZE)
    const { formatMessage } = useIntl()

    const {
      currentPage: [currentPage, setCurrentPage],
      pageSize: [pageSize, setPageSize],
      sorting: [sorting, setSorting],
    } = useManualPaginatingAndSorting(data, pagination?.onPageChanged, {
      initialPageSize: settingsStorage?.pageSize ?? initialPageSize,
      keySorting: sort?.keySorting,
    })

    const [pageSizes] = useState(
      pagination?.pageSizes || (props.virtualTable ? DEFAULT_VIRTUAL_PAGE_SIZES : DEFAULT_PAGE_SIZES),
    )
    const [columnWidths, setColumnWidths] = useColumnsWidth(columns)
    const columnsToExport = useMemo(
      () =>
        columns
          .filter((c) => !c.disabled && c.export !== false)
          .map((column) => ({
            ...column,
            title: column.titleToExport || column.title || column.name,
            getCellValue: getExportCellValue(column),
          })),
      [columns],
    )

    const startExport = useCallback(
      (options) => {
        exporterRef.current.exportGrid(options)
      },
      [exporterRef],
    )

    const setSetting = useMemo(
      () => ({
        columnOrder: (value) => setColumnOrder(value),
        columnWidths: (value) => setColumnWidths(value),
        hiddenColumnNames: (value) => setHiddenColumnNames(value),
        pageSize: (value) => setPageSize(value),
        sorting: (value) => setSorting(value),
      }),
      [setColumnOrder, setColumnWidths, setHiddenColumnNames, setPageSize, setSorting],
    )

    useEffect(() => {
      if (!tableName) {
        return
      }

      // Clear grid setting if new columns was added (or name changed) with a new app version
      const columnNames = columns.map((column) => column.name)
      if (settingsStorage.columnOrder) {
        // If there's a missmatch betwee the localstorage columns and columns reset local storage preferences
        if (
          settingsStorage.columnOrder.length !== columns.length ||
          !columnNames.every((columnName) => settingsStorage.columnOrder.includes(columnName))
        ) {
          setSettingsStorage({})
          return
        }
      }

      const settingsItems = Object.keys(settingsStorage)
      settingsItems.forEach((item) => {
        const value = settingsStorage[item]
        setSetting[item](value)
      })
      // Only load table settings on initial load
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const lineBreakColumns = columns
        ?.filter((col) => !!col.wordWrap || !!col.align)
        ?.map((col) => ({ columnName: col.name, wordWrapEnabled: true, align: col.align }))
      setTableColumnExtensions(lineBreakColumns)
    }, [columns])

    useEffect(() => {
      setSelection(selectable?.checked)
    }, [selectable?.checked])

    useImperativeHandle(gridRef, () => ({
      unSelectAll: () => {
        setSelection([])
      },
      pageSize,
    }))

    const handleUpdatePreference = (item) => (value) => {
      if (!tableName) {
        return
      }
      setSetting[item](value)
      // on pageSize change always force 1st page on grid
      if (item === 'pageSize' && props.filtering?.clearFilters) {
        setCurrentPage(0)
      }
      if (
        item === 'hiddenColumnNames' &&
        props.filtering?.filters &&
        areHiddenColumnsFilteredBy(value, props.filtering.filters)
      ) {
        const hiddenColumnFilters = {}
        value.forEach((val) => {
          hiddenColumnFilters[val] = undefined
        })
        props.filtering.onChange({
          ...props.filtering.filters,
          ...hiddenColumnFilters,
        })
      }
      setSettingsStorage({ sorting, columnOrder, hiddenColumnNames, pageSize, columnWidths, [item]: value })
    }

    const CollumnChooserContainerComponent = columnChooserGroups
      ? columnChooserContainer({
          handleToggle: handleUpdatePreference('hiddenColumnNames'),
          groups: columnChooserGroups,
          hiddenColumns: hiddenColumnNames,
        })
      : columnChooserContainerEmpty()

    const handleSorting = (_columns) => {
      handleUpdatePreference('sorting')(_columns)
    }

    const handleSelection = useCallback(
      (indexes) => {
        if (selectable?.onChange) {
          selectable.onChange(indexes)
        }
        setSelection(indexes)
      },
      [selectable],
    )

    return (
      <>
        <Grid
          columns={columns}
          columnChooserContainerComponent={CollumnChooserContainerComponent}
          data={data}
          editable={editable}
          height={height}
          name={name}
          onRowChangesChange={editable?.onRowsUpdate}
          pagination={pagination}
          selectable={selectable ? { ...selectable, checked: selection } : undefined}
          sort={sort}
          title={title}
          tableMessages={{
            noData: formatMessage({ id: 'table.no-data' }),
          }}
          {...props}
          // sorting props
          sorting={sorting}
          onSortingChange={handleSorting}
          // selection props
          onSelectionChange={handleSelection}
          // pagination props
          currentPage={currentPage}
          onChangePage={setCurrentPage}
          onCurrentPageChange={setCurrentPage}
          onPageSizeChange={handleUpdatePreference('pageSize')}
          pageSize={pageSize}
          pageSizes={pageSizes}
          // column based props
          columnOrder={columnOrder}
          columnWidths={columnWidths}
          hiddenColumnNames={hiddenColumnNames}
          onColumnWidthsChange={handleUpdatePreference('columnWidths')}
          onHiddenColumnNamesChange={handleUpdatePreference('hiddenColumnNames')}
          onOrderChange={handleUpdatePreference('columnOrder')}
          // export props
          onStartExport={startExport}
          // extension props
          tableColumnExtensions={tableColumnExtensions}
        />
        <GridExporter
          ref={exporterRef}
          rows={data}
          columns={columnsToExport}
          columnOrder={columnOrder}
          hiddenColumnNames={hiddenColumnNames}
          customizeCell={customizeCell}
          onSave={onSave(exportTitle || tableName)}
          selection={
            // map rowIndex to rows in order for export feature to work as expected
            data
              ?.map((d, index) => ({ ...d, rowIndex: index }))
              .filter((d) => selection?.includes(d.id))
              .map((d) => d.rowIndex)
          }
        />
      </>
    )
  },
)

DataGrid.displayName = 'DataGrid'

DataGrid.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  columns: PropTypes.array.isRequired,
  columnChooserGroups: PropTypes.array,
  data: PropTypes.array.isRequired,
  actions: PropTypes.array,
  RowDetail: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  toolbarActions: PropTypes.array,
  disableExport: PropTypes.bool,
  disableResizing: PropTypes.bool,
  disableReordering: PropTypes.bool,
  disableColumnVisibility: PropTypes.bool,
  sort: PropTypes.shape({
    disabled: PropTypes.bool,
    keySorting: PropTypes.shape({
      orderBy: PropTypes.string,
      order: PropTypes.oneOf(['ASC', 'DESC']),
    }),
  }),
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    onPageChanged: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  editable: PropTypes.shape({
    disabled: PropTypes.func,
    onRowsUpdate: PropTypes.func,
  }),
  selectable: PropTypes.shape({
    onChange: PropTypes.func,
    actions: PropTypes.array,
    isChecked: PropTypes.func,
    checked: PropTypes.array,
  }),
  filtering: PropTypes.object,
  defaultHiddenColumns: PropTypes.array,
  virtualTable: PropTypes.bool,
  exportTitle: PropTypes.string,
}

export default DataGrid
