import { Typography } from '@material-ui/core'
import React from 'react'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL, NIUM_ONBOARD_STATUS } from 'constants.js'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import KYCLink from './KYCLink'
import { useIntl } from 'react-intl'

const KYC = ({ data, status, companyId }) => {
  const { formatMessage } = useIntl()

  const { data: kycURL, refetch: regenerateLink } = useQuery('regenerateKYC', () => get(`${API_URL}/e-wallet/regenerate-kyc/${companyId}`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
    onSuccess: () => {
      toast.success('KYC link regenerated successfully.')
    },
    onError: (error) => {
      toast.error(<p>{getReadableErrorMessage(error)} <br/><br /> {formatMessage({ id: 'tlpay.the-kyc-link-failed-to-regenerate' })}<br/> {formatMessage({ id: 'tlpay.please-try-again-or-contact-support' })}</p>)
    },
  })
  const url = kycURL?.redirectUrl || data?.redirectUrl

  if (!data) return 'loading...'
  return (
    <>
      <Typography variant='h3' gutterBottom>
        {formatMessage({ id: 'tlpay.step-2-applicant-id-validation' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.this-is-the-final-step-which-conducts-an-applicant-identity-check-this-must-only-be-completed-by-the-applicant-named-in-the-previous-step' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.if-you-are-not-the-named-applicant-please-click-on-the-copy-link-button-below-and-forward-on' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.once-you-start-this-step-it-will-transfer-you-to-your-mobile-phone-to-take-a-picture-of-your-identity-document-and-a-selfie' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.for-more-information-please-click' })} <a href='https://help.travelledger.org/tl-pay-account/account-registration-process-and-documents-needed/step-by-step-guide-kyb-kyc-form-completion#final-step-know-your-customer-kyc' target='blank'>{formatMessage({ id: 'tlpay.here' })}</a>.
      </Typography>
      {status === NIUM_ONBOARD_STATUS.IN_PROGRESS &&
        <KYCLink url={url} regenerateLink={regenerateLink} />
      }
    </>
  )
}

export default KYC
