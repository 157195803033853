import React from 'react'
import PropTypes from 'prop-types'
import AuthView from 'containers/AuthView'
import UserForm from './components/UserForm'
import PasswordUpdateForm from './components/PasswordUpdateForm'
import { useGetUserProfile, usePatchUserProfile, usePatchPassword } from './hooks'

const UserProfile = ({
  id: userId,
  location
}) => {
  const { data: userProfileData } = useGetUserProfile(userId)
  const userProfile = userProfileData?.data?.attributes
  const { mutate: patchUserProfile } = usePatchUserProfile(userId)
  const { mutate: patchUserPassword } = usePatchPassword(userId, userProfile)

  return (
    <AuthView location={location} title="User profile">
      <UserForm onSave={patchUserProfile} userProfile={userProfile} />
      <PasswordUpdateForm onSave={patchUserPassword} userProfile={userProfile} />
    </AuthView>
  )
}

UserProfile.propTypes = {
  id: PropTypes.string,
  location: PropTypes.object
}

export default UserProfile
