import { createSelector } from 'reselect'

const selectToken = state => state.auth.token

const getToken = createSelector(
  [ selectToken ],
  token => token
)

export const isAuthenticated = createSelector(
  [ selectToken ],
  token => token !== null && token !== ''
)

const authSelector = {
  isAuthenticated,
  getToken
}
export default authSelector
