/* eslint-disable no-param-reassign */
import { useStyles } from '../styles'
import React, {
  useState,
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { Typography, OutlinedInput } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { manageFileCompression } from 'utils/compress-file'

const FileComponent = ({ nameId, formItem: { info, infoAdditional, fileCount = 1, labels = ['File'] } }) => {
  const classes = useStyles()
  const {
    control,
    setValue,
    watch,
  } = useFormContext()
  
  // remove trailing 0
  const fileNameOrigin = nameId.substring(0, nameId.lastIndexOf('.') - 1)
  
  // use watch to update file names
  watch((values) => {
    let thisValue = values
    fileNameOrigin.split('.').forEach(level => {
      if(level !== '' && typeof thisValue === 'object') {
        thisValue = thisValue[level]
      }
    })
    setSelectedFiles(thisValue?.filter(v => !!v).map(v => v.fileName).filter(v => !!v).join(', '))
  })
  const [selectedFiles, setSelectedFiles] = useState('')
  const infoText = `${selectedFiles ? `<br /> <b>Saved: ${selectedFiles}</b>` : ''}`
  const resetFile = (target, index) => {
    setValue(`${fileNameOrigin}${index}.document`, undefined)
    setValue(`${fileNameOrigin}${index}.fileName`, undefined)
    setValue(`${fileNameOrigin}${index}.fileType`, undefined)
    target.value = ''
  }

  const onDrop = async (event, index) => {
    const { target } = event
    const file = target.files[0]
    if(!file) {
      return
    }
    const fileToProcess = await manageFileCompression(file);

    const sizeLimitBytes = 1.5 * 1000 * 1000 // 1.5 MB
    if(fileToProcess.size > sizeLimitBytes) {
      alert(`Oops! The file you're trying to upload (${file.name}) exceeds our size limit of 1.5MB`)
      resetFile(target, index)
      return
    }

    setValue(`${fileNameOrigin}${index}.document`, fileToProcess.data)
    setValue(`${fileNameOrigin}${index}.fileName`, file.name)
    setValue(`${fileNameOrigin}${index}.fileType`, file.type)
  }

  return [...Array(fileCount).keys()].map(index => <Controller key={index}
    render={({ fieldState }) => {
      return (
        <>
        <OutlinedInput
          type="file"
          label={labels[index]}
          labelWidth="100"
          onChange={(e) => { onDrop(e, index) }}
          inputProps={{ accept: ".jpg,.png,.jpeg,.pdf", id: `${fileNameOrigin}${index}` }}
          error={!!fieldState.error}
          fullWidth
          margin='dense'
          className={classes.fileField}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  resetFile(document.getElementById(`${fileNameOrigin}${index}`), index)
                }}
                onMouseDown={(e) => {
                  e.preventDefault()
                }}
                edge="end"
              >
                <ClearIcon fontSize="small" color="disabled" />
              </IconButton>
            </InputAdornment>
          }
          />
          {index === 0 && info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: info }} />}
          {index !== 0 && index === fileCount - 1 && infoAdditional && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: infoAdditional }} />}
          {index === fileCount - 1 && infoText && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: infoText }} />}
          {fieldState.error && <FormHelperText error>Is Required</FormHelperText>}
        </>  
      )
    }
    }
    control={control}
    name={`${fileNameOrigin}${index}`}
    error={false}
    defaultValue={null}
  />)
}

export default FileComponent