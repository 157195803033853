import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'

export const BULK_ACTIONS = {
  COMMIT: 'Commit',
  UPDATE_PARTNER: 'Update Partner',
  DELETE: 'Delete'
}

const ImportedDataTable = forwardRef(({
  data,
  columns,
  onPageChanged,
  onRowsUpdate,
  selectable,
  totalNumberOfItems,
  ...restProps
}, ref) => {
  return (
    <DataGrid
      virtualTable
      name="review_and_edit_imported_data"
      fullRemainingWindowHeight
      selectable={selectable}
      ref={ref}
      columns={columns}
      data={data}
      pagination={{
        onPageChanged,
        totalNumberOfItems
      }}
      sort={{
        keySorting: {
          orderBy: 'createdAt',
          order: 'DESC'
        }
      }}
      editable={{
        onRowsUpdate
      }}
      { ...restProps }
    />
  )
})

ImportedDataTable.displayName = 'ImportedDataTable'

ImportedDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onPageChanged: PropTypes.func,
  onRowsUpdate: PropTypes.func,
  selectable: PropTypes.object,
  totalNumberOfItems: PropTypes.number
}

export default memo(ImportedDataTable)
