import moment from 'moment'
import { BSD_CALENDAR_EVENTS } from 'constants.js'
import 'moment/min/locales'
import { scheduleFieldsToCronExpression } from 'utils/cronTransform'

export const locale = window.navigator.userLanguage || window.navigator.language
const localeData = moment.localeData(locale)

export const firstDayOfWeek = localeData.firstDayOfWeek()

const today = moment().startOf('day')
export const getCalendarCellEventType = ({
  dayEvents, dayDate, otherMonth,
}) => {
  // check if weekend or holiday
  for (const { type } of dayEvents) {
    if (
      type === BSD_CALENDAR_EVENTS.HOLIDAY ||
      type === BSD_CALENDAR_EVENTS.WEEKEND
    ) {
      return type
    }
  }

  if (otherMonth || dayDate.isBefore(today)) {
    // disabled the same way as weekends
    return BSD_CALENDAR_EVENTS.WEEKEND
  }

  return BSD_CALENDAR_EVENTS.BSD
}

export const setMomentTime = (date, time) => {
  const [hour, minute] = time?.split(':') ?? []
  return moment(date).set({ hour, minute })
}

export const shouldDisplayOption = (eventType) =>
  eventType !== BSD_CALENDAR_EVENTS.HOLIDAY && eventType !== BSD_CALENDAR_EVENTS.WEEKEND
export const shouldDisplayUpdateTimeOption = (eventType) =>
  eventType === BSD_CALENDAR_EVENTS.FORCE_COLLECTION || BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION
export const shouldDisplaySkipOption = (eventType) =>
  eventType === BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION ||
  eventType === BSD_CALENDAR_EVENTS.FORCE_COLLECTION ||
  eventType === BSD_CALENDAR_EVENTS.BSD
export const shouldDisplayUnSkipOption = (eventType) => eventType === BSD_CALENDAR_EVENTS.SKIP_COLLECTION
export const shouldDisplayForceOption = (eventType) => {
  const { SCHEDULED_COLLECTION, FORCE_COLLECTION: FORCE, SKIP_COLLECTION: SKIP, HOLIDAY, WEEKEND } = BSD_CALENDAR_EVENTS
  return ![SCHEDULED_COLLECTION, FORCE, SKIP, HOLIDAY, WEEKEND].includes(eventType)
}

export const filterRelevantEventTypeDays = (day) =>
  day.type !== BSD_CALENDAR_EVENTS.BSD && day.type !== BSD_CALENDAR_EVENTS.WEEKEND

export const sortWeekByLocale = (a, b) => {
  if (b < firstDayOfWeek) {
    if (a < firstDayOfWeek) {
      return 1
    }
    return -1
  }
  return a - b
}

/**
 * @param {number} weekDay
 * @param {string} eventTime format: HH:mm
 */
export const getCronString = (weekDay, eventTime) => {
  const [hour, minute] = eventTime.split(':')

  return scheduleFieldsToCronExpression({
    week: weekDay,
    hour,
    minute,
  })
}

/**
 * @param {number[]} weekDay
 * @param {string} eventTime format: HH:mm
 */
export const getCronStringMultiple = (weekDay, eventTime) => {
  const [hour, minute] = eventTime.split(':')

  return scheduleFieldsToCronExpression({
    week: weekDay.join(','),
    hour,
    minute,
  })
}
