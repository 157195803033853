import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'

const isSpecific = val => val !== 'first' && val !== 'L'

const MonthlyForm = ({ onChange, value }) => {

  const handleChange = ({ target }) => {
    if (target.value === 'specific') {
      onChange(15)
      return
    }
    onChange(target.value)
  }

  const handleInputChange = ({ target }) => {
    onChange(target.value)
  }

  return (
    <Box mt={2}>
      <Typography variant="subtitle2">
        Recurs every month on
      </Typography>
      <RadioGroup name="gender1" value={value} onChange={handleChange}>
        <FormControlLabel value="first" control={<Radio />} label="First day" />
        <FormControlLabel value="L" control={<Radio />} label="Last day" />
        <FormControlLabel checked={isSpecific(value)} value="specific" control={<Radio />} label="Specific day" />
        {
          isSpecific(value) &&
          <TextField
            style={{ width: 50 }}
            type="number"
            onChange={handleInputChange}
            value={value}
            inputProps={{
              min: 2,
              max: 30
            }}
          />
        }
      </RadioGroup>
    </Box>
  )
}

MonthlyForm.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.oneOf([ 'first', 'L' ]),
    PropTypes.number,
  ])
}

export default MonthlyForm
