import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'
import Spinner from 'containers/App/components/Spinner'
import Modal from 'components/Modal'
import CompanyForm from '../CompanyForm'
import { useMutation } from 'react-query'
import { createCompany, updateCompany } from 'services/companiesApi'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import ManageUsersTable from '../ManageUsersTable/ManageUsersTable'
import { filterCompanies } from './utils'
import { FILTER_TYPES } from 'components/DataGrid/utils'
import { dateToReadableDateTime, utcToTimezone } from 'utils/date'
import { FEATURE_TL_SUB_ID_ENABLED } from 'constants.js'
import { useIntl } from 'react-intl'

const BranchCompaniesTable = ({
  currUserCompany,
  companies,
  isLoading,
  title,
  canEdit,
  refetch,
  filter,
  showCreateBranch,
  showBranchSettings,
  defaultExpandedRowIds = [],
}) => {
  if (isLoading) {
    return <Spinner />
  }

  const [filters, setFilters] = useState({})

  const columns = formatMessage => [
    {
      name: 'type',
      title: formatMessage({ id: 'companies-and-users.type' }),
      getCellValue: () => (showBranchSettings ? 'Branch' : 'HQ'),
      width: 75,
    },
    {
      name: 'name',
      title: formatMessage({ id: 'companies-and-users.name' }),
      getCellValue: (row) => row?.name,
      filter,
    },
    {
      name: 'ABTA',
      title: formatMessage({ id: 'companies-and-users.abta' }),
      getCellValue: (row) => row?.codes?.ABTA,
      width: 75,
      filter,
    },
    {
      name: 'ABTACRM',
      title: formatMessage({ id: 'companies-and-users.abta-crm' }),
      getCellValue: (row) => row?.codes?.ABTACRM,
      width: 75,
      filter,
    },
    {
      name: 'IATA',
      title: formatMessage({ id: 'companies-and-users.iata' }),
      getCellValue: (row) => row?.codes?.IATA,
      width: 75,
      filter: filter,
    },
    {
      name: 'TIDS',
      title: formatMessage({ id: 'companies-and-users.tids' }),
      getCellValue: (row) => row?.codes?.TIDS,
      width: 75,
      filter,
    },
    {
      name: 'EMAIL_ID',
      title: formatMessage({ id: 'companies-and-users.email-id' }),
      getCellValue: (row) => row?.codes?.EMAIL_ID,
      filter,
    },
    FEATURE_TL_SUB_ID_ENABLED ? 
      {
        name: 'TL_S',
        title: formatMessage({ id: 'companies-and-users.travel-ledger-subscriber-id' }),
        getCellValue: (row) => row?.codes?.TL_S,
        filter,
      } : null,
    {
      name: 'inheritCommercialLink',
      title: formatMessage({ id: 'companies-and-users.inherit-commercial-links' }),
      getCellValue: (row) => (row.inheritCommercialLink ? 'Yes' : 'No'),
      ...(filter && {
        filter: {
          type: FILTER_TYPES.MULTIPLE_SELECT,
          inputProps: {
            options: { true: 'Yes', false: 'No' },
          },
        },
      }),
      disabled: !showBranchSettings,
    },
    {
      name: 'centrallyManaged',
      title: formatMessage({ id: 'companies-and-users.centrally-managed' }),
      getCellValue: (row) => (row.centrallyManaged ? 'Yes' : 'No'),
      ...(filter && {
        filter: {
          type: FILTER_TYPES.MULTIPLE_SELECT,
          inputProps: {
            options: { true: 'Yes', false: 'No' },
          },
        },
      }),
      disabled: !showBranchSettings,
    },
    {
      name: 'inheritWallet',
      title: formatMessage({ id: 'companies-and-users.inherit-wallet' }),
      getCellValue: (row) => (row.inheritWallet ? 'Yes' : 'No'),
      ...(filter && {
        filter: {
          type: FILTER_TYPES.MULTIPLE_SELECT,
          inputProps: {
            options: { true: 'Yes', false: 'No' },
          },
        },
      }),
      disabled: !showBranchSettings,
    },
    {
      name: 'usersCount',
      title: formatMessage({ id: 'companies-and-users.users' }),
      getCellValue: (row) => (row.usersCount > 0 ? row.usersCount : 'No'),
      ...(filter && {
        filter: {
          type: FILTER_TYPES.MULTIPLE_SELECT,
          inputProps: {
            options: { true: 'Yes', false: 'No' },
          },
        },
      }),
    },
    {
      name: 'status',
      title: formatMessage({ id: 'companies-and-users.status' }),
      getCellValue: (row) => row?.status,
      filter,
    },
    {
      name: 'address',
      title: formatMessage({ id: 'companies-and-users.address' }),
      getCellValue: (row) => row?.address,
      filter,
    },
    {
      name: 'telephone',
      title: formatMessage({ id: 'companies-and-users.telephone' }),
      getCellValue: (row) => row?.telephone,
      filter,
    },
    {
      name: 'city',
      title: formatMessage({ id: 'companies-and-users.city' }),
      getCellValue: (row) => row?.city,
      filter,
    },
    {
      name: 'timezone',
      title: formatMessage({ id: 'companies-and-users.timezone' }),
      getCellValue: (row) => row?.timezone,
      filter,
    },
    {
      name: 'website',
      title: formatMessage({ id: 'companies-and-users.website' }),
      getCellValue: (row) => row?.website,
      filter,
    },
    {
      title: formatMessage({ id: 'companies-and-users.updated-by' }),
      name: 'updatedBy',
      getCellValue: row => row?.updatedBy?.name,
      filter: true
    },
    {
      title: formatMessage({ id: 'companies-and-users.updated-at' }),
      name: 'updatedAt',
      getCellValue: row => utcToTimezone(row?.updatedAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.updatedAt, currUserCompany.timezone)),
      filter: { type: FILTER_TYPES.DATE },
      type: 'datetime'
    }
  ].filter(identity => identity) // filter out possible nulls

  const [editCompany, setEditCompany] = useState(null)
  const [createMode, setCreateMode] = useState(false)
  const { isLoading: isCreating, mutate: createCompanyMutation } = useMutation(createCompany, {
    onSuccess: () => {
      toast.success('Branch company created successfully.')
      refetch()
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  const { isLoading: isPatching, mutate: updateCompanyMutation } = useMutation(updateCompany, {
    onSuccess: () => {
      toast.success('Company profile updated successfully.')
      refetch()
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })
  const canListUsers = canEdit
  const filteredData = filterCompanies(companies || [], filters)
  const { formatMessage } = useIntl()

  return (
    <>
      <DataGrid
        title={title}
        name="branch_companies"
        columns={columns(formatMessage)}
        data={filteredData}
        defaultHiddenColumns={['ABTACRM', 'TIDS', 'IATA', 'EMAIL']}
        actions={
          canEdit
            ? [
                {
                  icon: 'edit',
                  tooltip: formatMessage({ id: 'companies-and-users.edit' }),
                  onClick: (_, company) => {
                    setEditCompany(company)
                  },
                },
              ]
            : []
        }
        toolbarActions={
          canEdit && showCreateBranch
            ? [
                {
                  icon: 'add',
                  tooltip: formatMessage({ id: 'companies-and-users.create-branch' }),
                  onClick: () => {
                    setCreateMode(true)
                    setEditCompany(null)
                  },
                },
              ]
            : []
        }
        defaultExpandedRowIds={defaultExpandedRowIds}
        pagination={{ pageSizes: [5, 10, 25], pageSize: 5 }}
        filtering={{
          defaultFilters: {},
          filters,
          onChange: (newValue) => {
            setFilters(newValue)
          },
        }}
        {...(canListUsers && { RowDetail: (prop) => <ManageUsersTable company={prop.row} canEdit={canEdit} /> })}
        expandableComponentTootltip={formatMessage({ id: 'companies-and-users.manage-users' })}
        isRowExpandable={() => canListUsers}
      />
      {(createMode || editCompany) && (
        <Modal
          title={
            createMode
              ? formatMessage({ id: 'companies-and-users.create-branch' })
              : formatMessage({ id: 'companies-and-users.edit-company-profile' })
          }
          defaultOpened={true}
          onClose={() => {
            setCreateMode(false)
            setEditCompany(null)
          }}
          maxWidth="lg"
        >
          <CompanyForm
            mutate={createMode ? createCompanyMutation : updateCompanyMutation}
            refetch={refetch}
            currUserCompany={currUserCompany}
            company={editCompany}
            isLoading={isCreating || isPatching}
            createMode={createMode}
            showBranchSettings={showBranchSettings}
          />
        </Modal>
      )}
    </>
  )
}

BranchCompaniesTable.propTypes = {
  currUserCompany: PropTypes.object,
  companies: PropTypes.array,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  canEdit: PropTypes.bool,
  filter: PropTypes.bool,
  true: PropTypes.bool,
  refetch: PropTypes.func,
  showCreateBranch: PropTypes.bool,
  showBranchSettings: PropTypes.bool,
  defaultExpandedRowIds: PropTypes.array,
}

export default BranchCompaniesTable
