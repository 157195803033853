import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { useStyles } from './styles'

const HeaderRowCell = (props) => {
  const classes = useStyles()
  const { filters = {}, children, column } = props
  const isFiltered = Object.entries(filters).some(([key, value]) =>
    key === column?.name && !!(value && Object.entries(value).length > 0 && value?.value !== '')
  )

  return (
    <TableHeaderRow.Cell
      {...props}
      {...isFiltered && { className: classes.root }}
    >
      {children}
    </TableHeaderRow.Cell>
  )
}

HeaderRowCell.propTypes = {
  props: PropTypes.object
}

export const makeHeaderRowCell = (filters) => (props) => <HeaderRowCell filters={filters} {...props} />

export default HeaderRowCell
