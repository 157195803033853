/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AuthView from 'containers/AuthView'
import ImportedDataTable, { BULK_ACTIONS } from 'containers/CSVMenu/components/ImportedDataTable'
import Spinner from 'containers/App/components/Spinner'
import GlobalSpinnerContextProvider from 'containers/App/components/GlobalSpinnerContextProvider'
import { BreadcrumbMenuTransactions } from 'containers/BreadcrumbMenu'
import SnackbarComponent from 'components/Snackbar'
import {
  CommercialLinkDisplayNameCell,
  DepartureDateCell,
  DescriptionCell,
  OriginalAmountCell,
  StatusCell,
  TagCell,
  BookingRefCell
} from 'components/TransactionsTable/components'
import { FILTER_TYPES, DEFAULT_COLUMN_WIDTH_VALUES } from 'components/DataGrid/utils'
import { COMPANY_STATUS, CURRENCY_CODE, CURRENCY_CODE_TO_NAME, SPS_IMPORT_FILE_STATUSES } from 'constants.js'
import { dateToReadableDate, dateToReadableDateTime, utcToTimezone } from 'utils/date'
import { getTagsOptionsFileUpload, filterTags } from 'utils/tags'
import { customTags, customOriginalAmount } from './customCellsToExport'
import DeleteConfirmationModal, { useDeleteModal } from './components/DeleteConfirmation'
import PartnerAutocompleteModal, { usePartnerModal } from './components/PartnerAutocomplete'
import UploadModal from './components/Upload'
import { DueDateEditField } from './components/DueDateEditField'
import { DEFAULT_HIDDEN_COLUMNS, initialFilterValues } from './constants.js'
import { parseInvoices, validateCommit, validateUpdatePartner, validateDelete } from './utils'
import { filterLocalInvoices, filterValuesToQueryParams, getDueDate } from './utils.js'
import { useGridTitleWithHelpPopup } from 'components/HelpPopup/HelpPopup'
import { useQuery } from 'react-query'
import { getCompanies } from 'services/companiesApi'
import { useIntl } from 'react-intl'
import kebabCase from 'lodash/kebabCase'

const CSVMenu = ({
  location,
  uploadCSV,
  setUploadCSVError,
  currUserCompanyId,
  fetchUncommittedInvoices,
  invoices,
  commitInvoices,
  updateInvoice,
  updateInvoices,
  deleteInvoices,
  snackbar,
  isLoading,
  currUserCompany,
  csv,
  push,
}) => {
  const [filters, setFilters] = useState(initialFilterValues)
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)
  const { formatMessage } = useIntl()

  const { data: processingEntities } = useQuery('processing-entities-filter', () => getCompanies({ 'isProcessingEntity[eq]': true }), {
    enabled: !!currUserCompany && !!currUserCompany.id && !currUserCompany.isProcessingEntity,
    retry: false
  })

  const handleDeleteInvoices = (selection) => {
    const checkedInvoices = invoices.filter((invoice) => selection.includes(invoice.id))
    deleteInvoices(checkedInvoices)
  }

  const {
    selection: deleteSelection,
    openModal: openModalDelete,
    onCancel: onCancelDelete,
    onConfirm: onConfirmDelete,
  } = useDeleteModal(handleDeleteInvoices)

  const handleUpdateInvoices = (rowIds, partnerAndCommercialLink) => {
    const patchInvoices = []
    rowIds.forEach((rowId) => {
      const rowData = invoices.find((invoice) => invoice.id === rowId)
      patchInvoices.push({
        id: rowData.id,
        targetId: partnerAndCommercialLink.targetId,
        commercialLinkId: partnerAndCommercialLink.commercialLinkId,
      })
    })
    updateInvoices(patchInvoices)
  }

  const {
    selection: partnerSelection,
    openModal: openModalPartner,
    onCancel: onCancelPartner,
    onConfirm: onConfirmPartner,
  } = usePartnerModal(handleUpdateInvoices)

  useEffect(() => {
    if (currUserCompanyId) {
      const queryParams = filterValuesToQueryParams(filters, currUserCompanyId)
      fetchUncommittedInvoices(queryParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currUserCompanyId, filters])

  const handleCommitInvoices = (selection) => {
    const checkedInvoices = invoices.filter((invoice) => selection.includes(invoice.id))

    commitInvoices(checkedInvoices)
  }

  const columns = [
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.tags' }),
      name: 'tags',
      getCellValue: (row) => <TagCell tags={filterTags(row?.tags, true)} />,
      getCellValueToExport: (row) => (filterTags(row?.tags, true) ? '!' : ''),
      customizeCellToExport: customTags,
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: getTagsOptionsFileUpload(),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.seller-name' }),
      name: 'sellerName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.seller-id-type' }),
      name: 'sellerIdType',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.seller-id' }),
      name: 'sellerId',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.partner-name' }),
      name: 'partnerName',
      filter: true,
      disabled: currUserCompany.isProcessingEntity,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.partner-id-type' }),
      name: 'partnerIdType',
      filter: true,
      disabled: currUserCompany.isProcessingEntity,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.partner-id' }),
      name: 'partnerId',
      filter: true,
      disabled: currUserCompany.isProcessingEntity,
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      title: formatMessage({ id: 'booking-import.processing-entity-company-name' }),
      name: 'processingEntityCompanyName',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: !currUserCompany.isProcessingEntity ? processingEntities?.reduce((r, i) => {
            return Object.assign(r, { [i.name]: i.name })
          }, { 'Direct': 'Direct' }) || { 'Direct': 'Direct' } : { [currUserCompany.name]: currUserCompany.name }
        }
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.booking-ref' }),
      name: 'bookingRef',
      getCellValue: (row) => <BookingRefCell bookingRef={row.bookingRef} tags={filterTags(row.tags, true)} />,
      getCellValueToExport: (row) => row?.bookingRef,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.lead-name' }),
      name: 'leadName',
      filter: true,
      disabled: currUserCompany.isProcessingEntity,
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.dep-date' }),
      name: 'departureDate',
      getCellValue: (row) => <DepartureDateCell departureDate={row?.departureDate} tags={row?.tags} />,
      getCellValueToExport: (row) => dateToReadableDate(row.departureDate),
      filter: {
        type: FILTER_TYPES.DATE,
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.due' }),
      name: 'earliestDueDate',
      editComponent: (props) => <DueDateEditField dueDate={getDueDate(props.row)} {...props} />,
      type: 'date',
      filter: { type: FILTER_TYPES.DATE },
      getCellValue: (row) => getDueDate(row),
      getCellValueToExport: (row) => dateToReadableDate(getDueDate(row)),
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL
    },
    {
      title: formatMessage({ id: 'booking-import.currency' }),
      name: 'currency',
      getCellValue: row => row?.currency ?? '',
      getCellValueToExport: row => row?.currency ?? '',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: {
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP],
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR],
          }
        }
      },
      titleToExport: 'Currency',
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.amount' }),
      name: 'originalAmountInCents',
      getCellValue: (row) => <OriginalAmountCell
        originalAmountInCents={row?.originalAmountInCents}
        tags={row?.tags}
        currency={row.currency} />,
      getCellValueToExport: (row) => row?.originalAmountInCents,
      customizeCellToExport: customOriginalAmount,
      type: 'currency',
      summary: filters?.currency?.length !== 1 ? null : (total, row) =>
        total + parseInt(row?.updatedValues?.currentAmountInCents || row?.currentAmountInCents, 10) ?? 0,
      summaryCurrency: filters?.currency?.[0],
      filter: { type: FILTER_TYPES.CURRENCY },
      align: 'right',
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.cl-description' }),
      name: 'commercialLinkDisplayName',
      getCellValue: row => <CommercialLinkDisplayNameCell
        displayName={row.commercialLinkDisplayName}
        tags={row.tags}
        isImport={true}
      />,
      getCellValueToExport: row => row.commercialLinkDisplayName,
      filter: true,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.tx-status' }),
      name: 'status',
      getCellValue: (row) => <StatusCell status={row.status} />,
      getCellValueToExport: (row) => row.status,
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(SPS_IMPORT_FILE_STATUSES)
              .map(([k, v]) => [k, formatMessage({ id: `sps-import-file-status.${kebabCase(v)}` })]),
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.S
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.description' }),
      name: 'reasonDescription',
      getCellValue: (row) => <DescriptionCell description={row.reasonDescription} status={row.status} />,
      getCellValueToExport: (row) => row.reasonDescription,
      filter: true,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.updated-by' }),
      name: 'updatedBy',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-import.updated-at' }),
      name: 'updatedAt',
      getCellValue: (row) => utcToTimezone(row.updatedAt, currUserCompany.timezone),
      getCellValueToExport: (row) => dateToReadableDateTime(utcToTimezone(row.updatedAt, currUserCompany.timezone)),
      filter: { type: FILTER_TYPES.DATE },
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'datetime',
    },
    {
      title: formatMessage({ id: 'booking-import.seller-invoice-no' }),
      name: 'sellerInvoiceNo',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-import.buyer-booking-ref' }),
      name: 'buyerBookingRef',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
  ]

  const handleRowUpdate = ({ row, change }) => {
    const patchInvoice = {
      id: row.id,
      earliestDueDate: change.earliestDueDate.format('YYYY-MM-DD'),
    }
    updateInvoice(patchInvoice)
  }

  const handleSuccessImport = () => {
    // refetch invoices on success import
    const queryParams = filterValuesToQueryParams(filters, currUserCompanyId)
    fetchUncommittedInvoices(queryParams)
  }

  const parsedInvoices = parseInvoices(invoices)
  const filteredInvoices = filterLocalInvoices(parsedInvoices, filters)
  const totalNumberOfInvoices = filteredInvoices.length

  return (
    <AuthView location={location} title="Transactions">
      <BreadcrumbMenuTransactions />
      <ImportedDataTable
        columns={columns}
        defaultHiddenColumns={DEFAULT_HIDDEN_COLUMNS}
        data={filteredInvoices}
        onPageChanged={() => { }}
        selectable={{
          actions: [
            {
              title: formatMessage({ id: `bulk-actions.${kebabCase(BULK_ACTIONS.COMMIT)}` }),
              icon: 'add_circle',
              onClick: handleCommitInvoices,
              validate: validateCommit(invoices),
              color: 'success',
            },
            {
              title: formatMessage({ id: `bulk-actions.${kebabCase(BULK_ACTIONS.UPDATE_PARTNER)}` }),
              icon: 'people',
              onClick: openModalPartner,
              validate: validateUpdatePartner(invoices),
              disabled: currUserCompany.status !== COMPANY_STATUS.LIVE,
            },
            {
              title: formatMessage({ id: `bulk-actions.${kebabCase(BULK_ACTIONS.DELETE)}` }),
              icon: 'delete',
              onClick: openModalDelete,
              validate: validateDelete(invoices),
              color: 'error',
            },
          ],
        }}
        editable={{
          onRowsUpdate: handleRowUpdate,
        }}
        toolbarActions={[
          {
            icon: 'backup',
            label: formatMessage({ id: 'table-filters.upload-file' }),
            tooltip: 'Upload file',
            onClick: () => {
              setUploadModalIsOpen(true)
            },
          },
        ]}
        filtering={{
          defaultFilters: initialFilterValues,
          filters,
          onChange: (newValue) => {
            setFilters(newValue)
          },
          clearFilters: () => {
            setFilters(initialFilterValues)
          },
        }}
        totalNumberOfItems={totalNumberOfInvoices}
      />
      <DeleteConfirmationModal selection={deleteSelection} onCancel={onCancelDelete} onConfirm={onConfirmDelete} />
      <PartnerAutocompleteModal selection={partnerSelection} onCancel={onCancelPartner} onConfirm={onConfirmPartner} selectionFilter={
        (c) => c.status === COMPANY_STATUS.LIVE
      } />
      <UploadModal
        isOpen={uploadModalIsOpen}
        onCloseClick={() => {
          setUploadCSVError(null)
          setUploadModalIsOpen(false)
        }}
        onSuccess={handleSuccessImport}
        uploadCSV={uploadCSV}
        setUploadCSVError={setUploadCSVError}
        csv={csv}
      />
      <SnackbarComponent status={snackbar.isOpened} error={snackbar.isError} text={snackbar.successOrErrorText} action={snackbar.action} push={push} />
      <GlobalSpinnerContextProvider>
        <Spinner open={isLoading} />
      </GlobalSpinnerContextProvider>
    </AuthView>
  )
}

CSVMenu.propTypes = {
  location: PropTypes.object,
  uploadCSV: PropTypes.func,
  setUploadCSVError: PropTypes.func,
  currUserCompanyId: PropTypes.string,
  currUserCompany: PropTypes.object,
  fetchUncommittedInvoices: PropTypes.func,
  invoices: PropTypes.array,
  commitInvoices: PropTypes.func,
  updateInvoices: PropTypes.func,
  deleteInvoices: PropTypes.func,
  snackbar: PropTypes.object,
  isLoading: PropTypes.bool,
  csv: PropTypes.object,
}

export default CSVMenu
