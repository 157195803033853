import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

export const DueDateEditField = memo((props) => {

    const { dueDate, onValueChange } = props
    const [date, setDate] = useState(moment.utc(dueDate))

    const handleDateChange = (_date) => {
        const newDate = moment(moment(_date).format('YYYY-MM-DD'))
        const originalDate = moment(moment(dueDate).format('YYYY-MM-DD'))

        if (newDate.isValid()) {
            // Commit only if value is actually changed
            if (!newDate.isSame(originalDate)) {
                onValueChange(newDate)
            }
        }
        setDate(_date)
    }

    return (
        <TableEditRow.Cell>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    value={date}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
        </TableEditRow.Cell>
    )
})

DueDateEditField.propTypes = {
    onValueChange: PropTypes.func.isRequired,
    dueDate: PropTypes.object
}