import { BASE_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const getSimulations = async () => {
  const url = `${BASE_URL}/collection-simulation`
  return httpFetch(url, {
    shouldThrowError: true
  })
}

export const getCommercialLinkCollectionDates = async (commercialLinkId, date) => {
  const utcDate = new Date(date).toISOString()
  const url = `${BASE_URL}/collection-simulation/dates?commercialLinkId=${commercialLinkId}&simulationDate=${utcDate}`
  return httpFetch(url, {
    shouldThrowError: true
  })
}

export const postSimulation = async (body) => {
  const url = `${BASE_URL}/collection-simulation`
  return httpFetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    shouldThrowError: true
  })
}

export const deleteSimulation = async (id) => {
  const url = `${BASE_URL}/collection-simulation/${id}`
  return httpFetch(url, {
    method: 'DELETE',
    shouldThrowError: true
  })
}
