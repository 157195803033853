import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { useIntl } from 'react-intl'

const HiddenPasswordLabel = ({ password }) => {
  const [ hiddenPassword, setHiddenPassword ] = useState(true)
  const { formatMessage } = useIntl()

  const handleTogglePasswordVisibility = event => {
    event.preventDefault()
    setHiddenPassword(!hiddenPassword)
  }

  return (
    <>
      <Typography component="p">
      {formatMessage({ id: 'data-delivery.hidden-password-label.password' })}: (
        <Link href="#" onClick={handleTogglePasswordVisibility}>
          {
            hiddenPassword
              ? formatMessage({ id: 'data-delivery.hidden-password-label.show' })
              : formatMessage({ id: 'data-delivery.hidden-password-label.hide' })
          }
        </Link>
        ) { hiddenPassword ? '*'.repeat(password?.length) : password }
      </Typography>
    </>
  )
}

HiddenPasswordLabel.propTypes = {
  password: PropTypes.string
}

export default HiddenPasswordLabel
