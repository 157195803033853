import { all, call } from 'redux-saga/effects'
import { takeFirst } from 'utils/generators'
import httpFetch from 'utils/httpFetch'
import { API_URL } from 'constants.js'
import { ACTIONS } from 'store/admin'

// Functions
export const performInviteUser = async newUserData => {
  const url = `${API_URL}/user`
  const userData = {
    name: newUserData.newUserName,
    email: newUserData.newUserEmail,
    is_admin: newUserData.newUserIsAdmin ? 1 : 0
  }
  const data = {
    body: JSON.stringify(userData),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  }

  return httpFetch(url, data)
}

// Sagas
export function* inviteUser(action) {
  try {
    const result = yield call(performInviteUser, action.payload)
    if (!result) {
      throw Error('Unable to invite user')
    }
  } catch (error) {
    console.error(error)
  }
}

// Watchers
function* watchInviteUser() {
  const { INVITE_USER } = ACTIONS
  yield takeFirst(INVITE_USER, inviteUser)
}

export default function* rootSaga() {
  yield all([
    watchInviteUser()
  ])
}
