import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { FormattedDate, FormattedTime } from 'react-intl'

const SimulationDetailValue = ({ date, dateOnly }) => {
  return (
    <Typography variant="body1">
      <FormattedDate value={date} />
      { !dateOnly ? (
        <>
          { ' ' }
          <FormattedTime value={date} />
        </>
      ) : null }
    </Typography>
  )
}

SimulationDetailValue.propTypes = {
  date: PropTypes.string,
  dateOnly: PropTypes.bool
}

export default memo(SimulationDetailValue)
