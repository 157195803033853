import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react'

import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './styles'
import { Button, DialogActions, DialogTitle } from '@material-ui/core'

export const Modal = forwardRef(
  ({ children, maxWidth = 'sm', onClose = () => {}, defaultOpened = false, title, actions, ...otherProps }, ref) => {
    const classes = useStyles()
    const [isOpen, setIsOpen] = useState(defaultOpened)

    const close = useCallback(() => {
      setIsOpen(false)
      onClose()
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
      }),
      [close],
    )

    const handleEscape = useCallback(
      (event) => {
        if (event.keyCode === 27) {
          close()
        }
      },
      [close],
    )

    useEffect(() => {
      if (isOpen) {
        document.addEventListener('keydown', handleEscape, false)
      }
      return () => {
        document.removeEventListener('keydown', handleEscape, false)
      }
    }, [handleEscape, isOpen])

    return (
      <Dialog fullWidth maxWidth={maxWidth} aria-labelledby="company-modal" open={isOpen}>
        {title && <DialogTitle id="company-modal">{title}</DialogTitle>}
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => close()}>
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent} {...otherProps}>
          {children}
        </DialogContent>
        {actions && !!Object.keys(actions).length && (
          <DialogActions>
            {actions.secondary && (
              <Button onClick={actions.secondary.handler} color="primary">
                {actions.secondary.label}
              </Button>
            )}
            {actions.primary && (
              <Button onClick={actions.primary.handler} color="primary" autoFocus>
                {actions.primary.label}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    )
  },
)

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  defaultOpened: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  actions: PropTypes.shape({
    primary: PropTypes.shape({ label: PropTypes.string, handler: PropTypes.func }),
    secondary: PropTypes.shape({ label: PropTypes.string, handler: PropTypes.func }),
  }),
}

Modal.displayName = 'Modal'

export default Modal
