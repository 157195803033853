/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { performGetRemittance } from 'store/remittances/requests'
import { ReasonCodeCell } from 'containers/BookingsReview/components'
import {
  PayerNameCell,
  PayeeNameCell,
  RemittanceStatusCell,
  InvoiceStatusCell,
} from 'components/TransactionsTable/components'
import DataGrid from 'components/DataGrid'
import styles from './styles.module.css'
import {
  getBuyerIdFromInvoice,
  getBuyerIdTypeFromInvoice,
  getSellerIdFromInvoiceOnRemittances,
  getSellerIdTypeFromInvoiceOnRemittances,
} from 'utils/invoices'
import { getRemittance } from 'services/remittancesApi'
import Spinner from 'containers/App/components/Spinner'
import { useIntl } from 'react-intl'

const DETAILS_TYPE_INVOICES = 'invoices'
const DETAILS_TYPE_REMITTANCES = 'remittances'

const remittancesColumns = (formatMessage) => [
  { title: formatMessage({ id: 'transaction-details-table.remittances-columns.payer' }), name: 'sourceId', getCellValue: PayerNameCell },
  { title: formatMessage({ id: 'transaction-details-table.remittances-columns.payee' }), name: 'targetId', getCellValue: PayeeNameCell },
  { title: formatMessage({ id: 'transaction-details-table.remittances-columns.date-paid' }), name: 'paymentDate', type: 'date' },
  {
    title: formatMessage({ id: 'transaction-details-table.remittances-columns.amount-paid' }),
    name: 'amountPaidInCents',
    type: 'currency',
    summary: (total, row) => total + parseInt(row?.updatedValues?.amountPaidInCents || row?.amountPaidInCents, 10) ?? 0,
    align: 'right',
  },
  { title: formatMessage({ id: 'transaction-details-table.remittances-columns.payment-ref' }), name: 'paymentRef' },
  { title: formatMessage({ id: 'transaction-details-table.remittances-columns.payment-method' }), name: 'paymentMethod' },
  { title: formatMessage({ id: 'transaction-details-table.remittances-columns.status' }), name: 'status', getCellValue: RemittanceStatusCell },
  {
    title: formatMessage({ id: 'transaction-details-table.remittances-columns.cl-payment-profile' }),
    name: 'paymentProfile',
    getCellValue: (row) => row?.invoices[0]?.commercialLink?.paymentProfile?.name,
  },
  {
    title: formatMessage({ id: 'transaction-details-table.remittances-columns.cl-description' }),
    name: 'commercialLinkDescription',
    getCellValue: (row) => row?.invoices[0]?.commercialLink?.description,
  },
]

const invoicesColumns = (formatMessage, isPE, currency) => [
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.seller-name' }),
    name: 'sellerName',
    getCellValue: (row) => row.sourceCompany.name,
    filter: true,
  },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.seller-id-type' }),
    name: 'sellerIdType',
    getCellValue: getSellerIdTypeFromInvoiceOnRemittances,
    filter: true,
  },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.seller-id' }),
    name: 'sellerId',
    getCellValue: getSellerIdFromInvoiceOnRemittances,
    filter: true,
  },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.buyer-name' }),
    name: 'buyerName',
    getCellValue: (row) => row.targetCompany.name,
    filter: true,
  },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.buyer-id-type' }),
    name: 'buyerIdType',
    getCellValue: getBuyerIdTypeFromInvoice,
    filter: true,
  },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.buyer-id' }),
    name: 'buyerId',
    getCellValue: getBuyerIdFromInvoice,
    filter: true,
  },
  { title: formatMessage({ id: 'transaction-details-table.invoices-columns.booking-ref' }), name: 'bookingRef' },
  { title: formatMessage({ id: 'transaction-details-table.invoices-columns.lead-name' }), name: 'leadName', disabled: isPE },
  { title: formatMessage({ id: 'transaction-details-table.invoices-columns.dep-date' }), name: 'departureDate', type: 'date' },
  { title: formatMessage({ id: 'transaction-details-table.invoices-columns.due' }), name: 'earliestDueDate', type: 'date' },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.original-amt' }),
    name: 'originalAmountInCents',
    summary: (total, row) =>
      total + parseInt(row?.updatedValues?.originalAmountInCents || row?.originalAmountInCents, 10) ?? 0,
    summaryCurrency: currency,
    type: 'currency',
    align: 'right',
  },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.current-amt' }),
    name: 'currentAmountInCents',
    summary: (total, row) =>
      total + parseInt(row?.updatedValues?.currentAmountInCents || row?.currentAmountInCents, 10) ?? 0,
    summaryCurrency: currency,
    type: 'currency',
    align: 'right',
  },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.tx-status' }),
    name: 'status',
    getCellValue: InvoiceStatusCell,
  },
  { title: formatMessage({ id: 'transaction-details-table.invoices-columns.reason-code' }), name: 'reasonCode', getCellValue: ReasonCodeCell },
  {
    title: formatMessage({ id: 'transaction-details-table.invoices-columns.cl-description' }),
    name: 'commercialLinkDescription',
    getCellValue: (row) => row?.commercialLink?.description,
  },
]

const InnerTransactionDetailsTable = ({
  rowData,
  detailsType,
  title,
  isCurrentCompanyProcessingEntity,
  commentSection,
  exportTitle,
  disableExport,
}) => {
  const [remittances, setRemittances] = useState([])
  const [invoices, setInvoices] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { formatMessage } = useIntl()

  const currency = rowData?.currency

  const columns = {
    // Hide leadName for PE
    [DETAILS_TYPE_INVOICES]: invoicesColumns(formatMessage, isCurrentCompanyProcessingEntity, currency),
    [DETAILS_TYPE_REMITTANCES]: remittancesColumns(formatMessage),
  }[detailsType]

  const data = {
    [DETAILS_TYPE_INVOICES]: invoices,
    [DETAILS_TYPE_REMITTANCES]: remittances,
  }[detailsType] || [] 

  useEffect(() => {
    const fetchRemittancesByIds = async () => {
      setIsLoading(true)
      const results = await Promise.all(
        rowData.ir.map((remittanceId) => performGetRemittance(remittanceId).then(({ data }) => data.attributes)),
      )
      setRemittances(results)
      setIsLoading(false)
    }

    const fetchRemittance = async () => {
      setIsLoading(true)
      const remittance = await getRemittance(rowData.id)
      setInvoices(remittance.data.attributes.invoices)
      setIsLoading(false)
    }

    if (detailsType === DETAILS_TYPE_INVOICES && rowData) {
      fetchRemittance()
    } else if (detailsType === DETAILS_TYPE_REMITTANCES) {
      fetchRemittancesByIds()
    }
  }, [rowData])

  if (isLoading) {
    return <Spinner open={true} />
  }

  return (
    <div className={styles.innerTable}>
      <DataGrid
        pagination={{ disabled: true }}
        sort={{ disabled: true }}
        disableExport={disableExport}
        disableColumnVisibility={disableExport}
        disableResizing
        disableReordering
        title={title}
        exportTitle={exportTitle}
        columns={columns}
        data={data}
      />
      {commentSection}
    </div>
  )
}

InnerTransactionDetailsTable.propTypes = {
  title: PropTypes.string.isRequired,
  detailsType: PropTypes.oneOf([DETAILS_TYPE_INVOICES, DETAILS_TYPE_REMITTANCES]).isRequired,
  rowData: PropTypes.object.isRequired,
  isCurrentCompanyProcessingEntity: PropTypes.bool,
  commentSection: PropTypes.node,
  exportTitle: PropTypes.string,
  disableExport: PropTypes.bool,
}

const mapState = ({ auth }) => ({
  currUserCompanyId: auth.companyId,
})

export default connect(mapState, null)(InnerTransactionDetailsTable)
