import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'
import { withConnect } from './connect'

const CreateTransaction = ({ redirectTo, goBack }) => {
  const classes = useStyles()

  const handleClose = () => {
    goBack()
  }

  const handleRedirect = path => () => {
    redirectTo(`/new-transaction/${path}`)
  }

  return (
    <Dialog aria-labelledby="choose-transaction-type-dialog" className={classes.dialog} open={true} maxWidth={'sm'} fullWidth={true}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>Choose transaction type:</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleRedirect('booking')} id="booking">Create Booking</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleRedirect('remittance')} id="remittance">Create Remittance</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

CreateTransaction.propTypes = {
  redirectTo: PropTypes.func,
  goBack: PropTypes.func
}

export default withConnect(CreateTransaction)
