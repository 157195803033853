import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import React from 'react'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'
import SelectOnTabMenuItem from '../SelectOnTabMenuItem'
import { Chip } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

// eslint-disable-next-line react/display-name
const FormSelect = React.memo(({
  label,
  keyTitle,
  name,
  fullWidth,
  className,
  style,
  error,
  variant,
  disabled,
  dense,
  margin,
  multiple,
  ...props
}) => {
  // the original implementation with `keyTitle` being an object does
  // not allow us to choose the order of the items
  const items = keyTitle instanceof Array
    ? keyTitle
    : Object.keys(keyTitle).map(
      value => ({ value, label: keyTitle[value] })
    )

  return <FormControl {...dense && { size: 'small' }} margin={margin} variant={variant} fullWidth={fullWidth} className={className} style={style} error={!!error} disabled={disabled}>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Select
      {...props}
      {...(multiple && {
        renderValue: selected => selected && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
            {selected.map(value => (
              <Chip
                key={value}
                label={keyTitle[value]}
                clickable
                deleteIcon={<CancelIcon onMouseDown={(e) => e.stopPropagation()} />}
                onDelete={() => props.onChange(props.value.filter(v => v !== value))}
                disabled={disabled}
              />
            ))}
          </div>
        )
      })}
      multiple={multiple}
      value={
        multiple
          ? (Array.isArray(props.value) ? props.value : [])
          : (props.value || '')
      }
      label={label}
      MenuProps={{ variant: "menu" }}
      inputProps={{
        name,
        id: name
      }}
    >
      {
        items.map(({ value, label: itemLabel }) => (
          <SelectOnTabMenuItem
            name={name}
            key={value}
            value={value}
            onChange={props.onChange}
          >
            {itemLabel}
          </SelectOnTabMenuItem>
        ))
      }
    </Select>
    {error ? (<FormHelperText>{error}</FormHelperText>) : null}
  </FormControl>
})

FormSelect.propTypes = {
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]))
  ]),
  label: PropTypes.string.isRequired,
  keyTitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    }))
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default FormSelect
