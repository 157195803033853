import React, { useRef, useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { useStyles } from './styles'
import Portal from 'components/Portal'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

const BOUND_MARGIN = 12

const HelpPopup = ({ children, id, values, withPortal, defaultMessage = 'EMPTY', iconSize = 'inherit' }) => {
  const childrenEl = useRef(null)
  const [ coords, setCoords ] = useState({})
  const { formatMessage } = useIntl()

  // formatMessage returns the id if the translation is empty. Use a default message and clean it after
  let helpMessage = formatMessage({ id: `${id}.help`, defaultMessage }, { br: <br />, ...values })
  if (typeof (helpMessage) === 'string') {
    helpMessage = helpMessage.replace('EMPTY', '')
  }

  useEffect(() => {
    if (withPortal && childrenEl?.current) {
      const rect = childrenEl.current.getBoundingClientRect()
      setCoords({
        left: rect.x + rect.width,
        top: rect.y + window.scrollY - BOUND_MARGIN
      })
    }
  }, [ childrenEl, withPortal ])

  const classes = useStyles({ ...coords, withPortal })

  if (!id || !helpMessage) {
    return children
  }

  const HelperTooltip =
    <Tooltip
      arrow
      className={classNames(classes.helpPopup, 'HelperTooltip')}
      interactive
      title={helpMessage}
    >
      <InfoIcon fontSize={iconSize} className={classes.infoIcon} />
    </Tooltip>

  return (
    <span className={classes.title}>
      <span ref={childrenEl}>
        { children }
      </span>
      { !withPortal ?
        HelperTooltip
        : coords?.top ? (
          <Portal>
            {HelperTooltip}
          </Portal>
        ) : null }
    </span>
  )
}

HelpPopup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]),
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  values: PropTypes.object,
  withPortal: PropTypes.bool,
  iconSize: PropTypes.oneOf(['small', 'medium', 'large', 'inherit', 'default'])
}

export const TranslationWithHelpPopup = ({ id, values }) => {
  const { formatMessage } = useIntl()

  return <HelpPopup id={id} values={values}>
    {formatMessage({ id }, values)}
  </HelpPopup>
}

export const useGridTitleWithHelpPopup = ({ id, values }) => {
  const { formatMessage } = useIntl()
  return {
    title: <TranslationWithHelpPopup id={id} values={values} />,
    titleToExport: formatMessage({ id }, values)
  }
}

export default memo(HelpPopup)
