import { fullName } from 'utils/functions'
import { FEATURE_PAGINATION_ENABLED } from 'constants.js'

const SELECT_INVOICE_FOR_ADD_REMITTANCE = fullName('select-invoice-for-add-remittance', 'SELECT_INVOICE_FOR_ADD_REMITTANCE')
const FETCH_INVOICES = fullName('fetch-invoices', 'FETCH_INVOICES')
const FETCH_INVOICES_SUCCESS = fullName('fetch-invoices', 'FETCH_INVOICES_SUCCESS')
const FETCH_INVOICES_FAILURE = fullName('fetch-invoices', 'FETCH_INVOICES_FAILURE')
const FETCH_UNCOMMITTED_INVOICES = fullName('fetch-invoices-uncommitted', 'FETCH_UNCOMMITTED_INVOICES')
const FETCH_UNCOMMITTED_INVOICES_SUCCESS = fullName('fetch-invoices-uncommitted', 'FETCH_UNCOMMITTED_INVOICES_SUCCESS')
const FETCH_UNCOMMITTED_INVOICES_FAILURE = fullName('fetch-invoices-uncommitted', 'FETCH_UNCOMMITTED_INVOICES_FAILURE')
const CREATE_INVOICE = fullName('create-invoice', 'CREATE_INVOICE')
const CREATE_INVOICE_SUCCESS = fullName('create-invoices', 'CREATE_INVOICE_SUCCESS')
const CREATE_INVOICE_FAILURE = fullName('create-invoices', 'CREATE_INVOICE_FAILURE')
const COMMIT_INVOICES = fullName('commit-invoices', 'COMMIT_INVOICES')
const COMMIT_INVOICES_SUCCESS = fullName('commit-invoices', 'COMMIT_INVOICES_SUCCESS')
const COMMIT_INVOICES_FAILURE = fullName('commit-invoices', 'COMMIT_INVOICES_FAILURE')
const UPDATE_INVOICE = fullName('update-invoice', 'UPDATE_INVOICE')
const UPDATE_INVOICE_SUCCESS = fullName('update-invoice', 'UPDATE_INVOICE_SUCCESS')
const UPDATE_INVOICE_FAILURE = fullName('update-invoice', 'UPDATE_INVOICE_FAILURE')
const UPDATE_INVOICES_SUCCESS = fullName('update-invoice', 'UPDATE_INVOICES_SUCCESS')
const UPDATE_INVOICES_FAILURE = fullName('update-invoice', 'UPDATE_INVOICES_FAILURE')
const UPDATE_INVOICES = fullName('update-invoice-uncommitted', 'UPDATE_INVOICES')
const DELETE_INVOICES = fullName('update-invoice', 'DELETE_INVOICES')
const DELETE_INVOICES_SUCCESS = fullName('update-invoice', 'DELETE_INVOICES_SUCCESS')
const DELETE_INVOICES_FAILURE = fullName('update-invoice', 'DELETE_INVOICES_FAILURE')
const CLEAR_INVOICES_STORE = fullName('clear-invoices-store', 'CLEAR_INVOICES_STORE')
const CLOSE_SNACKBAR = fullName('close-snackbar', 'CLOSE_SNACKBAR')
const SET_LOADING = fullName('set-loading', 'SET_LOADING')
const FETCH_LATEST_CYCLES = fullName('fetch-cycles', 'FETCH_LATEST_CYCLES')
const FETCH_LATEST_CYCLES_SUCCESS = fullName('fetch-cycles', 'FETCH_LATEST_CYCLES_SUCCESS')

export const ACTIONS = {
  FETCH_INVOICES,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAILURE,
  FETCH_UNCOMMITTED_INVOICES,
  FETCH_UNCOMMITTED_INVOICES_SUCCESS,
  FETCH_UNCOMMITTED_INVOICES_FAILURE,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  COMMIT_INVOICES,
  COMMIT_INVOICES_SUCCESS,
  COMMIT_INVOICES_FAILURE,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILURE,
  UPDATE_INVOICES_SUCCESS,
  UPDATE_INVOICES_FAILURE,
  UPDATE_INVOICES,
  DELETE_INVOICES,
  DELETE_INVOICES_SUCCESS,
  DELETE_INVOICES_FAILURE,
  CLEAR_INVOICES_STORE,
  CLOSE_SNACKBAR,
  SELECT_INVOICE_FOR_ADD_REMITTANCE,
  SET_LOADING,
  FETCH_LATEST_CYCLES,
  FETCH_LATEST_CYCLES_SUCCESS
}

export const fetchInvoices = (payload) => ({
  type: FETCH_INVOICES,
  payload
})

export const fetchUncommittedInvoices = (payload) => ({
  type: FETCH_UNCOMMITTED_INVOICES,
  payload
})

export const commitInvoices = (payload) => ({
  type: COMMIT_INVOICES,
  payload
})

export const updateInvoice = (payload) => ({
  type: UPDATE_INVOICE,
  payload
})

export const updateInvoices = (payload, uncommitted = true) => ({
  type: UPDATE_INVOICES,
  payload,
  uncommitted
})

export const deleteInvoices = (payload) => ({
  type: DELETE_INVOICES,
  payload
})

export const createInvoice = (payload) => ({
  type: CREATE_INVOICE,
  payload
})

export const clearInvoices = () => ({
  type: CLEAR_INVOICES_STORE
})

export const selectInvoiceForAddRemittance = payload => ({
  type: SELECT_INVOICE_FOR_ADD_REMITTANCE,
  payload
})

export const setLoading = payload => ({
  type: SET_LOADING,
  payload
})

export const fetchLatestCycles = (isPastView) => ({
  type: FETCH_LATEST_CYCLES,
  isPastView
})

const initialState = {
  selected: null,
  committed: {
    filters: {},
    list: [],
    error: false,
    latestCycles: []
  },
  uncommitted: {
    filters: {},
    list: [],
    error: false
  },
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

const updateSingleInvoiceInList = (invoice, updatedItems) => {
  const matchedInvoice = updatedItems.find(i => i.id === invoice.id)
  if (matchedInvoice) {
    const t = {
      ...invoice,
      ...matchedInvoice,
      lastUpdate: Date.now()
    }
    return t
  }
  return invoice
}

const revertSingleInvoiceInList = (invoice, updatedItems) => {
  const matchedInvoice = updatedItems.some(i => i.id === invoice.id)
  if (matchedInvoice) {
    const t = {
      ...invoice,
      lastUpdate: Date.now()
    }
    return t
  }
  return invoice
}

export const invoices = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_INVOICE_FOR_ADD_REMITTANCE:
      return {
        ...state,
        selected: action.payload
      }
    case FETCH_INVOICES:
      return {
        ...state,
        committed: {
          ...state.committed,
          filters: action.payload || state.committed.filters
        }
      }
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        selected: null,
        committed: {
          ...state.committed,
          list: FEATURE_PAGINATION_ENABLED ? action.payload.attributes : action.payload,
          lastUpdate: Date.now()
        }
      }
    case FETCH_INVOICES_FAILURE:
      return {
        ...state,
        selected: null,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case FETCH_UNCOMMITTED_INVOICES:
      return {
        ...state,
        uncommitted: {
          ...state.uncommitted,
          filters: action.payload || state.uncommitted.filters
        }
      }
    case FETCH_UNCOMMITTED_INVOICES_SUCCESS:
      return {
        ...state,
        uncommitted: {
          ...state.uncommitted,
          list: action.payload
        }
      }
    case UPDATE_INVOICE || UPDATE_INVOICES:
      return {
        ...state,
        // snackbar: {
        //   isOpened: false,
        //   isError: false,
        //   successOrErrorText: ''
        // }
      }
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        committed: {
          ...state.committed,
          list: [
            ...state.committed.list.map(inv => {
              if (inv.id === action.payload.id) {
                const t = {
                  ...inv,
                  ...action.payload.attributes,
                  lastUpdate: Date.now()
                }
                return t
              }
              return inv
            })
          ]
        }
      }
    case UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        committed: {
          ...state.committed,
          list: [
            ...state.committed.list.map(inv => {
              if (inv.id === action.payload.id) {
                return {
                  ...inv,
                  lastUpdate: Date.now()
                }
              }
              return inv
            })
          ]
        },
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload.error || 'Cannot update invoice'
        }
      }
    case UPDATE_INVOICES_SUCCESS:
      // Same action is used for committed and uncommitted invoices, that's why we have to make sure to update only
      // invoices we are trying to update
      return {
        ...state,
        committed: action.payload.uncommitted ? state.committed : {
          ...state.committed,
          list: [
            ...state.committed.list.map(i => updateSingleInvoiceInList(i, action.payload.data))
          ]
        },
        uncommitted: action.payload.uncommitted ? {
          ...state.uncommitted,
          list: [
            ...state.uncommitted.list.map(i => updateSingleInvoiceInList(i, action.payload.data))
          ]
        } : state.uncommitted
      }
    case UPDATE_INVOICES_FAILURE:
      return {
        ...state,
        committed: action.payload.uncommitted ? state.committed : {
          ...state.committed,
          list: [ ...state.committed.list.map(i => revertSingleInvoiceInList(i, action.payload.data)) ]
        },
        uncommitted: action.payload.uncommitted ? {
          ...state.uncommitted,
          list: [ ...state.uncommitted.list.map(i => revertSingleInvoiceInList(i, action.payload.data)) ]
        } : state.uncommitted,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload.error || 'Cannot update invoices'
        }
      }
    case DELETE_INVOICES_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: action.payload
        }
      }
    case DELETE_INVOICES_FAILURE:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: action.payload.message,
          action: action.payload.action
        }
      }
    case CREATE_INVOICE_FAILURE:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload.message,
          action: action.payload.action
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    case CLEAR_INVOICES_STORE:
      return {
        ...state,
        committed: {
          ...state.committed,
          list: []
        },
        uncommitted: {
          ...state.uncommitted,
          list: []
        },
        latestCycleStartDates: null,
        latestCollectionDates: null,
        latestCollectionPayments: null,
        latestDisbursementDates: null,
        latestDisbursementPayments: null,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case FETCH_LATEST_CYCLES_SUCCESS:
      return {
        ...state,
        latestCycleStartDates: action.latestCycleStartDates,
        latestCollectionDates: action.latestCollectionDates,
        latestCollectionPayments: action.latestCollectionPayments,
        latestDisbursementDates: action.latestDisbursementDates,
        latestDisbursementPayments: action.latestDisbursementPayments,
      }
    default:
      return state
  }
}
