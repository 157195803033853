import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  topTransactionPaper: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    boxShadow: '0px 2px 3px -1px rgba(0,0,0,0.2), 0px 1px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 1px rgba(0,0,0,0.12)'
  },
  fieldsMargin: {
    paddingRight: theme.spacing(1),
    width: '100%'
  },
  formInput: {
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px'
  }
}))
