import React, { memo } from 'react'
import Alert from '@material-ui/lab/Alert'

const RequiredCell = memo(() => {
  return (
    <Alert severity="error">Required</Alert>
  )
})

RequiredCell.displayName = 'RequiredCell'

export default RequiredCell
