import { connect } from 'react-redux'
import Component from './UsersInvites'
import { push } from 'connected-react-router'

export const mapState = ({ auth }) => ({
  auth,
})

export const mapDispatch = (dispatch) => ({
  push: (data) => dispatch(push(data)),
})

export default connect(mapState, mapDispatch)(Component)
