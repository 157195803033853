import { makeStyles } from '@material-ui/core/styles'
import { darken, lighten, fade } from '@material-ui/core/styles/colorManipulator'
import { BSD_CALENDAR_EVENTS } from 'constants.js'

export const getBorder = (theme) =>
  `1px solid ${
    theme.palette.type === 'light'
      ? lighten(fade(theme.palette.divider, 1), 0.88)
      : darken(fade(theme.palette.divider, 1), 0.68)
  }`

export const getBrightBorder = (theme) =>
  `1px solid ${
    theme.palette.type === 'light'
      ? lighten(fade(theme.palette.divider, 1), 0.72)
      : darken(fade(theme.palette.divider, 1), 0.5)
  }`

export const useStyles = makeStyles((theme) => ({
  bsdGridContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  bsdActions: {
    padding: theme.spacing(1),
  },
  bsdPopover: {
    width: '400px',
  },
  GREEN: {
    color: theme.palette.success.main,
  },
  RED: {
    color: theme.palette.error.main,
  },
  BLUE: {
    color: theme.palette.primary.light,
  },
  ORANGE: {
    color: theme.palette.warning.main,
  },
  TRANSPARENT: {
    opacity: 0,
  },
  cell: {
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 0,
    height: 30,
    borderRight: getBorder(theme),
    '&:last-child': {
      borderRight: 'none',
      paddingRight: 0,
    },
    'tr:last-child &': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
      outline: 0,
    },
    boxSizing: 'border-box',
  },
  text: {
    padding: '0',
    textAlign: 'center',
  },
  today: {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    width: '1.72em',
    height: '1.72em',
    lineHeight: 1.72,
    textAlign: 'center',
    borderRadius: '50%',
    cursor: 'default',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  otherMonth: {
    cursor: 'pointer',
    color: theme.palette.text.disabled,
  },
  shadedCell: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
      outline: 0,
    },
  },
  [BSD_CALENDAR_EVENTS.WEEKEND]: {
    cursor: 'pointer',
    fontWeight: 600,
    color: theme.palette.text.disabled,
    position: 'relative',
    '&::after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      content: '"x"',
      color: theme.palette.action.disabled,
      lineHeight: '100%',
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 100,
    },
  },
  [BSD_CALENDAR_EVENTS.HOLIDAY]: {
    cursor: 'pointer',
    fontWeight: 600,
    color: theme.palette.text.disabled,
    position: 'relative',
    '&::after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      content: '"x"',
      color: theme.palette.action.disabled,
      lineHeight: '100%',
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 100,
    },
  },
  [BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION]: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  [BSD_CALENDAR_EVENTS.SKIP_COLLECTION]: {
    fontWeight: 600,
    color: theme.palette.error.main,
    position: 'relative',
    '&::after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      content: '"x"',
      color: theme.palette.error.light,
      lineHeight: '100%',
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 100,
    },
  },
  [BSD_CALENDAR_EVENTS.FORCE_COLLECTION]: {
    fontWeight: 600,
    color: theme.palette.success.main,
  },
  brightRightBorder: {
    borderRight: getBrightBorder(theme),
    '&:last-child': {
      borderRight: 'none',
    },
  },
  brightBorderBottom: {
    borderBottom: getBrightBorder(theme),
  },
  legendRow: {
    width: 'auto',
    flexDirection: 'row',
  },
  legendCell: {
    width: 10,
    height: 'auto',
  },
}))
