import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import {
  Box,
  Card,
  CardActions,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import Button from 'components/Button'
import FormSelect from 'components/FormSelect'
import ReactHookFormMuiCheckbox from 'components/ReactHookFormMuiCheckbox'
import {
  SCHEDULE_FILE_FORMAT_KEY_TITLE,
  SCHEDULE_SPS_FILE_FORMAT_KEY_TITLE,
  SCHEDULE_AVAILABLE_TEMPLATES,
  TASK_FREQUENCY_TYPES,
  TASK_TRANSACTIONS_CRITERIA_TYPES,
  TASK_SELLER_REC_TEMPLATES,
  TASK_BUYER_REC_TEMPLATES,
  TASK_TRANSACTIONS_TEMPLATES,
  TASK_CRITERIA,
  TASK_CRITERIA_TITLE,
  SCHEDULE_AVAILABLE_DATE_FORMATS,
  SCHEDULE_AVAILABLE_ENCAPSULATION_OPTIONS,
  SCHEDULE_AVAILABLE_DECIMAL_SEPARATOR_CHAR,
  TRANSACTION_TEMPLATE,
  COMPANIES_TEMPLATE,
} from 'constants.js'
import { formSchema } from './formValidationSchema'
import ChipInput from './ChipInput'
import PeriodField from './PeriodField'
import MonthlyForm from '../MonthlyForm'
import WeeklyForm from '../WeeklyForm'
import { useStyles } from './styles'
import { parseDataToForm, parseDataForSubmit } from './utils'
import moment from 'moment'
import { mapFormKeys } from 'utils/functions'
import { useIntl } from 'react-intl'

const DEFAULT_CRITERIA = TASK_CRITERIA.Last7Days
const getCriteriaOptions = (isCompanies) => [
  TASK_CRITERIA.Today,
  TASK_CRITERIA.Yesterday,
  TASK_CRITERIA.CurrentWeek,
  TASK_CRITERIA.LastCalendarWeek,
  TASK_CRITERIA.Last7Days,
  TASK_CRITERIA.LastCalendarMonth,
  TASK_CRITERIA.LastCalendarYear,
  ...((isCompanies && [TASK_CRITERIA.AllRecords]) || []),
].reduce((options, key) => Object.assign(options, { [key]: TASK_CRITERIA_TITLE[key] }), {})

const DEFAULT_COLLECTION_CRITERIA = TASK_CRITERIA.LastCollection
const getCollectionCriteriaOptions = () => [
  TASK_CRITERIA.LastCollection,
  TASK_CRITERIA.CurrentWeek,
  TASK_CRITERIA.LastCalendarWeek,
  TASK_CRITERIA.CurrentMonth,
  TASK_CRITERIA.LastCalendarMonth,
  TASK_CRITERIA.Last3CalendarMonths,
].reduce((options, key) => Object.assign(options, { [key]: TASK_CRITERIA_TITLE[key] }), {})

const DEFAULT_BUYER_REC_CRITERIA = TASK_CRITERIA.BuyerRecCurrentWeek
const getBuyerRecCriteriaOptions = () => [
  TASK_CRITERIA.BuyerRecCurrentWeek,
  TASK_CRITERIA.BuyerRecAllDue,
  TASK_CRITERIA.Custom,
].reduce((options, key) => Object.assign(options, { [key]: TASK_CRITERIA_TITLE[key] }), {})

const initialValues = {
  name: '',
  fileName: '',
  sftp: false,
  criteria: DEFAULT_CRITERIA,
  mailto: [],
  emailDelivery: false,
  template: '',
  fileFormat: '',
  frequencyType: TASK_FREQUENCY_TYPES.WEEKLY,
  daysOfWeek: ['1'],
  dayOfMonth: '',
  timeOfDay: '00:00',
  anonymiseData: false,
  splitForEachSeller: false,
  endAt: '',
  startAt: '',
  criteriaType: TASK_TRANSACTIONS_CRITERIA_TYPES.COMMIT_DATE,
  customPeriod: null,
  dateFormat: Object.keys(SCHEDULE_AVAILABLE_DATE_FORMATS)[0],
  encapsulation: Object.keys(SCHEDULE_AVAILABLE_ENCAPSULATION_OPTIONS)[0],
  decimalSeparator: Object.keys(SCHEDULE_AVAILABLE_DECIMAL_SEPARATOR_CHAR)[0],
  includeUncommitted: false,
}

const TaskForm = ({
  task = {},
  onSaveTask = () => { },
  onCancelTask = () => { },
  fetchLatestCycles,
  latestCollectionDates,
}) => {
  const classes = useStyles()
  const [periodOptions, setPeriodOptions] = useState({})
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: joiResolver(formSchema),
    defaultValues: initialValues,
  })
  const { formatMessage } = useIntl()

  const [emailDelivery, template, frequencyType, daysOfWeek, dayOfMonth, criteria, criteriaType, customPeriod] = watch([
    'emailDelivery',
    'template',
    'frequencyType',
    'daysOfWeek',
    'dayOfMonth',
    'criteria',
    'criteriaType',
    'customPeriod',
  ])

  let availableFileFormats = {}
  const showCriteriaTypeOptions = TASK_TRANSACTIONS_TEMPLATES.includes(template)
  const isCollectionDateOptionSelected =
    showCriteriaTypeOptions && criteriaType === TASK_TRANSACTIONS_CRITERIA_TYPES.COLLECTION_SUBMISSION
  const isBuyerRecFile = TASK_BUYER_REC_TEMPLATES.includes(template)
  const isSellerRecFile = TASK_SELLER_REC_TEMPLATES.includes(template)
  const isTransactions = template === TRANSACTION_TEMPLATE
  const isCompanies = template === COMPANIES_TEMPLATE
  if (isSellerRecFile) {
    availableFileFormats = SCHEDULE_SPS_FILE_FORMAT_KEY_TITLE
  } else if (template) {
    availableFileFormats = SCHEDULE_FILE_FORMAT_KEY_TITLE
  }
  
  
  const isEdit = task?.id

  const setInitialValue = useCallback((key) => setValue(key, initialValues[key]), [setValue])

  const areDateControlsDisabled = frequencyType === TASK_FREQUENCY_TYPES.NONE
  const forceNoFrequency = useMemo(
    () => isCollectionDateOptionSelected && moment(criteria).isValid(),
    [criteria, isCollectionDateOptionSelected],
  )

  useEffect(() => {
    forceNoFrequency ? setValue('frequencyType', TASK_FREQUENCY_TYPES.NONE) : setInitialValue('frequencyType')
  }, [forceNoFrequency, setValue, setInitialValue])

  useEffect(() => {
    if (areDateControlsDisabled) {
      setInitialValue('timeOfDay')
      setInitialValue('startAt')
      setInitialValue('endAt')
    }
  }, [areDateControlsDisabled, setInitialValue])

  useEffect(() => {
    if (isCollectionDateOptionSelected) {
      fetchLatestCycles()
    } else if (isBuyerRecFile) {
      setPeriodOptions(getBuyerRecCriteriaOptions())
      !isEdit && setValue('criteria', DEFAULT_BUYER_REC_CRITERIA)
    } else {
      const options = getCriteriaOptions(isCompanies)
      setPeriodOptions(options)

      // use a valid value for the new options
      if (!(criteria in options)) {
        !isEdit && setValue('criteria', DEFAULT_CRITERIA)
      }
    }
  }, [criteriaType, isBuyerRecFile, isCompanies, criteria, isEdit, setValue, fetchLatestCycles, isCollectionDateOptionSelected])

  useEffect(() => {
    if (isCollectionDateOptionSelected && latestCollectionDates) {
      const latestCollectionDateOptions = latestCollectionDates.reduce((acc, cur) => {
        const value = cur.startDate.format('YYYY-MM-DD')
        acc[value] = cur.label
        return acc
      }, {})

      const options = {
        ...getCollectionCriteriaOptions(),
        ...latestCollectionDateOptions,
      }

      setPeriodOptions(options)

      // use a valid value for the new options
      if (!(criteria in options)) {
        setValue('criteria', DEFAULT_COLLECTION_CRITERIA)
      }
    }
  }, [latestCollectionDates, isCollectionDateOptionSelected])

  useEffect(() => {
    if (isSellerRecFile) {
      setValue('fileFormat', 'sps')
    } else {
      setValue('fileFormat', 'csv')
    }
  }, [template])

  useEffect(() => {
    if (frequencyType === TASK_FREQUENCY_TYPES.MONTHLY) {
      setValue('daysOfWeek', [])
      if (!dayOfMonth) {
        setValue('dayOfMonth', 'first')
      }
    } else {
      setValue('dayOfMonth', '')
      if (!daysOfWeek) {
        setValue('daysOfWeek', ['1'])
      }
    }
  }, [frequencyType])

  useEffect(() => {
    const values = { ...initialValues, ...parseDataToForm(task) }
    if (TASK_BUYER_REC_TEMPLATES.includes(values.template)) {
      setPeriodOptions(getBuyerRecCriteriaOptions())
      setValue('criteria', values.criteria)
    }
    if (!values.customPeriod) {
      values.customPeriod = null
    }
    reset(mapFormKeys(values, initialValues))
  }, [task?.id])

  const onSubmit = handleSubmit((formData) => {
    const data = parseDataForSubmit(formData)
    if (!showCriteriaTypeOptions) {
      delete data.criteriaType
    }
    if (isBuyerRecFile) {
      data.extra = {
        splitForEachSeller: formData.splitForEachSeller,
        customPeriod: formData.customPeriod && {
          startDate: formData.customPeriod.startDate,
          endDate: formData.customPeriod.endDate,
        },
        dateFormat: formData.dateFormat,
        encapsulation: formData.encapsulation,
        decimalSeparator: formData.decimalSeparator,
      }
    } else if (isTransactions) {
      data.extra = {
        includeUncommitted: formData.includeUncommitted,
      }
    } else {
      data.extra = null
    }

    delete data.splitForEachSeller
    delete data.customPeriod
    delete data.dateFormat
    delete data.encapsulation
    delete data.decimalSeparator
    delete data.includeUncommitted
    onSaveTask(data)
  })

  return (
    <form className={classes.fieldsMargin} noValidate autoComplete="off" onSubmit={onSubmit}>
      <Card className={classes.topPaper}>
        <Grid container spacing={8}>
          <Grid item container md={5}>
            <Grid container item>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={formatMessage({ id: 'task-scheduler.task-name' })}
                    error={!!errors.name}
                    required
                    helperText={errors.name?.message}
                  />
                )}
                control={control}
                name="name"
              />
            </Grid>
            <Grid container item>
              <Controller
                // eslint-disable-next-line no-unused-vars
                render={({ field: { ref, ...field } }) => (
                  <FormSelect
                    {...field}
                    full
                    label={formatMessage({ id: 'task-scheduler.template' })}
                    keyTitle={SCHEDULE_AVAILABLE_TEMPLATES}
                    required
                    fullWidth
                    error={errors.template?.message}
                    disabled={isEdit}
                  />
                )}
                control={control}
                name="template"
              />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={formatMessage({ id: 'task-scheduler.output-file-name' })}
                      full
                      fullWidth
                      required
                      error={!!errors.fileName}
                      helperText={errors.fileName?.message}
                    />
                  )}
                  control={control}
                  name="fileName"
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  // eslint-disable-next-line no-unused-vars
                  render={({ field: { value, onChange, ...field } }) => (
                    <FormSelect
                      {...field}
                      value={value}
                      onChange={onChange}
                      full
                      label={formatMessage({ id: 'task-scheduler.output-file-format' })}
                      keyTitle={availableFileFormats}
                      required
                      fullWidth
                      error={errors.fileFormat?.message}
                    />
                  )}
                  control={control}
                  name="fileFormat"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" justifyContent="space-between">
              <Grid item md={12}>
                {showCriteriaTypeOptions && (
                  <Box my={2}>
                    <Typography variant="h6" className={classes.dialogTitle}>
                      {formatMessage({ id: 'task-scheduler.period-relative-to' })}:
                    </Typography>
                    <Controller
                      control={control}
                      name="criteriaType"
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value={TASK_TRANSACTIONS_CRITERIA_TYPES.COLLECTION_SUBMISSION}
                            control={<Radio />}
                            label={formatMessage({ id: 'task-scheduler.collection-netting-time' })}
                          />
                          <FormControlLabel
                            value={TASK_TRANSACTIONS_CRITERIA_TYPES.COMMIT_DATE}
                            control={<Radio />}
                            label={formatMessage({ id: 'task-scheduler.commit-date' })}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Box>
                )}
                <PeriodField
                  control={control}
                  options={periodOptions}
                  error={errors.period}
                  criteria={criteria}
                  customPeriod={customPeriod}
                />
              </Grid>
            </Grid>
            <Box my={2}>
              <ReactHookFormMuiCheckbox control={control} name="anonymiseData" label={formatMessage({ id: 'task-scheduler.anonymise-data' })} />
              {isTransactions && <>
                <br />
                <ReactHookFormMuiCheckbox 
                  control={control} 
                  name="includeUncommitted" 
                  label={formatMessage({ id: 'task-scheduler.include-all-uncommitted-transactions' })} />
              </>}
            </Box>
            {isBuyerRecFile && (
              <Box mb={2} width="100%">
                <Typography component="h6" variant="h6">
                  {formatMessage({ id: 'task-scheduler.buyer-rec-options' })}
                </Typography>
                <Box>
                  <ReactHookFormMuiCheckbox
                    control={control}
                    name="splitForEachSeller"
                    label={formatMessage({ id: 'task-scheduler.split-file-for-each-seller' })}
                  />
                </Box>
                <Box>
                  <Controller
                    // eslint-disable-next-line no-unused-vars
                    render={({ field: { ref, ...field } }) => (
                      <FormSelect
                        {...field}
                        label={formatMessage({ id: 'task-scheduler.date-format' })}
                        keyTitle={SCHEDULE_AVAILABLE_DATE_FORMATS}
                        fullWidth
                        name="dateFormat"
                      />
                    )}
                    control={control}
                    name="dateFormat"
                  />
                </Box>
                <Box>
                  <Controller
                    // eslint-disable-next-line no-unused-vars
                    render={({ field: { ref, ...field } }) => (
                      <FormSelect
                        {...field}
                        full
                        label={formatMessage({ id: 'task-scheduler.decimal-separator-character' })}
                        keyTitle={SCHEDULE_AVAILABLE_DECIMAL_SEPARATOR_CHAR}
                        fullWidth
                        name="decimalSeparator"
                        error={errors.decimalSeparator?.message}
                      />
                    )}
                    control={control}
                    name="decimalSeparator"
                  />
                </Box>
                <Box>
                  <Controller
                    // eslint-disable-next-line no-unused-vars
                    render={({ field: { ref, ...field } }) => (
                      <FormSelect
                        {...field}
                        full
                        label={formatMessage({ id: 'task-scheduler.encapsulation' })}
                        keyTitle={SCHEDULE_AVAILABLE_ENCAPSULATION_OPTIONS}
                        fullWidth
                        name="encapsulation"
                        error={errors.encapsulation?.message}
                      />
                    )}
                    control={control}
                    name="encapsulation"
                  />
                </Box>
              </Box>
            )}
            <Box mb={2} width="100%">
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'task-scheduler.delivery-method' })}
              </Typography>
              {(errors.sftp || errors.emailDelivery) && (
                <Typography color="error">{formatMessage({ id: 'task-scheduler.select-at-least-one-delivery-method' })}</Typography>
              )}
              {Reflect.has(errors, '') && (
                <Grid item md={4}>
                  <Typography component="p" variant="caption" color="error">
                    {errors[''].message}
                  </Typography>
                </Grid>
              )}
              <Box>
                <ReactHookFormMuiCheckbox
                  checkboxProps={{
                    error: errors.sftp,
                  }}
                  control={control}
                  name="sftp"
                  label={
                    <span>
                      {formatMessage({ id: 'task-scheduler.secure-ftp' })}
                      <Tooltip title="Delivered to your Data delivery user ID (from Data Delivery config)">
                        <Info fontSize="small" />
                      </Tooltip>
                    </span>
                  }
                />
              </Box>
              <Box>
                <ReactHookFormMuiCheckbox
                  control={control}
                  name="emailDelivery"
                  label={
                    <span>
                      {formatMessage({ id: 'task-scheduler.email-delivery' })}
                      <Tooltip title="Enter valid email addresses, separated by semi-colons, to which the scheduled data extract will be delivered">
                        <Info fontSize="small" />
                      </Tooltip>
                    </span>
                  }
                />
                {emailDelivery && (
                  <>
                    <ReactHookFormMuiCheckbox control={control} name="emailAdmins" label={formatMessage({ id: 'task-scheduler.email-company-admins' })} />
                    <ChipInput
                      name="mailto"
                      label={formatMessage({ id: 'task-scheduler.emails' })}
                      control={control}
                      error={errors?.mailto}
                      helperText={errors?.mailto?.message}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid container item md={7}>
            <Grid item md={10}>
              <Box my={2}>
                <Typography variant="h6" component="h6">
                  {formatMessage({ id: 'task-scheduler.task-run-date-range' })}
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item md={4}>
                    <Controller
                      control={control}
                      name="startAt"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={formatMessage({ id: 'task-scheduler.start-date' })}
                          type="date"
                          disabled={areDateControlsDisabled}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Controller
                      control={control}
                      name="endAt"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={formatMessage({ id: 'task-scheduler.end-date' })}
                          type="date"
                          disabled={areDateControlsDisabled}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box my={2}>
                <Typography component="h6" variant="h6">
                  {formatMessage({ id: 'task-scheduler.task-run-frequency' })}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={3}>
                    <Controller
                      control={control}
                      name="timeOfDay"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          disabled={areDateControlsDisabled}
                          label={formatMessage({ id: 'task-scheduler.time-of-day' })}
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 60,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Controller
                  control={control}
                  name="frequencyType"
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value={TASK_FREQUENCY_TYPES.WEEKLY}
                        control={<Radio />}
                        label={formatMessage({ id: 'task-scheduler.weekly' })}
                        disabled={forceNoFrequency}
                      />
                      <FormControlLabel
                        value={TASK_FREQUENCY_TYPES.MONTHLY}
                        control={<Radio />}
                        label={formatMessage({ id: 'task-scheduler.monthly' })}
                        disabled={forceNoFrequency}
                      />
                      <FormControlLabel
                        value={TASK_FREQUENCY_TYPES.NONE}
                        control={<Radio />}
                        label={formatMessage({ id: 'task-scheduler.none' })}
                        disabled={forceNoFrequency}
                      />
                    </RadioGroup>
                  )}
                />
                {frequencyType === TASK_FREQUENCY_TYPES.WEEKLY && (
                  <Controller
                    control={control}
                    name="daysOfWeek"
                    render={() => (
                      <WeeklyForm
                        error={!!errors.daysOfWeek}
                        value={daysOfWeek}
                        onChange={(val) => setValue('daysOfWeek', val)}
                      />
                    )}
                  />
                )}
                {frequencyType === TASK_FREQUENCY_TYPES.MONTHLY && (
                  <Controller
                    control={control}
                    name="dayOfMonth"
                    render={() => <MonthlyForm value={dayOfMonth} onChange={(val) => setValue('dayOfMonth', val)} />}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <CardActions>
          <Button size="large" color="abort" icon="clear" onClick={onCancelTask}>
            {formatMessage({ id: 'task-scheduler.cancel' })}
          </Button>
          <Button size="large" color="success" icon="save" type="submit">
            {
              isEdit
                ? formatMessage({ id: 'table-filters.update' })
                : formatMessage({ id: 'table-filters.create' })
            }
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

TaskForm.propTypes = {
  task: PropTypes.object,
  onSaveTask: PropTypes.func,
  onCancelTask: PropTypes.func,
  fetchLatestCycles: PropTypes.func,
  latestCollectionDates: PropTypes.array,
}

export default TaskForm
