import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  listItem: {
    padding: 0,
  },
  list: {
    width: '100%',
    padding: 0,
    margin: 0,
  }
}))