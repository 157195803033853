import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { useStyles } from './styles'

const Row = memo(({ disabled, row, ...restProps }) => {
  const isDisabled = disabled ? disabled(row) : false
  const classes = useStyles()

  return <Table.Row
    row={row}
    height={50}
    {...restProps}
    className={isDisabled ? classes.disabled : null}
  />
})

Row.propTypes = {
  row: PropTypes.object,
  disabled: PropTypes.func
}

Row.displayName = 'Row'

export const makeRow = (disabled) => (props) => <Row height={50} disabled={disabled} {...props} />

export default Row

