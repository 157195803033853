import { person, contact, documentDetailsCorporateStakeholders } from './common'
import { TYPE } from '../../formTypes'
import {
  positionUk,
  industrySector,
  totalEmployees,
  annualTurnoverUk,
  businessTypesUk,
  corporateDocumentTypeUk,
  identityDocTypesUk,
  addressDocTypesUk,
  authorityDocTypesUk,
  personDocumentTypeUk,
  intendedUseOfAccount,
  restrictedCountries,
  transactionCountriesUk,
  yesOrNo,
  formatValueMap,
  stakeholderTypes,
} from '../values'
import { NIUM_STAKEHOLDER_TYPE } from 'constants.js'
import { getKeyByValue } from 'utils/functions'

export const address = formatMessage => ({
  addressLine1: {
    label: formatMessage({ id: 'kyb-form.address-line-1' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  addressLine2: {
    label: formatMessage({ id: 'kyb-form.address-line-2' }),
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  city: {
    label: formatMessage({ id: 'kyb-form.city' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  country: {
    label: formatMessage({ id: 'kyb-form.country' }),
    type: TYPE.COUNTRY,
    required: true
  },
  postcode: {
    label: formatMessage({ id: 'kyb-form.postcode' }),
    type: TYPE.STRING,
    info: formatMessage({ id: 'kyb-form.expected-format-se1-5-by-there-should-be-space-between-the-two-parts-of-the-postcode' }),
    required: true,
    customErrorMessageTypes: [
      'string.pattern.base',
    ],
  },
  state: {
    label: formatMessage({ id: 'kyb-form.state' }),
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
})

export const documentDetailsCorporate = formatMessage => ({
  documentType: {
    label: formatMessage({ id: 'kyb-form.document-type' }),
    type: TYPE.SELECT,
    options: formatValueMap(corporateDocumentTypeUk, formatMessage),
    required: true,
  },
  "document.0.document": {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE,
    info: formatMessage({ id: 'kyb-form.file-size-limit-1-5-mb-accepted-formats-pdf-jpg-png' }),
    required: true,
  },
  documentNumber: {
    label: formatMessage({ id: 'kyb-form.document-number' }),
    type: TYPE.STRING,
    required: true,
    info: formatMessage({ id: 'kyb-form.e-g-incorporation-number-or-passport-etc' }),
  },
  documentIssuanceCountry: {
    label: formatMessage({ id: 'kyb-form.document-issuance-country' }),
    type: TYPE.COUNTRY,
    required: true,
  },
})

export const documentDetailsPerson = formatMessage => (docType) => {
  let typeOptions = personDocumentTypeUk
  if(docType === 'IDENTITY') {
    typeOptions = identityDocTypesUk
  }
  if(docType === 'ADDRESS') {
    typeOptions = addressDocTypesUk
  }
  if(docType === 'AUTHORITY') {
    typeOptions = authorityDocTypesUk
  }
  return {
    documentType: {
      label: formatMessage({ id: 'kyb-form.document-type' }),
      type: TYPE.SELECT,
      options: typeOptions,
      required: true,
      ...(docType === 'IDENTITY' && { defaultValue: getKeyByValue(identityDocTypesUk, identityDocTypesUk.DRIVER_LICENCE) }),
      ...(docType === 'AUTHORITY' && { defaultValue: getKeyByValue(authorityDocTypesUk, authorityDocTypesUk.POWER_OF_ATTORNEY) }),
      ...(docType === 'IDENTITY' && { info: formatMessage({ id: 'kyb-form.change-you-id-type-in-the-dropdown-if-needed-e-g-passport' }) }),
    },
    "document.0.document": {
      label: formatMessage({ id: 'kyb-form.document' }),
      type: TYPE.FILE,
      info: formatMessage({ id: 'kyb-form.file-size-limit-1-5-mb-accepted-formats-pdf-jpg-png' }),
      infoAdditional: "For passport, select the same file again",
      required: true,
      ...(docType === 'IDENTITY' && { fileCount: 2, labels: ['Front', 'Back'] }),
    },
    documentNumber: {
      label: formatMessage({ id: 'kyb-form.document-number' }),
      type: TYPE.STRING,
      required: true,
      info: formatMessage({ id: 'kyb-form.e-g-incorporation-number-or-passport-etc' })
    },
    documentIssuanceCountry: {
      label: formatMessage({ id: 'kyb-form.document-issuance-country' }),
      type: TYPE.COUNTRY,
      required: true
    },
    documentExpiryDate: {
      label: formatMessage({ id: 'kyb-form.document-expiry-date' }),
      type: TYPE.DATE,
      future: true,
      info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
    },
    documentIssuedDate: {
      label: formatMessage({ id: 'kyb-form.document-issued-date' }),
      type: TYPE.DATE,
      past: true,
      info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
    },
    documentIssuingAuthority: {
      label: formatMessage({ id: 'kyb-form.document-issuing-authority' }),
      type: TYPE.STRING,
      required: true,
      info: formatMessage({ id: 'kyb-form.e-g-dvla-hmpo' }),
    },
  }
}

export const stakeholdersFields = formatMessage => ({
  stakeholderType: {
    type: TYPE.RADIO,
    options: formatValueMap(stakeholderTypes, formatMessage),
    required: true
  },
  businessPartner: {
    title: formatMessage({ id: 'kyb-form.stakeholder-details' }),
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.CORPORATE,
    },
    fields: {
      businessName: {
        label: formatMessage({ id: 'kyb-form.business-name' }),
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: formatMessage({ id: 'kyb-form.business-registration-number' }),
        type: TYPE.STRING,
        required: true
      },
      businessEntityType: {
        label: formatMessage({ id: 'kyb-form.stakeholder-type' }),
        type: TYPE.SELECT,
        options: formatValueMap(positionUk, formatMessage),
        required: true
      },
      sharePercentage: {
        label: formatMessage({ id: 'kyb-form.share-percentage' }),
        type: TYPE.STRING,
        decimalDigits: 2,
        info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
      },
      "legalDetails.registeredCountry": {
        label: formatMessage({ id: 'kyb-form.registered-country' }),
        type: TYPE.COUNTRY,
        required: true
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporateStakeholders(formatMessage)]
      },
    }
  },
  stakeholderDetails: {
    title: formatMessage({ id: 'kyb-form.stakeholder-details' }),
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.INDIVIDUAL
    },
    fields: {
      ...person(formatMessage),
      birthCountry: {
        label: formatMessage({ id: 'kyb-form.birth-country' }),
        type: TYPE.COUNTRY,
        required: true
      },
      address: {
        title: formatMessage({ id: 'kyb-form.address-details' }),
        type: TYPE.GROUP,
        fields: address(formatMessage),
      },
      "professionalDetails.0": {
        title: formatMessage({ id: 'kyb-form.professional-details' }),
        type: TYPE.GROUP,
        fields: {
          position: {
            label: formatMessage({ id: 'kyb-form.position' }),
            type: TYPE.SELECT,
            options: formatValueMap(positionUk, formatMessage),
          },
          sharePercentage: {
            label: formatMessage({ id: 'kyb-form.share-percentage' }),
            type: TYPE.STRING,
            decimalDigits: 2,
            info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
          },
        }
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details-id-documents-must-be-a-colour-copy' }),
        type: TYPE.GROUP,
        multiple: true,
        fields: [{
          ...documentDetailsPerson(formatMessage)('IDENTITY'),
          documentIssuedDate: {
            label: formatMessage({ id: 'kyb-form.document-issued-date' }),
            info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
            type: TYPE.DATE,
            past: true,
            required: true
          },
        },
        {
          ...documentDetailsPerson(formatMessage)('ADDRESS'),
          documentIssuedDate: {
            label: formatMessage({ id: 'kyb-form.document-issued-date' }),
            info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
            type: TYPE.DATE,
            past: true,
            required: true
          },
        }]
      },
    },
  },
})

export const formStructure = formatMessage => ({
  region: {
    title: formatMessage({ id: 'kyb-form.region' }),
    type: TYPE.GROUP,
    fields: {
      region: {
        label: formatMessage({ id: 'kyb-form.region' }),
        type: TYPE.SELECT,
        options: { UK: 'UK' },
        required: true,
      }
    }
  },
  businessDetails: {
    title: formatMessage({ id: 'kyb-form.business-details' }),
    type: TYPE.GROUP,
    fields: {
      businessName: {
        label: formatMessage({ id: 'kyb-form.business-name' }),
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: formatMessage({ id: 'kyb-form.business-registration-number' }),
        type: TYPE.STRING,
        required: true,
        info: formatMessage({ id: 'kyb-form.if-you-do-not-have-a-business-registration-number-please-enter-your-unique-tax-reference-utr-or-vat-number' }),
      },
      tradeName: {
        label: formatMessage({ id: 'kyb-form.trade-name' }),
        type: TYPE.STRING,
        required: true
      },
      website: {
        label: formatMessage({ id: 'kyb-form.website' }),
        type: TYPE.STRING
      },
      businessType: {
        label: formatMessage({ id: 'kyb-form.business-type' }),
        type: TYPE.SELECT,
        options: formatValueMap(businessTypesUk, formatMessage),
        required: true
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        info: formatMessage({ id: 'kyb-form.please-upload-a-copy-of-your-certificate-of-incorporation-mandatory-for-more-information-on-required-documents-please-click' }) +
          ' <a href="https://help.travelledger.org/tl-pay-account/account-registration-process/uk-application-kyb-kyc" target="_blank">' +
          formatMessage({ id: 'kyb-form.here' }) + '</a> ' +
          formatMessage({ id: 'kyb-form.to-consult-our-wiki-page' }),
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporate(formatMessage)]
      },
      legalDetails: {
        title: formatMessage({ id: 'kyb-form.legal-registration-details' }),
        type: TYPE.GROUP,
        fields: {
          registeredDate: {
            label: formatMessage({ id: 'kyb-form.registration-date' }),
            type: TYPE.DATE,
            info: formatMessage({ id: 'kyb-form.the-date-the-business-was-originally-registered' }),
            required: true,
            past: true,
          },
          registeredCountry: {
            label: formatMessage({ id: 'kyb-form.registered-country' }),
            type: TYPE.COUNTRY,
            required: true
          },
        }
      },
      addresses: {
        title: formatMessage({ id: 'kyb-form.addresses' }),
        type: TYPE.GROUP,
        fields: {
          registeredAddress: {
            title: formatMessage({ id: 'kyb-form.registered-address' }),
            noExpand: true,
            type: TYPE.GROUP,
            fields: address(formatMessage),
          },
          isSameAddress: {
            label: formatMessage({ id: 'kyb-form.is-your-business-address-same-as-your-registered-address' }),
            type: TYPE.RADIO,
            options: formatValueMap(yesOrNo, formatMessage),
            required: true,
          },
          businessAddress: {
            title: formatMessage({ id: 'kyb-form.business-address' }),
            type: TYPE.GROUP,
            noExpand: true,
            fields: address(formatMessage),
            visibleIfFalse: 'businessDetails.addresses.isSameAddress',
          },
        },
      },
    }
  },
  stakeholders: {
    title: formatMessage({ id: 'kyb-form.stakeholders' }),
    info: formatMessage({ id: 'kyb-form.please-provide-the-following-information' }) + '<br /><ul><li>' +
      formatMessage({ id: 'kyb-form.for-sole-traders-partnerships-add-owners-details-e-g-only-one-sole-trader-needed' }) + '</li><li>' +
      formatMessage({ id: 'kyb-form.two-directors-officers-listed-on-companies-house' }) + '</li><li>' +
      formatMessage({ id: 'kyb-form.shareholders-with-more-than-25-ownership-of-the-company' }) + '</li><li>' +
      formatMessage({ id: 'kyb-form.if-you-have-a-corporate-owner-please-enter-their-stakeholder-information' }) + '</li></ul> <br />' +
      formatMessage({ id: 'kyb-form.for-more-information-on-the-required-documents-please-click' }) +
      '<a href="https://help.travelledger.org/tl-pay-account/account-registration-process/uk-application-kyb-kyc" target="_blank">' +
      formatMessage({ id: 'kyb-form.here' }) + '</a> ' +
      formatMessage({ id: 'kyb-form.to-consult-our-wiki-page' }),
    type: TYPE.GROUP,
    multiple: true,
    fields: [stakeholdersFields(formatMessage)]
  },
  applicantDetails: {
    title: formatMessage({ id: 'kyb-form.applicant-details' }),
    info: formatMessage({ id: 'kyb-form.all-clients-are-required-to-complete-this-section-the-applicant-must-be-an-authorized-representative-appointed-either-as-a-director-or-an-authorized-signatory-designated-by-the-company-s-director-or-via-power-of-attorney-to-execute-and-sign-transactions-on-behalf-of-the-company' }), 
    type: TYPE.GROUP,
    fields: {
      ...person(formatMessage),
      contactDetails: {
        title: formatMessage({ id: 'kyb-form.applicants-contact-details' }),
        type: TYPE.GROUP,
        fields: contact(formatMessage),
      },
      address: {
        title: formatMessage({ id: 'kyb-form.applicants-address-details' }),
        type: TYPE.GROUP,
        info: formatMessage({ id: 'kyb-form.the-applicant-s-address-details-is-where-you-provide-your-current-and-personal-place-of-residence-you-will-need-to-enter-your-complete-address-including-the-house-number-street-name-town-or-city-county-or-state-postal-code' }),
        fields: address(formatMessage),
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.applicants-document-details' }),
        info: formatMessage({ id: 'kyb-form.please-provide-the-following-documents-where-applicable' }) + '<br /><ul><li>' +
          formatMessage({ id: 'kyb-form.photo-identification' }) + '</li><li>' +
          formatMessage({ id: 'kyb-form.proof-of-address' }) + '</li><li>' +
          formatMessage({ id: 'kyb-form.letter-of-authority-required-only-if-the-applicant-is-not-a-director-or-stakeholder-listed-above' }) + '</li></ul> <u>' +
          formatMessage({ id: 'kyb-form.please-provide-colour-copies-of-any-proof-of-identity-documents-black-and-white-copies-are-not-accepted' }) + ' </u> <br /> <br />' +
          formatMessage({ id: 'kyb-form.for-more-information-on-the-required-documents-please-click' }) + ' <a href="https://help.travelledger.org/tl-pay-account/account-registration-process/uk-application-kyb-kyc" target="_blank">' + 
          formatMessage({ id: 'kyb-form.here' }) + '</a> ' +
          formatMessage({ id: 'kyb-form.to-consult-our-wiki-page' }),
        type: TYPE.GROUP,
        multiple: true,
        fields: [
          documentDetailsPerson(formatMessage)('IDENTITY'),
          documentDetailsPerson(formatMessage)('ADDRESS'),
          documentDetailsPerson(formatMessage)('AUTHORITY'),
        ]
      },
      "professionalDetails.0": {
        title: formatMessage({ id: 'kyb-form.applicants-professional-details' }),
        type: TYPE.GROUP,
        fields: {
          position: {
            label: formatMessage({ id: 'kyb-form.position' }),
            type: TYPE.SELECT,
            options: formatValueMap(positionUk, formatMessage),
            required: true
          },
          sharePercentage: {
            label: formatMessage({ id: 'kyb-form.share-percentage' }),
            type: TYPE.STRING,
            decimalDigits: 2,
            info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
          },
        }
      },
    }
  },
  riskAssessmentInfo: {
    title: formatMessage({ id: 'kyb-form.additional-business-information' }),
    type: TYPE.GROUP,
    fields: {
      totalEmployees: {
        label: formatMessage({ id: 'kyb-form.total-employees' }),
        type: TYPE.SELECT,
        options: formatValueMap(totalEmployees, formatMessage),
        required: true
      },
      annualTurnover: {
        label: formatMessage({ id: 'kyb-form.annual-turnover' }),
        type: TYPE.SELECT,
        options: formatValueMap(annualTurnoverUk, formatMessage),
        required: true
      },
      industrySector: {
        label: formatMessage({ id: 'kyb-form.industry-sector' }),
        type: TYPE.SELECT,
        options: formatValueMap(industrySector, formatMessage),
        required: true
      },
      intendedUseOfAccount: {
        label: formatMessage({ id: 'kyb-form.intended-use-of-account' }),
        type: TYPE.SELECT,
        options: formatValueMap(intendedUseOfAccount, formatMessage),
        required: true,
      },
      transactionCountries: {
        label: formatMessage({ id: 'kyb-form.transaction-countries-you-make-payments-to' }),
        type: TYPE.COUNTRY,
        options: formatValueMap(transactionCountriesUk, formatMessage),
        multiple: true,
        required: true,
        info: formatMessage({ id: 'kyb-form.please-select-countries-you-make-payments-to' }),
      },
      countryOfOperation: {
        label: formatMessage({ id: 'kyb-form.countries-of-operation' }),
        type: TYPE.COUNTRY,
        multiple: true,
        required: true,
        info: formatMessage({ id: 'kyb-form.please-select-countries-where-you-have-offices-or-operations' }),
      },
      restrictedCountriesInfo: {
        label: formatMessage({ id: 'kyb-form.do-you-directly-or-indirectly-offer-travel-to-any-of-the-following-countries' }),
        info: formatMessage({ id: 'kyb-form.afghanistan-belarus-burundi-central-african-republic-cuba-eritrea-guinea-bissau-iran-iraq-libya-myanmar-north-korea-russia-somalia-south-sudan-sudan-syria-or-yemen' }),
        type: TYPE.RADIO,
        options: formatValueMap(yesOrNo, formatMessage),
        required: true
      },
      restrictedCountries: {
        label: formatMessage({ id: 'kyb-form.restricted-countries' }),
        type: TYPE.COUNTRY,
        options: formatValueMap(restrictedCountries, formatMessage),
        multiple: true,
        info: formatMessage({ id: 'kyb-form.please-select-all-countries-that-apply-in-the-drop-down' }),
        visibleIfTrue: 'riskAssessmentInfo.restrictedCountriesInfo',
      },
      ofacLicencePresent: {
        label: formatMessage({ id: 'kyb-form.do-you-have-an-ofci-license-or-equivalent' }),
        type: TYPE.RADIO,
        options: formatValueMap(yesOrNo, formatMessage),
        visibleIfTrue: 'riskAssessmentInfo.restrictedCountriesInfo',
      },
    }
  },
})
