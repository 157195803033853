import { fullName } from 'utils/functions'
const FETCH_BUSINESS_TERMS = fullName('business-terms', 'FETCH_BUSINESS_TERMS')
const FETCH_BUSINESS_TERMS_SUCCESS = fullName('business-terms', 'FETCH_BUSINESS_TERMS_SUCCESS')
const FETCH_BUSINESS_TERMS_ERROR = fullName('business-terms', 'FETCH_BUSINESS_TERMS_ERROR')
const CLOSE_SNACKBAR = fullName('business-terms', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_BUSINESS_TERMS,
  FETCH_BUSINESS_TERMS_SUCCESS,
  FETCH_BUSINESS_TERMS_ERROR,
  CLOSE_SNACKBAR
}

export const fetchBusinessTerms = payload => ({
  type: FETCH_BUSINESS_TERMS,
  payload
})

const initialState = {
  businessTerms: [],
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

const businessTerms = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_TERMS_SUCCESS:
      return {
        ...state,
        businessTerms: action.payload
      }
    case FETCH_BUSINESS_TERMS_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    default:
      return state
  }
}

export default businessTerms
