import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'

const FailedPaymentCheckbox = ({ row, onToggle }) => {
  return (
    <Checkbox disabled={!row.checked} onChange={(_, checked) => onToggle(checked)} checked={row?.shouldFailPayment === true ?? false} color="primary" />
  )
}

FailedPaymentCheckbox.propTypes = {
  row: PropTypes.object,
  onToggle: PropTypes.func
}

export default FailedPaymentCheckbox
