import { put, select, take, fork, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { getLocation } from 'connected-react-router'
import { DEFAULT_SNACKBAR_TIMEOUT, ERROR_SNACKBAR_TIMEOUT } from 'constants.js'

export function* getRouterQueryParam(param) {
  const state = yield select()
  const { search } = getLocation(state)
  const cleanSearchQuery = search.replace('?', '')
  const params = new URLSearchParams(cleanSearchQuery)
  return params.get(param)
}

export function* dismissSnackBar(pattern, delayDuration = DEFAULT_SNACKBAR_TIMEOUT) {
  yield delay(delayDuration)
  yield put({
    type: pattern
  })
}

export function* dismissErrorSnackBar(pattern, delayDuration = ERROR_SNACKBAR_TIMEOUT) {
  yield delay(delayDuration)
  yield put({
    type: pattern
  })
}

export function* takeFirst(pattern, saga, ...args) {
  const task = yield fork(function* () {
    while (true) {
      const action = yield take(pattern)
      yield call(saga, ...args.concat(action))
    }
  })
  return task
}

