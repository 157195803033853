import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import { useStyles } from './styles'
import { useLocation } from 'react-router-dom'

const NavigationDrawerItem = ({
  button = true,
  Icon,
  title,
  subtitle,
  onClick,
  disabled,
  className,
  classNameActive,
  badgeContent,
  titleClasses,
  subtitleClasses,
  isSection = false,
  routes = []
}) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  return (
    <ListItem button={button} onClick={onClick} disabled={disabled} className={[className, routes.includes(pathname) && classNameActive]}>
      {Icon && <ListItemIcon className={classes.icon}><Icon /></ListItemIcon>}
      <ListItemText
        {...isSection && { className: classes.section }}
        primary={title}
        primaryTypographyProps={{ className: titleClasses }}
        secondary={subtitle}
        secondaryTypographyProps={{ className: subtitleClasses }}
      />
      {badgeContent && <Badge color="error" badgeContent={badgeContent} />}
    </ListItem>
  )
}

NavigationDrawerItem.propTypes = {
  button: PropTypes.bool,
  Icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.object,
  classNameActive: PropTypes.object,
  badgeContent: PropTypes.object,
  titleClasses: PropTypes.object,
  subtitleClasses: PropTypes.object,
  isSection: PropTypes.bool,
  routes: PropTypes.array
}

export default NavigationDrawerItem
