import * as React from 'react'
import PropTypes from 'prop-types'
import { getMessagesFormatter } from '@devexpress/dx-core'
import {
  Getter, Template, Plugin, TemplateConnector } from '@devexpress/dx-react-core'
import MTableCell from '@material-ui/core/TableCell'
import {
  tableColumnsWithEditing,
  isHeadingEditCommandsTableCell,
  isEditCommandsTableCell,
  isAddedTableRow,
  isEditTableRow
} from '@devexpress/dx-grid-core'
import {
  Table
} from '@devexpress/dx-react-grid-material-ui'
import ActionButton from '../components/ActionButtons'
import { getColumnActionWidth } from '../utils'
import { useIntl } from 'react-intl'

const pluginDependencies = [
  { name: 'EditingState' },
  { name: 'Table' }
]

const defaultMessages = {
  commitCommand: 'Save',
  cancelCommand: 'Cancel'
}

const TableActionColumn = React.memo(({
  actions: extraActions,
  commandComponent: Command,
  showEditCommand,
  disableEditCommand,
  messages
}) => {
  const getMessage = getMessagesFormatter({ ...defaultMessages, ...messages })
  const tableColumnsComputed = (
    { tableColumns }
  ) => tableColumnsWithEditing(tableColumns, getColumnActionWidth(extraActions.length + (showEditCommand ? 1 : 0)))
  const { formatMessage } = useIntl()

  return (
    <Plugin
      name="TableEditColumn"
      dependencies={pluginDependencies}
    >
      <Getter name="tableColumns" computed={tableColumnsComputed} />

      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isHeadingEditCommandsTableCell(tableRow, tableColumn)}
      >
        {(params) => (
          <TemplateConnector>
            {() => (
              <Table.Cell variant="head" {...params}>
                {formatMessage({ id: 'table.actions' })}
              </Table.Cell>
            )}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableCell"
        predicate={(
          { tableRow, tableColumn }
        ) => isEditCommandsTableCell(tableRow, tableColumn)}
      >
        {({ tableRow, style, colSpan }) => (
          <TemplateConnector>
            {(getters, actions) => {
              const isEdit = isEditTableRow(tableRow)
              const isNew = isAddedTableRow(tableRow)
              const isEditing = isEdit || isNew
              const rowIds = [ tableRow.rowId ]
              return (
                <MTableCell
                  row={tableRow.row}
                  style={style}
                  colSpan={colSpan}
                >
                  {showEditCommand && !isEditing && (
                    <Command
                      id="edit"
                      text={getMessage('editCommand')}
                      disabled={disableEditCommand?.(tableRow?.row)}
                      onExecute={() => actions.startEditRows({ rowIds })}
                    />
                  )}
                  {isEditing && (
                    <Command
                      id="commit"
                      text={getMessage('commitCommand')}
                      onExecute={() => {
                        actions.stopEditRows({ rowIds })
                        actions.commitChangedRows({ rowIds })
                      }}
                    />
                  )}
                  {isEditing && (
                    <Command
                      id="cancel"
                      text={getMessage('cancelCommand')}
                      onExecute={() => {
                        if (isNew) {
                          actions.cancelAddedRows({ rowIds })
                        } else {
                          actions.stopEditRows({ rowIds })
                          actions.cancelChangedRows({ rowIds })
                        }
                      }}
                    />
                  )}
                  {!isEditing && extraActions.map(extraAction => (
                    <ActionButton {...extraAction} rowData={tableRow?.row} key={extraAction.tooltip} />
                  ))}
                </MTableCell>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  )
})

TableActionColumn.propTypes = {
  actions: PropTypes.array,
  cellComponent: PropTypes.element,
  headerCellComponent: PropTypes.element,
  commandComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ]),
  showEditCommand: PropTypes.bool,
  disableEditCommand: PropTypes.func,
  width: PropTypes.number,
  messages: PropTypes.object
}

TableActionColumn.defaultProps = {
  actions: [],
  howAddCommand: false,
  showEditCommand: false,
  showDeleteCommand: false,
  messages: {}
}

export { TableActionColumn }
