import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router'
import Grid from '@material-ui/core/Grid'
import AuthView from 'containers/AuthView/connect'
import { GlobalSpinnerContext, GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import { API_URL, ROUTE_URL } from 'constants.js'
import { dateToReadableDateTime, utcToTimezone } from 'utils/date'
import { withConnect } from './connect'
import { get } from 'utils/api'
import DataGrid from 'components/DataGrid'
import Button from 'components/Button'
import { useIntl } from 'react-intl'

const initialFilterValues = {
  name: ''
}

const filterValuesToQueryParams = (filterValues) => {
  const filtersToSet = {}
  if (filterValues.name) {
    filtersToSet['name[contains]'] = filterValues.name
  }
  return filtersToSet
}

const BusinessTermsList = ({ location, redirectTo, currUserCompanyId, currUserCompany }) => {
  const { formatMessage } = useIntl()

  const columns = [
    { name: 'id', title: formatMessage({ id: 'business-terms.id' }) },
    { name: 'name', title: formatMessage({ id: 'business-terms.name' }), filter: true },
    {
      name: 'createdAt',
      title: formatMessage({ id: 'business-terms.created-at' }),
      type: 'datetime',
      getCellValue: row => utcToTimezone(row.createdAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.createdAt, currUserCompany.timezone))
    },
    {
      title: formatMessage({ id: 'business-terms.updated-by' }),
      name: 'updatedBy',
      getCellValue: row => row?.updatedBy?.name,
      filter: true
    },
    {
      title: formatMessage({ id: 'business-terms.updated-at' }),
      name: 'updatedAt',
      getCellValue: row => utcToTimezone(row?.updatedAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row?.updatedAt, currUserCompany.timezone)),
      filter: true,
      type: 'datetime'
    }
  ]

  const isGlobalSpinnerOn = useContext(GlobalSpinnerContext)
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)

  const [ businessTerms, setBusinessTerms ] = React.useState([])
  const [ filters, setFilters ] = React.useState(initialFilterValues)
  const queryParams = filterValuesToQueryParams(filters)

  React.useEffect(() => {
    (async () => {
      const url = `${API_URL}/business-terms`
      try {
        setGlobalSpinner(true)
        const result = await get(url, queryParams)
        setBusinessTerms(result.data)
        setGlobalSpinner(false)
      } catch (e) {
        console.error(e)
        setGlobalSpinner(false)
      }
    })()
  }, [ currUserCompanyId, filters ])

  const handleAddBusinessTerms = React.useCallback(() => {
    redirectTo(ROUTE_URL.businessTermsCreate)
  }, [])

  const handleEditBusinessTerms = React.useCallback((e, rowData) => {
    redirectTo(generatePath(ROUTE_URL.businessTermsEdit, { id: rowData.id }))
  }, [])

  return (
    <AuthView location={location} title="Business Terms">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DataGrid
            actions={[
              {
                icon: 'edit',
                tooltip: 'Edit Business Terms',
                onClick: handleEditBusinessTerms
              }
            ]}
            fullRemainingWindowHeight
            filtering={{
              defaultFilters: initialFilterValues,
              filters,
              onChange: (newValue) => {
                setFilters(newValue)
              },
              clearFilters: () => {
                setFilters(initialFilterValues)
              }
            }}
            name="business_terms_profiles"
            columns={columns}
            data={businessTerms}
            loading={isGlobalSpinnerOn}
            extra={<Button onClick={handleAddBusinessTerms} size="small" icon="add" color="primary">
              {formatMessage({ id: 'table-filters.create' })}
            </Button>}
            defaultHiddenColumns={[ 'id' ]}
          />
        </Grid>
      </Grid>
    </AuthView>
  )
}

BusinessTermsList.propTypes = {
  location: PropTypes.object,
  redirectTo: PropTypes.func,
  currUserCompanyId: PropTypes.string,
  currUserCompany: PropTypes.object
}

export default withConnect(BusinessTermsList)
