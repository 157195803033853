import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const getBusinessesTerms = async (urlParams) => {
  const url = `${API_URL}/business-terms`
  const { data } = await httpFetch(url, {
    urlParams,
    shouldThrowError: true
  })
  return data
}
