import React, {useState} from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './styles'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Link } from 'react-router-dom'
import { ROUTE_URL } from 'constants.js'
import CircularProgress from '@material-ui/core/CircularProgress';
import BackOffCountDown from 'components/BackOffCountdown'
import { useIntl } from 'react-intl'

const MFACodeAuthorizeDialogForm = ({
  open,
  setOpen,
  onConfirm,
  isLoading,
  tryAgainAt
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const {
    register,
    handleSubmit,

    formState: {
      errors,
    },
  } = useForm({ resolver: joiResolver(formSchema) })
  const [isBackOffBlocked, setIsBackOffBlocked] = useState(false)

  const onSubmit = handleSubmit((data) => {
    const { otpToken } = data
    onConfirm(otpToken)
  })

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onSubmit={() => onSubmit()}
      aria-labelledby="2fa-dialog"
    >
      <DialogContent className={classes.content}>
        <Typography variant='h5'>{formatMessage({ id: 'mfa-dialog.2-factor-authentication-required' })}</Typography>
        <Typography variant='body2'>{formatMessage({ id: 'mfa-dialog.please-enter-your-6-digit-2-fa-code-from-your-trusted-device' })}</Typography>
        <form className={classes.form} method="dialog">
          <BackOffCountDown tryAgainAt={tryAgainAt} setIsBackOffBlocked={setIsBackOffBlocked} />
          <TextField
            autoFocus
            {...register("otpToken")}
            label={formatMessage({ id: 'mfa-dialog.2-factor-authentication-code' })}
            error={errors?.otpToken}
            variant="outlined"
            inputProps={{
              maxLength: 6,
              style: { textAlign: 'center' }
            }}
            fullWidth
            disabled={isLoading || isBackOffBlocked}
          />
        </form>
        <Typography component="h1" variant="caption">
          {formatMessage({ id: 'mfa-dialog.lost-your-trusted-device' })} <Link to={ROUTE_URL.resetAuthenticator}>{formatMessage({ id: 'mfa-dialog.reset-two-factor-authentication' })}</Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="default"
          disabled={isLoading}
        >
          {formatMessage({ id: 'mfa-dialog.cancel' })}
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit()}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ?
            <CircularProgress size={20} />
            :
            formatMessage({ id: 'mfa-dialog.confirm' })
          }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MFACodeAuthorizeDialogForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  tryAgainAt: PropTypes.number
}

export default MFACodeAuthorizeDialogForm
