import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import { TextField, Typography, InputAdornment } from '@material-ui/core'
import Joi from 'joi'
import { useStyles } from './styles'

const ModalUpdateStatus = ({ isOpen, onCloseClick, cls }) => {
  const classes = useStyles()
  const modalRef = useRef()
  const [dueDateOverrideDays, setDueDateOverrideDays] = useState(0)

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.open()
    }
  }, [isOpen])

  const handleChange = ev => {
    try {
      Joi.assert(ev.target.value, Joi.number().min(0))
      setDueDateOverrideDays(ev.target.value)
    } catch (err) {
      return
    }
  }

  const update = () => {
    modalRef.current.close()
    onCloseClick(dueDateOverrideDays)
  }

  return (<>
    <Modal
      ref={modalRef}
      title="Due Date Override"
      defaultOpened={isOpen}
      actions={{
        primary: { label: 'Save', handler: () => update() },
        secondary: {
          label: 'Cancel',
          handler: () => {
            modalRef.current.close()
            onCloseClick()
          },
        },
      }}
      onClose={onCloseClick}
    >
      { cls && cls.some(cl => cl.role ==='PROCESSING_ENTITY') &&
        <Typography gutterBottom variant="body1"><span className={classes.subtitle}>Seller</span>: {cls && cls.map(({ sellerCompany }) => sellerCompany.branchCount > 0 ? `${sellerCompany?.name} (+${sellerCompany.branchCount} branches)` : sellerCompany?.name).join(', ')}</Typography>
      }
      <Typography gutterBottom variant="body1"><span className={classes.subtitle}>Buyer</span>: {cls && cls.map(({ buyerCompany }) => buyerCompany.branchCount > 0 ? `${buyerCompany?.name} (+${buyerCompany.branchCount} branches)` : buyerCompany?.name).join(', ')}</Typography>
      <Grid container justify="space-between">
        <Grid item>
          <FormControl component="fieldset">
            <TextField
              label="Number of Days"
              type="number"
              value={dueDateOverrideDays}
              onChange={handleChange}
              helperText={(<>
                <Typography variant="caption">This number of calendar days will be added to the due date of the transactions committed to this company. Example: If you commit transactions on 1 Feb 2022 and create a 30-day override, the transactions due date will become 3 March 2022</Typography>
              </>
              )}
              InputProps={{
                endAdornment: <InputAdornment position="end">Days</InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  </>)
}

ModalUpdateStatus.propTypes = {
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  cls: PropTypes.array
}

export default ModalUpdateStatus
