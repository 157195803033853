import React from 'react'
import PropTypes from 'prop-types'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'

const CLASS_NAME = 'HelperTooltip'

const HeaderSortLabel = ({ setHideSortTooltip, ...restProps }) => (
  <TableHeaderRow.SortLabel
    {...restProps}
    onMouseOver={(e) => setHideSortTooltip(e.target.classList.contains(CLASS_NAME) || e.target.parentNode.classList.contains(CLASS_NAME))}
  />
)

HeaderSortLabel.propTypes = {
  setHideSortTooltip: PropTypes.func,
}

export const makeHeaderSortLabel = (setHideSortTooltip) => (props) =>
  <HeaderSortLabel setHideSortTooltip={setHideSortTooltip} {...props} />

export default HeaderSortLabel
