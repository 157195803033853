import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useStyles } from '../styles'

const InvitesErrorAlert = ({ failedInvites = [] }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  return (
    <div className={classes.alert}>
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Invite error</AlertTitle>
          <List>
            {failedInvites.map((failedInvite) => (
              <ListItem key={failedInvite.email}>
                <ListItemText primary={`email: ${failedInvite.email} - ${failedInvite.error}`} />
              </ListItem>
            ))}
          </List>
        </Alert>
      </Collapse>
    </div>
  )
}

InvitesErrorAlert.propTypes = {
  failedInvites: PropTypes.array,
}

export default InvitesErrorAlert
