import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  formGroup: {
    marginLeft: theme.spacing(1)
  },
  formControl: {}
}))
