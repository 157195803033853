import { fullName } from 'utils/functions'

// Action Names
const FETCH_DATA_DELIVERY = fullName('data-delivery', 'FETCH_DATA_DELIVERY')
const FETCH_DATA_DELIVERY_SUCCESS = fullName('data-delivery', 'FETCH_DATA_DELIVERY_SUCCESS')
const FETCH_DATA_DELIVERY_ERROR = fullName('data-delivery', 'FETCH_DATA_DELIVERY_ERROR')
const PATCH_CONFIG_DATA_DELIVERY = fullName('data-delivery', 'PATCH_CONFIG_DATA_DELIVERY')
const PATCH_CONFIG_DATA_DELIVERY_SUCCESS = fullName('data-delivery', 'PATCH_CONFIG_DATA_DELIVERY_SUCCESS')
const PATCH_CONFIG_DATA_DELIVERY_ERROR = fullName('data-delivery', 'PATCH_CONFIG_DATA_DELIVERY_ERROR')
const CLOSE_SNACKBAR = fullName('data-delivery', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_DATA_DELIVERY,
  FETCH_DATA_DELIVERY_SUCCESS,
  FETCH_DATA_DELIVERY_ERROR,
  PATCH_CONFIG_DATA_DELIVERY,
  PATCH_CONFIG_DATA_DELIVERY_SUCCESS,
  PATCH_CONFIG_DATA_DELIVERY_ERROR,
  CLOSE_SNACKBAR
}

export const fetchDataDelivery = () => ({
  type: FETCH_DATA_DELIVERY
})

export const patchConfigDataDelivery = payload => ({
  type: PATCH_CONFIG_DATA_DELIVERY,
  payload
})

const initialState = {
  dataDelivery: [],
  companyConfig: null,
  snackbars: false,
  error: false,
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

const dataDelivery = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_DELIVERY_SUCCESS:
      return {
        ...state,
        companyConfig: action.payload.companyConfig,
        dataDelivery: action.payload.dataDelivery
      }
    case FETCH_DATA_DELIVERY_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case PATCH_CONFIG_DATA_DELIVERY_SUCCESS:
      return {
        ...state,
        companyConfig: action.payload.companyConfig,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: action.payload.snackbar
        }
      }
    case PATCH_CONFIG_DATA_DELIVERY_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    default:
      return state
  }
}
export default dataDelivery
