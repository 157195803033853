import { Typography } from '@material-ui/core'
import React from 'react'
import Button from 'components/Button'
import { useIntl } from 'react-intl'

const Introduction = ({ goToNextStep }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <Typography variant='h3' gutterBottom>
        {formatMessage({ id: 'tlpay.welcome-to-your-tl-pay-account-application' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.you-re-about-to-apply-for-a-business-bank-account-on-the-travel-ledger-platform-to-approve-your-account-we-ll-need-detailed-information-about-your-company-and-its-stakeholders' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.to-get-started-please-follow-these-steps' })}:
      </Typography>
      <Typography paragraph>
        <ol>
          <li style={{ marginBottom: '10px' }}>{formatMessage({ id: 'tlpay.visit-our-support-page' })} (<a href='https://help.travelledger.org/tl-pay-account' target='_blank' rel='noreferrer'>{formatMessage({ id: 'tlpay.click-here' })}</a>) {formatMessage({ id: 'tlpay.to-review-the-required-documents-and-information-for-your-company-and-its-stakeholders' })}</li>
          <li style={{ marginBottom: '10px' }}>{formatMessage({ id: 'tlpay.gather-all-necessary-documents-and-information' })}</li>
          <li style={{ marginBottom: '10px' }}>{formatMessage({ id: 'tlpay.now-start-your-tl-pay-account-application' })}</li>
          <li style={{ marginBottom: '10px' }}>{formatMessage({ id: 'tlpay.for-assistance-please-contact' })} <a href='mailto:support@travelledger.org'>support@travelledger.org</a></li>
        </ol>
      </Typography>
      <Button color="primary" onClick={goToNextStep}>
        {formatMessage({ id: 'tlpay.start-application' })}
      </Button>
    </>
  )
}

export default Introduction
