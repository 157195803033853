import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'
import ActionButton from './ActionButton'

const BulkActionsTopSummary = memo(({ selection, actions, bulkUpdateRows, toggleSelection }) => {
  const classes = useStyles()
  if (!actions?.length) {
    return null
  }

  const handleClick = (onClick) => {
    toggleSelection({ rowIds: selection, state: false })
    onClick(selection, bulkUpdateRows)
  }

  return (
    <Grid className={classes.root}>
      <Grid item>
        { actions.map((action, index) => (
          <ActionButton
            key={index}
            { ...action }
            onClick={() => handleClick(action.onClick)}
            selection={selection}
          />
        )) }
      </Grid>
    </Grid>
  )
})

BulkActionsTopSummary.propTypes = {
  selection: PropTypes.array.isRequired,
  bulkUpdateRows: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      validate: PropTypes.func
    })
  )
}

BulkActionsTopSummary.displayName = 'BulkActionsTopSummary'

export default BulkActionsTopSummary
