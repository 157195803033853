import { CURRENCY_CODE, CURRENCY_CODE_TO_NAME } from 'constants.js';

export const initialFilterValues = {
  status: 'ALL',
  transType: 'ALL',
  currency: [
    CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP],
    CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR],
  ],
}

export const DEFAULT_HIDDEN_COLUMNS = [
  'description',
  'optional1',
  'optional2',
  'optional3',
  'destCountry',
  'destination',
  'transProductType',
  'transPaxNo',
  'confNumber',
  'tktVchrNumber',
  'bookingSellAmount',
  'bookingComAmount',
  'bookingNettAmount',
  'bookingSellTaxAmount',
  'bookingComTaxAmount',
  'bookingNettTaxAmount',
  'buyerNotes',
  'sellerNotes',
  'flightNumbers',
  'airlineCodes',
]
