import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const ResultInfo = ({ result }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  if (!result?.summary) {
    return null
  }

  const hasErrors = !!result.summary.failed

  return (
    <>
      <Box my={1}>
        <Typography disablePadding variant="h5">
          {formatMessage({ id: 'invite-partners.import-summary' })}
        </Typography>
      </Box>
      <Box my={1}>
        <Paper>
          <Box py={1}>
            <Grid container direction="row" justify="space-around">
              <Grid item><Typography variant="subtitle1">{formatMessage({ id: 'invite-partners.total' })}: { result?.summary?.total || 0 }</Typography></Grid>
              <Grid item><Typography variant="subtitle1" color="primary">{formatMessage({ id: 'invite-partners.success' })}: { result?.summary?.success || 0 }</Typography></Grid>
              <Grid item><Typography variant="subtitle1" color="error">{formatMessage({ id: 'invite-partners.failed' })}: { result?.summary?.failed || 0 }</Typography></Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      { hasErrors && <>
        <Box mt={2} display="flex" alignItems="center">
          <WarningIcon className={classes.listIcon} color="error" fontSize="small" />
          <Typography disablePadding variant="h6">
            {formatMessage({ id: 'invite-partners.errors-found' })}
          </Typography>
        </Box>
        {result?.resultByFile.map(({ errors }) =>
          errors?.length && errors?.map((error, id) => (
            <Grid container key={id}>
              <Grid item md={1}>
                <Box py={0.9}>
                  <Typography variant="subtitle2">
                    {formatMessage({ id: 'invite-partners.line' })} {error?.rowIndex}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={11}>
                <List disablePadding>
                  {
                    error.errors.map((_error, errorIndex) => (
                      <ListItem key={`error_${errorIndex}`} className={classes.nestedLevel2} dense>
                        <ListItemText primary={_error} />
                      </ListItem>
                    ))
                  }
                </List>
              </Grid>
            </Grid>
          ))
        )}
      </>}
    </>
  )
}

ResultInfo.propTypes = {
  result: PropTypes.object
}

export default ResultInfo
