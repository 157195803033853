const csvFields = formatMessage => [
  {
    title: formatMessage({ id: 'csv-uploader.seller-id-type' }),
    notes: formatMessage({ id: 'csv-uploader.mandatory' }),
    format: formatMessage({ id: 'csv-uploader.accepted-values-abta-iata-tids' }),
  },
  {
    title: formatMessage({ id: 'csv-uploader.seller-id' }),
    notes: formatMessage({ id: 'csv-uploader.mandatory' }),
    format: formatMessage({ id: 'csv-uploader.if-id-type-1-abta-5-chars-alphanumeric-if-iata-or-tids-9-12-chars-alphanumeric' }),
  },
  {
    title: formatMessage({ id: 'csv-uploader.buyer-id-type' }),
    notes: formatMessage({ id: 'csv-uploader.mandatory' }),
    format: formatMessage({ id: 'csv-uploader.accepted-values-abta-iata-tids' }),
  },
  {
    title: formatMessage({ id: 'csv-uploader.buyer-id' }),
    notes: formatMessage({ id: 'csv-uploader.mandatory' }),
    format: formatMessage({ id: 'csv-uploader.if-id-type-3-abta-5-chars-alphanumeric-if-iata-or-tids-9-12-chars-alphanumeric' }),
  },
  {
    title: formatMessage({ id: 'csv-uploader.business-terms-name' }),
    notes: formatMessage({ id: 'csv-uploader.mandatory' }),
    format: ''
  },
  {
    title: formatMessage({ id: 'csv-uploader.settlement-schedule-name' }),
    notes: formatMessage({ id: 'csv-uploader.mandatory' }),
    format: ''
  }
]
export default csvFields