
import { EWALLET_PROVIDER } from 'constants.js'
import { 
  formStructure as formStructure_NIUMEU, 
  stakeholdersFields as stakeholdersFields_NIUMEU, 
  documentDetailsCorporate as documentDetailsCorporate_NIUMEU, 
  documentDetailsPerson as documentDetailsPerson_NIUMEU
} from './nium/schemas/formSchemaEU'
import { 
  formStructure as formStructure_NIUMUK, 
  stakeholdersFields as stakeholdersFields_NIUMUK, 
  documentDetailsCorporate as documentDetailsCorporate_NIUMUK, 
  documentDetailsPerson as documentDetailsPerson_NIUMUK,
} from './nium/schemas/formSchemaUK'
import { documentDetailsCorporateStakeholders } from './nium/schemas/common'
import { allDocumentTypesUk, allDocumentTypesEu, formatValueMap } from './nium/values'

export const FormStructureProviderMap = {
  [EWALLET_PROVIDER.NIUM_UK]: formatMessage => ({
    formStructure: formStructure_NIUMUK(formatMessage),
    stakeholdersFields: stakeholdersFields_NIUMUK(formatMessage),
    documentDetailsCorporate: documentDetailsCorporate_NIUMUK(formatMessage),
    documentDetailsPerson: documentDetailsPerson_NIUMUK(formatMessage),
    documentDetailsCorporateStakeholders: documentDetailsCorporateStakeholders(formatMessage),
    allDocumentTypes: formatValueMap(allDocumentTypesUk, formatMessage),
  }),
  [EWALLET_PROVIDER.NIUM_EU]: formatMessage => ({
    formStructure: formStructure_NIUMEU(formatMessage),
    stakeholdersFields: stakeholdersFields_NIUMEU(formatMessage),
    documentDetailsCorporate: documentDetailsCorporate_NIUMEU(formatMessage),
    documentDetailsPerson: documentDetailsPerson_NIUMEU(formatMessage),
    documentDetailsCorporateStakeholders: documentDetailsCorporateStakeholders(formatMessage),
    allDocumentTypes: formatValueMap(allDocumentTypesEu, formatMessage),
  }),
}
