import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  TableFooter,
  Tooltip
} from '@material-ui/core'
import { HighlightOff } from '@material-ui/icons'
import { makeAmountCell } from './utils'
import { useStyles } from '../styles'
import { getFormattedAmount } from 'utils/functions'

const getSum = (data, field) => data.reduce((prev, current) => prev + current[field], 0)

export const BookingSummary = ({ bookingData, deselectInvoice }) => {
  const classes = useStyles()
  if (isEmpty(bookingData)) {
    return null
  }

  return (
    <Box width="100%">
      <Typography gutterBottom variant="h5">
        Booking Summary
      </Typography>
      <TableContainer component={Paper} className={classes.summaryTableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.summaryTableHeaderRow}>
              <TableCell />
              <TableCell>
                Lead name / Departure date
              </TableCell>
              <TableCell>
                Booking ref
              </TableCell>
              <TableCell>
                Commercial link
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell align="right">
                Original amount
              </TableCell>
              <TableCell align="right">
                Current amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingData.map(d => (
              <TableRow key={d.id}>
                <TableCell>
                  <Tooltip title="Remove transaction">
                    <IconButton size="small" onClick={() => deselectInvoice?.(d.id)}>
                      <HighlightOff />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {d.leadName} / {d.departureDate}
                </TableCell>
                <TableCell>
                  {d.bookingRef}
                </TableCell>
                <TableCell>
                  {d.commercialLink?.description}
                </TableCell>
                <TableCell>
                  {d.status}
                </TableCell>
                <TableCell align="right">
                  {makeAmountCell('originalAmountInCents')(d)} {d.currency}
                </TableCell>
                <TableCell align="right">
                  {makeAmountCell('currentAmountInCents')(d)} {d.currency}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter >
            <TableRow>
              <TableCell colSpan={5}>
                Total
              </TableCell>
              <TableCell align="right">
                {getFormattedAmount(getSum(bookingData, 'originalAmountInCents'))} {bookingData?.[0]?.currency}
              </TableCell>
              <TableCell align="right">
                {getFormattedAmount(getSum(bookingData, 'currentAmountInCents'))} {bookingData?.[0]?.currency}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

BookingSummary.propTypes = {
  bookingData: PropTypes.array.isRequired,
  deselectInvoice: PropTypes.func.isRequired
}
