import React, { useState, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Checkbox, FormControlLabel, Grid, Link, TextField, Typography } from '@material-ui/core'
import ReactHookFormMuiPasswordField from 'components/ReactHookFormMuiPasswordField'
import { useStyles } from '../styles'

const TermsAndConditions = lazy(() => import('./TermsAndConditions'))

const UserForm = ({ form, inviteDetails, setTerms, isCompanyInvite }) => {
  const classes = useStyles()
  const [TCPopupVisible, setTCPopupVisible] = useState(false)

  const handleTCConfirm = (value) => {
    setTCPopupVisible(false)
    setTerms(value)
  }

  const {
    control,
    formState: { errors },
  } = form

  return (
    <>
      <Grid item xs={12}>
        <Typography component="h1" variant="subtitle2">
          User details
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                className={classes.input}
                disabled={!isCompanyInvite}
                fullWidth
                error={errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Role"
            name="role"
            variant="outlined"
            // Added fallback ADMIN role if the role is not defined, this case happens on company registration
            value={isCompanyInvite ? 'ADMIN' : inviteDetails?.role}
            disabled
            className={classes.input}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                className={classes.input}
                fullWidth
                error={errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} style={{ marginTop: '16px' }}>
        <Grid item xs={12}>
          <ReactHookFormMuiPasswordField
            required={false}
            form={form}
            name="password"
            variant="outlined"
            nameConfirm="confirmPassword"
          />
        </Grid>

        {isCompanyInvite && (
          <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
            <Controller
              name="acceptTos"
              control={control}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // eslint-disable-next-line react/prop-types
                        checked={field.value}
                        color="primary"
                        control={control}
                        disableRipple
                        name="acceptTos"
                        onClick={() => setTCPopupVisible(true)}
                      />
                    }
                    label={
                      <>
                        I agree to <span style={{ textDecoration: 'underline' }}>Terms and conditions</span>
                      </>
                    }
                    labelPlacement="end"
                    value="end"
                  />
                  {errors.acceptTos && <Typography color="error">You have to agree to terms and conditions</Typography>}
                </>
              )}
            />
            <Link
              href="https://www.travelledger.org/en/pricing/"
              target="_blank"
              rel="noopener"
              underline="always"
              className={classes.feeScheduleLink}
            >
              Travel Ledger Fee Schedule
            </Link>
          </Grid>
        )}
      </Grid>
    
      {TCPopupVisible && (
        <Suspense fallback={<div>Loading...</div>}>
          <TermsAndConditions onAccept={() => handleTCConfirm(true)} onDeny={() => handleTCConfirm(false)} />
        </Suspense>
      )}
    </>
  )
}

UserForm.propTypes = {
  form: PropTypes.object.isRequired,
  inviteDetails: PropTypes.object,
  setTerms: PropTypes.func,
  isCompanyInvite: PropTypes.bool,
}

export default UserForm
