import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import styles from '../Snackbar/styles.module.css'

const SnackbarComponent = ({ status, error, text, action, push }) => {

  const [ snackbar ] = useState({
    vertical: 'top',
    horizontal: 'right'
  })
  const { vertical, horizontal } = snackbar
  const styleSnackBar = error ? styles.SnackbarErrorBlock : styles.SnackbarBlock
  const textSnackBar = error ? (text ? `Error: ${text}` : 'Error') : text
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      className={styleSnackBar}
      key={`${vertical},${horizontal}`}
      open={status}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message={<span id="message-id">{textSnackBar}</span>}
      {...action && { action: <Button color="secondary" onClick={() => push(action.snackbarActionRoute)}>{action.snackbarActionText}</Button>}}
    />
  )
}
SnackbarComponent.propTypes = {
  status: PropTypes.bool,
  error: PropTypes.bool,
  text: PropTypes.string
}
export default SnackbarComponent
