import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import { useQuery } from 'react-query'
import { Redirect } from 'react-router'
import { get } from 'utils/api'
import { BASE_URL } from 'constants.js'

const MaintenaceMode = () => {
  const classes = useStyles()
  const { data } = useQuery(`api-base-url`, () => get(`${BASE_URL}/health`), {
    retry: false,
  })

  if (data && !data.maintenance) {
    return <Redirect to="/" />
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" gutterBottom>
          System Maintenance
        </Typography>
        <Typography>We are currently undergoing planned maintenance of the platform. Please return later.</Typography>
      </div>
    </Container>
  )
}

export default MaintenaceMode
