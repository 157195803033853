import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import AuthView from '../AuthView'
import FormSelect from 'components/FormSelect'
import { useStyles } from './styles'
import { useForm, Controller } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import SnackbarComponent from 'components/Snackbar'
import Button from 'components/Button'
import { useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'

const roles = {
  ADMIN: 'ADMIN',
  NORMAL: 'NORMAL',
}

const Invite = ({ snackbar, createInvite }) => {
  const location = useLocation()
  const { company: selectedCompany } = location.state || {}
  const { register, handleSubmit, formState: { errors }, control } = useForm({
    resolver: joiResolver(formSchema),
    defaultValues: { role: roles.NORMAL },
  })
  const { formatMessage } = useIntl()

  const onSubmit = handleSubmit((data) => {
    createInvite({ ...data, companyId: selectedCompany?.id })
  })

  const classes = useStyles()
  return (
    <AuthView location={location}>
      <Paper className={classes.root}>
        <SnackbarComponent status={snackbar?.isOpened} error={snackbar?.isError} text={snackbar?.successOrErrorText} />
        <form className={classes.form} onSubmit={onSubmit}>
          <Typography component="h1" variant="h5">
            {formatMessage({ id: 'invite-users.invite-user-to' })} {selectedCompany?.name}
          </Typography>
          <TextField
            label={formatMessage({ id: 'invite-users.name' })}
            {...register("name")}
            helperText={errors?.name?.message}
            error={!!errors.name}
          />
          <TextField
            label={formatMessage({ id: 'invite-users.email' })}
            type="email"
            {...register("email")}
            helperText={errors?.email?.message}
            error={!!errors.email}
          />
          <Controller
            name="role"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormSelect
                name="role"
                label={formatMessage({ id: 'invite-users.type' })}
                keyTitle={Object.fromEntries(
                  Object.entries(roles)
                    .map(([k, v]) => [k, formatMessage({ id: `user-roles.${kebabCase(v)}` })])
                )}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={errors?.role?.message}
              />
            )}
          />
          <Button className={classes.fab} color="primary" type="submit" icon="send">
            {formatMessage({ id: 'invite-users.send-invite' })}
          </Button>
        </form>
      </Paper>
    </AuthView>
  )
}

Invite.propTypes = {
  location: PropTypes.object,
  snackbar: PropTypes.object,
  createInvite: PropTypes.func,
}

export default Invite
