import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import BoldTagParser from 'components/BoldTagParser'

const Logs = ({ logs }) => {
  const classes = useStyles()
  return logs?.map((log, index) => {
    return (
      <div key={index} className={classes.logMessage}>
        <BoldTagParser text={log} />
      </div>
    )
  })
}

Logs.propTypes = {
  logs: PropTypes.array
}

export default Logs
