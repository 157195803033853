import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { API_URL, USER_AUTH_STORAGE_KEYS } from 'constants.js'
import { ACTIONS } from 'store/companies'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { patch, get } from 'utils/api'
import { performFetchCompanies } from '../requests'
import { localStorage } from 'utils/storage'
import { dismissErrorSnackBar, dismissSnackBar } from 'utils/generators'

export function* fetchCompanies() {
  const { FETCH_COMPANIES_SUCCESS } = ACTIONS
  try {
    const { data } = yield call(performFetchCompanies)
    yield put({ type: FETCH_COMPANIES_SUCCESS, payload: data })
  } catch (e) {
    console.error(e)
  }
}

export function* fetchCurrUserCompany() {
  const companyId = localStorage.getItem(USER_AUTH_STORAGE_KEYS.companyId)
  if (companyId) {
    const url = `${API_URL}/companies/${companyId}`
    try {
      const { FETCH_CURR_USER_COMPANY_SUCCESS } = ACTIONS
      const response = yield call(
        get,
        url
      )
      yield put({
        type: FETCH_CURR_USER_COMPANY_SUCCESS,
        payload: {
          id: response.data.id,
          ...response.data.attributes
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export function* patchCurrUserCompany(actions) {
  const actionsCompany = actions.data
  const { id } = actionsCompany
  delete actionsCompany.id
  const { PATCH_CURR_USER_COMPANY_SUCCESS, PATCH_CURR_USER_COMPANY_ERROR, CLOSE_SNACKBAR } = ACTIONS
  try {
    const company = yield call(
      patch,
      `${API_URL}/companies/${id}`,
      actionsCompany
    )
    company.data.attributes.id = company.data.id
    yield put({
      type: PATCH_CURR_USER_COMPANY_SUCCESS,
      payload: company
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({ type: PATCH_CURR_USER_COMPANY_ERROR, payload: `${e.data.errors[0].detail}` })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* clearCurrUserCompany() {
  yield put({
    type: ACTIONS.CLEAR_CURR_USER_COMPANY
  })
}

export function* searchCompanies(actions) {
  const url = `${API_URL}/companies/search`
  try {
    const { SEARCH_COMPANIES_SUCCESS } = ACTIONS
    const result = yield call(
      get,
      url,
      actions.payload
    )
    yield put({
      type: SEARCH_COMPANIES_SUCCESS,
      payload: result
    })
  } catch (e) {
    console.error(e)
  }
}

export default function* rootSaga() {
  const { LOGOUT } = AUTH_ACTIONS
  const { FETCH_COMPANIES, FETCH_CURR_USER_COMPANY, PATCH_CURR_USER_COMPANY, SEARCH_COMPANIES } = ACTIONS
  yield all([
    takeLatest(FETCH_COMPANIES, fetchCompanies),
    takeLatest(FETCH_CURR_USER_COMPANY, fetchCurrUserCompany),
    takeLatest(PATCH_CURR_USER_COMPANY, patchCurrUserCompany),
    takeLatest(LOGOUT, clearCurrUserCompany),
    takeLatest(SEARCH_COMPANIES, searchCompanies)
  ])
}
