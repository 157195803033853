import { connect } from 'react-redux'
import Component from './ForgotPassword'
import { replace } from 'connected-react-router'

export const mapState = ({
  auth
}) => {
  return {
    ...auth
  }
}

export const mapDispatch = dispatch => ({
  replace: data => dispatch(replace(data))
})

export default connect(mapState, mapDispatch)(Component)
