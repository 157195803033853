export const columnsInvite = [
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'to',
    title: 'Email',
  },
  {
    name: 'inviterName',
    title: 'Inviter',
    getCellValue: (row) => `${row?.inviterName} (${row?.inviterEmail})`,
  },
  {
    name: 'inviteType',
    title: 'Invite type',
  },
  {
    name: 'inviteStatus',
    title: 'status',
  },
]
