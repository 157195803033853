import React from 'react'
import PropTypes from 'prop-types'
import { EditButton, CancelButton, SaveButton } from '../components/ActionButtons'

const commandComponents = {
  edit: EditButton,
  commit: SaveButton,
  cancel: CancelButton
}

const Command = ({ id, onExecute, disabled }) => {
  const CommandButton = commandComponents[id]
  return (
    <CommandButton
      disabled={disabled}
      onExecute={onExecute}
    />
  )
}

Command.propTypes = {
  id: PropTypes.string.isRequired,
  onExecute: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default Command
