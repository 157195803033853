import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Cell from './Cell'
import moment from 'moment'
import { getCalendarCellEventType } from '../../utils'

const TimeTableCellCalendar = (props) => {
  const { onDayClick, currentEventsMap, startDate, otherMonth, timezone } = props
  // startdate is coming from the browser 
  // time is set as midnight but it has the local timezone attached
  // extract only the date part, then convert it in a date using the company timezone
  const onlyDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`
  const dayDate = moment.tz(onlyDate, timezone)
  
  const dayEvents = currentEventsMap[
    dayDate.format('YYYY-MM-DD')
  ] ?? []

  const dayType = getCalendarCellEventType({
    dayEvents, otherMonth, dayDate,
  })

  const handleClick = clickEvent => onDayClick(
    clickEvent,
    { date: dayDate, type: dayType },
  )

  return <Cell
    {...props}
    dayDate={dayDate}
    dayEvents={dayEvents}
    dayType={dayType}
    onClick={handleClick}
  />
}

TimeTableCellCalendar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  otherMonth: PropTypes.bool,
  onDayClick: PropTypes.func,
  currentEventsMap: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
}

export const MemoTimeTableCellCalendar = memo(TimeTableCellCalendar)

const makeTimeTableCellCalendar = (extraProps) => (props) => <MemoTimeTableCellCalendar {...props} {...extraProps} />

export default makeTimeTableCellCalendar
