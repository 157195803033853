import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import getId from 'uuid/v4'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import AuthView from 'containers/AuthView/connect'
import SnackbarComponent from 'components/Snackbar'
import FormInputDate from 'components/FormInputDate'
import { MaterialAutocomplete } from 'components/MaterialAutocomplete'
import Button from 'components/Button'
import { ErrorsForm } from 'containers/SentTransactions/components/ErrorsForm'
import { TransactionLines } from 'containers/SentTransactions/components/TransactionLines'
import { useStyles } from './styles'
import { withConnect } from './connect'
import { ROUTE_URL } from 'constants.js'
import { useIntl } from 'react-intl'

const TRANSACTION_ADD = 'TRANSACTION_ADD'
const TRANSACTION_EDIT = 'TRANSACTION_EDIT'
const TRANSACTION_REMOVE = 'TRANSACTION_REMOVE'

const initialTransactionLine = {
  amount: '',
  dueDate: '',
  type: 'BALANCE'
}

const initialState = {
  partner: {},
  bookingRef: '',
  leadName: '',
  description: '',
  departureDate: '',
  transactionLines: [ { ...initialTransactionLine, id: getId() } ]
}

const reducer = (state, action) => {
  switch (action.type) {
    case TRANSACTION_ADD:
      return {
        ...state,
        transactionLines: [
          ...state.transactionLines,
          { ...initialTransactionLine, id: getId() }
        ]
      }
    case TRANSACTION_EDIT: {
      const { formId, fieldName, fieldValue } = action.payload

      const lines = state.transactionLines.map(obj => {
        if (obj.id === formId) {
          // eslint-disable-next-line no-param-reassign
          obj[fieldName] = fieldValue
        }
        return obj
      })

      return {
        ...state,
        transactionLines: [
          ...lines
        ]
      }
    }
    case TRANSACTION_REMOVE:
      return {
        ...state,
        transactionLines: [
          ...state.transactionLines.filter(obj => obj.id !== action.payload)
        ]
      }
    case action.type:
      return {
        ...state,
        [action.type]: action.payload
      }
    default:
      return state
  }
}

const usePartnerSearchAutocomplete = (dispatch, searchCompanies, clearSearchCompaniesData) => {
  const searchHandler = _.throttle((text) => {
    searchCompanies({ q: text })
  }, 500, { trailing: true, leading: true })

  const inputHandler = React.useCallback(({ target }) => {
    searchHandler(target.value)
  })

  const handleSelectPartner = React.useCallback(partnerObj => {
    dispatch({ type: 'partner', payload: partnerObj })
    clearSearchCompaniesData()
  }, [])

  return {
    inputHandler,
    handleSelectPartner
  }
}

// eslint-disable-next-line no-shadow
const CreateBooking = ({
  currUserCompanyId,
  createInvoice,
  companies,
  searchCompanies,
  clearSearchCompaniesData,
  redirectTo,
  location,
  invoicesSnackbar
}) => {
  const classes = useStyles()
  const textFieldProps = { fullWidth: true, className: classes.formInput }
  const { formatMessage } = useIntl()

  const [ {
    partner,
    bookingRef,
    leadName,
    description,
    departureDate,
    transactionLines
  }, dispatch ] = React.useReducer(reducer, initialState)
  const [ errors, setErrors ] = React.useState([])
  const { inputHandler, handleSelectPartner } = usePartnerSearchAutocomplete(dispatch, searchCompanies, clearSearchCompaniesData)

  const handleDispatchValue = React.useCallback(({ target }) => {
    dispatch({ type: target.name, payload: target.value })
  }, [])

  const handleDispatchTransactionValue = React.useCallback(({ target }) => {
    const [ fieldName, formId ] = target.name.split('_')

    dispatch({
      type: TRANSACTION_EDIT,
      payload: {
        formId,
        fieldName,
        fieldValue: target.value
      }
    })
  }, [])

  const handleAddTransactionLine = React.useCallback(() => {
    dispatch({ type: TRANSACTION_ADD })
  }, [])

  const handleRemoveTransactionLine = React.useCallback(id => {
    dispatch({ type: TRANSACTION_REMOVE, payload: id })
  }, [])

  const onSubmit = () => {
    const errorsArr = []

    if (!partner.id) {
      errorsArr.push('Please select a partner for this booking.')
    }
    if (!bookingRef) {
      errorsArr.push('Please fill out your booking reference.')
    }
    if (!leadName) {
      errorsArr.push('Please fill out the lead name for this booking.')
    }
    if (!transactionLines[0].dueDate && !transactionLines[0].amount) {
      errorsArr.push('Please fill out at least one transaction line for this booking.')
    }
    if (description && description.length > 255) {
      errorsArr.push('Description cannot be longer than 255 characters.')
    }
    if (!departureDate) {
      errorsArr.push('Please select a departure date for this booking.')
    }

    for (const line of transactionLines) {
      if (line.amount && !line.dueDate) {
        errorsArr.push('Missing due date in a partially filled transaction line.')
      }
      if (!line.amount && line.dueDate) {
        errorsArr.push('Missing amount in a partially filled transaction line.')
      }
    }

    setErrors(errorsArr)

    if (errorsArr.length) {
      return
    }

    const formData = {
      bookingRef,
      leadName,
      description,
      departureDate,
      targetId: partner.id,
      sourceId: currUserCompanyId,
      scheduleEntries: [
        ...transactionLines.map(x => ({ ...x, amount: Number(x.amount) * 100 })).filter(x => x.dueDate && x.amount)
      ]
    }
    createInvoice(formData)
    redirectTo(ROUTE_URL.bookingsReview)
  }

  const onCancel = React.useCallback(() => {
    redirectTo(ROUTE_URL.bookingsReview)
  }, [])

  return (
    <AuthView location={location} title="Create booking">
      <Grid container direction="row">
        <Grid container item sm={12} md={6}>
          <form autoComplete="off" className={classes.form}>
            <FormControl {...textFieldProps}>
              <MaterialAutocomplete
                label="Partner *"
                placeholder="Type a partner name"
                options={companies}
                getOptionLabel={option => option.name}
                renderOptionKeys={[ 'name', 'abtaNumber', 'city' ]}
                onSelect={handleSelectPartner}
                onInputChange={inputHandler}
              />
            </FormControl>
            <TextField
              {...textFieldProps}
              name="bookingRef"
              label="Booking Ref"
              value={bookingRef}
              onChange={handleDispatchValue}
              required
            />
            <TextField
              {...textFieldProps}
              name="leadName"
              label="Lead Name"
              value={leadName}
              onChange={handleDispatchValue}
              required
            />
            <TextField
              {...textFieldProps}
              name="description"
              label="Description"
              value={description}
              onChange={handleDispatchValue}
            />
            <FormInputDate
              {...textFieldProps}
              name="departureDate"
              label="Departure Date"
              value={departureDate}
              onChange={handleDispatchValue}
              required
            />
          </form>
        </Grid>
      </Grid>
      <Grid container item sm={12} md={6}>
        <TransactionLines
          transactionLines={transactionLines}
          onDispatchValue={handleDispatchTransactionValue}
          onAddTransactionLine={handleAddTransactionLine}
          onRemoveTransactionLine={handleRemoveTransactionLine}
        />
      </Grid>
      <ErrorsForm errors={errors}></ErrorsForm>
      <Grid className={classes.buttonSpacing} container justify="flex-start">
        <Button onClick={onCancel} marginRight icon="clear" color="abort">Cancel</Button>
        <Button onClick={onSubmit} color="success" icon="add">
          {formatMessage({ id: 'table-filters.create' })}
        </Button>
      </Grid>
      <SnackbarComponent
        status={invoicesSnackbar.isOpened}
        error={invoicesSnackbar.isError}
        text={invoicesSnackbar.successOrErrorText}
      />
    </AuthView>
  )
}

CreateBooking.propTypes = {
  currUserCompanyId: PropTypes.string,
  invoicesSnackbar: PropTypes.object,
  location: PropTypes.object,
  redirectTo: PropTypes.func,
  createInvoice: PropTypes.func,
  companies: PropTypes.array,
  searchCompanies: PropTypes.func,
  links: PropTypes.array,
  clearSearchCompaniesData: PropTypes.func
}

export default withConnect(CreateBooking)
