import config from 'store'
import qs from 'qs'
import selectors from 'store/auth/selector'
import { localStorage } from 'utils/storage'
import { USER_AUTH_STORAGE_KEYS, ROUTE_URL, REQUEST_TIMEOUT } from 'constants.js'

const errorDictionary = {
  'companies_abta_number_unique': 'Abta number is already taken by another company',
  'commercial_links_two_parties_unique': 'Commercial link between this buyer/seller already exists',
  'commercial_links_three_parties_unique': 'Commercial link between this buyer/seller/processing entity already exists'
}
// Workaround until errors are thrown as 500
export const transformError500Message = (errorMessage) => {
  const errorKey = Object.keys(errorDictionary).find(error => errorMessage.includes(error))
  if (errorKey) {
    return errorDictionary[errorKey]
  }
  return errorMessage
}

const httpFetch = async (targetUrl, opts = {}) => {
  let url
  const { urlParams } = opts.urlParams ? opts : []
  const { getText, shouldThrowError, customErrorHandler } = opts
  const { getItem } = localStorage
  const { store } = config
  const token = selectors.getToken(store.getState()) || getItem(USER_AUTH_STORAGE_KEYS.token)
  // eslint-disable-next-line no-param-reassign
  delete opts.urlParams
  const intialContentType = opts?.body instanceof FormData ? {} : { 'Content-Type': 'application/json' }
  const options = {
    timeout: REQUEST_TIMEOUT,
    ...opts,
    headers: {
      ...intialContentType,
      ...opts.headers
    },
    mode: 'cors'
  }

  url = targetUrl
  if (!opts.noToken) {
    options.headers['Authorization'] = `Bearer ${token}`
  }

  url += qs.stringify(urlParams, { encode: false, addQueryPrefix: true })

  const method = getText ? 'text' : 'json'

  try {
    const result = await fetch(url, options)
    // no content
    if (result.status === 204) {
      return null
    }
    if (![ 200, 201 ].includes(result.status)) {
      if (result.status === 503) {
        Object.values(USER_AUTH_STORAGE_KEYS).forEach(item => localStorage.removeItem(item))
        window.location.href = ROUTE_URL.maintenanceMode
        return
      }
      if (result.status === 401) {
        Object.values(USER_AUTH_STORAGE_KEYS).forEach(item => localStorage.removeItem(item))
        window.location.href = `${ROUTE_URL.login}?sessionExpired=true`
        return
      }
      if (customErrorHandler) {
        const responseData = await result.json()
        return customErrorHandler({ status: result.status, data: responseData })
      }
      if (shouldThrowError) {
        if (result.status === 500) {
          let errorMessage
          try {
            const responseData = await result.json()
            errorMessage = transformError500Message(responseData?.errors[0]?.detail)
          } catch (error) {
            errorMessage = 'Unknown error, try again later'
          }
          throw new Error(errorMessage)
        }
        const responseData = await result.json()
        if (Reflect.has(responseData, 'errors') && Array.isArray(responseData.errors)) {
          throw new Error(responseData.errors[0].detail)
        }
      }
      const httpFetchError = {
        success: false, error: true, message: result.statusText || ''
      }
      try {
        const errorResult = await (result)[method]()
        if (typeof errorResult === 'string') {
          httpFetchError.extraMessage = errorResult
        } else {
          httpFetchError.extraMessage = errorResult.error
        }
      } catch (error) {
        console.error(error)
      }
      return httpFetchError
    }
    return await (result)[method]()
  } catch (error) {
    if (shouldThrowError) {
      throw error
    }
    console.error(error)
    return null
  }
}

export default httpFetch
