import { all, put, call } from 'redux-saga/effects'
import FileSaver from 'file-saver'
import { takeFirst } from 'utils/generators'
import httpFetch from 'utils/httpFetch'
import { API_URL } from 'constants.js'
import { ACTIONS } from 'store/csv'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { performPostCSV } from '../requests'
import { patchInvoices } from 'services/invoices'

// Functions
export function* putError(error) {
  yield put({
    type: AUTH_ACTIONS.SET_ERROR,
    payload: error,
  })
  yield put({
    type: AUTH_ACTIONS.SET_ERROR,
    payload: false,
  })
}

export const performGetCSV = async (csvFilters) => {
  const usedCsvFilters = { ...csvFilters }
  if (isNaN(usedCsvFilters.limit)) {
    delete usedCsvFilters.limit
  }
  if (usedCsvFilters.search === undefined) {
    delete usedCsvFilters.search
  }
  if (usedCsvFilters.after) {
    usedCsvFilters.after = usedCsvFilters.after.format()
  }
  if (usedCsvFilters.before) {
    usedCsvFilters.before = usedCsvFilters.before.format()
  }
  const url = `${API_URL}/transactionsCSV`
  const result = httpFetch(url, { getText: true, urlParams: usedCsvFilters })
  return result
}

// Sagas
export function* uploadCSV(action) {
  try {
    const { autoCommit, files, force } = action.payload
    let isREC = false
    files.forEach(file => {
      if (file.name.toLowerCase().startsWith('rec')) {
        isREC = true
      }
    })

    if (isREC) {
      const body = new FormData()
      files.forEach((file, index) => {
        body.append(`csv_file_${index}`, file)
      })
      yield call(patchInvoices, { body })
    } else {
      yield call(performPostCSV, { autoCommit, files, force })
    }
    yield put({
      type: ACTIONS.SET_UPLOAD_CSV_SUCCESS
    })
  } catch (error) {
    const isDuplicateFileError = error?.data[0]?.code === 'SPS11'
    if (isDuplicateFileError) {
      yield put({
        type: ACTIONS.SET_UPLOAD_CSV_ERROR,
        payload: error,
      })
    } else {
      yield put({
        type: ACTIONS.SET_UPLOAD_CSV_SUCCESS
      })
    }
  }
}

export function* downloadCSV(action) {
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: true,
  })
  try {
    const downloadResult = yield call(performGetCSV, action.payload)
    if (!downloadResult.error) {
      FileSaver.saveAs(new File([downloadResult], 'report.csv', { type: 'text/csv' }))
    } else {
      yield call(putError, `${downloadResult.message} ${downloadResult.extraMessage || ''}`)
    }
  } catch (error) {
    console.error(error)
    yield call(putError, error)
  }
  yield put({
    type: AUTH_ACTIONS.SET_FETCHING,
    payload: false,
  })
}

// Watchers
function* watchUploadCSV() {
  const { UPLOAD_CSV } = ACTIONS
  yield takeFirst(UPLOAD_CSV, uploadCSV)
}

function* watchDownloadCSV() {
  const { DOWNLOAD_CSV } = ACTIONS
  yield takeFirst(DOWNLOAD_CSV, downloadCSV)
}

export default function* rootSaga() {
  yield all([watchUploadCSV(), watchDownloadCSV()])
}
