import React from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'

const columns = [
  {
    name: 'name',
    title: 'Name'
  },
  {
    name: 'email',
    title: 'Email'
  },
  {
    name: 'companyName',
    title: 'Company Name'
  },
  {
    name: 'error',
    title: 'Error'
  }
]

const ValidationGrid = ({
  data
}) => {
  if (!data.length) {
    return null
  }
  return (
    <DataGrid
      title="Validate"
      columns={columns}
      data={data}
    />
  )
}

ValidationGrid.propTypes = {
  data: PropTypes.array
}

export default ValidationGrid
