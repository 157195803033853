import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { ROUTE_URL } from 'constants.js'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import MFACodeVerificationForm from 'components/MFACodeVerificationForm/MFACodeVerificationForm'
import { Link, ListItemText } from '@material-ui/core'
import { downloadTxtFile } from 'utils/functions'
import { Redirect } from 'react-router'
import GlobalSpinnerContextProvider from 'containers/App/components/GlobalSpinnerContextProvider'
import Spinner from 'containers/App/components/Spinner/Spinner'

const formatRecoveryKey = (recoveryKey) => recoveryKey.replace(/(.{4})/g, '$1 ')

const handleDownloadFile = (recoveryKey) => (event) => {
  event.preventDefault()
  downloadTxtFile(recoveryKey, 'travel-ledger-recovery-key')
}

const steps = [
  'Download App',
  'Scan QR Code',
  'Recovery Code',
  'Complete'
];

const SetupAuthenticator = ({ auth, confirmMFACode }) => {
  const classes = useStyles()

  const [activeStep, setActiveStep] = useState(0)
  const [authenticator, setAuthenticator] = useState(0)

  if (auth.fetching) {
    return <GlobalSpinnerContextProvider>
      <Spinner open={true} />
    </GlobalSpinnerContextProvider>
  }

  if (!auth.qrCode && !auth.token) {
    return <Redirect to={ROUTE_URL.login} />
  }

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>

        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" className={classes.title}>
          Two-Factor Authentication Setup
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel className={[classes.wFull, classes.title]}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className={classes.contentContainer}>
          {activeStep === 0 &&
            <div className={classes.paper}>
              <Typography paragraph>
                <span className={classes.important}>IMPORTANT:</span> As an added layer of security Two Factor authentication will be  required <strong>every time you log in</strong>.
              </Typography>
              <Typography className={classes.subTitle}>
                Download and install the Authenticator app
              </Typography>
              <div className={classes.centeredAuthenticator} >
                <Button
                  variant="contained"
                  className={[authenticator === 1 && classes.authenticatorNotSelected, classes.authenticatorButton]}
                  onClick={() => setAuthenticator(0)}
                  disableElevation
                >
                  <Typography variant='caption' component={'p'}>Google Authenticator</Typography>
                  <Typography variant='caption' component={'p'}>(Recommended)</Typography>
                </Button>
              </div>
              <div className={classes.authenticatorContainer}>
                {authenticator === 0 &&
                  <>
                    <Link
                      target="_blank"
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      className={classes.googleLink}
                    >
                      <img src={`${process.env.PUBLIC_URL}/app-store.png`} alt="app store"/>
                    </Link>
                    <Link target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                      <img src={`${process.env.PUBLIC_URL}/google-play.png`} alt="google play"/>
                    </Link>
                  </>
                }
              </div>
            </div>
          }

          {activeStep === 1 &&
            <>
              <ListItemText>
                <Typography align="center">
                  Scan the QR code with Google Authenticator app.
                </Typography>
                <Box align="center">
                  <img src={auth.qrCode} alt="qr code" />
                </Box>
              </ListItemText>
              <Typography align="center">Or enter the following code manually:</Typography>
              <Typography component="h1" variant="body1" align="center" paragraph>
                {auth.mfaSecret.replace(/(.{4})/g, '$1 ')}
              </Typography>
              <Typography align="center" >The app will now display a code that you will use every time you log in.</Typography>
            </>
          }

          {activeStep === 2 &&
            <>
              <Typography>
                Download and store the recovery code in a safe place. If you loose access to your
                device you can reset two factor authentication using the recovery code:
                <pre className={classes.centered}>
                  {formatRecoveryKey(auth.recoveryKey)}{' '}
                  <Link href="#" onClick={handleDownloadFile(auth.recoveryKey)}>
                    ( Download )
                  </Link>
                </pre>
              </Typography>
            </>
          }

          {activeStep === 3 &&
            <>
              <Typography>
                To complete the setup, insert the two-factor authentication code from the app and click confirm.
              </Typography>
              <MFACodeVerificationForm confirmMFACode={confirmMFACode} />
            </>
          }

        </div>
        <div className={activeStep === 0 ? classes.stepperButtonsContainerFirst : classes.stepperButtonsContainer}>
          {activeStep > 0 &&
            <Button
              variant="contained"
              className={classes.navButton}
              size="large"
              color="primary"
              onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
            >
              Previous
            </Button>
          }
          {activeStep < 3 &&
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={activeStep === 0 ? classes.wHalf : classes.navButton}
              onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
            >
              Next
            </Button>
          }
        </div>

      </div>
    </Container>
  )
}

SetupAuthenticator.defaultProps = {
  destination: ROUTE_URL.home,
  postAuth: [],
  confirmMFACode: () => { },
}

SetupAuthenticator.propTypes = {
  auth: PropTypes.object.isRequired,
  confirmMFACode: PropTypes.func.isRequired,
}

export default SetupAuthenticator
