import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Controller } from 'react-hook-form'

const ReactHookFormMuiCheckbox = ({ control, name, label, value, checkboxProps, ...props }) => {
  const handleChange = (e) => e.target.checked
  const getName = (_name, _value) => _value ? `${_name}[${_value}]` : _name

  return (
    <Controller
      name={getName(name, value)}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={!!field.value}
              onChange={(e) => field.onChange(handleChange(e))}
              color="primary"
              {...checkboxProps}
            />
          }
          label={label}
        />
      )}
      { ...props }
    />
  )
}

ReactHookFormMuiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  error: PropTypes.object,
  checkboxProps: PropTypes.object
}

export default ReactHookFormMuiCheckbox
