import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { Collapse, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoIcon from '@material-ui/icons/Info'

import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const Instructions = ({ fieldsCsv }) => {
  const [ expanded, setExpanded ] = useState(false)
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <Grid container className={classes.expandRow} justify="space-between" onClick={handleExpandClick}>
        <Grid item>
          <Typography variant="h6"><InfoIcon /> {formatMessage({ id: 'csv-uploader.instructions' })}</Typography>
        </Grid>
        <Grid item>
          <ExpandMoreIcon className={classNames(classes.expand, {
            [classes.expandOpen]: expanded
          })} />
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List dense>
          {fieldsCsv.map((fieldCsv, index) => (
            <ListItem key={fieldCsv.title}>
              <ListItemText
                primary={`${index + 1}. ${fieldCsv.title}`}
                secondary={<><Typography>{fieldCsv.notes}</Typography><Typography>{fieldCsv.format}</Typography></>}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

Instructions.propTypes = {
  fieldsCsv: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      notes: PropTypes.string,
      format: PropTypes.string
    })
  )
}

export default Instructions
