import React from 'react'
import { Tooltip } from '@material-ui/core'

const RedactedDateCell = React.memo(() => {
  return (
    <Tooltip title={'Contact Advantage Travel Partnership for more information.'}>
      <span>---------</span>
    </Tooltip>
  )
})

RedactedDateCell.displayName = 'RedactedDateCell'

export default RedactedDateCell
