import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AuthView from 'containers/AuthView'
import SnackbarComponent from 'components/Snackbar'
import TaskForm from './components/TaskForm'
import TasksTable from './components/TasksTable'

const TaskScheduler = ({
  location,
  tasks,
  currentTask,
  snackbar,
  fetchTasks,
  runTask,
  addTask,
  editTask,
  updateTask,
  deleteTask,
  fetchLatestCycles,
  latestCollectionDates,
}) => {
  useEffect(() => {
    fetchTasks()
  }, [])

  const onSaveTask = (taskForm) => {
    const task = taskForm
    if (!Reflect.has(currentTask, 'id')) {
      addTask(task)
    } else {
      updateTask(currentTask.id, task)
    }
  }

  const onCancelTask = () => {
    editTask({})
  }

  return (
    <AuthView location={location} title="Task Scheduler">
      <TaskForm onSaveTask={onSaveTask} task={currentTask} onCancelTask={onCancelTask} fetchLatestCycles={fetchLatestCycles} latestCollectionDates={latestCollectionDates} />
      <TasksTable data={tasks} onRunClick={runTask} onEditClick={editTask} onDeleteClick={deleteTask} />
      <SnackbarComponent status={snackbar.isOpened} error={snackbar.isError} text={snackbar.successOrErrorText} />
    </AuthView>
  )
}

TaskScheduler.propTypes = {
  location: PropTypes.object,
  tasks: PropTypes.array,
  snackbar: PropTypes.object,
  currentTask: PropTypes.object,
  fetchTasks: PropTypes.func,
  runTask: PropTypes.func,
  addTask: PropTypes.func,
  editTask: PropTypes.func,
  updateTask: PropTypes.func,
  deleteTask: PropTypes.func,
  fetchLatestCycles: PropTypes.func,
  latestCollectionDates: PropTypes.array,
}

export default TaskScheduler
