import { Typography, Grid } from '@material-ui/core'
import React from 'react'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useIntl } from 'react-intl';

const KYCLink = ({ url, regenerateLink }) => {
  const { formatMessage } = useIntl()

  return (
        <>
          <Typography paragraph>
            <Grid container direction='row' spacing={2} noWrap>
              <Grid item container >
                <Button 
                  icon={<ArrowRightAltIcon />} 
                  iconPosition='end'
                  color="primary" 
                  marginRight="10px"
                  onClick={() => window.open(url, '_blank')}>
                  {formatMessage({ id: 'tlpay.start-verification' })}
                </Button>

                <Button 
                  icon={<FileCopyIcon />} 
                  iconPosition='end'
                  color="primary" 
                  marginLeft="10px"
                  onClick={() => 
                    navigator.clipboard.writeText(url)
                      .then(() => {
                        toast.success('Link copied to clipboard!')
                      })
                  }>
                  {formatMessage({ id: 'tlpay.copy-link' })}
                </Button>
              </Grid>
            </Grid>
          </Typography>
          <Typography paragraph>
            {formatMessage({ id: 'tlpay.the-link-is-only-valid-for-24hrs-please-click-this-button-to-generate-a-new-link-if-needed' })}
          </Typography>
          <Typography paragraph>
            <Button 
              icon={<AutorenewIcon />} 
              iconPosition='end' 
              onClick={regenerateLink}
              >
              {formatMessage({ id: 'tlpay.regenerate-link' })}
            </Button>

          </Typography>
        </>
  )
}

export default KYCLink
