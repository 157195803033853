import React from 'react'
import FormattedError from 'components/FormattedError'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postForgotPassword } from 'services/authApi'

export const usePostForgotPassword = (options) => useMutation(body => postForgotPassword(body), {
  onError: (error) => {
    toast.error(<FormattedError error={error} />)
  },
  onSuccess: () => {
    toast.success('If we have a user account associated with that email address, an email with further instructions was sent.')
  },
  ...options
})
