import moment from 'moment'
import { cronExpressionToScheduleFields, scheduleFieldsToCronExpression } from 'utils/cronTransform'
import { DEFAULT_DATE_FORMAT, TASK_FREQUENCY_TYPES } from 'constants.js'

const parseDayOfMonth = (dayOfMonth) => {
  if (dayOfMonth === 'first') {
    return 1
  }
  if (dayOfMonth === 'L') {
    return 'L'
  }

  return Number(dayOfMonth)
}

export const parseFrequency = ({ timeOfDay, daysOfWeek, dayOfMonth, frequencyType }) => {
  const [hour, minute] = timeOfDay.split(':')
  return scheduleFieldsToCronExpression({
    second: 0,
    minute,
    hour: hour,
    day: frequencyType === 'MONTHLY' ? parseDayOfMonth(dayOfMonth) : undefined,
    week: frequencyType === 'MONTHLY' ? '*' : daysOfWeek.join(','),
  })
}

export const parseDataForSubmit = (data) => {
  const {
    // eslint-disable-next-line no-unused-vars
    emailDelivery,
    frequencyType,
    daysOfWeek,
    dayOfMonth,
    timeOfDay,
    startAt,
    endAt,
    ...rest
  } = data
  return {
    ...rest,
    endAt: endAt ? moment(endAt).format(DEFAULT_DATE_FORMAT) : undefined,
    startAt: startAt ? moment(startAt).format(DEFAULT_DATE_FORMAT) : undefined,
    frequency:
      frequencyType === TASK_FREQUENCY_TYPES.NONE
        ? null
        : parseFrequency({ dayOfMonth, daysOfWeek, frequencyType, timeOfDay }),
  }
}

const getWeekDays = (weekDayString) => {
  if (weekDayString === '*') {
    return '*'
  }
  return weekDayString.split(',')
}

export const parseDataToForm = (data = {}) => {
  if (Object.keys(data).length === 0) {
    return {}
  }
  const { frequency, ...d } = data
  let frequencyFields = {}
  if (frequency) {
    const { minute, hour, day, week } = cronExpressionToScheduleFields(frequency)
    const weekDays = getWeekDays(week)
    frequencyFields = {
      timeOfDay: `${hour}:${minute}`,
      dayOfMonth: day === '1' ? 'first' : day,
      daysOfWeek: weekDays,
      frequencyType: week && week === '*' ? TASK_FREQUENCY_TYPES.MONTHLY : TASK_FREQUENCY_TYPES.WEEKLY,
    }
  } else {
    frequencyFields.frequencyType = TASK_FREQUENCY_TYPES.NONE
  }

  let extra = {}
  if (data.extra) {
    extra = {
      customPeriod: data.extra.customPeriod,
      splitForEachSeller: data.extra.splitForEachSeller,
      dateFormat: data.extra.dateFormat,
      decimalSeparator: data.extra.decimalSeparator,
      encapsulation: data.extra.encapsulation,
      ...data.extra
    }
  }

  const parsedData = {
    ...d,
    emailDelivery: d.emailAdmins || !!d.mailto?.length,
    ...frequencyFields,
    ...extra
  }
  return parsedData
}
