import { connect } from 'react-redux'
import Component from './TaskScheduler.js'
import { fetchTasks, addTask, editTask, runTask, updateTask, deleteTask } from 'store/tasks'
import { fetchLatestCycles } from 'store/invoices'

const mapState = ({ tasks, invoices }) => ({
  latestCollectionDates: invoices.latestCollectionDates,
  ...tasks,
})

const mapDispatch = dispatch => ({
  fetchTasks: () => dispatch(fetchTasks()),
  addTask: (task) => dispatch(addTask(task)),
  editTask: (task) => dispatch(editTask(task)),
  runTask: (id) => dispatch(runTask(id)),
  updateTask: (id, task) => dispatch(updateTask(id, task)),
  deleteTask: (id) => dispatch(deleteTask(id)),
  fetchLatestCycles: () => dispatch(fetchLatestCycles())
})

export default connect(mapState, mapDispatch)(Component)
