import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useStyles } from './styles'

export const InputText = ({ errors, name, control, label, disabled, InputProps, className, ...rest }) => {
  const classes = useStyles()
  const render = ({ field }) => {
    return (
      <TextField
        {...rest}
        {...field}
        name={name}
        label={label}
        variant="outlined"
        fullWidth
        className={className ?? classes.input}
        error={!!errors?.[name]}
        helperText={errors?.[name]?.message}
        InputProps={{ ...InputProps, disabled }}
      />
    )
  }

  return <Controller name={name} control={control} render={render} />
}

InputText.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
}

export default InputText
