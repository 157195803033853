import React, { useEffect, useMemo, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import SnackbarComponent from 'components/Snackbar'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import UserForm from './components/UserForm'
import CompanyForm from './components/CompanyForm'
import CompanyIdsForm from './components/CompanyIdsForm'
import Grid from '@material-ui/core/Grid'
import { ROUTE_URL, COMPANY_ID_TYPES } from 'constants.js'
import { mapFormKeys } from 'utils/functions'
import { CustomTab, CustomTabs, REGISTRATION_TABS } from './components/Tabs'

const initialFieldsUser = {
  name: '',
  role: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const initialFieldsCompany = {
  address: '',
  city: '',
  telephone: '',
  website: '',
  timezone: '',
  countryId: '',
  acceptTos: false,
  companyName: '',
}

const initialFieldsIds = {
  emailID: '',
  abtaID: '',
  iataID: '',
  tID: '',
}

const getInitialValues = (isCompanyInvite) => {
  if (!isCompanyInvite) {
    return initialFieldsUser
  }
  return {
    ...initialFieldsUser,
    ...initialFieldsCompany,
    ...initialFieldsIds,
  }
}

const Registration = ({ snackbar, encounteredError, fetchInviteData, submitRegistration, inviteDetails, history }) => {
  // manage tabs
  const [tabSelected, setTabSelected] = useState(REGISTRATION_TABS.companyDetails.key)
  const companyDetailsSelected = tabSelected === REGISTRATION_TABS.companyDetails.key
  const companyIdsSelected = tabSelected === REGISTRATION_TABS.companyIds.key
  const userDetailsSelected = tabSelected === REGISTRATION_TABS.userDetails.key
  const lastTabKey = Math.max(...Object.values(REGISTRATION_TABS).map(v => v.key))
  const changeTab = (forward = true) => {
    const nextIndex = tabSelected + (forward ? 1 : -1)
    const existsTab = Object.values(REGISTRATION_TABS).some(t => t.key === nextIndex)
    setTabSelected(existsTab ? nextIndex : 0)
  }
  
  const isCompanyInvite = inviteDetails?.inviteType === 'COMPANY'
  const initialValues = useMemo(() => getInitialValues(isCompanyInvite), [isCompanyInvite])
  const form = useForm({
    resolver: joiResolver(formSchema(inviteDetails?.inviteType)),
    defaultValues: initialValues,
  })
  const {
    formState: { errors, submitCount },
    handleSubmit,
    reset,
    setValue,
  } = form

  // on validation errors, switch tab to show it to the user
  useEffect(() => {
    const errorKeys = Object.keys(errors)
    if (isCompanyInvite && submitCount > 0 && errorKeys.length) {
      const companyFields = Object.keys(initialFieldsCompany).filter(field => field !== 'acceptTos')
      const companyIdFields = Object.keys(initialFieldsIds)
      if(companyFields.some(cf => errorKeys.includes(cf))) {
        // has error in 1st tab
        setTabSelected(REGISTRATION_TABS.companyDetails.key)
      } else if(companyIdFields.some(cf => errorKeys.includes(cf))) {
        // has error in 2nd tab
        setTabSelected(REGISTRATION_TABS.companyIds.key)
      }
    }
  }, [errors, submitCount, isCompanyInvite])

  useEffect(() => {
    fetchInviteData()
  }, [fetchInviteData])

  useEffect(() => {
    if (inviteDetails) {
      reset(
        mapFormKeys(
          inviteDetails,
          initialValues,
        ),
      )
    }
  }, [inviteDetails, reset, initialValues])

  useEffect(() => {
    if (encounteredError && !snackbar?.isOpened) {
      history.push(ROUTE_URL.login)
    }
  }, [encounteredError, history, snackbar])

  const onSubmit = handleSubmit((data) => {
    const {
      acceptTos,
      address,
      city,
      telephone,
      website,
      timezone,
      password,
      countryId,
      email,
      name,
      companyName,
      emailID,
      abtaID,
      iataID,
      tID,
    } = data
    let fields = { email, name, password }
    if (isCompanyInvite) {
      // build codes object
      const codes = {
        ...(emailID && { [COMPANY_ID_TYPES.EMAIL_ID]: emailID }),
        ...(abtaID && { [COMPANY_ID_TYPES.ABTA]: abtaID }),
        ...(iataID && { [COMPANY_ID_TYPES.IATA]: iataID }),
        ...(tID && { [COMPANY_ID_TYPES.TIDS]: tID }),
      }
      fields = {
        ...inviteDetails,
        codes,
        acceptTos,
        address,
        city,
        countryId,
        telephone,
        website,
        timezone,
        companyName,
        ...fields,
      }
      if (fields.role) {
        delete fields.role
      }
    }
    if (fields.inviteType) {
      delete fields.inviteType
    }
    submitRegistration(fields)
  })

  const classes = useStyles()
  return (
    <Container component="main" >
      <SnackbarComponent status={snackbar?.isOpened} error={snackbar?.isError} text={snackbar?.successOrErrorText} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registration
        </Typography>
        {!isCompanyInvite && (
          <Typography variant="h6" className={classes.companyName}>
            {inviteDetails?.companyName}
          </Typography>
        )}
        
        {isCompanyInvite && <CustomTabs 
          value={tabSelected} 
          onChange={(event, newValue) => setTabSelected(newValue)}>
          {Object.values(REGISTRATION_TABS).map(val => 
            <CustomTab key={val.key} label={val.label} />)}
        </CustomTabs>}

        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {isCompanyInvite && companyDetailsSelected ? (
              <Grid container item xs={12} spacing={2}>
                <CompanyForm form={form} />
              </Grid>
            ) : null}

            {isCompanyInvite && companyIdsSelected ? (
              <Grid container item xs={12} spacing={2}>
                <CompanyIdsForm form={form} />
              </Grid>
            ) : null}

            {(!isCompanyInvite || userDetailsSelected) && <Grid container item xs={12} spacing={2}>
              <UserForm
                form={form}
                inviteDetails={inviteDetails}
                setTerms={(value) => {
                  setValue('acceptTos', value, { shouldValidate: true })
                }}
                isCompanyInvite={isCompanyInvite}
              />
            </Grid>}
            <Grid container item xs={12} justifyContent='center'>
              <Grid container item xs={6} justifyContent='center'>
                {isCompanyInvite && <Button
                    disabled={tabSelected === 0}
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => changeTab(false)}
                  >
                  Back
                </Button>}

                {isCompanyInvite && tabSelected < lastTabKey && <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => changeTab()}
                  >
                  Next
                </Button>}

                {(!isCompanyInvite || (tabSelected === lastTabKey)) && <Button
                  disabled={!!Object.keys(errors).length}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Register
                </Button>}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

Registration.defaultProps = {
  fetchInviteData: () => {},
  submitRegistration: () => {},
}

Registration.propTypes = {
  inviteDetails: PropTypes.object,
  encounteredError: PropTypes.bool,
  snackbar: PropTypes.object,
  history: PropTypes.object,
  fetchInviteData: PropTypes.func.isRequired,
  submitRegistration: PropTypes.func.isRequired,
}

export default Registration
