import React from 'react'
import PropTypes from 'prop-types'
import { dateToReadableDateTime } from 'utils/date'

const CollectionDateCell = React.memo(({ date }) => {
  if (!date) {
    return ''
  }

  return dateToReadableDateTime(date)
})

CollectionDateCell.displayName = 'CollectionDateCell'

CollectionDateCell.propTypes = {
  date: PropTypes.string,
}

export default CollectionDateCell
