import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { ACTIONS } from 'store/dataDelivery'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { performGetConfigCompany, performGetDataDelivery, performPatchConfigCompany } from '../requests'
import { dismissErrorSnackBar, dismissSnackBar } from 'utils/generators'

const getAuthState = state => state.auth

function* fetchDataDelivery() {
  try {
    const auth = yield select(getAuthState)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const [ companyConfig, dataDelivery ] = yield all([
      call(performGetConfigCompany, auth.companyId),
      call(performGetDataDelivery)
    ])
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({ type: ACTIONS.FETCH_DATA_DELIVERY_SUCCESS, payload: { companyConfig: companyConfig.data.attributes, dataDelivery: dataDelivery.data } })
  } catch (e) {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
  }
}

function* patchConfigDataDelivery({ payload }) {
  try {
    const auth = yield select(getAuthState)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const result = yield call(performPatchConfigCompany, auth.companyId, payload)
    if (result.errors) {
      throw result.data.errors[0].detail
    }
    const resultConfig = yield call(performGetConfigCompany, auth.companyId)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({
      type: ACTIONS.PATCH_CONFIG_DATA_DELIVERY_SUCCESS,
      payload: {
        companyConfig: resultConfig.data.attributes,
        snackbar: 'Data delivery configuration updated.'
      }
    })
    yield fork(dismissSnackBar, ACTIONS.CLOSE_SNACKBAR)
  } catch (e) {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({
      type: ACTIONS.PATCH_CONFIG_DATA_DELIVERY_ERROR,
      payload: `${e.message}`
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ACTIONS.PATCH_CONFIG_DATA_DELIVERY, patchConfigDataDelivery),
    takeLatest(ACTIONS.FETCH_DATA_DELIVERY, fetchDataDelivery)
  ])
}
