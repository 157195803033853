import { fullName } from 'utils/functions'
export const FETCH_COMPANIES = fullName('fetch-curr-user-company', 'FETCH_COMPANIES')
export const FETCH_COMPANIES_SUCCESS = fullName('fetch-curr-user-company-success', 'FETCH_COMPANIES_SUCCESS')
export const FETCH_CURR_USER_COMPANY = fullName('fetch-curr-user-company', 'FETCH_CURR_USER_COMPANY')
export const FETCH_CURR_USER_COMPANY_SUCCESS = fullName('fetch-curr-user-company-success', 'FETCH_CURR_USER_COMPANY_SUCCESS')
export const PATCH_CURR_USER_COMPANY = fullName('patch-curr-user-company', 'PATCH_CURR_USER_COMPANY')
export const PATCH_CURR_USER_COMPANY_SUCCESS = fullName('patch-curr-user-company-success', 'PATCH_CURR_USER_COMPANY_SUCCESS')
export const PATCH_CURR_USER_COMPANY_ERROR = fullName('patch-curr-user-company-error', 'PATCH_CURR_USER_COMPANY_ERROR')
export const CLEAR_CURR_USER_COMPANY = fullName('clear-curr-user-company', 'CLEAR_CURR_USER_COMPANY')
export const SEARCH_COMPANIES = fullName('search-companies', 'SEARCH_COMPANIES')
export const SEARCH_COMPANIES_SUCCESS = fullName('search-companies-success', 'SEARCH_COMPANIES_SUCCESS')
export const CLEAR_SEARCH_COMPANIES_DATA = fullName('clear-search-companies-data', 'CLEAR_SEARCH_COMPANIES_DATA')
export const CLOSE_SNACKBAR = fullName('close-snackbar', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_CURR_USER_COMPANY,
  FETCH_CURR_USER_COMPANY_SUCCESS,
  PATCH_CURR_USER_COMPANY,
  PATCH_CURR_USER_COMPANY_SUCCESS,
  PATCH_CURR_USER_COMPANY_ERROR,
  CLEAR_CURR_USER_COMPANY,
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_SUCCESS,
  CLEAR_SEARCH_COMPANIES_DATA,
  CLOSE_SNACKBAR
}

export const fetchCompanies = () => ({
  type: FETCH_COMPANIES
})

export const fetchCurrUserCompany = payload => ({
  type: FETCH_CURR_USER_COMPANY,
  payload
})

export const clearCurrUserCompany = () => ({
  type: CLEAR_CURR_USER_COMPANY
})

export const patchCurrUserCompany = data => ({
  type: PATCH_CURR_USER_COMPANY,
  data: data
})

export const searchCompanies = payload => ({
  type: SEARCH_COMPANIES,
  payload
})

export const clearSearchCompaniesData = () => ({
  type: CLEAR_SEARCH_COMPANIES_DATA
})

const initialState = {
  currUserCompany: {
    id: '',
    name: '',
    address: '',
    city: '',
    telephone: '',
    website: '',
    timezone: '',
    abtaNumber: ''
  },
  searchCompany: {
    data: []
  },
  allCompanies: [],
  snackbars: false,
  error: false
}

export const companies = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURR_USER_COMPANY_SUCCESS:
      return {
        ...state,
        currUserCompany: action.payload
      }
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        allCompanies: action.payload
      }
    case PATCH_CURR_USER_COMPANY_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: 'Company Profile updated successfully.',
        },
        currUserCompany: action.payload.data.attributes,
        snackbars: true,
        error: false
      }
    case PATCH_CURR_USER_COMPANY_ERROR:
      return {
        ...state,
        snackbars: true,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        },
        error: true
      }
    case CLEAR_CURR_USER_COMPANY:
      return {
        ...state,
        currUserCompany: {
          id: '',
          name: '',
          address: '',
          city: '',
          telephone: '',
          website: '',
          timezone: '',
          abtaNumber: ''
        }
      }
    case SEARCH_COMPANIES_SUCCESS:
      return {
        ...state,
        searchCompany: { ...action.payload }
      }
    case CLEAR_SEARCH_COMPANIES_DATA:
      return {
        ...state,
        searchCompany: {
          data: []
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbars: false
      }
    default:
      return state
  }
}
