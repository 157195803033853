import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ValidationForm from 'react-validation/build/form'
import { ROUTE_URL, ADMIN_VIEWS } from 'constants.js'
import FormButton from 'components/FormButton'
import OneItemRow from 'components/Rows/OneItemRow'
import TwoItemRow from 'components/Rows/TwoItemRow'
import Login from 'containers/Login'
import { Grid } from '@material-ui/core'

class AuthView extends Component {
  static getDerivedStateFromProps(nextProps) {
    return {
      displayLogin: !nextProps.isAuthenticated && nextProps.loggedIn
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      /*
        The flag displayLogin is true if the user not authenticated ( isAuthenticated == false)
        and the current view requries login ( loggedIn == true )
        Fir example, if the user in not authenticated, but the view does not require the user to be logged in
        there is no point to display the login screen.
      */
      displayLogin: !props.isAuthenticated && props.loggedIn
    }
  }

  // 1. First, we check if we already have the token
  // 2. If we do, we remain on this view
  // 3. If we don't, we try to get the token from localstorage
  // 4. If we do get it from local storage, we remain on this view
  // 5. If we don't get it from local storage, we display the login view
  // 6. Once we get the token from the login view, we display this view again

  componentDidMount() {
    const { auth, fetchAuth, postAuth } = this.props
    fetchAuth({ auth, postAuth })
  }

  componentDidUpdate() {
    const { replace } = this.props
    if (!this.hasViewAccess()) {
      replace(ROUTE_URL.home)
    }
  }

  hasViewAccess() {
    const { auth, location } = this.props
    const { isAdmin, fetching } = auth
    if(!fetching && ADMIN_VIEWS.includes(location.pathname)) {
      return isAdmin
    }
    return true
  }

  renderRequireLoggedInView() {
    const { auth, children, location, postAuth } = this.props
    const { displayLogin } = this.state

    return displayLogin ? <Login destination={location.pathname} postAuth={postAuth} /> : auth.token && children
  }

  renderRequireLoggedOutView() {
    const { auth, children, logout, replace } = this.props

    return auth.token ? (
      <div className="container">
        <div className="notification">
          <ValidationForm>
            <OneItemRow>
              <label className="label">You must log out to access this view! Log out?</label>
            </OneItemRow>
            <TwoItemRow>
              <FormButton onClick={event => {
                event.preventDefault()
                logout()
              }}>
                <label>Log out</label>
              </FormButton>
              <FormButton onClick={event => {
                event.preventDefault()
                replace(ROUTE_URL.home)
              }}>
                <label>Cancel</label>
              </FormButton>
            </TwoItemRow>
          </ValidationForm>
        </div>
      </div>
    ) : children
  }

  render() {
    const { loggedIn, extra } = this.props

    return (
      <Fragment>
        <Grid container justify="space-between">
          <Grid item>
            {extra}
          </Grid>
        </Grid>
        { loggedIn
          ? this.renderRequireLoggedInView()
          : this.renderRequireLoggedOutView()
        }
      </Fragment>
    )
  }
}

AuthView.defaultProps = {
  loggedIn: true,
  postAuth: []
}

AuthView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]),
  location: PropTypes.object,
  loggedIn: PropTypes.bool,
  extra: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]),
  isAuthenticated: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  fetchAuth: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  postAuth: PropTypes.arrayOf(PropTypes.string)
}

export default AuthView
