import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  formSection: {
    flexDirection: 'column'
  },
  input: {
    marginTop: theme.spacing(2)
  },
  inputWithTooltip: {
    marginTop: theme.spacing(2),
    width: 'calc(100% - 33px)',
  },
  tooltip: {
    paddingTop: '15px',
  },
  formAction: {
    justifyContent: 'center'
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
    alignSelf: 'center'
  },
  companyName: {
    marginTop: theme.spacing(1)
  },
  feeScheduleLink: {
    marginLeft: theme.spacing(4)
  }
}))
