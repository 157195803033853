import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from 'components/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import { CommLinkEntities } from './CommLinkEntities'
import { PartPayments } from './PartPayments'
import { PartBusinessTerms } from './PartBusinessTerms'
import SnackbarComponent from 'components/Snackbar'
import { useCLEditState } from './useCLEditState'
import { ACCOUNT_ROLE } from 'constants.js'
import { useStyles } from './styles'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'

const shouldDisableSubmit = (role, state) => {
  // payment profiles that do not require the contact details will
  // have null buyerContact and sellerContact fields (see <PartPayments/>)
  const { common, payment, business } = state

  const isProcessingEntity = role === ACCOUNT_ROLE.PROCESSING_ENTITY

  const requiresSellerContact = isProcessingEntity && payment.sellerContact !== null
  const hasSellerContact = !!(payment.sellerContact?.id && payment.sellerMandateId)

  const requiresBuyerContact = payment.buyerContact !== null
  const hasBuyerContact = !!(payment.buyerContact?.id && payment.buyerMandateId)

  return (
    isProcessingEntity && common.sellerId === '' ||
    common.buyerId === '' ||
    common.status === '' ||
    business.businessTermsSelected === '' ||
    payment.settlementScheduleSelected === '' ||
    requiresSellerContact && !hasSellerContact ||
    requiresBuyerContact && !hasBuyerContact
  )
}

const CLEditForm = ({
  role,
  editedCommercialLink,
  companies,
  postCommercialLinks,
  patchCommercialLink,
  searchCompanies,
  clearSearchCompaniesData,
  currUserCompany,
  settlementSchedules = [],
  snackbar
}) => {
  const classes = useStyles()

  const descriptionInputRef = useRef()
  const { formatMessage } = useIntl()
  const isEdit = !!editedCommercialLink?.id
  const canEdit = !!editedCommercialLink?.canEdit

  const isReadOnly = isEdit && !canEdit

  const isBuyerWithNoProcessingEntity = role === ACCOUNT_ROLE.BUYER && isEdit && !editedCommercialLink?.processingEntityId
  const isBuyerOrSellerWithProcessingEntity = role !== ACCOUNT_ROLE.PROCESSING_ENTITY && isEdit && !!editedCommercialLink?.processingEntityId
  const isBuyerOrSellerViewingDetails = isBuyerWithNoProcessingEntity || isBuyerOrSellerWithProcessingEntity
  const isBuyerOrSellerWithNoProcessingEntity = role !== ACCOUNT_ROLE.PROCESSING_ENTITY || (isEdit && !editedCommercialLink?.processingEntityId)
  const isSellerWithNoProcessingEntity = isBuyerOrSellerWithNoProcessingEntity && !isBuyerWithNoProcessingEntity

  const { state, commonActions, paymentActions, businessActions } = useCLEditState({
    role,
    currUserCompany,
    editedCommercialLink
  })

  useEffect(() => {
    if (descriptionInputRef?.current && editedCommercialLink?.copy) {
      descriptionInputRef.current.focus()
      commonActions.dispatchValue({ target: { name: 'name', value: '' } })
    }
  }, [ descriptionInputRef, editedCommercialLink ])

  const handleCreate = () => {
    const { common, payment, business } = state

    const objLink = {
      description: common.description,
      sellerId: common.sellerId,
      buyerId: common.buyerId,
      status: common.status,
      settlementScheduleId: payment.settlementScheduleSelected,
      paymentConfiguration: {},
      businessTermsId: business.businessTermsSelected
    }

    if (payment.buyerContact !== null) {
      objLink.paymentConfiguration.buyer = {
        contactId: String(payment.buyerContact.id),
        mandateId: payment.buyerMandateId
      }
    }

    if (role === ACCOUNT_ROLE.PROCESSING_ENTITY) {
      objLink.processingEntityId = currUserCompany.id

      if (payment.sellerContact !== null) {
        objLink.paymentConfiguration.seller = {
          contactId: String(payment.sellerContact.id),
          mandateId: payment.sellerMandateId
        }
      }
    }

    // avoid create duplicated commercial links with copy feature
    if (editedCommercialLink?.copy) {
      const assessObj = {
        description: editedCommercialLink.description,
        sellerId: editedCommercialLink.sellerId,
        buyerId: editedCommercialLink.buyerId,      
        status: editedCommercialLink.status,
        settlementScheduleId: editedCommercialLink.settlementScheduleId,
        paymentConfiguration: {
          buyer: {
            contactId: editedCommercialLink.paymentConfiguration.buyer.contactId,
            mandateId: editedCommercialLink.paymentConfiguration.buyer.mandateId
          },
          ...role === ACCOUNT_ROLE.PROCESSING_ENTITY && {
            seller: {
              contactId: editedCommercialLink.paymentConfiguration.seller.contactId,
              mandateId: editedCommercialLink.paymentConfiguration.seller.mandateId
            }
          }
        },
        businessTermsId: editedCommercialLink.businessTermsId,
        ...role === ACCOUNT_ROLE.PROCESSING_ENTITY && { processingEntityId: editedCommercialLink.processingEntityId},
      }
      if (JSON.stringify(assessObj) === JSON.stringify(objLink)) {
        return toast.error('No changes were made. Can\'t duplicate the Commercial Link',{autoClose: 4000})
      }
    }

    postCommercialLinks(objLink)
  }

  const handleSubmit = () => {
    if (!isEdit) {
      return handleCreate()
    }

    const { common, payment, business } = state
    const paymentConfiguration = {}

    if (payment.buyerContact !== null) {
      paymentConfiguration.buyer = {
        contactId: String(payment.buyerContact.id),
        mandateId: payment.buyerMandateId
      }
    }

    if (role === ACCOUNT_ROLE.SELLER) {
      patchCommercialLink({
        id: editedCommercialLink?.id,
        objLink: {
          paymentConfiguration,
          status: common.status,
          description: common.description,
        }
      })
      return
    }

    if (payment.sellerContact !== null) {
      paymentConfiguration.seller = {
        contactId: String(payment.sellerContact.id),
        mandateId: payment.sellerMandateId
      }
    }

    const objLink = {
      description: common.description,
      buyerId: common.buyerId,
      sellerId: common.sellerId,
      status: common.status,
      settlementScheduleId: payment.settlementScheduleSelected,
      paymentConfiguration,
      businessTermsId: business.businessTermsSelected
    }

    patchCommercialLink({ objLink, id: editedCommercialLink?.id })
  }

  const actionTitle = isEdit
    ? formatMessage({ id: 'table-filters.save' })
    : formatMessage({ id: 'table-filters.create' })
  const ActionIcon = isEdit ? 'edit' : 'add'
  const descriptionPlaceholder = editedCommercialLink?.copy ? 'Insert a description for this commercial link' : ''
  const disableSubmit = shouldDisableSubmit(role, state)

  return (
    <Card className={classes.root}>
      <CardContent>
        <CommLinkEntities
          commonState={state.common}
          commonActions={commonActions}
          clearSearchCompaniesData={clearSearchCompaniesData}
          role={role}
          classes={classes}
          isEdit={isEdit}
          isReadOnly={isReadOnly}
          searchCompanies={searchCompanies}
          currUserCompany={currUserCompany}
          options={companies}
        />
        <Grid container spacing={3} direction={'row'}>
          <TextField
            className={classes.formInput}
            disabled={isReadOnly}
            name="description"
            label={formatMessage({ id: 'commercial-link-entity.description' })}
            placeholder={descriptionPlaceholder}
            inputRef={descriptionInputRef}
            value={state.common.description}
            onChange={commonActions.dispatchValue}
          />
        </Grid>
        {
          editedCommercialLink?.settlementSchedule || settlementSchedules ? (
            <>
                <PartPayments
                  settlementSchedules={isBuyerOrSellerViewingDetails ? [ editedCommercialLink?.settlementSchedule ] : settlementSchedules}
                  isSellerWithNoProcessingEntity={isSellerWithNoProcessingEntity}
                  isBuyerOrSellerWithNoProcessingEntity={isBuyerOrSellerWithNoProcessingEntity}
                  isBuyerOrSellerWithProcessingEntity={isBuyerOrSellerWithProcessingEntity}
                  classes={classes}
                  seller={state.common.paymentSeller}
                  buyer={state.common.paymentBuyer}
                  paymentState={state.payment}
                  paymentActions={paymentActions}
                  isReadOnly={isReadOnly}
                />
              <PartBusinessTerms
                classes={classes}
                currUserCompany={currUserCompany}
                businessState={state.business}
                businessActions={businessActions}
                settlementSchedules={editedCommercialLink?.settlementSchedule ? [ editedCommercialLink?.settlementSchedule ] : settlementSchedules}
                commercialLinkSettlementScheduleId={state.payment.settlementScheduleSelected}
                commercialLinkBusinessTermsId={isBuyerOrSellerViewingDetails || isSellerWithNoProcessingEntity ? editedCommercialLink?.businessTermsId : null}
              />
            </>
          ) : null
        }
      </CardContent>
      {!isReadOnly && (
        <CardActions>
          <Button
            onClick={handleSubmit}
            variant="extended"
            size="small"
            color="primary"
            disabled={disableSubmit}
            aria-label={actionTitle}
            margin
            icon={ActionIcon}
          >
            {actionTitle}
          </Button>
        </CardActions>
      )}
      <SnackbarComponent status={snackbar.isOpened} error={snackbar.isError} text={snackbar.successOrErrorText} />
    </Card>
  )
}

CLEditForm.propTypes = {
  role: PropTypes.oneOf([ ACCOUNT_ROLE.BUYER, ACCOUNT_ROLE.SELLER, ACCOUNT_ROLE.PROCESSING_ENTITY ]).isRequired,
  editedCommercialLink: PropTypes.object,
  companies: PropTypes.array,
  settlementSchedules: PropTypes.array,
  postCommercialLinks: PropTypes.func,
  patchCommercialLink: PropTypes.func,
  searchCompanies: PropTypes.func,
  clearSearchCompaniesData: PropTypes.func,
  currUserCompany: PropTypes.object,
  snackbar: PropTypes.object
}

export default CLEditForm
