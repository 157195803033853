import React, { memo } from 'react'
import PropTypes from 'prop-types'
import TablePagination from '@material-ui/core/TablePagination'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const getPaginationLabelDisplayed = (recordsCount) => ({ from, to }) => {
  const toLabel = Math.min(to, recordsCount)
  if (!recordsCount && from === 1) {
    return '0 of 0'
  }
  return `${from}-${toLabel} of ${recordsCount}`
}

const Pagination = ({ count, currentPage, pageSize, pageSizes, onChangePage, onChangePageSize }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <TablePagination
      component="div"
      count={-1}
      page={currentPage}
      onChangePage={(_ev, page) => onChangePage(page)}
      rowsPerPage={pageSize}
      rowsPerPageOptions={pageSizes}
      labelRowsPerPage={formatMessage({ id: 'table-pagination.rows-per-page' })}
      onChangeRowsPerPage={ev => onChangePageSize(ev.target.value)}
      className={classes.root}
      nextIconButtonProps={{ disabled: count < (currentPage + 1) * pageSize }}
      labelDisplayedRows={getPaginationLabelDisplayed(count)}
    />
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizes: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
}

export default memo(Pagination)
