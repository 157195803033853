import { connect } from 'react-redux'
import Component from './ConfirmAuthenticator.js'
import { confirmMFACode } from 'store/auth'

export const mapState = ({
  auth
}) => ({
  auth
})

export const mapDispatch = (dispatch) => ({
  confirmMFACode: otpToken => dispatch(confirmMFACode(otpToken))
})

export default connect(mapState, mapDispatch)(Component)
