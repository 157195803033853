import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'
import { get, del, patch } from '../utils/api'

export const getUsersConfig = async (id) => {
  const url = `${API_URL}/users/${id}`
  return httpFetch(url)
}

export const getLanguage = async () => {
  const url = `${API_URL}/users/languages`
  return httpFetch(url)
}

export const updateLanguage = async (language) => {
  const url = `${API_URL}/users/languages`
  return httpFetch(url, {
    method: 'PATCH',
    body: JSON.stringify({ language }),
  })
}

export const patchUsersConfig = async (id, body) => {
  const url = `${API_URL}/users/${id}`
  const data = {
    method: 'PATCH',
    body: JSON.stringify(body),
  }
  return httpFetch(url, data)
}

export const getUsers = async (companyId) => {
  const url = `/users?companyId=${companyId}`
  return get(url)
}

export const deactivateUser = async (id) => {
  const url = `/users/${id}`
  return del(url)
}

export const reactivateUser = async (id) => {
  const url = `/users/${id}/reactivate`
  return patch(url)
}

export const resetMFAUser = async (email) => {
  const url = `/sessions/resetMFA`
  return patch(url, { email })
}
