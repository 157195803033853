import { connect } from 'react-redux'

export const mapState = ({ auth, companies }) => ({
  auth,
  currUserCompany: companies.currUserCompany,
})

export const mapDispatch = () => ({})

export default connect(mapState, mapDispatch)
