import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BranchCompaniesTable from '../BranchCompaniesTable'
import { useQuery } from 'react-query'
import { getBranchCompanies } from 'services/companiesApi'
import Spinner from 'containers/App/components/Spinner'
import { USER_ROLES } from 'constants.js'
import UserInvites from '../UsersInvites'
import { useIntl } from 'react-intl'

const CompanyProfile = ({ auth, currUserCompany }) => {
  const { role } = auth
  const [company, setCompany] = useState(null)
  const { data: myCompany, refetch, isLoading, isFetching } = useQuery('branchCompanies', () => getBranchCompanies())
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (myCompany && !company) {
      setCompany(myCompany)
    }
  }, [myCompany, company])

  if (isLoading || !company || !myCompany || isFetching) {
    return <Spinner />
  }

  const canEdit = [USER_ROLES.SUPERADMIN, USER_ROLES.ADMIN].includes(role)
  const associatedCompaniesData = myCompany.hq ? [myCompany.hq] : myCompany.branches
  const canEditAssociatedCompanies = myCompany.hq ? false : canEdit
  return (
    <>
      <BranchCompaniesTable
        title={<strong>{formatMessage({ id: 'companies-and-users.my-company' })}</strong>}
        companies={[myCompany]}
        isLoading={isLoading || isFetching}
        canEdit={canEdit}
        refetch={refetch}
        filter={false}
        showCreateBranch={false}
        showBranchSettings={!!myCompany.hq}
        defaultExpandedRowIds={[myCompany.id]}
        currUserCompany={currUserCompany}
      />
      <BranchCompaniesTable
        title={formatMessage({ id: 'companies-and-users.associated-companies' })}
        companies={associatedCompaniesData}
        isLoading={isLoading || isFetching}
        canEdit={canEditAssociatedCompanies}
        refetch={refetch}
        filter={true}
        showCreateBranch={true}
        showBranchSettings={!myCompany.hq}
        currUserCompany={currUserCompany}
      />
      {company && canEdit && (
        <>
          <UserInvites />
        </>
      )}
    </>
  )
}

CompanyProfile.propTypes = {
  auth: PropTypes.object,
  currUserCompany: PropTypes.object
}

export default CompanyProfile
