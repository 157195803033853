import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FolderIcon from '@material-ui/icons/Folder'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useStyles } from './styles'
import { FTP_HOST, FTP_PORT } from 'constants.js'
import HiddenPasswordLabel from './HiddenPasswordLabel'
import { TreeItem, TreeView } from '@material-ui/lab'
import { Box } from '@material-ui/core'
import { useIntl } from 'react-intl'

const DataDeliveryFtp = ({ onChangeConfig, companyConfig }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch onChange={(e) => onChangeConfig(e.target.name, e.target.checked)} checked={!!companyConfig?.ftpActive} name="ftpActive" />}
            label={formatMessage({ id: 'data-delivery.enable-secure-ftp' })}
          />
        </Grid>
      </Grid>
      { companyConfig?.ftpActive ? (
        <Grid container>
          <Grid item xs={12} className={classes.textLabel}>
            <Card >
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">{formatMessage({ id: 'data-delivery.connection-details' })}</Typography>
                    <Typography component="p">
                      {formatMessage({ id: 'data-delivery.ftp-address-is' })}: {FTP_HOST}
                    </Typography>
                    <Typography component="p">
                      {formatMessage({ id: 'data-delivery.port' })}: {FTP_PORT} ({formatMessage({ id: 'data-delivery.sftp-protocol' })})
                    </Typography>
                    <Typography component="p">
                      {formatMessage({ id: 'data-delivery.user-id' })}: {companyConfig?.ftpUser}
                    </Typography>
                    <HiddenPasswordLabel password={companyConfig?.ftpPassword} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">{formatMessage({ id: 'data-delivery.instructions' })}</Typography>
                    <Typography>
                      {formatMessage({ id: 'data-delivery.files-must-be-uploaded-to-the' })} <code>/data/delivery</code> {formatMessage({ id: 'data-delivery.folder' })}
                    </Typography>
                    <TreeView
                      className={classes.treeView}
                      defaultCollapseIcon={<FolderOpenIcon />}
                      defaultSelected={[ '2' ]}
                      defaultExpanded={[ '1' ]}
                      defaultExpandIcon={<FolderIcon />}
                    >
                      <TreeItem nodeId="1" label="data">
                        <TreeItem icon={<FolderIcon />} className={classes.treeViewItem} nodeId="2" label={<Box display="flex" alignItems="center" flexWrap="wrap"><Typography>{formatMessage({ id: 'data-delivery.delivery' })}</Typography> <KeyboardBackspaceIcon className={classes.leftArrow} /></Box>} />
                        <TreeItem icon={<FolderIcon />} className={classes.treeViewItem} nodeId="3" label={formatMessage({ id: 'data-delivery.error' })} />
                        <TreeItem icon={<FolderIcon />} className={classes.treeViewItem} nodeId="4" label={formatMessage({ id: 'data-delivery.processed' })} />
                      </TreeItem>
                    </TreeView>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : null }
    </>
  )
}

DataDeliveryFtp.propTypes = {
  onChangeConfig: PropTypes.func,
  companyConfig: PropTypes.object
}

export default DataDeliveryFtp
