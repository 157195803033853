import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  dateTimeItem: {
    height: 50,
    margin: theme.spacing(1),
    minWidth: 100
  },
  gridItem: {
    minWidth: 290
  },
  section: {
    minHeight: 300,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2)
  },
  sectionLow: {
    minHeight: 300,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2)
  },
  fabMargin: {
    marginTop: theme.spacing(3)
  },
  fabExtendedIcon: {
    marginRight: theme.spacing(1)
  }
}))
