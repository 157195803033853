import Joi from 'joi';
import { PAYMENT_PLATFORMS } from 'constants.js';

export const getValidationSchema = (profileId, platform = PAYMENT_PLATFORMS.DIRECT_DEBIT_UK) => {
  const isUpdate = !!profileId;

  if (platform === PAYMENT_PLATFORMS.E_WALLET) {
    return formSchemaEWallet;
  }
  if (isUpdate) {
    return formSchemaUpdate;
  }
  return formSchemaCreate;
};

const sharedRules = {
  platform: Joi.string().optional().default(PAYMENT_PLATFORMS.DIRECT_DEBIT_UK),
  profileName: Joi.string().required().messages({
    'string.empty': `"Profile Name" cannot be an empty field`,
    'any.required': `"Profile Name" is a required field`,
  }),
  bottomlinePtxEmail: Joi.string().optional().allow(null).allow(''),
  bottomlinePtxPassword: Joi.string().optional().allow(null).allow(''),
  paymentId: Joi.string().optional().allow(null).allow(''),
  fundsClearingPeriod: Joi.number().required(),
};

const formSchemaCreate = Joi.object({
  ...sharedRules,
  bottomlinePtxEmail: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      'string.empty': `"Bottomline PTX email" cannot be an empty field`,
      'any.required': `"Bottomline PTX email" is a required field`,
      'string.email': `"Bottomline PTX email" must be a valid email`,
    }),
  bottomlinePtxPassword: Joi.string().required().min(3).messages({
    'string.empty': `"Bottomline PTX password" cannot be an empty field`,
    'any.required': `"Bottomline PTX password" is a required field`,
    'string.min': `"Bottomline PTX password" should have a minimum length of {#limit}`,
  }),
});

const formSchemaUpdate = Joi.object({
  ...sharedRules,
  bottomlinePtxPassword: Joi.string().allow('').required().min(3).messages({
    'string.empty': `"Bottomline PTX password" cannot be an empty field`,
    'any.required': `"Bottomline PTX password" is a required field`,
    'string.min': `"Bottomline PTX password" should have a minimum length of {#limit}`,
  }),
});

const formSchemaEWallet = Joi.object({
  ...sharedRules,
  profileName: Joi.string().required().messages({
    'string.empty': `"Profile Name" cannot be an empty field`,
    'any.required': `"Profile Name" is a required field`,
  }),
});
