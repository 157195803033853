import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import {
  Getter, Action, Plugin
} from '@devexpress/dx-react-core'
import useDebounce from 'utils/debounceHook'
const FilteringState = ({
  defaultFilters,
  filters = {},
  defaultDebounceTimeout = 1000,
  onFiltersChange
}) => {
  const [ gridFilters, setGridFilters ] = useState(filters ?? defaultFilters)

  const gridFiltersDebounced = useDebounce(gridFilters, defaultDebounceTimeout)

  // Propagate filters changed only when debounced value changes
  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange(gridFiltersDebounced)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ gridFiltersDebounced ])

  /*
    When filters change outside of the component, make sure we check equality with local filters and debounced filter value before setting it to be new filters
  */
  useEffect(() => {
    if (!isEqual(filters, gridFiltersDebounced) && !isEqual(filters, gridFilters)) {
      setGridFilters(filters)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ filters ])

  const handleChangeFilters = ({ key, value }) => {
    setGridFilters({
      ...filters,
      [key]: value
    })
  }

  const handleClearFilters = () => {
    setGridFilters(defaultFilters ?? filters)
  }

  return (
    <Plugin
      name="FilteringState"
    >
      <Getter name="filters" value={gridFilters} />
      <Action name="changeColumnFilter" action={handleChangeFilters} />
      <Action name="clearFilters" action={handleClearFilters} />
    </Plugin>
  )
}

FilteringState.propTypes = {
  defaultFilters: PropTypes.object,
  filters: PropTypes.object,
  defaultDebounceTimeout: PropTypes.number,
  onFiltersChange: PropTypes.func
}

export default FilteringState
