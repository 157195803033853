import { fullName } from 'utils/functions'

// Action Names
const FETCH_COUNTRIES = fullName('countries', 'FETCH_COUNTRIES')
const FETCH_COUNTRIES_SUCCESS = fullName('countries', 'FETCH_COUNTRIES_SUCCESS')
const FETCH_COUNTRIES_ERROR = fullName('countries', 'FETCH_COUNTRIES_ERROR')

export const ACTIONS = {
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR
}

export const fetchCountries = () => ({
  type: FETCH_COUNTRIES
})

const initialState = {
  countries: []
}

const countries = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload
      }
    default:
      return state
  }
}
export default countries
