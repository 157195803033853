import { connect } from 'react-redux'
import Component from './SimulationTool.js'

export const mapState = ({ companies }) => ({
  currUserCompany: companies?.currUserCompany
})

export const mapDispatch = () => ({})

export default connect(mapState, mapDispatch)(Component)
