import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Link, useLocation, matchPath } from 'react-router-dom'

const matchRouteIndex = (location, links) => links?.findIndex(link => matchPath(location.pathname, { path: link.to, exact: true })?.isExact)

const BreadcrumbMenuBase = ({ links }) => {
  const location = useLocation()
  return (
    <Tabs value={matchRouteIndex(location, links)}>
      { links.map(link =>
        <Tab key={link.to} label={link.label} component={Link} to={link.to} />
      ) }
    </Tabs>
  )
}

BreadcrumbMenuBase.propTypes = {
  links: PropTypes.array
}

export default BreadcrumbMenuBase
