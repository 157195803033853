import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    padding: 0 + '!important'
  }
})

const RowDetailToggleButton = ({ onToggle, expanded, row, isExpandable, tooltip = '' }) => {
  const classes = useStyles()

  if (!isExpandable?.(row)) {
    return <Table.Cell>&nbsp;</Table.Cell>
  }
  return (
    <Table.Cell className={classes.root}>
      <IconButton tabIndex={-1} onClick={onToggle}>
        <Tooltip title={tooltip}>{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Tooltip>
      </IconButton>
    </Table.Cell>
  )
}

RowDetailToggleButton.propTypes = {
  onToggle: PropTypes.func,
  expanded: PropTypes.bool,
  row: PropTypes.object,
  isExpandable: PropTypes.func,
  tooltip: PropTypes.string,
}

export default RowDetailToggleButton
