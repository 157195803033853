import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { ACTIONS, searchNotifications } from 'store/notifications'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { performGetNotifications, performPatchDismissNotifications } from '../requests'

const getNotificationsState = state => state.notifications

function* fetchNotifications({ payload }) {
  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const { data } = yield call(performGetNotifications, payload)
    yield put({ type: ACTIONS.FETCH_NOTIFICATIONS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: ACTIONS.FETCH_NOTIFICATIONS_ERROR, payload: e.message })
  } finally {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
  }
}

function* dismissNotifications({ payload }) {
  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const { filters } = yield select(getNotificationsState)
    yield call(performPatchDismissNotifications, payload)
    yield put({ type: ACTIONS.DISMISS_NOTIFICATIONS_SUCCESS })
    yield put(searchNotifications(filters))
  } catch (e) {
    yield put({ type: ACTIONS.FETCH_NOTIFICATIONS_ERROR, payload: e.message })
  } finally {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
  }
}

export default function* rootSaga() {
  const { FETCH_NOTIFICATIONS, DISMISS_NOTIFICATIONS } = ACTIONS
  yield all([
    takeLatest(FETCH_NOTIFICATIONS, fetchNotifications),
    takeLatest(DISMISS_NOTIFICATIONS, dismissNotifications)
  ])
}
