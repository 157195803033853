import { TYPE } from '../../formTypes'
import { corporateDocumentTypeStakeholder } from '../values'

export const contact = formatMessage => ({
  contactNo: {
    label: formatMessage({ id: 'kyb-form.contact-no' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
      'string.pattern.base',
    ],
  },
  email: {
    label: formatMessage({ id: 'kyb-form.email' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
      'string.email',
    ],
  },
  countryCode: {
    label: formatMessage({ id: 'kyb-form.country-code' }),
    type: TYPE.COUNTRY,
    required: true
  },
})

export const person = formatMessage => ({
  firstName: {
    label: formatMessage({ id: 'kyb-form.first-name' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  middleName: {
    label: formatMessage({ id: 'kyb-form.middle-name' }),
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  lastName: {
    label: formatMessage({ id: 'kyb-form.last-name' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  nationality: {
    label: formatMessage({ id: 'kyb-form.nationality' }),
    type: TYPE.COUNTRY,
    required: true
  },
  dateOfBirth: {
    label: formatMessage({ id: 'kyb-form.date-of-birth' }),
    type: TYPE.DATE,
    past: true,
    required: true
  },
})

export const document = formatMessage => ({
  document: {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE
  }
})


export const documentDetailsCorporateStakeholders = formatMessage => ({
  documentType: {
    label: formatMessage({ id: 'kyb-form.document-type' }),
    type: TYPE.SELECT,
    options: corporateDocumentTypeStakeholder,
    required: true,
  },
  "document.0.document": {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE,
    info: " File size limit: 1.5MB | Accepted formats: PDF, JPG, PNG",
    required: true,
  },
  documentNumber: {
    label: formatMessage({ id: 'kyb-form.document-number' }),
    type: TYPE.STRING,
    required: true,
    info: 'e.g. incorporation number or passport, etc...'
  },
  documentIssuanceCountry: {
    label: formatMessage({ id: 'kyb-form.document-issuance-country' }),
    type: TYPE.COUNTRY,
    required: true,
  },
})