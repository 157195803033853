import Compress from 'compress.js'
import { pdfjs as PDF } from 'react-pdf/dist/esm/entry.webpack'
import { createPdf } from 'pdfmake/build/pdfmake'
import { isProduction } from 'constants.js'

export const manageFileCompression = async (file, compressThresholdInMB) => {
  const { size } = file
  const compressThreshold = (compressThresholdInMB || 0.4) * 1000 * 1000 // default 400 KB
  
  if(size > compressThreshold) {
    // attempt file compression
    try {
      const compressed = await compressFile(file)
      return compressed
    } catch(e) {
      // "silent" error - will fallback on original file
      console.error(`Error compressing file ${file.name}. ${e}`)
    }
  }
  const data = await getBase64Data(file)
  return { data, size }
}

const getBase64Data = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
    reader.onabort = () => { reject('file reading was aborted') }
    reader.onerror = () => { reject('file reading has failed') }
    reader.onload = () => {
      const base64data = reader.result.split(',')[1]
      resolve(base64data)
    }
    reader.readAsDataURL(file)
})

const compressFile = async (originalFile) => {
  const { type, size } = originalFile
  let compressed;
  if (type.includes('image')) {
    compressed = compressImage(originalFile)
  } else if (type.includes('pdf')) {
    compressed = await compressPdf(originalFile)
  } else {
    throw new Error(`Unmanaged file format: ${type}`)
  }

  if(size < compressed.size) {
    throw new Error(`Compressed file is bigger than the original! Initial size: ${size}, final size: ${compressed.size}`)
  }
  return compressed
}

const compressImage = async (image, settings) => {
  const compress = new Compress()
  const defaultImgSettings = {
    size: 1, // the max size in MB, defaults to 2MB
    quality: 0.5, // the quality of the image, max is 1,
    // maxWidth: 1600, // the max width of the output image, defaults to 1920px
    // maxHeight: 1600, // the max height of the output image, defaults to 1920px
    resize: false, // defaults to true, set false if you do not want to resize the image width and height
  }
  const compressionSettings = settings || defaultImgSettings
  const [compressed] = await compress.compress([image], compressionSettings)
  
  if(!isProduction) {
    downloadFile(compressed.prefix + compressed.data, `Compressed_${image.name}`)
  }
  return {
    data: compressed.data,
    size: compressed.endSizeInMb * 1000 * 1000 // in bytes
  }
}

const downloadFile = (dataUrl, fileName) => {
  const anchor = document.createElement('a');
  anchor.id = `temp_download_image_${new Date().getTime()}`;
  anchor.download = fileName
  anchor.href = dataUrl
  anchor.click()
}

const compressPdf = async (file) => {
  // open document with pdfjs
  const docUrl = URL.createObjectURL(file)
  const pdfDoc = await getPdfDocument(docUrl)
  // transform pages into images, with compression
  const {
    _pdfInfo: {
      numPages
    }
  } = pdfDoc
  const pages = []
  for(let i = 0; i < numPages; i++) {
    const image = await getPageImg(pdfDoc, i + 1)
    pages.push(image)
  }
  // create new document
  const pdfContent = pages.map((p, idx) => ({
    image: p.data,
    height: p.height,
    width: p.width,
    ...(idx !== pages.length - 1 && { pageBreak: 'after' })
  }))
  const [first] = pages
  const newDoc = createPdf({
    info: {
      title: file.name,
    },
    pageSize: {
      width: first?.width || 600,
      height: first?.height || 600
  },
    pageMargins: [0, 0, 0, 0],
    content: pdfContent
  })

  if(!isProduction) {
    newDoc.getDataUrl((dataUrl) => {
      downloadFile(dataUrl, `Compressed_${file.name}`)
    })
  }
  const encoded = await getNewPdfDataAndSize(newDoc)
  return encoded
}

const getNewPdfDataAndSize = (newDoc) => new Promise((resolve) => {
  newDoc.getBase64((base64) => {
    resolve({
      data: base64,
      // linear function - dividing by 1.37 returns a good approximation of file size in Bytes
      size: base64.length / 1.37 
    })
  })
})

const getCanvas = () => {
  const canvasId = 'pdf-canvas-helper'
  const existing = document.querySelector(`canvas#${canvasId}`)
  if(existing) {
    return existing
  }
  const canvas = document.createElement('canvas');
  canvas.id = canvasId
  canvas.style.display = 'none'
  canvas.style.width = '100%'
  var body = document.getElementsByTagName("body")[0];
  body.appendChild(canvas);
  return getCanvas()
}

const getPdfDocument = (url) => {
  return new Promise((resolve, reject) => {
    const loadingTask = PDF.getDocument(url);
    loadingTask.promise.then(
      function (pdfDoc) {
        resolve(pdfDoc);
      },
    ).catch(e => reject(e));
  });
}

const getPageImg = async (doc, pageNum) => {
  const page = await doc.getPage(pageNum)
  const {
    width: ogWidth,
    height: ogHeight,
  } = page.getViewport({
    scale: 1.0
  });
  
  // use an upscaled viewport for image transformation
  // this results in higher quality and readability 
  const viewport = page.getViewport({
    scale: 1.5
  });
  const { height, width } = viewport
  const canvas = getCanvas()
  canvas.height = height
  canvas.width = width
  const renderTask = page.render({
    canvasContext: canvas.getContext('2d'),
    viewport: viewport,
  });

  return new Promise((resolve, reject) => {
    renderTask.promise.then(() => {
      // format + quality
      const data = canvas.toDataURL("image/png", 1)
      resolve({
        data,
        height: ogHeight,
        width: ogWidth,
      })
      
    }).catch((e) => reject(e))
  })
}
