import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'react-query'
import { del, get, post } from 'utils/api'
import { API_URL, USER_ROLES, SUBSCRIPTION_TYPES } from 'constants.js'
import { Grid, Typography, Card, CardContent } from '@material-ui/core'
import AuthView from 'containers/AuthView/connect'
import connect from './connect'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import { useStyles } from './styles'
import { ROUTE_URL } from 'constants.js'
import { Redirect } from 'react-router'
import ConfirmDialog from 'components/ConfirmDialog'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Divider from '@material-ui/core/Divider'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'
import { useForm, Controller } from "react-hook-form"
import Link from '@material-ui/core/Link'
import { snakeCase, transform } from 'lodash'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { useIntl } from 'react-intl'

const Section = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}))

const SaveSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(6),
}))

const CardTitleSection = styled(Grid)(({ theme }) => ({
  background: theme.palette.grey[200],
  display: 'flex',
}))

const Container = styled(Grid)(() => ({
  flexWrap: 'nowrap'
}))

const CustomCardContent = styled(CardContent)(() => ({
  display: 'flex',
}))

const CostLabel = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  textDecoration: 'underline',
}))

const Subscription = ({ location, auth, currUserCompany }) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { formatMessage } = useIntl()
  const formSchema = Joi.object({
    type: Joi.string().required(),
    premium: Joi.object().keys({
      tier: Joi.valid('TIER_1', 'TIER_2')
    }).when('type', { is: SUBSCRIPTION_TYPES.PREMIUM, then: Joi.object({ tier: Joi.required() }).required() }),
    options: Joi.object().keys({
      subscribe_as: Joi.object().keys({
        buyer: Joi.boolean(),
        seller: Joi.boolean()
      }),
      billing_options: Joi.string().valid('pay_monthly', 'pay_annually'),
      non_abta: Joi.string().valid('YES', 'NO')
    }).when('type', {
      is: SUBSCRIPTION_TYPES.PRO,
      then: Joi.object({
        subscribe_as: Joi.object().or('buyer', 'seller').required(),
        billing_options: Joi.required()
      }).required()
    }).when('type', {
      is: SUBSCRIPTION_TYPES.PREMIUM,
      then: Joi.object({
        non_abta: Joi.required()
      }).required()
    })
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isValid }
  } = useForm({
    resolver: joiResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      type: SUBSCRIPTION_TYPES.BASIC,
      options: {
        billing_options: 'pay_annually',
        non_abta: 'YES'
      }
    },
  })
  const typeSelected = watch('type')

  const onSubmit = (data) => {
    enableSubscription(data)
  }

  const { isLoading, data, refetch } = useQuery(`get-company-subscription`, () => get(`${API_URL}/subscription`), {
    retry: false,
  })

  useEffect(() => {
    if (data) {
      const { type, data: { tier, options } = {} } = data
      reset({
        type,
        ...tier && { premium: { tier } },
        // eslint-disable-next-line no-param-reassign
        options: transform(options, (result, value, key) => { result[snakeCase(key)] = value }, {})
      })
    }
  }, [data, reset])

  useEffect(() => {
    if (isDirty) {
      reset({
        type: typeSelected,
      }, { keepDirty: true })
    }
  }, [typeSelected, reset, isDirty])

  const { mutate: cancelSubscription } = useMutation(() => del(`${API_URL}/subscription`), {
    onSuccess: () => {
      toast.success(formatMessage({ id: 'subscription.subscription-updated' }))
      refetch()
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  const subscribe = async (props) => {
    return post(`${API_URL}/subscription`, { ...props })
  }

  const { mutate: enableSubscription } = useMutation(subscribe, {
    onSuccess: () => {
      toast.success(formatMessage({ id: 'subscription.subscription-updated' }))
      refetch()
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  if (!currUserCompany?.isHeadOffice) {
    return <Redirect to={ROUTE_URL.home} />
  }

  if (isLoading) {
    return (
      <AuthView location={location} title="Subscription">
        <Typography>Loading...</Typography>
      </AuthView>
    )
  }

  const renderSubscriptionLevel = ({ value: v, label: l, description, subDescription, cost, tiers, options }) => {
    return (
      <Grid item>
        <Card>
          <Container container alignItems="stretch" >
            <CardTitleSection item sm={3} md={2}>
              <CustomCardContent>
                <FormControlLabel
                  value={v}
                  control={<Radio color='primary' />}
                  label={<Typography variant='h5'>{l}</Typography>}
                />
              </CustomCardContent>
            </CardTitleSection>
            <Divider orientation="vertical" flexItem />
            <Grid item xs>
              <CardContent>
                <Container container direction="row" alignItems='center'>
                  <Grid item xs={9}>
                    <Typography {...subDescription && { paragraph: true }}>{description}</Typography>
                    <Typography>{subDescription}</Typography>
                    {(tiers || options) &&
                      <Section>
                        <Card variant='outlined'>
                          <CardContent>
                            <Grid container direction="row" spacing={10}>
                              {tiers &&
                                <Grid item>
                                  <Controller
                                    name="premium.tier"
                                    control={control}
                                    render={({ field }) =>
                                      <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                          {tiers.label}
                                        </FormLabel>
                                        <RadioGroup {...field}>
                                          {tiers.items.map(({ label: _label, value: _value }) => (
                                            <FormControlLabel key={_label} value={_value} control={<Radio color='primary' checked={watch('premium.tier') === _value} />} label={_label} />
                                          ))}
                                        </RadioGroup>
                                      </FormControl>
                                    }
                                  />
                                </Grid>
                              }
                              {options &&
                                <>
                                  {options.map(({ label: optionLabel, subLabel, info, items = [], value: optionValue, type }) => (
                                    <Grid item key={optionValue}>
                                      <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel component="legend">{optionLabel}</FormLabel>
                                        {subLabel &&
                                          <Container container alignItems="center" spacing={1}>
                                            <Grid item>
                                              <Typography>{subLabel}</Typography>
                                            </Grid>
                                            {info &&
                                              <Grid item>
                                                <Tooltip title={info}>
                                                  <InfoIcon fontSize="small" />
                                                </Tooltip>
                                              </Grid>
                                            }
                                          </Container>
                                        }
                                        <FormGroup>
                                          {type === 'checkbox' && items.map(({ label: optionItemLabel, value: optionItemValue }) => {
                                            const optionItemTag = `options.${optionValue}.${optionItemValue}`
                                            return (
                                              <Controller
                                                key={optionItemTag}
                                                name={optionItemTag}
                                                control={control}
                                                render={({ field }) =>
                                                  <FormControlLabel
                                                    control={<Checkbox {...field} checked={!!watch(optionItemTag) && watch('type') === v} color='primary' />}
                                                    label={
                                                      <Container container alignItems="center" spacing={1}>
                                                        <Grid item>
                                                          <Typography>{optionItemLabel}</Typography>
                                                        </Grid>
                                                      </Container>
                                                    }
                                                  />
                                                }
                                              />
                                            )
                                          })}
                                          {type === 'radio' &&
                                            <Controller
                                              name={`options.${optionValue}`}
                                              control={control}
                                              render={({ field }) =>
                                                <FormControl>
                                                  <RadioGroup {...field}>
                                                    {items.map(({ label: optionItemLabel, value: optionItemValue }) => (
                                                      <FormControlLabel key={optionItemLabel} value={optionItemValue} control={<Radio color='primary' checked={watch(`options.${optionValue}`) === optionItemValue} />} label={optionItemLabel} />
                                                    ))}
                                                  </RadioGroup>
                                                </FormControl>
                                              }
                                            />
                                          }
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>
                                  ))
                                  }
                                </>
                              }
                            </Grid>
                          </CardContent>
                        </Card>
                      </Section>
                    }
                  </Grid>
                  <Grid item>
                    <CardContent>
                      <CostLabel variant='h6'>{cost}</CostLabel>
                    </CardContent>
                  </Grid>
                </Container>
              </CardContent>
            </Grid>
          </Container>
        </Card>
      </Grid>
    )
  }

  return (
    <AuthView location={location} auth={auth} title="Subscription">
      <>
        <Typography variant='h2' paragraph>
          {formatMessage({ id: 'subscription.my-subscription' })}
        </Typography>
        <Typography paragraph>
          {formatMessage({ id: 'subscription.current-subscription' })}:
          <Typography display='inline' variant='h6'> {
            data?.type
              ? formatMessage({ id: `subscription-types.${data?.type?.toLowerCase()}` })
              : formatMessage({ id: 'subscription-types.basic' })
          }</Typography>
        </Typography>
        {auth.role !== USER_ROLES.NORMAL && <>
          <Typography paragraph>
            {formatMessage({ id: 'subscription.choose-your-preferred-subscription-plan-below' })}
          </Typography>
          <Controller
            name="type"
            control={control}
            render={({ field }) =>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup {...field}>
                  <Container container spacing={2} direction="column">
                    {renderSubscriptionLevel({
                      value: SUBSCRIPTION_TYPES.BASIC,
                      label: formatMessage({ id: 'subscription-types.basic' }),
                      description: formatMessage({ id: 'subscription.basic-is-for-smaller-buyers-that-only-transact-with-a-small-number-of-suppliers-and-do-not-need-to-settle-directly-with-the-sellers-for-example-they-pay-only-via-abta-or-advantage-direct-debit-facility-basic-does-not-include-automation-functionality' }),
                      cost: formatMessage({ id: 'subscription.free' }),
                    })}
                    {renderSubscriptionLevel({
                      value: SUBSCRIPTION_TYPES.PRO,
                      label: formatMessage({ id: 'subscription-types.pro' }),
                      description: formatMessage({ id: 'subscription.pro-is-the-subscription-level-required-for-suppliers-and-for-those-buyers-needing-data-import-export-capabilities-and-access-to-all-platform-automated-functionality-for-buyers-the-monthly-fee-varies-based-on-configured-branches-and-or-trade-i-ds-loaded' }),
                      subDescription: formatMessage({ id: 'subscription.for-sellers-data-exchange-fees-apply-starting-from-25-for-the-first-100-bookings-per-month' }),
                      options: [
                        {
                          label: formatMessage({ id: 'subscription.subscribe-as-select-all-that-apply' }),
                          value: 'subscribe_as',
                          type: 'checkbox',
                          items: [
                            {
                              label: formatMessage({ id: 'subscription.buyer' }),
                              value: 'buyer',
                            },
                            {
                              label: formatMessage({ id: 'subscription.seller' }),
                              value: 'seller',
                            }
                          ]
                        },
                        {
                          label: formatMessage({ id: 'subscription.choose-billing-options' }),
                          value: 'billing_options',
                          type: 'radio',
                          items: [
                            {
                              label: formatMessage({ id: 'subscription.pay-monthly' }),
                              value: 'pay_monthly',
                            },
                            {
                              label: formatMessage({ id: 'subscription.pay-annually' }),
                              value: 'pay_annually',
                            }
                          ]
                        }
                      ]
                    })}
                    {renderSubscriptionLevel({
                      value: SUBSCRIPTION_TYPES.PREMIUM,
                      label: formatMessage({ id: 'subscription-types.premium' }),
                      description: formatMessage({ id: 'subscription.the-premium-subscription-level-is-designed-for-suppliers-who-want-comprehensive-protection-for-both-sellers-and-buyers-against-counterparty-insolvency-it-s-priced-as-a-percentage-of-the-booking-value-and-includes-all-supplier-data-exchange-costs-except-for-tl-pay-charges' }),
                      subDescription: formatMessage({ id: 'subscription.please-review-all-specific-conditions-before-choosing-this-option' }),
                      cost: formatMessage({ id: 'subscription.0-44-of-booking-value' }),
                      tiers: {
                        label: formatMessage({ id: 'subscription.please-select-your-premium-subscription-tier' }),
                        items: [
                          {
                            label: formatMessage({ id: 'subscription.tier-1-up-to-500-000-annual-cover-with-a-20-k-excess' }),
                            value: 'TIER_1',
                          },
                          {
                            label: formatMessage({ id: 'subscription.tier-2-up-to-2-million-annual-cover-with-a-50-k-excess' }),
                            value: 'TIER_2',
                          }
                        ]
                      },
                      options: [
                        {
                          label: formatMessage({ id: 'subscription.optional-cover' }),
                          subLabel: formatMessage({ id: 'subscription.cover-for-non-abta-members-only' }),
                          value: 'non_abta',
                          type: 'radio',
                          info: formatMessage({ id: 'subscription.select-this-option-if-you-are-registered-with-abta-as-a-supplier-and-want-to-exclude-from-your-premium-cover-all-the-invoices-you-exchange-with-abta-agents-those-transactions-will-be-charged-at-the-pro-subscription-level' }),
                          items: [
                            {
                              label: formatMessage({ id: 'subscription.yes' }),
                              value: 'YES',
                            },
                            {
                              label: formatMessage({ id: 'subscription.no' }),
                              value: 'NO',
                            }
                          ]
                        }
                      ]
                    })}
                  </Container>
                </RadioGroup>
              </FormControl>
            }
          />
          <Section>
            <Link target="_blank" underline="always" href="https://www.travelledger.org/pricing/">
              <Typography>
                {formatMessage({ id: 'subscription.click-to-view-full-pricing-breakdown' })}
              </Typography>
            </Link>
          </Section>
          <SaveSection>
            <Button
              color={'primary'}
              onClick={() => setConfirmOpen(true)}
              disabled={!isDirty || !isValid}
            >
              {formatMessage({ id: 'subscription.confirm' })}
            </Button>
          </SaveSection>
        </>
        }
      </>
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={watch('type', SUBSCRIPTION_TYPES.BASIC) === SUBSCRIPTION_TYPES.BASIC ? cancelSubscription : handleSubmit(onSubmit)}
        yesText={'Confirm'}
        noText={'Cancel'}
      >
        <Typography paragraph>
          {formatMessage({ id: 'subscription.are-you-sure-you-want-to-change-your-subscription-to' })} <Typography display='inline' variant='h6'>{formatMessage({ id: `subscription-types.${watch('type')?.toLowerCase()}` })}</Typography> ?
        </Typography>
      </ConfirmDialog>
    </AuthView>
  )
}

Subscription.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(Subscription)
