import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(2),
      width: '550px'
    }
  },
  fab: {
    width: '120px',
    marginTop: '30px'
  },
  fabIcon: {
    marginRight: theme.spacing(1)
  }
}))
