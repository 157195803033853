import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const performGetNotifications = async (params) => {
  const url = `${API_URL}/notifications`
  return httpFetch(url, { urlParams: params })
}

export const performPatchDismissNotifications = async ids => {
  try {
    const url = `${API_URL}/notifications`
    const data = {
      method: 'PATCH',
      body: JSON.stringify(ids)
    }
    return httpFetch(url, data)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const performGetNotificationsConfig = async (id) => {
  const url = `${API_URL}/users/${id}`
  return httpFetch(url)
}

export const performPatchNotificationsConfig = async (id, body) => {
  try {
    const url = `${API_URL}/users/${id}`
    const data = {
      method: 'PATCH',
      body: JSON.stringify(body)
    }
    return httpFetch(url, data)
  } catch (error) {
    console.error(error)
    return null
  }
}
