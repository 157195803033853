import { API_URL } from 'constants.js'
import { makeAxiosConfig, post } from 'utils/api'
import handleApiFetchResponse from 'utils/handleApiFetchResponse'

export const performLogin = async authData => {
  const url = `${API_URL}/sessions`
  const data = {
    body: JSON.stringify(authData),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  }

  return handleApiFetchResponse(await fetch(url, data))
}

export const performRefresh = async () => {
  const url = `${API_URL}/sessions/current/renew`
  const data = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  }

  return await post(url, data)
}

export const performGetTokenExpiry = async () => {
  const url = `${API_URL}/sessions/current/expiry`
  const { headers } = makeAxiosConfig()

  // we don't want to use the `get()` util because it checks for 401
  // and this endpoint uses 401 to indicate that it has expired
  const response = await fetch(url, { headers })

  if (response.status === 401) {
    return 0 // expired
  }

  if (response.status === 204) {
    return null // no expiry
  }

  if (response.status === 200) {
    return await response.json()
  }

  await handleApiFetchResponse(response)
}

export const performLogout = async () => {
  const url = `${API_URL}/sessions/current`
  const data = {
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE'
  }

  return handleApiFetchResponse(await fetch(url, data))
}

export const performSetPassword = async passwordData => {
  const url = `${API_URL}/auth/password/${passwordData.passwordToken}`
  const data = {
    body: JSON.stringify({ password: passwordData.newPassword }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  }

  return handleApiFetchResponse(await fetch(url, data))
}

export const performSetupMFA = async ({ accessToken, recoveryKey = undefined }) => {
  const url = `${API_URL}/sessions/setup`
  const data = {
    body: JSON.stringify({ accessToken, recoveryKey }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  }
  return handleApiFetchResponse(await fetch(url, data))
}

export const performConfirmMFACode = async ({ otpToken, accessToken }) => {
  const url = `${API_URL}/sessions/verify`
  const data = {
    body: JSON.stringify({ otpToken, accessToken }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  }
  return handleApiFetchResponse(await fetch(url, data))
}

export const performResetPassword = async newPassword => {
  const url = `${API_URL}/user/password`
  const data = {
    body: JSON.stringify({ password: newPassword }),
    headers: { 'Content-Type': 'application/json' },
    method: 'PATCH'
  }
  return handleApiFetchResponse(await fetch(url, data))
}

export const performResetPasswordByToken = async resetData => {
  const url = `${API_URL}/users/password/reset-password`
  const data = {
    body: JSON.stringify(resetData),
    headers: { 'Content-Type': 'application/json' },
    method: 'PATCH'
  }
  return handleApiFetchResponse(await fetch(url, data))
}
