import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'
import FailedPaymentCheckbox from './FailedPaymentCheckbox'
import { useIntl } from 'react-intl'

const CommercialLinksTable = ({ commercialLinks, onCheck, onCheckFailedPayment }) => {
  const { formatMessage } = useIntl()

  const columns = [
    { title: formatMessage({ id: 'business-terms-list.business-terms' }), name: 'businessTerm', getCellValue: (row) => row?.businessTerms?.name },
    { title: formatMessage({ id: 'business-terms-list.seller' }), name: 'seller', getCellValue: (row) => row?.sellerCompany?.name },
    { title: formatMessage({ id: 'business-terms-list.pe' }), name: 'pe', getCellValue: (row) => row?.processingEntityCompany?.name },
    { title: formatMessage({ id: 'business-terms-list.buyer' }), name: 'buyer', getCellValue: (row) => row?.buyerCompany?.name },
    { title: formatMessage({ id: 'business-terms-list.commercial-link-description' }), name: 'description' },
    // eslint-disable-next-line react/display-name
    { title: formatMessage({ id: 'business-terms-list.mark-payment-as-failed' }), name: 'shouldFailPayment', getCellValue: (row) => <FailedPaymentCheckbox row={row} onToggle={onCheckFailedPayment(row)} /> }
  ]

  return (
    <DataGrid
      name="simulationGrid"
      columns={columns}
      data={commercialLinks}
      height={400}
      selectable={{
        onChange: onCheck
      }}
      sort={{
        disabled: true
      }}
    />
  )
}

CommercialLinksTable.propTypes = {
  commercialLinks: PropTypes.array.isRequired,
  onCheck: PropTypes.func,
  onCheckFailedPayment: PropTypes.func
}

export default memo(CommercialLinksTable)
