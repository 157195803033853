import { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { get as _get } from 'lodash'
import { DEFAULT_DATE_FORMAT, INVOICE_ROLE, INVOICE_TAGS, STATUSES } from 'constants.js'
import { _betweenDates, _contains, _equalsDate, convertQueryToNum } from 'utils/filters'
import {
  getBuyerIdFromInvoice,
  getBuyerIdTypeFromInvoice,
  getSellerIdFromInvoice,
  getSellerIdTypeFromInvoice,
} from 'utils/invoices'

export const validateCommit = (invoices) => (selection) => {
  const selectedRows = invoices.filter((invoice) => selection.includes(invoice.id))
  if (selectedRows.some((row) => ![STATUSES.MATCHED, STATUSES.ACCEPTED].includes(row.status))) {
    throw new Error('Only matched invoices can be committed')
  }
  const isNotOwner = selectedRows.some((row) => row?.role !== INVOICE_ROLE.SOURCE)
  if (isNotOwner) {
    throw new Error('Only owner can apply selected invoices')
  }
  const hasEmptyCommercialLink = selectedRows.some((row) => !row?.commercialLinkId && row.status !== STATUSES.MATCHED)
  if (hasEmptyCommercialLink) {
    throw new Error('Only invoices with a commercial link assigned are able to be committed')
  }
  const hasSuspendedOrArchivedCL = selectedRows.some(row =>
    row.tags.some(tag =>
      tag === INVOICE_TAGS.SUSPENDED_COMMERCIAL_LINK ||
      tag === INVOICE_TAGS.ARCHIVED_COMMERCIAL_LINK
    )
  )
  if (hasSuspendedOrArchivedCL) {
    throw new Error('Invoices with an Archived or Suspended commercial link cannot be committed')
  }
  return true
}

export const validateUpdatePartner = (invoices) => (selection) => {
  const selectedRows = invoices.filter((invoice) => selection.includes(invoice.id))
  const isNotOwner = selectedRows.some((row) => row?.role !== INVOICE_ROLE.SOURCE)
  if (isNotOwner) {
    throw new Error('Only owner can apply selected invoices')
  }
  return true
}

export const validateDelete = (invoices) => (selection) => {
  const selectedRows = invoices.filter((invoice) => selection.includes(invoice.id))
  const isNotOwner = selectedRows.some((row) => row?.role !== INVOICE_ROLE.SOURCE)
  if (isNotOwner) {
    throw new Error('Only owner can apply selected invoices')
  }
  return true
}

const getEditingRow = (invoices, changes) => {
  const lastModifiedRow = changes.find((change) => {
    const { updatedItem } = change
    return invoices.find((invoice) => {
      const updatedValues = {
        ...updatedItem?.updatedValues?.partnerNameOrId,
        ...updatedItem?.updatedValues?.commercialLinkName,
      }
      return (
        updatedItem.id === invoice.id &&
        (updatedValues?.targetId !== invoice?.updatedValues?.targetId ||
          updatedValues?.commercialLinkId !== invoice?.updatedValues?.commercialLinkId)
      )
    })
  })
  if (lastModifiedRow) {
    const { updatedItem, latestUpdatedValue } = lastModifiedRow
    return { updatedItem, latestUpdatedValue }
  }
}

export const useEditingInvoices = (_invoices) => {
  const [invoices, setInvoices] = useState(_invoices ?? [])
  const currentEditingRowData = useRef({})

  useEffect(() => {
    setInvoices(_invoices)
  }, [_invoices])

  const setRowData = (changes) => {
    const editingRow = getEditingRow(invoices, changes)
    if (editingRow) {
      const { updatedItem, latestUpdatedValue } = editingRow
      const spreadLatestUpdatedValue = {
        ...(latestUpdatedValue?.partnerNameOrId || latestUpdatedValue?.commercialLinkName),
      }
      if (updatedItem.id === currentEditingRowData?.current?.id) {
        currentEditingRowData.current = { ...currentEditingRowData.current, ...spreadLatestUpdatedValue }
      } else {
        currentEditingRowData.current = { id: updatedItem.id, ...spreadLatestUpdatedValue }
      }
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) => {
          if (invoice.id === updatedItem.id) {
            return { ...invoice, updatedValues: { ...invoice?.updatedValues, ...spreadLatestUpdatedValue } }
          }
          return invoice
        }),
      )
    }
    return currentEditingRowData.current
  }
  const setRowsData = (indexes, changeRow) => {
    const updatedRows = []
    setInvoices((prevInvoices) =>
      prevInvoices.map((invoice, index) => {
        const shouldUpdate = indexes.includes(index)
        if (shouldUpdate && changeRow) {
          const changes = changeRow(invoice)
          const updatedRow = { ...invoice, updatedValues: { ...invoice?.updatedValues, ...changes } }
          updatedRows.push(updatedRow)
          return updatedRow
        }
        return invoice
      }),
    )
    currentEditingRowData.current = {}
    return updatedRows
  }
  return {
    invoices,
    setRowData,
    setRowsData,
  }
}

export const filterValuesToQueryParams = (filterValues, currUserCompanyId) => {
  let filtersToSet = {}
  filtersToSet['sourceId[eq]'] = currUserCompanyId
  if (filterValues.bookingRef) {
    filtersToSet['bookingRef[contains]'] = filterValues.bookingRef
  }
  if (filterValues.leadName) {
    filtersToSet['leadName[contains]'] = filterValues.leadName
  }
  if (filterValues.departureDate) {
    const { startDate, endDate } = filterValues.departureDate
    if (moment(startDate).isSame(moment(endDate))) {
      filtersToSet['departureDate[eq]'] = moment(filterValues.departureDate).format(DEFAULT_DATE_FORMAT)
    } else {
      filtersToSet['departureDate[gt]'] = moment(filterValues.departureDate.startDate)
        .subtract(1, 'd')
        .format(DEFAULT_DATE_FORMAT)
      filtersToSet['departureDate[lt]'] = moment(filterValues.departureDate.endDate)
        .add(1, 'd')
        .format(DEFAULT_DATE_FORMAT)
    }
  }
  if (filterValues.status && filterValues.status.length) {
    filtersToSet['status[in]'] = filterValues.status
  }
  if (filterValues.commercialLinkName) {
    filtersToSet['commercialLinkId[eq]'] = filterValues.commercialLinkName
  }
  if (filterValues.limit) {
    filtersToSet.limit = filterValues.limit
  }
  if (filterValues.sorting) {
    filtersToSet = { ...filtersToSet, ...filterValues.sorting }
  }
  if (filterValues.paginationQuery) {
    filtersToSet = { ...filtersToSet, ...filterValues.paginationQuery }
  }
  return filtersToSet
}

// This helper replaces server-side search on client-side beacuse we are doing client-side filtering & pagination
export const filterLocalInvoices = (invoices, filters) => {
  return invoices.filter((invoice) => {
    if (filters.sellerName && !_contains(filters.sellerName, invoice.sourceCompany?.name)) {
      return false
    }
    if (filters.sellerIdType && !_contains(filters.sellerIdType, getSellerIdTypeFromInvoice(invoice))) {
      return false
    }
    if (filters.sellerId && !_contains(filters.sellerId, getSellerIdFromInvoice(invoice))) {
      return false
    }
    if (filters.partnerName && !_contains(filters.partnerName, _get(invoice, 'partnerName'))) {
      return false
    }
    if (filters.partnerIdType && !_contains(filters.partnerIdType, _get(invoice, 'partnerIdType'))) {
      return false
    }
    if (filters.partnerId && !_contains(filters.partnerId, _get(invoice, 'partnerId'))) {
      return false
    }
    if (filters.currency && filters.currency.length && !filters.currency.includes(invoice.currency)) {
      return false
    }
    if (filters.processingEntityCompanyName && filters.processingEntityCompanyName.length && !filters.processingEntityCompanyName.includes(invoice.processingEntityCompanyName)) {
      return false
    }
    if (filters.leadName && !_contains(filters.leadName, invoice.leadName)) {
      return false
    }
    if (filters.bookingRef && !_contains(filters.bookingRef, invoice.bookingRef)) {
      return false
    }
    if (filters.earliestDueDate && filters.earliestDueDate?.startDate) {
      const { startDate, endDate } = filters.earliestDueDate
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.earliestDueDate)) {
        return false
      } else if (!_betweenDates(invoice.earliestDueDate, startDate, endDate)) {
        return false
      }
    }
    if (filters.departureDate && filters.departureDate?.startDate) {
      const { startDate, endDate } = filters.departureDate
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.departureDate)) {
        return false
      } else if (!_betweenDates(invoice.departureDate, startDate, endDate)) {
        return false
      }
    }
    if (_get(filters, 'originalAmountInCents.value')) {
      if (
        (filters.originalAmountInCents.type === 'eq' &&
          !(
            convertQueryToNum(filters.originalAmountInCents.value) === parseFloat(invoice.originalAmountInCents, 10)
          )) ||
        (filters.originalAmountInCents.type === 'gte' &&
          !(convertQueryToNum(filters.originalAmountInCents.value) <= parseFloat(invoice.originalAmountInCents, 10))) ||
        (filters.originalAmountInCents.type === 'lte' &&
          !(convertQueryToNum(filters.originalAmountInCents.value) >= parseFloat(invoice.originalAmountInCents, 10)))
      ) {
        return false
      }
    }
    if (filters.status?.length && !filters.status.find((filtered) => filtered === invoice.status)) {
      return false
    }
    if (filters.tags?.length && !filters.tags.find((filtered) => invoice.tags.includes(filtered))) {
      return false
    }
    if (
      filters.commercialLinkDisplayName &&
      !_contains(filters.commercialLinkDisplayName, invoice.commercialLinkDisplayName)
    ) {
      return false
    }
    if (filters.reasonDescription && !_contains(filters.reasonDescription, invoice.reasonDescription)) {
      return false
    }
    if (filters.updatedAt && filters.updatedAt?.startDate) {
      const { startDate, endDate } = filters.updatedAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.updatedAt)) {
        return false
      } else if (!_betweenDates(invoice.updatedAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.updatedBy && !_contains(filters.updatedBy, invoice?.updatedBy?.name)) {
      return false
    }
    return true
  })
}

export const parseInvoices = (invoices) => {
  return invoices.map((invoice) => ({
    ...invoice,
    // https://zube.io/applied-blockchain/travel-ledger/tickets/658
    // Resolve updated with created if the row wasn't updated yet
    updatedBy: invoice?.updatedBy?.name || invoice?.createdBy?.name || `${invoice?.uploadType} (${invoice?.sender})`,
    updatedAt: invoice?.updatedBy ? invoice.updatedAt : invoice.createdAt,
    sellerId: getSellerIdFromInvoice(invoice),
    sellerName: invoice.sourceCompany?.name,
    sellerIdType: getSellerIdTypeFromInvoice(invoice),
    partnerName: invoice.targetCompany?.name,
    partnerIdType: getBuyerIdTypeFromInvoice(invoice),
    partnerId: getBuyerIdFromInvoice(invoice),
    commercialLinkDisplayName: invoice.commercialLink?.displayName,
    processingEntityCompanyName: invoice.commercialLink?.processingEntityCompanyName || 'Direct'
  }))
}

export const getDueDate = ({ earliestDueDate, commercialLink }) => {  
  if(earliestDueDate) return earliestDueDate
  return moment.utc().add(commercialLink?.dueDateOverrideDays ?? 0, 'days')
} 