import React, { useState } from 'react'
import FormattedError from 'components/FormattedError'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { postSimulation, getSimulations, deleteSimulation, getCommercialLinkCollectionDates } from 'services/simulationsApi'
import { getCommercialLinks } from 'services/commercialLinksApi'
import { getBusinessesTerms } from 'services/businessTermsApi'
import { getPaymentProfiles } from 'services/paymentProfilesApi'
import { getSettlementScheduleDates } from 'services/settlementSchedulesApi'
import moment from 'moment'

const today = moment()

const matchCommercialLink = (businessesTerms, paymentProfiles) => async (commercialLink) => {
  const businessTerms = businessesTerms.find(_businessTerms => _businessTerms.id === commercialLink.businessTermsId)
  const paymentProfile = paymentProfiles.find(_paymentProfile => _paymentProfile.id === commercialLink.paymentProfileId)
  const dates = await getSettlementScheduleDates(commercialLink?.settlementScheduleId, { from: today.format('YYYY-MM-DD'), to: today.clone().add(90, 'days').format('YYYY-MM-DD') })
  return { ...commercialLink, businessTerms, paymentProfile, dates }
}

const getCommercialLinksMatchedWithBusinessesTermsAndPaymentProfile = async (liveOnly) => {
  const [ commercialLinks, businessesTerms, paymentProfiles ] = await Promise.all([
    getCommercialLinks(liveOnly ? { 'status[eq]': 'LIVE' } : undefined),
    getBusinessesTerms(),
    getPaymentProfiles()
  ])
  const matchCommercialLinkWithBusinessesTerms = matchCommercialLink(businessesTerms, paymentProfiles)
  return await Promise.all(commercialLinks.map(matchCommercialLinkWithBusinessesTerms))
}

export const useGetCommercialLinks = (liveOnly = false) => useQuery('commercialLinks', () => getCommercialLinksMatchedWithBusinessesTermsAndPaymentProfile(liveOnly))
export const useGetSimulations = () => useQuery('simulations', () => getSimulations())
export const useGetCommercialLinkCollectionEvents = (commercialLinkId, date) =>
  useQuery(
    [ 'simulations', commercialLinkId, date ],
    () => getCommercialLinkCollectionDates(commercialLinkId, date),
    {
      enabled: !!commercialLinkId && !!date
    }
  )
export const usePostSimulation = (options) => useMutation(newSimulation => postSimulation(newSimulation), {
  onError: (error) => {
    toast.error(<FormattedError error={error} />)
  },
  ...options
})

export const useDeleteSimulation = (options) => useMutation(id => deleteSimulation(id), {
  onError: (error) => {
    toast.error(<FormattedError error={error} />)
  },
  ...options
})

export const useCommercialLinks = (remoteCommercialLinks = []) => {
  const [ commercialLinks, setCommercialLinks ] = useState(remoteCommercialLinks)

  const toggleFailedPayment = commercialLink => checked => {
    setCommercialLinks(prevCommercialLinks => prevCommercialLinks.map(prevCommercialLink => {
      if (prevCommercialLink.id === commercialLink.id) {
        return { ...prevCommercialLink, shouldFailPayment: checked }
      }
      return prevCommercialLink
    }))
  }

  const toggleCommercialLinks = (commercialLinksIndexes) => {
    setCommercialLinks(prevCommercialLinks => prevCommercialLinks.map((prevCommercialLink) => {
      const checked = commercialLinksIndexes.includes(prevCommercialLink.id)
      const shouldFailPayment = checked === true ? prevCommercialLink.shouldFailPayment : false
      return { ...prevCommercialLink, checked, shouldFailPayment }
    }))
  }

  return { commercialLinks, setCommercialLinks, toggleFailedPayment, toggleCommercialLinks }
}
