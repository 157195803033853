import { joiResolver } from '@hookform/resolvers/joi'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Button from 'components/Button'
import ReactHookFormMuiPasswordField from 'components/ReactHookFormMuiPasswordField'
import ReactHookFormMuiTextField from 'components/ReactHookFormMuiTextField'
import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const defaultValues = {
  currentPassword: '',
  otpToken: '',
  password: '',
  confirmPassword: ''
}

const PasswordUpdateForm = ({ userProfile, onSave }) => {
  const form = useForm({ resolver: joiResolver(formSchema), defaultValues })
  const { handleSubmit, formState: { errors }, control, reset } = form
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const textFieldProps = {
    fullWidth: true,
    margin: 'normal'
  }

  const onSubmit = handleSubmit((data) => {
    reset(defaultValues)
    onSave(data)
  })

  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <Card className={classes.topPaper}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} lg={3} className={classes.fieldSet}>
              <ReactHookFormMuiTextField
                label={formatMessage({ id: 'user-profile.current-password' })}
                control={control}
                name="currentPassword"
                type="password"
                required
                error={errors?.currentPassword}
                {...textFieldProps}
              />
              <ReactHookFormMuiPasswordField
                required={false}
                label={formatMessage({ id: 'user-profile.password' })}
                name="password"
                nameConfirm="confirmPassword"
                form={form}
                {...textFieldProps}
              />
              { userProfile?.isMfaSetup ? (
                <ReactHookFormMuiTextField
                  label={formatMessage({ id: 'user-profile.2-factor-authentication-code' })}
                  name="otpToken"
                  control={control}
                  error={errors?.otpToken}
                  inputProps={{
                    maxLength: 6
                  }}
                  fullWidth
                />
              ) : null }
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            disabled={!!Object.keys(errors).length}
            type="submit"
            size="large"
            color="success"
            aria-label="add"
            marginTop
            icon="save"
          >
            {formatMessage({ id: 'user-profile.update-password' })}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

PasswordUpdateForm.propTypes = {
  userProfile: PropTypes.object,
  onSave: PropTypes.func
}

export default PasswordUpdateForm
