import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  successColor: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.main
    }
  },
  errorColor: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  },
  abortColor: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText
    }
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
}))
