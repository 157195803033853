const getItem = (key) => {
  if (!key) {
    throw new Error(`
      You must provide an argument to getItem which is the
      item you would like to set
    `)
  }

  try {
    const item = window.localStorage.getItem(key)

    if (item === null) {
      return undefined
    }

    return item
  } catch (err) {
    return undefined
  }
}

const setItem = (key, item) => {
  if (key === undefined || item === undefined) {
    throw new Error(`
      You must provide two arguments to setItem. A key and an item to set.
    `)
  }

  try {
    window.localStorage.setItem(key, item)
  } catch (err) {
    throw new Error(`
      There was an error setting the item: ${item} to localStorage.
    `)
  }
}

const removeItem = (key) => {
  if (!key) {
    throw new Error(`
      You must provide an argument to removeItem which is the
      item you would like to remove
    `)
  }

  try {
    const item = window.localStorage.removeItem(key)

    if (item === null) {
      return undefined
    }

    return item
  } catch (err) {
    return undefined
  }
}

const removeAll = () => {
  try {
    window.localStorage.clear()
    window.sessionStorage.clear()
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
  } catch (err) {
    return undefined
  }
}

export const localStorage = {
  getItem,
  setItem,
  removeItem
}

export { removeAll }
