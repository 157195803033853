import { _contains } from 'utils/filters'

export const filterCompanies = (companies, filters) => {
  return companies.filter((company) => {
    const data = {
      ...company,
      ...company.codes,
    }

    for (const key of Object.keys(filters)) {
      if (key === 'inheritCommercialLink') {
        if (
          filters.inheritCommercialLink &&
          filters.inheritCommercialLink.length &&
          !filters.inheritCommercialLink.find((filtered) => filtered === data.inheritCommercialLink.toString())
        ) {
          return false
        }
      } else if (key === 'centrallyManaged') {
        if (
          filters.centrallyManaged &&
          filters.centrallyManaged.length &&
          !filters.centrallyManaged.find((filtered) => filtered === data.centrallyManaged.toString())
        ) {
          return false
        }
      } else if (key === 'usersCount') {
        if (
          filters.usersCount &&
          filters.usersCount.length &&
          !filters.usersCount.find((filtered) => filtered === (!!data.usersCount).toString())
        ) {
          return false
        }
      } else if ((filters[key] && !_contains(filters[key], data[key])) || !data[key]) {
        return false
      }
    }
    return true
  })
}
