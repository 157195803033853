import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  dropZone: {
    cursor: 'pointer',
    width: '100%',
    height: 150,
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'dashed',
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
