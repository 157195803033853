import Joi from 'joi'

const message = {
  'any.required': 'This field is required.',
  'string.empty': 'This field is required.',
}

export const formSchema = Joi.object({
  name: Joi.string().required().messages(message),
  codes: Joi.object().required().messages(message),
  address: Joi.string().max(255).required().messages(message),
  city: Joi.string().max(100).required().messages(message),
  telephone: Joi.string().max(50).allow('', null),
  website: Joi.string().uri().allow('', null),
  timezone: Joi.string().max(40).required().messages(message),
  countryId: Joi.string().required().messages(message),
  inheritCommercialLink: Joi.boolean(),
  centrallyManaged: Joi.boolean(),
  inheritWallet: Joi.boolean(),
})
