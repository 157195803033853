import React from 'react'
import FormattedError from 'components/FormattedError'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postImportCSV } from 'services/commercialLinksApi'

export const usePostImportCSV = (options) => useMutation(files => postImportCSV(files), {
  onError: (error) => {
    toast.error(<FormattedError error={error} />)
  },
  ...options,
  onSuccess: (result) => {
    if (!result.totalCount) {
      throw new Error('Invalid response')
    }

    options.onSuccess({
      summary: {
        failed: result.failedCount,
        success: result.successCount,
        total: result.totalCount
      },
      resultByFile: [
        {
          fileName: '',
          errors: result.errors
        }
      ]
    })
  }
})
