import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  button: {
    margin: theme.spacing(1)
  },
  successColor: {
    backgroundColor: theme.palette.success.main
  },
  errorColor: {
    backgroundColor: theme.palette.error.main
  }
}))
