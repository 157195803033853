import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SelectOnTabMenuItem from 'components/SelectOnTabMenuItem'
import { API_URL, PAYMENT_PLATFORMS } from 'constants.js'
import { get } from 'utils/api'
import { useGetPaymentProfile } from 'containers/SettlementScheduleEdit/hooks'
import { useIntl } from 'react-intl'

const searchHandler = debounce(
  async (text, setter, paymentProfileId) => {
    if (!text || !paymentProfileId) {
      return
    }
    const url = `${API_URL}/ptx-contacts/search`
    try {
      const result = await get(url, { q: text, paymentProfileId })
      setter(result.data)
    } catch (e) {
      console.error(e)
    }
  }, 250)

// eslint-disable-next-line react/display-name
export const PartPayments = React.memo(({
  classes,
  settlementSchedules,
  seller,
  buyer,
  paymentState,
  paymentActions,
  isBuyerOrSellerWithNoProcessingEntity,
  isBuyerOrSellerWithProcessingEntity,
  isReadOnly
}) => {

  const [ sellerContacts, setSellerContacts ] = useState([])
  const [ buyerContacts, setBuyerContacts ] = useState([])
  const [ sellerMandates, setSellerMandates ] = useState([])
  const [ buyerMandates, setBuyerMandates ] = useState([])

  const clearSellerContacts = () => {
    setSellerContacts([])
  }

  const clearBuyerContacts = () => {
    setBuyerContacts([])
  }

  const {
    settlementScheduleSelected,
    sellerContact,
    sellerMandateId,
    buyerContact,
    buyerMandateId
  } = paymentState

  const settleSchedule = useMemo(() => (
    settlementScheduleSelected
      ? settlementSchedules.find(ss => ss.id === settlementScheduleSelected)
      : undefined
  ), [ settlementScheduleSelected, settlementSchedules ])

  const { data: paymentProfile } = useGetPaymentProfile(settleSchedule?.paymentProfileId)
  const requirePaymentDetails = paymentProfile?.platform === PAYMENT_PLATFORMS.DIRECT_DEBIT_UK

  const { dispatchValue } = paymentActions
  const { formatMessage } = useIntl()

  useEffect(() => {
    const required = requirePaymentDetails

    // using empty object when required allows us to know if the field
    // is required or not without having to look at the payment profile
    // (used elsewhere such as submit button and payload creation, null
    // means it is not required)
    dispatchValue({ target: { name: 'sellerContact', value: required ? {} : null, required }})
    dispatchValue({ target: { name: 'sellerMandateId', value: required ? sellerMandateId : '', required }})
    dispatchValue({ target: { name: 'buyerContact', value: required ? {} : null, required }})
    dispatchValue({ target: { name: 'buyerMandateId', value: required ? buyerMandateId : '', required }})
  }, [ paymentProfile?.platform ])

  const getContactDetails = async (contactId, fieldName) => {
    const url = `${API_URL}/ptx-contacts/${contactId}`
    try {
      const contactResp = await get(url, { paymentProfileId: paymentProfile.id })
      if (contactResp.data.id) {
        const contactDetails = { id: contactResp.data.id, ...contactResp.data.attributes }
        dispatchValue({ target: { name: fieldName, value: contactDetails } })
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    (async () => {
      if (!sellerContact?.id || !paymentProfile) {
        setSellerMandates([])
        return
      }
      if (!sellerContact.companyName) {
        await getContactDetails(sellerContact.id, 'sellerContact')
      }
      const url = `${API_URL}/ptx-contacts/${sellerContact.id}/mandates`
      try {
        const result = await get(url, { paymentProfileId: paymentProfile.id })
        setSellerMandates(result.data)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [ sellerContact?.id, paymentProfile ])

  useEffect(() => {
    (async () => {
      if (!buyerContact?.id || !paymentProfile) {
        setBuyerMandates([])
        return
      }
      if (!buyerContact.companyName) {
        await getContactDetails(buyerContact.id, 'buyerContact')
      }
      const url = `${API_URL}/ptx-contacts/${buyerContact.id}/mandates`
      try {
        const result = await get(url, { paymentProfileId: paymentProfile.id })
        setBuyerMandates(result.data)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [ buyerContact?.id, paymentProfile ])

  const onAutocompleteValueSet = (contactFieldName, mandateFieldName, mandatesSetter) => {
    return (e, value) => {
      dispatchValue({ target: { name: contactFieldName, value: value || {} } })
      dispatchValue({ target: { name: mandateFieldName, value: '' } })
      mandatesSetter([])
    }
  }

  return (
    <React.Fragment>
      <Divider style={{ marginBottom: 20 }} />
      <Typography component="p">
        {formatMessage({ id: 'commercial-link-entity.payments' })}
      </Typography>
      <Grid spacing={3} container>
        {
          !isBuyerOrSellerWithProcessingEntity && (
            <Grid item xs={12} className={classes.half}>
              <FormControl className={classes.formInput} error={Boolean(paymentState.errorSettlementScheduleSelectedField)}>
                <InputLabel htmlFor="settlementScheduleSelected">{formatMessage({ id: 'commercial-link-entity.settlement-schedule-configuration' })} *</InputLabel>
                <Select
                  id="settlementScheduleSelected"
                  name="settlementScheduleSelected"
                  value={settlementScheduleSelected}
                  onChange={dispatchValue}
                  disabled={!!isReadOnly}
                  required
                  MenuProps={{variant: "menu"}}
                >
                  {
                    settlementSchedules.length > 0 && settlementSchedules.map((obj, k) => (
                      <SelectOnTabMenuItem name="settlementScheduleSelected" key={k} value={obj.id} onChange={dispatchValue}>{obj.name}</SelectOnTabMenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{paymentState.errorSettlementScheduleSelectedField}</FormHelperText>
              </FormControl>
            </Grid>
          )
        }
        {
          settlementScheduleSelected && requirePaymentDetails &&
          <Grid item xs={12} className={classes.half}>
            { !isBuyerOrSellerWithNoProcessingEntity ? (
              <Grid className={classes.paymentRoleRow} container>
                <div className={classes.paymentText}>Seller - {seller?.name || 'Seller name'}</div>
                <Autocomplete
                  className={classes.paymentTextInput}
                  value={sellerContact}
                  options={sellerContacts}
                  autoSelect
                  disabled={isReadOnly || !settlementScheduleSelected}
                  getOptionLabel={option => option.companyName || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Seller PTX Contact"
                      onChange={({ target }) => searchHandler(target.value, setSellerContacts, paymentProfile.id)}
                      onBlur={clearSellerContacts}
                      fullWidth
                      error={Boolean(paymentState.errorSellerContactField)}
                      helperText={paymentState.errorSellerContactField}
                      required
                    />
                  )}
                  onChange={onAutocompleteValueSet('sellerContact', 'sellerMandateId', setSellerMandates)}
                />
                <FormControl disabled={isBuyerOrSellerWithProcessingEntity} className={classes.paymentTextInput} error={Boolean(paymentState.errorSellerMandateIdField)}>
                  <InputLabel htmlFor="sellerMandateId">Seller Mandate ID *</InputLabel>
                  <Select
                    disabled={!sellerContact || !sellerContact?.id || !sellerMandates.length || isReadOnly || !settlementScheduleSelected}
                    key={`seller-mandate-select__${sellerMandateId}`}
                    id="sellerMandateId"
                    name="sellerMandateId"
                    value={sellerMandateId}
                    onChange={dispatchValue}
                    MenuProps={{variant: "menu"}}
                  >
                    {
                      sellerMandates.map((obj, k) => (
                        <SelectOnTabMenuItem name="sellerMandateId" key={k} value={obj.id} onChange={dispatchValue}>{obj.id}</SelectOnTabMenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText>{paymentState.errorSellerMandateIdField}</FormHelperText>
                </FormControl>
              </Grid>
            ) : null }
            {requirePaymentDetails && <Grid className={classes.paymentRoleRow} container>
              <div className={classes.paymentText}>Buyer - {buyer?.name || 'Buyer name'}</div>
              <Autocomplete
                disabled={isReadOnly || !settlementScheduleSelected}
                className={classes.paymentTextInput}
                value={buyerContact}
                options={buyerContacts}
                autoSelect
                getOptionLabel={option => option.companyName || ''}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Buyer PTX Contact"
                    onChange={({ target }) => searchHandler(target.value, setBuyerContacts, paymentProfile.id)}
                    onBlur={clearBuyerContacts}
                    fullWidth
                    error={Boolean(paymentState.errorBuyerContactField)}
                    helperText={paymentState.errorBuyerContactField}
                    required
                  />
                )}
                onChange={onAutocompleteValueSet('buyerContact', 'buyerMandateId', setBuyerMandates)}
              />
              <FormControl disabled={isBuyerOrSellerWithProcessingEntity} className={classes.paymentTextInput} error={Boolean(paymentState.errorBuyerMandateIdField)}>
                <InputLabel htmlFor="buyerMandateId">Buyer Mandate ID *</InputLabel>
                <Select
                  disabled={!buyerContact || !buyerContact.id || !buyerMandates.length || isReadOnly || !settlementScheduleSelected}
                  key={`buyer-mandate-select__${buyerMandateId}`}
                  id="buyerMandateId"
                  name="buyerMandateId"
                  value={buyerMandateId}
                  onChange={dispatchValue}
                  MenuProps={{variant: "menu"}}
                >
                  {
                    buyerMandates.map((obj, k) => (
                      <SelectOnTabMenuItem name="buyerMandateId" key={k} value={obj.id} onChange={dispatchValue}>{obj.id}</SelectOnTabMenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{paymentState.errorBuyerMandateIdField}</FormHelperText>
              </FormControl>
            </Grid>}
          </Grid>
        }
      </Grid>
    </React.Fragment>
  )
})

PartPayments.propTypes = {
  classes: PropTypes.object.isRequired,
  seller: PropTypes.object.isRequired,
  buyer: PropTypes.object.isRequired,
  settlementSchedules: PropTypes.array,
  paymentState: PropTypes.object,
  paymentActions: PropTypes.object,
  settlementScheduleSelected: PropTypes.string,
  isSellerWithNoProcessingEntity: PropTypes.bool,
  isBuyerOrSellerWithNoProcessingEntity: PropTypes.bool,
  isBuyerOrSellerWithProcessingEntity: PropTypes.bool,
  isReadOnly: PropTypes.bool
}
