import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import { ROUTE_URL, API_URL } from 'constants.js'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import SnackbarComponent from 'components/Snackbar'
import { Link, useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import { toast } from 'react-toastify'
import logo from 'assets/logo_brand.png'
import { Grid } from '@material-ui/core'
import BackOffCountDown from 'components/BackOffCountdown'
import { get } from 'utils/api'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'

const Login = ({ auth, destination, fetchAuth, replace, postAuth }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameError, setUsernameError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [isBackOffBlocked, setIsBackOffBlocked] = useState(false)
  const { formatMessage } = useIntl()

  const ERROR_EMPTY_FIELD = formatMessage({ id: 'login.this-field-is-required' })

  // get login message
  const { data } = useQuery(`get-login-message`, () => get(`${API_URL}/login-message`), {
    retry: false,
  })
  const { htmlMessage } = data || {}
  const hasMessage = !!htmlMessage


  useEffect(() => {
    if (queryParams.sessionExpired) {
      toast.error(formatMessage({ id: 'login.session-expired-please-login-again' }))
      history.replace(ROUTE_URL.login)
    }
  }, [queryParams])

  useEffect(() => {
    if (auth.token) {
      replace(destination)
    }
  }, [auth.token])

  const handleUsernameChange = ({ target }) => {
    setUsernameError(target.value ? null : ERROR_EMPTY_FIELD)
    setUsername(target.value)
  }
  const handlePasswordChange = ({ target }) => {
    setPasswordError(target.value ? null : ERROR_EMPTY_FIELD)
    setPassword(target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    fetchAuth({ auth: { username, password }, postAuth })
  }

  const classes = useStyles()

  const Form = (
    <>
      <div className={classes.logoContainer}>
        <img src={logo} className={classes.logo} alt="logo" />
      </div>
      <div className={classes.centerContainer}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <BackOffCountDown tryAgainAt={auth?.snackbar?.tryAgainAt} setIsBackOffBlocked={setIsBackOffBlocked} />
          <TextField
            onChange={handleUsernameChange}
            value={username}
            error={!!usernameError}
            helperText={usernameError}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="email"
            label={formatMessage({ id: 'login.email' })}
            name="username"
            autoComplete="email"
            autoFocus
          />
          <TextField
            onChange={handlePasswordChange}
            value={password}
            error={!!passwordError}
            helperText={passwordError}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password"
            label={formatMessage({ id: 'login.password' })}
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={isBackOffBlocked}
          />
          <Link className={classes.link} to={ROUTE_URL.forgotPassword}>
            {formatMessage({ id: 'login.forgot-your-password' })}
          </Link>
          <Button
            disabled={!!(usernameError || passwordError || auth.fetching)}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {formatMessage({ id: 'login.log-in' })}
          </Button>
        </form>
      </div>

      {hasMessage && <div className={classes.centerContainer}>
        <div className={classes.loginMessage} 
          dangerouslySetInnerHTML={{ __html: htmlMessage }}>
        </div>
      </div>
      }
    </>
  )

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Container component="main" className={classes.loginContainer}>
          {Form}
          </Container>
        </Grid>
      </Grid>
      <SnackbarComponent
        status={auth?.snackbar?.isOpened}
        error={auth?.snackbar?.isError}
        text={auth?.snackbar?.successOrErrorText}
      />
    </>
  )
}

Login.defaultProps = {
  destination: ROUTE_URL.home,
  postAuth: [],
  login: () => {},
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  destination: PropTypes.string.isRequired,
  fetchAuth: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  postAuth: PropTypes.arrayOf(PropTypes.string),
}

export default Login
