import { connect } from 'react-redux'
import Component from './AuthView.js'
import { fetchAuth, logout } from 'store/auth'
import { replace } from 'connected-react-router'
import selector from 'store/auth/selector'

export const mapState = ({ auth, routerReducer }) => ({
  auth, routerReducer,
  isAuthenticated: selector.isAuthenticated({ auth })
})

export const mapDispatch = dispatch => ({
  fetchAuth: data => dispatch(fetchAuth(data)),
  logout: data => dispatch(logout(data)),
  replace: data => dispatch(replace(data))
})

export default connect(mapState, mapDispatch)(Component)
