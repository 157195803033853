import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from '@devexpress/dx-react-core'
import BulkActionsTopSummary from '../components/BulkActionsTopSummary'

const pluginDependencies = [
  { name: 'Toolbar' },
  { name: 'SelectionState' },
  { name: 'EnhancedEditingState' }
]

const TableActionFooter = ({ actions }) => {
  return (
    <Plugin name="TableActionFooter" dependencies={pluginDependencies}>
      <Template name="footer">
        <Grid container alignItems="center" justify="space-between">
          <TemplateConnector>
            {({ selection }, { toggleSelection, bulkUpdateRows }) => {
              return (
                <BulkActionsTopSummary selection={selection} bulkUpdateRows={bulkUpdateRows} toggleSelection={toggleSelection} actions={actions} />
              )
            }}
          </TemplateConnector>
          <TemplatePlaceholder />
        </Grid>
      </Template>
    </Plugin>
  )
}

TableActionFooter.propTypes = {
  actions: PropTypes.array.isRequired
}

export default TableActionFooter
