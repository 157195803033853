import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import 'react-datepicker/dist/react-datepicker.css'
import { useStyles, CommitSwitch } from './styles'
import { Checkbox, CircularProgress } from '@material-ui/core'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DescriptionIcon from '@material-ui/icons/Description'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import MuiAlert from '@material-ui/lab/Alert'
import Tooltip from '@material-ui/core/Tooltip'
import useLocalStorage from 'utils/useLocalStorage'
import { useIntl } from 'react-intl'

const Csv = ({ uploadCSV, csv, setUploadCSVError, onCancel }) => {
  const [autoCommit, setAutoCommit] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState(null)
  const [uploadAgain, setUploadAgain] = useState(false)
  const [autoCommitStore, setAutoCommitStore ] = useLocalStorage(`@tldg:autocommit`, false)
  const { formatMessage } = useIntl()

  const classes = useStyles()

  useEffect(() => {
    setAutoCommit(autoCommitStore)
  }, [autoCommitStore])

  const bytesForHuman = (bytes, decimals = 2) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let b = bytes
    let i = 0
    for (i; b > 1024; i++) {
      b /= 1024;
    }
    return parseFloat(b.toFixed(decimals)) + ' ' + units[i]
  }

  const handleClickAutoCommit = () => {
    setAutoCommitStore(!autoCommit)
    setAutoCommit((prevAutoCommit) => !prevAutoCommit)
  }

  const handleUploadCSVFile = (files, force) => {
    if (force) {
      setUploadCSVError(null)
    }
    uploadCSV({ autoCommit, files, force })
  }

  const handleRemoveFile = (index) => {
    setUploadCSVError(null)
    setUploadAgain(false)
    const files = [...uploadedFiles]
    files.splice(index, 1)
    setUploadedFiles(files)
  }

  const { error, fetching } = csv
  const duplicateErrorModalOpen = error?.data[0]?.code === 'SPS11'
  const isREC = uploadedFiles?.some(file => file.name.toLowerCase().startsWith('rec'))

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item>
        <Dropzone
          multiple={false}
          onDrop={(files) => {
            setUploadCSVError(null)
            setUploadAgain(false)
            setUploadedFiles(files)
          }}
          className={classes.dropzone}
          accept={'.csv,.sps,.dat,.txt,.tra'}
        >
          <CloudUploadOutlinedIcon fontSize="large" />
          <label className={classes.label}>{formatMessage({ id: 'file-uploads.drop-file-here-or-click-to-choose-from-computer' })}</label>
        </Dropzone>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <CommitSwitch
              checked={!isREC ? autoCommit : false}
              onChange={handleClickAutoCommit}
              disabled={isREC}
            />
          }
          label={formatMessage({ id: 'file-uploads.auto-commit' })}
        />
      </Grid>
      <Grid item>
        <List dense={true}>
          {uploadedFiles?.map((file, index) => (
            <ListItem
              key={file.path}
              className={classes.listItem}
            >
              <ListItemIcon>
                {error ?
                  <Tooltip title={duplicateErrorModalOpen ? 'Duplicate File' : 'Invalid File'} placement="right-start">
                    <WarningIcon fontSize="large" className={duplicateErrorModalOpen ? classes.warningIcon : classes.errorIcon} />
                  </Tooltip>
                  :
                  <DescriptionIcon fontSize="large" color="primary" />
                }
              </ListItemIcon>
              <ListItemText
                primary={file.name}
                secondary={bytesForHuman(file.size)}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
                  {fetching ?
                    <CircularProgress disableShrink />
                    :
                    <DeleteIcon />
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
      {duplicateErrorModalOpen &&
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={uploadAgain}
                onChange={() => setUploadAgain(!uploadAgain)}
                value="Matched"
                color="primary"
              />
            }
            label={formatMessage({ id: 'file-uploads.this-file-has-already-been-imported-are-you-sure-you-want-upload' })}
          />
        </Grid>
      }
      {error && !duplicateErrorModalOpen &&
        <Grid item>
          <MuiAlert elevation={6} variant="filled" severity="error" icon={false}>
            {Array.isArray(error?.data) &&
              <ul className={classes.errorList}>
                {error.data.map((err, index) => (
                  <li key={index}>{err.message || err.detail}
                    <span className={classes.errorListSecondary}>
                      {err.row && `(Row ${err.row})`}
                      {err.lineNo && `(Line ${err.lineNo})`}
                    </span>
                  </li>
                ))}
              </ul>
            }
          </MuiAlert>
        </Grid>
      }
      <Grid container item className={classes.buttonsContainer}>
        <Grid item>
          <Button onClick={onCancel}>{formatMessage({ id: 'file-uploads.cancel' })}</Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!uploadedFiles?.length > 0 || (duplicateErrorModalOpen && !uploadAgain) || (error && !duplicateErrorModalOpen) || fetching}
            variant="contained"
            color="primary"
            onClick={() => handleUploadCSVFile(uploadedFiles, (duplicateErrorModalOpen && uploadAgain))}
          >
            {formatMessage({ id: 'file-uploads.upload' })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

Csv.propTypes = {
  uploadCSV: PropTypes.func.isRequired,
  setUploadCSVError: PropTypes.func.isRequired,
  csv: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default Csv
