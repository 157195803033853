import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { styled } from '@material-ui/core/styles'

export const CustomTabs = styled(Tabs)(({theme}) => ({
  marginTop: theme.spacing(1.5) + 'px',
}))

export const CustomTab = styled(Tab)(({theme}) => ({
  backgroundColor: theme.palette.grey['100'] + ' !important',
  '&.Mui-selected': {
    borderBottom: '5px solid ' + theme.palette.text.primary,
    backgroundColor: theme.palette.common.white + ' !important',
  }
}))

export const REGISTRATION_TABS = {
  companyDetails: {
    key: 0,
    label: 'Company details'
  },
  companyIds: {
    key: 1,
    label: 'Company IDs'
  },
  userDetails: {
    key: 2,
    label: 'User details'
  },
}
