import React from 'react'

import SnackbarComponent from 'components/Snackbar'
import AuthView from 'containers/AuthView/connect'
import { BreadcrumbMenuTransactions } from 'containers/BreadcrumbMenu'
import PropTypes from 'prop-types'
import CompanySettlementSummariesTable from './components/CompanySettlementSummariesTable'

const PaymentsStatement = ({ location, snackbar }) => {
  return (
    <AuthView location={location} title="Transactions">
      <BreadcrumbMenuTransactions />
      <CompanySettlementSummariesTable />
      <SnackbarComponent status={snackbar.isOpened} error={snackbar.isError} text={snackbar.successOrErrorText} />
    </AuthView>
  )
}

PaymentsStatement.propTypes = {
  location: PropTypes.object,
  snackbar: PropTypes.object,
}

export default PaymentsStatement
