import { joiResolver } from '@hookform/resolvers/joi'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Button from 'components/Button'
import ReactHookFormMuiTextField from 'components/ReactHookFormMuiTextField'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { useStyles } from './styles'
import { mapFormKeys } from 'utils/functions';
import { useIntl } from 'react-intl'

const defaultValues = {
  name: ''
}

const UserForm = ({ userProfile, onSave }) => {
  const { handleSubmit, formState: { errors }, reset, control } = useForm({ resolver: joiResolver(formSchema), defaultValues })
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const textFieldProps = {
    fullWidth: true,
    margin: 'normal'
  }

  useEffect(() => {
    reset(mapFormKeys(userProfile, defaultValues));
  }, [userProfile, reset])

  const onSubmit = handleSubmit((data) => {
    onSave(data)
  })

  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <Card className={classes.topPaper}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} md={3} className={classes.fieldSet}>
              <ReactHookFormMuiTextField
                label={formatMessage({ id: 'user-profile.name' })}
                control={control}
                name="name"
                required
                error={errors?.name}
                {...textFieldProps}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            disabled={!!Object.keys(errors).length}
            type="submit"
            size="large"
            color="success"
            aria-label="add"
            marginTop
            icon="save"
          >
            {formatMessage({ id: 'user-profile.save' })}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

UserForm.propTypes = {
  userProfile: PropTypes.object,
  onSave: PropTypes.func
}

export default UserForm
