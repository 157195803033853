import { connect } from 'react-redux'
import { push } from 'connected-react-router'

export const mapState = ({ auth, companies }) => ({
  auth,
  push,
  currUserCompany: companies.currUserCompany,
})

const mapDispatch = dispatch => ({
  push: (data) => dispatch(push(data))
})

export default connect(mapState, mapDispatch)
