import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import NavigationDrawerItem from '../NavigationDrawerItem'
import { useStyles } from './styles'

const NavigationDrawerSection = ({
  disabled,
  children,
  title,
  Icon,
  sectionName,
  activeSection,
  setActiveSection
}) => {
  const classes = useStyles()
  const isActive = activeSection === sectionName
  const handleClick = () => setActiveSection(isActive ? null : sectionName)
  return (
    <React.Fragment>
      <NavigationDrawerItem
        disabled={disabled}
        title={title}
        Icon={Icon}
        onClick={handleClick}
      />
      {(isActive) && (
        <ListItem className={classes.listItem}>
          <List className={classes.list}>
            {children}
          </List>
        </ListItem>
      )}
    </React.Fragment>
  )
}

NavigationDrawerSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]),
  Icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  sectionName: PropTypes.string.isRequired,
  activeSection: PropTypes.string,
  setActiveSection: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default NavigationDrawerSection
