import React from 'react'
import PropTypes from 'prop-types'
import PublishIcon from '@material-ui/icons/Publish'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const ExportButton = ({ buttonRef, onToggle }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <Button
      ref={buttonRef}
      variant="contained"
      color="secondary"
      size="small"
      onClick={onToggle}
      startIcon={<PublishIcon />}
      className={classes.root}
    >
      {formatMessage({ id: 'table-filters.export' })}
    </Button>
  )
}

ExportButton.propTypes = {
  onToggle: PropTypes.func,
  buttonRef: PropTypes.func
}

export default ExportButton
