import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  MonthView
} from '@devexpress/dx-react-scheduler-material-ui'

const DayScaleLayoutCalendar = (props) => <MonthView.DayScaleLayout {...props} style={{ minWidth: 'auto' }} />

DayScaleLayoutCalendar.propTypes = {
  cellsData: PropTypes.array.isRequired
}

export default memo(DayScaleLayoutCalendar)
