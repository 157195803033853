import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import { kebabCase, xor } from 'lodash'
import { Checkbox, Grid, FormControlLabel, FormGroup, FormLabel, InputAdornment, Switch, TextField } from '@material-ui/core'
import AuthView from 'containers/AuthView'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import Button from 'components/Button'
import FormSelect from 'components/FormSelect'
import HelpPopup from 'components/HelpPopup'
import Section from 'components/PaymentProfileConfigForm/components/Section'
import SnackbarComponent from 'components/Snackbar'
import {
  API_URL,
  ROUTE_URL,
  PERIOD_KEY_TITLE,
  FINANCIAL_FLOAT_OPTIONS,
  STATUSES,
  ERROR_SNACKBAR_TIMEOUT,
  SUCCESS_SNACKBAR_TIMEOUT,
  FEATURE_ALLOW_SETTLEMENT_OF_UNREVIEWED
} from 'constants.js'
import { get, patch, post } from 'utils/api'
import { getReadableErrorMessage } from 'utils/errors'
import { useBusinessTermsState } from './useBusinessTermsState'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const textFieldProps = {
  fullWidth: true,
  margin: 'normal'
}

const BusinessTermsConfiguration = ({
  location,
  history,
  currUserCompany
}) => {
  const { id: businessTermsId } = useParams()
  const [ exisingBusinessTerms, setExistingBusinessTerms ] = useState({})
  const [ buttonDisabled, setButtonDisabled ] = useState(false)

  const [ snackbar, setSnackbar ] = useState({
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  })

  const { formatMessage } = useIntl()

  const hoursInputProps = {
    ...textFieldProps,
    type: 'number',
    InputProps: {
      endAdornment: <InputAdornment position="end">({formatMessage({  id: 'business-terms-configuration.hours' })})</InputAdornment>
    }
  }

  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)

  const {
    state,
    handleDispatchValue: onChangeValue,
    handleDispatchCheckbox: onChangeCheckbox,
    handleDispatchSwitch: onChangeSwitch
  } = useBusinessTermsState(businessTermsId, exisingBusinessTerms)

  useEffect(() => {
    (async () => {
      if (businessTermsId) {
        const url = `${API_URL}/business-terms/${businessTermsId}`
        try {
          setGlobalSpinner(true)
          const result = await get(url)
          setExistingBusinessTerms(result.data.attributes)
          setGlobalSpinner(false)
        } catch (e) {
          console.error(e)
          setGlobalSpinner(false)
        }
      }
    })()
  }, [ businessTermsId ])

  const {
    name,
    nameError,
    deposit,
    depositPeriod,
    balance,
    balancePeriod,
    inclTxStatusSubmission,
    voidUnacceptedInvoices,
    restrictRefundAmendments,
    allowBuyerImport,
    financialFloatOption,
    manualEntryForwardsFunds,
  } = state

  const handleSubmit = async () => {
    const formData = {
      name: state.name,
      depositTerms: {
        numHours: deposit,
        when: depositPeriod
      },
      balanceTerms: {
        numHours: balance,
        when: balancePeriod
      },
      sellerRules: {
        validStatusesToInclude: state.inclTxStatusSubmission,
        voidUnacceptedInvoices: state.voidUnacceptedInvoices,
        restrictRefundAmendments: state.restrictRefundAmendments,
        voidNonLiveInvoices: state.voidNonLiveInvoices,
        allowBuyerImport: state.allowBuyerImport,
      },
      processorRules: {
        financialFloatOption,
        manualEntryForwardsFunds,
      }
    }

    try {
      setGlobalSpinner(true)
      setButtonDisabled(true)
      let result
      let successMsg

      if (businessTermsId) {
        const url = `${API_URL}/business-terms/${businessTermsId}`
        result = await patch(url, { data: { type: 'business-terms', attributes: formData } })
        successMsg = 'Business Terms edited.'
      } else {
        const url = `${API_URL}/business-terms`
        result = await post(url, { data: { type: 'business-terms', attributes: formData } })
        successMsg = 'Business Terms added.'
      }
      setGlobalSpinner(false)

      if (result.errors) {
        throw result.response
      }

      setSnackbar({
        isOpened: true,
        isError: false,
        successOrErrorText: successMsg
      })
      await sleep(SUCCESS_SNACKBAR_TIMEOUT)

      history.push(ROUTE_URL.businessTermsList)

    } catch (err) {
      setGlobalSpinner(false)
      setButtonDisabled(false)

      setSnackbar({
        isOpened: true,
        isError: true,
        successOrErrorText: getReadableErrorMessage(err)
      })
      await sleep(ERROR_SNACKBAR_TIMEOUT)
      setSnackbar({
        isOpened: false,
        isError: false,
        successOrErrorText: ''
      })
    }
  }

  const classes = useStyles()

  return (
    <AuthView location={location}>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.half}>
          <TextField
            onChange={onChangeValue}
            value={name || ''}
            className={classes.formInput}
            error={!!nameError}
            helperText={nameError}
            name="name"
            label={formatMessage({ id: 'business-terms-configuration.name' })}
            required
            {...textFieldProps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.half}>
          <TextField
            onChange={onChangeValue}
            value={deposit}
            className={classes.formInput}
            name="deposit"
            label={formatMessage({ id: 'business-terms-configuration.deposit' })}
            InputLabelProps={{ shrink: true }}
            {...hoursInputProps}
          />
        </Grid>
        <Grid item xs={6} className={classes.half}>
          <FormSelect
            className={classes.formInput}
            label={formatMessage({ id: 'business-terms-configuration.period' })}
            name="depositPeriod"
            onChange={onChangeValue}
            value={depositPeriod}
            keyTitle={Object.fromEntries(
              Object.entries(PERIOD_KEY_TITLE)
                .map(([k, v]) => [k, formatMessage({ id: `period-key-title.${kebabCase(v)}` })])
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.half}>
          <TextField
            onChange={onChangeValue}
            value={balance}
            className={classes.formInput}
            name="balance"
            label={formatMessage({ id: 'business-terms-configuration.balance' })}
            InputLabelProps={{ shrink: true }}
            {...hoursInputProps}
          />
        </Grid>
        <Grid item xs={6} className={classes.half}>
          <FormSelect
            className={classes.formInput}
            label={formatMessage({ id: 'business-terms-configuration.period' })}
            name="balancePeriod"
            onChange={onChangeValue}
            value={balancePeriod}
            keyTitle={Object.fromEntries(
              Object.entries(PERIOD_KEY_TITLE)
                .map(([k, v]) => [k, formatMessage({ id: `period-key-title.${kebabCase(v)}` })])
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.gridItem}>
          <Section title={formatMessage({ id: 'business-terms-configuration.seller-rules' })} className={classes.section}>
            <FormLabel component="legend">{formatMessage({ id: 'business-terms-configuration.tx-status-included-in-submission' })}</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  checked={inclTxStatusSubmission.includes(STATUSES.MATCHED)}
                  onChange={() => onChangeCheckbox('inclTxStatusSubmission')(xor(inclTxStatusSubmission, [ STATUSES.MATCHED ]))}
                  value={STATUSES.MATCHED}
                />}
                disabled={!FEATURE_ALLOW_SETTLEMENT_OF_UNREVIEWED}
                label={
                  <HelpPopup id="business-terms.blank">
                    {formatMessage({ id: 'business-terms-configuration.blank-unreviewed' })}
                  </HelpPopup>
                }
              />
              <FormControlLabel
                name="voidUnacceptedInvoices"
                control={<Checkbox
                  className={classes.nestedCheckbox}
                  color="primary"
                  disabled={inclTxStatusSubmission.includes(STATUSES.MATCHED)}
                  checked={voidUnacceptedInvoices}
                  onChange={(_, checked) => onChangeCheckbox('voidUnacceptedInvoices')(checked)}
                />}
                label={
                  <HelpPopup id="business-terms.void">
                    {formatMessage({ id: 'business-terms-configuration.void-unreviewed-invoices-at-the-end-of-the-review-period' })}
                  </HelpPopup>
                }
                className={classes.nestedCheckbox}
              />
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  checked={inclTxStatusSubmission.includes(STATUSES.ACCEPTED)}
                  // xor is used here for toggling status
                  onChange={() => onChangeCheckbox('inclTxStatusSubmission')(xor(inclTxStatusSubmission, [ STATUSES.ACCEPTED ]))}
                  value={STATUSES.ACCEPTED}
                />}
                label={formatMessage({ id: 'invoice-status.accepted' })}
              />
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  checked={inclTxStatusSubmission.includes(STATUSES.AMENDED)}
                  onChange={() => onChangeCheckbox('inclTxStatusSubmission')(xor(inclTxStatusSubmission, [ STATUSES.AMENDED ]))}
                  value={STATUSES.AMENDED}
                />}
                label={formatMessage({ id: 'invoice-status.amended' })}
              />
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  checked={inclTxStatusSubmission.includes(STATUSES.REJECTED)}
                  disabled
                  onChange={() => onChangeCheckbox('inclTxStatusSubmission')(xor(inclTxStatusSubmission, [ STATUSES.REJECTED ]))}
                  value={STATUSES.REJECTED}
                />}
                label={formatMessage({ id: 'invoice-status.rejected' })}
              />
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  checked={state.voidNonLiveInvoices}
                  onChange={({ target }) => onChangeCheckbox('voidNonLiveInvoices')(target.checked)}
                  value="voidNonLiveInvoices"
                />}
                label={formatMessage({ id: 'business-terms-configuration.auto-void-transactions-for-non-live-commercial-links' })}
              />
            </FormGroup>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <FormControlLabel
                  name="restrictRefundAmendments"
                  control={<Checkbox
                    checked={restrictRefundAmendments}
                    onChange={(_, checked) => onChangeCheckbox('restrictRefundAmendments')(checked)}
                    color="primary"
                  />}
                  label={formatMessage({ id: 'business-terms-configuration.restrict-refund-amendments' })}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <FormControlLabel
                  name="allowBuyerImport"
                  control={<Checkbox
                    checked={allowBuyerImport}
                    onChange={(_, checked) => onChangeCheckbox('allowBuyerImport')(checked)}
                    color="primary"
                  />}
                  label={formatMessage({ id: 'business-terms-configuration.allow-buyer-import' })}
                />
              </Grid>
            </Grid>
          </Section>
        </Grid>
        {currUserCompany.isProcessingEntity === true &&
          <Grid item xs={6} className={classes.gridItem}>
            <Section title={formatMessage({ id: 'business-terms-configuration.processor-rules' })} className={classes.sectionLow}>
              <FormSelect
                className={classes.formInput}
                label={formatMessage({ id: 'business-terms-configuration.financial-float-provision' })}
                name="financialFloatOption"
                onChange={onChangeValue}
                value={financialFloatOption}
                keyTitle={Object.fromEntries(
                  Object.entries(FINANCIAL_FLOAT_OPTIONS)
                    .map(([k, v]) => [k, formatMessage({ id: `financial-float-options.${kebabCase(v)}` })])
                )}
              />
              <FormControlLabel
                control={<Switch checked={manualEntryForwardsFunds} onChange={onChangeSwitch} value="manualEntryForwardsFunds" />}
                label={formatMessage({ id: 'business-terms-configuration.forward-funds-when-offline-remittance-entered-manually-in-tl' })}
              />
            </Section>
          </Grid>
        }
      </Grid>
      <Button
        size="large"
        color="success"
        aria-label={formatMessage({ id: 'business-terms-configuration.save' })}
        disabled={!!(nameError) || buttonDisabled}
        onClick={handleSubmit}
        marginTop
        icon="save"
      >
        {formatMessage({ id: 'business-terms-configuration.save' })}
      </Button>
      <SnackbarComponent
        status={snackbar.isOpened}
        error={snackbar.isError}
        text={snackbar.successOrErrorText}
      />
    </AuthView>
  )
}

BusinessTermsConfiguration.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  currUserCompany: PropTypes.object
}

export default BusinessTermsConfiguration
