import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import AuthView from 'containers/AuthView'
import { ROUTE_URL } from '../../constants'
import DataGrid from 'components/DataGrid'
import { useQuery } from 'react-query'
import { getSettlementSchedules } from 'services/settlementSchedulesApi'
import Button from 'components/Button'
import { useIntl } from 'react-intl'

export const columns = formatMessage => [
  {
    name: 'id',
    title: formatMessage({ id: 'settlement-schedules.id' })
  },
  {
    name: 'name',
    title: formatMessage({ id: 'settlement-schedules.name' })
  }
]

const SettlementSchedulesList = ({
  location,
  push
}) => {
  const { data } = useQuery('settlementSchedules', () => getSettlementSchedules(), { refetchOnWindowFocus: false })
  const settlementSchedules = data?.data ?? []
  const { formatMessage } = useIntl()

  const handleEditSettlementSchedule = (selection) => {
    push(ROUTE_URL.settlementScheduleEdit.replace(':id?', selection))
  }

  return (
    <AuthView location={location} title="Settlement Schedules">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <DataGrid
            name="settlement_schedules"
            columns={columns(formatMessage)}
            defaultHiddenColumns={[ 'id' ]}
            data={settlementSchedules}
            fullRemainingWindowHeight
            selectable={{
              actions: [
                {
                  title: formatMessage({ id: 'settlement-schedules.edit' }),
                  icon: 'edit',
                  onClick: handleEditSettlementSchedule,
                  validate: (selection) => {
                    if (selection?.length !== 1) {
                      throw new Error(formatMessage({ id: 'settlement-schedules.you-must-select-only-one-settlement-schedule' }))
                    }
                    return true
                  }
                }
              ]
            }}
            extra={<Button icon="add" size="small" color="primary" onClick={() => push(ROUTE_URL.settlementScheduleEdit.replace(':id?', ''))}>
              {formatMessage({ id: 'table-filters.create' })}
            </Button>}
          />
        </Grid>
      </Grid>
    </AuthView>
  )
}

SettlementSchedulesList.propTypes = {
  location: PropTypes.object,
  push: PropTypes.func
}

export default SettlementSchedulesList
