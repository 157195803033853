import { COMMERCIAL_LINK_STATUS } from '../../constants'
import { useEffect, useState, useRef } from 'react'
import { _betweenDates, _contains, _equalsDate } from 'utils/filters'

export const useEditingCommercialLinks = (_commercialLinks) => {
  const [ commercialLinks, setCommercialLinks ] = useState(_commercialLinks ?? [])
  const currentEditingRowData = useRef({})

  useEffect(() => {
    setCommercialLinks(_commercialLinks)
  }, [ _commercialLinks ])

  const setRowsData = (ids, changeRow) => {
    const updatedRows = []
    setCommercialLinks((prevInvoices => prevInvoices.map((invoice) => {
      const shouldUpdate = ids.includes(invoice.id)
      if (shouldUpdate && changeRow) {
        const changes = changeRow(invoice)
        const updatedRow = { ...invoice, updatedValues: { ...invoice?.updatedValues, ...changes } }
        updatedRows.push(updatedRow)
        return updatedRow
      }
      return invoice
    })))
    currentEditingRowData.current = {}
    return updatedRows
  }
  return {
    commercialLinks,
    setRowsData
  }
}

export const getSellerIdType = (commercialLink) => commercialLink.sellerCodeType || Object.keys(commercialLink.sellerCompany.codes)[0] || '-'
export const getSellerId = (commercialLink) => {
  const type = commercialLink.sellerCodeType || Object.keys(commercialLink.sellerCompany.codes)[0]
  return type ? commercialLink.sellerCompany.codes[type] : '-'
}
export const getBuyerIdType = (commercialLink) => commercialLink.buyerCodeType || Object.keys(commercialLink.buyerCompany.codes)[0] || '-'
export const getBuyerId = (commercialLink) => {
  const type = commercialLink.buyerCodeType || Object.keys(commercialLink.buyerCompany.codes)[0]
  return type ? commercialLink.buyerCompany.codes[type] : '-'
}

export const filterLocalCommercialLinks = (commercialLinks, filters) => {
  return commercialLinks.filter((commercialLink) => {
    if (filters['buyerName'] && !_contains(filters['buyerName'], commercialLink.buyerCompany.name)) {
      return false
    }
    if (filters['buyerIdType'] && !_contains(filters['buyerIdType'], getBuyerIdType(commercialLink))) {
      return false
    }
    if (filters['buyerId'] && !_contains(filters['buyerId'], getBuyerId(commercialLink))) {
      return false
    }
    if (filters['sellerName'] && !_contains(filters['sellerName'], commercialLink.sellerCompany.name)) {
      return false
    }
    if (filters['sellerIdType'] && !_contains(filters['sellerIdType'], getSellerIdType(commercialLink))) {
      return false
    }
    if (filters['sellerId'] && !_contains(filters['sellerId'], getSellerId(commercialLink))) {
      return false
    }
    if (filters['businessTermsName'] && !_contains(filters['businessTermsName'], commercialLink.businessTerms?.name)) {
      return false
    }
    if (filters['role'] && !filters['role'].find(filtered => filtered === commercialLink.role)) {
      return false
    }
    if (filters['dueDateOverrideDays'] && filters['dueDateOverrideDays'] !== commercialLink.dueDateOverrideDays.toString()) {
      return false
    }
    if (
      filters['status'] &&
      !filters['status'].find(filtered => {
        if (filtered === COMMERCIAL_LINK_STATUS.SUSPENDED) {
          return commercialLink.status?.toLowerCase().includes(filtered.toLowerCase())
        }
        return filtered === commercialLink.status
      })
    ) {
      return false
    }
    if (filters.description && !_contains(
      filters.description, 
      commercialLink.displayName
    )) {
      return false
    }
    if (filters.updatedAt && filters.updatedAt?.startDate) {
      const { startDate, endDate } = filters.updatedAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, commercialLink.updatedAt)) {
        return false
      } else if (!_betweenDates(commercialLink.updatedAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.updatedBy && !_contains(filters.updatedBy, commercialLink?.updatedBy?.name)) {
      return false
    }

    return true
  })
}
