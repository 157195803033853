import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import className from 'classnames'
import zxcvbn from 'zxcvbn'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import { REQUIRED_PASSWORD_STRENGTH } from 'constants.js'
import { useIntl } from 'react-intl'

const getError = (zxc, error) => {
  if (zxc.password && zxc.score < REQUIRED_PASSWORD_STRENGTH) {
    return 'Password too weak'
  }
  if (error) {
    return 'Password is required'
  }
  return ''
}

const PasswordStrengthInput = ({
  minStrength = REQUIRED_PASSWORD_STRENGTH,
  thresholdLength = 6,
  onChange = () => {},
  value = '',
  name,
  error,
  ...props
}) => {
  const classes = useStyles()
  const [zxcvbnObject, setZxcvbnObject] = useState(zxcvbn(value))
  const { formatMessage } = useIntl()

  useEffect(() => {
    const { password, score } = zxcvbnObject
    if (password?.length >= thresholdLength && score >= minStrength) {
      onChange({ target: { name, value: password, valid: true } })
      return
    }
    onChange({ target: { name, value: password, valid: false } })
  }, [zxcvbnObject])

  const onChangeInput = (event) => {
    const { value: _value } = event.target
    setZxcvbnObject(zxcvbn(_value))
  }

  return (
    <span className={classes.root}>
      <TextField
        {...props}
        type="password"
        onChange={onChangeInput}
        value={zxcvbnObject.password}
        fullWidth
        error={Boolean(error || (zxcvbnObject.password && zxcvbnObject.score < REQUIRED_PASSWORD_STRENGTH))}
        helperText={getError(zxcvbnObject, error)}
      />
      {zxcvbnObject.score < REQUIRED_PASSWORD_STRENGTH && (
        <Typography className={classes.helperText}>
          {formatMessage({ id: 'password-strength-input.increase-length-and-complexity-until-the-bar-turns-green' })} <br />
          {formatMessage({ id: 'password-strength-input.e-g-include-symbols-capital-letters-special-characters' })}
        </Typography>
      )}
      <div className={classes.strengthMeter}>
        <div className={className(classes.strengthMeterFill, [`strengthMeterFill${zxcvbnObject?.score ?? 0}`])}></div>
      </div>
      <Typography variant="caption">{zxcvbnObject?.feedback?.warning}</Typography>
    </span>
  )
}

PasswordStrengthInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  minStrength: PropTypes.number,
  thresholdLength: PropTypes.number,
  error: PropTypes.object,
}

export default PasswordStrengthInput
