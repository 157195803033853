import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { ROUTE_URL } from 'constants.js'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import MFACodeVerificationForm from 'components/MFACodeVerificationForm/MFACodeVerificationForm'
import { Link } from 'react-router-dom'

const SetupAuthenticator = ({
  confirmMFACode,
  auth
}) => {
  const classes = useStyles()
  
  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome to Travel Ledger
        </Typography>
        <div className={classes.form}>
          <MFACodeVerificationForm confirmMFACode={confirmMFACode} tryAgainAt={auth?.tryAgainAt} />
          <Typography component="h1" variant="body2">
            Lost your trusted device? <Link to={ROUTE_URL.resetAuthenticator}>Reset two-factor authentication</Link>
          </Typography>
        </div>
      </div>
    </Container>
  )
}

SetupAuthenticator.defaultProps = {
  destination: ROUTE_URL.home,
  postAuth: [],
  confirmMFACode: () => {
  }
}

SetupAuthenticator.propTypes = {
  confirmMFACode: PropTypes.func.isRequired
}

export default SetupAuthenticator
