import { ROUTE_URL, USER_AUTH_STORAGE_KEYS } from 'constants.js'
import { AuthError } from 'utils/errors'

const handleApiFetchResponse = async response => {
  if (response.status === 204) {
    return
  }
  if (response.status === 500) {
    let errorMessage
    try {
      const responseData = await response.json()
      errorMessage = responseData?.errors[0]?.detail
    } catch (error) {
      errorMessage = 'Unknown error, try again later'
    }
    throw new Error(errorMessage)
  }
  if (response.status === 503) {
    Object.values(USER_AUTH_STORAGE_KEYS).forEach(item => localStorage.removeItem(item))
    window.location.href = ROUTE_URL.maintenanceMode
    return
  }
  if (response.status === 401) {
    Object.values(USER_AUTH_STORAGE_KEYS).forEach(item => localStorage.removeItem(item))
    window.location.href = `${ROUTE_URL.login}?sessionExpired=true`
    return
  }
  const responseData = await response.json()
  if (Reflect.has(responseData, 'errors') && Array.isArray(responseData.errors)) {
    throw new AuthError(responseData.errors[0].detail, response.headers.get('x-retry-at'))
  }
  return responseData
}

export default handleApiFetchResponse
