import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Modal from 'components/Modal'
import { CL_STATUS_TO_NAME, COMMERCIAL_LINK_STATUS } from 'constants.js'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

/**
 * @param {{
 * value: import('constants.js').CommercialLinkStatus
 * onChange: React.ChangeEvent<HTMLInputElement>
 * }} param0 
 */
const StatusRadioGroup = ({ value, onChange }) => {
  const options = Object
    .values(COMMERCIAL_LINK_STATUS)
    .map(status => <FormControlLabel
      key={status}
      value={status}
      label={CL_STATUS_TO_NAME[status] ?? status}
      control={<Radio />}
    />)

  return <RadioGroup
    value={value}
    name="status"
    aria-label="status"
    onChange={onChange}
  >
    {options}
  </RadioGroup>
}

StatusRadioGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const ModalUpdateStatus = ({ isOpen, onCloseClick }) => {
  const modalRef = useRef()
  const [status, setStatus] = useState(COMMERCIAL_LINK_STATUS.LIVE)
  const [archiveWarning, setArchiveWarning] = useState(false)

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.open()
    }
  }, [isOpen])

  const handleChangeStatus = ev => {
    setStatus(ev.target.value)
  }

  const update = () => {
    modalRef.current.close()
    onCloseClick(status)
  }

  const handleSaveClick = () => {
    if (status === COMMERCIAL_LINK_STATUS.ARCHIVED) {
      setArchiveWarning(true)
      return
    }
    update()
  }

  return (<>
    <Modal ref={modalRef} defaultOpened={isOpen} onClose={onCloseClick}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <FormControl component="fieldset">
            <StatusRadioGroup
              value={status}
              onChange={handleChangeStatus}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" justify="flex-end">
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleSaveClick}>Save</Button>
        </Grid>
      </Grid>
    </Modal>
    {archiveWarning && <Modal
      title={'Archive commercial link'}
      defaultOpened={archiveWarning}
      onClose={() => {
        setArchiveWarning(false)
      }}
      maxWidth="sm"
      actions={{
        primary: { label: 'Yes', handler: () => {
          update()
          setArchiveWarning(false)
        }},
        secondary: {
          label: 'Cancel',
          handler: () => {
            setArchiveWarning(false)
          },
        },
      }}
    >
      <Typography>This action can not be reverted. Are you sure you want to archive the commercial link?</Typography>
    </Modal>}
  </>)
}

ModalUpdateStatus.propTypes = {
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired
}

export default ModalUpdateStatus
