import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  fieldSet: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  topPaper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  fabMargin: {
    marginLegt: theme.spacing(3)
  },
  fabExtendedIcon: {
    marginRight: theme.spacing(1)
  }
}))
