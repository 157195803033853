import React, { useEffect } from 'react'
import Card from '@material-ui/core/Card'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CardContent from '@material-ui/core/CardContent'
import SnackbarComponent from 'components/Snackbar'
import AuthView from '../AuthView/connect'
import PropTypes from 'prop-types'
import { FILE_DATA_DELIVERY_COMMIT_OPTIONS } from 'constants.js'
import DataDeliveryFtp from './components/DataDeliveryFtp'
import DataDeliveryEmail from './components/DataDeliveryEmail'
import DataDeliveryLogTable from './components/DataDeliveryLogTable'
import { utcToTimezone } from 'utils/date'
import { useIntl } from 'react-intl'

const FileDataDelivery = ({ location, snackbar, auth, dataDelivery, companyConfig, fetchDataDelivery, patchConfigDataDelivery, currUserCompany }) => {
  const { formatMessage } = useIntl()

  useEffect(() => {
    fetchDataDelivery()
  }, [ auth.companyId ])

  const handleChangeConfig = (field, value) => {
    patchConfigDataDelivery({ [field]: value })
  }

  const dataDeliveryEntries = dataDelivery ? dataDelivery.map(entry => {
    return {
      ...entry,
      createdAt: utcToTimezone(entry.createdAt, currUserCompany.timezone)
    }
  }) : []

  return (
    <AuthView location={location} title="Data delivery">
      { !!companyConfig &&
      <>
        <Card>
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(e) => handleChangeConfig(e.target.name, e.target.checked ? FILE_DATA_DELIVERY_COMMIT_OPTIONS.FULL : FILE_DATA_DELIVERY_COMMIT_OPTIONS.PARTIAL)}
                  checked={companyConfig?.autoCommitDataDelivery === FILE_DATA_DELIVERY_COMMIT_OPTIONS.FULL}
                  name="autoCommitDataDelivery"
                />
              }
              label={formatMessage({ id: 'data-delivery.only-commit-fully-matched-files' })}
            />
            <DataDeliveryFtp onChangeConfig={handleChangeConfig} companyConfig={companyConfig} />
            <DataDeliveryEmail onChangeConfig={handleChangeConfig} companyConfig={companyConfig} />
          </CardContent>
        </Card>
        <DataDeliveryLogTable
          style={{ marginTop: 15 }}
          data={dataDeliveryEntries}
          fetchDataDelivery={fetchDataDelivery}
          name={'data_delivery_-_log'}
        />
        <SnackbarComponent
          status={snackbar.isOpened}
          error={snackbar.isError}
          text={snackbar.successOrErrorText}
        />
      </> }
    </AuthView>
  )
}

FileDataDelivery.propTypes = {
  location: PropTypes.object,
  dataDelivery: PropTypes.array,
  snackbar: PropTypes.object,
  companyConfig: PropTypes.object,
  auth: PropTypes.object,
  fetchDataDelivery: PropTypes.func.isRequired,
  patchConfigDataDelivery: PropTypes.func.isRequired
}

export default FileDataDelivery
