import { makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme) => ({
  rootSection: {
    margin: '24px 0 !important',
    '&.Mui-expanded': {
      border: '2px solid'
    }
  },
  buttonContainerSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.default,
    padding: '0 1em'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  datePicker: {
    display: 'flex',
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  fileField: {
    marginTop: theme.spacing(2),
    "& legend": {
      visibility: "visible"
    }
  },
  buttonSpacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  fullWidth: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  hidden: {
    display: 'none',
  },
  selectClear: {
    marginRight: theme.spacing(2),
  },
  primaryLabel: {
    color: theme.palette.primary.main,
  },
  heading: {
    fontWeight: 'bold !important',
  },
  formContainer: {
    maxWidth: '100%',
  },
  info: {
    "& li": {
      marginBottom: theme.spacing(1),
    }
  },
  successIcon: {
    color: green[500]
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  copyStakeholderControl: {
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.primary.main}`,
    margin: '.5rem 1rem',
    padding: '1rem',

    '& > div': {
      display: 'flex',
      alignItems: 'flex-end',
      gap: '1rem',
    }
  },
  multiTextField: {
    border: `2px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    display: 'block',
    marginTop: '1rem',

    '&.error': {
      border: `1px solid ${theme.palette.error.main}`,

      '& > legend': {
        color: theme.palette.error.main,
      }
    },

    '& > legend': {
      color: theme.palette.primary.main,
      fontSize: '.75rem',
      padding: '0 .5rem',
    },

    '& > .info': {
      padding: '1rem 0 .5rem 0',
      color: theme.palette.text.secondary,
    },

    '& .text-input-container': {
      display: 'flex',
      gap: '.5rem',
      marginTop: '1rem',
      alignItems: 'center',

      '& > .text-input': {
        flex: '1',
        marginTop: 0,
      }
    },

    '& .remove-btn': {
      flexGrow: '0',
    },

    '& .error-msg-container': {
      color: theme.palette.error.main,
      padding: '.5rem 1rem 0 1rem',
      fontWeight: 'bold',
      fontSize: '.75rem',
    },

    '& .add-btn-container': {
      paddingTop: '2rem',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}))
