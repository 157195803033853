import React from 'react'
import PropTypes from 'prop-types'
import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from '@devexpress/dx-react-core'
import ActionButton from '../components/ActionButtons'

const pluginDependencies = [
  { name: 'Toolbar' }
]

const ToolbarAction = ({ icon, tooltip, onClick, label }) => {
  return (
    <Plugin name="ToolbarFilter" dependencies={pluginDependencies}>
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {() => (
            <ActionButton icon={icon} tooltip={tooltip} onClick={onClick} label={label} />
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  )
}

ToolbarAction.propTypes = {
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string
}

export default ToolbarAction
