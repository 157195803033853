import { connect } from 'react-redux'
import Component from './CountrySelectInput'
import { fetchCountries } from 'store/countries'

export const mapState = ({ countries }) => ({
  ...countries
})

export const mapDispatch = dispatch => ({
  fetchCountries: () => dispatch(fetchCountries())
})

export default connect(mapState, mapDispatch)(Component)
