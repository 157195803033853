import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const performFetchRegistrationInvite = async token => {
  const url = `${API_URL}/invites/${token}`
  return httpFetch(url, { shouldThrowError: true })
}

export const performRegistration = async registrationData => {
  const data = {
    ...registrationData
  }
  if (data.codes) {
    delete data.codes.ABTACRM
  }
  const url = `${API_URL}/invites/redeem`
  return httpFetch(url, { method: 'POST', body: JSON.stringify(registrationData), shouldThrowError: true })
}
