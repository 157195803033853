import { fullName } from 'utils/functions'

// Action Names
const FETCH_AUTH = fullName('auth', 'FETCH_AUTH')
const FETCH_AUTH_SUCCESS = fullName('auth', 'FETCH_AUTH_SUCCESS')
const FETCH_AUTH_FAILURE = fullName('auth', 'FETCH_AUTH_FAILURE')
const REQUIRE_MFA = fullName('auth', 'REQUIRE_MFA')
const SETUP_MFA = fullName('auth', 'SETUP_MFA')
const SETUP_MFA_SUCCESS = fullName('auth', 'SETUP_MFA_SUCCESS')
const SETUP_MFA_FAILURE = fullName('auth', 'SETUP_MFA_FAILURE')
const RESET_MFA = fullName('auth', 'RESET_MFA')
const RESET_MFA_SUCCESS = fullName('auth', 'RESET_MFA_SUCCESS')
const RESET_MFA_FAILURE = fullName('auth', 'RESET_MFA_FAILURE')
const CONFIRM_MFA = fullName('auth', 'CONFIRM_MFA')
const CONFIRM_MFA_CODE = fullName('auth', 'CONFIRM_MFA_CODE')
const CONFIRM_MFA_CODE_SUCCESS = fullName('auth', 'CONFIRM_MFA_CODE_SUCCESS')
const CONFIRM_MFA_CODE_FAILURE = fullName('auth', 'CONFIRM_MFA_CODE_FAILURE')
const REFRESH_TOKEN = fullName('auth', 'REFRESH_TOKEN')
const REFRESH_TOKEN_SUCCESS = fullName('auth', 'REFRESH_TOKEN_SUCCESS')
const REFRESH_TOKEN_FAILURE = fullName('auth', 'REFRESH_TOKEN_FAILURE')
const GET_TOKEN_EXPIRY = fullName('auth', 'GET_TOKEN_EXPIRY')
const GET_TOKEN_EXPIRY_SUCCESS = fullName('auth', 'GET_TOKEN_EXPIRY_SUCCESS')
const GET_TOKEN_EXPIRY_FAILURE = fullName('auth', 'GET_TOKEN_EXPIRY_FAILURE')
const LOGOUT = fullName('auth', 'LOGOUT')
const SET_PASSWORD = fullName('password', 'SET_PASSWORD')
const RESET_PASSWORD = fullName('password', 'RESET_PASSWORD')
const RESET_PASSWORD_TOKEN = fullName('password', 'RESET_PASSWORD_TOKEN')
const RESET_PASSWORD_TOKEN_SUCCESS = fullName('password', 'RESET_PASSWORD_TOKEN_SUCCESS')
const RESET_PASSWORD_TOKEN_FAILURE = fullName('password', 'RESET_PASSWORD_TOKEN_FAILURE')
const CLOSE_SNACKBAR = fullName('snackbar', 'CLOSE_SNACKBAR')
const SET_UNREAD_NOTIFICATIONS = fullName('user', 'SET_UNREAD_NOTIFICATIONS')
const SET_FETCHING = fullName('fetching', 'FETCHING') // This might not be needed - remove if possible
const SET_ERROR = fullName('error', 'ERROR') // This might not be needed - remove if possible

export const ACTIONS = {
  FETCH_AUTH,
  FETCH_AUTH_SUCCESS,
  FETCH_AUTH_FAILURE,
  REQUIRE_MFA,
  SETUP_MFA,
  SETUP_MFA_SUCCESS,
  SETUP_MFA_FAILURE,
  RESET_MFA,
  RESET_MFA_SUCCESS,
  RESET_MFA_FAILURE,
  CONFIRM_MFA,
  CONFIRM_MFA_CODE,
  CONFIRM_MFA_CODE_SUCCESS,
  CONFIRM_MFA_CODE_FAILURE,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  LOGOUT,
  SET_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_TOKEN,
  RESET_PASSWORD_TOKEN_SUCCESS,
  RESET_PASSWORD_TOKEN_FAILURE,
  SET_UNREAD_NOTIFICATIONS,
  SET_FETCHING,
  SET_ERROR,
  CLOSE_SNACKBAR,
  GET_TOKEN_EXPIRY,
  GET_TOKEN_EXPIRY_SUCCESS,
  GET_TOKEN_EXPIRY_FAILURE,
}

export const fetchAuth = authData => ({
  type: FETCH_AUTH,
  payload: authData
})

export const logout = () => {
  return {
    type: LOGOUT
  }
}

export const setPassword = passwordData => {
  return {
    type: SET_PASSWORD,
    payload: passwordData
  }
}

export const confirmMFACode = otpToken => {
  return {
    type: CONFIRM_MFA_CODE,
    payload: otpToken
  }
}

export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN
  }
}

export const resetMFA = (resetBody) => {
  return {
    type: RESET_MFA,
    payload: resetBody
  }
}

export const resetPassword = newPassword => ({
  type: RESET_PASSWORD,
  payload: newPassword
})

export const resetPasswordToken = (password, token) => ({
  type: RESET_PASSWORD_TOKEN,
  payload: {
    password,
    token
  }
})

export const setFetching = fetching => ({
  type: SET_FETCHING,
  payload: fetching
})

export const setError = encounteredError => ({
  type: SET_ERROR,
  payload: encounteredError
})

export const getTokenExpiry = () => ({
  type: GET_TOKEN_EXPIRY,
})

const initialStateSnackbar = {
  isOpened: false,
  isError: false,
  successOrErrorText: ''
}

const initialState = {
  fetching: false,
  id: '',
  username: '',
  token: '',
  companyId: '',
  name: '',
  role: '',
  encounteredError: false,
  accessToken: '',
  qrCode: '',
  recoveryKey: '',
  unreadNotifications: 0,
  snackbar: initialStateSnackbar,
  // other state updates might reset this back to undefined
  // if undefined and not fetching, the state was reset
  // (<SessionTimeout> checks for this and triggers a fetch)
  expiration: undefined, // undefined => unknown; 0 => expired; null => no expiry
  fetchingExpiration: false,
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOKEN_EXPIRY:
      return {
        ...state,
        expiration: undefined,
        fetchingExpiration: true,
      }
    case GET_TOKEN_EXPIRY_SUCCESS:
      return {
        ...state,
        expiration: action.payload,
        fetchingExpiration: false,
      }
    case GET_TOKEN_EXPIRY_FAILURE:
      return {
        ...state,
        expiration: 0,
        fetchingExpiration: false,
      }
    case FETCH_AUTH:
      return {
        ...state,
        fetching: true
      }
    case FETCH_AUTH_SUCCESS:
      return {
        ...initialState, // resets expiration to undefined (unknown)
        ...action.payload,
        fetching: false
      }
    case FETCH_AUTH_FAILURE:
      return {
        ...state,
        fetching: false,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload.error,
          tryAgainAt: action.payload?.tryAgainAt
        }
      }
    case SETUP_MFA:
      return {
        ...state,
        email: action.payload.email,
        accessToken: action.payload.accessToken,
        fetching: true
      }
    case SETUP_MFA_SUCCESS:
      return {
        ...state,
        qrCode: action.payload.imageUrl,
        recoveryKey: action.payload.recoveryKey,
        mfaSecret: action.payload.mfaSecret,
        fetching: false
      }
    case SETUP_MFA_FAILURE:
      return {
        ...state,
        fetching: false
      }
    case RESET_MFA:
      return {
        ...state,
        fetching: true
      }
    case RESET_MFA_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        fetching: false
      }
    case RESET_MFA_FAILURE:
      return {
        ...state,
        fetching: false
      }
    case CONFIRM_MFA:
      return {
        ...state,
        fetching: false,
        accessToken: action.payload.accessToken,
        email: action.payload.email
      }
    case CONFIRM_MFA_CODE:
      return {
        ...state,
        fetching: true
      }
    case CONFIRM_MFA_CODE_SUCCESS:
      return {
        ...state,
        qrCode: action.payload.imageUrl,
        recoveryKey: action.payload.recoveryKey,
        accessToken: '',
        fetching: false
      }
    case CONFIRM_MFA_CODE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: 'aqui',
        tryAgainAt: action.payload?.tryAgainAt
      }
    case REFRESH_TOKEN:
      return {
        ...state,
        fetching: true
      }
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state, 
        expiration: undefined, // reset to unknown (just like auth success)
        token: action.payload.token,
        fetching: false
      }
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        fetching: false,
        // keep expiration, will timeout
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: "Failed to refresh token"
        }
      }
    case RESET_PASSWORD_TOKEN:
      return {
        ...state,
        fetching: true
      }
    case RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        fetching: false
      }
    case RESET_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        fetching: false,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload.error
        }
      }
    case CLOSE_SNACKBAR: {
      return {
        ...state,
        snackbar: initialStateSnackbar
      }
    }
    case LOGOUT:
      return {
        id: '',
        username: '',
        token: '',
        companyId: '',
        name: '',
        role: '',
        fetching: false,
        snackbar: initialStateSnackbar,
        expiration: undefined,
      }
    case SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        fetching: false,
        unreadNotifications: action.payload
      }
    case SET_FETCHING:
      return {
        ...state,
        fetching: action.payload
      }
    case SET_ERROR:
      return {
        ...state,
        encounteredError: action.payload
      }
    default:
      return state
  }
}
export default auth
