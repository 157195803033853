import React from 'react'
import PropTypes from 'prop-types'

const TwoItemRow = props => (
  <div className="columns">
    <div className="column is-1" />
    <div className="column is-5">
      { props.children[0] }
    </div>
    <div className="column is-5">
      { props.children[1] }
    </div>
    <div className="column is-1" />
  </div>
)

TwoItemRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ])
}

export default TwoItemRow
