import React, { memo, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import { filterRelevantEventTypeDays } from '../../utils'
import { BSD_CALENDAR_EVENTS, BSD_CALENDAR_EVENTS_TO_NAME } from 'constants.js'
import { dateToReadableDateTime } from 'utils/date'

const columns = [
  {
    title: 'Date',
    name: 'date',
    type: 'datetime',
    getCellValueToExport: (row) => dateToReadableDateTime(row.date)
  },
  {
    name: 'type',
    title: 'Type',
    getCellValue: (row) => {
      if (row?.type === BSD_CALENDAR_EVENTS.HOLIDAY) {
        return `Public holiday (${row?.details.name})`
      }
      return BSD_CALENDAR_EVENTS_TO_NAME[row?.type]
    }
  },
  {
    name: 'manual',
    title: 'Unsaved',
    getCellValue: (row) => row?.override ? 'X' : ''
  }
]

const ManualCollectionsTable = ({
  currentEventsMap,
  unsavedEventsMap,
}) => {
  const [ displayOnlyUnsavedCollectionDays, setDisplayOnlyUnsavedCollectionDays ] = useState(false)

  const filteredData = useMemo(() => {
    if (displayOnlyUnsavedCollectionDays) {
      return Object.values(unsavedEventsMap).flat()
    }

    return Object.values(currentEventsMap)
      .flat()
      .filter(filterRelevantEventTypeDays)
  }, [ currentEventsMap, unsavedEventsMap, displayOnlyUnsavedCollectionDays ])

  const handleDisplayOnlyUnsavedCollectionDays = (event) => {
    setDisplayOnlyUnsavedCollectionDays(event.target.checked)
  }

  return (
    <>
      <DataGrid
        title="Schedule collections"
        pagination={{
          pageSize: 25
        }}
        summaryToolbar={() => (
          <Box pl={1}>
            <FormControlLabel
              control={<Switch checked={displayOnlyUnsavedCollectionDays} onChange={handleDisplayOnlyUnsavedCollectionDays} value="displayOnlyUnsavedCollectionDays" />}
              label="Display only unsaved collection days"
            />
          </Box>
        )}
        columns={columns}
        data={filteredData}
      />
    </>
  )
}

ManualCollectionsTable.propTypes = {
  currentEventsMap: PropTypes.object.isRequired,
  unsavedEventsMap: PropTypes.object.isRequired,
}

export default memo(ManualCollectionsTable)
