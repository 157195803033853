import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import { INVOICE_TAGS, INVOICE_TAGS_READABLE } from 'constants.js'
import UnderlinedCell from '../UnderlinedCell'

const BookingRefCell = React.memo(({ bookingRef, tags = [] }) => {
  if (tags.includes(INVOICE_TAGS.POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT)) {
    return (
      <Tooltip title={INVOICE_TAGS_READABLE.POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT}>
        <UnderlinedCell error={true} warning={false}>
          {bookingRef}
        </UnderlinedCell>
      </Tooltip>
    )
  } else if (tags.includes(INVOICE_TAGS.POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT)) {
    return (
      <Tooltip title={INVOICE_TAGS_READABLE.POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT}>
        <UnderlinedCell error={false} warning={true}>
          {bookingRef}
        </UnderlinedCell>
      </Tooltip>
    )
  } else if (tags.includes(INVOICE_TAGS.POSSIBLE_ENHANCED_DUPLICATE)) {
    return (
      <Tooltip title={INVOICE_TAGS_READABLE.POSSIBLE_ENHANCED_DUPLICATE}>
        <UnderlinedCell error={true} warning={false}>
          {bookingRef}
        </UnderlinedCell>
      </Tooltip>
    )
  }
  return bookingRef
})

BookingRefCell.displayName = 'BookingRefCell'

BookingRefCell.propTypes = {
  bookingRef: PropTypes.string,
  tags: PropTypes.array,
}

export default BookingRefCell
