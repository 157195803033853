import { fullName } from 'utils/functions'

// Action Names
const FETCH_TASKS = fullName('tasks', 'FETCH_TASKS')
const FETCH_TASKS_SUCCESS = fullName('tasks', 'FETCH_TASKS_SUCCESS')
const FETCH_TASKS_ERROR = fullName('tasks', 'FETCH_TASKS_ERROR')
const ADD_TASK = fullName('tasks', 'ADD_TASK')
const ADD_TASK_SUCCESS = fullName('tasks', 'ADD_TASK_SUCCESS')
const ADD_TASK_ERROR = fullName('tasks', 'ADD_TASK_ERROR')
const EDIT_TASK = fullName('tasks', 'EDIT_TASK')
const RUN_TASK = fullName('tasks', 'RUN_TASK')
const RUN_TASK_SUCCESS = fullName('tasks', 'RUN_TASK_SUCCESS')
const RUN_TASK_ERROR = fullName('tasks', 'RUN_TASK_ERROR')
const UPDATE_TASK = fullName('tasks', 'UPDATE_TASK')
const UPDATE_TASK_SUCCESS = fullName('tasks', 'UPDATE_TASK_SUCCESS')
const UPDATE_TASK_ERROR = fullName('tasks', 'UPDATE_TASK_ERROR')
const DELETE_TASK = fullName('tasks', 'DELETE_TASK')
const DELETE_TASK_SUCCESS = fullName('tasks', 'DELETE_TASK_SUCCESS')
const DELETE_TASK_ERROR = fullName('tasks', 'DELETE_TASK_ERROR')
const CLOSE_SNACKBAR = fullName('tasks', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_ERROR,
  ADD_TASK,
  ADD_TASK_SUCCESS,
  ADD_TASK_ERROR,
  EDIT_TASK,
  RUN_TASK,
  RUN_TASK_SUCCESS,
  RUN_TASK_ERROR,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERROR,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_ERROR,
  CLOSE_SNACKBAR
}

export const fetchTasks = () => ({
  type: FETCH_TASKS
})

export const addTask = payload => ({
  type: ADD_TASK,
  payload
})

export const editTask = payload => ({
  type: EDIT_TASK,
  payload
})

export const runTask = payload => ({
  type: RUN_TASK,
  payload
})

export const updateTask = (id, payload) => ({
  type: UPDATE_TASK,
  payload: {
    id,
    task: payload
  }
})

export const deleteTask = payload => ({
  type: DELETE_TASK,
  payload
})

const initialState = {
  tasks: [],
  currentTask: {},
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload
      }
    case FETCH_TASKS_ERROR:
    case ADD_TASK_ERROR:
    case RUN_TASK_ERROR:
    case UPDATE_TASK_ERROR:
    case DELETE_TASK_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        currentTask: {},
        snackbar: {
          ...state.snackbar,
          isOpened: true,
          isError: false,
          successOrErrorText: 'Task created'
        }
      }
    case EDIT_TASK:
      return {
        ...state,
        currentTask: action.payload
      }
    case RUN_TASK_SUCCESS:
      return {
        ...state,
        currentTask: {},
        snackbar: {
          ...state.snackbar,
          isOpened: true,
          isError: false,
          successOrErrorText: 'Task executed'
        }
      }
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        currentTask: {},
        tasks: state.tasks.map(task => action.payload.id === task.id ? action.payload : task),
        snackbar: {
          ...state.snackbar,
          isOpened: true,
          isError: false,
          successOrErrorText: 'Task updated'
        }
      }
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        currentTask: {},
        tasks: state.tasks.filter(task => task.id !== action.payload),
        snackbar: {
          ...state.snackbar,
          isOpened: true,
          isError: false,
          successOrErrorText: 'Task deleted'
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    default:
      return state
  }
}
export default tasks
