import axios from 'axios'
import qs from 'qs'
import config from 'store'
import selectors from 'store/auth/selector'
import { API_URL, REQUEST_TIMEOUT, ROUTE_URL, USER_AUTH_STORAGE_KEYS } from 'constants.js'
import { DownloadNoContentError } from './errors'

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// Intercept response to check for 401 status error
axiosInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    Object.values(USER_AUTH_STORAGE_KEYS).forEach(item => localStorage.removeItem(item))
    window.location.href = `${ROUTE_URL.login}?sessionExpired=true`
    return
  }
  return Promise.reject(error)
})


export const makeAxiosConfig = (axiosConfig = {}) => {
  const { store } = config
  const tokenLocalStorage = localStorage.getItem(USER_AUTH_STORAGE_KEYS.token)
  const token = selectors.getToken(store.getState()) || tokenLocalStorage
  return {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      Authorization: `bearer ${token}`
    }
  }
}

export const parseErrors = (detail) => {
  const { rawErrors } = JSON.parse(detail)
  return rawErrors.reduce((accm, { error, path }) => {
    if (!path) {
      throw (detail)
    }

    const i = error.includes(`${path}:`) ? 2 : 1
    const err = error.substring(path.length + i, error.length)
    const key = path.substring(1, path.length)
    return { ...accm, [key]: err }
  }, {})
}

export const patch = async (endpoint, payload) => {
  try {
    // manage content type header
    const contentType = payload instanceof FormData ? {
      'Content-Type': 'multipart/form-data'
    } : {};
    const requestConfig = makeAxiosConfig(config)
    requestConfig.headers = {
      ...requestConfig.headers,
      ...contentType
    }
    
    const { data } = await axiosInstance.patch(endpoint, payload, requestConfig)
    return data
  } catch (error) {
    throw error.response
  }
}

export const patchFormData = async (endpoint, payload) => {
  try {
    const axiosConfig = makeAxiosConfig({ headers: {
      'Content-Type': 'multipart/form-data'
    }})
    const { data } = await axiosInstance.patch(endpoint, payload, axiosConfig)
    return data
  } catch (error) {
    throw error.response
  }
}

export const del = async (endpoint, extraConfig) => {
  try {
    const { data } = await axiosInstance.delete(endpoint, makeAxiosConfig(extraConfig || {}))
    return data
  } catch (error) {
    throw error.response
  }
}


export const get = async (endpoint, params = {}, requestConfig = {}) => {
  const query = qs.stringify(params, { addQueryPrefix: true })
  const getFunc = axiosInstance.get(`${endpoint}${query}`, { ... makeAxiosConfig(), ...requestConfig })
  return getFunc
    .then(response => response.data)
    .catch(error => {
      throw error.response
    })
}

export const post = async (endpoint, payload) => {
  try {
    const { data } = await axiosInstance.post(endpoint, payload, makeAxiosConfig())
    return data
  } catch (error) {
    throw error.response
  }
}

export const download = async (endpoint) => {
  const response = await axiosInstance.get(endpoint, { ...makeAxiosConfig(), responseType: 'blob' })
  if (response.status === 204) {
    return Promise.reject(new DownloadNoContentError())
  }
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = downloadUrl
  const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replace('"', '').replace('"', '')
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
