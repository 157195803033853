import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  disabledText: {
    color: theme.palette.action.disabled,
    borderBottomColor: theme.palette.action.disabled,
    borderBottomWidth: 1,
    borderBottomStyle: 'dashed'
  },
  note: {
    marginTop: theme.spacing(2)
  },
  stepperGrid: {
    marginTop: theme.spacing(.5),
    overflowX: 'scroll'
  },
  stepLabel: {
    width: 200,
    textAlign: 'center'
  }
}))
