import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import classNames from 'clsx'
import Icon from '@material-ui/core/Icon'
import MUIButton from '@material-ui/core/Button'

// eslint-disable-next-line react/display-name
const Button = ({ children, disabled = false, icon, iconPosition = 'start', color, marginLeft, marginRight, marginTop, marginBottom, onClick, className, ...props }) => {
  const classes = useStyles()
  return (
    <MUIButton
      {...props}
      variant="contained"
      color={color || 'default'}
      className={classNames(
        {
          [classes.button]: true,
          [classes.abortColor]: color === 'abort',
          [classes.errorColor]: color === 'error',
          [classes.successColor]: color === 'success',
          [classes.marginLeft]: !!marginLeft,
          [classes.marginRight]: !!marginRight,
          [classes.marginTop]: !!marginTop,
          [classes.marginBottom]: !!marginBottom
        }, className
      )}
      disabled={disabled}
      startIcon={icon && iconPosition === 'start' ? <Icon>{icon}</Icon> : null}
      endIcon={icon && iconPosition === 'end' ? <Icon>{icon}</Icon> : null}
      onClick={onClick}
    >
      { children }
    </MUIButton>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.oneOf([
    'default', 'inherit', 'primary', 'secondary', 'success', 'error', 'abort'
  ]),
  iconPosition: PropTypes.oneOf([
    'start', 'end'
  ]),
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Button
