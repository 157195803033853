import { kebabCase } from "lodash"
import { NIUM_STAKEHOLDER_TYPE } from "../../../../../../../constants"

// common
export const totalEmployees = {
  EM006: 'Less than or equal 1',
  EM007: 'Between 2 to 5',
  EM008: 'Between 6 to 24',
  EM009: 'More than 24',
}

export const industrySector = {
  IS228: 'Travel & Tourism',
  IS244: 'Technology providers',
}

export const intendedUseOfAccount = {
  IU002: 'Pay suppliers and vendors (defaulted)',
}

// all positions

const allPositions = {
  DIRECTOR: { DIRECTOR: 'Director' },
  UBO: { UBO: 'Owner/Ultimate Beneficial Owners (UBO)' },
  SHAREHOLDER: { SHAREHOLDER: 'Shareholder' },
  TRUSTEE: { TRUSTEE: 'Trustee' },
  SETTLOR: { SETTLOR: 'Settlor' },
  PARTNER: { PARTNER: 'Partner' },
  MEMBERS: { MEMBERS: 'Members' },
  SIGNATORY: { SIGNATORY: 'Authorized Signatory' },
  REPRESENTATIVE: { REPRESENTATIVE: 'Authorized Representative' },
}

// uk
export const positionUk = {
  ...allPositions.DIRECTOR,
  ...allPositions.UBO,
  ...allPositions.SHAREHOLDER,
  ...allPositions.TRUSTEE,
  ...allPositions.SETTLOR,
  ...allPositions.PARTNER,
  ...allPositions.MEMBERS,
  ...allPositions.SIGNATORY,
  ...allPositions.REPRESENTATIVE,
}

export const corporateDocumentTypeStakeholder = {
  REGISTRATION_DOC: 'Registration Document',
  UBO_DECLARATION: 'UBO Declaration',
  CONTROLLING_PERSON_DECLARATION: 'Controlling Person Declaration',
}

// full list filtered per Roberto's feedback
export const businessTypesUk = {
  SOLE_TRADER: 'Sole Trader',
  PRIVATE_COMPANY: 'Private Limited Company',
  PUBLIC_COMPANY: 'Public Company',
  TRUST: 'Trust',
  LIMITED_LIABILITY_PARTNERSHIP: 'Limited Liability Partnership Firm',
  UNINCORP_PARTNERSHIP: 'Partnership',
  // GOVERNMENT_ENTITY: 'Government Body',
  ASSOCIATION: 'Associations',
  // OTHERS: 'Others'
}

export const corporateDocumentTypeUk = {
  BUSINESS_REGISTRATION_DOC: 'Registration document',
  TRUST_DEED: 'Trust Deed',
  PARTNERSHIP_DEED: 'Partnership Deed',
  ASSOCIATION_DEED: 'Association Deed',
}

export const identityDocTypesUk = {
  DRIVER_LICENCE: 'Driver\'s License (front and back)',
  NATIONAL_ID: 'National ID',
  PASSPORT: 'Passport',
}

export const addressDocTypesUk = {
  PROOF_OF_ADDRESS: 'Proof Of Address',
}

export const authorityDocTypesUk = {
  POWER_OF_ATTORNEY: 'Letter of Authority',
}

export const personDocumentTypeUk = {
  ...identityDocTypesUk,
  ...addressDocTypesUk,
  ...authorityDocTypesUk,
}

export const allDocumentTypesUk = {
  ...corporateDocumentTypeUk,
  ...personDocumentTypeUk,
}

export const annualTurnoverUk = {
  GB008: 'Less than GBP 100,000',
  GB009: 'GBP 100,000 to GBP 500,000',
  GB010: 'GBP 500,000 to GBP 1,500,000',
  GB011: 'GBP 1,500,000 +',
}

export const countryOfOperationEu = {
  AL: 'Albania',
  AD: 'Andorra',
  AT: 'Austria',
  BY: 'Belarus',
  BE: 'Belgium',
  BA: 'Bosnia and Herzegovina',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FO: 'Faroe Islands',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GI: 'Gibraltar',
  GR: 'Greece',
  VA: 'Holy See (Vatican City State)',
  HU: 'Hungary',
  IS: 'Iceland',
  IE: 'Ireland, Republic of',
  IT: 'Italy',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  ME: 'Montenegro',
  NL: 'Netherlands',
  MK: 'Macedonia',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SM: 'San Marino',
  RS: 'Serbia, Republic of',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SJ: 'Svalbard and Jan Mayen Islands',
  SE: 'Sweden',
  CH: 'Switzerland',
  UA: 'Ukraine',
  GB: 'United Kingdom',
}

export const transactionCountriesUk = [
  'GB',
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

export const restrictedCountries = [
  'AF', 
  'YE', 
  'SY', 
  'SD', 
  'SS', 
  'SO', 
  'RU', 
  'MM', 
  'LY', 
  'KP', 
  'IQ', 
  'IR', 
  'GW', 
  'ER', 
  'CF', 
  'BI', 
  'BY', 
  'CU',
]

// eu
export const positionEu = {
  ...allPositions.DIRECTOR,
  ...allPositions.UBO,
  ...allPositions.SHAREHOLDER,
  ...allPositions.TRUSTEE,
  ...allPositions.PARTNER,
  ...allPositions.SETTLOR,
  ...allPositions.SIGNATORY,
  ...allPositions.REPRESENTATIVE,
}

// full list filtered per Roberto's feedback
export const businessTypesEu = {
  SOLE_TRADER: 'Sole Trader',
  PRIVATE_COMPANY: 'Private Limited Company',
  PUBLIC_COMPANY: 'Public Company',
  TRUST: 'Trust',
  LIMITED_LIABILITY_PARTNERSHIP: 'Limited Liability Partnership Firm',
  // GOVERNMENT_ENTITY: 'Government Body',
  ASSOCIATION: 'Associations'
}

export const businessTypePositionMap = {
  SOLE_TRADER: {
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  PRIVATE_COMPANY: {
    ...allPositions.DIRECTOR,
    ...allPositions.UBO,
    ...allPositions.SHAREHOLDER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  PUBLIC_COMPANY: {
    ...allPositions.DIRECTOR,
    ...allPositions.UBO,
    ...allPositions.SHAREHOLDER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  TRUST: {
    ...allPositions.TRUSTEE,
    ...allPositions.SETTLOR,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  LIMITED_LIABILITY_PARTNERSHIP: {
    ...allPositions.PARTNER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  // GOVERNMENT_ENTITY: [
  //  ...allPositions.SIGNATORY,
  //  ...allPositions.REPRESENTATIVE,
  // ],
  ASSOCIATION: {
    ...allPositions.DIRECTOR,
    ...allPositions.SHAREHOLDER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
}

export const corporateDocumentTypeEu = {
  // Ref: https://docs.nium.com/apis/reference/fetchcorporateconstantsusingget

  BUSINESS_REGISTRATION_DOC: 'Business Registration Document',
  REGISTER_OF_DIRECTORS: 'Register of Directors',
  REGISTER_OF_SHAREHOLDERS: 'Register of Shareholders',
  TRUST_DEED: 'Trust Deed',
  PARTNERSHIP_DEED: 'Partnership Deed',
  NATIONAL_ID: 'National ID',
  PASSPORT: 'Passport',
  POWER_OF_ATTORNEY: 'Power Of Attorney Certified with Apostille',
  ASSOCIATION_DEED: 'Association Deed',
  PROOF_OF_ADDRESS: 'Proof Of Address',
  CORPORATE_STRUCTURE: 'Corporate Structure Document',
}

export const personDocumentTypeEu = {
  NATIONAL_ID: 'National ID',
  PASSPORT: 'Passport',
  POWER_OF_ATTORNEY: 'Certified Letter of Authority',
  PROOF_OF_ADDRESS: 'Proof Of Address',
}

export const stakeholderDetailsPersonDocumentTypeEu = {
  NATIONAL_ID: 'National ID',
  PASSPORT: 'Passport',
  POWER_OF_ATTORNEY: 'Apostilled Letter of Authority',
}

export const monthlyTransactionVolume = {
  MVEU01: '< EUR 5,000',
  MVEU02: 'EUR 5,001 TO EUR 10,000',
  MVEU03: 'EUR 10,001 TO EUR 20,000',
  MVEU04: 'EUR 20,001 to EUR 50,000',
  MVEU05: 'EUR 50,001 to EUR 100,000',
  MVEU06: 'EUR 100,001 to EUR 250,000',
  MVEU07: 'EUR 250,001 to EUR 500,000',
  MVEU08: 'EUR 500,001 to EUR 1,000,000',
  MVEU10: 'EUR 1,000,000 to EUR 10,000,000',
  MVEU11: 'EUR 10,000,000',
}

export const monthlyTransactions = {
  ATC01: '1-5',
  ATC02: '6-10',
  ATC03: '11-20',
  ATC04: '21-50',
  ATC05: '51-100',
  ATC06: '101-500',
  ATC07: '501-1,000',
  ATC08: '1,001-2,500',
  ATC09: '2,501 - 5,000',
  ATC10: '5,001 - 10,000',
  ATC11: '>10,000',
}

export const averageTransactionValue = {
  ATVEU01: '< EUR 1,000',
  ATVEU02: 'EUR 1,001 TO EUR 10,000',
  ATVEU03: 'EUR 10,001 TO EUR 20,000',
  ATVEU04: 'EUR 20,001 to EUR 50,000',
  ATVEU05: 'EUR 50,001 to EUR 100,000',
  ATVEU06: 'EUR 100,001 to EUR 300,000',
  ATVEU07: 'EUR 300,001 to EUR 600,000',
  ATVEU08: 'EUR 600,001 to EUR 1,000,000',
  ATVEU09: '> EUR 1,000,000',
}

export const allDocumentTypesEu = {
  ...corporateDocumentTypeEu,
  ...personDocumentTypeEu,
}

export const annualTurnoverEu = {
  EU008: 'Less than EUR 100,000',
  EU009: 'EUR 100,000 to EUR 500,000',
  EU010: 'EUR 500,000 to EUR 1,500,000',
  EU011: 'EUR 1,500,000 +',
}

export const yesOrNo = {
  Yes: 'Yes',
  No: 'No',
}

export const stakeholderTypes = {
  [NIUM_STAKEHOLDER_TYPE.INDIVIDUAL]: 'Individual',
  [NIUM_STAKEHOLDER_TYPE.CORPORATE]: 'Corporate (If owned by another company)'
}

export function formatValueMap(obj, formatMessage, prefix) {
  if (obj !== null && !Array.isArray(obj) && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) =>
        typeof value === 'string'
          ? [key, formatMessage({ id: `${prefix ?? 'kyb-form.'}${kebabCase(key)}` })]
          : [key, value]
      )
    )
  }
  return obj
}
