import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  select: {
    width: 200,
    marginLeft: 10,
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 6,
      paddingBottom: 6
    },
    '& .MuiSelect-select': {
      paddingRight: 0
    },
    '& .MuiSelect-select:focus': {
      background: 'transparent'
    },
  },
  form: {
    verticalAlign: 'middle'
  }
}))
