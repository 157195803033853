/* eslint-disable no-shadow */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { MaterialAutocomplete } from 'components/MaterialAutocomplete'
import { searchCompanies, clearSearchCompaniesData } from 'store/companies'
import { searchCommercialLinks } from 'store/commercialLinks'
import SelectOnTabMenuItem from 'components/SelectOnTabMenuItem'
import { useStyles } from './styles'
import Modal from 'components/Modal'
import { COMMERCIAL_LINK_STATUS } from 'constants.js'

export const usePartnerModal = (onSuccess) => {
  const [ selection, setSelection ] = useState([])
  const bulkUpdateRowsFunc = useRef()

  const openModal = (ids, bulkUpdateRows) => {
    bulkUpdateRowsFunc.current = bulkUpdateRows
    setSelection(ids)
  }

  const onCancel = () => {
    bulkUpdateRowsFunc.current = undefined
    setSelection([])
  }

  const onConfirm = (company) => {
    onSuccess(selection, company, bulkUpdateRowsFunc.current)
    onCancel()
  }

  return {
    selection,
    openModal,
    onCancel,
    onConfirm
  }
}

let searchHandler = () => {}

const PartnerAutocomplete = ({ selection, onCancel, onConfirm, selectionFilter }) => {
  const modalRef = useRef()

  useEffect(() => {
    if (selection?.length) {
      modalRef.current.open()
    } else {
      modalRef.current.close()
    }
  }, [ selection ])

  const { links, companies } = useSelector(({ companies, commercialLinks }) => ({
    links: commercialLinks.searchLinks,
    companies: companies.searchCompany.data.filter(selectionFilter)
  }))
  const dispatch = useDispatch()
  const [ company, setCompany ] = useState({})

  useEffect(() => {
    searchHandler = _.throttle((text) => {
      dispatch(searchCompanies({ q: text }))
    }, 500, { trailing: true, leading: true })
  }, [])

  const inputHandler = ({ target }) => {
    searchHandler(target.value)
  }

  const handleSelect = targetCompany => {
    setCompany({
      targetCompany,
      targetId: targetCompany.id,
      commercialLink: undefined,
      commercialLinkId: undefined
    })
    if (targetCompany.id) {
      dispatch(searchCommercialLinks({
        'buyerId[eq]': targetCompany.id,
        'status[neq]': COMMERCIAL_LINK_STATUS.ARCHIVED
      }))
    }
    dispatch(clearSearchCompaniesData())
  }

  const handleChange = ({ target }) => {
    setCompany(prevCompany => ({
      ...prevCompany,
      commercialLink: target.value,
      commercialLinkId: target.value.id
    }))
  }

  const handleCloseClick = (success = false) => () => {
    if (!success) {
      return onCancel()
    }
    onConfirm(company)
  }

  const listView = links.length > 0 && links.map((obj, k) => (
    <SelectOnTabMenuItem key={k} value={obj} onChange={handleChange}>{obj.displayName}</SelectOnTabMenuItem>
  ))

  const classes = useStyles()
  return (
    <Modal defaultOpened={true} ref={modalRef} onClose={handleCloseClick(false)}>
      <Grid container className={classes.root}>
        <Grid container alignItems="center" justify="space-between" className={classes.content}>
          <Grid item xs={12} className={classes.formField}>
            <MaterialAutocomplete
              placeholder="Type a Partner name"
              label="Partner name"
              inputProps={{ margin: 'none', placeholder: 'Partner name' }}
              options={companies}
              getOptionLabel={option => option.name || ''}
              renderOptionKeys={[ 'name', 'abtaNumber', 'city' ]}
              onSelect={handleSelect}
              onInputChange={inputHandler}
              fullWidth
              value={company?.targetCompany ?? ''}
            />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <FormControl fullWidth>
              <InputLabel>Commercial link</InputLabel>
              <Select
                disabled={!company.targetId}
                renderValue={obj => obj.displayName}
                defaultValue={company?.commercialLink ?? ''}
                onChange={handleChange}
                value={company?.commercialLink ?? ''}
                MenuProps={{variant: "menu"}}
              >
                {listView}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleCloseClick(false)}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" disabled={!company.commercialLinkId} color="primary" onClick={handleCloseClick(true)}>Ok</Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

PartnerAutocomplete.propTypes = {
  selection: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

PartnerAutocomplete.defaultProps = {
  selectionFilter: () => true
}

export default PartnerAutocomplete
