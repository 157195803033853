import React from 'react'
import PropTypes from 'prop-types'
import { Getter, Template, TemplatePlaceholder, TemplateConnector, Plugin } from '@devexpress/dx-react-core'
import { isFilterTableRow } from '@devexpress/dx-grid-core'
import { TableFilterRow as DETableFilterRow } from '@devexpress/dx-react-grid-material-ui'
import { FILTER_TYPES, isFilterTableCell, tableHeaderRowsWithFilter } from '../utils'
import { CurrencyFilterCell, DateFilterCell, InputFilterCell, AutocompleteFilterCell, SelectFilterCell, MultipleSelectFilterCell, NumberFilterCell } from '../components/FiltersCell'

const pluginDependencies = [
  { name: 'FilteringState' },
  { name: 'Table' },
  { name: 'DataTypeProvider', optional: true }
]

const TableFilterRow = ({
  rowHeight,
  rowComponent: FilterRow = DETableFilterRow.Row
}) => {
  const tableHeaderRowsComputed = ({ tableHeaderRows, columns }) => tableHeaderRowsWithFilter(tableHeaderRows, columns, rowHeight)
  return (
    <Plugin
      name="TableFilterRow"
      dependencies={pluginDependencies}
    >
      <Getter name="tableHeaderRows" computed={tableHeaderRowsComputed} />
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isFilterTableCell(tableRow, tableColumn, FILTER_TYPES.DATE)}
      >
        {(params) => (
          <TemplateConnector>
            {({ filters }, { changeColumnFilter }) => {
              const { name: columnName } = params.tableColumn.column
              return (
                <TemplatePlaceholder name="valueEditor">
                  {() => (
                    <DateFilterCell
                      predefinedRanges={params.tableColumn.column?.filter.predefinedRanges}
                      value={filters?.[columnName]}
                      disableClearButton={params.tableColumn.column?.filter.disableClearButton}
                      onChange={(value) => changeColumnFilter({ key: columnName, value })}
                    />
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isFilterTableCell(tableRow, tableColumn, FILTER_TYPES.SELECT)}
      >
        {(params) => (
          <TemplateConnector>
            {({ filters }, { changeColumnFilter }) => {
              const { name: columnName } = params.tableColumn.column
              return (
                <TemplatePlaceholder name="valueEditor">
                  {() => (
                    <SelectFilterCell
                      value={filters?.[columnName]}
                      onChange={(value) => changeColumnFilter({ key: columnName, value })}
                      { ...params?.tableColumn?.column?.filter?.inputProps }
                    />
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isFilterTableCell(tableRow, tableColumn, FILTER_TYPES.MULTIPLE_SELECT)}
      >
        {(params) => (
          <TemplateConnector>
            {({ filters }, { changeColumnFilter }) => {
              const { name: columnName } = params.tableColumn.column
              return (
                <TemplatePlaceholder name="valueEditor">
                  {() => (
                    <MultipleSelectFilterCell
                      value={filters?.[columnName]}
                      onChange={(value) => changeColumnFilter({ key: columnName, value })}
                      { ...params?.tableColumn?.column?.filter?.inputProps }
                    />
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isFilterTableCell(tableRow, tableColumn, FILTER_TYPES.AUTOCOMPLETE)}
      >
        {(params) => (
          <TemplateConnector>
            {({ filters }, { changeColumnFilter }) => {
              const { name: columnName } = params.tableColumn.column
              return (
                <TemplatePlaceholder name="valueEditor">
                  {() => (
                    <AutocompleteFilterCell
                      value={filters?.[columnName]}
                      onChange={(value) => changeColumnFilter({ key: columnName, value })}
                      { ...params?.tableColumn?.column?.filter?.inputProps }
                    />
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isFilterTableCell(tableRow, tableColumn)}
      >
        {(params) => (
          <TemplateConnector>
            {({ filters }, { changeColumnFilter }) => {
              const { name: columnName } = params.tableColumn.column
              return (
                <TemplatePlaceholder name="valueEditor">
                  {() => (
                    <InputFilterCell
                      value={filters?.[columnName] || ''}
                      onChange={(event) => changeColumnFilter({ key: columnName, value: event.target.value })}
                    />
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableRow"
        predicate={({ tableRow }) => !!isFilterTableRow(tableRow)}
      >
        {(params) => <FilterRow {...params} />}
      </Template>
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isFilterTableCell(tableRow, tableColumn, FILTER_TYPES.CURRENCY)}
      >
        {(params) => (
          <TemplateConnector>
            {({ filters }, { changeColumnFilter }) => {
              const { name: columnName } = params.tableColumn.column
              return (
                <TemplatePlaceholder name="valueEditor">
                  {() => (
                    <CurrencyFilterCell
                      value={filters?.[columnName]}
                      onChange={(value) => changeColumnFilter({ key: columnName, value })}
                    />
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isFilterTableCell(tableRow, tableColumn, FILTER_TYPES.NUMBER)}
      >
        {(params) => (
          <TemplateConnector>
            {({ filters }, { changeColumnFilter }) => {
              const { name: columnName } = params.tableColumn.column
              return (
                <TemplatePlaceholder name="valueEditor">
                  {() => (
                    <NumberFilterCell
                      value={filters?.[columnName] || ''}
                      onChange={(event) => changeColumnFilter({ key: columnName, value: event.target.value })}
                    />
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableRow"
        predicate={({ tableRow }) => !!isFilterTableRow(tableRow)}
      >
        {(params) => <FilterRow {...params} />}
      </Template>
    </Plugin>
  )
}

TableFilterRow.propTypes = {
  rowComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  rowHeight: PropTypes.number
}

export default TableFilterRow
