import { postLogin, postMfaVerify } from 'services/authApi'
import { getUsersConfig, patchUsersConfig } from 'services/usersApi'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

export const useGetUserProfile = (id) => useQuery('userProfile', () => getUsersConfig(id), { enabled: !!id })
export const usePatchUserProfile = (id) => useMutation(userData => {
  patchUsersConfig(id, userData)
}, {
  onError: (error) => {
    toast.error(error.toString())
  },
  onSuccess: () => {
    toast.success('Profile updated')
  }
})

export const usePatchPassword = (userId, userProfile) => useMutation(async passwordData => {
  const loginResult = await postLogin({ username: userProfile.email, password: passwordData.currentPassword })
  if (userProfile.isMfaSetup) {
    const loginMfaResult = await postMfaVerify({ accessToken: loginResult.accessToken, otpToken: passwordData.otpToken })
    if (loginMfaResult?.error === true || !Reflect.has(loginMfaResult, 'token')) {
      throw new Error('Invalid 2-factor authentication code')
    }
  }
  return patchUsersConfig(userId, { password: passwordData.password })
}, {
  onError: (error) => {
    toast.error(error.toString())
  },
  onSuccess: () => {
    toast.success('Password updated')
  }
})
