import { connect } from 'react-redux'
import Component from './FileDataDelivery.js'
import { fetchDataDelivery, patchConfigDataDelivery } from 'store/dataDelivery'

const mapState = ({ auth, dataDelivery, companies }) => ({
  currUserCompany: companies.currUserCompany,
  auth,
  ...dataDelivery
})

const mapDispatch = dispatch => ({
  fetchDataDelivery: () => dispatch(fetchDataDelivery()),
  patchConfigDataDelivery: (data) => dispatch(patchConfigDataDelivery(data))
})

export default connect(mapState, mapDispatch)(Component)
