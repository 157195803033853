import { connect } from 'react-redux'
import Component from './NotificationCentre.js'
import { searchNotifications, dismissNotifications } from 'store/notifications'

export const mapState = ({ notifications, companies }) => ({
  currUserCompany: companies.currUserCompany,
  ...notifications
})

export const mapDispatch = (dispatch) => ({
  searchNotifications: (filters) => dispatch(searchNotifications(filters)),
  dismissNotifications: (indexes) => dispatch(dismissNotifications(indexes))
})

export default connect(mapState, mapDispatch)(Component)
