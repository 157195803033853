import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import TimeZoneInput from 'components/TimeZoneInput'
import CountrySelectInput from 'containers/CountrySelectInput'
import { Controller } from 'react-hook-form'
import { useStyles } from '../styles'
import Grid from '@material-ui/core/Grid'

const CompanyForm = ({
  form: {
    control,
    formState: { errors },
    register,
  },
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12}>
        <Typography component="h1" variant="subtitle2">
          Company details
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="companyName"
          render={({ field }) => (
            <TextField
              {...field}
              label="Company Name"
              variant="outlined"
              className={classes.input}
              fullWidth
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="countryId"
          control={control}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { onChange, value, ...field } }) => (
            <CountrySelectInput
              {...field}
              onChange={onChange}
              value={value}
              name="countryId"
              defaultValue=""
              label="Country"
              fullWidth
              required
              variant="outlined"
              className={classes.input}
              error={!!errors?.countryId}
              helperText={errors?.countryId?.message}
            />
          )}
        />
      </Grid>
      
      <Grid item xs={12} md={6}>
        <TextField
          {...register('address')}
          variant="outlined"
          fullWidth
          required
          label="Company Address"
          error={!!errors?.address}
          helperText={errors?.address?.message}
          className={classes.input}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="timezone"
          control={control}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { onChange, value, ...field } }) => (
            <TimeZoneInput
              {...field}
              onChange={onChange}
              value={value}
              name="timezone"
              defaultValue=""
              label="Timezone"
              fullWidth
              variant="outlined"
              className={classes.input}
              error={!!errors?.timezone}
              helperText={errors?.timezone?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...register('city')}
          variant="outlined"
          fullWidth
          required
          label="Company City"
          error={!!errors?.city}
          helperText={errors?.city?.message}
          className={classes.input}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...register('website')}
          variant="outlined"
          fullWidth
          label="Company Website"
          error={!!errors?.website}
          helperText={errors?.website?.message}
          className={classes.input}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...register('telephone')}
          variant="outlined"
          fullWidth
          label="Company Telephone"
          error={!!errors?.telephone}
          helperText={errors?.telephone?.message}
          className={classes.input}
          />
      </Grid>
    </>
  )
}

CompanyForm.propTypes = {
  form: PropTypes.object,
}

export default CompanyForm
