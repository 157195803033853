import { connect } from 'react-redux'
import Component from './App.js'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router'
import { fetchCurrUserCompany } from 'store/companies'
import { getTokenExpiry, logout, refreshToken } from 'store/auth'

export const mapState = ({ auth, companies }) => ({
  auth,
  company: companies.currUserCompany
})

export const mapDispatch = dispatch => ({
  push: data => dispatch(push(data)),
  logout: data => dispatch(logout(data)),
  refreshToken: data => dispatch(refreshToken(data)),
  fetchCurrUserCompany: data => dispatch(fetchCurrUserCompany(data)),
  getTokenExpiry: () => dispatch(getTokenExpiry()),
})

export default withRouter(connect(mapState, mapDispatch)(Component))
