import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  MonthView
} from '@devexpress/dx-react-scheduler-material-ui'

const TimeTableLayoutCalendar = (props) => <MonthView.TimeTableLayout {...props} style={{ minWidth: 'auto' }} />

TimeTableLayoutCalendar.propTypes = {
  cellsData: PropTypes.array.isRequired
}

export default memo(TimeTableLayoutCalendar)
