import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  important: {
    color: theme.palette.error.main,
    fontWeight: 'bold'
  },
  stepperButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    margin: theme.spacing(1),
  },
  stepperButtonsContainerFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(1),
  },
  centeredAuthenticator: {
    display: 'flex',
    justifyContent: 'center',
    width: '65%',
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    },
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  authenticatorContainer: {
    display: 'flex',
    margin: theme.spacing(3, 0, 2),
  },
  authenticatorNotSelected: {
    backgroundColor: theme.palette.common.white,
    opacity: 0.5
  },
  wFull: {
    width: '100%'
  },
  wHalf: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    },
  },
  authenticatorButton: {
    borderRadius: '5px',
    display: 'block',
    height: theme.spacing(5),
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: 16
  },
  googleLink: {
    marginRight: 20
  },
  contentContainer: {
    height: '410px'
  },
  navButton: {
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      width: "35%"
    },
  }
}))
