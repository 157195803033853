import { fullName } from 'utils/functions'
const FETCH_REMITTANCES = fullName('fetch-remittances', 'FETCH_REMITTANCES')
const FETCH_REMITTANCES_SUCCESS = fullName('fetch-remittances-success', 'FETCH_REMITTANCES_SUCCESS')
const FETCH_REMITTANCES_FAILURE = fullName('fetch-remittances-failure', 'FETCH_REMITTANCES_FAILURE')
const CREATE_REMITTANCE = fullName('create-remittance', 'CREATE_REMITTANCE')
const CREATE_REMITTANCE_SUCCESS = fullName('create-remittance-success', 'CREATE_REMITTANCE_SUCCESS')
const CREATE_REMITTANCE_FAILURE = fullName('create-remittance-failure', 'CREATE_REMITTANCE_FAILURE')
const CLEAR_REMITTANCE_STORE = fullName('clear-remittances-store', 'CLEAR_REMITTANCE_STORE')
const CLOSE_SNACKBAR = fullName('create-remittance-close-snackbar', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_REMITTANCES,
  FETCH_REMITTANCES_SUCCESS,
  FETCH_REMITTANCES_FAILURE,
  CREATE_REMITTANCE,
  CREATE_REMITTANCE_SUCCESS,
  CREATE_REMITTANCE_FAILURE,
  CLOSE_SNACKBAR
}

export const fetchRemittances = payload => ({
  type: FETCH_REMITTANCES,
  payload
})

export const createRemittance = payload => ({
  type: CREATE_REMITTANCE,
  payload
})

export const clearRemittances = () => ({
  type: CLEAR_REMITTANCE_STORE
})

const initialState = {
  list: [],
  error: false,
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

export const remittances = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REMITTANCES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error: false,
        lastUpdate: Date.now()
      }
    case FETCH_REMITTANCES_FAILURE:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case CREATE_REMITTANCE_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: action.payload
        }
      }
    case CREATE_REMITTANCE_FAILURE:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    case CLEAR_REMITTANCE_STORE:
      return {
        ...state,
        list: [],
        error: false
      }

    default:
      return state
  }
}
