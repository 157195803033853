import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
  root: {
    height: 500
  },
  listItem: {
    cursor: 'pointer'
  },
  logs: {
    overflowY: 'auto',
    height: 500
  },
  logMessage: {
    marginBottom: '25px'
  },
  listItemSecondaryText: {
    color: theme.palette.action.disabled,
    fontSize: theme.typography.caption.fontSize
  }
}))
