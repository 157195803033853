import { all, call, put, takeLatest, fork } from 'redux-saga/effects'
import { ACTIONS } from '../index'
import { dismissErrorSnackBar } from 'utils/generators'
import { performFetchBusinessTerms } from '../requests'

export function* fetchBusinessTerms() {
  const { FETCH_BUSINESS_TERMS_SUCCESS, FETCH_BUSINESS_TERMS_ERROR, CLOSE_SNACKBAR } = ACTIONS
  try {
    const { data } = yield call(performFetchBusinessTerms)
    yield put({ type: FETCH_BUSINESS_TERMS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FETCH_BUSINESS_TERMS_ERROR })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export default function* rootSaga() {
  const { FETCH_BUSINESS_TERMS } = ACTIONS
  yield all([
    takeLatest(FETCH_BUSINESS_TERMS, fetchBusinessTerms)
  ])
}
