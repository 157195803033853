import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import config from 'store'
import AppContainer from 'containers/App'
import { Redirect, Route } from 'react-router'
// import Dashboard from 'containers/Dashboard'
import Login from 'containers/Login'
import SetupAuthenticator from 'containers/SetupAuthenticator'
import ResetAuthenticator from 'containers/ResetAuthenticator'
import ConfirmAuthenticator from 'containers/ConfirmAuthenticator'
import ForgotPassword from 'containers/ForgotPassword'
import ResetPassword from 'containers/ResetPassword'
import {
  SentTransactions,
  CreateBooking as CreateBookingTransaction,
  CreateRemittance as CreateRemittanceTransaction,
} from 'containers/SentTransactions'
import CSVMenu from 'containers/CSVMenu'
import NotificationCentre from 'containers/NotificationCentre'
import NotificationCentreConfiguration from 'containers/NotificationCentreConfiguration'
import FileDataDelivery from 'containers/FileDataDelivery'
import TaskScheduler from 'containers/TaskScheduler'
import User from 'containers/User'
import Invite from 'containers/Invite'
import CompanyInvite from 'containers/CompanyInvite'
import BookingsReview from 'containers/BookingsReview'
import RemittancesReview from 'containers/RemittancesReview'
import CommercialLinksList from 'containers/CommercialLinksList'
import CommercialLinkDetails from 'containers/CommercialLinkDetails'
import PaymentProfileList from 'containers/PaymentProfileList'
import PaymentsStatement from 'containers/PaymentsStatement'
import InstantPayments from 'containers/InstantPayments'
import PaymentProfileConfiguration from 'containers/PaymentProfileConfiguration'
import SettlementSchedulesList from 'containers/SettlementSchedulesList'
import SettlementScheduleEdit from 'containers/SettlementScheduleEdit'
import BusinessTermsList from 'containers/BusinessTermsList'
import BusinessTermsConfiguration from 'containers/BusinessTermsConfiguration'
import EntityProfile from 'containers/EntityProfile'
import UserProfile from 'containers/UserProfile'
import ErrorFallback from 'containers/App/components/ErrorFallback'
import { ROUTE_URL } from 'constants.js'
import 'css/main.css'
import 'css/reset.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import SimulationTool from 'containers/SimulationTool'
import MaintenanceMode from 'containers/MaintenanceMode'
import Registration from 'containers/Registration'
import useCrowdin from 'utils/useCrowdin'
import BillingInvoices from 'containers/Billing/BillingInvoices'
import Subscription from 'containers/Billing/Subscription'
import BillingDetails from 'containers/Billing/BillingDetails'
import CompaniesList from 'containers/Companies/CompaniesList'
import TLMain from 'containers/TLPay/TLMain'
import { getDefaultLanguage } from 'containers/App/utils'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: window.location.hostname,
  release: process.env.REACT_APP_SENTRY_RELEASE,
})

const App = () => {
  const { store, history } = config
  const [language, setLanguage] = React.useState(getDefaultLanguage())
  const { messages } = useCrowdin(language)

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} onError={console.error}>
      <Provider store={store}>
        <IntlProvider defaultLocale={language} locale={language} messages={messages}>
          <ConnectedRouter history={history}>
            <AppContainer language={language} onLanguageChange={setLanguage}>
              <Route exact path="/">
                <Redirect to={ROUTE_URL.bookingsReview} />
              </Route>
              <Route exact path={ROUTE_URL.login} component={Login} />
              <Route exact path={ROUTE_URL.registration} component={Registration} />
              <Route exact path={ROUTE_URL.setupAuthenticator} component={SetupAuthenticator} />
              <Route exact path={ROUTE_URL.resetAuthenticator} component={ResetAuthenticator} />
              <Route exact path={ROUTE_URL.confirmAuthenticator} component={ConfirmAuthenticator} />
              <Route exact path={ROUTE_URL.resetPassword} component={ResetPassword} />
              <Route exact path={ROUTE_URL.forgotPassword} component={ForgotPassword} />
              <Route exact path={ROUTE_URL.createTransaction} component={SentTransactions} />
              <Route exact path={ROUTE_URL.createBookingTransaction} component={CreateBookingTransaction} />
              <Route exact path={ROUTE_URL.createRemittanceTransaction} component={CreateRemittanceTransaction} />
              <Route exact path={ROUTE_URL.csv} component={CSVMenu} />
              <Route exact path={ROUTE_URL.notifications} component={NotificationCentre} />
              <Route exact path={ROUTE_URL.notificationsConfiguration} component={NotificationCentreConfiguration} />
              <Route exact path={ROUTE_URL.fileDataDelivery} component={FileDataDelivery} />
              <Route exact path={ROUTE_URL.taskScheduler} component={TaskScheduler} />
              <Route exact path={ROUTE_URL.invite} component={Invite} />
              <Route exact path={ROUTE_URL.companyInvite} component={CompanyInvite} />
              <Route exact path={ROUTE_URL.userById} component={User} />
              <Route exact path={ROUTE_URL.bookingsReview} component={BookingsReview} />
              <Route exact path={ROUTE_URL.remittancesReview} component={RemittancesReview} />
              <Route exact path={ROUTE_URL.paymentsStatement} component={PaymentsStatement} />
              <Route exact path={ROUTE_URL.instantPayments} component={InstantPayments} />
              <Route exact path={ROUTE_URL.commercialLinksList} component={CommercialLinksList} />
              <Route exact path={ROUTE_URL.commercialLinkDetails} component={CommercialLinkDetails} />
              {/* Disabled for time being by Roberto's request */}
              {/* <Route exact path={ROUTE_URL.commercialLinkBulkCreation} component={CommercialLinkBulkCreation} /> */}
              <Route exact path={ROUTE_URL.entityProfile} component={EntityProfile} />
              <Route exact path={ROUTE_URL.userProfile} component={UserProfile} />
              <Route exact path={ROUTE_URL.paymentProfileList} component={PaymentProfileList} />
              <Route exact path={ROUTE_URL.paymentProfileConfiguration} component={PaymentProfileConfiguration} />
              <Route exact path={ROUTE_URL.settlementSchedulesList} component={SettlementSchedulesList} />
              <Route exact path={ROUTE_URL.settlementScheduleEdit} component={SettlementScheduleEdit} />
              <Route exact path={ROUTE_URL.businessTermsList} component={BusinessTermsList} />
              <Route exact path={ROUTE_URL.businessTermsCreate} component={BusinessTermsConfiguration} />
              <Route exact path={ROUTE_URL.businessTermsEdit} component={BusinessTermsConfiguration} />
              <Route exact path={ROUTE_URL.simulationTool} component={SimulationTool} />
              <Route exact path={ROUTE_URL.maintenanceMode} component={MaintenanceMode} />
              <Suspense fallback={<div>Loading...</div>}>
                <Route exact path={ROUTE_URL.billingSubscription} component={Subscription} />
                <Route exact path={ROUTE_URL.billingInvoices} component={BillingInvoices} />
                <Route exact path={ROUTE_URL.billingDetails} component={BillingDetails} />
                <Route exact path={ROUTE_URL.companiesList} component={CompaniesList} />
                <Route exact path={ROUTE_URL.tlpay} component={TLMain} />
              </Suspense>
            </AppContainer>
          </ConnectedRouter>
        </IntlProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
