import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PrintIcon from '@material-ui/icons/Print'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { memo, useMemo, useRef } from 'react'
import Calendar from './Calendar'
import { useReactToPrint } from 'react-to-print'

const MONTHS_OPTIONS = [ 3, 6, 12 ]

const CalendarGroup = ({
  currentEventsMap,
  timezone,
  monthsToDisplay,
  onMonthsToDisplayChange,
  onDayClick,
  startFrom,
  onDateNavigate,
}) => {
  const componentRef = useRef()
  const period = useMemo(() => [ ...Array(monthsToDisplay).keys() ].map((_, index) => moment(startFrom).clone().add(index, 'month').startOf('month')), [ startFrom, monthsToDisplay ])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const isBeforeCurrentMoth = period[0].diff(moment(), 'months') <= 0

  return (
    <Grid container>
      <Grid container item xs={6} alignItems="center">
        <IconButton disabled={isBeforeCurrentMoth} onClick={onDateNavigate(-1)}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton onClick={onDateNavigate(1)}>
          <ChevronRightIcon />
        </IconButton>
        <Typography>{ `${period[0].format('MMM YY')} - ${period[monthsToDisplay - 1].format('MMM YY')}` }</Typography>
        <IconButton onClick={handlePrint}>
          <PrintIcon />
        </IconButton>
      </Grid>
      <Grid container item xs={6} alignItems="center" justify="flex-end">
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={monthsToDisplay}
          onChange={onMonthsToDisplayChange}
          label="Number of months displayed"
          MenuProps={{variant: "menu"}}
        >
          { MONTHS_OPTIONS.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>) }
        </Select>
        <Typography>
          months
        </Typography>
      </Grid>
      <Grid ref={componentRef} container item spacing={1}>
        { period.map(month => {
          const monthString = month.format('YYYY-MM-DD')
          return (
            <Grid key={monthString} item xs={8}>
              <Paper>
                <Calendar
                  currentDate={monthString}
                  currentEventsMap={currentEventsMap}
                  onDayClick={onDayClick}
                  timezone={timezone}
                />
              </Paper>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

CalendarGroup.propTypes = {
  currentEventsMap: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  monthsToDisplay: PropTypes.number,
  onMonthsToDisplayChange: PropTypes.func,
  startFrom: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  onDateNavigate: PropTypes.func,
  onDayClick: PropTypes.func.isRequired
}

export default memo(CalendarGroup, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps))
