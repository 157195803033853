export const getSellerIdTypeFromInvoice = (invoice) =>
  invoice.commercialLink?.sellerCodeType || Object.keys(invoice.sourceCompany?.companyCodes || {})[0] || '-'

export const getSellerIdFromInvoice = (invoice) => {
  const type = invoice.commercialLink?.sellerCodeType || Object.keys(invoice.sourceCompany?.companyCodes || {})[0]
  return type ? invoice.sourceCompany?.companyCodes?.[type] : '-'
}
export const getBuyerIdTypeFromInvoice = (invoice) => invoice.importedTargetIdType

export const getBuyerIdFromInvoice = (invoice) => invoice.importedTargetId

/**
 * Deprecate when GET /remittance/:id returns invoices prop with same structure as new list invoices
 * https://zube.io/applied-blockchain/travel-ledger/c/3047
 */
export const getSellerIdTypeFromInvoiceOnRemittances = (invoice) =>
  invoice.commercialLink.sellerCodeType || Object.keys(invoice.sourceCompany?.codes || {})[0] || '-'

/**
 * Deprecate when GET /remittance/:id returns invoices prop with same structure as new list invoices
 * https://zube.io/applied-blockchain/travel-ledger/c/3047
 */
export const getSellerIdFromInvoiceOnRemittances = (invoice) => {
  const type = invoice.commercialLink.sellerCodeType || Object.keys(invoice.sourceCompany?.codes || {})[0]
  return type ? invoice.sourceCompany?.codes?.[type] : '-'
}