import { fullName } from 'utils/functions'

// Action Names
const FETCH_INVITE_DATA = fullName('auth', 'FETCH_INVITE_DATA')
const FETCH_INVITE_DATA_SUCCESS = fullName('auth', 'FETCH_INVITE_DATA_SUCCESS')
const FETCH_INVITE_DATA_FAILURE = fullName('auth', 'FETCH_INVITE_DATA_FAILURE')
const REGISTER = fullName('auth', 'REGISTER')
const REGISTER_SUCCESS = fullName('auth', 'REGISTER_SUCCESS')
const REGISTER_FAILURE = fullName('auth', 'REGISTER_FAILURE')

const CLOSE_SNACKBAR = fullName('snackbar', 'CLOSE_SNACKBAR')
const SET_FETCHING = fullName('fetching', 'FETCHING') // This might not be needed - remove if possible
const SET_ERROR = fullName('error', 'ERROR') // This might not be needed - remove if possible

export const ACTIONS = {
  FETCH_INVITE_DATA,
  FETCH_INVITE_DATA_SUCCESS,
  FETCH_INVITE_DATA_FAILURE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SET_FETCHING,
  SET_ERROR,
  CLOSE_SNACKBAR
}

export const fetchInviteData = () => ({
  type: FETCH_INVITE_DATA
})

export const register = (data) => ({
  type: REGISTER,
  payload: data
})

export const setFetching = fetching => ({
  type: SET_FETCHING,
  payload: fetching
})

export const setError = encounteredError => ({
  type: SET_ERROR,
  payload: encounteredError
})

const initialStateSnackbar = {
  isOpened: false,
  isError: false,
  successOrErrorText: ''
}

const initialState = {
  fetching: false,
  token: '',
  inviteDetails: {
    email: '',
    role: '',
    name: ''
  },
  encounteredError: false,
  snackbar: initialStateSnackbar
}

const registration = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        fetching: true
      }
    case REGISTER_SUCCESS:
      return {
        ...action.payload,
        fetching: false
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        fetching: false,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case FETCH_INVITE_DATA:
      return {
        ...state,
        encounteredError: false,
        fetching: true
      }
    case FETCH_INVITE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false
      }
    case FETCH_INVITE_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        encounteredError: true,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case SET_FETCHING:
      return {
        ...state,
        fetching: action.payload
      }
    case SET_ERROR:
      return {
        ...state,
        encounteredError: action.payload
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: initialStateSnackbar
      }
    default:
      return state
  }
}

export default registration
