import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useStyles } from './styles'

export const MaterialAutocomplete = ({
  getOptionLabel,
  inputProps,
  label,
  onInputChange,
  onInputClear,
  onSelect,
  options,
  placeholder,
  renderOptionKeys,
  onChange,
  ...props
}) => {
  const classes = useStyles()
  const selectedOption = useRef(undefined)

  const renderOption = option => (
    <Table onClick={() => onSelect(option)} className={classes.option} dense="true">
      <TableBody>
        <TableRow>
          {
            renderOptionKeys.length > 0 && renderOptionKeys.map((obj, k) => (
              <TableCell key={k}>{option[obj]}</TableCell>
            ))
          }
        </TableRow>
      </TableBody>
    </Table>
  )

  useEffect(() => {
    return () => {
      selectedOption.current = undefined
    }
  }, [])

  const handleOnClear = (event, value, reason) => {
    if (reason === 'clear') {
      onInputClear()
    }
  }

  const handleKeyDown = ({ key }) => {
    if ((key === 'Enter' || key === 'Tab') && selectedOption.current) {
      onSelect(selectedOption.current)
    }
  }

  const handleHighlightChange = (event, option) => {
    selectedOption.current = option
  }

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      PaperComponent={params => <Paper {...params} className={classes.paper} />}
      renderOption={renderOption}
      autoSelect
      onHighlightChange={handleHighlightChange}
      onInputChange={handleOnClear}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onChange={onInputChange}
          placeholder={placeholder}
          fullWidth
          {...inputProps}
        />
      )}
      {...props}
    />
  )
}

MaterialAutocomplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  renderOptionKeys: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputClear: PropTypes.func,
  onChange: PropTypes.func,
  inputProps: PropTypes.object
}

MaterialAutocomplete.defaultProps = {
  label: undefined,
  placeholder: 'Type a name',
  options: [],
  renderOptionKeys: [ 'name' ],
  getOptionLabel: (props) => props.name,
  onSelect: () => {},
  onInputClear: () => {},
  onInputChange: () => {},
  onChange: () => {},
  inputProps: {
    margin: 'normal'
  }
}
