import React from 'react'
import upperFirst from 'lodash/upperFirst'
import isEmpty from 'lodash/isEmpty'
import {
  COMMON_STATE,
  PAYMENT_STATE,
  BUSINESS_STATE,
  UPDATE_INITIAL_STATE,
  CLEAR_FIELD_ERRORS,
  REPEATED_BUYER_SELLER_ERROR
} from './constants'
import {
  ACCOUNT_ROLE
} from 'constants.js'
import { useIntl } from 'react-intl'

const ENTITY_BY_ROLE = {
  [ACCOUNT_ROLE.BUYER]: [
    { sourceKey: 'id', objKey: 'buyerId' },
    { sourceKey: '*', objKey: 'paymentBuyer' }
  ],
  [ACCOUNT_ROLE.SELLER]: [
    { sourceKey: 'id', objKey: 'sellerId' },
    { sourceKey: '*', objKey: 'paymentSeller' }
  ]
}

const initialState = formatMessage => ({
  common: {
    description: '',
    status: '',
    buyerId: '',
    paymentBuyer: undefined,
    sellerId: '',
    paymentSeller: undefined,
    errorStatusField: formatMessage({ id: 'commercial-link-edit-state.status-is-required' }),
    [`error${ACCOUNT_ROLE.BUYER}Field`]: formatMessage({ id: 'commercial-link-edit-state.buyer-is-required' }),
    [`error${ACCOUNT_ROLE.SELLER}Field`]: formatMessage({ id: 'commercial-link-edit-state.seller-is-required' }),
  },
  payment: {
    settlementScheduleSelected: '',
    sellerContact: {},
    sellerMandateId: '',
    buyerContact: {},
    buyerMandateId: '',
    errorSettlementScheduleSelectedField: formatMessage({ id: 'commercial-link-edit-state.settlement-schedule-is-required' }),
    errorSellerContactField: formatMessage({ id: 'commercial-link-edit-state.seller-contact-is-required' }),
    errorSellerMandateIdField: formatMessage({ id: 'commercial-link-edit-state.seller-mandate-is-required' }),
    errorBuyerContactField: formatMessage({ id: 'commercial-link-edit-state.buyer-contact-is-required' }),
    errorBuyerMandateIdField: formatMessage({ id: 'commercial-link-edit-state.buyer-mandate-is-required' }),
  },
  business: {
    businessTermsSelected: '',
    errorBusinessTermsSelectedField: formatMessage({ id: 'commercial-link-edit-state.business-terms-is-required' }),
  }
})

const reducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_INITIAL_STATE:
      return {
        ...state,
        common: {
          description: payload.description,
          status: payload.status,
          buyerId: payload.buyerId,
          paymentBuyer: payload.buyerCompany,
          sellerId: payload.sellerId,
          paymentSeller: payload.sellerCompany
        },
        payment: {
          settlementScheduleSelected: payload.settlementScheduleId,
          sellerContact: { id: payload?.paymentConfiguration?.seller?.contactId },
          sellerMandateId: payload?.paymentConfiguration?.seller?.mandateId,
          buyerContact: { id: payload.paymentConfiguration.buyer.contactId },
          buyerMandateId: payload.paymentConfiguration.buyer.mandateId
        },
        business: {
          businessTermsSelected: payload.businessTermsId
        }
      }
    case CLEAR_FIELD_ERRORS:
      return {
        ...state,
        common: {
          ...state.common,
          [`error${ACCOUNT_ROLE.BUYER}Field`]: '',
          [`error${ACCOUNT_ROLE.SELLER}Field`]: ''
        }
      }
    case COMMON_STATE:
      return {
        ...state,
        common: {
          ...state.common,
          ...payload
        }
      }
    case PAYMENT_STATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          ...payload
        }
      }
    case BUSINESS_STATE:
      return {
        ...state,
        business: {
          ...state.business,
          ...payload
        }
      }
    default:
      return state
  }
}

export const useCLEditState = ({ role, currUserCompany, editedCommercialLink }) => {
  const { formatMessage } = useIntl()
  const [ state, dispatch ] = React.useReducer(reducer, initialState(formatMessage))

  const setRepeatEntitiesError = (_role) => {
    dispatch({
      type: COMMON_STATE,
      payload: {
        [`error${_role}Field`]: REPEATED_BUYER_SELLER_ERROR
      }
    })
    ENTITY_BY_ROLE[_role].forEach(item => {
      dispatch({
        type: COMMON_STATE,
        payload: {
          [item.objKey]: item.sourceKey === '*' ? {} : '',
          [`error${upperFirst(item.objKey)}Field`]: item.sourceKey !== '*' ? `${item.objKey} is required` : false
        }
      })
    })
  }

  const setEntityByRole = (_role, selectedCompanyObj) => {
    const isBuyerOrSeller = Object.keys(ENTITY_BY_ROLE).includes(_role)
    if (isBuyerOrSeller) {
      if (_role === ACCOUNT_ROLE.BUYER && state.common.sellerId === selectedCompanyObj?.id) {
        return setRepeatEntitiesError(ACCOUNT_ROLE.BUYER)
      }

      if (_role === ACCOUNT_ROLE.SELLER && state.common.buyerId === selectedCompanyObj?.id) {
        return setRepeatEntitiesError(ACCOUNT_ROLE.SELLER)
      }

      ENTITY_BY_ROLE[_role].forEach(item => {
        dispatch({
          type: COMMON_STATE,
          payload: {
            [item.objKey]: item.sourceKey === '*' ? selectedCompanyObj : selectedCompanyObj?.[item.sourceKey],
            [`error${_role}Field`]: ''
          }
        })
      })
    }
  }

  const commonActions = {
    onSelectEntity: React.useCallback((selectedRole, option) => {
      setEntityByRole(selectedRole, option)
    }, [ state.common ]),
    dispatchValue: React.useCallback(({ target }) => {
      dispatch({
        type: COMMON_STATE,
        payload: {
          [target.name]: target.value,
          [`error${upperFirst(target.name)}Field`]: isEmpty(target.value) ? `${target.name} is required` : false
        }
      })
    }, [])
  }

  const paymentActions = {
    dispatchValue: React.useCallback(({ target: { name, value, required = true } }) => {
      if (name === 'settlementScheduleSelected') {
        dispatch({
          type: PAYMENT_STATE,
          payload: {
            ...initialState(formatMessage).payment,
            [name]: value,
            [`error${upperFirst(name)}Field`]: required && isEmpty(value) ? `${name} ${formatMessage({ id: 'commercial-link-edit-state.is-required' })}` : false
          }
        })
        return
      }
      dispatch({
        type: PAYMENT_STATE,
        payload: {
          [name]: value,
          [`error${upperFirst(name)}Field`]: required && isEmpty(value) ? `${name} ${formatMessage({ id: 'commercial-link-edit-state.is-required' })}` : false
        }
      })
    }, [])
  }

  const businessActions = {
    dispatchValue: React.useCallback(({ target }) => {
      dispatch({
        type: BUSINESS_STATE,
        payload: {
          [target.name]: target.value,
          [`error${upperFirst(target.name)}Field`]: isEmpty(target.value) ? `${target.name} ${formatMessage({ id: 'commercial-link-edit-state.is-required' })}` : false
        }
      })
    }, [])
  }

  React.useEffect(() => {
    setEntityByRole(role, currUserCompany)
  }, [])

  React.useEffect(() => {
    if (editedCommercialLink) {
      dispatch({ type: UPDATE_INITIAL_STATE, payload: editedCommercialLink })
    }
  }, [ !!editedCommercialLink ])

  return {
    state,
    commonActions,
    paymentActions,
    businessActions
  }
}
