import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import { Grid, TextField } from '@material-ui/core'
import FormSelect from 'components/FormSelect'
import { SearchOrViewCompany } from './SearchOrViewCompany'
import { ACCOUNT_ROLE, CL_STATUS_TO_NAME } from 'constants.js'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'

export const CommLinkEntities = React.memo(
  ({
    role,
    currUserCompany,
    classes,
    options,
    searchCompanies,
    clearSearchCompaniesData,
    commonState,
    commonActions,
    isReadOnly,
    isEdit
  }) => {
    const { status, paymentBuyer, paymentSeller } = commonState
    const { dispatchValue } = commonActions
    const { formatMessage } = useIntl()

    const processingEntity = role === ACCOUNT_ROLE.PROCESSING_ENTITY ? currUserCompany.name : ''
    const showSellerSelect = role === ACCOUNT_ROLE.PROCESSING_ENTITY || role === ACCOUNT_ROLE.BUYER
    const showBuyerSelect = role === ACCOUNT_ROLE.PROCESSING_ENTITY || role === ACCOUNT_ROLE.SELLER

    const searchHandler = useCallback(
      throttle(
        text => {
          searchCompanies({ q: text })
        },
        500,
        { trailing: true, leading: true }
      ),
      [ throttle ]
    )

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6} className={classes.half}>
            {showSellerSelect && (
              <SearchOrViewCompany
                classes={classes}
                commonState={commonState}
                options={options}
                searchHandler={searchHandler}
                clearSearchCompaniesData={clearSearchCompaniesData}
                commonActions={commonActions}
                isReadOnly={isEdit}
                label={formatMessage({ id: 'commercial-link-entity.seller-entity' })}
                lookupRole={ACCOUNT_ROLE.SELLER}
                company={paymentSeller}
              />
            )}
            {showBuyerSelect && (
              <SearchOrViewCompany
                classes={classes}
                commonState={commonState}
                options={options}
                searchHandler={searchHandler}
                clearSearchCompaniesData={clearSearchCompaniesData}
                commonActions={commonActions}
                isReadOnly={isEdit}
                label={formatMessage({ id: 'commercial-link-entity.buyer-entity' })}
                lookupRole={ACCOUNT_ROLE.BUYER}
                company={paymentBuyer}
              />
            )}
          </Grid>
          <Grid item xs={6} className={classes.half}>
            <FormSelect
              className={classes.formInput}
              disabled={isReadOnly}
              name="status"
              label={formatMessage({ id: 'commercial-link-entity.status' }) + ' *'}
              onChange={dispatchValue}
              value={status}
              keyTitle={Object.fromEntries(
                Object.entries(CL_STATUS_TO_NAME)
                  .map(([k, v]) => [k, formatMessage({ id: `cl-status-to-name.${kebabCase(v)}` })])
              )}
              error={commonState.errorStatusField}
              required
            />
            {processingEntity && (
              <TextField
                className={classes.formInput}
                name="processingEntity"
                label={formatMessage({ id: 'commercial-link-entity.processing-entity' })}
                onChange={dispatchValue}
                value={processingEntity}
                disabled
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
)

CommLinkEntities.displayName = 'CommLinkEntities'
CommLinkEntities.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.string,
  currUserCompany: PropTypes.object,
  searchCompanies: PropTypes.func,
  commonState: PropTypes.object,
  commonActions: PropTypes.object,
  options: PropTypes.array,
  clearSearchCompaniesData: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isEdit: PropTypes.bool
}
