import React from 'react'
import PropTypes from 'prop-types'
import {
  SCHEDULE_SPS_FILE_FORMAT_KEY_TITLE,
  SCHEDULE_FILE_FORMAT_KEY_TITLE,
  TASK_CRITERIA_TITLE,
} from 'constants.js'
import { cronExpressionToReadableString } from 'utils/cronTransform'
import DataGrid from 'components/DataGrid'
import { toast } from 'react-toastify'
import { downloadTask } from 'services/tasksApi'
import { DownloadNoContentError } from 'utils/errors'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import { useIntl } from 'react-intl'

const fileFormats = {
  ...SCHEDULE_SPS_FILE_FORMAT_KEY_TITLE,
  ...SCHEDULE_FILE_FORMAT_KEY_TITLE
}

const renderPeriod = rowData => (
  moment(rowData.criteria).isValid()
    ? rowData.criteria
    : TASK_CRITERIA_TITLE[rowData.criteria]
)
const renderFrequency = rowData => (
  rowData.frequency
    ? cronExpressionToReadableString(rowData.frequency)
    : 'None'
)
const renderFormatName = rowData => fileFormats[rowData.fileFormat]
const renderYesNo = key => rowData => rowData[key] ? 'Yes' : 'No'
const renderEmails = ({ mailto }) => (
  !mailto ? 'No'
    : mailto.length === 0 ? 'Only Admins'
      : `Admins and ${mailto.join(', ')}`
)

const columns = formatMessage => [
  { name: 'name', title: formatMessage({ id: 'task-scheduler.task-name' }) },
  { name: 'startAt', title: formatMessage({ id: 'task-scheduler.start' }), getCellValue: rowData => rowData.startAt || rowData.createdAt, type: 'date' },
  { name: 'endAt', title: formatMessage({ id: 'task-scheduler.end' }), getCellValue: rowData => rowData.endAt, type: 'date' },
  { name: 'period', title: formatMessage({ id: 'task-scheduler.period' }), getCellValue: renderPeriod },
  { name: 'frequency', title: formatMessage({ id: 'task-scheduler.frequency' }), getCellValue: renderFrequency },
  { name: 'template', title: formatMessage({ id: 'task-scheduler.template' }) },
  { name: 'fileFormat', title: formatMessage({ id: 'task-scheduler.format-name' }), getCellValue: renderFormatName },
  { name: 'fileName', title: formatMessage({ id: 'task-scheduler.file-name' }) },
  { name: 'sftp', title: formatMessage({ id: 'task-scheduler.ftp' }), getCellValue: renderYesNo('renderFtp') },
  { name: 'mailto', title: formatMessage({ id: 'task-scheduler.email' }), getCellValue: renderEmails },
  { name: 'anonymiseData', title: formatMessage({ id: 'task-scheduler.anonymise' }), getCellValue: renderYesNo('anonymiseData') }
]

const TasksTable = ({
  onRunClick = () => { },
  onEditClick,
  onDeleteClick,
  data,
  ...props
}) => {

  const { formatMessage } = useIntl()

  const handleDownload = async (event, task) => {
    try {
      await downloadTask(task.id)
    } catch (error) {
      if (error instanceof DownloadNoContentError) {
        return toast.warn(<Typography>{formatMessage({ id: 'task-scheduler.no-data-matching-the-criteria-to-generate-the-report' })}</Typography>)
      }
      toast.error(<Typography>{formatMessage({ id: 'task-scheduler.something-went-wrong-try-again-later' })}</Typography>)
    }
  }

  return (
    <DataGrid
      name="tasks_scheduled"
      columns={columns(formatMessage)}
      data={data}
      {...props}
      actions={[
        {
          icon: 'play_arrow',
          tooltip: formatMessage({ id: 'task-scheduler.run' }),
          onClick: (_, task) => onRunClick(task.id)
        },
        {
          icon: 'download',
          tooltip: formatMessage({ id: 'task-scheduler.download' }),
          onClick: handleDownload
        },
        {
          icon: 'edit',
          tooltip: formatMessage({ id: 'task-scheduler.edit' }),
          onClick: (_, task) => onEditClick(task)
        },
        {
          icon: 'delete',
          tooltip: formatMessage({ id: 'task-scheduler.delete-task' }),
          onClick: (_, task) => onDeleteClick(task.id)
        }
      ]}
    />
  )
}

TasksTable.propTypes = {
  data: PropTypes.array.isRequired,
  onRunClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
}

export default TasksTable
