import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import { usePostForgotPassword } from './hooks'
import { ROUTE_URL } from 'constants.js'
import { useIntl } from 'react-intl'

const ERROR_EMPTY_FIELD = 'This field is required.'

const ForgotPassword = ({
  replace
}) => {
  const [ email, setEmail ] = React.useState('')
  const [ emailError, setEmailError ] = React.useState(null)
  const { formatMessage } = useIntl()

  const forgotPasswordMutation = usePostForgotPassword()

  const handleEmailChange = ({ target }) => {
    setEmailError(target.value ? null : ERROR_EMPTY_FIELD)
    setEmail(target.value)
  }

  const handleSuccess = () => {
    replace(ROUTE_URL.login)
  }

  const handleSubmit = e => {
    e.preventDefault()
    forgotPasswordMutation.mutate({ email }, { onSuccess: handleSuccess })
  }

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {formatMessage({ id: 'login.forgot-your-password' })}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            onChange={handleEmailChange}
            value={email}
            error={!!emailError}
            helperText={emailError}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="email"
            label={formatMessage({ id: 'login.enter-email-address' })}
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
          />
          <Button
            disabled={!!(emailError) || forgotPasswordMutation.isLoading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {formatMessage({ id: 'login.email-me-a-recovery-link' })}
          </Button>
        </form>
      </div>
    </Container>
  )
}

ForgotPassword.propTypes = {
  replace: PropTypes.func.isRequired
}

export default ForgotPassword
