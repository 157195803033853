import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormSelect from '../../../../components/FormSelect'
import { useStyles } from './styles'
import Button from 'components/Button'
import { useIntl } from 'react-intl'

function UserForm({ user, id, roles, setUserProp, submit }) {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <form className={classes.root} autoComplete="off" onKeyUp={(e) => e.keyCode === 13 && submit()}>
      <Typography component="h1" variant="h5">
        {id ? formatMessage({ id: 'user-form.edit-user' }) : formatMessage({ id: 'user-form.create-user' })}
      </Typography>
      <TextField
        name="name"
        label={formatMessage({ id: 'user-form.name' })}
        value={user.name.value}
        onChange={(e) => {
          if (!e.target.value) {
            setUserProp('name', { err: formatMessage({ id: 'user-form.name-is-required' }), value: '' })
            return
          }
          setUserProp('name', { value: e.target.value })
        }}
        helperText={user.name.err}
        error={!!user.name.err}
        required={true}
      />
      <TextField
        name="email"
        label={formatMessage({ id: 'user-form.email' })}
        value={user.email.value}
        onChange={(e) => setUserProp('email', { value: e.target.value })}
        helperText={user.email.err}
        error={!!user.email.err}
      />
      <FormSelect
        name="role"
        label={formatMessage({ id: 'user-form.type' })}
        keyTitle={roles}
        value={user.role.value}
        onChange={(e) => setUserProp('role', { value: e.target.value })}
        error={user.role.err}
      />
      <Button
        className={classes.fab}
        color="success"
        onClick={submit}
        icon="save"
        disabled={!!user.name.err}
      >
        {formatMessage({ id: 'user-form.save' })}
      </Button>
    </form >
  )
}

UserForm.propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
  roles: PropTypes.object,
  setUserProp: PropTypes.func,
  submit: PropTypes.func
}

export default UserForm
