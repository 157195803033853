import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { useStyles } from './styles'
import classNames from 'clsx'

const ActionButton = ({ title, selection, icon, validate, onClick, color, disabled }) => {
  const classes = useStyles()
  let validation = ''
  try {
    validate(selection)
  } catch (error) {
    validation = error.message
  }
  return (
    <Tooltip title={validation} arrow placement="top">
      <span>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classNames(
            {
              [classes.button]: true,
              [classes.errorColor]: color === 'error',
              [classes.successColor]: color === 'success'
            }
          )}
          disabled={disabled || selection.length === 0 || !!validation}
          startIcon={icon ? <Icon>{icon}</Icon> : null}
          onClick={onClick}
        >
          { title }
        </Button>
      </span>
    </Tooltip>
  )
}

ActionButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  selection: PropTypes.array,
  onClick: PropTypes.func,
  validate: PropTypes.func
}

export default memo(ActionButton)
