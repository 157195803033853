import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Getter, Action, Plugin, TemplateConnector
} from '@devexpress/dx-react-core'

const pluginDependencies = [
  { name: 'EditingState' }
]

const EnhancedEditingState = ({
  onFinishEditingRow = () => {},
  onRowChangesChange = () => {}
}) => {
  const [ lastRowId, setLastRowId ] = useState(undefined)
  const [ lastChanges, setLastChanges ] = useState(undefined)

  const handleRowChangesChange = (rows, { rowId, change }) => {
    const row = rows.find((_row) => _row.id === rowId)
    if (lastRowId === rowId) {
      onRowChangesChange({ rowId, row, change: { ...lastChanges, ...change } })
      setLastChanges({ ...lastChanges, ...change })
    } else {
      onRowChangesChange({ rowId, row, change })
      onFinishEditingRow({ rowId, change })
      setLastRowId(rowId)
      setLastChanges(change)
    }
  }

  return (
    <Plugin
      name="EnhancedEditingState"
      dependencies={pluginDependencies}
    >
      <Getter name="lastRowId" value={lastRowId} />
      <Getter name="lastChanges" value={lastChanges} />
      <TemplateConnector>
        {({ rows }, { changeRow }) => (
          <Action name="updateRow" action={({ rowId, change }) => {
            handleRowChangesChange(rows, { rowId, change })
            changeRow({ rowId, change })
          }} />
        )}
      </TemplateConnector>
      <TemplateConnector>
        {(_, { changeRow }) => (
          <Action name="bulkUpdateRows" action={(updates) => {
            updates.forEach(({ rowId, change }) => {
              changeRow({ rowId, change })
            })
          }} />
        )}
      </TemplateConnector>
    </Plugin>
  )
}

EnhancedEditingState.propTypes = {
  onRowChangesChange: PropTypes.func,
  onFinishEditingRow: PropTypes.func
}

export default EnhancedEditingState
