import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  paper: {
    minWidth: '100%',
    width: 'max-content',
    '& li': {
      padding: 0
    }
  },
  option: {
    '& td': {
      padding: 8
    }
  }
})
