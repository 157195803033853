import React from 'react'
import PropTypes from 'prop-types'
import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from '@devexpress/dx-react-core'

const pluginDependencies = [
  { name: 'Toolbar' },
  { name: 'SelectionState' }
]

const TableTopSummary = ({ children }) => (
  <Plugin name="TableTopSummary" dependencies={pluginDependencies}>
    <Template name="header">
      <TemplatePlaceholder />
      <TemplateConnector>
        {children}
      </TemplateConnector>
    </Template>
  </Plugin>
)

TableTopSummary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func
  ]).isRequired
}

export default TableTopSummary
