import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  Input,
  MenuItem,
  Select,
  Divider,
  Chip,
  TableCell
} from '@material-ui/core'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const MultipleSelectCell = ({ options, value = [], onChange }) => {

  const classes = useStyles()
  const { formatMessage } = useIntl()

  const isAllOptionsSelected = () => value.length === Object.keys(options).length && value.every(el => Object.keys(options).includes(el))

  const handleOnChange = (_value) => {
    if (!_value.includes("option_all")) onChange(_value)
  }

  const handleOnChangeAlt = (_value) => {
    if (_value) onChange([])
    else onChange(Object.keys(options))
  }

  return (
    <TableCell align="center">
      <FormControl fullWidth>
        <Select
          MenuProps={{variant: "menu"}}
          multiple
          value={value}
          onChange={(event) => handleOnChange(event.target.value)}
          input={<Input />}
        >
          <div value='option_all' className={classes.dropdownAllOption}>
            <Chip
              size="small"
              label={formatMessage({ id: isAllOptionsSelected() ? 'multiple-select-cell.unselect-all' : 'multiple-select-cell.select-all' })}
              onClick={() => handleOnChangeAlt(isAllOptionsSelected())}
              variant="outlined"
              className={classes.chip}
            />
          </div>
          <Divider />
          {Object.entries(options).map(([ key, _value ]) => (
            <MenuItem key={key} value={key} className={classes.dropdownOption}>
              {_value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  )
}

MultipleSelectCell.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.object,
  value: PropTypes.array
}

export default MultipleSelectCell
