import React, { memo } from 'react'
import PropTypes from 'prop-types'
import MUIChipInput from 'material-ui-chip-input'
import { Controller } from 'react-hook-form'
import { emailRegex } from 'constants.js'

export const ChipInput = ({
  label,
  value,
  onChange,
  ...props
}) => {

  const handleRemoveEmail = (email, index) => {
    onChange(value?.filter((_, _index) => _index !== index) || [])
  }

  const handleAddEmail = (email) => {
    const exists = value.find(_email => _email === email)
    if (!exists) {
      onChange([ ...value, email ])
    }
  }

  const validateEmail = _value => emailRegex.test(_value)

  return (
    <MUIChipInput
      label={label}
      fullWidth
      color="primary"
      onDelete={handleRemoveEmail}
      onAdd={handleAddEmail}
      value={value || []}
      newChipKeys={[ 'Enter', 'Tab' ]}
      blurBehavior="add"
      onBeforeAdd={validateEmail}
      { ...props }
    />
  )
}

ChipInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func
}

const ChipInputFormControlWrapper = ({
  control,
  name,
  label,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ChipInput
          label={label}
          onChange={field.onChange}
          value={field.value}
          { ...props }
        />
      )} />
  )
}

ChipInputFormControlWrapper.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default memo(ChipInputFormControlWrapper)
