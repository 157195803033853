import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ViewState } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  MonthView,
  Toolbar
} from '@devexpress/dx-react-scheduler-material-ui'
import { firstDayOfWeek } from '../../utils'
import DayScaleLayoutCalendar from './DayScaleLayoutCalendar'
import TimeTableLayoutCalendar from './TimeTableLayoutCalendar'
import makeTimeTableCellCalendar from './TimeTableCellCalendar'
import moment from 'moment'

const Calendar = ({ currentDate, currentEventsMap, onCurrentDateChange, onDayClick, timezone }) => {
  return (
    <Scheduler firstDayOfWeek={firstDayOfWeek}>
      <ViewState
        currentDate={currentDate}
        onCurrentDateChange={onCurrentDateChange}
      />
      <MonthView
        intervalCount={1}
        dayScaleLayoutComponent={DayScaleLayoutCalendar}
        timeTableLayoutComponent={TimeTableLayoutCalendar}
        timeTableCellComponent={makeTimeTableCellCalendar({ currentEventsMap, onDayClick, timezone })}
      />
      <Toolbar flexibleSpaceComponent={() => moment(currentDate).format('MMMM YYYY')} />
    </Scheduler>
  )
}

Calendar.propTypes = {
  currentEventsMap: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  currentDate: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  onCurrentDateChange: PropTypes.func,
  onDayClick: PropTypes.func.isRequired
}

export default memo(Calendar, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps))
