import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import AuthView from 'containers/AuthView/connect'
import FormattedError from 'components/FormattedError'
import CommercialLinksTable from './components/CommercialLinksTable'
import SimulationForm from './components/SimulationForm'
import SimulationDetails from './components/SimulationDetails'
import SimulationsLogs from './components/SimulationsLogs'
import { ROUTE_URL } from 'constants.js'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import { useGetCommercialLinks, useCommercialLinks, useGetSimulations, usePostSimulation, useDeleteSimulation } from './hooks'
import { Redirect } from 'react-router'

const defaultValues = {
  name: '',
  simulationDate: moment().format('YYYY-MM-DDTHH:mm'),
  remainCollected: false
}

const SimulationTool = ({
  currUserCompany,
  location
}) => {
  const queryClient = useQueryClient()
  const [ simulationDate, setSimulationDate ] = useState()
  const reactHookForm = useForm({ defaultValues, resolver: joiResolver(formSchema) })

  const commercialLinksQuery = useGetCommercialLinks(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remoteCommercialLinks = commercialLinksQuery?.data ?? []
  const { commercialLinks, setCommercialLinks, toggleFailedPayment, toggleCommercialLinks } = useCommercialLinks([])
  const simulationsQuery = useGetSimulations()
  const simulationMutation = usePostSimulation({
    onSuccess: () => {
      queryClient.invalidateQueries('simulations')
      queryClient.invalidateQueries('commercialLinks')
      reactHookForm.reset(defaultValues)
    }
  })
  const simulationRemoveMutation = useDeleteSimulation({
    onSuccess: () => {
      queryClient.invalidateQueries('simulations')
    }
  })

  if (!currUserCompany?.isProcessingEntity) {
    return <Redirect to={ROUTE_URL.home} />
  }

  useEffect(() => {
    if (remoteCommercialLinks.length) {
      setCommercialLinks(remoteCommercialLinks)
    }
  }, [ remoteCommercialLinks, setCommercialLinks ])

  const handleSimulationSubmit = data => {
    simulationMutation.mutate(data)
  }

  const errors = useMemo(
    () => [ commercialLinksQuery.error, simulationsQuery.error, simulationMutation.error ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ commercialLinksQuery.error, simulationsQuery.error ]
  )

  useEffect(() => {
    errors.forEach(error => {
      if (error) {
        toast.error(<FormattedError error={error} />)
      }
    })
  }, [ errors ])

  const isSubmitDisabled = !commercialLinks.some(commercialLink => commercialLink.checked === true) || simulationMutation.isLoading

  return (
    <AuthView location={location} title="Simulation">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CommercialLinksTable
            onCheck={toggleCommercialLinks}
            onCheckFailedPayment={toggleFailedPayment}
            commercialLinks={commercialLinks}
          />
        </Grid>
        <Grid item xs={12}>
          <SimulationForm
            commercialLinks={commercialLinks}
            onSubmitData={handleSimulationSubmit}
            onDateChanges={setSimulationDate}
            disabledSubmit={isSubmitDisabled}
            reactHookForm={reactHookForm}
          />
        </Grid>
        <Grid item xs={12}>
          <SimulationDetails
            simulationDate={simulationDate}
            commercialLinks={commercialLinks}
          />
        </Grid>
        <Grid item xs={12}>
          <SimulationsLogs
            simulations={simulationsQuery.data || []}
            onSimulationDelete={simulationRemoveMutation.mutate}
          />
        </Grid>
      </Grid>
    </AuthView>
  )
}

SimulationTool.propTypes = {
  location: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default SimulationTool
