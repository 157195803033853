import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  quickViews: {
    minWidth: 160,
    marginLeft: 10,
    marginRight: 30,
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 6,
      paddingBottom: 6
    }
  },
  form: {
    verticalAlign: 'middle'
  }
}))
