import { STATUSES, INVOICE_STATUS, INVOICE_STATUSES_TO_NAME, INVOICE_ROLE, REMITTANCE_STATUSES } from 'constants.js'
import React from 'react'
import { makeAmountCell } from '../utils'
import { MTableToolbar } from 'material-table'
import PropTypes from 'prop-types'

const StatusGreen = () => <span style={{ color: 'green', fontSize: '16pt' }}>$</span>

const StatusRejected = () => <span style={{ color: 'orange', fontSize: '16pt' }}>X</span>

const StatusRed = () => <span style={{ color: 'red', fontSize: '16pt' }}>$</span>

const StatusEmpty = () => ''

export const BalanceCell = ({ status }) => {
  if (status === INVOICE_STATUS.REMITTED) {
    return <StatusGreen />
  } else if (status === INVOICE_STATUS.PENDING) {
    return <StatusRed />
  } else if (status === INVOICE_STATUS.REJECTED) {
    return <StatusRejected />
  } else {
    return <StatusEmpty />
  }
}

export const balanceCellToExport = ({ status }) => {
  if (status === INVOICE_STATUS.REMITTED) {
    return '$'
  } else if (status === INVOICE_STATUS.PENDING) {
    return 'X'
  } else if (status === INVOICE_STATUS.REJECTED) {
    return '-$'
  } else {
    return ''
  }
}

const renderAbtaNumber = (abta) => (abta ? `(${abta})` : '')

export const PayerNameCell = ({ sourceId, sourceCompany, importedSourceId }) => {
  if (sourceCompany?.name) {
    return `${sourceCompany?.name} ${renderAbtaNumber(importedSourceId)}`
  }
  return String(sourceId)
}
export const PayeeNameCell = ({ targetId, targetCompany, importedTargetId }) => {
  if (targetCompany?.name) {
    return `${targetCompany?.name} ${renderAbtaNumber(importedTargetId)}`
  }
  return String(targetId)
}

export const getTargetIdType = (invoice) => {
  return invoice.importedTargetIdType
}

export const getSourceIdType = (invoice) => {
  return invoice.sourceCompany?.codes[invoice.importedTargetIdType]
    ? invoice.importedTargetIdType // Return same type of the target
    : Object.keys(invoice.sourceCompany?.codes || {})[0]
}

export const getTargetId = (invoice) => {
  return invoice.importedTargetId
}

export const getSourceId = (invoice) => {
  const idType = getSourceIdType(invoice)
  return invoice.sourceCompany?.codes?.[idType]
}

export const OriginalAmountCell = makeAmountCell('originalAmountInCents')
export const CurrentAmountCell = makeAmountCell('currentAmountInCents')
export const AmountPaidCell = makeAmountCell('amount')

export const InvoiceStatusCell = (props) => {
  const { role, status, updatedValues } = props
  const parsedStatus = role === INVOICE_ROLE.TARGET && status === STATUSES.MATCHED ? 'New' : status
  const currentStatus = updatedValues?.status ?? parsedStatus
  return INVOICE_STATUSES_TO_NAME[currentStatus] ? INVOICE_STATUSES_TO_NAME[currentStatus] : currentStatus
}

/**
 * @param {{
 *  status: import('constants.js').RemittanceStatus
 * }} param0
 */
export const RemittanceStatusCell = ({ status }) => REMITTANCE_STATUSES[status] ?? status

export const Toolbar = ({ children, ...toolbarProps }) => (
  <div>
    <MTableToolbar {...toolbarProps} />
    {children}
  </div>
)

Toolbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
