import React, { memo } from 'react'
import classNames from 'clsx'
import { BSD_CALENDAR_EVENTS } from 'constants.js'
import { useStyles } from './styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { Cached, AttachMoney } from '@material-ui/icons'

const Legend = () => {
  const classes = useStyles()
  return (
    <Grid container justify="flex-end" className={classes.legendBox}>
      <Grid item>
        <Typography variant="caption">Legend</Typography>
      </Grid>
      <Grid item container direction="column" alignItems="flex-end">
        <Grid item container className={classes.legendRow}>
          <Typography variant="caption">Disabled day:</Typography>
          <div className={classNames(classes.legendCell, classes.cell, classes[BSD_CALENDAR_EVENTS.HOLIDAY])}>31</div>
        </Grid>
        <Grid item container className={classes.legendRow}>
          <Typography variant="caption">Active Collection(s):</Typography>
          <AttachMoney
            fontSize="small"
            className={classNames(
              classes.legendCell,
              classes.cell,
              classes.GREEN
            )}
          />
        </Grid>
        <Grid item container className={classes.legendRow}>
          <Typography variant="caption">All Collections Skipped:</Typography>
          <AttachMoney
            fontSize="small"
            className={classNames(
              classes.legendCell,
              classes.cell,
              classes.RED
            )}
          />
        </Grid>
        <Grid item container className={classes.legendRow}>
          <Typography variant="caption">Mixed Active/Skipped Collections:</Typography>
          <AttachMoney
            fontSize="small"
            className={classNames(
              classes.legendCell,
              classes.cell,
              classes.ORANGE
            )}
          />
        </Grid>
        <Grid item container className={classes.legendRow}>
          <Typography variant="caption">Active Cycle Start(s):</Typography>
          <Cached
            fontSize="small"
            className={classNames(
              classes.legendCell,
              classes.cell,
              classes.GREEN
            )}
          />
        </Grid>
        <Grid item container className={classes.legendRow}>
          <Typography variant="caption">All Cycle Starts Skipped:</Typography>
          <Cached
            fontSize="small"
            className={classNames(
              classes.legendCell,
              classes.cell,
              classes.RED
            )}
          />
        </Grid>
        <Grid item container className={classes.legendRow}>
          <Typography variant="caption">Mixed Active/Skipped Cycle Starts:</Typography>
          <Cached
            fontSize="small"
            className={classNames(
              classes.legendCell,
              classes.cell,
              classes.ORANGE
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(Legend)
