import Joi from 'joi'

export const formSchema = Joi.object({
  billingAddress: Joi.string().max(255).required(),
  billingCity: Joi.string().max(100).required(),
  billingCountryId: Joi.string(),
  taxIdentificationNumber: Joi.string().max(255).required(),
})

export const defaultValues = {
  billingAddress: '',
  billingCity: '',
  billingCountryId: '',
  taxIdentificationNumber: '',
}
