import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { useStyles } from '../styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useIntl } from 'react-intl'

const CsvDropZone = ({
  uploadCSV
}) => {
  const [ errorMessage, setErrorMessage ] = useState()
  const [ csvInfo, setCsvInfo ] = useState()
  const { formatMessage } = useIntl()

  const handleUpload = async (files) => {
    try {
      setErrorMessage()
      setCsvInfo()
      const file = files[0]
      setCsvInfo({ filename: file.name })
      uploadCSV(file)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const classes = useStyles()
  return (
    <>
      <Paper className={[ classes.childForm, errorMessage ? classes.error : '' ]}>
        <Dropzone multiple={false} onDrop={handleUpload} className={classes.dropZone}>
          { csvInfo ? (
            <Typography variant="subtitle2">{ `File ${csvInfo.filename}` }</Typography>
          ) : (
            <>
              <Typography variant="subtitle2">{formatMessage({ id: 'csv-drop-zone.drop-csv-file-formatted-with-following-fields-by-line' })}:</Typography>
              <Typography variant="subtitle2">{formatMessage({ id: 'csv-drop-zone.company-name-email-name' })}</Typography>
              <Typography variant="subtitle2">{formatMessage({ id: 'csv-drop-zone.or-click-here-to-select-file' })}</Typography>
            </>
          )
          }
        </Dropzone>
      </Paper>
      { errorMessage ? (<Typography variant="subtitle2" className={classes.error}>{errorMessage}</Typography>) : null }
    </>
  )
}

CsvDropZone.propTypes = {
  uploadCSV: PropTypes.func
}

export default CsvDropZone
