import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'

const ResetAuthenticator = ({
  auth,
  resetMFA
}) => {
  const {
    register,
    handleSubmit,

    formState: {
      errors,
    },
  } = useForm({ resolver: joiResolver(formSchema) })

  const onSubmit = handleSubmit((data) => {
    resetMFA(data)
  })

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome to Travel Ledger
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            {...register("username")}
            id="email"
            label="email"
            autoComplete="email"
            error={errors.username}
            helperText={errors.username}
            fullWidth
            autoFocus
            variant="outlined"
            margin="dense"
          />
          <TextField
            {...register("password")}
            error={errors.password}
            helperText={errors.password}
            variant="outlined"
            margin="dense"
            fullWidth
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            {...register("recoveryKey")}
            id="recoveryKey"
            label="recovery key"
            error={errors.recoveryKey}
            helperText={errors.recoveryKey}
            fullWidth
            variant="outlined"
            margin="dense"
          />
          <Button
            disabled={!!(auth?.fetching)}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset 2-factor authentication
          </Button>
        </form>
      </div>
    </Container>
  )
}

ResetAuthenticator.defaultProps = {
  resetMFA: () => {
  }
}

ResetAuthenticator.propTypes = {
  auth: PropTypes.object.isRequired,
  resetMFA: PropTypes.func.isRequired
}

export default ResetAuthenticator
