import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel">
    <CancelIcon />
  </IconButton>
)

CancelButton.propTypes = {
  onExecute: PropTypes.func
}

export default CancelButton
