import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const getPaymentProfileDefaultConfig = async () => {
  const url = `${API_URL}/payment-profiles/default-config`
  const { data } = await httpFetch(url, {
    shouldThrowError: true
  })
  return data
}

export const getPaymentProfiles = async (urlParams) => {
  const url = `${API_URL}/payment-profiles`
  const { data } = await httpFetch(url, {
    urlParams,
    shouldThrowError: true
  })
  return data
}

export const getPaymentProfile = async (id) => {
  const url = `${API_URL}/payment-profiles/${id}`
  const { data } = await httpFetch(url, { shouldThrowError: true })

  return {
    ...data.attributes,
    id: data.id,
  }
}
