import React from 'react'
import PropTypes from 'prop-types'
import { SPS_IMPORT_FILE_STATUSES, STATUSES } from 'constants.js'
import UnderlinedCell from '../UnderlinedCell'
import HelpPopup from 'components/HelpPopup'

const StatusCell = React.memo(({ status }) => {
  if (status === STATUSES.NA) {
    return (
      <HelpPopup id="booking-import.transaction-not-matched">
        <UnderlinedCell error>
          Not matched
        </UnderlinedCell>
      </HelpPopup>
    )
  }
  return SPS_IMPORT_FILE_STATUSES[status]
})

StatusCell.displayName = 'StatusCell'

StatusCell.propTypes = {
  status: PropTypes.string
}

export default StatusCell
