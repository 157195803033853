import { Weekday } from '@travel-ledger/type-constants'
import moment from 'moment'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

const weekdayFormats = {
  long: 'dddd',
  short: 'ddd',
  narrow: 'dd'
}

/**
 * Returns a list of weekdays and their localized names. The returned
 * array will respect the locale's start of week; this means the first
 * item in the array can have a `value` different from `0`.
 * 
 * By default, all weekdays are included in the list, with `'long'`
 * format and use the current locale provided by Intl. This can be
 * overridden with the `options` parameter.
 * 
 * @type {<
 *  T extends Weekday = Weekday
 * >(
 *  options?: {
 *    allowedWeekdays?: T[],
 *    format?: keyof typeof weekdayFormats
 *    locale?: string
 *  }
 * ) => Array<{ value: T; label: string }>}
 */
export const useMemoWeekdays = ({
  allowedWeekdays = Object.values(Weekday),
  format = 'long',
  locale
} = {}) => {
  const { locale: currentLocale } = useIntl()

  return useMemo(() => {
    const items = []
    const date = moment()
      .locale(locale ?? currentLocale)
      .startOf('week')

    for (let i = 0; i < 7; ++i) {
      // iso starts at 1 with sunday as 7, we start with sunday at 0
      const weekday = date.isoWeekday() === 7 ? 0 : date.isoWeekday()

      if (!allowedWeekdays.includes(weekday)) {
        date.add(1, 'day')
        continue
      }

      items.push({
        value: weekday,
        label: date.format(weekdayFormats[format]),
      })
      date.add(1, 'day')
    }

    return items
  }, [allowedWeekdays, format, locale, currentLocale])
}
