import { API_URL } from 'constants.js'
import { MalformedFileError } from 'utils/errors'
import httpFetch from 'utils/httpFetch'

export const customErrorHandler = ({ status, data }) => {
  if (status === 400) {
    if (data?.errors && Array.isArray(data?.errors)) {
      throw new MalformedFileError('', data?.errors)
    }else if(Array.isArray(data)){
      throw new MalformedFileError('', data)
    }
  }
}

export const performPostCSV = ({ autoCommit, files, force = false }) => {
  const url = `${API_URL}/invoices?autoCommit=${autoCommit}&force=${force}`
  const body = new FormData()
  files.forEach((file, index) => {
    body.append(`csv_file_${index}`, file)
  })
  return httpFetch(url, { body, method: 'POST', shouldThrowError: true, customErrorHandler })
}

export const performPatchCSV = ({ files }) => {
  const url = `${API_URL}/invoices`
  const body = new FormData()
  files.forEach((file, index) => {
    body.append(`csv_file_${index}`, file)
  })
  return httpFetch(url, { body, method: 'PATCH', shouldThrowError: true, customErrorHandler })
}
