import TextField from '@material-ui/core/TextField'
import React from 'react'

const FormInputDate = props => (
  <TextField
    {...props}
    InputLabelProps={{
      shrink: true
    }}
    type="date"
  />
)

export default FormInputDate
