import { Typography, OutlinedInput, Grid } from '@material-ui/core'
import React, { useEffect, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import {
  API_URL,
  EWALLET_PROVIDER,
  NIUM_ONBOARD_STATUS,
  NIUM_RFI_TYPE,
  NIUM_RFI_TEMPLATE_TYPE,
  NIUM_STAKEHOLDER_TYPE,
  isProduction,
} from 'constants.js'
import { InputText } from 'components/ui/Form/InputText'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useForm, Controller } from "react-hook-form"
import { useStyles } from './KYBForm/styles'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import FormSelect from 'components/FormSelect'
import CountrySelectInput from 'containers/CountrySelectInput'
import Button from 'components/Button'
import { post } from '../../../../utils/api'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import InfoIcon from '@material-ui/icons/Info'
import Alert from '@material-ui/lab/Alert'
import { forEach } from 'lodash'
import {
  corporateDocumentTypeEu,
  corporateDocumentTypeUk,
  personDocumentTypeEu,
  personDocumentTypeUk,
  intendedUseOfAccount,
} from './KYBForm/form-schemas/nium/values'
import { manageFileCompression } from 'utils/compress-file'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import { camelCaseToWords } from 'utils/functions'
import KYCLink from './KYCLink'
import { useIntl } from 'react-intl'

const personDocumentMap = {
  [EWALLET_PROVIDER.NIUM_EU]: personDocumentTypeEu,
  [EWALLET_PROVIDER.NIUM_UK]: personDocumentTypeUk,
}

const corporateDocumentMap = {
  [EWALLET_PROVIDER.NIUM_EU]: corporateDocumentTypeEu,
  [EWALLET_PROVIDER.NIUM_UK]: corporateDocumentTypeUk,
}

// unique key is template id + reference id + status  
const getItemKey = (template) => {
  const { referenceId, templateId, status } = template
  let itemKey = `${templateId}*${status}`
  // noticed that this can be missing if the status is responded
  if(referenceId) {
    itemKey += `*${referenceId}`
  }
  return itemKey
}
const kycLinkPattern = isProduction ?
  'https://integrations.partners.instarem.com/' : 'https://integrationspreprod.partners.instarem.com/'

const isKycLink = item => item.remarks?.includes(kycLinkPattern)
const getKycLink = item => {
  const [url] = item.remarks?.match(/\bhttps?:\/\/\S+/gi)
  return url
}
const displayRemarks = item => {
  if(isKycLink(item)) {
    return item.remarks?.replace(getKycLink(item), '')
  }
  return item.remarks
}

const RFI = ({ data, companyId, provider }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const { data: rfiDetails, isFetching } = useQuery('onboardRFI', () => get(`${API_URL}/e-wallet/onboard-rfi-details/${companyId}`), {
    retry: false
  })

  const allResponded = rfiDetails && rfiDetails.rfiTemplates &&
   !rfiDetails.rfiTemplates?.some(t => t.status === NIUM_ONBOARD_STATUS.RFI_REQUESTED && !isKycLink(t))

  const { control, errors, setValue, handleSubmit } = useForm()
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const [kycLink, setKycLink] = useState('')
  const { data: regenLink, refetch: regenerateLink } = useQuery('regenerateKYC', () => get(`${API_URL}/e-wallet/regenerate-kyc/${companyId}`), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
    onSuccess: () => {
      toast.success(formatMessage({ id: 'tlpay.kyc-link-regenerated-successfully' }))
    },
    onError: (error) => {
      toast.error(<p>{getReadableErrorMessage(error)} <br/><br /> {formatMessage({ id: 'tlpay.the-kyc-link-failed-to-regenerate' })}<br/> {formatMessage({ id: 'tlpay.please-try-again-or-contact-support' })}</p>)
    },
  })
  const kycUrl = regenLink?.redirectUrl || kycLink

  useEffect(() => {
    if (rfiDetails) {
      forEach(rfiDetails.rfiTemplates, (template) => {
        if(template.referenceId) {
          const itemKey = getItemKey(template)
          const itemName = `${itemKey}.businessInfo`
          if (template.template.rfiType === NIUM_RFI_TYPE.APPLICANT) {
            setValue(`${itemName}.applicantDetails.referenceId`, template.referenceId)
          }
          if (template.template.rfiType === NIUM_RFI_TYPE.STAKEHOLDER) {
            setValue(`${itemName}.stakeholders.0.referenceId`, template.referenceId)
          }
        }
      })
    }
  }, [rfiDetails, setValue])

  const onDrop = async (files, fieldName) => {
    for(const index of Object.keys(files)) {
      const file = files[index]
      const fileToProcess = await manageFileCompression(file);

      const sizeLimitBytes = 1.5 * 1000 * 1000 // 1.5 MB
      if(fileToProcess.size > sizeLimitBytes) {
        alert(formatMessage({ id: 'tlpay.oops-the-file-you-re-trying-to-upload-exceeds-our-size-limit-of-1-5-mb' }))
        return
      }
      setValue(`${fieldName}.${index}.document`, fileToProcess.data)
      setValue(`${fieldName}.${index}.fileName`, file.name)
      setValue(`${fieldName}.${index}.fileType`, file.type)
    }
  }

  const respondOnboardRFIDetails = (onboardInfo) => {
    return post(`/e-wallet/onboard-rfi-details/${companyId}`, onboardInfo)
  }

  const { mutate: respondOnboardRFIMutation, isLoading } = useMutation(respondOnboardRFIDetails, {
    onSuccess: () => {
      toast.success(formatMessage({ id: 'tlpay.onboard-rfi-response-sent-successfully' }))
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  // manage loading spinner
  useEffect(() => {
    if (isLoading || isFetching) {
      setGlobalSpinner(true)
    } else {
      setGlobalSpinner(false)
    }
  }, [isLoading, setGlobalSpinner, isFetching])

  const onSubmit = handleSubmit((formData) => {
    const dataToSend = {}
    let hasError = false
    for(const itemKey in formData) {
      const [templateId, status, referenceId] = itemKey.split('*')
      const details = rfiDetails.rfiTemplates.find(t => 
        t.templateId === templateId &&
        t.status === status &&
        (!referenceId || t.referenceId === referenceId)
      )
      if(!details || details.status === NIUM_ONBOARD_STATUS.RFI_RESPONDED) {
        // skip completed
        continue
      }

      // start validation
      const { template: {
        rfiType,
        type,
        name,
        requiredFields,
      } } = details
      
      // determine nested path where fields are
      let formPath = formData[itemKey].businessInfo
      // corporate type
      if(rfiType === NIUM_RFI_TYPE.CORPORATE) {
        if(type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
          formPath = formPath.documentDetails
        } else if (type === NIUM_RFI_TEMPLATE_TYPE.DATA) {
          if (name === 'otherData') {
            formPath = formPath.additionalInfo
          } else {
            formPath = formData[itemKey].riskAssessmentInfo
          }
        }
      }
      // applicant type
      if(rfiType === NIUM_RFI_TYPE.APPLICANT) {
        formPath = formPath.applicantDetails
        if(type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
          formPath = formPath.documentDetails
        }
      }
      // stakeholder type
      if(rfiType === NIUM_RFI_TYPE.STAKEHOLDER) {
        formPath = formPath.stakeholders[0]
        const { type: shType = NIUM_STAKEHOLDER_TYPE.INDIVIDUAL } = data?.stakeholders?.find((s) => s.referenceId === details.referenceId) || {}
        if(shType === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL) {
          formPath = formPath.stakeholderDetails
        } else if(shType === NIUM_STAKEHOLDER_TYPE.CORPORATE) {
          formPath = formPath.businessPartner
        }

        if(type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
          formPath = formPath.documentDetails
        }
      }

      // validate
      const fieldsToCheck = requiredFields.map(rf => rf.fieldValue)
      const hasMissing = fieldsToCheck.some(ftc => !formPath[ftc])
      if(hasMissing) {
        console.error(formatMessage({ id: 'tlpay.missing-required-field' }))
        hasError = true
        break
      }

      // no errors, include this rfi template 
      dataToSend[itemKey] = formData[itemKey]
    }

    if(hasError) {
      toast.error(formatMessage({ id: 'tlpay.missing-required-fields-please-complete-any-missing-information-in-the-fields-requested' }))
    } else {
      respondOnboardRFIMutation(dataToSend)
    }
  })

  const renderFields = (item) => {
    if (item.status === NIUM_ONBOARD_STATUS.RFI_RESPONDED) {
      return <Alert severity="success">{formatMessage({ id: 'tlpay.rfi-responded' })}</Alert>
    }

    // set the field name based on the templateId and the field name to be grouped
    // the .0. represent the first item in the array
    // mapped based on https://docs.nium.com/apis/docs/rfi-templates
    const itemKey = getItemKey(item)
    let itemNameOrigin = `${itemKey}.businessInfo`
    if (item.template.rfiType === NIUM_RFI_TYPE.CORPORATE) {
      if (item.template.type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
        itemNameOrigin += `.documentDetails`
      }
      if (item.template.type === NIUM_RFI_TEMPLATE_TYPE.DATA) {
        if ([
          'intendedUseOfAccount',
          'transactionCountries',
        ].includes(item.template.name)) {
          itemNameOrigin = `${itemKey}.riskAssessmentInfo`
        }

        if(item.template.name === 'otherData') {
          itemNameOrigin += `.additionalInfo`
        }
      }
    }
    if (item.template.rfiType === NIUM_RFI_TYPE.APPLICANT) {
      if (item.template.type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
        itemNameOrigin += `.applicantDetails.documentDetails`
      }
    }
    if (item.template.rfiType === NIUM_RFI_TYPE.STAKEHOLDER) {
      itemNameOrigin += `.stakeholders.0`
      const {
        type = NIUM_STAKEHOLDER_TYPE.INDIVIDUAL
      } = data?.stakeholders?.find((s) => s.referenceId === item.referenceId) || {}
      if (type === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL) {
        itemNameOrigin += '.stakeholderDetails'
      } else if (type === NIUM_STAKEHOLDER_TYPE.CORPORATE) {
        itemNameOrigin += '.businessPartner'
      }

      if (item.template.type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
        itemNameOrigin += `.documentDetails`
      }
    }

    // add documentType field if not present because it is required
    if (item.template.type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
      const DocumentTypeFiltered = item.template.requiredFields.filter((formItem) => {
        return formItem.fieldValue.toLowerCase().includes('documenttype')
      })
      if (!DocumentTypeFiltered.length) {
        item.template.requiredFields.push({
          "fieldLabel": "Document Type",
          "fieldValue": "documentType",
          "type": "data"
        })
      }
    }
    // manage kyc links as RFIs - here we don't want to render a form field but rather some buttons
    if(isKycLink(item)) {
      const url = getKycLink(item)
      !kycLink && url && setKycLink(url)
      return <KYCLink url={kycUrl} regenerateLink={regenerateLink} />
    }
    

    return item.template.requiredFields.map((formItem) => {
      const itemName = `${itemNameOrigin}.${formItem.fieldValue}`
      if (formItem.type === NIUM_RFI_TEMPLATE_TYPE.DATA) {
        if (formItem.fieldValue.toLowerCase().includes('documenttype')) {
          let options = {}
          if (item.template.rfiType === NIUM_RFI_TYPE.APPLICANT || item.template.rfiType === NIUM_RFI_TYPE.STAKEHOLDER) {
            options = { ...personDocumentMap[provider] }
            // for proof of address, it should be the only option selectable
            // in other cases, exclude it from selection
            const isPoa = item.template.documentType === 'POA'
            for(const key in options) {
              isPoa && key !== 'PROOF_OF_ADDRESS' && delete options[key]
              !isPoa && key === 'PROOF_OF_ADDRESS' && delete options[key]
            }
          } else if (item.template.rfiType === NIUM_RFI_TYPE.CORPORATE) {
            options = { ...corporateDocumentMap[provider] }
          }

          return <Controller
            key={itemName}
            render={({ field }) => <FormSelect
              {...field}
              label={formItem.fieldLabel}
              keyTitle={options}
              variant="outlined"
              className={classes.field}
              fullWidth
            />}
            control={control}
            name={itemName}
            error={false}
            defaultValue={Object.keys(options).length === 1 && Object.keys(options)[0]}
          />
        }
        if (formItem.fieldValue.toLowerCase().includes('date')) {
          return <Controller
            key={itemName}
            render={({ field: { onChange, value = null } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  className={classes.datePicker}
                  clearable
                  label={formItem.fieldLabel}
                  inputVariant="outlined"
                  format="yyyy-MM-dd"
                  onChange={newDate => onChange(moment(newDate).format('YYYY-MM-DD'))}
                  value={value}
                  emptyLabel="YYYY-MM-DD"
                  size='small'
                />
              </MuiPickersUtilsProvider>
            )}
            control={control}
            name={itemName}
            error={false}
          />
        }
        if (['country','countries'].some(v => formItem.fieldValue.toLowerCase().includes(v))) {
          return <Controller
            key={itemName}
            name={itemName}
            control={control}
            render={({ field }) => (
              <CountrySelectInput
                {...field}
                label={formItem.fieldLabel}
                fullWidth
                multiple={formItem.fieldValue.toLowerCase().includes('countries')}
                variant="outlined"
                valueKey="alpha2"
                className={classes.field}
                error={!!errors?.countryId}
                helperText={errors?.countryId?.message}
              />
            )}
          />
        }
        if (formItem.fieldValue === 'intendedUseOfAccount') {
          return <Controller
            key={itemName}
            render={({ field }) => <FormSelect
              {...field}
              label={formItem.fieldLabel}
              keyTitle={intendedUseOfAccount}
              variant="outlined"
              className={classes.field}
              fullWidth
            />}
            control={control}
            name={itemName}
            error={false}
          />
        }
        return <InputText
          key={itemName}
          errors={errors}
          name={itemName}
          control={control}
          label={formItem.fieldLabel}
        />
      }
      if (formItem.type === NIUM_RFI_TEMPLATE_TYPE.DOCUMENT) {
        return (
          <Controller
            key={itemName}
            render={() => (
              <OutlinedInput
                type="file"
                onChange={(e) => onDrop(e.target.files, itemName)}
                fullWidth
                className={classes.field}
                inputProps={{ accept: ".jpg,.png,.jpeg,.pdf", multiple: true }}
              />
            )}
            control={control}
            name={itemName}
            error={false}
          />
        )
      }
      return ''
    })
  }

  if (!data || !rfiDetails) return formatMessage({ id: 'tlpay.loading' })

  return (
    <>
      <Typography variant='h3' gutterBottom>
        {formatMessage({ id: 'tlpay.requests-for-information-rfi' })}
      </Typography>
      <div>
        {rfiDetails.rfiTemplates.map((template) => {
          const isRFITypeStakeholder = template.template.rfiType === NIUM_RFI_TYPE.STAKEHOLDER
          const stakeholder = isRFITypeStakeholder && data?.stakeholders &&
            data.stakeholders.find((s) => s.referenceId === template.referenceId)
          return <>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography className={classes.heading}>
                  {camelCaseToWords(template.template.name)}
                  {stakeholder && stakeholder?.name && ` (${stakeholder.name})`}
                  {!stakeholder && isRFITypeStakeholder && ` (${formatMessage({ id: 'tlpay.new' })})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.fullWidth}>
                {template.remarks && <>
                  <Grid container spacing={2}>
                    <Grid item>
                      <InfoIcon fontSize="small" />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom>
                        {displayRemarks(template)}
                      </Typography>
                    </Grid>
                  </Grid>
                  </>}
                  {renderFields(template)}
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        })}
      </div>
      <Button
        disabled={isLoading || isFetching || allResponded}
        variant="extended"
        type="submit"
        size="large"
        color="success"
        aria-label="add"
        onClick={onSubmit}
        className={classes.buttonSpacing}
      >
        {formatMessage({ id: 'tlpay.submit' })}
      </Button>
    </>
  )
}

export default RFI
