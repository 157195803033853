import React from 'react'
import { Box, Typography } from '@material-ui/core'

const BookingsListComments = () => (
  <Box p={1}>
    <Typography variant="body2" gutterBottom>
      * Showing first 10 bookings.<br />Use Export to download all bookings included in this remittance.
    </Typography>
  </Box>
)

export default BookingsListComments
