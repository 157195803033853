import React from 'react'
import PropTypes from 'prop-types'
import { Getter, Template, TemplateConnector, Plugin } from '@devexpress/dx-react-core'
import MTableRow from '@material-ui/core/TableRow'
import MTableCell from '@material-ui/core/TableCell'
import { computeSummaryRows, tableHeaderRowsWithSummary, isSummaryTableRow, isSummaryTableCell, matchColumnByComputedColumnsKey, currencyFormatter } from '../utils'

const pluginDependencies = [
  { name: 'Table' },
  { name: 'SelectionState' },
  { name: 'DataTypeProvider', optional: true }
]

const tableSummaryColumnsComputed = ({
  rows,
  tableColumns,
  selection,
  rowChanges
}) => computeSummaryRows(tableColumns, rows, rowChanges, selection)

const TableSummaryRow = ({ rowHeight }) => {
  const tableHeaderRowsComputed = ({ tableSummaryColumns, tableHeaderRows }) => tableHeaderRowsWithSummary(tableHeaderRows, tableSummaryColumns, rowHeight)

  return (
    <Plugin
      name="TableSummaryRow"
      dependencies={pluginDependencies}
    >
      <Getter name="tableSummaryColumns" computed={tableSummaryColumnsComputed} />
      <Getter name="tableHeaderRows" computed={tableHeaderRowsComputed} />
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => isSummaryTableCell(tableRow, tableColumn)}
      >
        {(params) => (
          <TemplateConnector>
            {
              ({ tableSummaryColumns }) => {
                const column = matchColumnByComputedColumnsKey(tableSummaryColumns, params.tableColumn)
                if (column) {
                  return (
                    <MTableCell
                      style={params.style}
                      colSpan={params.colSpan}
                      align={column.column?.align || 'center'}
                    >
                      {column?.summary && column?.column?.type === 'currency' 
                        ? currencyFormatter({ 
                          value: column?.summary, 
                          currency: column?.column?.summaryCurrency || column?.currency 
                        })
                        : column?.summary}
                    </MTableCell>)
                }
              }
            }
          </TemplateConnector>
        )}
      </Template>
      <Template
        name="tableRow"
        predicate={({ tableRow }) => !!isSummaryTableRow(tableRow)}
      >
        {(params) => <MTableRow height={32} {...params} />}
      </Template>
    </Plugin>
  )
}

TableSummaryRow.propTypes = {
  rowHeight: PropTypes.number
}

export default TableSummaryRow
