import Joi from 'joi'
import {
  SCHEDULE_FILE_FORMAT_KEY_TITLE,
  TASK_CRITERIA,
  TASK_FREQUENCY_TYPES,
  TASK_TRANSACTIONS_CRITERIA_TYPES,
  SCHEDULE_AVAILABLE_DATE_FORMATS,
  SCHEDULE_AVAILABLE_DECIMAL_SEPARATOR_CHAR,
  SCHEDULE_AVAILABLE_ENCAPSULATION_OPTIONS,
} from 'constants.js'

export const formSchema = Joi.object({
  name: Joi.string().min(1).required().messages({
    'string.empty': 'This field is required.',
  }),
  criteria: Joi.string()
    // TODO this should probably be expanded to obey the rules around criteriaType
    .allow(...Object.values(TASK_CRITERIA))
    .required(),
  frequencyType: Joi.string()
    .allow(...Object.values(TASK_FREQUENCY_TYPES))
    .when('criteria', {
      is: TASK_CRITERIA.LastCollection,
      then: Joi.string().allow(TASK_FREQUENCY_TYPES.NONE),
    }),
  fileFormat: Joi.string()
    .allow(...Object.keys(SCHEDULE_FILE_FORMAT_KEY_TITLE))
    .required()
    .messages({
      'string.empty': 'This field is required.',
    }),
  fileName: Joi.string().min(5).required().messages({
    'string.min': 'File name must contain at least 5 characters',
    'string.empty': 'This field is required.',
  }),
  sftp: Joi.boolean(),
  mailto: Joi.array().items(Joi.string().email({ tlds: { allow: false } })),
  template: Joi.string().min(1).required().messages({
    'string.empty': 'This field is required.',
  }),
  anonymiseData: Joi.boolean(),
  splitForEachSeller: Joi.boolean(),
  emailDelivery: Joi.boolean(),
  emailAdmins: Joi.boolean(),
  daysOfWeek: Joi.array()
    .items(Joi.string())
    .when('frequencyType', {
      is: 'WEEKLY',
      then: Joi.array().min(1).required(),
    }),
  dayOfMonth: [Joi.number(), Joi.string().allow('')],
  timeOfDay: Joi.string().required(),
  startAt: Joi.string().allow(''),
  endAt: Joi.string().allow(''),
  criteriaType: Joi.string().allow('', ...Object.values(TASK_TRANSACTIONS_CRITERIA_TYPES)),
  customPeriod: Joi.object().allow('').allow(null),
  dateFormat: Joi.string().allow(...Object.keys(SCHEDULE_AVAILABLE_DATE_FORMATS)),
  encapsulation: Joi.string().allow(...Object.keys(SCHEDULE_AVAILABLE_ENCAPSULATION_OPTIONS)),
  decimalSeparator: Joi.string().allow(...Object.keys(SCHEDULE_AVAILABLE_DECIMAL_SEPARATOR_CHAR)),
  includeUncommitted: Joi.boolean().optional(),
}).or('sftp', 'emailDelivery')
