import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'

import AuthView from 'containers/AuthView/connect'
import Button from 'components/Button'
import DataGrid from 'components/DataGrid'
import SnackbarComponent from 'components/Snackbar'
import { FILTER_TYPES } from 'components/DataGrid/utils'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import { dateToReadableDateTime, utcToTimezone } from 'utils/date'

import { CL_STATUS_TO_NAME, COMMERCIAL_LINK_STATUS, ROUTE_URL, CL_ROLE_TO_NAME, ACCOUNT_ROLE_TO_NAME } from '../../constants'

import ModalCreationSelector from './components/ModalCreationSelector'
import ModalUpdateStatus from './components/ModalUpdateStatus'
import ModalUpdateDueDateOverride from './components/ModalUpdateDueDateOverride'
import {
  useEditingCommercialLinks,
  getSellerIdType,
  getSellerId,
  getBuyerIdType,
  getBuyerId,
  filterLocalCommercialLinks
} from './utils'
import CSVUploader from './components/CSVUploader'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'

const initialFilterValues = {}

const useBulkUpdateStatus = (_commercialLinks, onConfirm) => {
  const [ modalStatusOpen, setModalStatusOpen ] = useState(false)
  const [ selectedIds, setSelectedIds ] = useState([])
  const [ clsForStatusUpdateStatus, setClsForStatusUpdateStatus ] = useState(null)
  const handleUpdateStatusClick = (ids) => {
    setClsForStatusUpdateStatus(_commercialLinks.find(cl => cl.id === ids[0]).status)
    setSelectedIds(ids)
    setModalStatusOpen(true)
  }
  const handleModalUpdateStatusClose = (status) => {
    if (status && onConfirm) {
      onConfirm(selectedIds, status)
    }
    setModalStatusOpen(false)
    setSelectedIds([])
  }

  return {
    modalStatusOpen,
    handleUpdateStatusClick,
    handleModalUpdateStatusClose,
    clsForStatusUpdateStatus,
  }
}

const useBulkUpdateDueDateOverride = (_commercialLinks, onConfirm) => {
  const [ modalDueDateOverrideOpen, setModalDueDateOverrideOpen ] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [ clsForDueDateOverrideUpdate, setClsForDueDateOverrideUpdate ] = useState(null)
  const handleUpdateDueDateOverrideClick = (ids) => {
    setClsForDueDateOverrideUpdate(_commercialLinks.filter(({id}) => ids.includes(id)))
    setSelectedIds(ids)
    setModalDueDateOverrideOpen(true)
  }
  const handleModalUpdateDueDateOverrideClose = (dueDateOverrideDays) => {
    if (dueDateOverrideDays && onConfirm) {
      onConfirm(selectedIds, dueDateOverrideDays)
    }
    setModalDueDateOverrideOpen(false)
    setSelectedIds([])
  }

  return {
    modalDueDateOverrideOpen,
    handleUpdateDueDateOverrideClick,
    handleModalUpdateDueDateOverrideClose,
    clsForDueDateOverrideUpdate
  }
}

const CommercialLinksList = ({
  location,
  push,
  currUserCompanyId,
  fetchCommercialLinks,
  commercialLinks: remoteCommercialLinks,
  currUserCompany,
  patchCommercialLink,
  snackbar
}) => {
  const theme = useTheme()
  const [ filters, setFilters ] = useState(initialFilterValues)
  const [ addModalOpen, setAddModalOpen ] = useState(false)
  const { commercialLinks, setRowsData } = useEditingCommercialLinks(remoteCommercialLinks?.links ?? [])
  const { formatMessage } = useIntl()

  const handleUpdateStatus = (rowIds, status) => {
    const changeRow = () => ({ status })
    const patchCommercialLinks = setRowsData(rowIds, changeRow)
    patchCommercialLink(patchCommercialLinks.map(_patchCommercialLink => {
      return {
        id: _patchCommercialLink.id,
        status
      }
    }))

    patchCommercialLinks
      .filter(({ id }) => rowIds.includes(id))
      .forEach(commLink => Object.assign(commLink, { status }))
  }

  const handleUpdateDueDateOverride = (rowIds, dueDateOverrideDays) => {    
    const changeRow = () => ({ dueDateOverrideDays })
    const patchCommercialLinks = setRowsData(rowIds, changeRow)
    patchCommercialLink(patchCommercialLinks.map(_patchCommercialLink => {
      return {
        id: _patchCommercialLink.id,
        dueDateOverrideDays: parseInt(dueDateOverrideDays)
      }
    }))

    patchCommercialLinks
      .filter(({ id }) => rowIds.includes(id))
      .forEach(commLink => Object.assign(commLink, { dueDateOverrideDays }))
  }

  const { handleUpdateStatusClick, handleModalUpdateStatusClose, modalStatusOpen } = useBulkUpdateStatus(commercialLinks, handleUpdateStatus)

  const { handleUpdateDueDateOverrideClick, handleModalUpdateDueDateOverrideClose, modalDueDateOverrideOpen, clsForDueDateOverrideUpdate } = useBulkUpdateDueDateOverride(commercialLinks, handleUpdateDueDateOverride)

  const isProcessingEntity = currUserCompany?.isProcessingEntity ?? false

  useEffect(() => {
    fetchCommercialLinks({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currUserCompanyId ])

  const handleEditClick = (rowIndexes) => {
    const index = rowIndexes[0]
    const rowData = commercialLinks.find(cl => cl.id === index)
    push({ pathname: ROUTE_URL.commercialLinkDetails, state: { editedCommercialLink: rowData } })
  }

  const handleCopyClick = (rowIndexes) => {
    const index = rowIndexes[0]
    const rowData = commercialLinks.find(cl => cl.id === index)
    Reflect.deleteProperty(rowData, 'id')
    rowData.copy = true
    rowData.name = ''
    rowData.status = COMMERCIAL_LINK_STATUS.PENDING
    push({ pathname: ROUTE_URL.commercialLinkDetails, state: { editedCommercialLink: rowData } })
  }

  const handleAddClick = () => {
    setAddModalOpen(true)
  }

  const handleAddClicked = (role) => {
    setAddModalOpen(false)
    push({ pathname: ROUTE_URL.commercialLinkDetails, state: { editedCommercialLink: null, role } })
  }

  const columns = [
    {
      title: formatMessage({ id: 'commercial-links.buyer-name' }),
      name: 'buyerName',
      getCellValue: row => row.buyerCompany.name,
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.buyer-id-type' }),
      name: 'buyerIdType',
      getCellValue: (row) => {
        return getBuyerIdType(row)
      },
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.buyer-id' }),
      name: 'buyerId',
      getCellValue: (row) => {
        return getBuyerId(row)
      },
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.seller-name' }),
      name: 'sellerName',
      getCellValue: row => row.sellerCompany.name,
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.seller-id-type' }),
      name: 'sellerIdType',
      getCellValue: (row) => {
        return getSellerIdType(row)
      },
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.seller-id' }),
      name: 'sellerId',
      getCellValue: (row) => {
        return getSellerId(row)
      },
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.business-terms' }),
      name: 'businessTermsName',
      getCellValue: row => row.businessTerms?.name,
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.role' }),
      name: 'role',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: isProcessingEntity
            ? Object.fromEntries(
              Object.entries(ACCOUNT_ROLE_TO_NAME)
                .map(([k, v]) => [k, formatMessage({ id: `account-role-to-name.${kebabCase(v)}` })])
            )
            : Object.fromEntries(
              Object.entries(CL_ROLE_TO_NAME)
                .map(([k, v]) => [k, formatMessage({ id: `cl-role-to-name.${kebabCase(v)}` })])
            )
        }
      }
    },
    {
      title: formatMessage({ id: 'commercial-links.due-date-override' }),
      name: 'dueDateOverrideDays',
      getCellValue: row => row.dueDateOverrideDays > 0 ? `${row.dueDateOverrideDays} days` : undefined,
      filter: {
        type: FILTER_TYPES.NUMBER
      },
    },
    { name: 'processingEntityCompanyName', title: formatMessage({ id: 'commercial-links.processing-entity' }), getCellValue: row => (row.processingEntityCompany ? row.processingEntityCompany.name : undefined) },
    { name: 'settlementSchedule', title: formatMessage({ id: 'commercial-links.settlement-schedule' }), getCellValue: row => (row.settlementSchedule ? row.settlementSchedule.name : undefined) },
    {
      name: 'status',
      title: formatMessage({ id: 'commercial-links.status' }),
      getCellValue: row => {
        const status = row.updatedValues?.status ?? row.status
        if (status?.toLowerCase().includes(COMMERCIAL_LINK_STATUS.SUSPENDED.toLowerCase())) {
          return CL_STATUS_TO_NAME[COMMERCIAL_LINK_STATUS.SUSPENDED]
        }
        return CL_STATUS_TO_NAME[status] ?? status
      },
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(CL_STATUS_TO_NAME)
              .map(([k, v]) => [k, formatMessage({ id: `cl-status-to-name.${kebabCase(v)}` })])
          ),
        }
      }
    },
    {
      name: 'description',
      title: formatMessage({ id: 'commercial-links.description' }),
      filter: true,
      getCellValue: row => row?.displayName,
    },
    {
      title: formatMessage({ id: 'commercial-links.updated-by' }),
      name: 'updatedBy',
      getCellValue: row => row?.updatedBy?.name,
      filter: true
    },
    {
      title: formatMessage({ id: 'commercial-links.updated-at' }),
      name: 'updatedAt',
      getCellValue: row => utcToTimezone(row?.updatedAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.updatedAt, currUserCompany.timezone)),
      filter: { type: FILTER_TYPES.DATE },
      type: 'datetime'
    }
  ]

  const filteredCommercialLinks = filterLocalCommercialLinks(commercialLinks, filters)

  return (
    <AuthView location={location} title="Commercial Links" extra={currUserCompany.isProcessingEntity ? <CSVUploader /> : null}>
      <DataGrid
        virtualTable
        data={filteredCommercialLinks}
        name={'commercial_links'}
        fullRemainingWindowHeight
        selectable={{
          actions: [
            {
              title: formatMessage({ id: 'commercial-links.copy' }),
              icon: 'file_copy',
              validate: (selection) => {
                if (selection?.length !== 1) {
                  throw new Error('You must select only one commercial link')
                }

                if (!commercialLinks?.find(cl => cl.id === selection?.[0]).own) {
                  throw new Error('You can\'t copy an inherited commercial link')
                }

                if (commercialLinks.some(cl => cl.id === selection?.[0] && !cl.canEdit)) {
                  throw new Error('You can\'t copy commercial link which does not belong to you')
                }

                return true
              },
              onClick: handleCopyClick
            },
            {
              title: formatMessage({ id: 'commercial-links.edit' }),
              icon: 'edit',
              validate: (selection) => {
                if (selection?.length !== 1) {
                  throw new Error('You must select only one commercial link')
                }

                if (!commercialLinks.find(cl => cl.id === selection?.[0])?.own) {
                  throw new Error('You can\'t edit an inherited commercial link')
                }

                if (commercialLinks.some(cl => cl.id === selection?.[0] && !cl.canEdit)) {
                  throw new Error('You can\'t edit commercial link which does not belong to you')
                }

                if (commercialLinks.some(cl => cl.id === selection?.[0] && cl.status === COMMERCIAL_LINK_STATUS.ARCHIVED)) {
                  throw new Error("You can't edit an archived commercial link")
                }

                return true
              },
              onClick: handleEditClick
            },
            {
              title: formatMessage({ id: 'commercial-links.update-status' }),
              icon: 'done_all',
              validate: (selection) => {
                if (selection.some(idx => !commercialLinks.find(cl => cl.id === idx).own)) {
                  throw new Error('You can\'t update an inherited commercial link')
                }

                if (commercialLinks.some(cl => cl.id === selection?.[0] && !cl.canEdit)) {
                  throw new Error('You can\'t update commercial link which does not belong to you')
                }

                const selectedCommercialLinks = commercialLinks.filter(cl => selection.includes(cl.id))
                if (selectedCommercialLinks.some(cl => cl.status === COMMERCIAL_LINK_STATUS.ARCHIVED)) {
                  throw new Error("You can't update the status of an archived commercial link")
                }

                return true
              },
              onClick: handleUpdateStatusClick
            },
            {
              title: formatMessage({ id: 'commercial-links.edit-due-date-override' }),
              validate: (selection) => {

                const selectedCommercialLinks = commercialLinks.filter(cl => selection.includes(cl.id))

                if (selectedCommercialLinks.some(cl => !cl.own && !cl.canEditDueDateOverride)) {
                  throw new Error('You can\'t update an inherited commercial link')
                }

                if (selectedCommercialLinks.some(cl => !(cl.role === 'SELLER' || cl.role === 'PROCESSING_ENTITY'))) {
                  throw new Error('You can\'t update commercial link which you don\'t are the seller or the processing entity')
                }

                if (selectedCommercialLinks.some(cl => cl.status === COMMERCIAL_LINK_STATUS.ARCHIVED)) {
                  throw new Error("You can't update the status of an archived commercial link")
                }

                return true
              },
              onClick: handleUpdateDueDateOverrideClick
            }
          ]
        }}
        filtering={{
          defaultFilters: initialFilterValues,
          filters,
          onChange: (newValue) => {
            setFilters(newValue)
          },
          clearFilters: () => {
            setFilters(initialFilterValues)
          }
        }}
        columns={columns}
        totalNumberOfItems={commercialLinks.length}
        extra={
          <>
            <Button onClick={handleAddClick} size="small" icon="add" color="primary" style={{ marginRight: theme.spacing(1) }}>
              {formatMessage({ id: 'table-filters.create' })}
            </Button>
            <RefreshButton onClick={() => fetchCommercialLinks({})} />
          </>
        }
      />
      {
        addModalOpen &&
        <ModalCreationSelector isProcessingEntity={isProcessingEntity} handleClose={handleAddClicked} />
      }
      <ModalUpdateStatus isOpen={modalStatusOpen} onCloseClick={handleModalUpdateStatusClose} />
      <ModalUpdateDueDateOverride isOpen={modalDueDateOverrideOpen} onCloseClick={handleModalUpdateDueDateOverrideClose} cls={clsForDueDateOverrideUpdate} />
      <SnackbarComponent
        status={snackbar.isOpened}
        error={snackbar.isError}
        text={snackbar.successOrErrorText}
      />
    </AuthView>
  )
}

CommercialLinksList.propTypes = {
  location: PropTypes.object,
  push: PropTypes.func,
  currUserCompanyId: PropTypes.string,
  fetchCommercialLinks: PropTypes.func,
  commercialLinks: PropTypes.object,
  currUserCompany: PropTypes.object,
  patchCommercialLink: PropTypes.func,
  snackbar: PropTypes.object
}

export default CommercialLinksList
