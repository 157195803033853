import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const getSettlementSchedules = async () => {
  const url = `${API_URL}/settlement-schedules`
  return httpFetch(url, {
    shouldThrowError: true
  })
}

export const getSettlementSchedule = async (id) => {
  const url = `${API_URL}/settlement-schedules/${id}`
  return httpFetch(url, {
    shouldThrowError: true
  })
}

export const getSettlementScheduleDates = async (id, { from, to }) => {
  const urlParams = { fromDate: from, toDate: to }
  const url = `${API_URL}/settlement-schedules/${id}/dates`
  return httpFetch(url, {
    urlParams,
    shouldThrowError: true
  })
}

export const postSettlementScheduleDates = async (body) => {
  const url = `${API_URL}/settlement-schedules/overrides`
  return httpFetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    shouldThrowError: true
  })
}

export const patchSettlementScheduleDates = async (body) => {
  const url = `${API_URL}/settlement-schedules/overrides`
  return httpFetch(url, {
    method: 'PATCH',
    body: JSON.stringify({ data: body }),
    shouldThrowError: true
  })
}

export const deleteSettlementScheduleDates = async (ids) => {
  const url = `${API_URL}/settlement-schedules/overrides/delete`
  return httpFetch(url, {
    method: 'POST',
    body: JSON.stringify({ data: ids }),
    shouldThrowError: true
  })
}

export const postSettlementSchedule = async (body) => {
  const url = `${API_URL}/settlement-schedules`
  return httpFetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    shouldThrowError: true
  })
}

export const patchSettlementSchedule = async (id, body) => {
  const url = `${API_URL}/settlement-schedules/${id}`
  return httpFetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    shouldThrowError: true
  })
}
