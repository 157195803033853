import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import { IconButton, InputAdornment, Select, MenuItem, FormControl, FormControlLabel } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { useQuery } from 'react-query'
import { getBranchCompanies } from 'services/companiesApi'
import { COMPANY_STATUS } from 'constants.js'

const ViewAsDropdown = ({ viewAsCompanyId, setViewAsCompanyId }) => {
  const { data } = useQuery('branchCompanies', () => getBranchCompanies({ status: COMPANY_STATUS.LIVE }))
  const classes = useStyles()

  const associatedCompaniesData = data ? data.branches : []

  return (
    <FormControl size="small" className={classes.form}>
      <FormControlLabel
        control={
          <Select
            className={classes.select}
            variant="outlined"
            value={viewAsCompanyId}
            onChange={(e) => setViewAsCompanyId(e.target.value)}
            MenuProps={{variant: "menu"}}
            {...viewAsCompanyId && {
              endAdornment:
                <InputAdornment position="end">
                  <IconButton disableRipple onClick={() => setViewAsCompanyId(null)}>
                    <Clear fontSize="small" />
                  </IconButton>
                </InputAdornment>
            }}
          >
            {associatedCompaniesData.map((ele) =>
              <MenuItem key={ele.id} value={ele.id}>
                {ele.name}
                {ele.codes && ` (${Object.entries(ele.codes)
                  .map(([key, value]) => `${key} ${value}`)
                  .join(' | ')})`}
              </MenuItem>
            )}
          </Select>
        }
        label="View As: "
        labelPlacement="start"
      />
    </FormControl>
  )
}

ViewAsDropdown.propTypes = {
  viewAsCompanyId: PropTypes.string.isRequired,
  setViewAsCompanyId: PropTypes.func.isRequired
}

export default ViewAsDropdown