import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Cell from '../Cell'

const EditCell = (props) => {
  const { column, row, children } = props
  if (column.editComponent) {
    return column.editComponent({ ...props })
  }
  if (column.getCellValue) {
    return <Cell>{column.getCellValue(row)}</Cell>
  }
  if (children) {
    return <Cell> {children} </Cell>
  }
  return <Cell> {row?.[column?.name]} </Cell>
}

EditCell.propTypes = {
  column: PropTypes.object,
  row: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default memo(EditCell)
