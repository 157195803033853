import { Typography, TextField } from '@material-ui/core'
import { useStyles } from '../styles'
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'

function DebouncedTextField({ controllerProps, customErrorMessageTypes, ...props }) {
  const getError = (error) => {
    const { type } = error

    if (customErrorMessageTypes?.includes(type)) {
      return error.message
    }

    if(type === 'number.base' || type === 'string.email' 
      || type === 'string.pattern.base' || type === 'string.pattern.invert.base') {
      return 'Invalid format'
    }
    if(type === 'number.max' || type === 'number.min') {
      return 'Value out of bounds'
    }
    
    return 'Is required'
  }
  const { field, fieldState } = controllerProps
  const [innerValue, setInnerValue] = useState("")
  const getValueWithMaxDecimals = (value) => {
    const { decimalDigits } = props
    if(!!decimalDigits && !!value && !isNaN(value)) {
      // eslint-disable-next-line no-unused-vars
      const [_, decimals] = value.split('.')
      const dd = Number(decimalDigits)
      if(decimals && decimals.length > dd) {
        return Number(value).toFixed(dd).toString()
      }
    }
    return value
  }
  const classes = useStyles()

  useEffect(() => {
    if (typeof field.value === "string")
      setInnerValue(getValueWithMaxDecimals(field.value))
  }, [field.value, field.name])

  const debouncedHandleChange = useDebouncedCallback((event) => {
    field.onChange(event)
    field.onBlur()
  }, 400)

  const handleChange = useCallback(
    (event) => {
      event.persist()
      setInnerValue(getValueWithMaxDecimals(event.target.value))
      debouncedHandleChange(event)
    },
    [debouncedHandleChange]
  )

  return (
    <>
      <TextField
        {...props}
        onChange={handleChange}
        value={innerValue}
        name={field.name}
        variant="outlined"
        fullWidth
        className={classes.field}
        error={!!fieldState.error}
        helperText={fieldState.error && getError(fieldState.error)}
        size='small'
        />
      {props.info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: props.info }} />}
    </>
  )
}

export default DebouncedTextField