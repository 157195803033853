import React, { useContext } from 'react'
import { isProduction, ROUTE_URL } from 'constants.js'
import BreadcrumbMenuBase from './BreadcrumbMenuBase'
import { useIntl } from 'react-intl'
import { BreadcrumbContext } from 'containers/App/components/BreadcrumbContext'

const BreadcrumbMenuTransactions = () => {
  const { formatMessage } = useIntl()
  const breadcrumbContext = useContext(BreadcrumbContext)

  return (
    <BreadcrumbMenuBase links={[
      {
        label: formatMessage({ id: 'breadcrumb-menu.bookings' }),
        to: ROUTE_URL.bookingsReview
      },
      breadcrumbContext.visibility.instantPayments && {
        label: formatMessage({ id: 'breadcrumb-menu.instant-payments' }),
        to: ROUTE_URL.instantPayments
      },
      {
        label: formatMessage({ id: 'breadcrumb-menu.remittances' }),
        to: ROUTE_URL.remittancesReview
      },
      !isProduction &&
      {
        label: formatMessage({ id: 'breadcrumb-menu.payments-statement' }),
        to: ROUTE_URL.paymentsStatement
      },
      {
        label: formatMessage({ id: 'breadcrumb-menu.file-uploads' }),
        to: ROUTE_URL.csv
      },
    ].filter(Boolean)} />
  )
}

export default BreadcrumbMenuTransactions
