import React from 'react'

import DataGrid from 'components/DataGrid'
import { currencyFormatter, DEFAULT_COLUMN_WIDTH_VALUES, FILTER_TYPES } from 'components/DataGrid/utils'
import { API_URL } from 'constants.js'
import PropTypes from 'prop-types'
import { get } from 'utils/api'
import { useQuery } from 'react-query'

const CommercialLinkNetsTable = ({ summaryDetails }) => {
  const { commercialLinkNets } = summaryDetails
  const { isLoading, data } = useQuery(`${summaryDetails.id}:commercial-link-nets`, async () =>
    get(`${API_URL}/payments-statement/transactions-summary/${summaryDetails.id}/commercial-link-nets`),
    Array.isArray(commercialLinkNets) ? {
      initialData: { data: commercialLinkNets },
      staleTime: Infinity,
    } : {},
  )

  const Amount = ({ amount, currency }) => {
    return currencyFormatter({ value: amount, currency })
  }

  const columns = [
    {
      title: 'Remittance Ref', // ! not being provided by the API
      name: 'paymentRef',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: 'Payer',
      name: 'payerName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: 'Payee',
      name: 'payeeName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: 'Settlement amount',
      name: 'absoluteAmountInCents',
      type: 'currency',
      getCellValue: (row) => 
        <Amount amount={row.isRefund ? -(row.absoluteAmountInCents) : row.absoluteAmountInCents} currency={row.currency} />,
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: 'Bank amount', // ! not being provided by the API
      name: 'bankAmount',
      type: 'currency',
      getCellValue: (row) => 
        <Amount amount={row.isRefund ? -(row.bankAmount) : row.bankAmount} currency={row.currency} />,
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: 'Notes',
      name: 'notes',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
  ].filter(Boolean)

  return (
    <DataGrid
      data={data?.data || []}
      name={'commercialLinkNets'}
      isLoading={isLoading}
      columns={columns}
      totalNumberOfItems={data?.data?.length || 0}
      pagination={{ disabled: true, pageSize: 999999 }}
      disableExport={true}
      disableColumnVisibility={true}
    />
  )
}

CommercialLinkNetsTable.propTypes = {
  summaryDetails: PropTypes.object,
}

export default CommercialLinkNetsTable
