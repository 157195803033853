import groupBy from 'lodash/groupBy'

export class UnauthorizedError extends Error {
  constructor(message) {
    super(message)
    this.name = 'UnauthorizedError'
  }
}

export class DownloadNoContentError extends Error {
  constructor(message) {
    super(message)
    this.name = 'DownloadNoContentError'
  }
}

export class MalformedFileError extends Error {
  constructor(message, data) {
    super(message)
    this.name = 'MalformedFileError'
    this.data = data || []
  }

  getErrorByFileName() {
    return groupBy(this.data, (item => item.file))
  }
}

export const getReadableErrorMessage = (errorResponse) => {
  const errors = errorResponse.data?.errors

  if (errors?.[0]?.title && errors?.[0]?.detail) {
    return `${errors?.[0]?.title}, ${errors?.[0]?.detail}`
  }

  return 'An error occured, please try again'
}

export class AuthError extends Error {
  constructor(message, at) {
    super(message)
    this.name = 'AuthError'
    this.tryAgainAt = at
  }
}
