import { createContext } from 'react';

export const BREADCRUMB_CONTEXT_DEFAULT_VALUES = {
  isLoading: false,
  visibility: {
    instantPayments: false,
  },
};
export const BreadcrumbContext = createContext(BREADCRUMB_CONTEXT_DEFAULT_VALUES);
export const BreadcrumbContextProvider = BreadcrumbContext.Provider;
