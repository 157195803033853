import moment from 'moment'
import { get as _get } from 'lodash'
import { DEFAULT_DATE_FORMAT } from 'constants.js'
import { _betweenDates, _contains, _equalsDate, convertQueryToNum } from 'utils/filters'

export const filterValuesToQueryParams = (filterValues, currUserCompanyId) => {
  let filtersToSet = {}

  filtersToSet['sourceOrTargetCompanyId[eq]'] = currUserCompanyId
  if (filterValues?.payer?.id) {
    filtersToSet['sourceId[eq]'] = filterValues.payer.id
  }
  if (filterValues?.payee?.id) {
    filtersToSet['targetId[eq]'] = filterValues.payee.id
  }
  if (filterValues.paymentRef) {
    filtersToSet['paymentRef[contains]'] = filterValues.paymentRef
  }
  if (filterValues.status && filterValues.status.length) {
    filtersToSet['status[in]'] = filterValues.status
  }
  if (filterValues.paymentDate) {
    const { startDate, endDate } = filterValues.paymentDate
    if (moment(startDate).isSame(moment(endDate))) {
      filtersToSet['paymentDate[eq]'] = moment(filterValues.paymentDate).format(DEFAULT_DATE_FORMAT)
    } else {
      filtersToSet['paymentDate[gt]'] = moment(filterValues.paymentDate.startDate).subtract(1, 'd').format(DEFAULT_DATE_FORMAT)
      filtersToSet['paymentDate[lt]'] = moment(filterValues.paymentDate.endDate).add(1, 'd').format(DEFAULT_DATE_FORMAT)
    }
  }
  if (filterValues.limit) {
    filtersToSet.limit = filterValues.limit
  }
  if (filterValues.sorting) {
    filtersToSet = { ...filtersToSet, ...filterValues.sorting }
  }
  if (filterValues.paginationQuery) {
    filtersToSet = { ...filtersToSet, ...filterValues.paginationQuery }
  }

  return filtersToSet
}

// Get seller company code type from 1st invoice if it is defined, otherwise get it from target company codes array(first one only)

// DISABLED LOGIC FOR NOW UNTIL WE IMPROVE PERFORMANCE ON HOW WE GET THE NEEDED DATA TO CALCULATE THIS

// const getRoleInTransaction = (id, commercialLink) => {
//   if (commercialLink.buyerId === id) {
//     return 'buyer'
//   }
//   if (commercialLink.sellerId === id) {
//     return 'seller'
//   }
//   if (commercialLink.processingEntityId === id) {
//     return 'pe'
//   }
// }

//
// export const getPayerIdType = (remittance) => {
//   const role = getRoleInTransaction(remittance.sourceCompany.id, remittance.invoices?.[0]?.commercialLink)
//   const fallbackCodeType = Object.keys(remittance.sourceCompany?.codes || {})[0] || ''
//   if (role === 'pe') {
//     return fallbackCodeType
//   }

//   const { importedTargetIdType } = remittance.invoices?.[0]
//   const codeTypeFromImportedIdType = remittance.sourceCompany?.codes[importedTargetIdType] && importedTargetIdType
//   if (role === 'buyer') {
//     return (
//       remittance.invoices?.[0]?.commercialLink?.buyerCodeType ||
//       codeTypeFromImportedIdType ||
//       fallbackCodeType
//     )
//   }
//   if (role === 'seller') {
//     return (
//       remittance.invoices?.[0]?.commercialLink?.sellerCodeType ||
//       codeTypeFromImportedIdType ||
//       fallbackCodeType
//     )
//   }

//   return ''
// }

// export const getPayeeIdType = (remittance) => {
//   const role = getRoleInTransaction(remittance.targetCompany.id, remittance.invoices?.[0]?.commercialLink)
//   const fallbackCodeType = Object.keys(remittance.targetCompany?.codes || {})[0] || ''
//   if (role === 'pe') {
//     return fallbackCodeType
//   }
//   const { importedTargetIdType } = remittance.invoices?.[0]
//   const codeTypeFromImportedIdType = remittance.targetCompany?.codes[importedTargetIdType] && importedTargetIdType
//   if (role === 'buyer') {
//     return (
//       remittance.invoices?.[0]?.commercialLink?.buyerCodeType ||
//       codeTypeFromImportedIdType ||
//       fallbackCodeType
//     )
//   }
//   if (role === 'seller') {
//     return (
//       remittance.invoices?.[0]?.commercialLink?.sellerCodeType ||
//       codeTypeFromImportedIdType ||
//       fallbackCodeType
//     )
//   }

//   return ''
// }

export const getPayerIdType = (remittance) => {
  if ('ABTA' in (remittance.sourceCompany?.codes || {})) {
    return 'ABTA'
  }
  return Object.keys(remittance.sourceCompany?.codes || {})[0] || ''
}

export const getPayeeIdType = (remittance) => {
  if ('ABTA' in (remittance.targetCompany?.codes || {})) {
    return 'ABTA'
  }
  return Object.keys(remittance.targetCompany?.codes || {})[0] || ''
}

export const getPayerId = (remittance) => {
  const idType = getPayerIdType(remittance)
  return idType ? remittance.sourceCompany?.codes?.[idType] : ''
}

export const getPayeeId = (remittance) => {
  const idType = getPayeeIdType(remittance)

  return idType ? remittance.targetCompany?.codes[idType] : ''
}

// This helper replaces server-side search on client-side beacuse we are doing client-side filtering & pagination
export const filterLocalRemittances = (invoices, filters) => {
  return invoices.filter(invoice => {
    if (filters.sourceId && !_contains(filters.sourceId, invoice.sourceCompany?.name)) {
      return false
    }
    if (filters.targetId && !_contains(filters.targetId, invoice.targetCompany?.name)) {
      return false
    }
    if (filters['sourceCompany.codes.type'] && !_contains(filters['sourceCompany.codes.type'], Object.keys(invoice.sourceCompany?.codes)[0])) {
      return false
    }
    if (filters['sourceCompany.codes'] && !_contains(filters['sourceCompany.codes'], Object.values(invoice.sourceCompany?.codes)[0])) {
      return false
    }
    if (filters['PayeeId'] && !_contains(filters['PayeeId'], getPayeeId(invoice))) {
      return false
    }
    if (filters.currency && filters.currency.length && !filters.currency.includes(invoice.currency)) {
      return false
    }
    if (filters.processingEntityCompanyName && filters.processingEntityCompanyName.length && !filters.processingEntityCompanyName.includes(invoice.processingEntityCompanyName)) {
      return false
    }
    if (filters['PayeeIdType'] && !_contains(filters['PayeeIdType'], getPayeeIdType(invoice))) {
      return false
    }
    if (filters.leadName && !_contains(filters.leadName, invoice.leadName)) {
      return false
    }
    if (filters.paymentRef && !_contains(filters.paymentRef, invoice.paymentRef)) {
      return false
    }
    if (filters.paymentDate && filters.paymentDate?.startDate) {
      const { startDate, endDate } = filters.paymentDate
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.paymentDate)) {
        return false
      } else if (!_betweenDates(invoice.paymentDate, startDate, endDate)) {
        return false
      }
    }
    if (_get(filters, 'amountPaidInCents.value')) {
      if (
        (filters.amountPaidInCents.type === 'eq' && !(convertQueryToNum(filters.amountPaidInCents.value) === parseFloat(invoice.amountPaidInCents, 10))) ||
        (filters.amountPaidInCents.type === 'gte' && !(convertQueryToNum(filters.amountPaidInCents.value) <= parseFloat(invoice.amountPaidInCents, 10))) ||
        (filters.amountPaidInCents.type === 'lte' && !(convertQueryToNum(filters.amountPaidInCents.value) >= parseFloat(invoice.amountPaidInCents, 10)))
      ) {
        return false
      }
    }
    if (filters.status && !filters.status.find(filtered => filtered === invoice.status)) {
      return false
    }
    if (filters.reasonCode && filters.reasonCode.length && !filters.reasonCode.find(filtered => filtered === invoice.status)) {
      return false
    }
    if (filters['commercialLinkDescription'] && !_contains(filters['commercialLinkDescription'], _get(invoice, 'invoices?.[0]?.commercialLink?.description'))) {
      return false
    }
    if (filters.collectionAt && filters.collectionAt?.startDate) {
      const { startDate, endDate } = filters.collectionAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.collectionAt)) {
        return false
      } else if (!_betweenDates(invoice.collectionAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.collectionPaymentAt && filters.collectionPaymentAt?.startDate) {
      const { startDate, endDate } = filters.collectionPaymentAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.collectionPaymentAt)) {
        return false
      } else if (!_betweenDates(invoice.collectionPaymentAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.disbursementAt && filters.disbursementAt?.startDate) {
      const { startDate, endDate } = filters.disbursementAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.disbursementAt)) {
        return false
      } else if (!_betweenDates(invoice.disbursementAt, startDate, endDate)) {
        return false
      }
    }
    if (filters?.disbursementPaymentAt?.startDate) {
      const { startDate, endDate } = filters.disbursementPaymentAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.disbursementPaymentAt)) {
        return false
      } else if (!_betweenDates(invoice.disbursementPaymentAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.settlementScheduleName && !_contains(filters.settlementScheduleName, invoice.settlementScheduleName)) {
      return false
    }
    if (filters.businessTermsName && !_contains(filters.businessTermsName, invoice.businessTermsName)) {
      return false
    }
    if (filters['paymentConfiguration.mandateId'] && !_contains(filters['paymentConfiguration.mandateId'], invoice.paymentConfiguration?.mandateId)) {
      return false
    }
    return true
  })
}

export const parseRemittances = (remittances) => {
  return remittances.map((remittance) => {
    return {
      ...remittance,
      processingEntityCompanyName: remittance.processingEntityCompanyName || 'Direct'
    }
  })
}
