import { BSD_CALENDAR_EVENTS } from 'constants.js';

export const CALENDAR_TIME_FORMAT = 'HH:mm'

// the following constants are tightly related, one will have:
//   - what type of skip is removed with an un-skip
// while another will have the inverse relationship:
//   - what type of un-skip removes a skip
//
// these relationships are linked to the union of day events
// (see unionDayEvents(..) for union of day events)

export const SKIP_TYPE_FOR = {
  // what would skip a scheduled event
  [BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START]: BSD_CALENDAR_EVENTS.SKIP_CYCLE_START,
  [BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION]: BSD_CALENDAR_EVENTS.SKIP_COLLECTION,
  
  // what type of skip is removed with an un-skip
  [BSD_CALENDAR_EVENTS.UN_SKIP_COLLECTION]: BSD_CALENDAR_EVENTS.SKIP_COLLECTION,
  [BSD_CALENDAR_EVENTS.UN_SKIP_CYCLE_START]: BSD_CALENDAR_EVENTS.SKIP_CYCLE_START,

  // what type of skip removes a forced event
  [BSD_CALENDAR_EVENTS.FORCE_CYCLE_START]: BSD_CALENDAR_EVENTS.SKIP_CYCLE_START,
  [BSD_CALENDAR_EVENTS.FORCE_COLLECTION]: BSD_CALENDAR_EVENTS.SKIP_COLLECTION,
}

export const SCHEDULED_TYPE_FOR = {
  // type of scheduled event a skip targets
  [BSD_CALENDAR_EVENTS.SKIP_COLLECTION]: BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION,
  [BSD_CALENDAR_EVENTS.SKIP_CYCLE_START]: BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START,
}

export const FORCED_TYPE_FOR = {
  // type of forced event a skip targets (for removal of forced event)
  [BSD_CALENDAR_EVENTS.SKIP_COLLECTION]: BSD_CALENDAR_EVENTS.FORCE_COLLECTION,
  [BSD_CALENDAR_EVENTS.SKIP_CYCLE_START]: BSD_CALENDAR_EVENTS.FORCE_CYCLE_START,

  // type of forced event a reschedule results in
  // also type of forced event being rescheduled
  [BSD_CALENDAR_EVENTS.RESCHEDULE_FORCE_COLLECTION]: BSD_CALENDAR_EVENTS.FORCE_COLLECTION,
  [BSD_CALENDAR_EVENTS.RESCHEDULE_FORCE_CYCLE_START]: BSD_CALENDAR_EVENTS.FORCE_CYCLE_START,
}

export const UN_SKIP_TYPE_FOR = {
  // what type of un-skip re-enables a scheduled event
  [BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START]: BSD_CALENDAR_EVENTS.UN_SKIP_CYCLE_START,
  [BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION]: BSD_CALENDAR_EVENTS.UN_SKIP_COLLECTION,

  // what type of un-skip removes a skip
  [BSD_CALENDAR_EVENTS.SKIP_CYCLE_START]: BSD_CALENDAR_EVENTS.UN_SKIP_CYCLE_START,
  [BSD_CALENDAR_EVENTS.SKIP_COLLECTION]: BSD_CALENDAR_EVENTS.UN_SKIP_COLLECTION,
}

// what types are used to remove an event; skipping a scheduled
// event is not a removal, it is a new skip event
export const REMOVE_EVENT_TYPE = {
  // removing skips
  [BSD_CALENDAR_EVENTS.SKIP_CYCLE_START]: UN_SKIP_TYPE_FOR[BSD_CALENDAR_EVENTS.SKIP_CYCLE_START],
  [BSD_CALENDAR_EVENTS.SKIP_COLLECTION]: UN_SKIP_TYPE_FOR[BSD_CALENDAR_EVENTS.SKIP_COLLECTION],

  // removing forced events
  [BSD_CALENDAR_EVENTS.FORCE_CYCLE_START]: SKIP_TYPE_FOR[BSD_CALENDAR_EVENTS.FORCE_CYCLE_START],
  [BSD_CALENDAR_EVENTS.FORCE_COLLECTION]: SKIP_TYPE_FOR[BSD_CALENDAR_EVENTS.FORCE_COLLECTION],
}

export const RESCHEDULE_FORCED_FOR = {
  // what type of reschedule event should be used for changing the
  // time of a forced event (used for existing force events)
  [BSD_CALENDAR_EVENTS.FORCE_CYCLE_START]: BSD_CALENDAR_EVENTS.RESCHEDULE_FORCE_CYCLE_START,
  [BSD_CALENDAR_EVENTS.FORCE_COLLECTION]: BSD_CALENDAR_EVENTS.RESCHEDULE_FORCE_COLLECTION,
}
