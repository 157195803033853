import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '45vh',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    padding: theme.spacing(1)
  },
  headerContent: {
    flexGrow: 1
  },
  headerButton: {
    color: theme.palette.error.contrastText
  },
  body: {
    padding: theme.spacing(1),
    overflowY: 'scroll'
  }
}))
