import * as React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'clsx'
import TableMUI from '@material-ui/core/Table'
import { withStyles } from '@material-ui/core/styles'
import { RefType } from '@devexpress/dx-react-core'
// import { getBorder } from './utils';

const styles = theme => ({
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    '& thead tr:first-of-type th': {
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      left: 'auto',
      top: 0,
      zIndex: 2,
      fallbacks: {
        position: '-webkit-sticky'
      }
    }
  },
  stickyTable: {
    position: 'sticky',
    zIndex: 500,
    overflow: 'visible',
    background: theme.palette.background.paper,
    fallbacks: {
      position: '-webkit-sticky'
    }
  },
  headTable: {
    top: 0
  },
  footTable: {
    bottom: 0
  }
})

const TableBase = ({
  children,
  classes,
  className,
  use,
  tableRef,
  ...restProps
}) => (
  <TableMUI
    ref={tableRef}
    stickyHeader
    className={classNames(
      {
        [classes.table]: true,
        [classes.stickyTable]: !!use,
        [classes.headTable]: use === 'head',
        [classes.footTable]: use === 'foot'
      },
      className
    )}
    {...restProps}
  >
    {children}
  </TableMUI>
)

TableBase.propTypes = {
  use: PropTypes.oneOf([ 'head', 'foot' ]),
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  tableRef: RefType.isRequired
}

TableBase.defaultProps = {
  use: undefined,
  className: undefined
}

export default withStyles(styles, { name: 'Table' })(TableBase)
