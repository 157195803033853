import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL } from 'constants.js'
import DataGrid from 'components/DataGrid'
import { Grid, Typography } from '@material-ui/core'
import AuthView from 'containers/AuthView/connect'
import Modal from 'components/Modal'
import InvoiceReceipt from './InvoiceReceipt'
import DownloadInvoice from './DownloadInvoice'
import connect from './connect'
import { ROUTE_URL } from 'constants.js'
import { Redirect } from 'react-router'

const BillingInvoices = ({ location, auth, currUserCompany }) => {
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [pdfInvoice, setPdfInvoice] = useState(null)
  const { isLoading, data } = useQuery(`api-base-url`, () => get(`${API_URL}/invoices/billing`), {
    retry: false,
  })

  if (!currUserCompany?.isHeadOffice) {
    return <Redirect to={ROUTE_URL.home} />
  }

  if (isLoading) {
    return (
      <AuthView location={location} title="Billing Invoices">
        <Typography>Loading...</Typography>
      </AuthView>
    )
  }

  const columns = [
    {
      title: 'Date',
      name: 'commitDate',
      type: 'date',
    },
    {
      title: 'Company',
      name: 'companyName',
      getCellValue: (row) => row.targetCompany?.name,
    },
    {
      title: 'Invoice Number',
      name: 'bookingRef',
    },
    {
      title: 'Total',
      name: 'total',
      type: 'currency',
    },
  ]
  const invoices = data ? data.data : []
  return (
    <AuthView location={location} auth={auth} title="Billing Invoices">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DataGrid
            virtualTable
            title={'Invoices'}
            columns={columns}
            data={invoices}
            sort={{
              keySorting: {
                orderBy: 'createdAt',
                order: 'DESC',
              },
            }}
            pagination={{
              onPageChanged: () => {},
              totalNumberOfItems: invoices.length,
            }}
            actions={[
              {
                icon: 'visibility',
                tooltip: 'View',
                onClick: (_, invoice) => {
                  setSelectedInvoice(invoice)
                },
              },
              {
                icon: 'download',
                tooltip: 'Download',
                onClick: (_, invoice) => {
                  setPdfInvoice(invoice)
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      {selectedInvoice && (
        <Modal
          maxWidth="md"
          defaultOpened={true}
          onClose={() => {
            setSelectedInvoice(null)
          }}
        >
          <InvoiceReceipt id="billing-invoice" invoice={selectedInvoice} />
        </Modal>
      )}
      {pdfInvoice && (
        <>
          <DownloadInvoice invoice={pdfInvoice} setPdfInvoice={setPdfInvoice} />
        </>
      )}
    </AuthView>
  )
}

BillingInvoices.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(BillingInvoices)
