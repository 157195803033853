import React, { useCallback, useState, useEffect, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Chip, Grid, Select, TextField, Tooltip } from '@material-ui/core'
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui'
import { VALID_REASONS_TO_AMEND, VALID_REASONS_TO_REJECT, INVOICE_EDIT_STATUS_OPTIONS, STATUSES, REASON_CODE_TO_DESCRIPTION } from 'constants.js'
import SelectOnTabMenuItem from 'components/SelectOnTabMenuItem'
import { currencyFormatter } from 'components/DataGrid/utils'
import Cell from 'components/DataGrid/components/Cell'
import RequiredCell from 'components/DataGrid/components/RequiredCell'
import { updateRelatedFields } from '../utils'

export const BookingTableStatistic = ({ currentAmountCentsSum = 0, originalAmountCentsSum = 0, itemsCount = 0, currency }) => (
  <Grid justify="space-evenly" alignItems="center" container>
    <Grid item>
      <Chip
        style={{ margin: 5 }}
        avatar={(<Avatar>$</Avatar>)}
        label={`Original Amount Total: ${currencyFormatter({ value: originalAmountCentsSum, currency })}`}
      />
    </Grid>
    <Grid item>
      <Chip
        style={{ margin: 5 }}
        avatar={(<Avatar>$</Avatar>)}
        label={`Current Amount Total: ${currencyFormatter({ value: currentAmountCentsSum, currency })}`}
      />
    </Grid>
    <Grid item>
      <Chip
        style={{ margin: 5 }}
        label={`Records: ${itemsCount}`}
      />
    </Grid>
  </Grid>
)

BookingTableStatistic.propTypes = {
  currentAmountCentsSum: PropTypes.number,
  originalAmountCentsSum: PropTypes.number,
  itemsCount: PropTypes.number,
  currency: PropTypes.string
}

export const CurrentAmountCell = ({ amendedAmount, currentAmountInCents, currency }) => {
  return currencyFormatter({ value: amendedAmount || currentAmountInCents, currency })
}

const isValidAmount = (row, amount) => {
  if (isNaN(amount)) {
    return false
  }
  const intAmount = parseInt(amount)
  const intOriginalAmount = parseInt(row.originalAmountInCents)
  const positiveNumber = row.originalAmountInCents > 0
  const restrictRefundAmendments = (row?.commercialLink?.businessTerms?.sellerRules?.restrictRefundAmendments ?? true)
  const currentValueIsLowerThanOriginal = intAmount < intOriginalAmount
  if ((positiveNumber && intAmount < 0) || (!positiveNumber && intAmount > 0)) {
    return false
  } else if (restrictRefundAmendments && !positiveNumber && currentValueIsLowerThanOriginal) {
    return false
  }
  return true
}

// eslint-disable-next-line react/display-name
export const CurrentAmountEditField = memo((props) => {
  const { row, value: updatedValue, editingEnabled, onValueChange, onFocus } = props
  const [ value, setValue ] = useState('')

  useEffect(() => {
    setValue((Number(updatedValue) / 100).toFixed(2))
  }, [ updatedValue ])

  const commitFieldValue = () => {
    const newValue = (Number(value) * 100).toFixed(0)

    // Commit only if value is actually changed
    if (updatedValue === parseInt(newValue, 10)) {
      return
    }
    if (isValidAmount(row, newValue)) {
      onValueChange(newValue, updateRelatedFields(row, { currentAmountInCents: newValue }))
    } else {
      setValue((Number(updatedValue) / 100).toFixed(2))
    }
  }

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' || key === 'Tab') {
      commitFieldValue()
    }
  }

  const handleValueChange = (e) => {
    setValue(e.target.value)
  }

  const parsedValue = updatedValue
  const hasError = (row.status === STATUSES.ACCEPTED && parsedValue !== row.originalAmountInCents) ||
    (row.status === STATUSES.AMENDED && parsedValue === row.originalAmountInCents)

  return (
    <TableEditRow.Cell>
      <Tooltip arrow open={hasError} title="For Amended current amount value should be different from original amount">
        <TextField
          inputProps={{ style: { textAlign: 'right' } }}
          disabled={!editingEnabled}
          error={hasError}
          onBlur={commitFieldValue}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          onChange={handleValueChange}
          value={value}
        />
      </Tooltip>
    </TableEditRow.Cell>
  )
})

CurrentAmountEditField.propTypes = {
  row: PropTypes.object.isRequired,
  editingEnabled: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ])
}

export const ReasonCodeCell = ({ reasonCode, updatedValues, status }) => {
  const currentReasonCode = updatedValues?.reasonCode ?? reasonCode
  const currentStatus = updatedValues?.status ?? status
  const shouldHaveReasonCode = (currentStatus === STATUSES.AMENDED || currentStatus === STATUSES.REJECTED) && !currentReasonCode
  if (shouldHaveReasonCode) {
    return <RequiredCell />
  }
  return currentReasonCode ? `${currentReasonCode} - ${REASON_CODE_TO_DESCRIPTION[currentReasonCode]}` : ''
}

ReasonCodeCell.propTypes = {
  reasonCode: PropTypes.string,
}

const getLookupValues = (status) => {
  if (status === STATUSES.AMENDED) {
    return VALID_REASONS_TO_AMEND
  } else if (status === STATUSES.REJECTED) {
    return VALID_REASONS_TO_REJECT
  }
  return {}
}

// eslint-disable-next-line react/display-name
export const ReasonCodeEditField = (props) => {
  const selectRef = useRef()
  const [ isOpen, setIsOpen ] = useState(false)
  const { onValueChange, row } = props
  const status = row?.status ?? row?.status

  const lookupValues = getLookupValues(status)

  const reasonCodeOptions = Object.entries(lookupValues).reduce((acc, [ key, _value ]) => {
    return [ ...acc, { key, value: `${key} - ${_value}` } ]
  }, [])
  const requiredForStatuses = [ STATUSES.AMENDED, STATUSES.REJECTED ]
  const value = row.reasonCode
  const hasError = requiredForStatuses.includes(row.status) && !value

  const handleChange = (event) => {
    onValueChange(event.target.value)
  }

  const handleFocus = () => {
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }

  const handleClose = useCallback(() => setIsOpen(false), [])
  const handleOpen = useCallback(() => setIsOpen(true), [])

  useEffect(() => {
    if (hasError) {
      setIsOpen(true)
    }
  }, [ hasError ])

  if (!Object.entries(lookupValues).length) {
    return <Cell tabIndex={1} />
  }

  return (
    <TableEditRow.Cell
      tabIndex={1}
      onFocus={handleFocus}
    >
      <Tooltip arrow open={hasError && !isOpen} title="Reason code is required">
        <Select
          error={hasError}
          open={isOpen}
          fullWidth
          ref={selectRef}
          value={value}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={{
            disablePortal: true,
            variant: 'menu'
          }}
        >
          {reasonCodeOptions
            // Sort first by letters, then by numbers (e.g: A, B, C, ..., Z, 0, 1, 2, ..., 9)
            .sort((a, b) => /[A-Za-z]/.test(b.key) - /[A-Za-z]/.test(a.key) 
                         || a.key.charCodeAt(0) - b.key.charCodeAt(0))
            .map(item => (
              <SelectOnTabMenuItem onChange={handleChange} key={item.key} value={item.key}>{item.value}</SelectOnTabMenuItem>
            ))
          }
        </Select>
      </Tooltip>
    </TableEditRow.Cell>
  )

}

ReasonCodeEditField.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
}

// eslint-disable-next-line react/display-name
export const BookingStatusEditField = memo(({ onValueChange, row, value: updatedValue = '' }) => {
  const selectRef = useRef()

  const handleChange = (event) => {
    const newValue = event.target.value
    onValueChange(newValue, updateRelatedFields(row, { status: newValue }))
  }

  const handleFocus = () => {
    selectRef.current.focus()
  }

  return (
    <Cell
      tabIndex={1}
      onFocus={handleFocus}
    >
      <Select
        fullWidth
        ref={selectRef}
        value={updatedValue}
        onChange={handleChange}
        MenuProps={{
          disablePortal: true,
          variant: 'menu'
        }}
      >
        {INVOICE_EDIT_STATUS_OPTIONS.map(item => (
          <SelectOnTabMenuItem key={item} value={item}>{item}</SelectOnTabMenuItem>
        ))}
      </Select>
    </Cell>
  )
})

BookingStatusEditField.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ])
}
