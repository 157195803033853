import { useEffect, useState, useRef } from 'react'
import moment from 'moment-timezone'
import { get as _get } from 'lodash'
import { DEFAULT_DATE_FORMAT, STATUSES, VALID_REASONS_TO_AMEND, QUICK_VIEWS, ACCOUNT_ROLE, FEATURE_PAGINATION_ENABLED } from 'constants.js'
import { _betweenDates, _contains, _equalsDate, convertQueryToNum } from 'utils/filters'
import { getBuyerIdFromInvoice, getBuyerIdTypeFromInvoice, getSellerIdFromInvoice, getSellerIdTypeFromInvoice } from 'utils/invoices'
import { utcToTimezone } from 'utils/date'

export const getQuickViewRole = (quickView) => {
  if ([QUICK_VIEWS.BUYER_CURRENT_WEEK, QUICK_VIEWS.BUYER_ALL_DUE, QUICK_VIEWS.BUYER_PAST].indexOf(quickView) >= 0) {
    return ACCOUNT_ROLE.BUYER
  } else if ([QUICK_VIEWS.SELLER_CURRENT_WEEK, QUICK_VIEWS.SELLER_ALL_DUE, QUICK_VIEWS.SELLER_PAST].indexOf(quickView) >= 0) {
    return ACCOUNT_ROLE.SELLER
  } else if ([QUICK_VIEWS.PE_CURRENT_WEEK, QUICK_VIEWS.PE_ALL_DUE, QUICK_VIEWS.PE_PAST].indexOf(quickView) >= 0) {
    return ACCOUNT_ROLE.PROCESSING_ENTITY
  }
  return null
}

export const calculateTotalCentsAmount = (_invoices, checked, field) => {
  if (checked.length) {
    return checked.reduce((sum, invoiceIndex) => {
      const currentValue = _invoices[invoiceIndex]?.updatedValues?.[field] ?? _invoices[invoiceIndex]?.[field]
      return sum + Number(currentValue) || 0
    }, 0)
  }
  return _invoices.reduce((sum, _invoice) => {
    const currentValue = _invoice?.updatedValues?.[field] ?? _invoice?.[field]
    return sum + Number(currentValue)
  }, 0)
}

export const updateRelatedFields = (row, updatedField) => {
  if (updatedField?.currentAmountInCents) {
    const currentAmountInCents = Number(updatedField.currentAmountInCents)
    const originalAmountInCents = Number(row.originalAmountInCents)
    if (originalAmountInCents === currentAmountInCents) {
      return { status: STATUSES.ACCEPTED, reasonCode: '' }
    } else if (currentAmountInCents === 0) {
      return { status: STATUSES.REJECTED, reasonCode: '' }
    } else {
      // We check reason code validity here because there are discrepancies between rejected and amended reason codes
      // so if we are changing from rejected to amended we should make sure that reason code is valid for new status, if not remove it
      const isValidReasonCode = Object.keys(VALID_REASONS_TO_AMEND).some((code) => code === row.reasonCode)
      return { status: STATUSES.AMENDED, reasonCode: isValidReasonCode ? row.reasonCode : '' }
    }
  }
  if ([STATUSES.ACCEPTED, STATUSES.MATCHED, STATUSES.AMENDED].includes(updatedField?.status)) {
    const originalAmountInCents = Number(row.originalAmountInCents)
    return { currentAmountInCents: originalAmountInCents, reasonCode: '' }
  } else if (updatedField.status === STATUSES.REJECTED) {
    return { currentAmountInCents: 0, reasonCode: '' }
  }
  return {}
}

export const updateDependentFields = (updatedItem, updatedValues) => {
  if (updatedValues?.currentAmountInCents) {
    const currentAmountInCents = Number(updatedValues.currentAmountInCents)
    const originalAmountInCents = Number(updatedItem.originalAmountInCents)
    if (originalAmountInCents === currentAmountInCents) {
      return { ...updatedValues, status: STATUSES.ACCEPTED, reasonCode: '' }
    } else if (currentAmountInCents === 0) {
      return { ...updatedValues, status: STATUSES.REJECTED, reasonCode: '' }
    } else {
      return { ...updatedValues, status: STATUSES.AMENDED, reasonCode: '' }
    }
  }
  if ([STATUSES.ACCEPTED, STATUSES.MATCHED, STATUSES.AMENDED].includes(updatedValues?.status)) {
    const originalAmountInCents = Number(updatedItem.originalAmountInCents)
    return { ...updatedValues, currentAmountInCents: originalAmountInCents, reasonCode: '' }
  } else if (updatedValues.status === STATUSES.REJECTED) {
    return { ...updatedValues, currentAmountInCents: 0, reasonCode: '' }
  }
  return { ...updatedItem.updatedValues, ...updatedValues }
}

const getEditingRow = (invoices, changes) => {
  const lastModifiedRow = changes.find((change) => {
    const { updatedItem } = change
    return invoices.find(
      (invoice) =>
        updatedItem.id === invoice.id &&
        JSON.stringify(updatedItem.updatedValues) !== JSON.stringify(invoice.updatedValues),
    )
  })
  if (lastModifiedRow) {
    const { updatedItem, latestUpdatedValue } = lastModifiedRow
    return { updatedItem, latestUpdatedValue }
  }
}

export const useEditingInvoices = (_invoices) => {
  const [invoices, setInvoices] = useState(_invoices ?? [])
  const currentEditingRowData = useRef({})

  useEffect(() => {
    setInvoices(_invoices)
  }, [_invoices])

  const setRowData = (changes) => {
    const editingRow = getEditingRow(invoices, changes)
    if (editingRow) {
      const { updatedItem, latestUpdatedValue } = editingRow
      const newUpdatedValues = updateDependentFields(updatedItem, latestUpdatedValue)
      if (updatedItem.id === currentEditingRowData?.current?.id) {
        currentEditingRowData.current = { ...currentEditingRowData.current, ...newUpdatedValues }
      } else {
        currentEditingRowData.current = { id: updatedItem.id, ...newUpdatedValues }
      }
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) => {
          if (invoice.id === updatedItem.id) {
            return { ...invoice, updatedValues: newUpdatedValues }
          }
          return invoice
        }),
      )
    }
    return currentEditingRowData.current
  }
  const setRowsData = (indexes, changeRow) => {
    const updatedRows = []
    setInvoices((prevInvoices) =>
      prevInvoices.map((invoice, index) => {
        const shouldUpdate = indexes.includes(index)
        if (shouldUpdate && changeRow) {
          const changes = changeRow(invoice)
          const updatedRow = { ...invoice, updatedValues: { ...invoice?.updatedValues, ...changes } }
          updatedRows.push(updatedRow)
          return updatedRow
        }
        return invoice
      }),
    )
    currentEditingRowData.current = {}
    return updatedRows
  }
  return {
    invoices,
    setRowData,
    setRowsData,
  }
}

export const filterValuesToQueryParams = (filterValues, currUserCompany) => {
  const currUserCompanyId = currUserCompany?.id
  const timezone = currUserCompany?.timezone || 'Europe/London'

  const filtersToSet = {}
  if (getQuickViewRole(filterValues.quickView) === ACCOUNT_ROLE.BUYER) {
    filtersToSet['targetId[eq]'] = currUserCompanyId
    if (filterValues?.partner?.id) {
      filtersToSet['sourceId[eq]'] = filterValues.partner.id
    }
    if (filterValues.partnerAbtaId) {
      filtersToSet['sellerAbta[in]'] = filterValues.partnerAbtaId
    }
  } else if (getQuickViewRole(filterValues.quickView) === ACCOUNT_ROLE.SELLER) {
    filtersToSet['sourceId[eq]'] = currUserCompanyId
    if (filterValues?.partner?.id) {
      filtersToSet['targetId[eq]'] = filterValues.partner.id
    }
    if (filterValues.partnerAbtaId) {
      filtersToSet['buyerAbta[in]'] = filterValues.partnerAbtaId
    }
  }

  if (filterValues.bookingRef) {
    filtersToSet['bookingRef[contains]'] = filterValues.bookingRef
  }
  if (filterValues.leadName) {
    filtersToSet['leadName[contains]'] = filterValues.leadName
  }
  if (filterValues.earliestDueDate) {
    const { startDate, endDate } = filterValues.earliestDueDate
    if (startDate && endDate && moment(startDate).isSame(endDate, 'day')) {
      filtersToSet['earliestDueDate[eq]'] = moment(startDate).format('YYYY-MM-DD')
    } else {
      if (startDate) {
        filtersToSet['earliestDueDate[gte]'] = moment(startDate).format('YYYY-MM-DD')
      }
      if (endDate) {
        filtersToSet['earliestDueDate[lte]'] = moment(endDate).format('YYYY-MM-DD')
      }
    }
  }
  if (filterValues.cycleStartAt) {
    const { startDate, endDate } = filterValues.cycleStartAt
    if (startDate && endDate && moment(startDate).isSame(endDate, 'day') && !moment().isSame(endDate, 'day')) {
      filtersToSet['cycleStartAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).startOf('day').utc().format()
      filtersToSet['cycleStartAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).endOf('day').utc().format()
    } else {
      if (startDate) {
        filtersToSet['cycleStartAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
      }
      if (endDate) {
        filtersToSet['cycleStartAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
      }
    }
  }
  if (filterValues.collectionPaymentAt) {
    const { startDate, endDate } = filterValues.collectionPaymentAt
    if (startDate && endDate && moment(startDate).isSame(endDate, 'day') && !moment().isSame(endDate, 'day')) {
      filtersToSet['collectionPaymentAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).startOf('day').utc().format()
      filtersToSet['collectionPaymentAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).endOf('day').utc().format()
    } else {
      if (startDate) {
        filtersToSet['collectionPaymentAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
      }
      if (endDate) {
        filtersToSet['collectionPaymentAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
      }
    }
  }
  if (filterValues.departureDate && filterValues.departureDate?.startDate) {
    const { startDate, endDate } = filterValues.departureDate
    if (moment(startDate).isSame(moment(endDate))) {
      filtersToSet['departureDate[eq]'] = moment(filterValues.departureDate).format(DEFAULT_DATE_FORMAT)
    } else {
      filtersToSet['departureDate[gt]'] = moment(filterValues.departureDate.startDate)
        .subtract(1, 'd')
        .format(DEFAULT_DATE_FORMAT)
      filtersToSet['departureDate[lt]'] = moment(filterValues.departureDate.endDate)
        .add(1, 'd')
        .format(DEFAULT_DATE_FORMAT)
    }
  }
  if (filterValues.status && filterValues.status.length) {
    filtersToSet['status[in]'] = filterValues.status
  }
  if (filterValues.settlementStatus && filterValues.settlementStatus.length) {
    filtersToSet['settlementStatus[in]'] = filterValues.settlementStatus
  }
  if (filterValues.reasonCode && filterValues.reasonCode.length) {
    filtersToSet['reasonCode[in]'] = filterValues.reasonCode
  }
  // if (filterValues.limit) {
  //   filtersToSet.limit = filterValues.limit
  // }
  // if (filterValues.sorting) {
  //   filtersToSet = { ...filtersToSet, ...filterValues.sorting }
  // }
  // if (filterValues.paginationQuery) {
  //   filtersToSet = { ...filtersToSet, ...filterValues.paginationQuery }
  // }

  if (filterValues.transactionType && filterValues.transactionType !== 'ALL') {
    filtersToSet['transactionType[eq]'] = filterValues.transactionType
  }

  if (FEATURE_PAGINATION_ENABLED) {

    filtersToSet['page'] = filterValues.currentPage
    filtersToSet['itemsPerPage'] = filterValues.itemsPerPage
    
    Object.entries(filterValues).forEach(
      ([key, value]) =>
        [
          'sellerName',
          'importedSourceIdType',
          'importedSourceId',
          'buyerName',
          'importedTargetIdType',
          'importedTargetId',
        ].includes(key) && (filtersToSet[key] = value)
    )

    if (filterValues.currency && filterValues.currency.length) {
      filtersToSet['currency[in]'] = filterValues.currency
    }

    if (filterValues.hideActioned) {
      filtersToSet['status[in]'] = Object.keys(STATUSES).filter(status => [STATUSES.ACCEPTED, STATUSES.REJECTED, STATUSES.AMENDED, STATUSES.VOID].indexOf(status) === -1)
    }

    if (_get(filterValues, 'originalAmountInCents.value')) {
      filtersToSet[`originalAmountInCents[${filterValues.originalAmountInCents.type}]`] = filterValues.originalAmountInCents.value
    }

    if (_get(filterValues, 'currentAmountInCents.value')) {
      filtersToSet[`currentAmountInCents[${filterValues.currentAmountInCents.type}]`] = filterValues.currentAmountInCents.value
    }

    if (filterValues.disbursementAt) {
      const { startDate, endDate } = filterValues.disbursementAt
      if (startDate && endDate && moment(startDate).isSame(endDate, 'day') && !moment().isSame(endDate, 'day')) {
        filtersToSet['disbursementAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).startOf('day').utc().format()
        filtersToSet['disbursementAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).endOf('day').utc().format()
      } else {
        if (startDate) {
          filtersToSet['disbursementAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
        }
        if (endDate) {
          filtersToSet['disbursementAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
        }
      }
    }

    if (filterValues.disbursementPaymentAt) {
      const { startDate, endDate } = filterValues.disbursementPaymentAt
      if (startDate && endDate && moment(startDate).isSame(endDate, 'day') && !moment().isSame(endDate, 'day')) {
        filtersToSet['disbursementPaymentAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).startOf('day').utc().format()
        filtersToSet['disbursementPaymentAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).endOf('day').utc().format()
      } else {
        if (startDate) {
          filtersToSet['disbursementPaymentAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
        }
        if (endDate) {
          filtersToSet['disbursementPaymentAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
        }
      }
    }

    if (filterValues.processingEntityCompanyName && filterValues.processingEntityCompanyName.length) {
      filtersToSet['processingEntityCompanyName[in]'] = filterValues.processingEntityCompanyName
    }

    if (filterValues.updatedAt) {
      const { startDate, endDate } = filterValues.updatedAt
      if (startDate && endDate && moment(startDate).isSame(endDate, 'day') && !moment().isSame(endDate, 'day')) {
        filtersToSet['updatedAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).startOf('day').utc().format()
        filtersToSet['updatedAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).endOf('day').utc().format()
      } else {
        if (startDate) {
          filtersToSet['updatedAt[gte]'] = moment.tz(moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
        }
        if (endDate) {
          filtersToSet['updatedAt[lte]'] = moment.tz(moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc().format()
        }
      }
    }

    if (filterValues.commercialLinkDisplayName) {
      filtersToSet['commercialLinkDisplayName[contains]'] = filterValues.commercialLinkDisplayName
    }

    if (filterValues.updatedBy) {
      filtersToSet['updatedBy[contains]'] = filterValues.updatedBy
    }

    if (filterValues.tags && filterValues.tags.length) {
      filtersToSet['tags[in]'] = filterValues.tags
    }
  }

  return filtersToSet
}

// TODO: TO BE REMOVED WITH PAGINATION ACCEPTENCE
// This helper replaces server-side search on client-side beacuse we are doing client-side filtering & pagination
export const filterLocalInvoices = (invoices, filters, quickView) => {
  return invoices.filter((invoice) => {
    if (filters.settlementStatus && filters.settlementStatus.length && !filters.settlementStatus.includes(invoice.settlementStatus)) {
      return false
    }
    if (filters.hideActioned && [STATUSES.ACCEPTED, STATUSES.REJECTED, STATUSES.AMENDED, STATUSES.VOID].indexOf(invoice.status) > -1 ) {
      return false
    }
    if (filters.sellerName && !_contains(filters.sellerName, invoice.sourceCompany?.name)) {
      return false
    }
    if (filters.importedSourceIdType && !_contains(filters.importedSourceIdType, invoice.importedSourceIdType)) {
      return false
    }
    if (filters.importedSourceId && !_contains(filters.importedSourceId, invoice.importedSourceId)) {
      return false
    }
    if (filters.buyerName && !_contains(filters.buyerName, invoice.targetCompany?.name)) {
      return false
    }
    if (filters.currency && filters.currency.length && !filters.currency.includes(invoice.currency)) {
      return false
    }
    if (filters.processingEntityCompanyName && filters.processingEntityCompanyName.length && !filters.processingEntityCompanyName.includes(invoice.processingEntityCompanyName)) {
      return false
    }
    if (filters.importedTargetIdType && !_contains(filters.importedTargetIdType, invoice.importedTargetIdType)) {
      return false
    }
    if (filters.importedTargetId && !_contains(filters.importedTargetId, invoice.importedTargetId)) {
      return false
    }

    if (filters.partner) {
      if (
        (getQuickViewRole(quickView) === ACCOUNT_ROLE.BUYER && !_contains(filters.partner, _get(invoice, 'sourceCompany.name'))) ||
        (getQuickViewRole(quickView) === ACCOUNT_ROLE.SELLER && !_contains(filters.partner, _get(invoice, 'targetCompany.name')))
      ) {
        return false
      }
    }
    if (filters.partnerAbtaId) {
      if (
        (getQuickViewRole(quickView) === ACCOUNT_ROLE.BUYER && !_contains(filters.partnerAbtaId, _get(invoice, 'sourceCompany.codes.ABTA'))) ||
        (getQuickViewRole(quickView) === ACCOUNT_ROLE.SELLER && !_contains(filters.partnerAbtaId, _get(invoice, 'targetCompany.codes.ABTA')))
      ) {
        return false
      }
    }
    if (filters.leadName && !_contains(filters.leadName, invoice.leadName)) {
      return false
    }
    if (filters.bookingRef && !_contains(filters.bookingRef, invoice.bookingRef)) {
      return false
    }
    if (filters.departureDate && filters.departureDate?.startDate) {
      const { startDate, endDate } = filters.departureDate
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.departureDate)) {
        return false
      } else if (!_betweenDates(invoice.departureDate, startDate, endDate)) {
        return false
      }
    }
    if (_get(filters, 'originalAmountInCents.value')) {
      if (
        (filters.originalAmountInCents.type === 'eq' &&
          !(
            convertQueryToNum(filters.originalAmountInCents.value) === parseFloat(invoice.originalAmountInCents, 10)
          )) ||
        (filters.originalAmountInCents.type === 'gte' &&
          !(convertQueryToNum(filters.originalAmountInCents.value) <= parseFloat(invoice.originalAmountInCents, 10))) ||
        (filters.originalAmountInCents.type === 'lte' &&
          !(convertQueryToNum(filters.originalAmountInCents.value) >= parseFloat(invoice.originalAmountInCents, 10)))
      ) {
        return false
      }
    }
    if (_get(filters, 'currentAmountInCents.value')) {
      if (
        (filters.currentAmountInCents.type === 'eq' &&
          !(convertQueryToNum(filters.currentAmountInCents.value) === parseFloat(invoice.currentAmountInCents, 10))) ||
        (filters.currentAmountInCents.type === 'gte' &&
          !(convertQueryToNum(filters.currentAmountInCents.value) <= parseFloat(invoice.currentAmountInCents, 10))) ||
        (filters.currentAmountInCents.type === 'lte' &&
          !(convertQueryToNum(filters.currentAmountInCents.value) >= parseFloat(invoice.currentAmountInCents, 10)))
      ) {
        return false
      }
    }
    if (filters.status && !filters.status.find((filtered) => filtered === invoice.status)) {
      return false
    }
    if (filters.tags?.length && !filters.tags.find((filtered) => invoice.tags.includes(filtered))) {
      return false
    }

    if (filters.collectionPaymentAt && filters.collectionPaymentAt?.startDate) {
      const { startDate, endDate } = filters.collectionPaymentAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.collectionPaymentAt)) {
        return false
      } else if (!_betweenDates(invoice.collectionPaymentAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.disbursementAt && filters.disbursementAt?.startDate) {
      const { startDate, endDate } = filters.disbursementAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.disbursementAt)) {
        return false
      } else if (!_betweenDates(invoice.disbursementAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.disbursementPaymentAt && filters.disbursementPaymentAt?.startDate) {
      const { startDate, endDate } = filters.disbursementPaymentAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.disbursementPaymentAt)) {
        return false
      } else if (!_betweenDates(invoice.disbursementPaymentAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.updatedAt && filters.updatedAt?.startDate) {
      const { startDate, endDate } = filters.updatedAt
      // If startDate equals endDate we are doing equality date check
      if (_equalsDate(startDate, endDate) && !_equalsDate(startDate, invoice.updatedAt)) {
        return false
      } else if (!_betweenDates(invoice.updatedAt, startDate, endDate)) {
        return false
      }
    }
    if (filters.updatedBy && !_contains(filters.updatedBy, invoice?.updatedBy)) {
      return false
    }
    if (
      filters.reasonCode &&
      filters.reasonCode.length &&
      !filters.reasonCode.find((filtered) => filtered === invoice.reasonCode)
    ) {
      return false
    }
    if (filters.commercialLinkDisplayName && !_contains(
      filters.commercialLinkDisplayName, 
      invoice.commercialLinkDisplayName
    )) {
      return false
    }
    return true
  })
}

const EMPTY_LEAD_NAME_LABEL = 'NOTSUPPLIED'

/** @param {typeof QUICK_VIEWS[keyof typeof QUICK_VIEWS]} quickView */
export const isCurrentWeekQuickView = quickView => [
  QUICK_VIEWS.BUYER_CURRENT_WEEK,
  QUICK_VIEWS.SELLER_CURRENT_WEEK,
  QUICK_VIEWS.PE_CURRENT_WEEK,
].includes(quickView)

/** @param {typeof QUICK_VIEWS[keyof typeof QUICK_VIEWS]} quickView */
export const isAllDueQuickView = quickView => [
  QUICK_VIEWS.BUYER_ALL_DUE,
  QUICK_VIEWS.SELLER_ALL_DUE,
  QUICK_VIEWS.PE_ALL_DUE,
].includes(quickView)

/** @param {typeof QUICK_VIEWS[keyof typeof QUICK_VIEWS]} quickView */
export const isPastQuickView = quickView => [
  QUICK_VIEWS.BUYER_PAST,
  QUICK_VIEWS.SELLER_PAST,
  QUICK_VIEWS.PE_PAST,
].includes(quickView)

// TODO: This is temp fix, we could remove this after moving to server filtering
export const parseInvoices = (invoices, timezone) => {
  return invoices.map((invoice) => {
    // https://zube.io/applied-blockchain/travel-ledger/tickets/658
    // Resolve updated with created if the row wasn't updated yet
    const updatedBy = invoice.updatedAt && !invoice.updatedBy
      ? 'System'
      : invoice?.updatedBy?.name || invoice?.createdBy?.name || `${invoice?.uploadType} (${invoice?.sender})`
    return {
      ...invoice,
      cycleStartAt: utcToTimezone(invoice.cycleStartAt, timezone),
      collectionClearedAt: utcToTimezone(invoice.collectionClearedAt, timezone),
      collectionPaymentAt: utcToTimezone(invoice.collectionPaymentAt, timezone),
      commitDate: utcToTimezone(invoice.commitDate, timezone),
      createdAt: utcToTimezone(invoice.createdAt, timezone),
      disbursementAt: utcToTimezone(invoice.disbursementAt, timezone),
      disbursementPaymentAt: utcToTimezone(invoice.disbursementPaymentAt, timezone),
      importedAt: utcToTimezone(invoice.importedAt, timezone),
      updatedAt: utcToTimezone(invoice?.updatedBy ? invoice.updatedAt : invoice.createdAt, timezone),
      updatedBy,
      sellerId: getSellerIdFromInvoice(invoice),
      sellerName: invoice.sourceCompany?.name,
      sellerIdType: getSellerIdTypeFromInvoice(invoice),
      buyerId: getBuyerIdFromInvoice(invoice),
      buyerIdType: getBuyerIdTypeFromInvoice(invoice),
      buyerName: invoice.targetCompany?.name,
      commercialLinkDisplayName: invoice.commercialLink?.displayName,
      currency: invoice?.currency,
      processingEntityCompanyName: (invoice.commercialLink?.processingEntity?.name || invoice.commercialLink?.processingEntityCompanyName) || 'Direct',
      leadName: invoice.leadName || EMPTY_LEAD_NAME_LABEL
    }
  })
}

export const updateFiltersOnQuickViewChange = (filters, setFilters, quickView, latestCollectionPayments) => {
  if (isCurrentWeekQuickView(quickView)) {
    setFilters({
      ...filters,
      collectionPaymentAt: null,
      cycleStartAt: {
        startDate: moment(),
        endDate: moment().endOf('isoweek')
      }
    })
    return
  }
  if (isAllDueQuickView(quickView)) {
    setFilters({
      ...filters,
      cycleStartAt: null,
      collectionPaymentAt: {
        startDate: moment(),
        endDate: moment().endOf('day').endOf('year').add(1, 'years')
      }
    })
    return
  }
  if (isPastQuickView(quickView)) {
    let startDate = moment().startOf('day').subtract(7, 'days').startOf('week')
    let endDate = moment()
    if (latestCollectionPayments?.length) {
      startDate = latestCollectionPayments[0].startDate.clone().startOf('day')
      endDate = latestCollectionPayments[0].startDate.clone().endOf('day')
      // Changed as an hotfix to avoid filter out same day bookings based on time (to be revised later)
      // endDate = latestCollectionPayments[0].startDate.isSame(moment(), 'day')
      //   ? moment()
      //   : latestCollectionPayments[0].startDate.endOf('day')
    }

    setFilters({
      ...filters,
      cycleStartAt: null,
      collectionPaymentAt: {
        startDate,
        endDate
      }
    })
    return
  }
}
