import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ValidationMessage = ({
  total
}) => {
  if (!total) {
    return null
  }
  return (
    <Typography variant="subtitle2" align="center" color="error">
      {
        total >= 1000 ?
          'Your uploaded file has too many errors, please fix it and submit again' :
          `Your uploaded file has ${total} errors, you can check the errors on table above. If you want to proceed, only invites not mentioned on table above will be sent.`
      }
    </Typography>
  )
}

ValidationMessage.propTypes = {
  total: PropTypes.number
}

export default ValidationMessage
