import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'

const ReactHookFormMuiTextField = ({ control, name, label, error, disabled = false, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <TextField
        label={label}
        fullWidth
        error={!!error}
        helperText={error?.message}
        {...props}
        {...field}
        disabled={disabled}
      />
    )}
  />
)

ReactHookFormMuiTextField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.node,
  error: PropTypes.object
}

export default ReactHookFormMuiTextField
