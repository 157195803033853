import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './style.module.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GlobalSpinnerContext } from '../GlobalSpinnerContextProvider'

const Spinner = ({ open = true }) => {
  const isGlobalSpinnerOn = useContext(GlobalSpinnerContext)
  return (open || isGlobalSpinnerOn) ? (
    <div className={styles.spinner}>
      <CircularProgress size={60} thickness={5} />
    </div>
  ) : <></>
}

Spinner.propTypes = {
  open: PropTypes.bool
}

export default Spinner
