import React from 'react'
import PropTypes from 'prop-types'
const columnChooserContainerEmpty =
  () =>
    ({ children }) => {
      return (
        <>
          {children}
        </>
      )
    }
columnChooserContainerEmpty.propTypes = {
  children: PropTypes.element
}
export default columnChooserContainerEmpty