import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { currencyFormatter } from 'components/DataGrid/utils'
import { INVOICE_TAGS, INVOICE_TAGS_READABLE } from 'constants.js'
import UnderlinedCell from '../UnderlinedCell'

const OriginalAmountCell = memo(({ originalAmountInCents, tags = [], currency }) => {
  if (tags.includes(INVOICE_TAGS.AMOUNT_ABOVE_THRESHOLD)) {
    return (
      <Tooltip title={INVOICE_TAGS_READABLE.AMOUNT_ABOVE_THRESHOLD}>
        <UnderlinedCell>
          {currencyFormatter({ value: originalAmountInCents, currency })}
        </UnderlinedCell>
      </Tooltip>
    )
  }
  return currencyFormatter({ value: originalAmountInCents, currency })
})

OriginalAmountCell.displayName = 'OriginalAmountCell'

OriginalAmountCell.propTypes = {
  originalAmountInCents: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  tags: PropTypes.array,
  currency: PropTypes.string
}

export default OriginalAmountCell
