import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const CheckboxGroup = ({
  type,
  items,
  value,
  onChange,
  label,
  direction,
  helperText,
  ...formControlProps
}) => {
  const handleChange = event => {
    if (!onChange) {
      return
    }

    const itemValue = type === 'number'
      ? +event.target.value
      : event.target.value

    onChange(event.target.checked
      ? [...value, itemValue]
      : value.filter(item => item !== itemValue)
    )
  }

  return <FormControl component="fieldset" {...formControlProps}>
    {label && <FormLabel component="legend">{label}</FormLabel>}
    <FormGroup style={{ flexDirection: direction ?? 'row' }}>
      {items.map((item, index) => <FormControlLabel
        key={`${index}:${item.value}:${item.label}`}
        label={item.label}
        control={<Checkbox
          color="primary"
          checked={value?.includes(item.value)}
          onChange={handleChange}
          value={item.value}
        />}
      />)}
    </FormGroup>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
}

CheckboxGroup.propTypes = {
  type: PropTypes.oneOf(['string', 'number']).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  helperText: PropTypes.string,

  // for FormControl
  error: PropTypes.bool,
  disabled: PropTypes.bool,
}


export default CheckboxGroup