import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import {
  Box,
  Grid,
  FormControl,
  TextField
} from '@material-ui/core'
import FormSelect from 'components/FormSelect'
import AuthView from 'containers/AuthView/connect'
import SnackbarComponent from 'components/Snackbar'
import FormInputDate from 'components/FormInputDate'
import { MaterialAutocomplete } from 'components/MaterialAutocomplete'
import { BookingSummary } from 'containers/SentTransactions/components/BookingSummary'
import { useStyles } from './styles'
import { withConnect } from './connect'
// import { get } from '../../utils/api'
import Button from 'components/Button'
import { getCompanyEntities } from './utils'
import { PAYMENT_FOR, ROUTE_URL, STATUSES } from 'constants.js'
import { useIntl } from 'react-intl'

const paidViaOptions = {
  DDUK_ABTA: 'DDUK_ABTA',
  OFFLINE_BANK: 'OFFLINE_BANK',
  TL_PAY: 'TL_PAY'
}

const currencyOptions = {
  GBP: 'GBP',
  USD: 'USD',
  EUR: 'EUR'
}

const initialState = {
  bookingRefs: [],
  amount: undefined,
  paidVia: paidViaOptions.OFFLINE_BANK,
  currency: currencyOptions.GBP,
  paymentRef: '',
  paymentFor: PAYMENT_FOR.BUYER_COLLECTION,
  paymentDate: '',
  commLinkEntities: {},
  selectedPayerId: '',
  selectedPayeeId: ''
}

const reducer = (state, action) => {
  if (action.type) {
    return {
      ...state,
      [action.type]: action.payload
    }
  }
  return state
}

let searchHandler = () => {}

const useBookingSearchAutocomplete = (dispatch, fetchInvoices, currUserCompanyId) => {
  React.useEffect(() => {
    searchHandler = _.throttle((text) => {
      fetchInvoices({
        'bookingRef[contains]': text,
        'sourceId[eq]': currUserCompanyId,
        'status[in]': Object.entries(STATUSES).filter(([ key ]) => ![ STATUSES.VOID, STATUSES.REJECTED ].includes(key)).map(([ , value ]) => value)
      })
    }, 500, { trailing: true, leading: true })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currUserCompanyId ])

  const inputHandlerBookingRef = ({ target }) => {
    searchHandler(target.value)
  }

  const handleSelectBookingRef = React.useCallback((_e, bookingObjs) => {
    dispatch({ type: 'bookingRefs', payload: bookingObjs })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    inputHandlerBookingRef,
    handleSelectBookingRef
  }
}

const CreateRemittance = ({
  invoices,
  fetchInvoices,
  selectedInvoices,
  createRemittance,
  redirectTo,
  remittancesSnackbar,
  currUserCompanyId,
  location
}) => {
  const classes = useStyles()
  const textFieldProps = { fullWidth: true, className: classes.formInput }
  const [ {
    bookingRefs,
    amount,
    paidVia,
    currency,
    paymentRef,
    paymentFor,
    paymentDate,
    selectedPayerId,
    selectedPayeeId,
    commLinkEntities
  }, dispatch ] = React.useReducer(reducer, initialState)
  const { formatMessage } = useIntl()

  const { inputHandlerBookingRef, handleSelectBookingRef } = useBookingSearchAutocomplete(dispatch, fetchInvoices, currUserCompanyId)

  React.useEffect(() => {
    if (selectedInvoices) {
      dispatch({ type: 'bookingRefs', payload: selectedInvoices })
    }
  }, [ selectedInvoices ])

  React.useEffect(() => {
    let didCancel = false

    if (bookingRefs.length > 1) {
      dispatch({ type: 'amount', payload: bookingRefs.reduce((prev, current) => prev + current.currentAmountInCents, 0) / 100 })
    } else if (bookingRefs.length === 1) {
      dispatch({ type: 'amount', payload: bookingRefs[0].currentAmountInCents / 100 })
    } else {
      dispatch({ type: 'amount', payload: 0 })
    }

    async function getCommlinkEntities() {
      if (bookingRefs.length && !didCancel) {
        const entities = await getCompanyEntities(bookingRefs)
        dispatch({ type: 'commLinkEntities', payload: entities })
      }
    }

    getCommlinkEntities()

    return () => {
      didCancel = true
    }
  }, [ bookingRefs ])

  const handleDispatch = React.useCallback(({ target }) => {
    dispatch({ type: target.name, payload: target.value })
  }, [])

  const handleDispatchAmount = React.useCallback(({ target }) => {
    const intAmount = parseInt(target.value)
    if (currUserCompanyId === bookingRefs[0].sourceId) {
      return dispatch({ type: 'amount', payload: target.value })
    }
    const positiveSign = bookingRefs[0].currentAmountInCents > 0
    if ((positiveSign && intAmount < 0) || (!positiveSign && intAmount > 0)) {
      return
    }
    dispatch({ type: 'amount', payload: target.value })
  }, [ currUserCompanyId, bookingRefs ])

  const onSubmit = () => {
    const formData = {
      currency,
      paymentRef,
      paymentDate: paymentDate || moment().format('YYYY-MM-DD'),
      amount: Number(amount) * 100,
      status: 'COMPLETED',
      paymentMethod: paidVia,
      invoiceIds: bookingRefs.map(b => b.id),
      sourceId: selectedPayerId,
      targetId: selectedPayeeId
    }
    createRemittance(formData)
    redirectTo(ROUTE_URL.remittancesReview)
  }

  const onCancel = () => {
    redirectTo(ROUTE_URL.remittancesReview)
  }

  const handleBookingDeselect = (id) => {
    dispatch({ type: 'bookingRefs', payload: bookingRefs.filter(b => b.id !== id) })
  }

  const submitDisabled =
    !selectedPayeeId ||
    !selectedPayerId ||
    selectedPayeeId === selectedPayerId ||
    !currency ||
    !paymentRef ||
    !paidVia ||
    !amount ||
    !bookingRefs?.[0]?.id

  return (
    <AuthView location={location} title="Create Remittance">
      <Grid container justify="space-between" spacing={5}>
        <Grid item container sm={12} lg={4} md={5}>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl {...textFieldProps}>
              <MaterialAutocomplete
                label={`${formatMessage({ id: 'remittances.booking-ref' })} *`}
                placeholder="Type a booking reference"
                value={bookingRefs || []}
                options={invoices}
                getOptionLabel={o => o.bookingRef || '-'}
                renderOptionKeys={[ 'bookingRef', 'departureDate', 'leadName' ]}
                onChange={handleSelectBookingRef}
                onInputChange={inputHandlerBookingRef}
                required
                multiple
                limitTags={10}
                debug
                disableClearable
                renderTags={(tags) => {
                  if (tags.length === 0) {
                    return null
                  }
                  if (tags.length === 1) {
                    const t = tags[0]
                    return <Box mr={2}>{`${t.bookingRef} - ${t.departureDate} - ${t.leadName}`}</Box>
                  }
                  return <Box mr={2}>Multiple invoices selected</Box>
                }}
              />
            </FormControl>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormSelect
                  {...textFieldProps}
                  name="selectedPayerId"
                  label={`${formatMessage({ id: 'remittances.sender' })} *`}
                  value={selectedPayerId}
                  onChange={handleDispatch}
                  keyTitle={ { ...commLinkEntities, [selectedPayeeId]: undefined }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  {...textFieldProps}
                  name="selectedPayeeId"
                  label={`${formatMessage({ id: 'remittances.recipient' })} *`}
                  value={selectedPayeeId}
                  onChange={handleDispatch}
                  keyTitle={ { ...commLinkEntities, [selectedPayerId]: undefined }}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={8}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label={formatMessage({ id: 'remittances.amount' })}
                  name="amount"
                  value={amount ?? ''}
                  disabled={bookingRefs.length > 1}
                  onChange={handleDispatchAmount}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormSelect
                  {...textFieldProps}
                  name="currency"
                  label={formatMessage({ id: 'remittances.currency' })}
                  value={currency}
                  onChange={handleDispatch}
                  keyTitle={currencyOptions}
                  required
                  disabled
                />
              </Grid>
            </Grid>
            <FormInputDate
              {...textFieldProps}
              label={formatMessage({ id: 'remittances.payment-date' })}
              name="paymentDate"
              value={paymentDate}
              onChange={handleDispatch}
            />
            <FormSelect
              {...textFieldProps}
              name="paidVia"
              label={`${formatMessage({ id: 'remittances.paid-via' })} *`}
              value={paidVia}
              onChange={handleDispatch}
              keyTitle={paidViaOptions}
              required
              disabled
            />
            <FormSelect
              {...textFieldProps}
              name="paymentFor"
              label={formatMessage({ id: 'remittances.payment-for' })}
              value={paymentFor}
              onChange={handleDispatch}
              keyTitle={PAYMENT_FOR}
              required
            />
            <TextField
              {...textFieldProps}
              name="paymentRef"
              label={formatMessage({ id: 'remittances.payment-ref' })}
              value={paymentRef}
              onChange={handleDispatch}
            />
            <Grid className={classes.buttonSpacing} container justify="flex-start">
              <Button size="large" color="abort" icon="clear" onClick={onCancel} marginRight>{formatMessage({ id: 'remittances.cancel' })}</Button>
              <Button size="large" color="success" icon="add" onClick={onSubmit} disabled={ submitDisabled }>
                {formatMessage({ id: 'table-filters.create' })}
              </Button>
            </Grid>
          </form>
        </Grid>
        <Grid item container sm={12} md={7} lg={8}>
          <BookingSummary bookingData={bookingRefs} deselectInvoice={handleBookingDeselect} />
        </Grid>
      </Grid>
      <SnackbarComponent
        status={remittancesSnackbar.isOpened}
        error={remittancesSnackbar.isError}
        text={remittancesSnackbar.successOrErrorText}
      />
    </AuthView>
  )
}

CreateRemittance.propTypes = {
  currUserCompanyId: PropTypes.string,
  location: PropTypes.object,
  remittancesSnackbar: PropTypes.object,
  invoices: PropTypes.array,
  fetchInvoices: PropTypes.func,
  createRemittance: PropTypes.func,
  redirectTo: PropTypes.func,
  selectedInvoices: PropTypes.array
}

export default withConnect(CreateRemittance)
