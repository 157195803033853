import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { fetchPaymentProfiles } from 'store/paymentProfiles'

const mapState = ({ auth, paymentProfiles, companies }) => ({
  currUserCompany: companies.currUserCompany,
  isLoading: auth.fetching,
  paymentProfiles: paymentProfiles.payment,
  userRole: auth.role
})

const mapDispatch = dispatch => ({
  redirectTo: params => dispatch(push(params)),
  fetchPaymentProfiles: () => dispatch(fetchPaymentProfiles())
})

export const withConnect = connect(mapState, mapDispatch)
