/* eslint-disable no-param-reassign */
import { Typography, FormControlLabel, Checkbox, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import Button from 'components/Button'
import { Controller, useFormContext } from 'react-hook-form'
import FormSelect from 'components/FormSelect'
import { InputText } from 'components/ui/Form/InputText'
import { styled } from '@material-ui/core/styles'
import CountrySelectInput from 'containers/CountrySelectInput'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL, NIUM_STAKEHOLDER_TYPE } from 'constants.js'
import useDebounce from 'utils/debounceHook'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { Alert } from '@material-ui/lab'
import { useIntl } from 'react-intl'

const StepActions = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

const cleanEmpty = obj => {
  if (Array.isArray(obj)) { 
    return obj
        .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
        .filter(v => !(v == null)); 
  } else { 
    return Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
        // eslint-disable-next-line no-return-assign, no-param-reassign
        .reduce((a, [k, v]) => (v == null ? a : (a[k]=v, a)), {});
  } 
}


const mapToBusinessDetails = (simpleDetails, exhaustiveDetails, signupStorage) => {
  const { businessName } = simpleDetails || {}

  const simpleDetailsParsed = cleanEmpty(simpleDetails || {})
  const exhaustiveDetailsParsed = cleanEmpty(exhaustiveDetails || {})
  const signupStorageParsed = cleanEmpty(signupStorage || {})

  exhaustiveDetailsParsed.stakeholders && delete exhaustiveDetailsParsed.stakeholders

  const businessDetails = {
    ...simpleDetailsParsed,
    ...(exhaustiveDetailsParsed || {}),
    businessName,
    ...(signupStorageParsed || {}),
  }
  return businessDetails
}

const toPositionEnum = value => {
  if(value === 'Ultimate Beneficial Owner') {
    return 'UBO'
  }
  return value && value.toUpperCase()
}

const limitProfessionalDetails = stakeholderDetails => {
  if(stakeholderDetails && stakeholderDetails.professionalDetails &&
    stakeholderDetails.professionalDetails.length) {
      // we only allow 1 professional details per stakeholder
      const [first] = stakeholderDetails.professionalDetails
      if(first.position) {
        first.position = toPositionEnum(first.position)
      }
      stakeholderDetails.professionalDetails = [first]
    }
  return stakeholderDetails
}

const mapToStakeholders = (exhaustiveDetails, signupStorage) => {
  const exhaustiveDetailsParsed = cleanEmpty(exhaustiveDetails || {})
  const signupStorageParsed = cleanEmpty(signupStorage || {})

  if(signupStorageParsed && signupStorageParsed.stakeholders) {
    const stakeholders = signupStorageParsed.stakeholders.map(s => {
      if(s.stakeholderType === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL) {
        s.stakeholderDetails = limitProfessionalDetails(s.stakeholderDetails)
      }
      return s
    })
    return stakeholders
  }

  if(exhaustiveDetailsParsed && exhaustiveDetailsParsed.stakeholders) {
    const stakeholders = exhaustiveDetailsParsed.stakeholders.map((s) => {
      const { stakeholderDetails, businessPartner } = s
      return {
        stakeholderType: s.entityType,
        ...(s.entityType === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL && { stakeholderDetails: limitProfessionalDetails(stakeholderDetails) }),
        ...(s.entityType === NIUM_STAKEHOLDER_TYPE.CORPORATE && { businessPartner: {
          ...businessPartner,
          businessEntityType: toPositionEnum(businessPartner.businessEntityType)
        } }),
      }
    })
    return stakeholders
  }

  return []
}


const CorporateDetails = ({ countryAlpha2, signupStorage, goToNextStep, provider, region }) => {
  const { control, watch, setValue } = useFormContext()
  const defaultDebounceTimeout = 1000
  const businessRegistrationNumber = watch('brnNumber', false)
  const searchReferenceId = watch('searchReferenceId', false)
  const countryCode = watch('countryCode', false)
  const brnDebounced = useDebounce(businessRegistrationNumber, defaultDebounceTimeout)
  const shouldFetchBRN = !!brnDebounced && !!countryCode && !!provider
  const shouldFetchExhaustive = !!searchReferenceId && !!provider
  const { formatMessage } = useIntl()

  useEffect(() => {
    setValue('countryCode', countryAlpha2)
    setValue('region', region)
  }, [countryAlpha2, region, setValue])

  const { data, refetch } = useQuery(
    `get-corporate-details`,
    () =>
      get(
        `${API_URL}/e-wallet/corporate-details/${provider}?countryCode=${countryCode}&businessRegistrationNumber=${businessRegistrationNumber}`,
      ),
    {
      enabled: shouldFetchBRN,
      retry: false,
    },
  )

  const { data: exhaustiveData, refetch: refetchExhaustive } = useQuery(
    `get-exhaustive-corporate-details`,
    () =>
      get(
        `${API_URL}/e-wallet/exhaustive-corporate-details/${provider}?countryCode=${countryCode}&searchReferenceId=${searchReferenceId}`,
      ),
    {
      enabled: shouldFetchExhaustive,
      retry: false,
    },
  )

  // manage exhaustive corporate details
  const { results: { businessDetails: businesses } = {} } = data || {}
  const showCompanyList = businesses && !!businesses.length
  const companySearchReference = {}
  businesses &&
    businesses.forEach((business) => {
      companySearchReference[business.searchReferenceId] = business.businessName
    })

  useEffect(() => {
    if (businesses && businesses.length === 1) {
      setValue('searchReferenceId', businesses[0].searchReferenceId)
    } else {
      setValue('searchReferenceId', '')
    }
  }, [businesses, setValue])

  useEffect(() => {
    if (shouldFetchExhaustive) {
      refetchExhaustive()
    }
  }, [searchReferenceId, refetchExhaustive, shouldFetchExhaustive])

  useEffect(() => {
    if (shouldFetchBRN) {
      refetch()
    }
  }, [brnDebounced, refetch, shouldFetchBRN])

  const handleGoToNextStep = () => {
    if (region) {
      setValue('region.region', region)
    }
    if (businesses) {
      setValue('stakeholders', mapToStakeholders(
        exhaustiveData && exhaustiveData.businessDetails, 
        signupStorage))
      setValue('businessDetails', mapToBusinessDetails(
          businesses[0], 
          exhaustiveData && exhaustiveData.businessDetails, 
          signupStorage && signupStorage.businessDetails))
      if (exhaustiveData && exhaustiveData.riskAssessmentInfo) {
        setValue('riskAssessmentInfo', {
          ...exhaustiveData.riskAssessmentInfo,
          ...signupStorage.riskAssessmentInfo,
        })
      }
    }
    goToNextStep()
  }

  const canContinue = () => {
    if (!(businessRegistrationNumber || watch('brnOther', false))) {
      return false
    }
    if (businessRegistrationNumber && !region && !countryCode) {
      return false
    }
    if (showCompanyList && !searchReferenceId) {
      return false
    }
    return true
  }

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {formatMessage({ id: 'tlpay.corporate-details' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.please-note-for-those-who-company-registration-number-is-not-relevant-selecting-other-will-bypass-our-electronic-checks-and-the-data-will-need-to-be-entered-and-processed-manually-resulting-in-a-longer-review-process' })}
      </Typography>
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item xs={4}>
          <InputText
            name="brnNumber"
            control={control}
            label={formatMessage({ id: 'tlpay.company-registration-number' })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={formatMessage({ id: 'tlpay.you-can-find-your-company-registration-number-on-your-certificate-of-incorporation-and-any-official-documentation-received-from-your-registration-office-exact-format-may-vary-depending-on-your-location-for-the-uk-this-is-8-characters-long' })}
                    arrow
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          {formatMessage({ id: 'tlpay.or' })}
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="brnOther"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    color="primary"
                    defaultValue={false}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
                label={formatMessage({ id: 'tlpay.tick-this-box-if-you-do-not-have-a-registration-number' })}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field }) => (
              <FormSelect
                {...field}
                disabled
                label={formatMessage({ id: 'tlpay.region' })}
                name="region"
                keyTitle={{
                  EU: 'EU',
                  UK: 'UK',
                }}
                variant="outlined"
                fullWidth
              />
            )}
            control={control}
            name="region"
            error={false}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="countryCode"
            control={control}
            render={({ field }) => (
              <CountrySelectInput
                {...field}
                label={formatMessage({ id: 'tlpay.country' })}
                valueKey="alpha2"
                variant="outlined"
                fullWidth
                disabled
              />
            )}
          />
        </Grid>

        {!region && (
          <Grid item xs={8}>
            <Alert severity="warning">
              {formatMessage({ id: 'tlpay.your-company-country-is-currently-not-supported-please-contact-us-for-further-information' })}
            </Alert>
          </Grid>
        )}

        {showCompanyList && (
          <Grid item xs={8}>
            <Controller
              name="searchReferenceId"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label={formatMessage({ id: 'tlpay.company' })}
                  value={searchReferenceId}
                  // name="searchReferenceId"
                  keyTitle={companySearchReference}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <StepActions>
        <Button color="primary" onClick={handleGoToNextStep} disabled={!canContinue()}>
          {formatMessage({ id: 'tlpay.continue' })}
        </Button>
      </StepActions>
    </>
  )
}

export default CorporateDetails
