import React from 'react'
import PropTypes from 'prop-types'
import TableChartIcon from '@material-ui/icons/TableChart'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const ColumnChooserButton = ({ buttonRef, onToggle }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <Button
      ref={buttonRef}
      variant="contained"
      color="secondary"
      size="small"
      onClick={onToggle}
      startIcon={<TableChartIcon />}
      className={classes.root}
    >
      {formatMessage({ id: 'table-filters.columns' })}
    </Button>
  )
}

ColumnChooserButton.propTypes = {
  onToggle: PropTypes.func,
  buttonRef: PropTypes.func
}

export default ColumnChooserButton
