import React, { useState, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { Box, Typography } from '@material-ui/core'

import { usePostImportCSV } from 'containers/CommercialLinksList/hooks'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Instructions from 'components/upload/Instructions'
import ResultInfo from 'components/upload/ResultInfo'

import fieldsCsv from './fieldsCsv'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const ModalImport = () => {
  const classes = useStyles()
  const modalRef = useRef()
  const [ csvsResult, setCsvsResult ] = useState({})
  const { mutate } = usePostImportCSV({ onSuccess: setCsvsResult })
  const { formatMessage } = useIntl()

  const handleOpenModalButton = () => {
    modalRef.current.open()
  }

  const handleUpload = (files) => {
    mutate(files)
  }

  const handleCloseModal = () => {
    setCsvsResult({})
  }

  return (
    <>
      <Box mb={1}>
        <Button variant="outlined" color="primary" icon="playlist_add" onClick={handleOpenModalButton}>{formatMessage({ id: 'csv-uploader.import' })}</Button>
      </Box>
      <Modal fullWidth maxWidth="md" ref={modalRef} onClose={handleCloseModal}>
        <>
          <Dropzone multiple={false} onDrop={handleUpload} className={classes.dropZone}>
            <Typography variant="subtitle2">{formatMessage({ id: 'csv-uploader.drop-csv-file-here' })}</Typography>
          </Dropzone>
          <ResultInfo result={csvsResult} />
          <Instructions fieldsCsv={fieldsCsv(formatMessage)} />
        </>
      </Modal>
    </>
  )
}

export default ModalImport
