import { connect } from 'react-redux'
import Component from './Invite'
import { createInvite } from 'store/users'

export const mapState = ({ users }) => ({
  ...users
})

export const mapDispatch = dispatch => ({
  createInvite: (data) => dispatch(createInvite(data))
})

export default connect(mapState, mapDispatch)(Component)
