import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  hiddenReceipt: {
    width: '19cm',
    display: 'none',
  },
  topPaper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  buttonSpacing: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  logo: {
    width: 180,
  },
  spacedRow: {
    height: theme.spacing(10),
  },
  upperDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  bottomDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))
