import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects'
import { API_URL, DEFAULT_DATE_FORMAT, DEFAULT_READABLE_DATE_FORMAT } from 'constants.js'
import { ACTIONS } from '../'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { get, patch, post } from 'utils/api'
import { dismissErrorSnackBar, dismissSnackBar } from 'utils/generators'
import moment from 'moment'

const getCommittedFilters = state => state?.invoices?.committed?.filters ?? {}
const getUncommittedFilters = state => state?.invoices?.uncommitted?.filters ?? {}

function* fetchInvoices() {
  const url = `${API_URL}/invoices`
  try {
    const filters = yield select(getCommittedFilters)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: true
    })
    const response = yield call(get, url, filters)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    if (response.errors) {
      yield put({
        type: ACTIONS.FETCH_INVOICES_FAILURE,
        payload: response.errors[0].detail
      })
    } else {
      yield put({
        type: ACTIONS.FETCH_INVOICES_SUCCESS,
        payload: response.data
      })
    }
  } catch (e) {
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
  }
}
function* fetchUncommittedInvoices() {
  const url = `${API_URL}/invoices/uncommitted`

  try {
    const filters = yield select(getUncommittedFilters)
    yield put({
      type: ACTIONS.SET_LOADING,
      payload: true
    })
    const response = yield call(get, url, filters)
    yield put({
      type: ACTIONS.SET_LOADING,
      payload: false
    })
    if (response.errors) {
      yield put({
        type: ACTIONS.FETCH_UNCOMMITTED_INVOICES_FAILURE,
        payload: response.errors[0].detail
      })
    } else {
      yield put({
        type: ACTIONS.FETCH_UNCOMMITTED_INVOICES_SUCCESS,
        payload: response.data
      })
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: ACTIONS.SET_LOADING,
      payload: false
    })
  }
}

function* commitInvoices({ payload }) {
  const url = `${API_URL}/invoices`

  const invoicesToCommit = payload || []

  const bulkUpdateRequest = {
    data: invoicesToCommit.map((i) => {
      return {
        type: 'invoices',
        id: i.id,
        attributes: {
          isCommitted: true
        }
      }
    })
  }

  try {
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: true
    })
    yield call(patch, url, bulkUpdateRequest)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
  } catch (e) {
    console.error(e)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    yield put({
      type: ACTIONS.FETCH_INVOICES_FAILURE,
      payload: e.errors[0].title
    })
  }
  yield call(fetchUncommittedInvoices)
}

function* updateInvoice({ payload }) {
  const url = `${API_URL}/invoices/${payload.id}`
  try {
    const { id, ...attrs } = payload
    const patchPayload = {
      data: {
        type: 'invoices',
        id,
        attributes: attrs
      }
    }
    const response = yield call(patch, url, patchPayload)
    yield put({
      type: ACTIONS.UPDATE_INVOICE_SUCCESS,
      payload: response.data
    })
  } catch (e) {
    console.error(e)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    yield put({
      type: ACTIONS.UPDATE_INVOICE_FAILURE,
      payload: {
        id: payload.id,
        error: e?.data?.errors?.[0]?.detail
      }
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* updateInvoices({ payload, uncommitted }) {
  const url = `${API_URL}/invoices`

  try {
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: true
    })
    const patchPayload = { data: [] }
    if (Array.isArray(payload)) {
      patchPayload.data = payload.map(invoice => {
        const { id, ...attributes } = invoice
        return {
          type: 'invoices',
          id,
          attributes
        }
      })
    }
    const response = yield call(patch, url, patchPayload)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    yield put({
      type: ACTIONS.UPDATE_INVOICES_SUCCESS,
      payload: {
        data: response.data,
        uncommitted
      }
    })
  } catch (e) {
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    yield put({
      type: ACTIONS.UPDATE_INVOICES_FAILURE,
      payload: {
        data: payload,
        uncommitted,
        error: e?.errors?.[0]?.detail
      }
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* deleteInvoices({ payload }) {
  const url = `${API_URL}/invoices/delete`

  try {
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: true
    })
    const invoicesIds = payload.map(invoice => invoice.id)
    const deletePayload = { data: invoicesIds }
    yield call(post, url, deletePayload)

    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    yield put({
      type: ACTIONS.DELETE_INVOICES_SUCCESS,
      payload: 'Invoices deleted.'
    })
    yield call(fetchUncommittedInvoices)
    yield fork(dismissSnackBar, ACTIONS.CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    yield put({
      type: ACTIONS.DELETE_INVOICES_FAILURE,
      payload: e?.data?.errors?.[0].title
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* createInvoice({ payload }) {
  const url = `${API_URL}/invoices`

  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })

    const postPayload = {
      data: {
        type: 'invoices',
        attributes: payload
      }
    }
    yield call(post, url, postPayload)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({ type: ACTIONS.CREATE_INVOICE_SUCCESS, payload: { message: 'Booking added.'} })
    yield fork(dismissSnackBar, ACTIONS.CLOSE_SNACKBAR)

  } catch (e) {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({ type: ACTIONS.CREATE_INVOICE_FAILURE, payload: e.errors[0].title })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* fetchLatestCycles({ isPastView }) {
  const LIMIT = 8
  const url = `${API_URL}/invoice-collection-cycles/latest-dates?limit=${LIMIT}&isPastView=${isPastView}`
  const response = yield call(get, url)

  const transform = date => ({
    label: moment(date, DEFAULT_DATE_FORMAT).format(DEFAULT_READABLE_DATE_FORMAT),
    startDate: moment(date, DEFAULT_DATE_FORMAT).startOf('day'),
    endDate: moment(date, DEFAULT_DATE_FORMAT).endOf('day')
  })

  yield put({
    type: ACTIONS.FETCH_LATEST_CYCLES_SUCCESS,
    latestCycleStartDates: response.latestCycleStartDates.map(transform),
    latestCollectionDates: response.latestCollectionDates.map(transform),
    latestCollectionPayments: response.latestCollectionPayments.map(transform),
    latestDisbursementDates: response.latestDisbursementDates.map(transform),
    latestDisbursementPayments: response.latestDisbursementPayments.map(transform),
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(ACTIONS.FETCH_INVOICES, fetchInvoices),
    takeLatest(ACTIONS.FETCH_UNCOMMITTED_INVOICES, fetchUncommittedInvoices),
    takeLatest(ACTIONS.COMMIT_INVOICES, commitInvoices),
    takeLatest(ACTIONS.UPDATE_INVOICE, updateInvoice),
    takeLatest(ACTIONS.UPDATE_INVOICES, updateInvoices),
    takeLatest(ACTIONS.DELETE_INVOICES, deleteInvoices),
    takeLatest(ACTIONS.CREATE_INVOICE, createInvoice),
    takeLatest(ACTIONS.FETCH_LATEST_CYCLES, fetchLatestCycles)
  ])
}
