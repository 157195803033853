import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import { useStyles } from '../styles'
import { useIntl } from 'react-intl'

const InviteFields = ({
  register,
  errors
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <Paper className={classes.childForm}>
      <TextField
        label={formatMessage({ id: 'invite-company.company-name' })}
        {...register("companyName")}
        helperText={errors?.companyName?.message}
        error={!!errors.companyName}
      />
      <TextField
        label={formatMessage({ id: 'invite-company.email' })}
        type="email"
        {...register("email")}
        helperText={errors?.email?.message}
        error={!!errors.email}
      />
      <TextField
        label={formatMessage({ id: 'invite-company.name' })}
        {...register("name")}
        helperText={errors?.name?.message}
        error={!!errors.name}
      />
    </Paper>
  )
}

InviteFields.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object
}

export default InviteFields
