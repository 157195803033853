import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: 40
  },
  section: {
    paddingLeft: theme.spacing(2),
    margin: 0
  }
}))