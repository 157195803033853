import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Section from 'components/PaymentProfileConfigForm/components/Section'
import { API_URL, PERIOD_KEY_TITLE, INCL_TX_STATUS_SUBMISSION_KEY_TITLE } from 'constants.js'
import { get } from 'utils/api'
import SelectOnTabMenuItem from 'components/SelectOnTabMenuItem'
import { cronExpressionToReadableString } from 'utils/cronTransform'
import { FormattedMessage, useIntl } from 'react-intl'

const findSettlementScheduleById = (settlementSchedules, settlementScheduleId) => settlementSchedules.find(settlementSchedule => settlementSchedule.id === settlementScheduleId)

// ?? why is this called PartBusinessTerms when it also uses settlement schedule?
// eslint-disable-next-line react/display-name
export const PartBusinessTerms = React.memo(
  ({ classes, currUserCompany, businessState, businessActions, settlementSchedules, commercialLinkSettlementScheduleId, commercialLinkBusinessTermsId }) => {
    const [ businessTermsList, setBusinessTermsList ] = useState([])
    const [ selectedBusinessTermsObj, setSelectedBusinessTermsObj ] = useState({})
    const { dispatchValue } = businessActions
    const { businessTermsSelected } = businessState
    const settlementSchedule = findSettlementScheduleById(settlementSchedules, commercialLinkSettlementScheduleId)
    const { formatMessage } = useIntl()

    useEffect(() => {
      (async () => {
        const url = `${API_URL}/business-terms${
          commercialLinkBusinessTermsId ? `/${commercialLinkBusinessTermsId}` : ''
        }`
        try {
          const result = await get(url)
          const tempTermsList = commercialLinkBusinessTermsId
            ? [ { id: result.data.id, ...result.data.attributes } ]
            : result.data
          setBusinessTermsList(tempTermsList)
          if (commercialLinkBusinessTermsId) {
            setSelectedBusinessTermsObj(tempTermsList[0])
          }
        } catch (e) {
          console.error(e)
        }
      })()
    }, [ currUserCompany.id ])

    useEffect(() => {
      (async () => {
        if (!businessTermsSelected || commercialLinkBusinessTermsId) {
          return Promise.resolve()
        }
        const url = `${API_URL}/business-terms/${businessTermsSelected}`
        try {
          const result = await get(url)
          const selectedBT = { id: result.data.id, ...result.data.attributes }
          setSelectedBusinessTermsObj(selectedBT)
        } catch (e) {
          console.error(e)
        }
      })()
    }, [ businessTermsSelected ])

    return (
      <React.Fragment>
        <Divider style={{ marginBottom: 20 }} />
        <Typography component="p">{formatMessage({ id: 'commercial-link-entity.business-terms' })}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.half}>
            <FormControl className={classes.formInput} error={Boolean(businessState.errorBusinessTermsSelectedField)}>
              <InputLabel htmlFor="businessTermsSelected">{formatMessage({ id: 'commercial-link-entity.business-terms-profile' })} *</InputLabel>
              <Select
                id="businessTermsSelected"
                name="businessTermsSelected"
                value={businessTermsSelected}
                onChange={dispatchValue}
                disabled={!!commercialLinkBusinessTermsId}
                MenuProps={{variant: "menu"}}
              >
                {businessTermsList.length > 0 &&
                  businessTermsList.map((obj, k) => (
                    <SelectOnTabMenuItem key={k} name="businessTermsSelected" value={obj.id} onChange={dispatchValue}>{obj.name}</SelectOnTabMenuItem>
                  ))}
              </Select>
              <FormHelperText>{businessState.errorBusinessTermsSelectedField}</FormHelperText>
            </FormControl>
            {selectedBusinessTermsObj.name && selectedBusinessTermsObj.depositTerms && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <span className={classes.btLabel}>Deposit:</span> {selectedBusinessTermsObj.depositTerms.numHours}{' '}
                    hours {PERIOD_KEY_TITLE[selectedBusinessTermsObj.depositTerms.when] || ''}
                  </Grid>
                  <Grid item xs={12}>
                    <span className={classes.btLabel}>Balance:</span> {selectedBusinessTermsObj.balanceTerms.numHours}{' '}
                    hours {PERIOD_KEY_TITLE[selectedBusinessTermsObj.balanceTerms.when] || ''}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} className={classes.gridItem}>
                    <Section title="Submission Inclusions" className={classes.section}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <span className={classes.btLabel}>Tx status included in submission:</span>{' '}
                          {selectedBusinessTermsObj.sellerRules.validStatusesToInclude
                            .map(k => INCL_TX_STATUS_SUBMISSION_KEY_TITLE[k])
                            .join(', ')}
                        </Grid>
                        <Grid item xs={12}>
                          <span className={classes.btLabel}>Buyer acceptance window:</span>{' '}
                          {settlementSchedule?.buyerReviewPeriodInHours} <FormattedMessage id='general.unit.hours' />
                        </Grid>
                      </Grid>
                    </Section>
                  </Grid>
                  {/* add cycle start schedule too? */}
                  <Grid item xs={6} className={classes.gridItem}>
                    <Section title="Collection Processing Schedule" className={classes.section}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <span className={classes.btLabel}>{ cronExpressionToReadableString(settlementSchedule?.collectionDefault ?? '') }</span>
                        </Grid>
                      </Grid>
                    </Section>
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}>
                    <Section title="Processor rules" className={classes.section}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <span className={classes.btLabel}>
                            Forward funds when offline remittance entered manually in TL
                          </span>{' '}
                          {String(selectedBusinessTermsObj.processorRules.manualEntryForwardsFunds)}
                        </Grid>
                        <Grid item xs={12}>
                          <span className={classes.btLabel}>Forward funds after invoice due date:</span>{' '}
                          {settlementSchedule?.forwardFundsPeriodInHours} <FormattedMessage id='general.unit.hours' />
                        </Grid>
                      </Grid>
                    </Section>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
)

PartBusinessTerms.propTypes = {
  currUserCompany: PropTypes.object,
  settlementSchedules: PropTypes.array,
  commercialLinkSettlementScheduleId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  businessState: PropTypes.object,
  businessActions: PropTypes.objectOf(PropTypes.func),
  commercialLinkBusinessTermsId: PropTypes.string
}
