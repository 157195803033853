const fieldsCSV = formatMessage => [
  {
    title: formatMessage({ id: 'modal-import-instructions.action' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory' }),
    format: formatMessage({ id: 'modal-import-instructions.c-create' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.entity-company-name' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory' }),
    format: formatMessage({ id: 'modal-import-instructions.alphanumeric-permitted-values-as-per-allowed-characters-dev' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.id-type-1' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory' }),
    format: formatMessage({ id: 'modal-import-instructions.accepted-values-abta-iata-tids' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.id-type-1-id' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory-must-be-unique-for-each-record-in-the-file' }),
    format: formatMessage({ id: 'modal-import-instructions.if-id-type-1-abta-5-chars-alphanumeric-if-iata-or-tids-9-12-chars-alphanumeric' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.id-type-2' }),
    notes: formatMessage({ id: 'modal-import-instructions.optional-only-one-id-of-each-type-a-company-can-have-one-abta-one-iata-and-one-tids-id-but-not-3-abta-i-ds-or-2-abta-1-iata-etc' }),
    format: formatMessage({ id: 'modal-import-instructions.accepted-values-abta-iata-tids' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.id-type-2-id' }),
    notes: formatMessage({ id: 'modal-import-instructions.optional-must-be-unique-for-each-record-in-the-file' }),
    format: formatMessage({ id: 'modal-import-instructions.if-id-type-2-abta-5-chars-alphanumeric-if-iata-or-tids-9-12-chars-alphanumeric' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.id-type-3' }),
    notes: formatMessage({ id: 'modal-import-instructions.optional-only-one-id-of-each-type-a-company-can-have-one-abta-one-iata-and-one-tids-id-but-not-3-abta-i-ds-or-2-abta-1-iata-etc' }),
    format: formatMessage({ id: 'modal-import-instructions.accepted-values-abta-iata-tids' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.id-type-3-id' }),
    notes: formatMessage({ id: 'modal-import-instructions.optional-must-be-unique-for-each-record-in-the-file' }),
    format: formatMessage({ id: 'modal-import-instructions.if-id-type-3-abta-5-chars-alphanumeric-if-iata-or-tids-9-12-chars-alphanumeric' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.entity-address' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory' }),
    format: formatMessage({ id: 'modal-import-instructions.alphanumeric-permitted-values-as-per-allowed-characters-dev' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.entity-city' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory' }),
    format: formatMessage({ id: 'modal-import-instructions.alphanumeric-permitted-values-as-per-allowed-characters-dev' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.entity-country' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory' }),
    format: formatMessage({ id: 'modal-import-instructions.alphanumeric-one-of-the-alpha-2-alpha-3-or-numeric-codes-from-the-iso-3166' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.entity-administrator-contact-name' }),
    notes: formatMessage({ id: 'modal-import-instructions.optional-if-provided-an-invitation-will-be-sent-to-the-user-and-they-will-need-to-set-up-their-password-if-not-provided-a-company-profile-without-an-admin-user-will-be-created-if-not-provided-fields-14-15-become-mandatory-and-the-admin-user-of-the-company-in-these-fields-becomes-administrator-of-the-user-less-company-profile' }),
    format: formatMessage({ id: 'modal-import-instructions.250-char-max-alphanumeric-permitted-values-as-per-allowed-characters-dev' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.entity-administrator-email-address' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory-if-field-11-is-not-empty' }),
    format: formatMessage({ id: 'modal-import-instructions.alphanumeric-permitted-values-as-per-allowed-characters-dev-email-format-validation' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.own-commercial-links' }),
    notes: formatMessage({ id: 'modal-import-instructions.if-1-the-company-will-have-its-own-commercial-links-and-fields-16-17-and-18-become-mandatory-for-this-record-if-0-the-company-will-inherit-the-commercial-link-of-the-hq-company-and-fields-14-15-are-mandatory-for-this-record' }),
    format: formatMessage({ id: 'modal-import-instructions.boolean' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.hq-entity-id-type' }),
    notes: formatMessage({ id: 'modal-import-instructions.always-a-cross-reference-to-an-existing-hq-type-id' }),
    format: formatMessage({ id: 'modal-import-instructions.accepted-values-abta-iata-tids' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.hq-entity-id' }),
    notes: formatMessage({ id: 'modal-import-instructions.always-a-cross-reference-to-an-existing-hq-id-value' }),
    format: formatMessage({ id: 'modal-import-instructions.5-or-9-12-chars' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.ptx-contact-id' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory-if-13-1' }),
    format: formatMessage({ id: 'modal-import-instructions.ptx-contact-id-format' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.ptx-mandate-id' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory-if-13-1' }),
    format: formatMessage({ id: 'modal-import-instructions.ptx-mandate-id-format' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.default-payment-method' }),
    notes: formatMessage({ id: 'modal-import-instructions.mandatory-if-13-1' }),
    format: formatMessage({ id: 'modal-import-instructions.direct-debit-uk-for-all-records' }),
  },
  {
    title: formatMessage({ id: 'modal-import-instructions.abta-crm-id' }),
    notes: formatMessage({ id: 'modal-import-instructions.optional' }),
    format: '',
  }
]
export default fieldsCSV