import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    alignContent: 'flex-start'
  },
  section: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%'
  },
  dateTimeItem: {
    height: 50,
    margin: theme.spacing(1),
    minWidth: 100
  },
  card: {
    marginBottom: theme.spacing(1)
  },
}))
