import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MaterialAutocomplete } from 'components/MaterialAutocomplete'
import MTableCell from '@material-ui/core/TableCell'
import useDebounce from 'utils/debounceHook'

const DEFAULT_DEBOUNCE_TIME = 500

const AutocompleteCell = ({ onChange, ...autocompleteProps }) => {

  const [ textInputValue, setTextInputValue ] = useState('')

  const debouncedTextInputValue = useDebounce(textInputValue, autocompleteProps?.debounceTime ?? DEFAULT_DEBOUNCE_TIME)

  const handleTextChange = (ev) => {
    setTextInputValue(ev.target.value)
  }

  const handleTextClear = () => {
    setTextInputValue('')
    onChange({})
  }

  useEffect(() => {
    if (autocompleteProps?.onInputChange && debouncedTextInputValue) {
      autocompleteProps.onInputChange(debouncedTextInputValue)
    }
  }, [ autocompleteProps, debouncedTextInputValue ])

  return (
    <MTableCell align="center">
      <MaterialAutocomplete
        placeholder=""
        onSelect={onChange}
        { ...autocompleteProps }
        onInputChange={handleTextChange}
        onInputClear={handleTextClear}
      />
    </MTableCell>
  )
}

AutocompleteCell.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
}

export default AutocompleteCell
