import { useState, useEffect, useRef } from 'react'
import snakeCase from 'lodash/snakeCase'
import { isObject } from 'utils/functions'
import { saveAs } from 'file-saver'
import { TABLE_DATA_TYPE, TABLE_SELECT_TYPE, TABLE_FILTER_TYPE } from '@devexpress/dx-grid-core'
import isFunction from 'lodash/isFunction'
import { INITIAL_PAGE_SIZE } from './constants'
export const TABLE_SUMMARY_TYPE = Symbol('summary')

const ICON_WIDTH = 48
const COLUMN_PADDING = 6

export const DEFAULT_COLUMN_WIDTH_VALUES = {
  XS: 80,
  S: 100,
  M: 115,
  L: 130,
  XL: 150,
  XXL: 180,
}

export const onSave = (name) => (workbook) => {
  workbook.csv.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'text/csv;charset=utf-8' }), `${name}.csv`, { autoBom: true })
  })
}

export const renderTitle = (title, props) => {
  if (typeof title === 'function') {
    return title(props)
  }
  return title
}

// Misc

export const getEnabledSortingColumns = (columns) =>
  columns.map((column) => ({ columnName: column.name, sortingEnabled: !column.disableSorting }))

export const currencyFormatter = ({ value, currency }) =>
  (Number(value) / 100).toLocaleString('en-UK', {
    style: 'currency',
    currency: currency || 'GBP',
    currencyDisplay: 'symbol',
  })

export const getColumnsName = (columns) => columns.map((column) => column.name)

export const getColumnsInitialWidth = (columns) =>
  columns.map((column) => ({
    columnName: column.name,
    width: column.width || DEFAULT_COLUMN_WIDTH_VALUES.XL,
  }))

export const getColumnActionWidth = (numActions) => ICON_WIDTH * numActions + COLUMN_PADDING * 2

export const spreadIfObjectValue = (value, columnName) => {
  if (isObject(value[columnName])) {
    return { ...value[columnName] }
  }
  return value
}

export const getTableName = (title, name) => {
  if (typeof title === 'string') {
    return snakeCase(title)
  } else if (name) {
    return snakeCase(name)
  }
  return ''
}

const getDirectionPagination = (order) => {
  return order === 'ASC' ? 'gt' : 'lt'
}

// TODO: Optimize this hook
export const useManualPaginatingAndSorting = (data = [], onChange = () => {}, options) => {
  const prevPagesSortingColumnValue = useRef([])
  const [currentPage, setCurrentPage] = useState(options?.initialPage ?? 0)
  const [pageSize, setPageSize] = useState(options?.initialPageSize || INITIAL_PAGE_SIZE)
  const [sorting, setSorting] = useState(options?.keySorting ?? {})
  const [paginationQuery, setPaginationQuery] = useState({})

  const setCurrentPageValue = (nextPage) => {
    const prevPage = currentPage
    const paginationSorting = options?.keySorting ?? sorting
    const direction = getDirectionPagination(paginationSorting?.order)
    setCurrentPage(nextPage)
    if (direction) {
      const sortingColumnName = `${paginationSorting.orderBy}[${direction}]`
      let index = 0
      let sortingColumnValue
      if (nextPage > prevPage) {
        index = data.length - 1
        sortingColumnValue = data[index]?.[paginationSorting.orderBy]
        prevPagesSortingColumnValue.current[prevPage] = data[0]?.[paginationSorting.orderBy]
      } else if (nextPage < prevPage && nextPage > 0 && prevPagesSortingColumnValue.current[nextPage]) {
        sortingColumnValue = data[index]?.[prevPagesSortingColumnValue.current[nextPage]]
      } else {
        sortingColumnValue = undefined
      }
      setPaginationQuery({ [sortingColumnName]: sortingColumnValue })
    }
  }

  const setPageSizeValue = (value) => {
    setPageSize(value)
  }

  const setSortingValue = (value) => {
    if (!value) {
      return
    }
    const arrayValue = Array.isArray(value) ? value : [value]
    if (arrayValue.length) {
      const [sortedColumn] = arrayValue
      const { direction, columnName, order, orderBy } = sortedColumn
      if ((!direction && !order) || (!columnName && !orderBy)) {
        return
      }
      setCurrentPage(0)
      setSorting({ order: (direction || order).toUpperCase(), orderBy: columnName || orderBy })
    }
  }

  useEffect(() => {
    onChange({ currentPage, pageSize, sorting, paginationQuery })
    // onChange is left out of deps for safety reasons when developers don't memoize handlers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sorting, paginationQuery])

  return {
    currentPage: [currentPage, setCurrentPageValue],
    pageSize: [pageSize, setPageSizeValue],
    sorting: [sorting, setSortingValue],
  }
}

export const useColumnsWidth = (columns) => {
  const intitalColumnsWidth = useRef(getColumnsInitialWidth(columns))
  const [columnsWidth, _setColumnsWidth] = useState(intitalColumnsWidth.current)

  const setColumnsWidth = (localColumns) => {
    const updatedColumns = intitalColumnsWidth.current.map((column) => {
      const matchedColumn = localColumns.find((localColumn) => localColumn.columnName === column.columnName)
      if (matchedColumn) {
        return {
          ...column,
          ...matchedColumn,
        }
      }
      return column
    })
    _setColumnsWidth(updatedColumns)
  }

  return [columnsWidth, setColumnsWidth]
}

// Summary plugin helpers
export const SUMMARY_OPERATIONS = {
  SUM: 'SUM',
  COUNT: 'COUNT',
}
const hasSomeColumnNotEmpty = (columns) => columns.some((column) => column?.summary > 0)

export const tableHeaderRowsWithSummary = (headerRows, tableSummaryColumns, rowHeight) => {
  if (hasSomeColumnNotEmpty(tableSummaryColumns)) {
    return [...headerRows, { key: TABLE_SUMMARY_TYPE.toString(), type: TABLE_SUMMARY_TYPE, height: rowHeight }]
  }
  return headerRows
}

export const isSummaryTableRow = (tableRow) => tableRow.type === TABLE_SUMMARY_TYPE

export const isSummaryTableCell = (tableRow, tableColumn) => {
  return (
    tableRow.type === TABLE_SUMMARY_TYPE &&
    (tableColumn.type === TABLE_DATA_TYPE || tableColumn.type === TABLE_SELECT_TYPE)
  )
}

export const getCurrencyColumnsName = (columns) =>
  columns.filter((column) => column.type === 'currency').map((column) => column.name)
export const getDateColumnsName = (columns) =>
  columns.filter((column) => column.type === 'date').map((column) => column.name)
export const getDatetimeColumnsName = (columns) =>
  columns.filter((column) => column.type === 'datetime').map((column) => column.name)
const operations = {
  [SUMMARY_OPERATIONS.SUM]: (a, b) => a + b,
  [SUMMARY_OPERATIONS.COUNT]: (a) => a + 1,
}

export const matchColumnByComputedColumnsKey = (computedColumns, column) => {
  if (computedColumns) {
    return computedColumns?.find((computedColumn) => computedColumn?.key === column?.key)
  }
}

export const computeSummaryRows = (
  tableColumns,
  rows,
  rowChanges,
  selection,
  defaultOperation = SUMMARY_OPERATIONS.COUNT,
) =>
  tableColumns.map((tableColumn) => {
    const computedResult = rows
      .map((row) => {
        return {
          ...row,
          ...rowChanges?.[row?.id],
        }
      })
      .reduce((total, current) => {
        const operation =
          tableColumn.type === TABLE_SELECT_TYPE
            ? defaultOperation
            : Reflect.has(tableColumn?.column || {}, 'summary')
            ? tableColumn.column.summary
            : undefined

        if (!operation) {
          return undefined
        }

        if (selection?.length > 0 && !selection.includes(current.id)) {
          return total
        }

        return isFunction(tableColumn?.column?.summary)
          ? tableColumn.column.summary(total, current)
          : operations[operation](total, current)
      }, 0)
    return {
      ...tableColumn,
      summary: computedResult,
    }
  })

// Filtering plugin helpers
export const FILTER_TYPES = {
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  CURRENCY: 'CURRENCY',
  CUSTOM: 'CUSTOM',
  DATE: 'DATE',
  DEFAULT: 'DEFAULT',
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
  SELECT: 'SELECT',
  NUMBER: 'NUMBER',
}

const hasSomeColumnWithFilter = (columns) => columns.some((column) => Reflect.has(column, 'filter'))

export const tableHeaderRowsWithFilter = (headerRows, columns, rowHeight) => {
  if (hasSomeColumnWithFilter(columns)) {
    return [...headerRows, { key: TABLE_FILTER_TYPE.toString(), type: TABLE_FILTER_TYPE, height: rowHeight }]
  }
  return headerRows
}

export const isFilterTableCell = (tableRow, tableColumn, filterType) => {
  if (tableRow.type === TABLE_FILTER_TYPE && tableColumn.type === TABLE_DATA_TYPE) {
    if (filterType) {
      if (isObject(tableColumn?.column?.filter) && tableColumn?.column?.filter?.type === filterType) {
        return true
      }
    } else if (tableColumn?.column?.filter === true) {
      return true
    }
  }
  return false
}

export const customizeCell = (cell, row, column) => {
  if (column.type === 'currency') {
    // eslint-disable-next-line no-param-reassign
    cell.value = cell.value === 0 ? 0 : (cell.value / 100).toFixed(2)
    // eslint-disable-next-line no-param-reassign
    cell.numFmt = '#,##0.00'
  }
  if (column?.customizeCellToExport) {
    column.customizeCellToExport(cell, row, column)
  }
}

export const getExportCellValue = (column) =>
  Reflect.has(column, 'getCellValueToExport') ? column.getCellValueToExport : column.getCellValue

/**
 * checks if columns in hiddenColumns array have filtered defined
 * @param {string[]} hiddenColumns array of hidden columns
 * @param {object} filters currently defined filters
 */
export const areHiddenColumnsFilteredBy = (hiddenColumns = [], filters = {}) =>
  hiddenColumns.some((column) => filters[column])
