import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const performFetchUsers = async (urlParams = {}) => {
  const url = `${API_URL}/users`
  return httpFetch(url, { urlParams })
}

export const performFetchInvites = async (extraUrlParams = {}) => {
  const urlParams = { 'inviteStatus[in]': [ 'PENDING', 'FAILED' ], ...extraUrlParams }
  const url = `${API_URL}/invites`
  return httpFetch(url, { urlParams })
}

export const performNewInvite = async (data) => {
  const url = `${API_URL}/invites`
  return httpFetch(url, { body: JSON.stringify(data), method: 'POST', shouldThrowError: true })
}

export const performNewCompanyInvite = (data) => {
  const url = `${API_URL}/invites`
  return httpFetch(url, { body: JSON.stringify(data), method: 'POST', shouldThrowError: true })
}

export const performValidateBulkCompanyInvite = (file) => {
  const url = `${API_URL}/invites/validate`
  const body = new FormData()
  body.append('file', file)
  return httpFetch(url, { body, method: 'POST', shouldThrowError: true })
}

export const performNewBulkCompanyInvite = (file, data) => {
  const url = `${API_URL}/invites`
  const body = new FormData()
  body.append('file', file)
  const keys = Object.keys(data)
  keys.forEach(key => {
    body.append(key, data[key])
  })
  return httpFetch(url, { body, method: 'POST', shouldThrowError: true })
}

export const performResetMFAUser = async (email) => {
  const url = `${API_URL}/sessions/resetMFA`
  return httpFetch(url, { body: JSON.stringify({ email }), method: 'PATCH', shouldThrowError: true })
}

export const performDeactivateUser = async (id) => {
  const url = `${API_URL}/users/${id}`
  return httpFetch(url, { method: 'DELETE', shouldThrowError: true })
}

export const performReactivateUser = async (id) => {
  const url = `${API_URL}/users/${id}/reactivate`
  return httpFetch(url, { method: 'PATCH', shouldThrowError: true })
}
