import React from 'react'
import PropTypes from 'prop-types'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'

const RefreshButton = ({ onClick }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={onClick}
      startIcon={<RotateLeftIcon />}
      className={classes.root}
    >
      {formatMessage({ id: 'table-filters.refresh-table' })}
    </Button>
  )
}

RefreshButton.propTypes = {
  onClick: PropTypes.func
}

export default RefreshButton
