import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'
import AuthView from 'containers/AuthView'
import Grid from '@material-ui/core/Grid'
import { FormProvider, useForm } from 'react-hook-form'
import { formSchema, defaultValues } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import { CYCLE_START_STRATEGY, ROUTE_URL } from 'constants.js'
import SnackbarComponent from 'components/Snackbar'
import { useStyles } from './styles'
import SettlementScheduleCalendar from './components/SettlementScheduleCalendar'
import CollectionsTable from './components/CollectionsTable'
import {
  useSettlementCalendarNavigation,
  useGetPaymentProfiles,
  useGetSettlementSchedule,
  useSaveSettlementSchedule,
} from './hooks'
import { cronExpressionToScheduleFields } from 'utils/cronTransform'
import Button from 'components/Button'
import { getCronString, getCronStringMultiple } from './utils'
import SettlementScheduleForm from './components/SettlementScheduleForm'
import { SettlementScheduleProfile, isDailySettlementScheduleProfile, settlementScheduleProfileOptions } from '@travel-ledger/type-constants'
import { isNumber } from 'lodash'
import { useIntl } from 'react-intl'

const useEffectLoadedSettlementSchedule = (settlementSchedule, resetForm) => useEffect(() => {
  if (!settlementSchedule) {
    return
  }
  const profileOptions = settlementScheduleProfileOptions[settlementSchedule.profile]
  // since profile params are not allowed for daily profiles, rebuild it for the UI
  if (isDailySettlementScheduleProfile(settlementSchedule.profile)) {
    // eslint-disable-next-line no-param-reassign
    settlementSchedule.profileParams = {
      weekDay: profileOptions?.allowedCycleStartWeekDays,
      fundingPeriodInMinutes: profileOptions?.fundingPeriodInMinutes
    }
  }

  const {
    collectionDefault, 
    cycleStartFrequency, 
    cycleStartStrategy, 
    profileParams,
  } = settlementSchedule

  const collection = cronExpressionToScheduleFields(collectionDefault)
  const cycleStart = cronExpressionToScheduleFields(cycleStartFrequency)
  const lockInvoicesAsEarlyAsPossible = (
    cycleStartStrategy === CYCLE_START_STRATEGY.EARLIEST
  )
  const entityValues = Object.keys(defaultValues).reduce((values, key) => {
    switch (key) {
      case 'collectionDay':
        return { ...values, [key]: collection.week }
      case 'collectionTime':
        return { ...values, [key]: collection.time }
      case 'cycleStartTime':
        return { ...values, [key]: cycleStart.time }
      case 'lockInvoicesAsEarlyAsPossible':
        return { ...values, [key]: lockInvoicesAsEarlyAsPossible }
      case 'fundingPeriodInMinutes':
        return { ...values, [key]: isNumber(profileParams?.fundingPeriodInMinutes) 
          ? profileParams?.fundingPeriodInMinutes 
          : (profileOptions?.fundingPeriodInMinutes || 0)
        }
      default:
        return { ...values, [key]: settlementSchedule[key] }
    }
  }, {})
  resetForm(entityValues)
}, [settlementSchedule, resetForm])

const useEffectGlobalSpinner = (isLoading, setGlobalSpinner) => {
  useEffect(() => {
    setGlobalSpinner(isLoading)
  }, [isLoading, setGlobalSpinner])
}

const buildProfileParams = (profile, data) => {
  const weekDays = (data?.cycleStartDay || []).map(d => d.value)
  switch(profile) {
    case SettlementScheduleProfile.WeeklyCollectionWithWeeklySupplierSubmissions:
      return {
        weekDay: weekDays,
      }
    case SettlementScheduleProfile.WeeklyCollectionWithDailySupplierSubmissions:
    case SettlementScheduleProfile.Custom: 
      return {
        weekDay: weekDays,
        fundingPeriodInMinutes: data?.fundingPeriodInMinutes,
      }
    default:
      return null
  }
}

const SettlementScheduleEdit = ({ location, history, currUserCompany }) => {
  const { id: settlementScheduleId } = useParams()
  const { data: paymentProfiles, isSuccess } = useGetPaymentProfiles()
  const { data: settlementScheduleData } = useGetSettlementSchedule(settlementScheduleId)
  const settlementSchedule = settlementScheduleData?.data?.attributes
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const { formatMessage } = useIntl()
  const form = useForm({ resolver: joiResolver(formSchema), defaultValues })
  const { reset: resetForm } = form
  const {
    currentEventsMap,
    existingEventsMap,
    setUnsavedEventsMap,
    unsavedEventsMap,
    startFrom,
    onNavigate,
    onMonthsToDisplayChange,
    monthsToDisplay,
  } = useSettlementCalendarNavigation(settlementScheduleId, currUserCompany.timezone)
  const {
    isLoading,
    mutate: createOrUpdateSettlementSchedule,
    snackbar,
  } = useSaveSettlementSchedule(
    settlementScheduleId,
    existingEventsMap,
    unsavedEventsMap,
    (settlementScheduleSaved) => {
      const route = ROUTE_URL.settlementScheduleEdit.replace(':id?', settlementScheduleSaved.id)
      history.replace(route)
      setGlobalSpinner(false)
      if (settlementScheduleId) {
        history.push(ROUTE_URL.settlementSchedulesList)
      }
    }
  )

  useEffectLoadedSettlementSchedule(settlementSchedule, resetForm)
  useEffectGlobalSpinner(isLoading, setGlobalSpinner)

  const handleSettlementScheduleCalendarClick = (event) => {
    const { type, date, details } = event
    const add = () => {

      setUnsavedEventsMap(unsavedEvents => [
        ...unsavedEvents,
        { date, type, details, override: true },
      ])
    }
    const remove = () => {
      setUnsavedEventsMap(unsavedEvents =>
        unsavedEvents.filter(unsaved => (
          !(unsaved.type === type && unsaved.date.isSame(date))
        ))
      )
    }
    return { add, remove }
  }

  const onSubmit = (data) => {
    const {
      name,
      nwds,
      paymentProfileId,
      disbursementPaymentProfileId,
      collectionDay,
      collectionTime, // format: HH:mm
      cycleStartDay,
      cycleStartTime, // format: HH:mm
      lockInvoicesAsEarlyAsPossible,
      buyerReviewPeriodInHours,
      fundingPeriodInMinutes,
      forwardFundsPeriodInHours,
      excludeWeekend,
      excludeHolidays,
      profile,
    } = data

    const formData = {
      name,
      nwds,
      paymentProfileId: paymentProfileId,
      disbursementPaymentProfileId,
      holidayRegion: 'GB:ENG',
      cycleStartStrategy: lockInvoicesAsEarlyAsPossible
        ? CYCLE_START_STRATEGY.EARLIEST
        : CYCLE_START_STRATEGY.CLOSEST_TO_SETTLEMENT,
      forwardFundsPeriodInHours,
      excludeWeekend,
      excludeHolidays,
    }

    formData.profile = profile
    formData.profileParams = buildProfileParams(profile, data)

    // other profiles take it from a backend function
    if (profile === SettlementScheduleProfile.Custom) {
      formData.cycleStartFrequency = getCronStringMultiple(cycleStartDay?.map(d => d.value), cycleStartTime)
      formData.collectionFrequency = getCronString(collectionDay, collectionTime)
      formData.buyerReviewPeriodInHours = buyerReviewPeriodInHours
      formData.fundingPeriodInMinutes = fundingPeriodInMinutes
      formData.excludeWeekend = excludeWeekend
      formData.excludeHolidays = excludeHolidays
    }

    createOrUpdateSettlementSchedule(formData)
  }

  const classes = useStyles()

  return (
    <FormProvider {...form}>
      <AuthView location={location} title="Settlement Schedule">
        <Grid container spacing={3}>
          {/* use full width (12) when creating as there is no collections table */}
          <Grid container item xs={settlementSchedule ? 6 : 12}>
            <SettlementScheduleForm
              paymentProfilesData={paymentProfiles}
              isSuccess={isSuccess}
              isProcessingEntity={currUserCompany.isProcessingEntity}
            />
          </Grid>
          {settlementSchedule ? (
            <Grid container item xs={6}>
              <CollectionsTable
                currentEventsMap={currentEventsMap}
                unsavedEventsMap={unsavedEventsMap} />
            </Grid>
          ) : null}
        </Grid>
        {settlementSchedule ? (
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridItem}>
              <SettlementScheduleCalendar
                timezone={currUserCompany.timezone}
                existingEventsMap={existingEventsMap}
                currentEventsMap={currentEventsMap}
                unsavedEventsMap={unsavedEventsMap}
                setUnsavedEventsMap={setUnsavedEventsMap}
                monthsToDisplay={monthsToDisplay}
                onMonthsToDisplayChange={onMonthsToDisplayChange}
                startFrom={startFrom}
                onDateNavigate={onNavigate}
                onDayClick={handleSettlementScheduleCalendarClick}
              />
            </Grid>
          </Grid>
        ) : null}
        <Button
          color="success"
          aria-label="save"
          disabled={isLoading}
          marginTop
          onClick={form.handleSubmit(onSubmit)}
          icon="save"
          size="large"
        >
          {
            settlementScheduleId
              ? formatMessage({ id: 'table-filters.save' })
              : formatMessage({ id: 'table-filters.create' })
          }
        </Button>
        <SnackbarComponent status={snackbar.isOpened} error={snackbar.isError} text={snackbar.successOrErrorText} />
      </AuthView>
    </FormProvider>
  )
}

SettlementScheduleEdit.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default SettlementScheduleEdit
