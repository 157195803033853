import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { API_URL } from 'constants.js'
import { ACTIONS } from '../'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { get, post } from 'utils/api'
import { dismissErrorSnackBar, dismissSnackBar } from 'utils/generators'

export function* fetchRemittances({ payload }) {
  const url = `${API_URL}/remittances`

  try {
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: true
    })
    const response = yield call(get, url, payload)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
    if (response.errors) {
      yield put({
        type: ACTIONS.FETCH_REMITTANCES_FAILURE,
        payload: response.errors[0].detail
      })
    } else {
      yield put({
        type: ACTIONS.FETCH_REMITTANCES_SUCCESS,
        payload: response.data
      })
    }
  } catch (e) {
    console.error(e)
    yield put({
      type: AUTH_ACTIONS.SET_FETCHING,
      payload: false
    })
  }
}

function* createRemittance({ payload }) {
  const url = `${API_URL}/remittances`

  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })

    const postPayload = {
      data: {
        type: 'remittances',
        attributes: payload
      }
    }
    yield call(post, url, postPayload)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({ type: ACTIONS.CREATE_REMITTANCE_SUCCESS, payload: 'Remittance added.' })
    yield fork(dismissSnackBar, ACTIONS.CLOSE_SNACKBAR)

  } catch (e) {
    console.error(e)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({ type: ACTIONS.CREATE_REMITTANCE_FAILURE, payload: e.errors[0].title })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

function* watchFetchRemittances() {
  yield takeLatest(ACTIONS.FETCH_REMITTANCES, fetchRemittances)
}

function* watchCreateRemittance() {
  yield takeLatest(ACTIONS.CREATE_REMITTANCE, createRemittance)
}

export default function* rootSaga() {
  yield all([
    watchFetchRemittances(),
    watchCreateRemittance()
  ])
}
