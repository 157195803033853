import { all, call, put, takeLatest, fork } from 'redux-saga/effects'
import { ACTIONS } from 'store/tasks'
import { dismissSnackBar, dismissErrorSnackBar } from 'utils/generators'
import { performFetchTasks, performCreateTask, performUpdateTask, performRunTask, performDeleteTask } from '../requests'

export function* fetchTasks() {
  const { FETCH_TASKS_SUCCESS, FETCH_TASKS_ERROR, CLOSE_SNACKBAR } = ACTIONS
  try {
    const { data } = yield call(performFetchTasks)
    yield put({ type: FETCH_TASKS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FETCH_TASKS_ERROR })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* createTask({ payload }) {
  const { ADD_TASK_SUCCESS, ADD_TASK_ERROR, CLOSE_SNACKBAR } = ACTIONS
  try {
    yield call(performCreateTask, payload)
    yield put({
      type: ADD_TASK_SUCCESS
    })
    yield call(fetchTasks)
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: ADD_TASK_ERROR,
      payload: e.message
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* runTask({ payload }) {
  const { RUN_TASK_SUCCESS, RUN_TASK_ERROR, CLOSE_SNACKBAR } = ACTIONS
  try {
    yield call(performRunTask, payload)
    yield put({
      type: RUN_TASK_SUCCESS
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: RUN_TASK_ERROR,
      payload: e.message
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* updateTask({ payload }) {
  const { id, task } = payload
  const { UPDATE_TASK_SUCCESS, UPDATE_TASK_ERROR, CLOSE_SNACKBAR } = ACTIONS
  try {
    yield call(performUpdateTask, id, task)
    yield put({
      type: UPDATE_TASK_SUCCESS,
      payload: {
        id,
        ...task
      }
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: UPDATE_TASK_ERROR,
      payload: e.message
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* deleteTask({ payload }) {
  const { DELETE_TASK_SUCCESS, DELETE_TASK_ERROR, CLOSE_SNACKBAR } = ACTIONS
  try {
    yield call(performDeleteTask, payload)
    yield put({
      type: DELETE_TASK_SUCCESS,
      payload
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
  } catch (e) {
    yield put({
      type: DELETE_TASK_ERROR,
      payload: e.message
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export default function* rootSaga() {
  const { FETCH_TASKS, ADD_TASK, RUN_TASK, UPDATE_TASK, DELETE_TASK } = ACTIONS
  yield all([
    takeLatest(FETCH_TASKS, fetchTasks),
    takeLatest(ADD_TASK, createTask),
    takeLatest(RUN_TASK, runTask),
    takeLatest(UPDATE_TASK, updateTask),
    takeLatest(DELETE_TASK, deleteTask)
  ])
}
