import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  groupLabel: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  list: {
    paddingLeft: '4px',
  },
}))
