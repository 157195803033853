import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const Section = ({ children, title, ...props }) => (
  <Paper {...props}>
    {title && (<Typography component="h2" variant="h6" style={{ marginBottom: 10 }}>{title}</Typography>)}
    {children}
  </Paper>
)

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ])
}

export default Section
