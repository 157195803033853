import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const performGetConfigCompany = async (companyId) => {
  const url = `${API_URL}/companies/${companyId}`
  return httpFetch(url)
}

export const performGetDataDelivery = async () => {
  const url = `${API_URL}/data-delivery`
  return httpFetch(url)
}

export const performPatchConfigCompany = async (companyId, configData) => {
  const url = `${API_URL}/companies/${companyId}`
  return httpFetch(url, { body: JSON.stringify(configData), method: 'PATCH', shouldThrowError: true })
}
