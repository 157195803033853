import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Popover,
  TableCell
} from '@material-ui/core'
import { CalendarToday, Clear } from '@material-ui/icons'
import { DateRangePicker } from 'materialui-daterange-picker'
import { useIntl } from 'react-intl'
import { DATE_RANGES } from 'components/DataGrid/constants'

const stringifyDates = (formatDateTimeRange) => (dateRange) => {
  if (dateRange?.startDate) {
    return formatDateTimeRange(dateRange?.startDate, dateRange?.endDate)
  }
  return ''
}

const dateRanges = [
  DATE_RANGES.THIS_WEEK,
  DATE_RANGES.NEXT_WEEK,
  DATE_RANGES.THIS_MONTH,
  DATE_RANGES.NEXT_MONTH,
  DATE_RANGES.THIS_YEAR,
  DATE_RANGES.NEXT_YEAR
]

const DateCell = ({ value, onChange, predefinedRanges, disableClearButton = false }) => {
  const { formatDateTimeRange } = useIntl()
  const stringifyFormattedDates = stringifyDates(formatDateTimeRange)
  const dateRange = useRef(value)
  const [ anchorEl, setAnchorEl ] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClear = () => {
    onChange(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
    onChange(dateRange.current)
  }

  const isOpen = Boolean(anchorEl)

  return (
    <TableCell align="center">
      <FormControl>
        <Input
          id="standard-adornment-password"
          value={stringifyFormattedDates(value)}
          onClick={handleClick}
          endAdornment={!disableClearButton && value ?
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear datepicker"
                onClick={handleClear}
                onMouseDown={handleClear}
                size="small"
              >
                <Clear />
              </IconButton>
            </InputAdornment>
            : null
          }
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                aria-label="Open date range picker"
                onClick={handleClick}
                onMouseDown={handleClick}
                size="small"
              >
                <CalendarToday />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {
        isOpen &&
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <DateRangePicker
            open
            toggle={handleClose}
            initialDateRange={value}
            onChange={(newValue) => {
              // We want the endDate to be the end of day instead of the same hour of the startDate
              const { startDate, endDate } = newValue
              endDate.setHours(23,59,59,999)
              dateRange.current = { startDate, endDate }
              handleClose()
            }}
            definedRanges={predefinedRanges || dateRanges}
          />
        </Popover>
      }
    </TableCell>
  )
}

DateCell.propTypes = {
  onChange: PropTypes.func,
  predefinedRanges: PropTypes.array,
  value: PropTypes.object,
  disableClearButton: PropTypes.bool
}

export default DateCell
