import { connect } from 'react-redux'
import Component from './PaymentProfileConfiguration'
import { createPaymentProfile, patchPaymentProfile, fetchCurrPaymentProfile } from 'store/paymentProfiles'

export const mapState = ({ auth, paymentProfiles }) => ({
  currUserCompanyId: auth.companyId,
  currPaymentProfile: paymentProfiles.currPaymentProfile,
  snackbar: paymentProfiles.snackbar
})

export const mapDispatch = dispatch => ({
  fetchCurrPaymentProfile: data => dispatch(fetchCurrPaymentProfile(data)),
  patchPaymentProfile: data => dispatch(patchPaymentProfile(data)),
  createPaymentProfile: data => dispatch(createPaymentProfile(data))
})

export default connect(mapState, mapDispatch)(Component)
