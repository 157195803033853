import { connect } from 'react-redux'
import Component from './EntityProfile'
import { push } from 'connected-react-router'
import { patchCurrUserCompany } from 'store/companies'

export const mapState = ({ auth, companies }) => ({
  companies,
  currUserCompanyId: auth.companyId,
  currUserCompany: companies.currUserCompany,
  auth,
})

export const mapDispatch = (dispatch) => ({
  patchCurrUserCompany: (data) => dispatch(patchCurrUserCompany(data)),
  push: (data) => dispatch(push(data)),
})

export default connect(mapState, mapDispatch)(Component)
