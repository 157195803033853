import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import AuthView from 'containers/AuthView/connect'
import DataGrid from 'components/DataGrid'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import { DEFAULT_COLUMN_WIDTH_VALUES, FILTER_TYPES } from 'components/DataGrid/utils'
import { filterValuesToQueryParams, parseCompanies } from './utils'
import {
  initialFilterValues,
  CL_FILTERS_VALUES_TO_NAMES,
  SUBSCRIPTION_STATUS_OPTIONS,
  COMPANY_STATUS_OPTIONS,
  CL_ROLE_FILTERS_VALUES,
  DEFAULT_HIDDEN_COLUMNS,
} from './constants'
import { useGridTitleWithHelpPopup } from 'components/HelpPopup/HelpPopup'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL, FEATURE_TL_SUB_ID_ENABLED } from 'constants.js'
import ViewAsDropdown from './components/ViewAsDropdown/ViewAsDropdown'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'

const CompaniesList = ({ location, auth, currUserCompany }) => {
  const [filters, setFilters] = useState(initialFilterValues)
  const [viewAsCompanyId, setViewAsCompanyId] = useState(null)
  const queryParams = filterValuesToQueryParams(filters, viewAsCompanyId)
  const { formatMessage } = useIntl()

  // fetch only if some filter is filled plus the acceptedTos and/or viewAsCompanyId
  const shouldFetch =
    Object.keys(queryParams).filter((el) => ['acceptedTos', 'viewAsCompanyId[eq]'].indexOf(el) === -1).length > 0

  const { isLoading, data, refetch } = useQuery('companies', () => get(`${API_URL}/companies`, queryParams), {
    enabled: shouldFetch,
    retry: false,
  })

  const columns = [
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.company-name' }),
      name: 'companyName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.abta' }),
      name: 'abta',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.tids' }),
      name: 'tids',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.iata' }),
      name: 'iata',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS,
    },
    {
      title: formatMessage({ id: 'companies.email-id' }),
      name: 'email_id',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.country-name' }),
      name: 'countryName',
      filter: true,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.head-office' }),
      name: 'headOffice',
      filter: true,
    },
    {
      title: formatMessage({ id: 'companies.role' }),
      name: 'role',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(CL_ROLE_FILTERS_VALUES)
              .map(([k, v]) => [k, formatMessage({ id: `cl-role-filters-values.${kebabCase(v)}` })]),
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.is-current-company-seller' }),
      name: 'isCurrentCompanySeller',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(CL_FILTERS_VALUES_TO_NAMES)
              .map(([k, v]) => [k, formatMessage({ id: `cl-filters-values-to-names.${kebabCase(v)}` })])
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.is-current-company-buyer' }),
      name: 'isCurrentCompanyBuyer',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(CL_FILTERS_VALUES_TO_NAMES)
              .map(([k, v]) => [k, formatMessage({ id: `cl-filters-values-to-names.${kebabCase(v)}` })])
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL,
    },
    {
      title: formatMessage({ id: 'companies.is-current-company-pe' }),
      name: 'isCurrentCompanyPe',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(CL_FILTERS_VALUES_TO_NAMES)
              .map(([k, v]) => [k, formatMessage({ id: `cl-filters-values-to-names.${kebabCase(v)}` })])
          ),
        },
      },
      disabled: !currUserCompany?.isProcessingEntity,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL,
    },
    {
      title: formatMessage({ id: 'companies.company-status' }),
      name: 'status',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(COMPANY_STATUS_OPTIONS)
              .map(([k, v]) => [k, formatMessage({ id: `company-status-options.${kebabCase(v)}` })])
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'companies.subscription' }),
      name: 'subscription',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(SUBSCRIPTION_STATUS_OPTIONS)
              .map(([k, v]) => [k, formatMessage({ id: `subscription-status-options.${kebabCase(v)}` })])
          ),
        },
      },
      disabled: !currUserCompany?.isProcessingEntity,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    FEATURE_TL_SUB_ID_ENABLED
      ? {
          title: formatMessage({ id: 'companies.travel-ledger-subscriber-id' }),
          name: 'tl_s',
          filter: true,
          width: DEFAULT_COLUMN_WIDTH_VALUES.L,
        }
      : null,
  ].filter((identity) => identity) // remove possible nulls

  const companies = data && shouldFetch ? data.data : []

  useEffect(() => {
    shouldFetch && refetch()
  }, [filters, viewAsCompanyId])

  return (
    <AuthView location={location} auth={auth} title="Company List">
      <DataGrid
        virtualTable
        data={parseCompanies(companies, currUserCompany?.name)}
        name={'companies'}
        fullRemainingWindowHeight
        isLoading={isLoading}
        filtering={{
          defaultFilters: initialFilterValues,
          filters,
          onChange: (newValue) => {
            setFilters(newValue)
          },
          clearFilters: () => {
            setFilters(initialFilterValues)
          },
        }}
        {...(currUserCompany.isHeadOffice &&
          currUserCompany.branchCount > 0 && {
            title: <ViewAsDropdown viewAsCompanyId={viewAsCompanyId} setViewAsCompanyId={setViewAsCompanyId} />,
          })}
        columns={columns}
        totalNumberOfItems={companies.length}
        extra={<RefreshButton onClick={refetch} />}
        defaultHiddenColumns={DEFAULT_HIDDEN_COLUMNS}
        {...(!shouldFetch && {
          tableMessages: {
            noData: formatMessage({ id: 'companies.please-select-value-s-in-the-filters-to-display-data' }),
          },
        })}
      />
    </AuthView>
  )
}

CompaniesList.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(CompaniesList)
