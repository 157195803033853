import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  textLabel: {
    margin: theme.spacing(1)
  },
  treeView: {
    flexGrow: 1,
    maxWidth: 400,
  },
  treeViewItem: {
    marginLeft: -10,
    paddingLeft: 18,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
  leftArrow: {
    color: 'green',
    marginLeft: 10
  }
}))
