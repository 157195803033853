import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'
import { patch, post } from '../utils/api'

export const getCompanies = async (urlParams) => {
  const url = `${API_URL}/companies`
  const { data } = await httpFetch(url, {
    urlParams,
    shouldThrowError: true,
  })
  return data
}

export const getBranchCompanies = async (urlParams) => {
  const url = `${API_URL}/companies/associations`
  const { data } = await httpFetch(url, {
    urlParams,
    shouldThrowError: true,
  })
  data.attributes.id = data.id
  return data.attributes
}

export const updateCompany = async ({ id, ...body }) => {
  return patch(`/companies/${id}`, body)
}

export const createCompany = async (company) => {
  return post(`/companies`, { company })
}

export const suspendCompany = async (id) => {
  return patch(`/companies/${id}/suspend`)
}

export const reactivateCompany = async (id) => {
  return patch(`/companies/${id}/reactivate`)
}

export const postImportCSVCompanies = async (files = []) => {
  const url = `${API_URL}/companies`
  const body = new FormData()
  files.forEach((file, index) => {
    body.append(`file_${index}`, file)
  })
  const result = await httpFetch(url, {
    body,
    method: 'POST',
    shouldThrowError: true,
  })
  return result
}
