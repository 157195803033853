import React from 'react'
import PropTypes from 'prop-types'
import { currencyFormatter } from 'components/DataGrid/utils'

const AmountCell = React.memo(({ amount, currency }) => {
  return currencyFormatter({ value: amount, currency })
})

AmountCell.displayName = 'OriginalAmountCell'

AmountCell.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  currency: PropTypes.string
}

export default AmountCell
