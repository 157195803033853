import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(() => ({
    dropdownOption: {
        paddingLeft: 30,
        "&.Mui-selected": {
            position: "relative",
            "&:before": {
                top: '5px',
                left: '5px',
                width: '20px',
                bottom: '5px',
                content: "'check'",
                position: 'absolute',
                fontFamily: 'Material Icons',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        }
    },
    dropdownAllOption: {
        paddingBottom: '7px',
        display: 'flex',
        justifyContent: 'center',
    },
    chip: {
        paddingLeft: 5,
        paddingRight: 5
    }
}))