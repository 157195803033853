import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    margin: theme.spacing(0, 1),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    action: {
      alignItems: 'flex-start',
    },
  },
}))
