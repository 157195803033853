import React, { useState, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Logs from './Logs'
import { useStyles } from './styles'

import ConfirmDialog from 'components/ConfirmDialog'
import { DateCell } from 'components/DataGrid/components/DataTypeCells'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const SimulationsLogs = ({ simulations, onSimulationDelete }) => {
  const [ selectedIndex, setSelectedIndex ] = useState(0)
  const [ confirmOpen, setConfirmOpen ] = useState(false)
  const [ idToDelete, setIdToDelete ] = useState()
  const [ simulation, setSimulation ] = useState({})

  const handleChange = newValue => () => {
    if (simulations.length) {
      setSimulation(simulations[newValue])
      setSelectedIndex(newValue)
    } else {
      setSimulation({})
    }
  }

  const handleDelete = id => event => {
    event.preventDefault()
    setConfirmOpen(true)
    setIdToDelete(id)
  }

  const handleConfirmedDelete = () => {
    onSimulationDelete(idToDelete)
    setIdToDelete(undefined)
  }

  useEffect(() => {
    handleChange(0)(null)
  }, [ simulations ])

  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid className={classes.logs} item xs={2}>
            <List dense>
              {simulations.map((_simulation, index) => (
                <ListItem
                  key={_simulation.id}
                  selected={selectedIndex === index}
                  onClick={handleChange(index)}
                  className={classes.listItem}
                >
                  <ListItemText
                    primary={_simulation.name}
                    secondary={<DateCell hasTime={true} value={_simulation.createdAt} />}
                    secondaryTypographyProps={{ className: classes.listItemSecondaryText }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={handleDelete(_simulation.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <ConfirmDialog
              title="Delete simulation?"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={handleConfirmedDelete}
            >
              Are you sure you want to delete this simulation?
            </ConfirmDialog>
          </Grid>
          <Grid className={classes.logs} item xs={10}>
            { Object.keys(simulation).length ? (
              <Logs logs={simulation.logs} />
            ) : null }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

SimulationsLogs.propTypes = {
  simulations: PropTypes.array,
  onSimulationDelete: PropTypes.func
}

export default memo(SimulationsLogs)
