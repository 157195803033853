import React from 'react'
import PropTypes from 'prop-types'
import AuthView from 'containers/AuthView'
import CompanyProfile from './components/CompanyProfile'

const EntityProfile = ({ location, companies, auth }) => {
  const { currUserCompany } = companies

  return (
    <AuthView location={location}>
      <CompanyProfile currUserCompany={currUserCompany} auth={auth} />
    </AuthView>
  )
}

EntityProfile.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
  companies: PropTypes.object,
}

export default EntityProfile
