import React from 'react'
import PropTypes from 'prop-types'
import {
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector
} from '@devexpress/dx-react-core'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../styles'
import { renderTitle } from '../utils'

const ToolbarTitle = ({ children }) => {
  const classes = useStyles()
  return (
    <Plugin name="ToolbarTitle" dependencies={[]}>
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {(_, { changeColumnFilter }) => {
            return (
              <Typography variant="h6" className={classes.title}>{renderTitle(children, { changeColumnFilter })}</Typography>
            )
          }}
        </TemplateConnector>
      </Template>
    </Plugin>
  )
}

ToolbarTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default ToolbarTitle
