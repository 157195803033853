import React, { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'
import RowDetail from './RowDetail'
import { memoizingComparison } from 'utils/functions'
import { FILTER_TYPES } from 'components/DataGrid/utils'
import { DATE_RANGES } from 'components/DataGrid/constants'
import { NOTIFICATION_MODULES, NOTIFICATION_TYPES } from 'constants.js'
import { Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'

const MessageText = ({ message }) => {
  const classes = useStyles()
  const isFormatted = message.includes('<br')
  return <Typography 
    className={isFormatted ? classes.messageTextNew : classes.messageText} 
    dangerouslySetInnerHTML={{ __html: message}} />
}

MessageText.propTypes = {
  message: PropTypes.string
}

const columns = (formatMessage) => [
  {
    title: formatMessage({ id: 'notifications.date' }),
    name: 'createdAt',
    type: 'datetime',
    filter: {
      type: FILTER_TYPES.DATE,
      predefinedRanges: [
        DATE_RANGES.THIS_WEEK,
        DATE_RANGES.THIS_MONTH,
        DATE_RANGES.THIS_YEAR
      ]
    } },
  {
    title: formatMessage({ id: 'notifications.type' }),
    name: 'type',
    filter: {
      type: FILTER_TYPES.MULTIPLE_SELECT,
      inputProps: {
        options: Object.fromEntries(
          Object.entries(NOTIFICATION_TYPES)
            .map(([k, v]) => [k, formatMessage({ id: `notification-types.${kebabCase(v)}` })])
        ),
      }
    }
  },
  {
    title: formatMessage({ id: 'notifications.module' }),
    name: 'module',
    filter: {
      type: FILTER_TYPES.MULTIPLE_SELECT,
      inputProps: {
        options: Object.fromEntries(
          Object.entries(NOTIFICATION_MODULES)
            .map(([k, v]) => [k, formatMessage({ id: `notification-modules.${kebabCase(v)}` })])
        ),
      }
    }
  },
  {
    title: formatMessage({ id: 'notifications.message' }),
    name: 'message',
    filter: true,
    width: 600,
    getCellValue: (row) => <MessageText message={row.message} />,
    getCellValueToExport: (row) => row.message
  }
]

const NotificationsTable = forwardRef(({
  data,
  onCheck,
  onPageChanged,
  totalNumberOfItems,
  ...props
}, ref) => {
  const { formatMessage } = useIntl()

  return (
    <DataGrid
      name="notifications"
      height={500}
      columns={columns(formatMessage)}
      data={data}
      totalNumberOfItems={totalNumberOfItems}
      ref={ref}
      disableRow={row => row.isDismissed}
      selectable={{
        onChange: onCheck,
        isDisabled: ({ row }) => row.isDismissed
      }}
      fullRemainingWindowHeight
      RowDetail={RowDetail}
      pagination={{
        totalNumberOfItems,
        onPageChanged
      }}
      sort={{
        keySorting: {
          orderBy: 'createdAt',
          order: 'DESC'
        }
      }}
      {...props}
    />
  )
})

NotificationsTable.displayName = 'NotificationsTable'

NotificationsTable.propTypes = {
  data: PropTypes.array.isRequired,
  onCheck: PropTypes.func,
  onPageChanged: PropTypes.func,
  totalNumberOfItems: PropTypes.number
}

export default memo(NotificationsTable, memoizingComparison)
