import { BSD_CALENDAR_EVENTS } from 'constants.js'
import { FORCED_TYPE_FOR, SCHEDULED_TYPE_FOR, SKIP_TYPE_FOR } from '../constants'

const removeEvent = (currentDateEvents, type, date) => {
  const index = currentDateEvents.findIndex(event => (
    event.type === type && event.date.isSame(date)
  ))

  if (index === -1) {
    return false
  }

  currentDateEvents.splice(index, 1)
  return true
}

const applyUnSkipEvent = (currentDateEvents, unSkipEvent) => {
  const skipType = SKIP_TYPE_FOR[unSkipEvent.type]

  if (removeEvent(currentDateEvents, skipType, unSkipEvent.date)) {
    return
  }
  console.warn('no skip event to remove', unSkipEvent)
}

const applyUnsavedSkipEvent = (currentDateEvents, skipEvent) => {
  if (removeEvent( // first try to skip a forced
    currentDateEvents, FORCED_TYPE_FOR[skipEvent.type], skipEvent.date,
  )) {
    return
  }

  if (removeEvent( // try to skip a scheduled instead
    currentDateEvents, SCHEDULED_TYPE_FOR[skipEvent.type], skipEvent.date,
  )) {
    // skipped a scheduled event, add the skip to represent it
    currentDateEvents.push(skipEvent)
    return
  }

  // we could not find anything to skip, we'll ignore the skip
  console.warn('ignored skip, can\'t find what is being skipped', skipEvent)
}

const applyRescheduleForcedEvent = (currentDateEvents, rescheduleForcedEvent) => {
  // remove the existing forced event. the date fields are
  // different so instead of using the removeEvent method,
  // we can search by ID instead (and no need to check type)
  const changedEvent = currentDateEvents.find(
    event => event.details.id === rescheduleForcedEvent.details.id
  )

  if (!changedEvent) {
    console.warn('ignored move, can\'t find the event being rescheduled', { rescheduleForcedEvent })
    return
  }

  // we just mutate it
  changedEvent.date = rescheduleForcedEvent.date
}

const applyExistingSkipEvent = (currentDateEvents, existingSkipEvent) => {
  // existing skips only apply to scheduled events
  const scheduledType = SCHEDULED_TYPE_FOR[existingSkipEvent.type]

  // we keep the skip, either because we skipped a scheduled
  // event and we want to represent that, or because it
  // did not skip anything, and we keep the existing skip
  // event so it can be un-skipped (to remove the stray skip)
  removeEvent(currentDateEvents, scheduledType, existingSkipEvent.date)
}

const splitUnsavedDayEvents = unsavedDayEvents => {
  const unsavedUnSkips = []
  const unsavedSkips = []
  const unsavedForces = []
  const unsavedForceReschedules = []

  for (const event of unsavedDayEvents) {
    switch (event.type) {
      case BSD_CALENDAR_EVENTS.SKIP_COLLECTION:
      case BSD_CALENDAR_EVENTS.SKIP_CYCLE_START:
        unsavedSkips.push(event)
        continue
      case BSD_CALENDAR_EVENTS.FORCE_COLLECTION:
      case BSD_CALENDAR_EVENTS.FORCE_CYCLE_START:
        unsavedForces.push(event)
        continue
      case BSD_CALENDAR_EVENTS.UN_SKIP_COLLECTION:
      case BSD_CALENDAR_EVENTS.UN_SKIP_CYCLE_START:
        unsavedUnSkips.push(event)
        continue
      case BSD_CALENDAR_EVENTS.RESCHEDULE_FORCE_COLLECTION:
      case BSD_CALENDAR_EVENTS.RESCHEDULE_FORCE_CYCLE_START:
        unsavedForceReschedules.push(event)
        continue
      default:
        console.error('unhandled unsaved event, ignoring', event)
    }
  }

  return { unsavedUnSkips, unsavedSkips, unsavedForces, unsavedForceReschedules }
}

export const unionDayEvents = (existingDayEvents, unsavedDayEvents) => {
  if (!unsavedDayEvents.length && !existingDayEvents.length) {
    return []
  }

  const currentDateEvents = [...existingDayEvents]
  const {
    unsavedUnSkips, unsavedSkips, unsavedForces, unsavedForceReschedules,
  } = splitUnsavedDayEvents(unsavedDayEvents)

  // first apply unsaved un-skips to remove existing skips
  unsavedUnSkips.forEach(unSkipEvent =>
    applyUnSkipEvent(currentDateEvents, unSkipEvent)
  )

  // now we can apply the existing skips (this gets rid of
  // the scheduled events that are skipped, otherwise we'd
  // see both events as they are both in 'existing')
  currentDateEvents.filter(event =>
    event.type === BSD_CALENDAR_EVENTS.SKIP_CYCLE_START ||
    event.type === BSD_CALENDAR_EVENTS.SKIP_COLLECTION
  ).forEach(existingSkipEvent => {
    applyExistingSkipEvent(currentDateEvents, existingSkipEvent)
  })

  // lastly apply the other unsaved events
  // (skips, forced time changes, and new forced events)
  unsavedSkips.forEach(skipEvent =>
    applyUnsavedSkipEvent(currentDateEvents, skipEvent)
  )
  unsavedForceReschedules.forEach(rescheduleForcedEvent =>
    applyRescheduleForcedEvent(currentDateEvents, rescheduleForcedEvent)
  )
  currentDateEvents.push(...unsavedForces)

  return currentDateEvents
}

export const unionEventMaps = (existingEventsMap, unsavedEventsMap) => {
  const currentEvents = {}
  const datesWithEvents = new Set(
    Object.keys(existingEventsMap).concat(
      Object.keys(unsavedEventsMap)
    )
  )

  for (const dateKey of datesWithEvents) {
    const existing = existingEventsMap[dateKey] ?? []
    const unsaved = unsavedEventsMap[dateKey] ?? []
    const union = unionDayEvents(existing, unsaved)

    if (union.length) {
      currentEvents[dateKey] = union
    }
  }

  return currentEvents
}