import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'
import PasswordStrengthInput from 'components/PasswordStrengthInput'

const ReactHookFormMuiPasswordField = ({ form, required = true, name, nameConfirm,...props }) => {
  const _nameConfirm = nameConfirm ?? `${name}Confirm`

  const {
    getValues, setError, clearErrors, setValue, control, formState: { errors },
  } = form

  const checkPasswordEquality = ({ passwordValue, confirmPasswordValue }) => {
    const _passwordValue = passwordValue ?? getValues(name)
    const _confirmPasswordValue = confirmPasswordValue ?? getValues(_nameConfirm)
    if (_passwordValue !== _confirmPasswordValue) {
      setError(_nameConfirm, { type: 'notEqual', message: 'Password doesn`t match' })
    } else {
      clearErrors(_nameConfirm)
    }
  }

  const handlePasswordChange = (ev) => {
    const { valid, value } = ev.target
    if ((value.length === 0 && required && !valid) || (value.length > 0 && !valid)) {
      setError(name, { type: 'notValid', message: 'Invalid password' })
    } else {
      clearErrors(name)
      checkPasswordEquality({ passwordValue: value })
    }
    setValue(name, value)
  }

  const handlePasswordConfirmationChange = (ev) => {
    const { value } = ev.target
    checkPasswordEquality({ confirmPasswordValue: value })
    setValue(_nameConfirm, value)
  }

  return (
    <>
      <Controller
        name={name}
        control={control}
        // eslint-disable-next-line no-unused-vars
        render={({ field: { ref, ...field } }) => (
          <PasswordStrengthInput
            {...field}
            onChange={handlePasswordChange}
            fullWidth
            id="password"
            label="Password"
            autoComplete="password"
            error={errors.password}
            {...props}
          />
        )}
      />
      <Controller
        name={_nameConfirm}
        control={control}
        // eslint-disable-next-line no-unused-vars
        render={({ field: { ref, ...field }}) => (
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            {...field}
            onChange={handlePasswordConfirmationChange}
            error={!!errors?.[_nameConfirm]}
            helperText={errors?.[_nameConfirm]?.message}
            {...props}
          />
        )}
      />
    </>
  )
}

ReactHookFormMuiPasswordField.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nameConfirm: PropTypes.string,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
}

export default ReactHookFormMuiPasswordField
