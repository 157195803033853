import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import UnderlinedCell from '../UnderlinedCell/UnderlinedCell'
import { useIntl } from 'react-intl'
import { INVOICE_TAGS } from 'constants.js'

const TAG_TOOLTIP_INFO = {
  [INVOICE_TAGS.PENDING_COMMERCIAL_LINK]: {
    messageIdSuffix: 'pending',
    showRed: false, // yellow
  },
  
  [INVOICE_TAGS.SUSPENDED_COMMERCIAL_LINK]: {
    messageIdSuffix: 'suspended',
    showRed: false, // yellow
  },
  [INVOICE_TAGS.ARCHIVED_COMMERCIAL_LINK]: {
    messageIdSuffix: 'archived',
    showRed: true,
  },
}
const TAGS_WITH_TOOLTIP = Object
  .keys(TAG_TOOLTIP_INFO)

/**
 * @param {import('constants.js').InvoiceTag[]} tags 
 * @param {boolean} isImport 
 * @returns {null | {
 *  messageId: string
 *  showRed: boolean
 * }}
 */
const getTooltipInfo = (tags, isImport) => {
  const tag = tags.find(t =>
    TAGS_WITH_TOOLTIP.includes(t),
  )

  if (!tag) {
    return null
  }

  const importOrReview = isImport ? 'import' : 'review'
  const tooltipInfo = TAG_TOOLTIP_INFO[tag]

  return {
    messageId: `help.booking-${importOrReview}.commercial-link-${tooltipInfo.messageIdSuffix}`,
    showRed: tooltipInfo.showRed,
  }
}

const CommercialLinkDisplayNameCell = memo(
  /**
   * @param {{
   *  displayName: string
   *  tags: []
   *  isImport: boolean
   * }} param0 
   */
  ({
  displayName, tags, isImport
}) => {
  const { formatMessage } = useIntl()

  const tooltipInfo = getTooltipInfo(
    tags, isImport
  )

  if (!tooltipInfo) {
    return displayName
  }

  const title = formatMessage({
    id: tooltipInfo.messageId,
  })

  return <Tooltip title={title}>
    <UnderlinedCell
      error={tooltipInfo.showRed}
      warning={!tooltipInfo.showRed}
    >
      <span>{displayName}</span>
    </UnderlinedCell>
  </Tooltip>
})

CommercialLinkDisplayNameCell.displayName = 'CommercialLinkDisplayNameCell'

CommercialLinkDisplayNameCell.propTypes = {
  displayName: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  isImport: PropTypes.bool,
}

export default CommercialLinkDisplayNameCell
