import { fullName } from 'utils/functions'

// Action Names
const INVITE_USER = fullName('admin', 'INVITE_USER')

export const ACTIONS = {
  INVITE_USER
}

export const inviteUser = userData => ({
  type: INVITE_USER,
  payload: userData
})
