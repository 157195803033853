import React, { useContext, useEffect } from 'react'

import SnackbarComponent from 'components/Snackbar'
import AuthView from 'containers/AuthView/connect'
import { BreadcrumbMenuTransactions } from 'containers/BreadcrumbMenu'
import PropTypes from 'prop-types'
import InstantPaymentsTable from './components/InstantPaymentsTable'
import { BreadcrumbContext } from 'containers/App/components/BreadcrumbContext'
import { ROUTE_URL } from 'constants.js'

const InstantPayments = ({ location, snackbar, redirectTo, currUserCompanyId }) => {
  const breadcrumbContext = useContext(BreadcrumbContext)

  useEffect(() => {
    if (
      currUserCompanyId &&
      !breadcrumbContext.isLoading &&
      !breadcrumbContext.visibility.instantPayments
    ) {
      redirectTo(ROUTE_URL.bookingsReview)
    }
  }, [
    currUserCompanyId,
    breadcrumbContext,
    breadcrumbContext.isLoading,
    breadcrumbContext.visibility.instantPayments,
    redirectTo,
  ])

  if (
    currUserCompanyId &&
    !breadcrumbContext.isLoading &&
    !breadcrumbContext.visibility.instantPayments
  ) return null

  return (
    <AuthView location={location} title="Transactions">
      <BreadcrumbMenuTransactions />
      <InstantPaymentsTable />
      <SnackbarComponent status={snackbar.isOpened} error={snackbar.isError} text={snackbar.successOrErrorText} />
    </AuthView>
  )
}

InstantPayments.propTypes = {
  location: PropTypes.object,
  snackbar: PropTypes.object,
}

export default InstantPayments
