import { makeStyles } from '@material-ui/core'

export const DRAWER_WIDTH = 220

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  list: {
    padding: 0
  },
  authName: {
    color: theme.palette.text.primary,
    fontWeight: '700'
  },
  companyName: {
    color: theme.palette.text.primary,
  },
  logoContainer: {
    background: theme.palette.primary.main,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  version: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    opacity: 0.5,
    fontSize: theme.typography.caption.fontSize,
    alignSelf: 'end',
    marginBottom: theme.spacing(1)
  },
  logo: {
    cursor: 'pointer',
    float: 'left',
    height: 40
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    justifyContent: 'space-between',
    background: theme.palette.secondary.main
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    width: '100%',
    overflowX: 'auto',
    flexGrow: 1,
    background: theme.palette.background.default,
    boxSizing: 'border-box',
    minHeight: '100vh',
    padding: theme.spacing(2)
  },
  drawerItemHome: {
    background: theme.palette.secondary.main
  },
  drawerItemHomeActive: {
    background: theme.palette.primary.background
  },
  logout: ({isProduction}) => ({
    color: theme.palette.error.main,
    marginBottom: theme.spacing(!isProduction ? 5 : 2)
  }),
  section: {
    fontSize: '0.85rem'
  }
}))
