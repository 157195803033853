import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ACTIONS } from 'store/countries'
import { performFetchCountries } from '../requests'
import { sortByHighOrderCountries } from 'utils/functions'

export function* fetchCountries() {
  const { FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_ERROR } = ACTIONS
  try {
    let { data } = yield call(performFetchCountries)
    data = [ ...data ].sort(sortByHighOrderCountries)
    yield put({ type: FETCH_COUNTRIES_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FETCH_COUNTRIES_ERROR })
  }
}

export default function* rootSaga() {
  const { FETCH_COUNTRIES } = ACTIONS
  yield all([
    takeLatest(FETCH_COUNTRIES, fetchCountries)
  ])
}
