import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import PasswordStrengthInput from 'components/PasswordStrengthInput'
import SnackbarComponent from 'components/Snackbar'

const NOT_MATCH = 'Password doesn`t match'

const ResetPassword = ({
  snackbar,
  postResetPassword
}) => {
  const [ form, setForm ] = useState({ password: undefined, confirmPassword: '' })
  const [ passwordMatchError, setPasswordMatchError ] = React.useState()

  const handleChange = e => {
    const { name, value } = e.target
    setForm(_form => ({
      ..._form,
      [name]: value
    }))
  }

  useEffect(() => {
    if (form.password !== form.confirmPassword) {
      return setPasswordMatchError(NOT_MATCH)
    }
    setPasswordMatchError()
  }, [ form ])

  const handleSubmit = e => {
    e.preventDefault()
    postResetPassword(form.password)
  }

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <SnackbarComponent
        status={snackbar.isOpened}
        error={snackbar.isError}
        text={snackbar.successOrErrorText}
      />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Please setup and confirm your new password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <PasswordStrengthInput
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="password"
            label="New Password"
            name="password"
            autoComplete="password"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            autoComplete="password"
            onChange={handleChange}
            type="password"
            error={!!passwordMatchError}
            helperText={passwordMatchError}
          />
          <Button
            disabled={!!passwordMatchError}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Confirm
          </Button>
        </form>
      </div>
    </Container>
  )
}

ResetPassword.defaultProps = {
  postResetPassword: () => {
  }
}

ResetPassword.propTypes = {
  snackbar: PropTypes.object,
  postResetPassword: PropTypes.func.isRequired
}

export default ResetPassword
