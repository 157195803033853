import { SettlementScheduleProfile, ReviewEndStrategy } from '@travel-ledger/type-constants'
import Joi from 'joi'
import { timePattern } from 'utils/cronTransform'

export const formSchema = Joi.object({
  name: Joi.string().required(),
  paymentProfileId: Joi.string().required(),
  disbursementPaymentProfileId: Joi.string().required(),
  nwds: Joi.array().required(),
  collectionDay: Joi.number().required(),
  collectionTime: Joi.string().pattern(timePattern).required(),
  cycleStartDay: Joi.array().items(Joi.object({
    value: Joi.number(),
    label: Joi.string(),
  })).min(1).required(),
  cycleStartTime: Joi.string().pattern(timePattern).required(),
  lockInvoicesAsEarlyAsPossible: Joi.boolean().optional(),
  buyerReviewPeriodInHours: Joi.number().min(0).required(),
  fundingPeriodInMinutes: Joi.number().min(0).required(),
  forwardFundsPeriodInHours: Joi.number().min(0).required(),
  excludeWeekend: Joi.boolean().required(),
  excludeHolidays: Joi.boolean().required(),
  profile: Joi.allow(...Object.values(SettlementScheduleProfile)).required(),
  cycleStartStrategy: Joi.optional().allow(...Object.values(ReviewEndStrategy)),
  profileParams: Joi.any().optional().allow(null),
})

export const defaultValues = {
  name: '',
  paymentProfileId: '',
  disbursementPaymentProfileId: '',
  nwds: [0, 6],
  collectionDay: 1,
  collectionTime: '12:00',
  cycleStartDay: [],
  cycleStartTime: '12:00',
  lockInvoicesAsEarlyAsPossible: false,
  buyerReviewPeriodInHours: 72,
  fundingPeriodInMinutes: 0,
  forwardFundsPeriodInHours: 72,
  excludeWeekend: false,
  excludeHolidays: false,
  profile: SettlementScheduleProfile.Custom,
  profileParams: null,
}
