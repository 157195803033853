import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PublishIcon from '@material-ui/icons/Publish'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { downloadRemittances, downloadInvoices } from 'services/paymentsStatementApi'

const SummaryExportButton = ({ buttonRef, companySettlementSummaryId: summaryId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportRemittances = async () => {
    await downloadRemittances(summaryId)
    handleClose()
  };
  const handleExportInvoices = async () => {
    await downloadInvoices(summaryId)
    handleClose()
  };
  // ! TODO
  // const handleExportTransactions = async () => {
  //   await downloadTransactions(summaryId)
  //   handleClose()
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleClick}
        style={{ borderRadius: '5px' }}
        startIcon={<PublishIcon />}
      >
        Export
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleExportRemittances}>Remittances</MenuItem>
        <MenuItem onClick={handleExportInvoices}>Invoices</MenuItem>
      </Menu>
    </div>
  )
}

SummaryExportButton.propTypes = {
  buttonRef: PropTypes.func,
  companySettlementSummaryId: PropTypes.string
}

export default SummaryExportButton
