import { createStore, applyMiddleware, compose } from 'redux'
import * as Sentry from '@sentry/react'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger'
import createRootReducer from './reducers'
import rootSaga from './sagas'

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
let composeEnhancers = compose
const sentryReduxEnhancer = Sentry.createReduxEnhancer({})
const middlewares = [ sagaMiddleware ]
// Build the middleware for intercepting and dispatching navigation actions
middlewares.push(routerMiddleware(history))

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true })
  middlewares.push(logger)
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
)
sagaMiddleware.run(rootSaga)

const config = {
  store,
  history
}

export default config
