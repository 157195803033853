import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Template, TemplatePlaceholder, Plugin, TemplateConnector
} from '@devexpress/dx-react-core'
import { ResetFiltersButton } from '../components/ActionButtons'

const ResetFilterPanel = ({
  onClick
}) => {
  const handleClickResetFilter = (actionClearFilters) => {
    actionClearFilters()
    onClick()
  }

  return (
    <Plugin
      name="ResetFilterPanel"
      dependencies={[
        { name: 'Toolbar' },
        { name: 'FilteringState' }
      ]}
    >
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <TemplateConnector>
          {(_, { clearFilters }) => (
            <ResetFiltersButton onClick={() => handleClickResetFilter(clearFilters)} />
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  )
}

ResetFilterPanel.propTypes = {
  onClick: PropTypes.func
}

export default ResetFilterPanel
