import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { useStyles } from './styles'
import { Box, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import ReactHookFormMuiTextField from 'components/ReactHookFormMuiTextField'
import HelpPopup from 'components/HelpPopup'
import { useIntl } from 'react-intl'

const formatEmailsWhitelist = (emails) => (emails || []).join('; ')

const DataDeliveryEmail = ({ onChangeConfig, companyConfig }) => {
  const classes = useStyles()
  const defaultValues = {
    dataDeliveryEmailsWhitelist: formatEmailsWhitelist(companyConfig?.dataDeliveryEmailsWhitelist)
  }
  const { formatMessage } = useIntl()
  const { handleSubmit, formState: { errors }, reset, control } = useForm({ resolver: joiResolver(formSchema), defaultValues })

  useEffect(() => {
    reset({ dataDeliveryEmailsWhitelist: formatEmailsWhitelist(companyConfig?.dataDeliveryEmailsWhitelist) })
  }, [companyConfig, reset])

  const onSubmit = handleSubmit((data) => {
    onChangeConfig('dataDeliveryEmailsWhitelist', data.dataDeliveryEmailsWhitelist)
  })

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <FormControlLabel
          className={classes.root}
          control={<Switch className={classes.switch} onChange={(e) => onChangeConfig(e.target.name, e.target.checked)} checked={!!companyConfig?.emailDataDeliveryActive} name="emailDataDeliveryActive" />}
          label={<HelpPopup
            id="data-delivery.emails-switcher"
            values={{ email: process.env.REACT_APP_DATA_DELIVERY_EMAIL, br: <br /> }}
          >
            {formatMessage({ id: 'data-delivery.enable-email-delivery-for-automated-delivery-email' })}
          </HelpPopup>}
        />
      </Grid>
      {!!companyConfig?.emailDataDeliveryActive && (
        <Grid container>
          <form autoComplete="off" onSubmit={onSubmit} style={{ width: '100%' }}>
            <Grid item xs={12}>
              <ReactHookFormMuiTextField
                label={<HelpPopup id="data-delivery.emails-whitelist" values={{ br: <br /> }}>{formatMessage({ id: 'data-delivery.additional-emails-for-data-delivery' })}</HelpPopup>}
                control={control}
                name="dataDeliveryEmailsWhitelist"
                error={errors?.dataDeliveryEmailsWhitelist}
                fullWidth={true}
                value={defaultValues.dataDeliveryEmailsWhitelist}
                placeholder="alice@yourcompany.com; bob@yourcompany.com"
                InputLabelProps={{ shrink: true }}
              />

            </Grid>
            <Grid item xs={12}>
              <Box marginTop={2}>
                <Button
                  disabled={!!Object.keys(errors).length}
                  variant="contained"
                  type="submit"
                  size="large"
                  color="success"
                  aria-label="save"
                  icon="save"
                >
                  {formatMessage({ id: 'data-delivery.save-additional-emails' })}
                </Button>
              </Box>
            </Grid>
          </form>
        </Grid>
      )}
    </Grid>
  )
}

DataDeliveryEmail.propTypes = {
  companyConfig: PropTypes.object,
  onChangeConfig: PropTypes.func
}

export default DataDeliveryEmail
