import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { LANGUAGE_CULTURE_NAMES } from '@travel-ledger/type-constants'

export default function LanguageConfirmationDialog({
  open,
  onClose,
  onConfirm,
  language,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Box pb={2}>
          Click confirm if you want to change your language to <code>{
            LANGUAGE_CULTURE_NAMES[language]
          }</code>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="default"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
