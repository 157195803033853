import { STATUSES } from 'constants.js'

export const initialFilterValues = {
  importedAt: '',
  status: [ STATUSES.MATCHED, STATUSES.FILE_DUPLICATE, STATUSES.TRANS_DUPLICATE, STATUSES.NA ],
  commercialLinkName: ''
}

export const DEFAULT_HIDDEN_COLUMNS = [
  'sellerInvoiceNo', 
  'buyerBookingRef',
]
