import { fullName } from 'utils/functions'
const FETCH_CURR_PAYMENT_PROFILE = fullName('fetch-curr-payment', 'FETCH_CURR_PAYMENT_PROFILE')
const FETCH_CURR_PAYMENT_PROFILE_SUCCESS = fullName('fetch-curr-payment-success', 'FETCH_CURR_PAYMENT_PROFILE_SUCCESS')
const FETCH_CURR_PAYMENT_PROFILE_ERROR = fullName('fetch-curr-payment-error', 'FETCH_CURR_PAYMENT_PROFILE_ERROR')
const POST_PAYMENT_PROFILE = fullName('post-payment', 'POST_PAYMENT_PROFILE')
const POST_PAYMENT_PROFILE_SUCCESS = fullName('post-payment-success', 'POST_PAYMENT_PROFILE_SUCCESS')
const PATCH_PAYMENT_PROFILE = fullName('patch-payment-profile', 'PATCH_PAYMENT_PROFILE')
const PATCH_PAYMENT_PROFILE_SUCCESS = fullName('patch-payment-profile-success', 'PATCH_PAYMENT_PROFILE_SUCCESS')
const PATCH_PAYMENT_PROFILE_ERROR = fullName('patch-payment-profile-error', 'PATCH_PAYMENT_PROFILE_ERROR')
const FETCH_PAYMENT_PROFILES = fullName('fetch-payment', 'FETCH_PAYMENT_PROFILES')
const PAYMENT_PROFILES_SUCCESS = fullName('payment-success', 'PAYMENT_PROFILES_SUCCESS')
const PAYMENT_PROFILES_ERROR = fullName('payment-error', 'PAYMENT_PROFILES_ERROR')
const CLOSE_SNACKBAR = fullName('close-error-payment', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_CURR_PAYMENT_PROFILE,
  FETCH_CURR_PAYMENT_PROFILE_SUCCESS,
  FETCH_CURR_PAYMENT_PROFILE_ERROR,
  POST_PAYMENT_PROFILE,
  POST_PAYMENT_PROFILE_SUCCESS,
  PATCH_PAYMENT_PROFILE,
  PATCH_PAYMENT_PROFILE_SUCCESS,
  PATCH_PAYMENT_PROFILE_ERROR,
  PAYMENT_PROFILES_SUCCESS,
  PAYMENT_PROFILES_ERROR,
  FETCH_PAYMENT_PROFILES,
  CLOSE_SNACKBAR
}

export const createPaymentProfile = payload => ({
  type: POST_PAYMENT_PROFILE,
  payload
})

export const patchPaymentProfile = payload => ({
  type: PATCH_PAYMENT_PROFILE,
  payload
})

export const fetchCurrPaymentProfile = payload => ({
  type: FETCH_CURR_PAYMENT_PROFILE,
  payload
})

export const fetchPaymentProfiles = payload => ({
  type: FETCH_PAYMENT_PROFILES,
  payload
})

const initialState = {
  currPaymentProfile: null,
  payment: [],
  snackbars: false,
  error: false,
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

export const paymentProfiles = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURR_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        currPaymentProfile: {
          id: action.payload.data.id,
          ...action.payload.data.attributes
        }
      }
    case FETCH_CURR_PAYMENT_PROFILE_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case POST_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: action.payload.snackbar
        }
      }
    case PAYMENT_PROFILES_SUCCESS:
      return {
        ...state,
        payment: action.payload.data
      }
    case PATCH_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        currPaymentProfile: {
          id: action.payload.data.id,
          ...action.payload.data.attributes
        },
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: action.payload.snackbar
        }
      }
    case PATCH_PAYMENT_PROFILE_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case PAYMENT_PROFILES_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    default:
      return state
  }
}
