import { _contains } from 'utils/filters'

export const filterUsers = (users, filters) => {
  return users.filter((user) => {
    if (
      filters.isDisabled &&
      filters.isDisabled.length &&
      !filters.isDisabled.find((filtered) => filtered === user.isDeleted.toString())
    ) {
      return false
    }

    if (filters.role && filters.role.length && !filters.role.find((filtered) => filtered === user.role)) {
      return false
    }
    if (filters.name && !_contains(filters.name, user.name)) {
      return false
    }
    if (filters.email && !_contains(filters.email, user.email)) {
      return false
    }
    return true
  })
}
