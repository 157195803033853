import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { NotInterested } from '@material-ui/icons'

const ErrorFallback = () => (
  <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={5} height="100vh">
    <NotInterested fontSize="large" style={{ height: 50, width: 50 }} />
    <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
      Something unexpected happened
    </Typography>
    <Typography gutterBottom>
      We have recorded this error and we will be right on it
    </Typography>
    <Button
      style={{
        backgroundColor: '#043943',
        marginTop: 40
      }}
      variant="contained"
      color="secondary"
      onClick={() => {
        window.location.href = '/'
      }}
    >
      Reload the app
    </Button>
  </Box>
)

export default ErrorFallback
