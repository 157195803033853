/* eslint-disable react/display-name */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'

const TransactionsTable = ({
  data,
  columns,
  renderToolBarChildren,
  detailPanel,
  toolbarActions,
  onPageChanged,
  totalNumberOfItems,
  sort,
  ...props
}) => {
  return (
    <DataGrid
      virtualTable
      title={props.title}
      columns={columns}
      summaryToolbar={renderToolBarChildren}
      data={data}
      toolbarActions={toolbarActions}
      RowDetail={({ row }) => detailPanel(row)}
      fullRemainingWindowHeight
      sort={{
        keySorting: {
          orderBy: sort?.columnName || 'createdAt',
          order: sort?.direction || 'DESC'
        }
      }}
      pagination={{
        onPageChanged,
        totalNumberOfItems
      }}
      { ...props }
    />
  )
}

TransactionsTable.propTypes = {
  title: PropTypes.string.isRequired,
  renderToolBarChildren: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  showSearch: PropTypes.bool,
  editable: PropTypes.object,
  actions: PropTypes.array,
  toolbarActions: PropTypes.array,
  detailPanel: PropTypes.oneOfType([ PropTypes.func, PropTypes.array ]),
  onPageChanged: PropTypes.func,
  totalNumberOfItems: PropTypes.number
}

export default memo(TransactionsTable)
