import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TimeZoneInput from 'components/TimeZoneInput'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { useStyles } from './styles'
import ReactHookFormMuiTextField from 'components/ReactHookFormMuiTextField'
import ReactHookFormMuiCheckbox from 'components/ReactHookFormMuiCheckbox'
import CountrySelectInput from 'containers/CountrySelectInput'
import { useForm, Controller } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { formSchema } from './formValidationSchema'
import ReactHookFormCompanyIds from 'components/ReactHookFormCompanyIds'
import Button from 'components/Button'
import { COMPANY_STATUS, USER_ROLES } from 'constants.js'
import { Typography } from '@material-ui/core'
import { suspendCompany, reactivateCompany } from 'services/companiesApi'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import { useMutation } from 'react-query'
import ConfirmDialog from 'components/ConfirmDialog'
import { filterOutReadOnlyCompanyCodes } from 'containers/Companies/utils'
import { mapFormKeys } from 'utils/functions';
import { useIntl } from 'react-intl'

const defaultValues = {
  name: '',
  address: '',
  city: '',
  telephone: '',
  website: '',
  countryId: '',
  timezone: '',
  codes: {},
  inheritCommercialLink: true,
  centrallyManaged: true,
  inheritWallet: true,
}

const CompanyForm = ({ mutate, refetch, currUserCompany, company, createMode, showBranchSettings }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { formatMessage } = useIntl()

  const onError = (error) => {
    toast.error(getReadableErrorMessage(error))
  }

  const { mutate: suspendCompanyMutation } = useMutation(suspendCompany, {
    onSuccess: () => {
      toast.success(formatMessage({ id: 'company-form.company-suspended' }))
      refetch()
    },
    onError,
  })
  const { mutate: reactivateCompanyMutation } = useMutation(reactivateCompany, {
    onSuccess: () => {
      toast.success(formatMessage({ id: 'company-form.company-reactivated' }))
      refetch()
    },
    onError,
  })

  const { handleSubmit, formState: { errors }, control, reset } = useForm({ resolver: joiResolver(formSchema), defaultValues })
  const classes = useStyles()
  const textFieldProps = {
    fullWidth: true,
    className: classes.textField,
    margin: 'normal',
  }

  useEffect(() => {
    if (company) {
      reset(mapFormKeys(company, defaultValues))
    } else {
      reset(defaultValues)
    }
  }, [company, reset])

  const handleStatusChange = () => {
    if (company.status === COMPANY_STATUS.LIVE) {
      suspendCompanyMutation(company.id)
    }
    if (company.status === COMPANY_STATUS.SUSPENDED) {
      reactivateCompanyMutation(company.id)
    }
  }

  const onSubmit = handleSubmit((data) => {
    const body = createMode ? data : { id: company.id, ...data }
    mutate(body)
  })

  // For now don't use the Read Only codes
  // eslint-disable-next-line no-param-reassign
  if (company?.codes) {
    // eslint-disable-next-line no-param-reassign
    company.codes = filterOutReadOnlyCompanyCodes(company.codes)
  }

  return (
    <>
      <form autoComplete="off" onSubmit={onSubmit}>
        <Card className={classes.topPaper}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} className={classes.fieldSet}>
                <ReactHookFormMuiTextField
                  label={formatMessage({ id: 'company-form.company-name' })}
                  control={control}
                  name="name"
                  required
                  error={errors?.name}
                  {...textFieldProps}
                />
                <Controller
                  name="timezone"
                  control={control}
                  // eslint-disable-next-line no-unused-vars
                  render={({ field: { ref, ...field } }) => (
                    <TimeZoneInput
                      {...textFieldProps}
                      {...field}
                      defaultValue=""
                      label={formatMessage({ id: 'company-form.timezone' })}
                      fullWidth
                      className={classes.textField}
                      error={!!errors?.timezone}
                      helperText={errors?.timezone?.message}
                    />
                  )}
                />
                <ReactHookFormMuiTextField
                  label={formatMessage({ id: 'company-form.company-telephone' })}
                  control={control}
                  name="telephone"
                  error={errors?.telephone}
                  {...textFieldProps}
                />
                <Controller
                  name="countryId"
                  control={control}
                  // eslint-disable-next-line no-unused-vars
                  render={({ field: { ref, ...field } }) => (
                    <CountrySelectInput
                      {...field}
                      defaultValue=""
                      label={formatMessage({ id: 'company-form.country' })}
                      fullWidth
                      required
                      margin="dense"
                      className={classes.textField}
                      error={!!errors?.countryId}
                      helperText={errors?.countryId?.message}
                    />
                  )}
                />
              </Grid>
              <Grid container xs={12} sm={6} md={4}>
                <Grid item xs={12}>
                  <ReactHookFormMuiTextField
                    label={formatMessage({ id: 'company-form.company-address' })}
                    control={control}
                    name="address"
                    required
                    error={errors?.address}
                    {...textFieldProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormMuiTextField
                    label={formatMessage({ id: 'company-form.company-city' })}
                    control={control}
                    name="city"
                    required
                    error={errors?.city}
                    {...textFieldProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormMuiTextField
                    label={formatMessage({ id: 'company-form.company-website' })}
                    control={control}
                    name="website"
                    error={errors?.website}
                    {...textFieldProps}
                  />
                </Grid>
                {!!company && (
                  <Grid item xs={12}>
                    <Typography>{formatMessage({ id: 'company-form.users' })}: {company.usersCount ? company.usersCount : formatMessage({ id: 'company-form.no' })}</Typography>
                  </Grid>
                )}
                {showBranchSettings && (
                  <>
                    <Grid item xs={12}>
                      <ReactHookFormMuiCheckbox
                        control={control}
                        name="inheritCommercialLink"
                        label={formatMessage({ id: 'company-form.inherit-commercial-links' })}
                        defaultValue={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReactHookFormMuiCheckbox
                        control={control}
                        name="centrallyManaged"
                        label={formatMessage({ id: 'company-form.centrally-managed' })}
                        defaultValue={false}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ReactHookFormMuiCheckbox
                        control={control}
                        name="inheritWallet"
                        label={formatMessage({ id: 'company-form.inherit-wallet' })}
                        defaultValue={false}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReactHookFormCompanyIds label={formatMessage({ id: 'company-form.company-id' }) + ' *'} name="codes" control={control} error={errors?.codes} />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid
              container
              direction="row"
              className={classes.buttonsContainer}
              alignItems="center"
            >
              <Button
                disabled={!!Object.keys(errors).length}
                variant="extended"
                type="submit"
                size="large"
                color="success"
                aria-label="add"
                icon="save"
                onClick={onSubmit}
                className={classes.fabMargin}
              >
                {formatMessage({ id: 'company-form.save' })}
              </Button>
              {company && currUserCompany.role !== USER_ROLES.NORMAL &&
                <Button onClick={() => setConfirmOpen(true)}>
                  {company.status === COMPANY_STATUS.LIVE &&
                    formatMessage({ id: 'company-form.suspend-branch' })
                  }
                  {company.status === COMPANY_STATUS.SUSPENDED &&
                    formatMessage({ id: 'company-form.reactivate-branch' })
                  }
                </Button>
              }
            </Grid>
          </CardActions>
        </Card>
      </form>
      {company && <ConfirmDialog
        title={formatMessage({ id: 'company-form.update-company-status' })}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleStatusChange}
      >
        {company.status === COMPANY_STATUS.LIVE &&
          formatMessage({ id: 'company-form.you-are-about-to-suspend-this-company-all-branches-and-pending-transactions-do-you-want-to-proceed' })
        }
        {company.status === COMPANY_STATUS.SUSPENDED &&
          formatMessage({ id: 'company-form.you-are-about-to-reactivate-this-company-and-all-branches-do-you-want-to-proceed' })
        }
      </ConfirmDialog>}
    </>
  )
}

CompanyForm.propTypes = {
  mutate: PropTypes.func,
  currUserCompany: PropTypes.object,
  company: PropTypes.object,
  createMode: PropTypes.bool,
  showBranchSettings: PropTypes.bool,
}

export default CompanyForm
