import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import AuthView from 'containers/AuthView/connect'
import DataGrid from 'components/DataGrid'
import Button from 'components/Button'
import { ROUTE_URL, USER_ROLES } from 'constants.js'
import { dateToReadableDateTime, utcToTimezone } from 'utils/date'
import { withConnect } from './connect'
import { useIntl } from 'react-intl'

const PaymentProfileList = ({ location, isLoading, redirectTo, paymentProfiles, fetchPaymentProfiles, userRole, currUserCompany }) => {
  const { formatMessage } = useIntl()

  const columns = [
    { name: 'name', title: formatMessage({ id: 'payment-profile.name' }) },
    { name: 'platform', title: formatMessage({ id: 'payment-profile.payment-platform' }) },
    {
      title: formatMessage({ id: 'payment-profile.updated-by' }),
      name: 'updatedBy',
      getCellValue: row => row?.updatedBy?.name
    },
    {
      title: formatMessage({ id: 'payment-profile.updated-at' }),
      name: 'updatedAt',
      getCellValue: row => utcToTimezone(row?.updatedAt, currUserCompany.timezone),
      getCellValueToExport: row => dateToReadableDateTime(utcToTimezone(row.updatedAt, currUserCompany.timezone)),
      type: 'datetime'
    }
  ]

  const canCreate = [ USER_ROLES.SUPERADMIN, USER_ROLES.ADMIN ].includes(userRole)
  React.useEffect(() => {
    fetchPaymentProfiles()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddPaymentProfile = () => {
    redirectTo(ROUTE_URL.paymentProfileConfiguration)
  }

  const handleEditPaymentProfile = (selection) => {
    redirectTo({ pathname: ROUTE_URL.paymentProfileConfiguration, state: { id: selection } })
  }

  return (
    <AuthView location={location} title="Payment Profiles">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DataGrid
            name="payment_profiles"
            options={{
              search: false,
              exportButton: false
            }}
            fullRemainingWindowHeight
            selectable={{
              actions: [
                {
                  title: formatMessage({ id: 'payment-profile.edit' }),
                  icon: 'edit',
                  onClick: handleEditPaymentProfile,
                  validate: (selection) => {
                    if (selection?.length === 0) {
                      throw new Error('You must select a payment profile')
                    }
                    if (selection?.length !== 1) {
                      throw new Error('You must select only one payment profile')
                    }
                    // const selectedPaymentProfile = paymentProfiles.find(paymentProfile => paymentProfile.id === selection[0])
                    // if (!selectedPaymentProfile.canEdit) {
                    //   throw new Error('You don\'t have enough permissions to edit this payment profile')
                    // }
                    return true
                  }
                }
              ]
            }}
            extra={canCreate && <Button icon="add" size="small" color="primary" onClick={handleAddPaymentProfile}>
              {formatMessage({ id: 'table-filters.create' })}
            </Button>}
            columns={columns}
            data={paymentProfiles}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </AuthView>
  )
}

PaymentProfileList.propTypes = {
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  paymentProfiles: PropTypes.array,
  fetchPaymentProfiles: PropTypes.func,
  redirectTo: PropTypes.func,
  userRole: PropTypes.string,
  currUserCompany: PropTypes.object
}

export default withConnect(PaymentProfileList)
