import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  strengthMeter: {
    position: 'relative',
    height: '3px',
    background: '#ddd',
    margin: '7px 0;',
    borderRadius: '2px',
    display: 'flex',
    '&::before': {
      content: '""',
      height: 'inherit',
      background: 'transparent',
      display: 'block',
      borderColor: '#fff',
      borderStyle: 'solid',
      borderWidth: '0 6px 0',
      position: 'absolute',
      width: 'calc(20% - 6px)',
      zIndex: '10',
      left: 'calc(20% - 6px)'
    },
    '&::after': {
      content: '""',
      height: 'inherit',
      background: 'transparent',
      display: 'block',
      borderColor: '#fff',
      borderStyle: 'solid',
      borderWidth: '0 6px 0',
      position: 'absolute',
      width: 'calc(20% - 6px)',
      zIndex: '10',
      right: 'calc(20% - 6px)'
    }
  },
  strengthMeterFill: {
    background: 'transparent',
    height: 'inherit',
    position: 'absolute',
    width: '0',
    borderRadius: 'inherit',
    transition: 'width 0.5s ease-in-out, background 0.25s',
    '&.strengthMeterFill0': {
      width: '20%',
      background: 'darkred'
    },
    '&.strengthMeterFill1': {
      width: '40%',
      background: 'orangered'
    },
    '&.strengthMeterFill2': {
      width: '60%',
      background: 'orange'
    },
    '&.strengthMeterFill3': {
      width: '80%',
      background: 'yellowgreen'
    },
    '&.strengthMeterFill4': {
      width: '100%',
      background: 'green'
    }
  },
  helperText: {
    marginTop: theme.spacing(1)
  }
}))
