import moment from 'moment'
import { DEFAULT_DATE_FORMAT } from 'constants.js'

export const _contains = (needle, haystack) => needle ? String(haystack || '').toLowerCase().includes(needle.toLowerCase()) : false

export const _equalsDate = (dateToCheck, dateToMatch) => moment(dateToCheck, DEFAULT_DATE_FORMAT).startOf('day').isSame(moment(dateToMatch, DEFAULT_DATE_FORMAT).startOf('day'))

export const _betweenDates = (dateToCheck, from, to) => moment(dateToCheck, DEFAULT_DATE_FORMAT).isBetween(moment(from, DEFAULT_DATE_FORMAT).startOf('day').subtract(1, 'd'), moment(to, DEFAULT_DATE_FORMAT).startOf('day').add(1, 'd'), 'days')

export const convertQueryToNum = num => Number((parseFloat(num, 10) * 100).toFixed(0))
