import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  topPaper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  fieldsMargin: {
    paddingRight: '15px',
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px'
  },
  formLabel: {
    marginTop: theme.spacing(2)
  },
  formGroupLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formInput: {
    width: '100%',
    marginBottom: '8px',
    marginTop: '16px'
  },
  frequencyInput: {
    marginBottom: '8px',
    marginTop: '16px',
    flex: 1,
    '&:first-child': {
      paddingRight: theme.spacing(.5)
    }
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)'
  }
}))
