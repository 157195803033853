import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import ReactHookFormMuiTextField from 'components/ReactHookFormMuiTextField'
import ReactHookFormMuiCheckbox from 'components/ReactHookFormMuiCheckbox'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { useStyles } from './styles'
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
import Button from 'components/Button'
import { useIntl } from 'react-intl'

const SimulationForm = ({
  commercialLinks = [],
  onSubmitData = () => {},
  onDateChanges = () => {},
  disabledSubmit,
  reactHookForm
}) => {
  const { control, handleSubmit, watch, getValues, errors } = reactHookForm
  const commercialLinksToFailIds = useMemo(() => commercialLinks.filter(commLink => commLink.checked && commLink.shouldFailPayment === true).map((commLink) => commLink.id), [ commercialLinks ])
  const commercialLinksIds = useMemo(() => commercialLinks.filter(commLink => commLink.checked && commLink.shouldFailPayment !== true).map((commLink) => commLink.id), [ commercialLinks ])
  const { formatMessage } = useIntl()

  useEffect(() => {
    const simulationDate = getValues('simulationDate')
    onDateChanges(simulationDate)
  }, [ watch('simulationDate') ])

  const onSubmit = handleSubmit((data) => {
    const utcSimulationDate = data.simulationDate
    const postData = { ...data, simulationDate: utcSimulationDate, commercialLinkIds: commercialLinksIds, commercialLinksToFail: commercialLinksToFailIds }
    onSubmitData(postData)
  })

  const classes = useStyles()
  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardContent>
          { commercialLinksToFailIds?.length ? (
            <Alert className={classes.warningBox} severity="warning">
              {formatMessage({ id: 'simulation.commercial-link-invoices-may-be-settled-in-the-same-transaction-thus-failure-of-one-will-fail-all-payments-for-commercial-links-that-might-be-aggregated' })}
            </Alert>
          ) : null }
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item xs={3}>
              <ReactHookFormMuiTextField
                label={formatMessage({ id: 'simulation.simulation-ref' })}
                control={control}
                name="name"
                placeholder="Type simulation ref"
                error={errors?.name}
                fullWidth
              />
            </Grid>
            <Grid container item xs={4} wrap="nowrap" alignItems="baseline">
              <ReactHookFormMuiTextField
                control={control}
                label={formatMessage({ id: 'simulation.run-one-off-collection-on' })}
                name="simulationDate"
                InputLabelProps={{
                  shrink: true
                }}
                type="datetime-local"
                error={errors?.simulationDate}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                disabled={disabledSubmit}
                onClick={onSubmit}
                size="small"
                color="primary"
                aria-label="add"
                marginTop
                marginBottom
              >
                {formatMessage({ id: 'simulation.create-collection-simulation' })}
              </Button>
              { (commercialLinksIds?.length === 0 && commercialLinksToFailIds?.length === 0) ? (
                <Typography variant="caption" component="p" color="error">
                  {formatMessage({ id: 'simulation.please-select-commercial-links-for-simulation' })}
                </Typography>
              ) : null }
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item xs={4}>
              <ReactHookFormMuiCheckbox control={control} name="remainCollected" label={formatMessage({ id: 'simulation.mark-simulation-invoices-as-collected' })} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}

SimulationForm.propTypes = {
  commercialLinks: PropTypes.array,
  onSubmitData: PropTypes.func,
  onDateChanges: PropTypes.func,
  disabledSubmit: PropTypes.bool,
  reactHookForm: PropTypes.shape({
    control: PropTypes.object,
    handleSubmit: PropTypes.func,
    watch: PropTypes.func,
    getValues: PropTypes.func,
    errors: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ])
  })
}

export default SimulationForm
