import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const GlobalSpinnerContext = createContext()
export const GlobalSpinnerActionsContext = createContext()

const GlobalSpinnerContextProvider = ({ children }) => {
  const [ isGlobalSpinnerOn, setGlobalSpinner ] = useState(false)

  return (
    <GlobalSpinnerContext.Provider value={isGlobalSpinnerOn}>
      <GlobalSpinnerActionsContext.Provider value={setGlobalSpinner}>
        {children}
      </GlobalSpinnerActionsContext.Provider>
    </GlobalSpinnerContext.Provider>
  )
}

GlobalSpinnerContextProvider.propTypes = {
  children: PropTypes.node
}

export default GlobalSpinnerContextProvider
