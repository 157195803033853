import React, { memo } from 'react'
import PropTypes from 'prop-types'
import UnderlinedCell from '../UnderlinedCell/UnderlinedCell'
import Tooltip from '@material-ui/core/Tooltip'
import { STATUSES } from 'constants.js'

const DescriptionCell = memo(({ description, status }) => {
  let text, helpText
  if (status === STATUSES.FILE_DUPLICATE) {
    text = 'Check File/Delete'
    helpText = 'Duplicate transaction in file for the same Buyer/Seller/Due date/Transaction type/Booking Ref/Currency. Unable to Load'
  } else if (status === STATUSES.TRANS_DUPLICATE) {
    text = 'Check Bookings/Delete'
    helpText = 'Multiple Duplicate transactions exist on platform for the same Buyer/Seller/Due date/Transaction type/Booking Ref/Currency. Unable to Load'
  }
  if (status === STATUSES.FILE_DUPLICATE || status === STATUSES.TRANS_DUPLICATE) {
    return (
      <Tooltip title={helpText}>
        <UnderlinedCell error={true} warning={false}>
          {text}
        </UnderlinedCell>
      </Tooltip>
    )
  }
  if (description) {
    return <UnderlinedCell error>{description}</UnderlinedCell>
  }
  return ''
})

DescriptionCell.displayName = 'DescriptionCell'

DescriptionCell.propTypes = {
  description: PropTypes.string
}

export default DescriptionCell
