import OtaClient from '@crowdin/ota-client'
import { useEffect, useState } from 'react'
import defaultMessages from 'lang/en.json'
import { captureException } from '@sentry/react'

const client = new OtaClient(process.env.REACT_APP_CROWDIN_CDN_HASH)

export default function useCrowdin(locale) {
  // use defaults right away to avoid errors on initial renders
  const [messages, setMessages] = useState(defaultMessages)

  useEffect(() => {
    const getTranslations = async () => {
      try {
        const files = await client.getLanguageTranslations(locale)
        if (!files[0].content) {
          throw new Error()
        }

        // on success load translations from CDN, but fallback to
        // source file for any missing translations (e.g. new keys)
        setMessages({ ...defaultMessages, ...files[0].content })
      } catch (error) {
        captureException(error, { extra: { locale }})
        // On error load translations source file
        setMessages(defaultMessages)
      }
    }

    getTranslations()
  }, [locale])

  return { messages }
}
