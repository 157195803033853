import { INVOICE_TAGS_READABLE } from 'constants.js';

export const getTagsOptionsBooking = () => {
  const {
    POSSIBLE_ENHANCED_DUPLICATE,
    AMOUNT_ABOVE_THRESHOLD,
    ...filteredOptions
  } = INVOICE_TAGS_READABLE
  return filteredOptions
}

export const getTagsOptionsFileUpload = () => {
  const {
    POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT,
    POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT,
    AMOUNT_ABOVE_THRESHOLD,
    ...filteredOptions
  } = INVOICE_TAGS_READABLE
  return filteredOptions
}

export const filterTags = (tags = [], isFileUpload = false) => {
  const filterBy = Object.keys(isFileUpload ? getTagsOptionsFileUpload() : getTagsOptionsBooking())
  return tags.filter(t => filterBy.includes(t))
}

