import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const boldRegex = /([^[]*)\[BOLD\](.*?)\[\/BOLD\](.*)/g

const lineParser = (line, index) => {
  let match = boldRegex.exec(line)
  if (match === null) {
    return <Typography key={index}>{line}</Typography>
  }

  const results = []
  while (match !== null) {
    if (match[2]) {
      results.push(<Box component="p" key={`${index}:match[2]`} fontWeight={700}>{match[2]}</Box>)
    }
    if (match[3]) {
      results.push(<Typography key={`${index}:match[3]`}>{match[3]}</Typography>)
    }
    match = boldRegex.exec(line)
  }
  return results
}

const BoldTagParser = ({ text }) => {
  if (!text) {
    return null
  }
  const lines = text.match(/[^\r\n]+/g)
  return lines?.map(lineParser)?.flat()
}
BoldTagParser.propTypes = {
  text: PropTypes.string.isRequired
}

export default BoldTagParser
