import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

function SessionTimeout({ auth, logout, refreshToken, getTokenExpiry, children }) {
  const [open, setOpen] = useState(false)
  const expireTimeout = useRef(null)
  const getExpiryTimeout = useRef(null)

  const reset = useCallback(() => {
    setOpen(false)
    clearTimeout(expireTimeout.current)
    clearTimeout(getExpiryTimeout.current)
  }, [setOpen])

  const onExpire = useCallback(() => {
    reset()
    toast.error('Session expired. Please login again.')
    logout()
  }, [logout,reset])

  const handleRefresh = useCallback(() => {
    reset()
    refreshToken()
  }, [refreshToken, reset])

  const showRefreshPrompt = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  useEffect(() => {
    if (!auth.token || auth.fetchingExpiration) {
      reset()
      return
    }

    // we have a token, we're not fetching, but have no known expiration
    if (auth.expiration === undefined) {
      getTokenExpiry()
      return
    }

    if (auth.expiration === null) { // no expiry
      reset()
      return
    }

    // will also be negative when expiration is 0 (zero)
    const timeLeft = auth.expiration - moment.utc()

    if (timeLeft <= 0) {
      onExpire()
      return
    }

    if (timeLeft <= 60000) {
      showRefreshPrompt()
      expireTimeout.current = setTimeout(onExpire, timeLeft)
      return
    }

    // check again 1min before known expiry
    getExpiryTimeout.current = setTimeout(getTokenExpiry, timeLeft - 60000)
  }, [auth.token, auth.expiration, auth.fetchingExpiration, showRefreshPrompt, onExpire, getTokenExpiry, reset])

  useEffect(() => {
    if (auth.token) {
      getTokenExpiry()
    }
  }, [auth.token, getTokenExpiry])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleRefresh}
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth={'xs'}
      >
        <DialogTitle>
          Session Timeout
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session is about to expire, confirm you would like to remain logged in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRefresh}
            fullWidth
            color='primary'
            variant='contained'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </div>
  )
}

SessionTimeout.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default SessionTimeout
