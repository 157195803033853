import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import FormSelect from 'components/FormSelect'
import { useStyles } from './styles'
import { DateRangePicker } from 'materialui-daterange-picker'
import { CalendarToday, Clear } from '@material-ui/icons'
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Popover,
} from '@material-ui/core'
import moment from 'moment'

export const CustomPeriodField = ({
  value,
  ..._props
}) => {

  const [anchorEl, setAnchorEl] = React.useState(null)

  const stringifyDates = (dateRange) => {
    if (!dateRange) {
      return ''
    }
    
    const startDate = moment(dateRange.startDate).format('DD/MM/YYYY')
    const endDate = moment(dateRange.endDate).format('DD/MM/YYYY')
    return `${startDate} - ${endDate}`
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClear = () => {
    _props.onChange(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)

  return (
    <Box mt={1}>
      <FormControl fullWidth>
        <Input
          id="standard-adornment-password"
          value={stringifyDates(value)}
          onClick={handleClick}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear datepicker"
                onClick={handleClear}
                onMouseDown={handleClear}
                size="small"
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                aria-label="Open date range picker"
                onClick={handleClick}
                onMouseDown={handleClick}
                size="small"
              >
                <CalendarToday />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {
        isOpen &&
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <DateRangePicker
            open
            toggle={handleClose}
            {..._props.value && { initialDateRange: _props.value }}
            {..._props}
          />
        </Popover>
      }
    </Box>
  )
}

CustomPeriodField.propTypes = {
  value: PropTypes.object,
}

const PeriodFieldFormControlWrapper = ({
  control,
  criteria,
  options,
  error
}) => {

  const classes = useStyles()

  return (
    <>
      <Controller
        // eslint-disable-next-line no-unused-vars
        render={({ field: { ref, ...field } }) => <FormSelect
          {...field}
          full
          label="Period"
          keyTitle={options}
          required
          fullWidth={true}
          className={classes.formInput}
          error={error?.message}
          name="criteria" // name is required
        />}
        control={control}
        name="criteria"
      />
      {criteria === 'CUSTOM' &&
        <Controller
          control={control}
          name="customPeriod"
          error={!!error}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, ...field } }) =>
            <CustomPeriodField {...field} error={error} />
          }
        />
      }
    </>
  )
}

PeriodFieldFormControlWrapper.propTypes = {
  control: PropTypes.object.isRequired,
  criteria: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  error: PropTypes.object
}

export default memo(PeriodFieldFormControlWrapper)
