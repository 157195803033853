import React from 'react'
import MUIToolbar from '@material-ui/core/Toolbar'
import { useStyles } from './styles'

const Toolbar = (props) => {
  const classes = useStyles()
  return (
    <MUIToolbar { ...props } variant="dense" className={classes.root} />
  )
}

export default Toolbar
