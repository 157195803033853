import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const performFetchPtxContacts = async (name) => {
  const url = `${API_URL}/ptx-contacts/search?q=${name}`
  return httpFetch(url, { shouldThrowError: true })
}

export const performFetchPtxContactsMandates = async (contactId) => {
  const url = `${API_URL}/ptx-contacts/${contactId}/mandates`
  return httpFetch(url, { shouldThrowError: true })
}
