import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const getOptionLabel = o => {
  if (!o.name) {
    return ''
  }
  let option = o.name
  if (o.city) {
    option += `, ${o.city}`
  }
  if (Object.keys(o.codes).length) {
    Object.keys(o.codes).forEach(code => {
      option += `, [${code.toLocaleUpperCase()}]: ${o.codes[code]}`
    })
  }
  return option
}

export const SearchOrViewCompany = React.memo(
  ({
    classes,
    options,
    searchHandler,
    clearSearchCompaniesData,
    commonActions,
    commonState,
    isReadOnly,
    label,
    lookupRole,
    company
  }) => {
    const { onSelectEntity } = commonActions
    const [ text, setText ] = useState('')

    const inputHandler = useCallback(
      ({ target }) => {
        setText(target.value)
        searchHandler(target.value)
      },
      [ searchHandler ]
    )

    const handleChange = useCallback((_e, value) => {
      setText('')
      onSelectEntity(lookupRole, value)
    }, [ lookupRole, onSelectEntity ])

    const handleBlur = useCallback(() => {
      setText('')
      clearSearchCompaniesData()
    }, [ clearSearchCompaniesData ])

    return (
      <React.Fragment>
        {isReadOnly ? (
          <TextField className={classes.formInput} label={label} value={company?.name || ''} disabled />
        ) : (
          <Autocomplete
            value={company || {}}
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={handleChange}
            inputValue={text || (company ? getOptionLabel(company) : '')}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                margin="dense"
                error={Boolean(commonState[`error${lookupRole}Field`])}
                helperText={commonState[`error${lookupRole}Field`]}
                onChange={inputHandler}
                onBlur={handleBlur}
                fullWidth
                required
              />
            )}
          />
        )}
      </React.Fragment>
    )
  }
)

SearchOrViewCompany.displayName = 'SearchOrViewCompany'
SearchOrViewCompany.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  searchHandler: PropTypes.func,
  clearSearchCompaniesData: PropTypes.func,
  commonState: PropTypes.object,
  commonActions: PropTypes.object,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string,
  lookupRole: PropTypes.string,
  company: PropTypes.object
}
