import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import BackOffCountDown from 'components/BackOffCountdown'

const MFACodeVerificationForm = ({
  confirmMFACode,
  tryAgainAt
}) => {
  const classes = useStyles()
  const {
    register,
    handleSubmit,

    formState: {
      errors,
    },
  } = useForm({ resolver: joiResolver(formSchema) })
  const [isBackOffBlocked, setIsBackOffBlocked] = useState(false)

  const onSubmit = handleSubmit((data) => {
    const { otpToken } = data
    confirmMFACode(otpToken)
  })

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <BackOffCountDown tryAgainAt={tryAgainAt} setIsBackOffBlocked={setIsBackOffBlocked} />
      <TextField
        autoFocus
        {...register("otpToken")}
        label="2-factor authentication code"
        error={errors?.otpToken}
        variant="outlined"
        inputProps={{
          maxLength: 6,
          style: { textAlign: 'center' }
        }}
        fullWidth
        disabled={isBackOffBlocked}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Confirm
      </Button>
    </form>
  )
}

MFACodeVerificationForm.defaultProps = {
  confirmMFACode: () => {
  }
}

MFACodeVerificationForm.propTypes = {
  confirmMFACode: PropTypes.func.isRequired,
  tryAgainAt: PropTypes.number
}

export default MFACodeVerificationForm
