import { get } from '../../utils/api'

export const getCompanyEntities = async (bookingRefs) => {
  const processingEntityIds = []
  const entities = {}
  bookingRefs.map(async (ref) => {
    const { sourceCompany: seller, targetCompany: buyer, commercialLink: commLink } = ref

    entities[buyer?.id] = buyer?.name
    entities[seller?.id] = seller?.name

    if (
      commLink.processingEntityId &&
      commLink.processingEntityId !== seller.id &&
      !processingEntityIds.some(pid => pid === commLink.processingEntityId)
    ) {
      processingEntityIds.push(commLink.processingEntityId)
    }
  })

  const processingEntities = await Promise.all(
    processingEntityIds.map(
      (pid)=> get(`/companies/${pid}`)
        .then(({ data }) => ({
          [data.id]: data.attributes?.name
        }))
    )
  )

  return {
    ...entities,
    ...processingEntities.reduce((prev, current) => ({
      ...prev,
      ...current
    }), {})
  }
}
