import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      height: '100vh',
      background: theme.palette.grey[100],
    },
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  logoContainer: {
    height: '80px'
  },
  logo: {
    maxHeight: '100%',
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    maxWidth: '450px', 
    background: theme.palette.background.default,
    padding: theme.spacing(5),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2,
  },  
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  loginMessage: {
    marginTop: '1em',
    background: '#FFF',
    padding: '1em',
    width: '100%',
    border: '1px solid black',
    overflow: 'scroll',
    fontSize: '16px',
    minHeight: '80px',
    maxHeight: '200px',
    '& p': {
      margin: '.5em 0'
    }
  },
}))
