import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import { useStyles } from '../styles'

export const ErrorsForm = ({ errors = [] }) => {
  const classes = useStyles()
  return (
    <Card direction="column" justify="flex-start">
      {errors.map((error, i) => (
        <p key={i} className={classes.errorText}>
          {error}
        </p>
      ))}
    </Card>
  )
}

ErrorsForm.displayName = 'ErrorsForm'
ErrorsForm.propTypes = {
  errors: PropTypes.array.isRequired
}
