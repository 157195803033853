import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { SCHEDULE_DAY_KEY_TITLE } from 'constants.js'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'

const WeeklyForm = ({ error, onChange, value = [] }) => {
  const { formatMessage } = useIntl()
  const options = useMemo(() => Object.entries(SCHEDULE_DAY_KEY_TITLE).map(e => ({
    label: formatMessage({ id: `schedule-day-key-title.${kebabCase(e[1])}` }),
    value: e[0],
  })), [formatMessage])
  const selectedDays = options.filter(o => value.includes?.(o.value))

  const handleChange = (_e, val) => {
    const newValue = val.map(v => v.value)
    onChange(newValue)
  }

  return (
    <Autocomplete
      ChipProps={{
        size: 'small'
      }}
      getOptionLabel={o => o.label}
      onChange={handleChange}
      multiple
      options={options}
      value={selectedDays}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          fullWidth
          label={formatMessage({ id: 'task-scheduler.recurs-every-week-on' })}
          placeholder={formatMessage({ id: 'task-scheduler.select-day-s' })}
        />
      )}
    />
  )
}

WeeklyForm.propTypes = {
  control: PropTypes.object,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.array
}

export default WeeklyForm
