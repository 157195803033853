import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { styled } from '@material-ui/core/styles'
import { AccordionActions } from '@material-ui/core'

export const CustomAccordion = styled(Accordion)(({theme}) => ({
  marginBottom: theme.spacing(1.5) + 'px',
  marginTop: theme.spacing(1.5) + 'px',
  borderRadius: theme.spacing(0.5) + 'px',
  boxShadow: 'none !important',
  border: '2px solid ' + theme.palette.divider,
  '&:before': {
    display: 'none',
  }
}))

export const CustomAccordionTitle = styled(AccordionSummary)(({theme}) => ({
  background: theme.palette.background.default,
  minHeight: 0,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: theme.spacing(0.5) + 'px !important',
  '&.Mui-expanded': {
    minHeight: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
  '& .MuiAccordionSummary-expandIcon': {
    paddingTop: 0,
    paddingBottom: 0,
  }
}))

export const CustomAccordionContent = styled(AccordionDetails)(({theme}) => ({
  background: theme.palette.background.default,
  borderRadius: theme.spacing(0.5) + 'px !important',
}))

export const CustomAccordionActions = styled(AccordionActions)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
}))