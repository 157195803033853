import React from 'react'
import PropTypes from 'prop-types'
import MTableCell from '@material-ui/core/TableCell'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import SelectOnTabMenuItem from 'components/SelectOnTabMenuItem'

const SelectCell = ({ options, value, onChange, ...selectProps }) => {
  return (
    <MTableCell align="center">
      <FormControl fullWidth>
        <Select
          MenuProps={{variant: "menu"}}
          onChange={(event) => onChange(event.target.value)}
          value={value}
          {...selectProps}
        >
          {Object.entries(options).map(([ key, _value ]) => (
            <SelectOnTabMenuItem key={key} name={key} onChange={(event) => onChange(event.target.value)} value={key}>
              {_value}
            </SelectOnTabMenuItem>
          ))}
        </Select>
      </FormControl>
    </MTableCell>
  )
}

SelectCell.propTypes = {
  options: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  onChange: PropTypes.func
}

export default SelectCell
