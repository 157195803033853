import { connect } from 'react-redux'
import Component from './Registration.js'
import { fetchInviteData, register } from 'store/registration'

export const mapState = ({
  registration
}) => {
  return {
    ...registration
  }
}

export const mapDispatch = (dispatch) => ({
  submitRegistration: data => dispatch(register(data)),
  fetchInviteData: () => dispatch(fetchInviteData())
})

export default connect(mapState, mapDispatch)(Component)
