import { InputAdornment } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { SettlementScheduleProfile, Weekday, isDailySettlementScheduleProfile, isWeeklySettlementScheduleProfile, offsettedCollection, settlementScheduleProfileOptions } from '@travel-ledger/type-constants'
import CheckboxGroup from 'components/CheckboxGroup'
import FormSelect from 'components/FormSelect'
import { TranslationWithHelpPopup } from 'components/HelpPopup/HelpPopup'
import Section from 'components/PaymentProfileConfigForm/components/Section'
import ReactHookFormMuiCheckbox from 'components/ReactHookFormMuiCheckbox'
import ReactHookFormMuiTextField from 'components/ReactHookFormMuiTextField'
import { useMemoEnumItems, useMemoWeekdays } from 'containers/SettlementScheduleEdit/hooks'
import PropTypes from 'prop-types'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { useStyles } from './styles'
import moment from 'moment'
import { ControlledWeekdaySelect } from '../ControlledWeekdaySelect'
import { isArray } from 'lodash'

const textFieldProps = {
  fullWidth: true,
  margin: 'normal'
}

const SettlementScheduleForm = ({
  paymentProfilesData,
  isSuccess,
  isProcessingEntity,
}) => {
  const { formatMessage } = useIntl()

  const MESSAGE_EMPTY_PAYMENT_PROFILE_ERROR = formatMessage({ id: 'settlement-schedules-form.you-first-must-create-a-payment-profile-in-order-to-create-a-settlement-schedule' })
  const MESSAGE_UPDATE_COLLECTION_DAYS = formatMessage({ id: 'settlement-schedules-form.you-must-save-any-change-of-day-or-time-in-order-to-reflect-cycle-start-and-collection-days-on-the-settlement-calendar' })
  const MESSAGE_NON_WORKING_DAYS = formatMessage({ id: 'settlement-schedules-form.weekend-days-public-holidays-non-working-banking-days-are-set-by-the-payment-profile-for-some-payment-profiles-collections-cannot-be-made-on-non-working-days' })

  const isEmptyPaymentProfileOptions = isSuccess && !paymentProfilesData?.length
    ? MESSAGE_EMPTY_PAYMENT_PROFILE_ERROR
    : undefined
  const paymentProfileItems = paymentProfilesData?.reduce(
    (items, paymentProfile) => {
      // eslint-disable-next-line no-param-reassign
      items[paymentProfile.id] = paymentProfile.name
      return items
    },
    {}
  ) ?? {}

  const classes = useStyles()
  const profileItems = useMemoEnumItems({ SettlementScheduleProfile })
  const collectionWeekdayItems = useMemoWeekdays()
  const nwdsItems = useMemoWeekdays({ format: 'short' })
  const { control, setValue, formState: { errors }, watch, getValues } = useFormContext()
  /** @type {SettlementScheduleProfile} */
  const selectedProfile = watch('profile')
  const currentProfileParams = watch('profileParams')

  const allowedCycleStartWeekdays = useMemo(
    () => (selectedProfile === SettlementScheduleProfile.Custom
      ? Object.values(Weekday)
      : settlementScheduleProfileOptions[selectedProfile]?.allowedCycleStartWeekDays
    ),
    [selectedProfile]
  )
  const cycleStartWeekdayItems = useMemoWeekdays({
    allowedWeekdays: allowedCycleStartWeekdays
  })

  // set value on edit
  useEffect(() => {
    if (currentProfileParams && currentProfileParams.weekDay) {
      setValue('cycleStartDay',
        cycleStartWeekdayItems.filter(
          item => {
            if(isArray(currentProfileParams.weekDay)) {
              return currentProfileParams.weekDay.some(wd => item.value === wd)
            } else {
              return item.value === currentProfileParams.weekDay
            }
          }
        )
      )
    }
  }, [currentProfileParams, cycleStartWeekdayItems, setValue])

  // this period does not exist when there is no PE/it is a direct payment
  useEffect(() => {
    if (!isProcessingEntity) {
      setValue('forwardFundsPeriodInHours', 0)
    }
  }, [isProcessingEntity, setValue])

  const ooptions = settlementScheduleProfileOptions[
    SettlementScheduleProfile.WeeklyCollectionWithDailySupplierSubmissions
  ].fundingPeriodInMinutesOptions.map((option) => ({
    value: option.value,
    label: <>
      <FormattedNumber value={option.labelAs} style="unit" unit={option.labelUnit} />
      &nbsp; {/* The client wants to display as days with real value in parentheses */}
      (<FormattedNumber value={option.value / 60} style="unit" unit="hour" />)
    </>
  }))

  const isReadonly = useMemo(() => {
    if (selectedProfile === SettlementScheduleProfile.Custom) {
      return {
        cycleStartStrategy: false,
        buyerReviewPeriodInHours: false,
        cycleStartDay: false,
        cycleStartTime: false,
        collectionDay: true,
        collectionTime: true,
        fundingPeriodInMinutes: false,
      }
    }

    const options = settlementScheduleProfileOptions[selectedProfile]

    return {
      cycleStartStrategy: true,
      buyerReviewPeriodInHours: true,
      cycleStartDay: isDailySettlementScheduleProfile(selectedProfile),
      cycleStartTime: true,
      collectionDay: true,
      collectionTime: true,
      fundingPeriodInMinutes: options && 'fundingPeriodInMinutes' in options,
    }
  }, [selectedProfile])

  // TODO this hook needs to be cleaned up
  useEffect(() => {
    if (!selectedProfile || selectedProfile === SettlementScheduleProfile.Custom) {
      return
    }

    const profileOptions = settlementScheduleProfileOptions[selectedProfile]

    setValue('cycleStartStrategy', profileOptions.cycleStartStrategy)
    setValue('buyerReviewPeriodInHours', profileOptions.buyerReviewPeriodInHours)

    // TODO daily is different
    const selectedCycleStartDay = getValues('cycleStartDay')
    if (isDailySettlementScheduleProfile(selectedProfile)) {
      // select all for daily
      setValue('cycleStartDay',
        profileOptions.allowedCycleStartWeekDays.map(
          weekday => cycleStartWeekdayItems.find(
            item => item.value === weekday
          )
        )
      )
    } else if (isWeeklySettlementScheduleProfile(selectedProfile)) {
      // create
      const value = (selectedCycleStartDay?.length && selectedCycleStartDay.find(
        // the first selected value that is allowed
          option => profileOptions.allowedCycleStartWeekDays.includes(option.value)
        )) ?? cycleStartWeekdayItems.find( // or the first allowed value
          item => item.value === profileOptions.allowedCycleStartWeekDays[0]
        )
      value && setValue('cycleStartDay', [value])
    }


    setValue('cycleStartTime', moment()
      .hour(profileOptions.cycleStartTime.hour)
      .minute(profileOptions.cycleStartTime.minute)
      .format('HH:mm')
    )

    const fundingPeriodInMinutes = 'fundingPeriodInMinutes' in profileOptions
      ? profileOptions.fundingPeriodInMinutes
      : profileOptions.fundingPeriodInMinutesOptions[0].value

    setValue('fundingPeriodInMinutes', fundingPeriodInMinutes)
    
  }, [selectedProfile, cycleStartWeekdayItems, getValues, setValue])

  const [triggerKey, setTriggerKey] = useState()

  watch(values => {
    const {
      fundingPeriodInMinutes,
      cycleStartDay,
      cycleStartTime
    } = values
    // can be empty
    if (!cycleStartDay?.length) {
      return
    }
    const tk = `${fundingPeriodInMinutes}$${cycleStartDay
      .map(csd => Number(csd.value))
      .sort((a, b) => a - b) // order ascending
      .join(',')}$${cycleStartTime}`
    // alter state to trigger useEffect
    if (tk !== triggerKey) {
      setTriggerKey(tk)
    }
    
  })

  // effect to calculate submission start
  useEffect(() => {
    if(!triggerKey) {
      return
    }
    const [fundingPeriodInMinutes, cycleStartDayString, cycleStartTime] = triggerKey.split('$')
    const cycleStartDay = cycleStartDayString.split(',').map(d => Number(d))

    if (cycleStartDay?.length && !!cycleStartTime) {
      const [hour, minute] = cycleStartTime.split(':')
      const { collectionTime, collectionWeekdays } = offsettedCollection(
        cycleStartDay,
        { hour, minute },
        fundingPeriodInMinutes,
      )

      // take last value (inputs are ordered)
      const newDay = collectionWeekdays[collectionWeekdays.length - 1]
      if (getValues('collectionDay') !== newDay) {
        setValue('collectionDay', newDay)
      }

      const newTime = moment().hour(collectionTime.hour).minute(collectionTime.minute).format('HH:mm')
      if (newTime !== getValues('collectionTime')) {
        setValue('collectionTime', newTime)
      }
    }
  }, [triggerKey, getValues, setValue])

  return (
    <form noValidate id="settings" autoComplete="off" className={classes.form}>
      <Section title={formatMessage({ id: 'settlement-schedules-form.settings' })} className={classes.section}>
        <ReactHookFormMuiTextField
          label={formatMessage({ id: 'settlement-schedules-form.name' })}
          control={control}
          name="name"
          required
          error={errors?.name}
          {...textFieldProps}
        />
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, ...field } }) => <FormSelect
            {...field}
            label={formatMessage({ id: 'settlement-schedules-form.collection-payment-configuration' }) + ' *'}
            keyTitle={paymentProfileItems}
            disabled={!!isEmptyPaymentProfileOptions}
            error={errors?.paymentProfileId?.message || isEmptyPaymentProfileOptions}
            required
            fullWidth
          />}
          control={control}
          name="paymentProfileId"
        />
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, ...field } }) => <FormSelect
            {...field}
            label={formatMessage({ id: 'settlement-schedules-form.disbursement-payment-configuration' }) + ' *'}
            keyTitle={paymentProfileItems}
            disabled={!!isEmptyPaymentProfileOptions}
            error={errors?.disbursementPaymentProfileId?.message || isEmptyPaymentProfileOptions}
            required
            fullWidth
          />}
          control={control}
          name="disbursementPaymentProfileId"
        />
      </Section>
      <Section title={formatMessage({ id: 'settlement-schedules-form.settlement-schedule-profile' })} className={classes.section}>
        <Controller
          control={control}
          name="profile"
          render={({ field }) => <FormSelect
            {...field}
            keyTitle={profileItems}
            className={classes.dateTimeItem}
            error={errors?.profile?.message}
            label={formatMessage({ id: 'settlement-schedules-form.profile' })}
            required
            fullWidth
          />}
        />
      </Section>
      <Section title={formatMessage({ id: 'settlement-schedules-form.schedule-defaults' })} className={classes.section}>
        <ReactHookFormMuiCheckbox
          control={control}
          name="lockInvoicesAsEarlyAsPossible"
          label={<TranslationWithHelpPopup id="settlement-schedule.earliest-cycle-possible" />}
          disabled={isReadonly.cycleStartStrategy}
          defaultValue={false}
          defaultChecked={false}
        />
        <ReactHookFormMuiTextField
          label={formatMessage({ id: 'settlement-schedules-form.buyer-review-period' })}
          name="buyerReviewPeriodInHours"
          type="number"
          control={control}
          error={errors?.buyerReviewPeriodInHours}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <Typography color="textSecondary">
                <FormattedMessage id="general.unit.hours" />
              </Typography>
            </InputAdornment>
          }}
          disabled={isReadonly.buyerReviewPeriodInHours}
          {...textFieldProps}
        />
        <Box display="flex" my={1} flexWrap="wrap" alignItems="center">
          <Box mr={2}>
            Exclude from Buyer Review Period calculations:
          </Box>
          <ReactHookFormMuiCheckbox
            control={control}
            name="excludeWeekend"
            label="Weekends"
            defaultValue={false}
            defaultChecked={false}
          />
          <ReactHookFormMuiCheckbox
            control={control}
            name="excludeHolidays"
            label="Non-banking days"
            defaultValue={false}
            defaultChecked={false}
          />
        </Box>
        {selectedProfile === SettlementScheduleProfile.WeeklyCollectionWithDailySupplierSubmissions
          ? <Controller
            control={control}
            name="fundingPeriodInMinutes"
            render={({ field }) => <FormSelect
              {...field}
              keyTitle={ooptions}
              error={errors?.profile?.message}
              label={formatMessage({ id: 'settlement-schedules-form.funding-period' })}
              required
              {...textFieldProps}
            />}
          />
          : <ReactHookFormMuiTextField
            label={formatMessage({ id: 'settlement-schedules-form.funding-period' })}
            name="fundingPeriodInMinutes"
            type="number"
            control={control}
            error={errors?.fundingPeriodInMinutes}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Typography color="textSecondary">
                  <FormattedMessage id="general.unit.minutes" />
                </Typography>
              </InputAdornment>,
            }}
            {...textFieldProps}
            disabled={isReadonly.fundingPeriodInMinutes}
          />
        }
        {isProcessingEntity && <ReactHookFormMuiTextField
          label={formatMessage({ id: 'settlement-schedules-form.forward-funds-after' })}
          name="forwardFundsPeriodInHours"
          type="number"
          control={control}
          error={errors?.forwardFundsPeriodInHours}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <Typography color="textSecondary">
                <FormattedMessage id="general.unit.hours" />
              </Typography>
            </InputAdornment>
          }}
          {...textFieldProps}
          disabled={isReadonly.forwardFundsPeriodInHours}
        />}
        <Card variant="outlined" className={classes.card}>
          <CardContent>
            <Typography component="p">
              {formatMessage({ id: 'settlement-schedules-form.cycle-start-schedule' })}
            </Typography>
            <ControlledWeekdaySelect
              options={cycleStartWeekdayItems}
              control={control}
              name="cycleStartDay"
              label={formatMessage({ id: 'settlement-schedules-form.every' })}
              error={!!errors?.cycleStartDay?.message}
              disabled={isReadonly.cycleStartDay}
              multiple={selectedProfile !== SettlementScheduleProfile.WeeklyCollectionWithWeeklySupplierSubmissions}
            />
            <ReactHookFormMuiTextField
              label={formatMessage({ id: 'settlement-schedules-form.hh-mm-24-h' })}
              control={control}
              name="cycleStartTime"
              type="time"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                step: 300 // 5 min
              }}
              className={classes.dateTimeItem}
              error={errors?.cycleStartTime}
              fullWidth={false}
              disabled={isReadonly.cycleStartTime}
            />
          </CardContent>
        </Card>
        <Card variant="outlined" className={classes.card}>
          <CardContent>
            <Typography component="p">
              {formatMessage({ id: 'settlement-schedules-form.collection-processing-schedule' })}
            </Typography>
            {/* TODO we need collection day to support "multiple selection" */}
            {/* for now take the latest  */}
            <Controller
              control={control}
              name="collectionDay"
              render={({ field }) => <FormSelect
                {...field}
                keyTitle={collectionWeekdayItems}
                className={classes.dateTimeItem}
                error={errors?.collectionDay?.message}
                label={formatMessage({ id: 'settlement-schedules-form.every' })}
                required
                disabled={isReadonly.collectionDay}
              />}
            />
            <ReactHookFormMuiTextField
              label={formatMessage({ id: 'settlement-schedules-form.hh-mm-24-h' })}
              control={control}
              name="collectionTime"
              type="time"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                step: 300 // 5 min
              }}
              className={classes.dateTimeItem}
              error={errors?.collectionTime}
              fullWidth={false}
              disabled={isReadonly.collectionTime}
            />
          </CardContent>
        </Card>
        <Typography component="p" variant="caption">{MESSAGE_UPDATE_COLLECTION_DAYS}</Typography>
      </Section>
      <Section title={formatMessage({ id: 'settlement-schedules-form.weekend-rules' })} className={classes.section}>
        <Controller
          name="nwds"
          control={control}
          render={({ field }) => <CheckboxGroup
            {...field}
            type="number"
            items={nwdsItems}
            error={!!errors?.nwds}
            disabled
          />}
        />
      </Section>
      <Typography variant="caption">{MESSAGE_NON_WORKING_DAYS}</Typography>
    </form>
  )
}

SettlementScheduleForm.propTypes = {
  paymentProfilesData: PropTypes.array,
  isSuccess: PropTypes.bool,
  isProcessingEntity: PropTypes.bool.isRequired,
}

export default memo(SettlementScheduleForm)
