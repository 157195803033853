import { fullName } from 'utils/functions'

// Action Names
const FETCH_NOTIFICATIONS = fullName('notifications', 'FETCH_NOTIFICATIONS')
const FETCH_NOTIFICATIONS_SUCCESS = fullName('notifications', 'FETCH_NOTIFICATIONS_SUCCESS')
const FETCH_NOTIFICATIONS_ERROR = fullName('notifications', 'FETCH_NOTIFICATIONS_ERROR')
const DISMISS_NOTIFICATIONS = fullName('notifications', 'DISMISS_NOTIFICATIONS')
const DISMISS_NOTIFICATIONS_SUCCESS = fullName('notifications', 'DISMISS_NOTIFICATIONS_SUCCESS')
const DISMISS_NOTIFICATIONS_ERROR = fullName('notifications', 'DISMISS_NOTIFICATIONS_ERROR')
const CLOSE_SNACKBAR = fullName('notifications', 'CLOSE_SNACKBAR')

export const ACTIONS = {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  DISMISS_NOTIFICATIONS,
  DISMISS_NOTIFICATIONS_SUCCESS,
  DISMISS_NOTIFICATIONS_ERROR,
  CLOSE_SNACKBAR
}

export const searchNotifications = (filters) => ({
  type: FETCH_NOTIFICATIONS,
  payload: filters
})

export const dismissNotifications = (indexes) => ({
  type: DISMISS_NOTIFICATIONS,
  payload: indexes
})

const initialState = {
  notifications: [],
  filters: {},
  snackbars: false,
  error: false,
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        clearSelection: false,
        filters: action.payload
      }
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        notificationsTotal: action.payload.length ? action.payload[0].resultTotal : 0
      }
    case DISMISS_NOTIFICATIONS_ERROR:
    case FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case DISMISS_NOTIFICATIONS:
      return {
        ...state,
        clearSelection: false
      }
    case DISMISS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        clearSelection: true
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpened: false,
          successOrErrorText: ''
        }
      }
    default:
      return state
  }
}
export default notifications
