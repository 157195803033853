import isEqual from 'lodash/isEqual'
import { APP_PREFIX, HIGH_PRIORITY_COUNTRIES_ALPHA2 } from 'constants.js'

export const isObject = value => typeof value === 'object' && value !== null
export const fullName = (state, action) => `${APP_PREFIX}/${state}/${action}`
export const toFloat = value => parseFloat(parseFloat(value).toFixed(2))
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
export const toCurrency = value => Number(value).toLocaleString('en-GB', { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' })
export const numberToString = value => typeof value === 'number' ? (isNaN(value) ? '' : value.toString()) : ''
export const downloadTxtFile = (text, filename) => {
  const element = document.createElement('a')
  const file = new Blob([text], { type: 'text/plain' })
  element.href = URL.createObjectURL(file)
  element.download = `${filename}.txt`
  document.body.appendChild(element)
  element.click()
}
export const sortByHighOrderCountries = (country1, country2) => {
  if (HIGH_PRIORITY_COUNTRIES_ALPHA2.includes(country2?.alpha2)) {
    return 1
  }
  if (HIGH_PRIORITY_COUNTRIES_ALPHA2.includes(country1?.alpha2)) {
    return -1
  }
  return 0
}

export const memoizingComparison = (o1, o2) => {
  try {
    return JSON.stringify(o1) === JSON.stringify(o2)
  } catch (error) {
    return isEqual(o1, o2)
  }
}

/*
* 0 => 0.00
* 10.01 => 10.01
* 10.1 => 10.10
* 100 => 100.00
* 1000 => 1 000.00
* 100000 => 100 000.00
* 100000000 => 100 000 000.00
* 100000000.1 => 100 000 000.10
* */
export const formatLongAmountString = amountString => {
  const [_d, _c] = amountString.split('.')
  const dollarsPart = +_d || 0
  let centsPart = _c || '00'
  if (centsPart.length < 2) {
    centsPart = centsPart + '0'
  } else if (centsPart.length > 2) {
    centsPart = centsPart.substr(0, 2)
  }
  const sign = dollarsPart < 0 ? '-' : ''
  const dollars_arr = String(Math.abs(dollarsPart)).split('')
  const dollars_arr_with_sep = dollars_arr.reduce((prev, curr, idx) => {
    prev.push(curr)
    const pos = dollars_arr.length - idx - 1
    if (pos > 0 && pos % 3 === 0) {
      prev.push(' ')
    }
    return prev
  }, [])
  return sign + dollars_arr_with_sep.join('') + '.' + centsPart
}

export const getFormattedAmount = amount => formatLongAmountString((amount / 100).toFixed(2))

// because we cannot yet use the `type-constants` package in react
export const formatAmountForExport = fractionalAmount => (fractionalAmount / 100).toFixed(2)

export const mapFormKeys = (values, model) => {
  const mapped = {};
  Object.keys(model).forEach(key => {
    mapped[key] = values && values[key];
  })
  return mapped;
}

export const getKeyByValue = (obj, value) => {
  const [key] = Object.entries(obj)
  .reduce((acc, [k, val]) => {
      if (val === value) {
          acc.push(k);
      }
      return acc;
  }, [])
  return key
};

export const camelCaseToWords = (s) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}