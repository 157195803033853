import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AuthView from 'containers/AuthView'
import CLEditForm from './components/CLEditForm'
import { ROUTE_URL } from '../../constants'
import { useQuery } from 'react-query'
import { getSettlementSchedules } from 'services/settlementSchedulesApi'

// TODO: Refactor this replacing all form complex logic by react-hook-form
const CommercialLinkDetails = ({
  replace,
  location,
  companies,
  postCommercialLinks,
  patchCommercialLink,
  searchCompanies,
  clearSearchCompaniesData,
  currUserCompany,
  snackbar
}) => {
  const { editedCommercialLink, role } = location?.state
  const commercialLinkRole = editedCommercialLink?.role ?? role
  const { data: settlementSchedulesData } = useQuery('settlementSchedules', () => getSettlementSchedules())
  const settlementSchedules = settlementSchedulesData?.data ?? []

  useEffect(() => {
    if (!commercialLinkRole) {
      replace(ROUTE_URL.commercialLinksList)
    }
  }, [ location?.state ])

  return (
    <AuthView location={location}>
      <CLEditForm
        editedCommercialLink={editedCommercialLink}
        role={commercialLinkRole}
        companies={companies}
        settlementSchedules={settlementSchedules}
        postCommercialLinks={postCommercialLinks}
        patchCommercialLink={patchCommercialLink}
        searchCompanies={searchCompanies}
        clearSearchCompaniesData={clearSearchCompaniesData}
        snackbar={snackbar}
        currUserCompany={currUserCompany}
      />
    </AuthView>
  )
}

CommercialLinkDetails.propTypes = {
  replace: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  postCommercialLinks: PropTypes.func,
  patchCommercialLink: PropTypes.func,
  companies: PropTypes.array,
  searchCompanies: PropTypes.func,
  clearSearchCompaniesData: PropTypes.func,
  currUserCompany: PropTypes.object,
  snackbar: PropTypes.object
}

export default CommercialLinkDetails
