import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Template,
  TemplatePlaceholder,
  TemplateConnector,
  Plugin,
  Getter
} from '@devexpress/dx-react-core'
import {
  getRowChange,
  TABLE_DATA_TYPE,
  rowsWithEditingCells,
  columnsWithEditingCells
} from '@devexpress/dx-grid-core'

const pluginDependencies = [
  { name: 'EditingState' },
  { name: 'EnhancedEditingState' },
  { name: 'Table' },
  { name: 'DataTypeProvider', optional: true }
]

const rowsWithEditingCellsComputed = ({ tableBodyRows, editingCells }) => rowsWithEditingCells(tableBodyRows, editingCells)
const columnsWithEditingCellsComputed = ({ tableColumns, editingCells }) => columnsWithEditingCells(tableColumns, editingCells)

// tslint:disable-next-line: max-line-length
const TableInlineCellEditing = (props) => {
  const { cellComponent: EditCell } = props

  return (
    <Plugin name="TableInlineCellEditing" dependencies={pluginDependencies}>
      <Getter name="tableBodyRows" computed={rowsWithEditingCellsComputed} />
      <Getter name="tableColumns" computed={columnsWithEditingCellsComputed} />
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }) => {
          return tableRow.type === TABLE_DATA_TYPE &&
            tableColumn.type === TABLE_DATA_TYPE &&
            !!tableColumn?.column?.editComponent &&
            !tableColumn?.column?.disableEditing?.({ row: tableRow.row })
        }
        }
      >
        {(params) => (
          <TemplateConnector>
            {(
              { rowChanges, isColumnEditingEnabled },
              { updateRow }
            ) => {
              const { tableRow: { rowId, row }, tableColumn: { column } } = params
              const { name: columnName } = column

              const currentRowChanges = getRowChange(rowChanges, rowId)
              const isUpdated = row.lastUpdate > currentRowChanges?.lastUpdate
              const changedRow = {
                ...row,
                ...(isUpdated ? {} : currentRowChanges)
              }

              const value = changedRow?.[columnName]
              const onValueChange = (newValue, relatedValues = {}) => {
                const changeArgs = {
                  rowId,
                  change: {
                    [columnName]: newValue,
                    ...relatedValues,
                    lastUpdate: Date.now()
                  }
                }
                updateRow(changeArgs)
              }
              const onFocus = e => e.target.select()
              const editingEnabled = isColumnEditingEnabled(columnName)

              return (
                <TemplatePlaceholder
                  name="valueEditor"
                  params={{
                    column,
                    row,
                    value,
                    onValueChange,
                    disabled: !editingEnabled
                  }}
                >
                  {content => (
                    <EditCell
                      {...params}
                      row={changedRow}
                      column={column}
                      value={value}
                      editingEnabled={editingEnabled}
                      onValueChange={onValueChange}
                      onFocus={onFocus}
                    >
                      {content}
                    </EditCell>
                  )}
                </TemplatePlaceholder>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  )
}

TableInlineCellEditing.propTypes = {
  cellComponent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func
  ]),
  selectTextOnEditStart: PropTypes.bool
}

TableInlineCellEditing.components = {
  cellComponent: 'Cell'
}

TableInlineCellEditing.defaultProps = {
  selectTextOnEditStart: false
}

export default TableInlineCellEditing
