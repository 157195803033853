import { Grid, IconButton, TextField } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Popover from '@material-ui/core/Popover'
import { Add, AttachMoney, Cached, Clear } from '@material-ui/icons'
import classNames from 'clsx'
import Button from 'components/Button'
import { BSD_CALENDAR_EVENTS } from 'constants.js'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { memoizingComparison } from 'utils/functions'
import { setMomentTime, shouldDisplayOption, unionDayEvents } from '../../utils'
import CalendarGroup from './CalendarGroup'
import {
  RESCHEDULE_FORCED_FOR,
  REMOVE_EVENT_TYPE,
  SKIP_TYPE_FOR,
  UN_SKIP_TYPE_FOR,
  CALENDAR_TIME_FORMAT,
} from '../../constants'
import Legend from './Legend'
import { useStyles } from './styles'

// TODO rescheduled a force, but then changed back to the original time
// this will keep the reschedule, when in fact it should remove the reschedule
//
// this has no visible effect, but an unnecessary update request is sent to
// the API to update the override to the same time it already has

const SettlementScheduleCalendar = ({
  timezone,
  currentEventsMap,
  existingEventsMap,
  unsavedEventsMap,
  setUnsavedEventsMap,
  monthsToDisplay,
  onMonthsToDisplayChange,
  startFrom,
  onDateNavigate,
}) => {
  // Define min time as one hour in the future
  const minTodayTime = moment.tz(timezone)
    .add(1, 'hour')
    .set({ // minutes precision
      seconds: 0,
      milliseconds: 0,
    })

  const [showCalendar, setShowCalendar] = useState(false)
  const [clickedDay, setClickedDay] = useState({})
  const [unsavedDayEvents, setUnsavedDayEvents] = useState([])
  const isToday = clickedDay?.date?.isSame(moment(), 'day')
  const classes = useStyles()

  useEffect(() => {
    if (!clickedDay.date) {
      setUnsavedDayEvents([])
      return
    }

    setUnsavedDayEvents(unsavedEventsMap[
      clickedDay.date.format('YYYY-MM-DD')
    ] ?? [])
  }, [clickedDay.date, unsavedEventsMap])

  const existingDayEvents = useMemo(() => {
    if (!clickedDay.date) {
      return null
    }

    return existingEventsMap[
      clickedDay.date.format('YYYY-MM-DD')
    ] ?? []
  }, [clickedDay.date, existingEventsMap])

  const clickedDayEvents = useMemo(() => {
    if (!existingDayEvents) {
      // when there is no clickedDay
      return []
    }

    return unionDayEvents(
      existingDayEvents,
      unsavedDayEvents,
    )
  }, [existingDayEvents, unsavedDayEvents])

  const handleAddEventClick = (type) => {
    const eventsSortedByTime = clickedDayEvents.sort(
      (a, b) => a.date.diff(b.date)
    )

    // clickedDay.date is already in the timezone
    const minClickedDayTime = clickedDay.date.clone().startOf('day')
    const targetTime = clickedDay.date.isSame(minTodayTime, 'day')
      ? moment.max(minClickedDayTime, minTodayTime)
      : minClickedDayTime

    // find a suitable time for the new event
    for (const event of eventsSortedByTime) {
      if (
        type === BSD_CALENDAR_EVENTS.FORCE_CYCLE_START &&
        event.type !== BSD_CALENDAR_EVENTS.FORCE_CYCLE_START &&
        event.type !== BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START
      ) {
        // for forced cycle start, check only against existing
        // forced and scheduled cycle start events
        continue
      }

      if (
        type === BSD_CALENDAR_EVENTS.FORCE_COLLECTION &&
        event.type !== BSD_CALENDAR_EVENTS.FORCE_COLLECTION &&
        event.type !== BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION
      ) {
        // for forced collection, check only against existing
        // forced and scheduled collection events
        continue
      }

      if (!targetTime.isSame(event.date)) {
        break
      }
      targetTime.add(1, 'minute')
    }

    if (targetTime.isAfter(clickedDay.date.clone().endOf('day'))) {
      // we've gone past 23:59, no longer a valid time on this day
      console.log('no suitable time for a new event', { clickedDay, type })
      return
    }

    setUnsavedDayEvents([
      ...unsavedDayEvents,
      {
        date: targetTime,
        type,
        id: new Date().toISOString(), // the key used in the UI
      }
    ])
  }

  const skipScheduledEvent = scheduledEvent => {
    if (
      scheduledEvent.type !== BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START &&
      scheduledEvent.type !== BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION
    ) {
      console.warn(`tried to skip non-scheduled event ${JSON.stringify(scheduledEvent)}`)
      return
    }

    // if there is an un-skip then we can remove it and the
    // scheduled event returns back to being skipped
    const unSkipType = UN_SKIP_TYPE_FOR[scheduledEvent.type]
    const unSkipIndex = clickedDayEvents.indexOf(event =>
      event.type === unSkipType &&
      event.date.isSame(scheduledEvent.date, 'minute')
    )

    if (unSkipIndex !== -1) {
      // remove un-skip event
      setUnsavedDayEvents(unsavedDayEvents
        .filter((_, index) => index !== unSkipIndex)
      )
      return
    }

    // we could check if there is a skip already, but we'll
    // trust that there isn't one; if we're here, we clicked
    // the clear button of a scheduled event, if it was
    // skipped, then it would've been a click on the clear
    // button of a skip event. See unionDayEvents(..) for
    // more information on how events are collapsed.

    // add skip event
    setUnsavedDayEvents([
      ...unsavedDayEvents,
      {
        // it will use the same id/key as the original event (scheduledEvent.id)
        ...scheduledEvent,
        type: SKIP_TYPE_FOR[scheduledEvent.type]
      },
    ])
  }

  const removeEvent = removedEvent => {
    if (unsavedDayEvents.includes(removedEvent)) {
      setUnsavedDayEvents(unsavedDayEvents.filter(
        event => event !== removedEvent
      ))
      return
    }

    const removerType = REMOVE_EVENT_TYPE[removedEvent.type]
    if (!removerType) {
      console.warn(`don't know how to remove ${JSON.stringify(removedEvent)}`)
      return
    }

    setUnsavedDayEvents([
      ...unsavedDayEvents,
      // it will use the same id/key as the original event (changedEvent.id)
      { ...removedEvent, type: removerType },
    ])
  }

  const handleClearEventClick = (clearedEvent) => {
    if (
      clearedEvent.type === BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START ||
      clearedEvent.type === BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION
    ) {
      skipScheduledEvent(clearedEvent)
      return
    }

    removeEvent(clearedEvent)
  }

  const handleClose = () => {
    setClickedDay({})
  }

  const handleToggleSettlementScheduleCalendar = () => {
    setShowCalendar((_showCalendar) => !_showCalendar)
  }

  const forcedEventHourChangeHandler = (ev, changedEvent) => {
    if (
      changedEvent.type !== BSD_CALENDAR_EVENTS.FORCE_COLLECTION &&
      changedEvent.type !== BSD_CALENDAR_EVENTS.FORCE_CYCLE_START
    ) {
      console.warn('only force events can have the hour changed, ignoring change', { changedEvent })
      return
    }

    const newDate = setMomentTime(
      clickedDay.date, ev.target.value,
    )

    // reschedule/existing force event will have an id
    const unsavedEvent = changedEvent.details?.id
      // with an id means we are rescheduling an existing force
      // event; that includes rescheduling a reschedule (because
      // it also has the details.id like the existing event)
      ? unsavedDayEvents.find(event =>
        event.type === RESCHEDULE_FORCED_FOR[changedEvent.type] &&
        event.details?.id === changedEvent.details.id
      )
      // without an id, it means we are just changing the time
      // of an unsaved force event (so not a reschedule); that
      // event is actually our "changedEvent"
      : changedEvent

    if (unsavedEvent) {
      // just change the time of the unsaved event (unsaved for or reschedule)
      setUnsavedDayEvents(unsavedDayEvents.map(event => {
        if (event === unsavedEvent) {
          return { ...event, date: newDate }
        }
        return event
      }))
      return
    }

    // changing the time on an existing force event creates
    // an unsaved reschedule (what links them is `details.id`)
    setUnsavedDayEvents([
      ...unsavedDayEvents,
      {
        // details.time will have the original time if we need it
        // it will use the same id/key as the original event (changedEvent.id)
        ...changedEvent,
        type: RESCHEDULE_FORCED_FOR[changedEvent.type],
        date: newDate,
      }
    ])
  }

  const handleDayClick = (clickEvent, clicked) => {
    if (!shouldDisplayOption(clicked.type)) {
      return
    }

    setClickedDay({
      ...clicked,
      anchorEl: clickEvent.currentTarget,
    })
  }

  const handleOptionClick = () => {
    setUnsavedEventsMap({
      ...unsavedEventsMap,
      [clickedDay.date.format('YYYY-MM-DD')]: unsavedDayEvents,
    })
    setClickedDay({})
  }

  const sortedClickedDayEvents = clickedDayEvents.sort((a, b) => a.date.diff(b.date))

  const clickedDayHasActiveCycleStart = useMemo(() => (
    !!clickedDay.date && clickedDayEvents.some(
      event => (
        clickedDay.date.isSame(event.date, 'day') && [
          BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START,
          BSD_CALENDAR_EVENTS.FORCE_CYCLE_START,
        ].includes(event.type)
      )
    )
  ), [clickedDay.date, clickedDayEvents])

  const clickedDayHasActiveCollection = useMemo(() => (
    !!clickedDay.date && clickedDayEvents.some(
      event => (
        clickedDay.date.isSame(event.date, 'day') && [
          BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION,
          BSD_CALENDAR_EVENTS.FORCE_COLLECTION,
        ].includes(event.type)
      )
    )
  ), [clickedDay.date, clickedDayEvents])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          size="large"
          color="primary"
          aria-label="Display Settlement Calendar"
          variant="extended"
          onClick={handleToggleSettlementScheduleCalendar}
        >
          {`${showCalendar ? 'Hide' : 'Display'} Settlement Calendar`}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {showCalendar && (
          <>
            <CalendarGroup
              currentEventsMap={currentEventsMap}
              timezone={timezone}
              monthsToDisplay={monthsToDisplay}
              onMonthsToDisplayChange={onMonthsToDisplayChange}
              startFrom={startFrom}
              onDateNavigate={onDateNavigate}
              onDayClick={handleDayClick}
            />
            <Legend />
          </>
        )}
        <Popover
          id="bsd-popover"
          PaperProps={{
            className: classes.bsdPopover,
          }}
          anchorEl={clickedDay?.anchorEl}
          open={Boolean(clickedDay?.anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grid className={classes.bsdGridContainer}>
            <Grid item xs={12}>
              <List
                component="nav"
                aria-label="secondary mailbox folders"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Cycle Starts
                    <IconButton
                      size="small"
                      onClick={() => handleAddEventClick(BSD_CALENDAR_EVENTS.FORCE_CYCLE_START)}
                      disabled={clickedDayHasActiveCycleStart}
                    >
                      <Add />
                    </IconButton>
                  </ListSubheader>
                }
              >
                {sortedClickedDayEvents
                  .filter(event =>
                    [
                      BSD_CALENDAR_EVENTS.FORCE_CYCLE_START,
                      BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START,
                      BSD_CALENDAR_EVENTS.SKIP_CYCLE_START,
                    ].includes(event.type),
                  )
                  .map(event => (
                    <ListItem key={event.id}>
                      <ListItemIcon>
                        <Cached
                          className={classNames({
                            [classes.GREEN]: event.type === BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START,
                            [classes.RED]: event.type === BSD_CALENDAR_EVENTS.SKIP_CYCLE_START,
                            [classes.BLUE]: event.type === BSD_CALENDAR_EVENTS.FORCE_CYCLE_START,
                          })}
                        />
                      </ListItemIcon>
                      {event.type === BSD_CALENDAR_EVENTS.FORCE_CYCLE_START ? (
                        <TextField
                          value={event.date.format(CALENDAR_TIME_FORMAT)}
                          onChange={ev => forcedEventHourChangeHandler(ev, event)}
                          name="time"
                          fullWidth
                          label="hh:mm (24H)"
                          type="time"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            min: isToday ? minTodayTime.format(CALENDAR_TIME_FORMAT) : undefined,
                            step: 1800, // 30 min
                          }}
                        />
                      ) : (
                        <ListItemText primary={event.date.format(CALENDAR_TIME_FORMAT)} />
                      )}
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleClearEventClick(event)}>
                          <Clear size="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <List
                component="nav"
                aria-label="secondary mailbox folders"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Collections
                    <IconButton
                      size="small"
                      onClick={() => handleAddEventClick(BSD_CALENDAR_EVENTS.FORCE_COLLECTION)}
                      disabled={clickedDayHasActiveCollection}
                    >
                      <Add />
                    </IconButton>
                  </ListSubheader>
                }
              >
                {sortedClickedDayEvents
                  .filter(event =>
                    [
                      BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION,
                      BSD_CALENDAR_EVENTS.FORCE_COLLECTION,
                      BSD_CALENDAR_EVENTS.SKIP_COLLECTION,
                    ].includes(event.type),
                  )
                  .map(event => (
                    <ListItem key={event.id}>
                      <ListItemIcon>
                        <AttachMoney
                          className={classNames({
                            [classes.GREEN]: event.type === BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION,
                            [classes.RED]: event.type === BSD_CALENDAR_EVENTS.SKIP_COLLECTION,
                            [classes.BLUE]: event.type === BSD_CALENDAR_EVENTS.FORCE_COLLECTION,
                          })}
                        />
                      </ListItemIcon>
                      {event.type === BSD_CALENDAR_EVENTS.FORCE_COLLECTION ? (
                        <TextField
                          value={event.date.format(CALENDAR_TIME_FORMAT)}
                          onChange={ev => forcedEventHourChangeHandler(ev, event)}
                          name="time"
                          fullWidth
                          label="hh:mm (24H)"
                          type="time"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            min: isToday ? minTodayTime.format(CALENDAR_TIME_FORMAT) : undefined,
                            step: 1800, // 30 min
                          }}
                        />
                      ) : (
                        <ListItemText primary={event.date.format(CALENDAR_TIME_FORMAT)} />
                      )}
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleClearEventClick(event)}>
                          <Clear size="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.bsdActions}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleOptionClick}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </Grid>
    </Grid>
  )
}

SettlementScheduleCalendar.propTypes = {
  timezone: PropTypes.string.isRequired,
  currentEventsMap: PropTypes.object.isRequired,
  existingEventsMap: PropTypes.object.isRequired,
  unsavedEventsMap: PropTypes.object.isRequired,
  setUnsavedEventsMap: PropTypes.func.isRequired,
  monthsToDisplay: PropTypes.number,
  onMonthsToDisplayChange: PropTypes.func,
  startFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDateNavigate: PropTypes.func,
}

export default memo(SettlementScheduleCalendar, memoizingComparison)
