import { API_URL } from 'constants.js'
import httpFetch from 'utils/httpFetch'

export const performFetchTasks = async () => {
  const url = `${API_URL}/tasks`
  return httpFetch(url, { shouldThrowError: true })
}

export const performCreateTask = async (data) => {
  const url = `${API_URL}/tasks`
  return httpFetch(url, { body: JSON.stringify(data), method: 'POST', shouldThrowError: true })
}

export const performRunTask = async (id) => {
  const url = `${API_URL}/tasks/${id}/execute`
  return httpFetch(url, { method: 'POST', shouldThrowError: true })
}

export const performDownloadTask = async (id) => {
  const url = `${API_URL}/tasks/${id}/download`
  return httpFetch(url, { method: 'GET', shouldThrowError: true })
}

export const performUpdateTask = async (id, data) => {
  const url = `${API_URL}/tasks/${id}`
  return httpFetch(url, { body: JSON.stringify(data), method: 'PATCH', shouldThrowError: true })
}

export const performDeleteTask = async (id) => {
  const url = `${API_URL}/tasks/${id}`
  return httpFetch(url, { method: 'DELETE', shouldThrowError: true })
}
