import { getNotificationsConfig, patchNotificationsConfig } from 'services/notificationsApi'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

export const useGetNotificationsConfig = (id) => useQuery('notificationSettings', () => getNotificationsConfig(id), { enabled: !!id })
export const usePatchNotificationsConfig = (id) => useMutation(notificationConfig => {
  const { notifyEmailFor, notifyPlatformFor } = notificationConfig
  patchNotificationsConfig(id, { notifyEmailFor, notifyPlatformFor })
}, {
  onError: (error) => {
    toast.error(error.toString())
  },
  onSuccess: () => {
    toast.success('Notifications configuration successfully updated')
  }
})
