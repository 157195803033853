import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  helpPopup: (props) => ({
    position: props?.withPortal ? 'absolute' : 'relative',
    top: props?.top ?? 0,
    left: props?.left ?? 0
  }),
  title: {
    color: theme.palette.text.primary,
    display: 'flex'
  },
  infoIcon: {
    height: 'auto',
    marginLeft: 2
  }
}))
