import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import FormSelect from 'components/FormSelect'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Section from 'components/PaymentProfileConfigForm/components/Section'
import { PAYMENT_GATEWAY_PLATFORM, PAYMENT_PLATFORMS } from 'constants.js'
import { useStyles } from './styles'
import Button from 'components/Button';
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'

const textFieldProps = {
  fullWidth: true,
  margin: 'normal'
}

export const PaymentProfileConfigForm = ({
  formValues,
  setFormValue,
  submitPayment,
  control,
  errors,
  platform,
  changePlatform,
  paymentProfileDefaultConfig,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (formValues.platform === PAYMENT_PLATFORMS.E_WALLET) {
      setFormValue('fundsClearingPeriod', paymentProfileDefaultConfig.eWalletDefaultClearingHours)
    }
  }, [formValues.platform, setFormValue, paymentProfileDefaultConfig])

  return (
    <React.Fragment>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} className={classes.gridItem}>
          <Controller
            control={control}
            name="profileName"
            render={({ field }) => (
              <TextField
                {...field}
                label={formatMessage({ id: 'payment-profile-configuration.profile-name' })}
                required
                error={!!errors.profileName}
                helperText={errors.profileName?.message}
                {...textFieldProps}
              />
            )}
          />

          <Controller
            control={control}
            name="platform"
            render={({ field: { onChange, value } }) => (
              <FormSelect
                onChange={(e) => {
                  changePlatform(e.target.value);
                  onChange(e);
                }}
                value={value}
                name="platform"
                label={formatMessage({ id: 'payment-profile-configuration.payment-platform' })}
                placeholder={formatMessage({ id: 'payment-profile-configuration.buyer-profile-id' })}
                keyTitle={Object.fromEntries(
                  Object.entries(PAYMENT_GATEWAY_PLATFORM)
                    .map(([k, v]) => [k, formatMessage({ id: `payment-gateway-platform.${kebabCase(v)}` })])
                )}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={6} className={classes.gridItem}>
          <Section title={formatMessage({ id: 'payment-profile-configuration.settings' })} className={classes.section}>
            {platform === PAYMENT_PLATFORMS.DIRECT_DEBIT_UK && <>
              <Controller
                control={control}
                name="bottomlinePtxEmail"
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="email"
                    label={formatMessage({ id: 'payment-profile-configuration.bottomline-ptx-email' })}
                    required
                    autoComplete="off"
                    error={!!errors.bottomlinePtxEmail}
                    helperText={errors.bottomlinePtxEmail?.message}
                    {...textFieldProps}
                  />
                )}
              />

              <Controller
                control={control}
                name="bottomlinePtxPassword"
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="password"
                    label={formatMessage({ id: 'payment-profile-configuration.bottomline-ptx-password' })}
                    required
                    autoComplete="off"
                    error={!!errors.bottomlinePtxPassword}
                    helperText={errors.bottomlinePtxPassword?.message}
                    {...textFieldProps}
                  />
                )}
              />

              <Controller
                control={control}
                name="paymentId"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={formatMessage({ id: 'payment-profile-configuration.payment-id-sun' })}
                    {...textFieldProps}
                  />
                )}
              />


            </>}
            <Controller
              control={control}
              name="fundsClearingPeriod"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={formatMessage({ id: 'payment-profile-configuration.funds-clearing-period' })}
                  {...textFieldProps}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">({formatMessage({ id: 'payment-profile-configuration.hours' })})</InputAdornment>
                  }}
                  disabled={formValues.platform === PAYMENT_PLATFORMS.E_WALLET}
                />
              )}
            />

          </Section>
        </Grid>
      </Grid>
      <Button
        color="success"
        aria-label="save"
        size="large"
        disabled={!!Object.entries(errors).length}
        onClick={submitPayment}
        icon="save"
        marginTop
      >
        {formatMessage({ id: 'payment-profile-configuration.save' })}
      </Button>
    </React.Fragment>
  )
}

PaymentProfileConfigForm.propTypes = {
  submitPayment: PropTypes.func,
  control: PropTypes.object,
  errors: PropTypes.object,
  platform: PropTypes.string,
  changePlatform: PropTypes.func,
  formValues: PropTypes.object,
  setFormValue: PropTypes.func,
  paymentProfileDefaultConfig: PropTypes.object,
}
