import { joiResolver } from '@hookform/resolvers/joi'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Radio from '@material-ui/core/Radio'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DAYS_OF_MONTH_KEY_TITLE, FREQUENCY_TYPE_KEY_TITLE, MAX_REFERENCE_MSG_LEN, scheduledWithdrawalDefaultValues, scheduledWithdrawalFormSchema } from '../withdrawalValidationSchema'
import { Button, Checkbox, DialogActions, FormControlLabel, FormHelperText, MenuItem, OutlinedInput, RadioGroup, Select, Switch, TextField, Typography, useTheme } from '@material-ui/core'
import CheckboxGroup from 'components/CheckboxGroup'
import { SCHEDULE_DAY_KEY_TITLE } from '../../../constants'
import InputText from 'components/ui/Form/InputText'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'
import { makeStyles } from '@material-ui/core'
import { ScheduledWithdrawalDayOfMonth, ScheduledWithdrawalFrequencyType } from '@travel-ledger/type-constants'

const WithdrawalAmountType = {
  SPECIFIC_AMOUNT: 'SPECIFIC_AMOUNT',
  WITHDRAW_ALL_AVAILABLE_FUNDS: 'WITHDRAW_ALL_AVAILABLE_FUNDS',
  LEAVE_BALANCE_OF: 'LEAVE_BALANCE_OF',
}

const useStyles = makeStyles((theme) => ({
  withdrawalAmountContainer: {
    marginTop: theme.spacing(2),

    '& .MuiFormControlLabel-root': {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,

      '& .MuiTypography-root.MuiFormControlLabel-label': {
        display: 'block',
        flex: '1',
      },
    },
  },
}))

function getWithdrawalAmountTypeFromConfig(withdrawalConfig) {
  if (withdrawalConfig.withdrawAllAvailableFunds) return WithdrawalAmountType.WITHDRAW_ALL_AVAILABLE_FUNDS
  if (withdrawalConfig.minAmountLeftEnabled) return WithdrawalAmountType.LEAVE_BALANCE_OF
  return WithdrawalAmountType.SPECIFIC_AMOUNT
}

const ScheduledWithdrawalDialog = ({
  open,
  onClose,
  whitelist,
  onSubmit,
  scheduledWithdrawal,
}) => {
  const theme = useTheme()
  const styles = useStyles()
  const { formatMessage } = useIntl()
  const isEditMode = !!scheduledWithdrawal?.id && !!scheduledWithdrawal?.configuration
  const {
    register,
    setValue,
    watch,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: joiResolver(scheduledWithdrawalFormSchema()),
    defaultValues: {
      ...scheduledWithdrawalDefaultValues,
      ...(isEditMode && {
        id: scheduledWithdrawal.id,
        account: scheduledWithdrawal.account,
        amount: scheduledWithdrawal.configuration.amount,
        reference: scheduledWithdrawal.configuration.reference,
        frequencyType: scheduledWithdrawal.configuration.frequencyType,
        daysOfWeek: scheduledWithdrawal.configuration.daysOfWeek,
        daysOfMonth: scheduledWithdrawal.configuration.daysOfMonth,
        timeOfDay: scheduledWithdrawal.configuration.timeOfDay,
        minAvailableFunds: scheduledWithdrawal.configuration.minAvailableFunds,
        minAmountLeft: scheduledWithdrawal.configuration.minAmountLeft,
        withdrawAllAvailableFunds: scheduledWithdrawal.configuration.withdrawAllAvailableFunds,
        minAvailableFundsEnabled: scheduledWithdrawal.configuration.minAvailableFundsEnabled,
        minAmountLeftEnabled: scheduledWithdrawal.configuration.minAmountLeftEnabled,
      }),
    },
  })

  const [withdrawalAmountType, setWithdrawalAmountType] = React.useState(
    isEditMode
      ? getWithdrawalAmountTypeFromConfig(scheduledWithdrawal.configuration)
      : WithdrawalAmountType.SPECIFIC_AMOUNT
  )

  const {
    account: selectedWhitelistAccount,
    minAvailableFundsEnabled,
    daysOfWeek,
    daysOfMonth,
    frequencyType,
  } = watch()

  const handleSubmit = handleFormSubmit(onSubmit)

  useEffect(() => {
    switch (withdrawalAmountType) {
      case WithdrawalAmountType.SPECIFIC_AMOUNT: {
        setValue('withdrawAllAvailableFunds', false)
        setValue('minAmountLeftEnabled', false)
        setValue('minAmountLeft', '')
        break
      }
      case WithdrawalAmountType.WITHDRAW_ALL_AVAILABLE_FUNDS: {
        setValue('withdrawAllAvailableFunds', true)
        setValue('amount', '')
        setValue('minAmountLeftEnabled', false)
        setValue('minAmountLeft', '')
        break
      }
      case WithdrawalAmountType.LEAVE_BALANCE_OF: {
        setValue('withdrawAllAvailableFunds', false)
        setValue('amount', '')
        setValue('minAmountLeftEnabled', true)
        break
      }
      default: throw new Error('Unexpected withdrawal amount type')
    }
  }, [withdrawalAmountType, setValue])

  useEffect(() => {
    if (frequencyType === ScheduledWithdrawalFrequencyType.WEEKLY) {
      setValue('daysOfMonth', [])
    } else if (frequencyType === ScheduledWithdrawalFrequencyType.MONTHLY) {
      setValue('daysOfWeek', [])
    } else if (frequencyType === ScheduledWithdrawalFrequencyType.DAILY) {
      setValue('daysOfMonth', [])
      setValue('daysOfWeek', [])
    }
  }, [frequencyType, setValue])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Box display="flex" m={2}>
          <Box flexGrow={0} pr={4}>
            <Box pb={2}>
              <Typography component="h5" variant="h5">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.choose-account-to-withdraw-to' })}
              </Typography>
            </Box>
            <List>
              {whitelist.map((beneficiary, index) => {
                return (
                  <ListItem key={index} style={{ border: `1px solid ${theme.palette.divider}` }}>
                    <ListItemIcon>
                      <Radio
                        checked={selectedWhitelistAccount === beneficiary.beneficiaryHashId}
                        onChange={() => setValue('account', beneficiary.beneficiaryHashId)}
                        value={beneficiary.beneficiaryHashId}
                        {...register('account')}
                      />
                    </ListItemIcon>
                    <ListItemText primary={beneficiary.beneficiaryAccountNumber} secondary={`${beneficiary.beneficiaryName} / ${beneficiary.beneficiaryBankName} / ${beneficiary.destinationCurrency}`} />
                  </ListItem>
                )
              })}
            </List>
            <FormHelperText error>{errors?.account?.message}</FormHelperText>
          </Box>
          <Box flexGrow={1}>
            <Box pb={2}>
              <Typography component="h5" variant="h5">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.schedule-details' })}
              </Typography>
            </Box>

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.when-select-frequency' })}:*
              </Typography>
              <Controller
                control={control}
                name="frequencyType"
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {Object.entries(FREQUENCY_TYPE_KEY_TITLE).map(([k, v]) => (
                      <FormControlLabel
                        key={k}
                        value={k}
                        control={<Radio />}
                        label={formatMessage({ id: `withdrawal-tab.${kebabCase(v)}` })}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              <FormHelperText error>{errors?.frequencyType?.message}</FormHelperText>
            </Box>

            {frequencyType === ScheduledWithdrawalFrequencyType.WEEKLY && (
              <Box pt={2}>
                <Typography component="h6" variant="h6">
                  {formatMessage({ id: 'scheduled-withdrawal-dialog.select-day-s' })}:*
                </Typography>
                <Controller
                  name="daysOfWeek"
                  control={control}
                  render={({ field }) => (
                    <CheckboxGroup
                      {...field}
                      type="number"
                      items={
                        Object.entries(SCHEDULE_DAY_KEY_TITLE)
                          .map(([key, value]) => ({ value: parseInt(key), label: formatMessage({ id: `schedule-day-key-title.${kebabCase(value)}` }) }))
                      }
                      onChange={days => setValue('daysOfWeek', days)}
                      value={daysOfWeek}
                      error={!!errors?.daysOfWeek}
                    />
                  )}
                />
                <FormHelperText error>{errors?.daysOfWeek?.message}</FormHelperText>
              </Box>
            )}

            {frequencyType === ScheduledWithdrawalFrequencyType.MONTHLY && (
              <Box pt={2}>
                <Typography component="h6" variant="h6">
                  {formatMessage({ id: 'scheduled-withdrawal-dialog.select-day-s' })}:*
                </Typography>
                <Controller
                  name="daysOfMonth"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      value={daysOfMonth}
                      onChange={e => setValue('daysOfMonth', e.target.value)}
                      input={<OutlinedInput label={formatMessage({ id: 'scheduled-withdrawal-dialog.days' })} />}
                      renderValue={(selected) => selected.map(x => typeof x === 'number' ? x : formatMessage({ id: `withdrawal-tab.${kebabCase(DAYS_OF_MONTH_KEY_TITLE[x])}` })).join(', ')}
                      fullWidth
                    >
                      {[ScheduledWithdrawalDayOfMonth.LAST, ...new Array(31).fill().map((_, i) => 1 + i)].map((day) => (
                        <MenuItem key={day} value={day}>
                          <Checkbox checked={daysOfMonth.includes(day)} />
                          <ListItemText primary={typeof day === 'number' ? day : formatMessage({ id: `withdrawal-tab.${kebabCase(DAYS_OF_MONTH_KEY_TITLE[day])}` })} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error>{errors?.daysOfMonth?.message}</FormHelperText>
              </Box>
            )}

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.select-time-of-day' })}:*
              </Typography>
              <Box width="8rem">
                <Controller
                  control={control}
                  name="timeOfDay"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={formatMessage({ id: 'scheduled-withdrawal-dialog.time-of-day' })}
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 60,
                      }}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <FormHelperText error>{errors?.timeOfDay?.message}</FormHelperText>
            </Box>

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.choose-scheduled-amount-to-be-withdrawn' })}:*
              </Typography>

              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={withdrawalAmountType === WithdrawalAmountType.WITHDRAW_ALL_AVAILABLE_FUNDS}
                      onChange={(_, checked) => setWithdrawalAmountType(checked ? WithdrawalAmountType.WITHDRAW_ALL_AVAILABLE_FUNDS : WithdrawalAmountType.SPECIFIC_AMOUNT)}
                    />
                  }
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.withdraw-all-available-funds' })}
                />

                <RadioGroup
                  value={withdrawalAmountType}
                  onChange={(_ev, value) => setWithdrawalAmountType(value)}
                >
                  <Box className={styles.withdrawalAmountContainer}>
                    <FormControlLabel
                      value={WithdrawalAmountType.SPECIFIC_AMOUNT}
                      control={<Radio disabled={withdrawalAmountType === WithdrawalAmountType.WITHDRAW_ALL_AVAILABLE_FUNDS} />}
                      label={
                        <InputText
                          className=''
                          errors={errors}
                          type="number"
                          name="amount"
                          control={control}
                          label={formatMessage({ id: 'scheduled-withdrawal-dialog.amount' })}
                          required={withdrawalAmountType === WithdrawalAmountType.SPECIFIC_AMOUNT}
                          disabled={withdrawalAmountType !== WithdrawalAmountType.SPECIFIC_AMOUNT}
                        />
                      }
                    />
                  </Box>

                  <Box className={styles.withdrawalAmountContainer}>
                    <FormControlLabel
                      value={WithdrawalAmountType.LEAVE_BALANCE_OF}
                      control={<Radio disabled={withdrawalAmountType === WithdrawalAmountType.WITHDRAW_ALL_AVAILABLE_FUNDS} />}
                      label={
                        <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }}>
                          <Box flexGrow="0">
                            {formatMessage({ id: 'scheduled-withdrawal-dialog.leave-balance-of' })}
                          </Box>
                          <Box flex="1">
                            <InputText
                              className=''
                              errors={errors}
                              type="number"
                              name="minAmountLeft"
                              control={control}
                              label={formatMessage({ id: 'scheduled-withdrawal-dialog.amount' })}
                              required={withdrawalAmountType === WithdrawalAmountType.LEAVE_BALANCE_OF}
                              disabled={withdrawalAmountType !== WithdrawalAmountType.LEAVE_BALANCE_OF}
                            />
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </RadioGroup>
              </Box>
              <InputText
                errors={errors}
                type="text"
                name="reference"
                control={control}
                label={formatMessage({ id: 'scheduled-withdrawal-dialog.reference-optional' })}
                inputProps={{ maxLength: MAX_REFERENCE_MSG_LEN }}
              />
            </Box>

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.schedule-rules' })}:
              </Typography>
              <Box pt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={minAvailableFundsEnabled}
                      onChange={(_, checked) => setValue('minAvailableFundsEnabled', checked)}
                    />
                  }
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.withdraw-only-when-available-funds-reach' }) + ':'}
                />
                <InputText
                  required={minAvailableFundsEnabled}
                  errors={errors}
                  type="number"
                  name="minAvailableFunds"
                  control={control}
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.amount' })}
                  disabled={!minAvailableFundsEnabled}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="default"
        >
          {formatMessage({ id: 'scheduled-withdrawal-dialog.cancel' })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
        >{
          isEditMode
            ? formatMessage({ id: 'scheduled-withdrawal-dialog.save' })
            : formatMessage({ id: 'scheduled-withdrawal-dialog.create' })
        }</Button>
      </DialogActions>
    </Dialog>
  )
}

ScheduledWithdrawalDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  whitelist: PropTypes.arrayOf(PropTypes.object),
}

export default ScheduledWithdrawalDialog
