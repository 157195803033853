// eslint-disable-next-line no-unused-vars
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { dateToReadableDate } from 'utils/date'

const DepartureDateCell = memo(({ departureDate }) => {
  return dateToReadableDate(departureDate)
})

DepartureDateCell.displayName = 'DepartureDateCell'

DepartureDateCell.propTypes = {
  departureDate: PropTypes.string,
  tags: PropTypes.array
}

export default DepartureDateCell
