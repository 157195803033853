import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import JSPDF from 'jspdf'
import html2canvas from 'html2canvas'
import InvoiceReceipt from './InvoiceReceipt'
import Box from '@material-ui/core/Box'
import { useStyles } from './styles'

const DownloadInvoice = ({ invoice, setPdfInvoice }) => {
  const classes = useStyles()
  const receiptRef = useRef(null)

  const printDocument = async () => {
    if (!receiptRef.current) {
      return
    }

    const input = document.getElementById('billing-receipt')
    const canvas = await html2canvas(input, {
      onclone: function (clonedDoc) {
        // eslint-disable-next-line
        clonedDoc.getElementById('billing-receipt').style.display = 'block'
        // There's an issue with colors not matching (not sure why)
        // Setting here all elelemtns with the theme text primary color
        const allCloneElements = clonedDoc.getElementsByTagName('*')
        for (let i = 0; i < allCloneElements.length; i++) {
          allCloneElements[i].style.color = "#043943";
        }
      },
    })
    const imgData = canvas.toDataURL('image/png')
    const pdf = new JSPDF()
    const imgProps = pdf.getImageProperties(imgData)
    const pdfWidth = pdf.internal.pageSize.getWidth() - 20 // 20 for the margins
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

    pdf.addImage(imgData, 'JPEG', 10, 10, pdfWidth, pdfHeight, null, 'MEDIUM')
    pdf.save(`TL-invoice-${invoice.bookingRef}.pdf`)
  }

  useEffect(() => {
    printDocument().then(() => {
      setPdfInvoice(null)
    })
  }, [receiptRef])

  return (
    <Box>
      <div className={classes.hiddenReceipt} id="billing-receipt" ref={receiptRef}>
        <InvoiceReceipt invoice={invoice} />
      </div>
    </Box>
  )
}

DownloadInvoice.propTypes = {
  invoice: PropTypes.object,
  setPdfInvoice: PropTypes.func,
}

export default DownloadInvoice
