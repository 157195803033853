import { connect } from 'react-redux'
import Component from './InstantPayments.js'
import { push } from 'connected-react-router'

export const mapState = ({ auth, invoices, companies }) => ({
  currUserCompanyId: auth.companyId,
  snackbar: invoices.snackbar,
  currUserCompany: companies.currUserCompany,
  currUserRole: auth.role
})

export const mapDispatch = (dispatch) => ({
  redirectTo: (data) => dispatch(push(data)),
})

export default connect(mapState, mapDispatch)(Component)
